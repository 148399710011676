import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';

import searchIcon from './../../../assets/onboard-images/Search_Icon.png';
import ActivateDisabledIcon from './../../../assets/images/Activate_Disabled_Icon.png';
import ActivateIcon from './../../../assets/images/Activate_Icon.png';
import menuIcon from './../../../assets/images/Dotted_ContextMenu_Icon.png';
import FileUpload_Icon from './../../../assets/images/FileUpload_Icon.png';
import UploadAdministration_Icon from './../../../assets/images/UploadAdministration_Icon.png';
import Admindata from '../../Administration/Administration_firstpage.js/Admin.json';
import TooltipHandler from '../../Onboard/OnboardComponents/TooltipHandler';
import { useHistory } from 'react-router-dom';
import { ActivateModelPopup, ActivationRequestModal, RequestSentModal } from '../../Administration/Administration_firstpage.js/ActivationModel';
import { useDispatch, useSelector } from 'react-redux';
import { getUploadAccounts, setSelectedAccountDetails } from '../../../actions/uploads.action';

function UploadAccountList() {
  const history = useHistory();
  const dispatch = useDispatch();
  let uploadAccounts = useSelector((state) => state.uploadReducer.uploadAccounts);
console.log('uploadAccounts',uploadAccounts)
  useEffect(() =>{
    dispatch(getUploadAccounts())
  },[dispatch])




  const [showRequestForm, setShowRequestForm] = useState(false);
  const [showRequestSent, setShowRequestSent] = useState(false);
  const [filter, setFilter] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'Account Name', 'Site Name', 'Created By'
  ]);
  function searchRows(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]
            .toString()
            .toLowerCase()
            .indexOf(filter.toLowerCase()) > -1,
      ),
    );
  };
  const menuoptions = [
    {
      id: 1,
      imageSrc: FileUpload_Icon,
      name: 'File Upload - Voice & Non Voice',
      value: '/upload/account'
    }, {
      id: 2,
      imageSrc: UploadAdministration_Icon,
      name: 'Upload Data Administration',
      value: "/upload/dataAdmin"
    }
  ];

  
  const [selectedAccount, setSelectedAccount] = useState({});
  const [openDownload, setOpenDownload] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);

  const onClickAccountRow = (data, id) => {
    switch (data?.Status) {
      case 'Active': {
        setDropdownIndex(id)
        setSelectedAccount(data);
        
        setOpenDownload(!openDownload);
        break;
      }
      case 'Inactive': {
        if (data?.Request !== 'Sent') {
          setSelectedAccount(data);
          setShowRequestForm(true);
          setShowRequestSent(false);
        }
        break;
      }
      default: { }
    }
  }

  return (
    <>
      <div>
        <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={false} label2='Upload' />
        <header className='administration_text'>Upload</header>
        <section>
          <main className="container_acc" style={{ width: "94%", left: 10, marginTop: 20 }} >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}><div className="accounts__text" >Accounts</div>
              <div> <span className='search-bar-role' style={{ marginTop: 10, marginRight: 20 }}>
                <input className='search-input-role' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Accounts' />
                <button className='search-icon'><img src={searchIcon} alt='' /> </button>
              </span></div>
            </div>
            <section className='table-section' >
              <table className='report-table' style={{ width: '100%', maxHeight: '519px', paddingLeft: 20 }}>
                <thead className='admin_trclass'>
                  <tr className='admin_trclass'>
                    {Admindata.headers.map(header => (<th className='table_head_admin' key={header.id}> {header.label}</th>))}
                  </tr>
                </thead>
                <tbody className='admin_trclass'>
                 
                  {searchRows(uploadAccounts).map((info, rowId) => (
                  <tr className='admin_trclass' key={rowId} >
                    <td className='tbody_admin'><div><img
                    style={{maxHeight:'40px',maxWidth:'60px'}}
                    // height='20px'
                    // width='60px'
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/org/customer/${info?.CustomerID}/photo`}
                  alt="Client Logo"
                /></div></td>
                    <td className='tbody_admin'>{info?.['Account Name']}</td>
                    <td className='tbody_admin'>{info?.['Site Name']}</td>
                    <td className='tbody_admin'>{info?.['Created By']}</td>
                    <td className='tbody_admin'>{(info?.['Created Date'])}</td>
                    <td className='tbody_admin'>{(info?.['Valid From'])}</td>
                    <td className='tbody_admin'>{(info?.['Valid From'])}</td>
                    <td className='tbody_admin extra_td'> <div id='active' style={{ backgroundColor: info?.Status === 'Active' ? '#6CC24A' : '#FF0000' }}></div>  {info?.['Status']}</td>
                    <td className='tbody_admin' onClick={() => {onClickAccountRow(info,rowId);dispatch(setSelectedAccountDetails(info))}}>{
                      info?.Status === 'Active' ? <>
                        <img src={menuIcon} alt='' style={{cursor: 'pointer'}}/>
                        <div style={{position:'relative'}} >
                        {(openDownload && dropdownIndex === rowId) && (
                          <ul className='menu_options' style={{width:'310px'}}>
                            {menuoptions.map(data => (
                              <li className='li_options' onClick={() => history.push(data.value)} >
                                <img src={data.imageSrc} alt='' /> {data.name} </li>)
                            )}
                          </ul>)}</div>
                      </> : <>
                        {info?.Request !== 'Sent' ?
                          <span><TooltipHandler src={ActivateIcon} position='top right'
                            arrowAlign='end' textBoxWidth='260px' text='Request for Account Activation' /> </span>
                          : <span > <TooltipHandler src={ActivateDisabledIcon} position='top right'
                            arrowAlign='end' textBoxWidth='340px' text='Activation request sent to administrator' /></span>}
                      </>} </td> </tr>
                ))}
                </tbody>
              </table>
              
              {showRequestForm && <ActivateModelPopup isOpen={setShowRequestForm} setShowModal={setShowRequestForm} style={{ height: '300px' }}>
              <ActivationRequestModal
                handleSubmitClick={() => {
                  setShowRequestForm(false);
                  setShowRequestSent(true);
                }} data={selectedAccount}
                handleCancelClick={() => setShowRequestForm(false)}
              />
            </ActivateModelPopup>}
              
              {showRequestSent && <ActivateModelPopup isOpen={setShowRequestSent} setShowModal={setShowRequestSent}>
              <RequestSentModal data={selectedAccount}
                handleClose={() => {
                  setShowRequestSent(false);
                  setShowRequestForm(false);
                }}
              />
            </ActivateModelPopup>}




            </section>
          </main>
        </section >


      </div ></>
  )
}

export default UploadAccountList