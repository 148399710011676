import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessGroupData, getAccessGroupEditData } from '../../../../actions/Administration/adminView.action';
import { updateAccessGroupAddEditStart } from './../../../../actions/Administration/adminView.action';
import searchIcon from '../../../../assets/images/Search_Icon.png';

const AccessGroupAddEdit = () => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    console.log('location::::::', location.state);
    let addData = location.state === undefined ? '' : location.state.addData;
    let accessGroupEditData = useSelector((state) => state.adminViewReducer.accessGroupEditData);
    const EditData = location.state === undefined ? '' : location.state.editInfo;
    const Mode = location.state === undefined ? '' : location.state.Mode;
    const [editAPIData, setEditAPIData] = useState({})
    const [query, setQuery] = useState('');

   

    useEffect(() => {
        dispatch(getAccessGroupEditData(Mode === 'Edit' ? EditData.AccessGroupID : ''));
    }, [dispatch, EditData, Mode]);

    useEffect(() => {
        setEditAPIData(accessGroupEditData)
    }, [dispatch, accessGroupEditData]);

    const filterQuery = data => {
        console.log('data????????',data)
        return data?.filter(option => {
            if (query === '') return option;
            else if (option.Operation.toLowerCase().includes(query.toLowerCase().trim()))
                return option;
        })
    }

    


    const [addAccessGroup, setAddAccessGroup] = useState(Mode === 'Edit' ? EditData : {});
    const HandleAddAccessGroup = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...addAccessGroup };
            newFormData[fieldName] = fieldValue;
            setAddAccessGroup(newFormData)
        }
    }
    let editoperationlist = editAPIData?.[0]?.OperationList;



    useEffect(() => {
        if (Mode === 'Edit') {
            setData(editoperationlist)
        }
        else {
            setData(addData)
        }
    }, [dispatch, addData, Mode, editoperationlist]);


    const [data, setData] = React.useState();

    // const toggle = index => {
    //     const newData = [...data];
    //     newData.splice(index, 1, {
    //         OperationId: data[index].OperationId,
    //         Operation: data[index].Operation,
    //         Selected: !data[index].Selected
    //     });
    //     setData(newData);
    // };


     const toggle =(opdata, index) => {
        const newData = [...data];
        newData.map((itm, i) => {
            if (opdata?.Operation === itm?.Operation) {
                newData.splice(i, 1, {
                        OperationId: itm.OperationId,
                        Operation: itm.Operation,
                        Selected: !itm.Selected
                    });
                    setData(newData);
            }})
    };

    const [checkedAll, setCheckedAll] = useState(false)

    const handleChange = event => {
        if (event.target.checked) {
            const newData = [...data];
            newData.map((itm, i) => {
                    newData.splice(i, 1, {
                            OperationId: itm.OperationId,
                            Operation: itm.Operation,
                            Selected: true
                        });
                        setData(newData);
                })
        } else {
            const newData = [...data];
            newData.map((itm, i) => {
                    newData.splice(i, 1, {
                            OperationId: itm.OperationId,
                            Operation: itm.Operation,
                            Selected: false
                        });
                        setData(newData);
                })
        }
        setCheckedAll(current => !current);
      };
   
      const [validation, setValidation] = useState(false);
   
    const dispobj = () =>{

        let DispOperations = data.filter((el) =>{
            return el.Selected === true});
        let OperationIds = DispOperations.map(access => {
            return {
                OperationId : access.OperationId
            }
        });

        console.log('OperationIds',OperationIds)
        let temparr = {
            RoleID : Mode === 'Edit' ? EditData.AccessGroupID : '',
            Role : addAccessGroup?.['Access Group'],
            Description : addAccessGroup.Description,
            Details : OperationIds
    
        }
        const temp_Arr = {
            Role : addAccessGroup?.['Access Group'],
            Description : addAccessGroup.Description,
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        if(isEmptyInput){
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
            window.scrollTo(0, 0);
        }
        else if(OperationIds.length === 0){
            setValidation('Please Ensure you Selected the Operations');
            window.scrollTo(0, 0);
        }
        else{
        console.log('dispOBJ',[temparr])
     dispatch(updateAccessGroupAddEditStart([temparr]))
     history.push('/admin/accessgroup')
     dispatch(getAccessGroupData())
        }
    }

    
    return (
        <div>
            <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={()=>history.push('/adminviewlanding')}
                isActive3={true} label3='Access Groups' value3={() => history.push('/admin/accessgroup')} isActive4={false} label4={Mode === 'Edit' ? 'Edit Access Group' : 'Add Access Group'}
            />
            <div className='administration_text'>{Mode === 'Edit' ? 'Edit' : 'Add'} Access Group</div>
            <div style={{ color: '#ED4F32',marginLeft:10 }}>{validation}</div>
            <main className='Roles_name_section'>
                <div className='rolename_inputfield'>
                    <label>Name * </label>
                    <input type='text'
                        name='Access Group'
                        value={addAccessGroup?.['Access Group']}
                        placeholder='Example:Associate Manager (AM)'
                        onChange={(e) => HandleAddAccessGroup(e)}
                    />
                </div>
                <div className='description_inputfield'>
                    <label>Descriptions * </label>
                    <input type='text'
                        name='Description'
                        value={addAccessGroup.Description}
                        placeholder='Example:Similar responsibilities are applicable here as for the SDM, however an AM usually has a dedicated to…'
                        onChange={(e) => HandleAddAccessGroup(e)}
                    />
                </div>
            </main>
            <main>
                <div className='select_operations_text'><label>Select Operations *</label></div>
                <div className='account_list select_all' style={{ width: '95%', marginTop: '0px',marginLeft: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div> <span style={{ cursor: 'pointer' }}>
                            <input type='checkbox' id='selectAll' name="selectAll" style={{ cursor: 'pointer' }}
                                    onChange={handleChange}
                                    checked={checkedAll}
                                />

                                <label className='label-region' htmlFor='selectAll' style={{ cursor: 'pointer' }}>  Select All </label></span></div>
                            <div style={{ marginRight: '20px', marginTop: '-5px' }}><span className='search-bar-widget'>
                                <input type='input' className='search_account'
                                    onChange={(e) => setQuery(e.target.value)}
                                    placeholder='Search Account' />
                                <button className='search-icon-widget'><img src={searchIcon} alt='' /> </button></span></div>
                        </div>
                    </div>
                {(data === undefined || data === '') ? '' :<section className='scroll_bar_accessGroup' style={{ marginLeft: 10 }}>
                    <div className='grid_accessgroup'>
                        {filterQuery(data)?.map((item, index) => (
                            <div>
                                <span class="grid_item_accessgroup" key={index}  >
                                    <input type='checkbox' id={item.Operation} style={{ cursor: 'pointer' }}
                                        name={item.Operation}
                                        checked={item.Selected || false}
                                        onChange={() => toggle(item,index)}
                                    />
                                    <label style={{ color: 'black', cursor: 'pointer' }} htmlFor={item.Operation} >
                                        {item.Operation} </label></span>
                            </div>
                        ))
                        }
                    </div>
                </section>}
            </main>
            <main className='Button_container_accessGroup' style={{ marginLeft: '500px' }}>
                <button className='btn_adduser' onClick={()=>dispobj()}>{Mode === 'Edit' ? 'UPDATE' : 'ADD'}</button>
                <button className='btn_canceluser' onClick={() => history.push('/admin/accessgroup')}>CANCEL</button>
            </main>
        </div>
    )
}

export default AccessGroupAddEdit