import React from "react";
import './buttonList.css';
import { Button } from "react-bootstrap";

const ButtonList = (props) => {
    const handleItems = (indx) => {
        for(let i=0;i<props.data.length;i++){
            if(i === indx)
            props.data[i]['isActv'] =true;
            else
            props.data[i]['isActv'] =false;
        }
        props.handleDataSelection(indx);
    }

    return (
        <div>
        <div className="label-rules">
            <h4>{props.bannerName}</h4>
        </div>        
        <div className="topContainer">
            <ul className="btnList">
            { props.data.map((item, indx)=>{
                return <li key={indx}>
                    <Button 
                    className={item.isActv ? 
                    'btn-activ' : 'btn-inActiv'} 
                    onClick={()=>{handleItems(indx)}} 
                    variant="outline-secondary">{item.fieldName}</Button>
                </li>
            }) }
            </ul>
        </div>
        </div>
    );
};

export default ButtonList;