import React from 'react';
import { Link } from "react-router-dom";
import './regional-card-widget.css';

const RegionalCardWdget = (props) => {
    return (
        <div className="row dropdown-card">
          <div className="global-acc-cntr">
            <div className="row">
                <div className="col-sm-2 col-md-1 col-lg-1 txt-cntr">
                  <img
                  className='img-fluid regional-wdgt-img'
                    src={props?.img}                    
                    alt="Fiance Report Icon"
                  />
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <div className="col-sm col-md col-lg">
                      <h5><b>{props?.txthdr}</b></h5>
                  </div>
                  <div>
                      <h6><b>{props?.txt}</b></h6>
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-4">
                  <div className="row">
                      <div className="col-sm-1 col-md-1 col-lg-1">
                        <hr className="dash-line"></hr>
                      </div>
                      <div className="col-sm-4 col-md-4 col-lg-4">                        
                        <Link className="dash-line-color" to={props?.lnk}><h6 className="dash-line-color"><b>{props?.lnktxt}</b></h6></Link>
                      </div>
                  </div>
                </div>
            </div>
          </div>
          </div>
    );
};

export default RegionalCardWdget;

