
import API from '../../api'
let token = localStorage.getItem("token");
export const getAdminAccountsService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const updateValidToDateService = (requestUrl, id) => {

    return API.post(requestUrl, id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getAdminQueueTableDataService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const updateAdminQueueTableDataService = (requestUrl, id) => {

    return API.post(requestUrl, id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getAdminSlaTableDataService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const updateAdminSlaTableDataService = (requestUrl, id) => {

    return API.post(requestUrl, id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getAdminSupportTableDataService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const updateAdminSupportTableDataService = (requestUrl, id) => {

    return API.post(requestUrl, id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getAdminVoiceSlaTableDataService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getAdminVoiceSupportTableDataService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };


  export const updateQueueAdminRequestService = (requestUrl, id) => {

    return API.post(requestUrl, id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const updateSLAAdminRequestService = (requestUrl, id) => {

    return API.post(requestUrl, id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const updateSupportAdminRequestService = (requestUrl, id) => {

    return API.post(requestUrl, id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const updateAdminUpdateService = (requestUrl, id) => {

    return API.post(requestUrl,id, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getPreviewAdminService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getRoleBasedViewService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      }
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };
