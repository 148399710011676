import React from 'react';
import {Bar} from 'react-chartjs-2';
import "./horizontal-bar-chart.css";

const HorizontalBarChart = (props) => {
  
  let lbls = [], dt = [];
  if(props.data && props.data.length > 0){
      props.data.map((itm)=>{
        lbls.push(itm.label);
        dt.push(itm.Value);
        return;
      });
  }
    const data = {
        type: "horizontalBar",
        labels: lbls,
        datasets: [
          {
            label: '',
            axis: 'y',
            backgroundColor: props?.clr,
            borderColor: props?.clr,
            borderWidth: 1,
            hoverBackgroundColor: props?.clr,
            hoverBorderColor: props?.clr,
            data: dt,
            barThickness: 10
          }
        ]
    };

    return (
        <div style={{height: '330px'}}>  
            <div className='text-center hrzntl-bar-txt py-3'><span>{props?.txt}</span></div>          
            <Bar  options={{responsive: true,
                            maintainAspectRatio: false,  
                            indexAxis: 'y',
                            plugins:{          
                                      legend: {display: false, position: 'right'},
                                      tooltip: {
                                        displayColors: false,
                                        backgroundColor: 'white',
                                        bodyColor: 'black',
                                        titleMarginBottom: 0,
                                        borderColor: '#969696',
                                        borderWidth: 1,
                                        titleColor: '#63666A',
                                        titleAlign: 'center',
                                        titleFont: {weight: '400'},
                                        displayColors: false,
                                        padding: 10,
                                        cornerRadius: 0,
                                        caretSize:5,
                                        bodyFont: {weight: 'bolder'}
                                      }
                                    },
                                 scales: {
                                        y: {
                                            grid: {
                                                display: false,
                                                borderColor: 'white'
                                            }
                                        }  
                                      }                                                                       
                                        
            }} data={data} />
        </div>
    );
};

export default HorizontalBarChart;