import { all, call, put, takeEvery } from "redux-saga/effects";
import { AdminViewURLS } from "../../constants/Administration/adminView-urls";
import { getAccessGroupAddDataService, getAccessGroupDataService, getAccessGroupEditDataService, getAccessGroupService, getAdminViewService, getAuditRBACDataSevice, getAuditRoleDataSevice, getEditUserDataService, getOperationsDataService, getRegionDataService, getTimeZoneDataService, getUPNValidationService, getUserflowCountSevice, getUserflowUsersSevice, updateAccessGroupAddEditService, updateAccessGroupDataService, updateAccessGroupDeleteService, updateAddUserService, updateDeleteUserService, updateEditUserService, updateOperationsDataService, updateOperationsDeleteService, updateRoleDeleteService } from "../../services/Administration/adminView.service";
import { adminViewActionTypes as actionTypes } from "./../../constants/Administration/adminView-actionTypes";
import { updateAccessGroupAddEditSuccess, updateAccessGroupDataSuccess, updateAccessGroupDeleteSuccess, updateAddUserSuccess, updateDeleteUserSuccess, updateEditUserSuccess, updateOperationsDataSuccess, updateOperationsDeleteSuccess, updateRoleDeleteSuccess } from "../../actions/Administration/adminView.action";



function* fetchAdminViewData(action) {
    try {
        const response = yield call(getAdminViewService, AdminViewURLS('GET_ADMIN_ROLES'));
        if (response.data) {
            yield put({
                type: actionTypes.ADMIN_ROLES,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while ADMIN Roles data", error);
    }
}

function* fetchAccessGroupData(action) {
    try {
        const response = yield call(getAccessGroupService, AdminViewURLS('GET_ACCESS_GROUP'));
        if (response.data) {
            yield put({
                type: actionTypes.ACCESS_GROUP,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while AccessGroup data", error);
    }
}

function* updateAccessGroupData({ payload: id }) {
    try {
        const response = yield call(updateAccessGroupDataService, AdminViewURLS('UPDATE_ACCESSGROUP_DATA'), id);
        if (response.data) {
            yield put(

                updateAccessGroupDataSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Role data", error);
    }
}

function* updateRoleDelete({ payload: id }) {
    try {
        const response = yield call(updateRoleDeleteService, AdminViewURLS('UPDATE_ROLE_DELETE'), id);
        if (response.data) {
            yield put(

                updateRoleDeleteSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Role Delete data", error);
    }
}


// OPERATIONS FLOW


function* fetchOperationsData(action) {
    try {
        const response = yield call(getOperationsDataService, AdminViewURLS('GET_OPERATIONS_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.OPERATIONS_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Operations data", error);
    }
}

function* updateOperationsData({ payload: id }) {
    try {
        const response = yield call(updateOperationsDataService, AdminViewURLS('UPDATE_OPERATIONS_DATA'), id);
        if (response.data) {
            yield put(

                updateOperationsDataSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating operations data", error);
    }
}

function* updateOperationsDelete({ payload: id }) {
    try {
        const response = yield call(updateOperationsDeleteService, AdminViewURLS('UPDATE_OPERATIONS_DELETE'), id);
        if (response.data) {
            yield put(

                updateOperationsDeleteSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating operations Delete data", error);
    }
}


// ACCESS GROUP FLOW


function* fetchAccessGroupMainPageData(action) {
    try {
        const response = yield call(getAccessGroupDataService, AdminViewURLS('GET_ACCESSGROUP_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.ACCESSGROUP_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Access Group data", error);
    }
}

function* fetchAccessGroupEditData({ accessgroupid }) {
    let items = {
        accessgroupid: accessgroupid,
    }
    try {
        const response = yield call(getAccessGroupEditDataService, AdminViewURLS('GET_ACCESSGROUP_EDIT_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.ACCESSGROUP_EDIT_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchAccessGroupAddData(action) {
    try {
        const response = yield call(getAccessGroupAddDataService, AdminViewURLS('GET_ACCESSGROUP_ADD_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.ACCESSGROUP_ADD_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Access Group ADD data", error);
    }
}

function* updateAccessGroupAddEdit({ payload: id }) {
    try {
        const response = yield call(updateAccessGroupAddEditService, AdminViewURLS('UPDATE_ACCESSGROUP_ADDEDIT'), id);
        if (response.data) {
            yield put(

                updateAccessGroupAddEditSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating AccessGroup data", error);
    }
}

function* updateAccessGroupDelete({ payload: id }) {
    try {
        const response = yield call(updateAccessGroupDeleteService, AdminViewURLS('UPDATE_ACCESSGROUP_DELETE'), id);
        if (response.data) {
            yield put(

                updateAccessGroupDeleteSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating AccessGroup Delete data", error);
    }
}

// USER FLOW

function* fetchTimeZoneData(action) {
    try {
        const response = yield call(getTimeZoneDataService, AdminViewURLS('GET_TIMEZONE_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.TIMEZONE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Time Zone data", error);
    }
}

function* updateAddUser({ payload: id }) {
    try {
        const response = yield call(updateAddUserService, AdminViewURLS('UPDATE_ADD_USER'), id);
        if (response.data) {
            yield put(

                updateAddUserSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating add user data", error);
    }
}

function* updateDeleteUser({ payload: id }) {
    try {
        const response = yield call(updateDeleteUserService, AdminViewURLS('UPDATE_DELETE_USER'), id);
        if (response.data) {
            yield put(

                updateDeleteUserSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating delete user data", error);
    }
}

function* fetchEditUserData({ userId }) {
    let items = {
        userId: userId,
    }
    try {
        const response = yield call(getEditUserDataService, AdminViewURLS('GET_EDIT_USER_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.EDIT_USER_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateEditUser({ payload: id }) {
    try {
        const response = yield call(updateEditUserService, AdminViewURLS('UPDATE_EDIT_USER'), id);
        if (response.data && response.data.statusCode === 200) {
            yield put(

                updateEditUserSuccess()

            );
          } else if(response.data && response.data.statusCode === 500) {
           
          alert('UPN already Exists. So Couldnt save the user details. please repeat the process with different UPN ')
          window.location.href = '/'
          }
          else{
            yield put(

                updateEditUserSuccess()

            );
            console.log(response)
          }
    } catch (error) {
        alert("Error while updating Edit user data", error);
    }
}

function* fetchRegionData(action) {
    try {
        const response = yield call(getRegionDataService, AdminViewURLS('GET_REGION_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.REGION_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Region data", error);
    }
}

function* fetchUPNValidation({ upn }) {
    let items = {
        upn: upn,
    }
    try {
        const response = yield call(getUPNValidationService, AdminViewURLS('GET_UPN_VALIDATION', items));
        if (response.data) {
            yield put({
                type: actionTypes.UPN_VALIDATION,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchUserflowUsers({ query,status }) {
    let items = {
        query: query,
        status:status
    }
    try {
        const response = yield call(getUserflowUsersSevice, AdminViewURLS('GET_USERFLOW_USERS', items));
        if (response.data) {
            yield put({
                type: actionTypes.USERFLOW_USERS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchUserflowCount({ status }) {
    let items = {
        status:status
    }
    try {
        const response = yield call(getUserflowCountSevice, AdminViewURLS('GET_USERFLOW_COUNT', items));
        if (response.data) {
            yield put({
                type: actionTypes.USERFLOW_COUNT,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

// AUDIT RBAC

function* fetchAuditRBACData({ role, accessGroup, operation }) {
    let items = {
        role: role,
        accessGroup: accessGroup,
        operation: operation
    }
    try {
        const response = yield call(getAuditRBACDataSevice, AdminViewURLS('GET_AUDITRBAC_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.AUDITRBAC_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchAuditRoleData({ role, account, site, user, region }) {
    let items = {
        type: actionTypes.GET_AUDITRBAC_DATA,
        role: role,
        account: account,
        site: site,
        user:user,
        region:region
      }
    try {
        const response = yield call(getAuditRoleDataSevice, AdminViewURLS('GET_AUDITROLE_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.AUDITROLE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}


function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_ADMIN_ROLES, fetchAdminViewData);
    yield takeEvery(actionTypes.GET_ACCESS_GROUP, fetchAccessGroupData);
    yield takeEvery(actionTypes.UPDATE_ACCESSGROUP_DATA_START, updateAccessGroupData);
    yield takeEvery(actionTypes.UPDATE_ROLE_DELETE_START, updateRoleDelete);

    // OPERATIONS FLOW

    yield takeEvery(actionTypes.GET_OPERATIONS_DATA,fetchOperationsData );
    yield takeEvery(actionTypes.UPDATE_OPERATIONS_DATA_START,updateOperationsData );
    yield takeEvery(actionTypes.UPDATE_OPERATIONS_DELETE_START, updateOperationsDelete);

    // ACCESS GROUP FLOW

    yield takeEvery(actionTypes.GET_ACCESSGROUP_DATA,fetchAccessGroupMainPageData );
    yield takeEvery(actionTypes.GET_ACCESSGROUP_EDIT_DATA,fetchAccessGroupEditData );
    yield takeEvery(actionTypes.GET_ACCESSGROUP_ADD_DATA,fetchAccessGroupAddData );
    yield takeEvery(actionTypes.UPDATE_ACCESSGROUP_ADDEDIT_START,updateAccessGroupAddEdit );
    yield takeEvery(actionTypes.UPDATE_ACCESSGROUP_DELETE_START,updateAccessGroupDelete );

    // USER FLOW

    yield takeEvery(actionTypes.GET_TIMEZONE_DATA,fetchTimeZoneData );
    yield takeEvery(actionTypes.UPDATE_ADD_USER_START, updateAddUser);
    yield takeEvery(actionTypes.UPDATE_DELETE_USER_START, updateDeleteUser);
    yield takeEvery(actionTypes.GET_EDIT_USER_DATA,fetchEditUserData );
    yield takeEvery(actionTypes.UPDATE_EDIT_USER_START, updateEditUser);
    yield takeEvery(actionTypes.GET_REGION_DATA, fetchRegionData);
    yield takeEvery(actionTypes.GET_UPN_VALIDATION, fetchUPNValidation);
    yield takeEvery(actionTypes.GET_USERFLOW_USERS, fetchUserflowUsers);
    yield takeEvery(actionTypes.GET_USERFLOW_COUNT, fetchUserflowCount);

    // AUDIT RBAC

    yield takeEvery(actionTypes.GET_AUDITRBAC_DATA, fetchAuditRBACData);

    // AUDIT ROLE
    
    yield takeEvery(actionTypes.GET_AUDITROLE_DATA, fetchAuditRoleData);


   

}
export default function* AdminViewSagas() {
    yield all([watchGetRequest()]);
}