import React, { useEffect } from 'react';
import './TeamKPISummary.css';
import AboveRequiredTargetIcon from "../../assets/images/AboveRequiredTarget_Status.png";
import BelowMinimalTargetIcon from "../../assets/images/BelowMinimalTarget_Status.png";
import { useHistory } from 'react-router-dom';
import { getTeamKPISummaryData } from '../../actions/ScoreCard&TeamKPI.action';
import { useDispatch, useSelector } from 'react-redux';
const TeamKPISummary = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let teamKPISummaryData = useSelector((state) => state.ScoreCardReducer.teamKPISummaryData);

    useEffect(() => {
        dispatch(getTeamKPISummaryData());
    }, [dispatch]);


  return (
    <div style={{marginTop:20}}>
        <div className='Teamkpi'>Team KPI Summary</div>
        <div className='CUST_EXP'>
            <div className='CustomerEXP'>Customer Experience</div>
            <div style={{display:'flex',flexWrap:'wrap'}}>
           {teamKPISummaryData?.[0]?.Details?.map((data,i) => 
            <div className='TeamKPI_box' key={i}>
                <div className='KPI_Title'>{data.Metric}</div>
                <div className='KPI_Percent'>{data.Value1}</div>
                <div className='KPI_Progress' style={{color: data.Color2 === 'Green' ? '#6CC24A' : '#EA333D'}}><img src ={data.Color2 === 'Green' ? AboveRequiredTargetIcon : BelowMinimalTargetIcon} alt=''/> {data.Value2}</div>
            </div>
           ) }
            </div>

          <main className='Agent_btn_placement'><div className='Agent_kpi_btn' style={{width:'280px',cursor:'pointer'}} onClick={()=>{history.push('/agentskpisummary')}}> EXPLORE AGENTS KPI SUMMARY </div></main>
        </div>
    </div>
  )
}

export default TeamKPISummary