
const getRoleId = () => {
    return localStorage.getItem('Role_id');
}
const getRoleName = () => {
    return localStorage.getItem('RoleSelected');
}

console.log('getRoleName',getRoleName())

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}
console.log('formatDate',formatDate(new Date()))

const welcomePageURLs = (name, ID) => {
    switch (name) {
        case 'GET_ROLES_TITLE': {
            return '/api/users/me/roles?wide=true'
        }
        case 'GET_WIDGET_INFO': {
            return '/api/users/me/widgets?wide=true'
        }

        case 'UPDATE_WIDGET_INFO': {
            return '/api/users/me/widgets'
        }

        case 'GET_RECENT_LINKS': {
            return '/api/users/me/recentqueries?wide=true'
        }

        case 'UPDATE_WIDGET_PREFERENCE_SHOWN': {
            return '/api/users/me/preferences'
        }
        case 'GET_WIDGET_PREFERENCE': {
            return '/api/users/me/preferences'
        }
        case 'GET_CAROUSEL_TITLE': {
            return '/api/feed/1?topn=5'
        }

        case 'GET_MULTI_ACCOUNTS_WIDGETS': {
            return `/api/users/me/widgets?roleid=${getRoleId()}`
        }

        case 'GET_MULTI_WIDGET_INFO': {
            return `/api/users/me/widgets?roleid=${getRoleId()}`
        }
        case 'UPDATE_MULTI_WIDGET': {
            return `/api/users/me/widgets?Roleid=${getRoleId()}`
        }

        case 'GET_ACCOUNT_SUMMARY': {
            return `/api/metrics/legacydtm/${ID.custID}/${ID.servID}/0?month=${formatDate(new Date())}&tabname=Summary`
        }
        case 'GET_ACCOUNT_DROPDOWN': {
            return `/api/users/me/widgets?roleid=${getRoleId()}&showsd=true`
        }
        case 'GET_STORED_ACCOUNT': {
            return `/api/users/me/preferences?roleid=${getRoleId()}`
        }
        case 'UPDATE_STORED_ACCOUNT': {
            return `/api/users/me/preferences?roleid=${getRoleId()}`
        }
        case 'GET_TASK_LINKS': {
            return `/api/usp/GetExpiredTasks?Role=${getRoleName()}`
        }
        default: { }
    }
}

export default welcomePageURLs;
