import React from "react";
import { Link } from "react-router-dom";
import './area-card.css';
import { Line } from "react-chartjs-2";
import { LineAreaChartModel, LineAreChartOptions } from "../chart-config";
import AboveRequiredTargetIcon from "../../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";

const EDashboardAreaCard = (props) => {
let defaultChartData = new LineAreaChartModel();    
if(props?.data?.Metric == ('Digital Trans (%)') || props?.data?.Metric == ('First Contact Resolution(%)') || props?.data?.Metric == ('Abondon Rate(%)') || props?.data?.Metric == ('Attrition(%)')){
  defaultChartData?.datasets[0]?.isPrcntSymbl.push(true);
}else{
  defaultChartData?.datasets[0]?.isPrcntSymbl.push(false);
}

props?.data?.Compliance?.map((itm, i)=>{
    defaultChartData?.labels.push(itm.YearMonth);
    defaultChartData?.datasets[0]?.data?.push(parseFloat(itm.Value1));
}); 
defaultChartData?.datasets[0]?.backgroundColor.push(props?.data?.ComplianceColour);
defaultChartData?.datasets[0]?.borderColor.push(props?.data?.Compliance[0].border);


const fixDecimals = (num) => {
    if(num && num.indexOf('%') > -1){
      return num;
    }else{
      return parseFloat(num).toFixed(2);
      // else
      // return parseFloat(num);
    }    
}

const fixDecimalVal2 = (num) => {
  if(num)
    return parseFloat(num).toFixed(2);
    else
    return parseFloat(num);
  }

const valClicked = (dt) => {
    props.valClicked(dt);
}


const getArrowMark = (color) => {
    switch(color){
      case '#6CC24A': {
        return (<img src={AboveRequiredTargetIcon} className="margn-5 mrgn-top-arrw-1"  alt="AbvRqdTgt Icon" />);
        }
      case 'Yellow' : {
        return (<img src={AboveMinimalTargetIcon}  className="margn-5 mrgn-top-arrw-1"  alt="AbvMnmlTgt Icon" />);
        }
      case '#EA333D' : {
        return(<img src={BelowMinimalTargetIcon}  className="margn-5 mrgn-top-arrw-1"  alt="BlwMnmlTgt Icon" />);
        }
      default: {
        return(<div></div>);
      }
    }
  }

const getValColor = (clr) => {
    switch(clr){
        case '#6CC24A': {
          return '#6CC24A';
          }
        case 'Yellow' : {
          return 'yellow';
          }
        case '#EA333D' : {
          return 'red';
          }
        default: {
          return(<div></div>);
        }
      }
}

const getReportURL = (metric) => {
    switch(metric){
        case 'AVG Handle Time(SEC)': {
          return '/aht';
          }
        case 'Digital Trans (%)' : {
          return '/digitalTrans';
          }
        case 'Contacts Per FTE' : {
          return '/fte';
          }
        case 'First Contact Resolution(%)': {
            return '/fcr';
        }
        case 'Abondon Rate(%)' : {
            return '/abrate';
        }
        case 'Cost Per Contact($)' : {
            return '/cpc';
        }
        case 'Penality at Risk($)' : {
            return '/penalty';
        }
        case 'Attrition(%)' : {
            return '/attrition';
        }
        default: {
          return;
        }
      }
}

    return (
        <div className="row area-card">
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-7">
                    <span className="area-card-hdr">{props?.data?.Metric}</span>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-5">
                    <div className="row">                        
                        <div className="col-sm-12 col-md-12 col-lg-12 text-end">                        
                            <span className="dash-rpt"></span><Link className="dash-line-color" to={getReportURL(props?.data?.Metric)}><h6 className="dash-line-color"><b>VIEW REPORT</b></h6></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-8 col-lg-6">                    
                    <div style={{marginBottom: '5px'}} onClick={()=>valClicked(props?.data)} className="area-card-val">{fixDecimals(props?.data?.Value1)}</div>
                    <div><span >{getArrowMark(props?.data?.Colour)}</span><span style={{color: getValColor(props?.data?.Colour)}} className="area-card-prcnt">{fixDecimalVal2(props.data.Value2)}%</span><span className="area-card-mnth">Last Month</span></div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-6 area-card-line-container" >
                    <Line data={defaultChartData} options={LineAreChartOptions} />
                </div>
            </div>
        </div>
    );
};

export default EDashboardAreaCard;