import React, { useEffect, useState } from 'react';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';

import { useHistory } from 'react-router-dom';
import './Audit.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessGroup, getAdminRoles, getAuditRBACData, getOperationsData } from '../../../../actions/Administration/adminView.action';
import Filter_Icon from './../../../../assets/images/Filter_Icon.png';
import Button from '../../../ReportTables/ReportFilters/Button';
import RefreshIcon from './../../../../assets/images/Reset_Icon.png';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';


const headers = [
    {
        caption: "Role",
        id: "1"
    }, {
        caption: "Access Group",
        id: "2"
    },
    {
        caption: "Operation",
        id: "3"
    }
]


const AuditRBAC = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let adminViewRoleData = useSelector((state) => state.adminViewReducer.adminViewRoleData);
    let accessGroupData = useSelector((state) => state.adminViewReducer.accessGroupData);
    let operationsMainData = useSelector((state) => state.adminViewReducer.operationsData);
    let auditRBACTableData = useSelector((state) => state.adminViewReducer.auditRBACData);
    useEffect(() => {
        dispatch(getAccessGroup());
        dispatch(getAdminRoles());
        dispatch(getOperationsData());

    }, [dispatch]);

    const [selectedRole, setselectedRole] = useState();
    const [selectedAccessGroup, setselectedAccessGroup] = useState();
    const [selectedOperation, setselectedOperation] = useState();
    const [isRefreshClicked, setIsRefreshedClicked] = useState(false);
    const [auditRBACData,setAuditRBACData] = useState([])

    useEffect(() => {
        if (selectedRole === undefined && selectedAccessGroup === undefined && selectedOperation === undefined) {
            setAuditRBACData([])
        }
        else{
        setAuditRBACData(auditRBACTableData)
        }
    }, [auditRBACTableData, selectedAccessGroup, selectedOperation, selectedRole]);
    

    

  console.log('selectedRole',selectedRole,selectedAccessGroup,selectedOperation)
    return (
        <section>
            <header>
                <AdminBreadcrumb value1={() => history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={() => history.push('/adminviewlanding')}
                    isActive3={false} label3='Audit RBAC'
                />
                <main className='administration_text'>Audit RBAC</main>
            </header>

            <div className='Filter_RBAC'>
            <main className='audit_filter_img'>
            <img src={Filter_Icon} alt='' style={{padding:'20px',paddingTop:'30px',paddingLeft:'30px'}}/>
            </main>
                <main className='Audit_dropdown'>
                    <label HtmlFor="role">Role</label>
                    <OnboardSelect data={adminViewRoleData} name='Role' value='Role Name' id='AccessGroupID' refresh={isRefreshClicked}
                        editValue='' addValueToQueue={(name,value) =>{setselectedRole(value); dispatch(getAuditRBACData(value,selectedAccessGroup ? selectedAccessGroup : '' , selectedOperation ? selectedOperation : ''));setIsRefreshedClicked(false)}} />
                </main>
                <main className='Audit_dropdown'>
                    <label HtmlFor="access">Access Group</label>
                    <OnboardSelect data={accessGroupData} name='AccessGroup' value='AccessGroup' id='RoleId' refresh={isRefreshClicked}
                        editValue='' addValueToQueue={(name,value) =>{setselectedAccessGroup(value); dispatch(getAuditRBACData(selectedRole ? selectedRole : '', value , selectedOperation ? selectedOperation : ''));setIsRefreshedClicked(false)}} />
                </main>
                <main className='Audit_dropdown'>
                    <label HtmlFor="access">Operation</label>
                    <OnboardSelect data={operationsMainData} name='Operation' value='OperationName' id='ID' refresh={isRefreshClicked}
                        editValue='' addValueToQueue={(name,value) =>{setselectedOperation(value); dispatch(getAuditRBACData(selectedRole ? selectedRole : '' , selectedAccessGroup ? selectedAccessGroup : '' , value));setIsRefreshedClicked(false)}} />
                </main>
                <main><div className='container_reset_request_admin' >
                                <Button className={`reset-icon download-btn `} item='Refresh Table'
                                    onClick={(event) => {
                                        setselectedRole(undefined);
                                        setselectedAccessGroup(undefined);
                                        setselectedOperation(undefined);
                                        setIsRefreshedClicked(true);
                                        setAuditRBACData([]);
                                    }} icon={RefreshIcon} /></div></main>
            </div>

           {auditRBACData.length === 0 ? <div className='Audit_nodata_card'>No records found.
           <br/> Use the filter options above to get relevant data.</div> : 
           
           <table style={{ width: '98%', marginTop: '40px' }} className='audit_table'>
            <thead>
            {headers.map(header => (
                            <th  key={header.id}><span >{header.caption}</span></th>
                        ))
                        }
            </thead>
            <tbody>
            {
               (auditRBACData).map((data, index) => (
                <tr>
                    <td >{data.role}</td>
                    <td >{data.accessGroup}</td>
                    <td>{data.operation}</td>
                </tr>
                ))
                    }
            </tbody>
        </table>}
        </section>
    )
}

export default AuditRBAC

