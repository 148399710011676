import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./doughnut-cards.css";
import { Doughnut  } from "react-chartjs-2";
import "chartjs-plugin-doughnut-innertext";
import { useHistory } from "react-router-dom";

const DoughnutCards = (props) => {

    const history = useHistory();
    let chartData;
    let firstval,secondVal;
    firstval = parseFloat(props?.data?.Value1.split('%')[0]) ? parseFloat(props?.data?.Value1.split('%')[0]) : 0;
    secondVal = (100 - firstval);
    chartData = [firstval, secondVal];
    const showData = chartData[0] + "%";
    const data1 = {
      labels: [],
      datasets: [
        {
          data: chartData,
          backgroundColor: [props?.data?.ComplianceColour, "#DDD"],
          borderColor: [props?.data?.ComplianceColour, "#DDD"]
        }
      ],
      text: showData
    };
    
    const options1 = {
      responsive: true,
      cutout: 47,
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontSize: 18
        }
      },
      centerText: {
        color: "#000",
        value: (props?.data?.Value1),
        fontSizeAdjust: 0 
      },
      layout: {
        padding: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        },
        margin: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plugins: {
        tooltip: {
                displayColors: false,
                backgroundColor: 'white',
                bodyColor: 'black',
                titleMarginBottom: 0,
                borderColor: '#969696',
                borderWidth: 1,
                titleColor: '#63666A',
                titleAlign: 'center',
                titleFont: {weight: '400'},
                displayColors: false,
                padding: 10,
                cornerRadius: 0,
                caretSize:5,
                bodyFont: {weight: 'bolder'},
            callbacks: {
              label: (context) => {
                  let label = "";             
                  if (context.parsed) {
                    label = (isNaN(context.parsed) ? context.parsed : context.parsed.toFixed(2)) + "%"
                  }
                  return label;                   
              }
            }
          }
    }
    };

    const viewRptClickd = (metric) => {
        props.viewRptClicked(metric);
    };

    const navigateToReport = (metric) => {
        switch(metric){
          case 'SLA Compliance' : {
            history.push('/sla'); break;
          }
          case 'CSAT' : {
            history.push('/csat'); break;
          }
          case 'Delivery Support': {
            history.push('/delivery'); break;
          }
          default: {}
        }
    };

    return (  
        <div className="card d-card rmv-brd doughnut-card">
        <div className="card-body d-card">
            <h5 className="card-title hdr-border d-card-title ps-4 dghnt-crd-hdr">{props?.data?.Metric}</h5>
            <div className="row" >
                <div  className="col-sm-12 col-md-12 col-lg-8">
                    <div className="row ps-4 dghnt-crd-lbl">
                        <h6>{props?.data?.Label1}</h6>
                    </div>
                    <div className="row douhgnut-card-container" onClick={()=>viewRptClickd(props?.data)}>
                        <Doughnut data={data1} options={options1} />
                    </div>
                </div>               
                <div  className="col-sm-12 col-md-12 col-lg-4">
                <div className="row dghnt-crd-lbl">
                    <h6>{props?.data?.Label2}</h6>
                </div>
                <div className="row">
                    <h6 className="dghnt-crd-val">{props?.data?.Value2}</h6>
                </div>
                <div className="row dghnt-crd-lbl mt-2">
                    <h6>{props?.data?.Label3}</h6>
                </div>
                <div className="row">
                    <h6 className="dghnt-crd-val">{props?.data?.Value3}</h6>
                </div>
                <div className="row dghnt-crd-lnk hnd-crs" onClick={()=>navigateToReport(props?.data?.Metric)}>
                <h6>VIEW REPORT</h6>
                </div>
                </div>
            </div>           
        </div>
        </div>        
    );
};

export default DoughnutCards;