import { all, call, put, takeEvery } from "redux-saga/effects";
import actionTypes from "../constants/actionTypes";
import reportConstructURLs from "../constants/reportURL";
import { getFinanceAccountService, getFinanceDataService, getFinanceDropdownService } from '../services/finance.service'

function* fetchFinanceData({ financeID, filterID }) {
    try {
        const response = yield call(getFinanceDataService, reportConstructURLs('GET_FINANCE_DATA', financeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.FINANCE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Finance data", error);
    }
}

function* fetchDropdownData(action) {
    try {
        const response = yield call(getFinanceDropdownService, reportConstructURLs('GET_DROPDOWN_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.DROPDOWN_DATA,
                payload: response.data.filter,
            });
        }
        console.log(response.data)
    } catch (error) {
        console.log("Error while fetching Finance data", error);
    }
}

function* fetchAccountData({ financeID, filterID }) {
    try {
        const response = yield call(getFinanceAccountService, reportConstructURLs('GET_ACCOUNT_DATA', financeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_DATA,
                payload: response.data.filter,
            });
        }
    } catch (error) {
        console.log("Error while fetching Finance data", error);
    }
}



function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_FINANCE_DATA, fetchFinanceData)
    yield takeEvery(actionTypes.GET_DROPDOWN_DATA, fetchDropdownData)
    yield takeEvery(actionTypes.GET_ACCOUNT_DATA, fetchAccountData)


}
export default function* financeSagas() {
    yield all([watchGetRequest()]);
}