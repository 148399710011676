import React, { useState } from 'react'
import { Accordion, ListGroup } from 'react-bootstrap'
import './DeveloperGuidelines.css';
import VerticalLine from './../../../../assets/images/VerticalLine_Headings.png';
import CopyToClipboard from './../../../../assets/github-images/CopyToClipboard.png';
import Developerguidelines from './../../../../assets/github-images/DeveloperGuidelines_TyporaDefendorerror.png';
import FORK_REPO from './../../../../assets/github-images/FORK_REPO.png';
import DeveloperGuidelines_Summary from './../../../../assets/github-images/DeveloperGuidelines_Summary.png';
import Developer_Guidelines_pushorigin from './../../../../assets/github-images/Developer_Guidelines_pushorigin.png';
import fetchorigin from './../../../../assets/github-images/DeveloperGuidelines_fetchorigin.png';
import syncupstreammaster from './../../../../assets/github-images/DeveloperGuidelines_syncupstreammaster.png';
import ToolTip from 'react-power-tooltip';

const DEDICATED_TOOLS = [
    { id: '1', title: "Visual Studio 2017 / 2019" },
    { id: '2', title: "SQL Server Management Studio" },
    { id: '3', title: "Exchange Web Services" },
    { id: '4', title: "Powershell" }
]

const GENERIC_ONES = [
    { id: '1', title: "Windows RDP" },
    { id: '2', title: "Notepad++" },
    { id: '3', title: "IIS Manager" },
    { id: '4', title: "GitHub Desktop" },
    { id: '5', title: "Visual Studio Code" },
    { id: '6', title: "Typora" }
]
const CODE_ETIQUETTE = [
    { id: '1', caption: "Acknowledge that our Development Environment is shared amongst multiple developers." },
    { id: '2', caption: "If you want to enhance (either because of a new project requirement - or a break fix scenario) - please talk with the other developers + the Lead developers. If they are not available - please seek guidance from the project team leaders/managers. " },
    { id: '3', caption: "We encourage review and scrutiny of ALL code in GSD Insight. We need to ensure that your suggestions do not conflict and will not potentially break other components that you may not be aware of." },
    { id: '4', caption: "Approaching the lead developers with suggested code already well thought out is desirable." },
    { id: '5', caption: "Try to write code in a manner consistent with existing code. Study the standard coding practices to ensure alignment. Code that looks like everyone is aware of consistency is much easier to maintain." },
    { id: '6', caption: "Check your code into git (git add ; git commit ) regularly (note this needs to be done as your user and not root). Don’t check in broken code - so choose your moments carefully. " },
    { id: '7', caption: "You write code - YOU are responsible for keeping the Developers Wiki up-to-date." },
    { id: '8', caption: "Release to production is controlled. Normally weekly. Don’t release stuff outside of this process without approval from project manager (or his delegate). Break-Fix releases still require approval (and are normally approved)." }
]
const NAMING_CONVENTIONS = [
    { id: '1', title: "Module names are lowercase_with_underscores" },
    { id: '2', title: "Constants are UPPERCASE_WITH_UNDERSCORES" },
    { id: '3', title: "Package wide variables are Mixed_Case_With_Underscores" },
    { id: '4', title: "Function variables are lowercase_with_underscores" }
]
const BASIC_REFERENCES = [
    { id: '1', caption: "Term" },
    { id: '2', caption: "Description" }
]
const BASIC_REFERENCES_DATA = [
    { id: '1', 'Term': 'upstream/master', 'Description': 'This is the master branch of the upstream repo. In our case this is Platform-DXC/intelligence-oc This is final location for our content and where our users will reference the articles from' },
    { id: '2', 'Term': 'clone', 'Description': 'This is the local copy of your forked repo. It will reside on your PC at the location that you have chosen. eg: documents/intelligence-oc . This the the location that you will locally edit/add markdown files.' },
    { id: '3', 'Term': 'forked repo', 'Description': 'This process requires you to fork the Platform-DXC/intelligence-oc repo to a repo that you own. This is where your github desktop will push its origin to' },
    { id: '4', 'Term': 'Pull request', 'Description': 'This is the process by which you initiate the changes in your forked repo to the upstream/master. This process involves you initating the PULL request - and an approver approving the merge. Depending on what you have changed - this approver may need to resolve merge conflicts.' }
]

const MARKDOWN_FILES = [
    { id: '1', caption: "Location of Developer articles (.md files)" },
    { id: '2', caption: "docs/developer/" }
]
const MARKDOWN_FILES_DATA = [
    { id: '1', 'location': 'Location of Developer images (.png files)', 'docs': 'docs/developer/images/' },
    { id: '2', 'location': 'Report manuals are named', 'docs': 'report_manual_*.md' },
    { id: '3', 'location': 'Markdown case', 'docs': 'Lower case – no spaces' },
    { id: '4', 'location': 'Markdown references', 'docs': './images/…. Use relative referencing for github content' }
]
const MARKDOWN_LIST = [
    { id: '1', title: "I created a new business_overview.md file (and took that section out of architecture.md)" },
    { id: '2', title: "Added this new md file to docs/README.md (as it is one of the few really important ones)" },
    { id: '3', title: "Added this new md file to docs/developers/README.md" }
]
const DeveloperGuidelines = () => {
    const [tooltipText, setTooltipText] = useState(false);
    const [showUseStrictTooltip,setShowUseStrictTooltip] = useState(false);
    const [showUseStrict2Tooltip,setShowUseStrict2Tooltip] = useState(false);
    const [showPreferredTooltip,setShowPreferredTooltip] = useState(false);
    const [showNormalIfTooltip,setShowNormalIfTooltip] = useState(false);
    const [showDoubleIfTooltip,setShowDoubleIfTooltip] = useState(false);
    
    function copyElementToClipboard(element) {
        window.getSelection().removeAllRanges();
        let range = document.createRange();
        range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
      }

      const APP_SECURITY = 'https://dxcportal.sharepoint.com/sites/GSDInsight/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FApplications%20Security%20Standards%20DXC%2Epdf&parent=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation';
      const SECURE_CODING ='https://dxcportal.sharepoint.com/sites/GSDInsight/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FOWASP%5FSCP%5FQuick%5FReference%5FGuide%5Fv2%2Epdf&parent=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation';
      const GITHUB_DESKTOP ='https://desktop.github.com/';
      const TYPORA ='https://www.typora.io/';
      const VISUAL_STUDIO ='https://code.visualstudio.com/docs/?dv=win64';
      const MASTER_REPO ='https://github.dxc.com/MWSGSD/GSD-Insight';
      const CLONED_REPOSITORY ='https://github.dxc.com/rgraham33/intelligence-oc';
      const GITHUB_DOCUMENTATION ='https://github.dxc.com/pages/Platform-DXC/devcloud-docs/github/';
      const SECURITY_TRAINING ='https://dxcportal.sharepoint.com/:b:/r/sites/GSDInsight/Shared Documents/General/Documentation/Security Trainings.pdf?csf=1&web=1&e=NE5FAM'



    return (
        <>
            <main className='accordion_handle' >
            <div style={{width:1200,margin:'auto'}}>
                <div className='Infrastructure'>Developer Guidelines</div>
                <div style={{ width: '98%'}}>
                    <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                        <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Application Security Standards</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <div className='title_security_standard'>The Application Security Standards document is located here:</div>
                                    <div className='link_security_standard' onClick={() => window.open(APP_SECURITY, '_blank')}> Applications Security Standards DXC.pdf</div>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Secure Coding Guideline</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <div className='title_security_standard'>OWASP Secure Coding Practices Quick Reference Guide is located here:</div>
                                    <div className='link_security_standard' onClick={() => window.open(SECURE_CODING, '_blank')}> OWASP_SCP_Quick_Reference_Guide_v2.pdf</div>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={2} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Security Training Plan</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <div className='title_security_standard'>The Security Training Plan for developers is located here: </div>
                                    <div className='link_security_standard' onClick={() => window.open(SECURITY_TRAINING, '_blank')}>Security Training Plan.pdf</div>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={3} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Coding Standards and Etiquette</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section className='code_etiquette'>
                                        <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Developers Etiquitte</main>
                                        <div style={{ width: '72%' }}>GSD Insight is currently developed in a SHARED development environment. Therefore the we ask each and every developer to tred carefully and respectfully. We currently have NOT worked out a way of each developer to have their own development environment in which they can clone standard code and proceed to crash and burn any way they like…</div>
                                        <div id='header_text'>Until we change our practices PLEASE FOLLOW these guidelines:</div>
                                        <div style={{ marginLeft: -3 }}><ol>{CODE_ETIQUETTE.map((data, index) => (<li>{data.caption}</li>))}</ol></div>
                                        <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Coding Standards</main>
                                        <div id='strict_enabled'>Strictness Enabled</div>
                                        <div>Enable the following at the top of your scripts. This prevents you from making spelling mistakes in variable names that would otherwise go un-noticed.</div>
                                        <main className='copy_list_box' style={{ display: 'flex' }}>
                                            <div id='UseStrict'>use strict;</div>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('UseStrict');setTooltipText('Copied')}} onMouseOver={() => {setShowUseStrictTooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowUseStrictTooltip(false)}/>
                                            <ToolTip show={showUseStrictTooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                        </main>
                                        <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Naming Conventions</main>
                                        <div>There isn’t an official style document but perlstyle offers some advice on naming conventions, and there are some other conventions:</div>
                                        <ol>
                                            {NAMING_CONVENTIONS.map((data, index) => (<li className='latin_li'>{data.title}</li>))}
                                        </ol>
                                        <main>Eg:</main>
                                        <main className='copy_list_box' style={{ display: 'flex' }}>
                                        <div id='UseStrict2'>sub function_name <span>&#x7B;</span> my ($var1, $var2, $var3) = @_; <span>&#x7D;</span></div>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('UseStrict2');setTooltipText('Copied')}} onMouseOver={() => {setShowUseStrict2Tooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowUseStrict2Tooltip(false)}/>
                                            <ToolTip show={showUseStrict2Tooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                        </main>
                                        <main>Is preferred over:</main>
                                        <main className='copy_list_box' style={{ display: 'flex' }}>
                                            <div id='PreferredOver'>sub functionName <span>&#x7B;</span> my $var1 = @_[0]; my $var2 = @_[1]; my $var3 = @_[3]; <span>&#x7D;</span></div>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('PreferredOver');setTooltipText('Copied')}} onMouseOver={() => {setShowPreferredTooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowPreferredTooltip(false)}/>
                                            <ToolTip show={showPreferredTooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                        </main>
                                        <main id='strict_enabled' style={{ marginTop: 20 }}>Brackets</main>
                                        <main>1. Normal If statement - noting position of <span>&#x7B;</span></main>
                                        <main className='copy_list_box' style={{ display: 'flex' }}>
                                            <div id='NormalIf'>if ($a eq “something”) <span>&#x7B;</span><div>&#x7D;</div></div>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('NormalIf');setTooltipText('Copied')}} onMouseOver={() => {setShowNormalIfTooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowNormalIfTooltip(false)}/>
                                            <ToolTip show={showNormalIfTooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                        </main>
                                        <main>2. Double line if statement - noting position of <span>&#x7B;</span></main>
                                        <main className='copy_list_box' style={{ display: 'flex' }}>
                                            <div id='DoubleIf'>if (($a eq “something” and $b eq “stuff”) or <br/>($d eq “nothing”)) <div>&#x7B;</div> <div>&#x7D;</div></div>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('DoubleIf');setTooltipText('Copied')}} onMouseOver={() => {setShowDoubleIfTooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowDoubleIfTooltip(false)}/>
                                            <ToolTip show={showDoubleIfTooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                        </main>
                                    </section>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={4} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Create Wiki Article</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section className='code_etiquette'>
                                        <div id='strict_enabled'># Creating and updating content </div>
                                        <div>This article describes the process of creating and updating documentation for GSD Insight on GitHub. </div><br />
                                        <div>This solution is currently centered around the use of GitHub Desktop and Typora / Visual Studio Code. This allows users to get going with their documentation requirements without the need for extreme git command line skills (which notably all of you who are DevOps trained would be perfectly capable of doing!)</div><br />
                                        <div>Before venturing into the process and where to get the tools from - this is a reminder that anytime you start your journey of updating or creating an article - please make sure you have sync’d your local clone with the upstream master. You are NOT the only person contributing to these repositories and therefore it is important to ensure that you START when-ever possible - with the latest commits from the upstream master. If you DONT do this - then your PULL request is likely to be denied with suitable appended comments. (NOTE the section on how to do this in the article below)</div><br />
                                        <div>Remember too - basic references relate to</div><br />
                                        <main>  <table className='basic_table' style={{ width: '96%' }}>
                                            <thead><tr>
                                                {BASIC_REFERENCES.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='basic_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {BASIC_REFERENCES_DATA.map((data, index) => (<tr key={data.id}>
                                                    <td className='basic_td'>{data?.Term}</td>
                                                    <td className='basic_td'>{data?.Description}</td></tr>))} </tbody>
                                        </table></main>
                                        <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Download and Install</main>
                                        <div>This process has been designed around the use of desktop UI tools to perform the git processes. It uses github desktop to perform the cloning and the push, pull functions. Clearly this process could be implemented using native git commands.</div><br />
                                        <div>To start : Download the following applications and follow their native installation instructions to complete their install:</div>
                                        <div className='order_list_style'> <ol>
                                            <li id='list_cursor' onClick={() => window.open(GITHUB_DESKTOP, '_blank')}>GitHub Desktop</li>
                                            <li id='list_cursor' onClick={() => window.open(TYPORA, '_blank')}>Typora</li>
                                            <li id='list_cursor' onClick={() => window.open(VISUAL_STUDIO, '_blank')}>Visual Studio Code for Windows x64</li>
                                        </ol></div>
                                        <main>Note: On DXC COE PC’s you may encounter a “Windows Defender Error” while installing TYPORA:</main><br/>
                                        <div>Eg:</div>
                                        <img src={Developerguidelines} alt='' style={{marginTop:20,marginBottom:20}}/>
                                        <div>To remediate this: <b>right click</b> on the .exe file - go to <b>Properties</b> - check the <b>Unblock</b> option and hit <b>Apply</b> and rerun the installation, it will work.</div>
                                        <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Fork and Clone the Master Repository</main>
                                         <div>This step will require you to fork your master repo (<span id='git_link_style' onClick={() => window.open(MASTER_REPO, '_blank')}>https://github.dxc.com/MWSGSD/GSD-Insight</span>) to make a repo that is owned by you. This will be the location from which you will CLONE the repo to your local PC and the location you will in the future initiate PULL requests from when you have completed your updates.</div><br/>
                                         <div>To fork the master repo navigate to <span id='git_link_style' onClick={() => window.open(MASTER_REPO, '_blank')}>https://github.dxc.com/MWSGSD/GSD-Insight.</span> Many of you may have already performed this function previously and you will be able to see the name of your fork if you click on the number next to the “fork” icon. </div><br/>
                                         <div>This typically will be in the form of https://github.dxc.com//intelligence-oc</div><br/>
                                         <div>If you don’t already have the forked repo – do so… (Again: Please make sure you are forking the repo from <span id='git_link_style' onClick={() => window.open(MASTER_REPO, '_blank')}>https://github.dxc.com/MWSGSD/GSD-Insight)</span></div>
                                         <img src={FORK_REPO} alt=''/>
                                         <main id='strict_enabled'>Navigate to your forked repo:</main>
                                         <ol>
                                            <li>Click <b>Clone or Download</b> (selecting <b>Open In Desktop</b>).</li>
                                            <li>Open Github desktop client and go to <b>File &gt; Clone Repository</b></li>
                                            <li>In confirmation dialog, select Open Github Desktop app.</li>
                                            <li>Github desktop should launch with a <b>Clone Repository</b> dialog box and show location of where it wants to save it</li>
                                            <li>Click <b>URL</b> tab and then paste in the clone URL</li>
                                            <li>Click <b>Clone</b> </li>
                                         </ol>
                                         <div>You should now be ready to create and edit the markdown files. <br/>(I put the clone on my local in documents/GitHub/. You may have chosen an alternate location)<br/> You are now ready to navigate to your local directory and edit (eg: open each .md file with Typora or Visual Studio Code)</div>
                                         <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Markdown Standards</main>
                                         <div>The following table shows a short example of some standards that we should ALL follow when editing and creating our markdown files.</div>
                                         <main>  <table className='basic_table' style={{ width: '96%' }}>
                                            <thead><tr>
                                                {MARKDOWN_FILES.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='basic_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {MARKDOWN_FILES_DATA.map((data, index) => (<tr key={data.id}>
                                                    <td className='basic_td'>{data?.location}</td>
                                                    <td className='basic_td'>{data?.docs}</td></tr>))} </tbody>
                                        </table></main>
                                        <main id='strict_enabled' style={{marginTop:20}}>Example</main>
                                        <ol>
                                            {MARKDOWN_LIST.map((data, index) => (<li className='latin_li'>{data.title}</li>))}
                                        </ol>
                                          <div>These changes are automatically detected by github desktop<br/> Within github desktop – commit to master (put a summary in first)</div>
                                          <img src={DeveloperGuidelines_Summary} alt='' style={{marginTop:20,marginBottom:20}}/>
                                          <div>And then click on <b>Commit to master</b> and <b>push origin</b> (this box should disappear when done)</div>
                                          <img src={Developer_Guidelines_pushorigin} alt='' style={{marginTop:20,marginBottom:20}}/>
                                          <div style={{marginBottom:20}}>You should be able to verify your changes in your CLONED git repo <br/>Once your happy with all of your changes – and you want them pulled into the master repo – create a <b>pull request</b> </div>
                                          <ol>
                                            <li className='latin_li'>Go to your cloned repository eg: <span className='link_security_standard' onClick={() => window.open(CLONED_REPOSITORY, '_blank')}>https://github.dxc.com/rgraham33/intelligence-oc</span></li>
                                            <li className='latin_li'>Select <b>Pull requests</b> tab</li>
                                            <li className='latin_li'>Select <b>New Pull request”</b></li>
                                            <li className='latin_li'>Select <b>Create Pull</b> request”</li>
                                            <li className='latin_li'>Enter your title, and select <b>create pull request</b></li>
                                        </ol>
                                        <div>Team process will approve or reject your pull request based on the review results.</div>
                                        <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Syncing your CLONE with the upstream/master repo</main>
                                        <div>Remember: YOU ARE NOT the ONLY person contributing to the repo. It is highly likely - if not 100% guaranteed - the upstream/master branch will be several commits INFRONT of your clone. You should endeavor to sync your clone with the upstream/master before commencement of your amendments/additions.</div><br/>
                                        <div>To do this - within your github desktop application - follow these instructions (VERY CAREFULLY) </div><br/>
                                        <div>Click on the ‘current branch’ tab and first select ‘master’ as the current branch (if it’s not already selected). Click on the ‘fetch origin’ button. </div>
                                        <div>Click on the ‘current branch’ tab again and click the ‘choose a branch to merge into master’ button at the bottom.</div>
                                        <img src={fetchorigin} alt='' style={{marginTop:20,marginBottom:20}}/>
                                        <div>* NOTE: Looking down this list, you will find 2 entries for every branch. Those that are prefixed with origin/ are the branches in your fork, and those prefixed with upstream/ are those in the original repository on GitHub. Select upstream/master from this list (and click merge upstream master button), and this pull the changes down from the master repository to bring your local clone up to date.</div>
                                        <img src={syncupstreammaster} alt='' style={{marginTop:20,marginBottom:20}}/>
                                        <div>Once your local clone has finished pulling the updates from the master repo - your local PC (aka the clone) will contain all of the latest files from the upstream/master. You may now proceed with editing. </div><br/>
                                        <div>You can choose to push all of these changes to your forked repo NOW or AFTER you have finished your changes.</div><br/>
                                        <div>To push these new changes to your fork, stored on GitHub, using the push origin button on GitHub desktop. (once push function complete - this button disappears).</div><br/>
                                        <div>Refer <span className='link_security_standard' onClick={() => window.open(GITHUB_DOCUMENTATION, '_blank')}>GitHub Documentation</span> for more details about DXC GitHub & DevOps documentation.</div>
                                    </section>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={5} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Tools We Use</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section className='tools_we_use'>
                                        <div className='dedicated_tools'><ListGroup variant="flush">
                                            <ListGroup.Item className='title_generic'><span className='tools_title'>Dedicated Tools</span></ListGroup.Item>
                                            {DEDICATED_TOOLS.map((list, index) => (<ListGroup.Item className='list_font_size'>{list.title}</ListGroup.Item>))}
                                        </ListGroup></div>
                                        <div className='dedicated_tools'><ListGroup variant="flush">
                                            <ListGroup.Item className='title_generic'><span className='tools_title'>Generic Ones</span></ListGroup.Item>
                                            {GENERIC_ONES.map((list, index) => (<ListGroup.Item className='list_font_size'>{list.title}</ListGroup.Item>))}
                                        </ListGroup></div>
                                    </section>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div></div>
            </main>

        </>
    )
}

export default DeveloperGuidelines