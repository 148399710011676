import React , {useState, useEffect} from "react";
import { useSelector, useDispatch} from "react-redux";
import LegendComponent from "../../Legend_CSV/legend";
import Box from "../../Box/box";
import BredCrumb from "../../Breadcrumb/bredcrumb";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "../../Insights/insights.css";
import "./regional-summ.css";
import { updateCalendarDateFormat, updateCalendarISOStringFormat, updateCalendarDate } from "../../../actions/user.action";
import ConstructRegionalSiteURLs from "../../../constants/regional-site-url";
import {getRegionalSiteGlobalSummaryData} from "../../../actions/regionalSite.action";

const RegionalSummary = () => {

  const dispatch = useDispatch();
  const [sites, setSites] = useState([]); 
  const [brdData, setBrdData] = useState([]);

  const [locationName, setLocationName] = useState(''); 
  const [selectedAccLocationName, setSelectedAccLocationName] = useState('');

  
  const globalSummaryData = useSelector((state) => state.regionalSiteReducer.rgnGlobal_Regional_Summary);
  const selectedAccCustID = useSelector((state) => state.regionalSiteReducer.rgnCustId);
  const selectedAccCustName = useSelector((state) => state.regionalSiteReducer.rgnCustName);  
  const selectedDate = useSelector((state) => state.userReducer.cal_dt);
  const breadCrumbData = useSelector((state) => (state.userReducer.breadCrumbArr));
  const selectedRegionName = useSelector((state) => state.regionalSiteReducer.regionName);
  const isoDateString = useSelector((state) => state.userReducer.cal_isostring_frmt);
  const regionID = useSelector((state) => state.regionalSiteReducer.regionID);  

  const getClientCustID = () => {    
    return selectedAccCustID;
  };

  useEffect(()=>{
    if(selectedAccCustName && selectedRegionName)
    setLocationName(`Global Accounts - ${selectedAccCustName}`);
    setSelectedAccLocationName(`Regional Summary - ${selectedRegionName} `);
    updateBreadCrumb(selectedDate);
  },[selectedAccCustName, selectedRegionName]);


  useEffect(()=>{
    if(selectedAccLocationName && selectedAccLocationName)    
    updateBreadCrumb(selectedDate);
  },[locationName, selectedAccLocationName]);

  const dateToYMD = (date) => {
    var strArray = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return m + " " + y;
  };

  const handleDateChange = (e) => {
    dispatch(updateCalendarDate(new Date(e)));
    dispatch(updateCalendarDateFormat(dateToYMD(new Date(e))));
    dispatch(updateCalendarISOStringFormat(new Date(e).toISOString().split('T')[0]));    
    dispatch(getRegionalSiteGlobalSummaryData(selectedAccCustID,0,regionID,isoDateString));
    updateBreadCrumb(new Date(e));
  };

  useEffect(()=>{
    console.log(globalSummaryData);
    if(globalSummaryData && globalSummaryData?.servicedesks?.length > 0){      
      setSites(globalSummaryData.servicedesks);  
    }
  },[globalSummaryData]);


  const updateBreadCrumb = (dt) => {
    let tmpArr = [];
    tmpArr.push(breadCrumbData[0]);
    let rptObj = {name : 'Reports', reDirect: '/regional' };
    tmpArr.push(rptObj);     
    let brdData = locationName;
    let obj = {name : brdData, reDirect: '/regional_global_accounts' };
    tmpArr.push(obj);      
    let gsmmry = selectedAccLocationName + '- ' + dateToYMD(dt);   
    let gsmmryObj = {name : gsmmry, reDirect: '' };  
    tmpArr.push(gsmmryObj);
    setBrdData(tmpArr);
  };  

  const regnlSummaryBtnClickHandler = () => {
    let obj = {};
    obj.cust_id = 0;
    obj.serv_id = 0;
    obj.regn_id = regionID;    
    let dwnldURL = ConstructRegionalSiteURLs("DOWNLOAD_REGIONALSITE_GLOBAL_SUMMARY_DATA",obj,isoDateString);   
    window.location.href = dwnldURL;
  }

  return (
    <div>     
      <div className="gsd-main-container">
        <div className="rgnl-cntr">
          <BredCrumb data={brdData}     />
          <div className="client-row">
            <div className="row hd">
              <div className="client-icon">
                <img
                  className="client-icon-img"
                  type="image/jpeg"
                  src={`${
                    process.env.REACT_APP_BASE_URL
                  }/api/org/customer/${getClientCustID()}/photo`}
                  alt="Client Logo"
                />
              </div>
              <div className="client-name">               
                {selectedAccCustName}
                <span className="border-right"></span>
              </div>
              <div className="client-date">
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  label="Select Month"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <div className="regional-section">
            <div className="row">
              <div className="col-md-4 regional-label">
                {`Regional Summary - ${selectedRegionName}`}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 regional-text">
                {(sites.length >  1) ? `( ${sites.length} Sites: ${sites.join(' | ')} )` : `( ${sites.length} Site: ${sites.join(' | ')} )` }
              </div>
            </div>
          </div>
          <LegendComponent shwRgnlSmmryBtn={true} regnlSummaryBtnClicked={regnlSummaryBtnClickHandler}></LegendComponent>
          <div style={{ height: "45px" }}></div>
          <div className="flex-container">
            {globalSummaryData?.blocks ?
                    globalSummaryData?.blocks?.map((itm, i)=>{
                            return <Box key={i} data={itm} SLAReportHandleClick={()=>{}}/>
                    }) : []
            }  
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionalSummary;
