import { all, call, put, takeEvery } from "redux-saga/effects";
import { welcomePage_actionTypes as actionTypes } from "../constants/welcomePage-actionTypes";
import welcomePageURLs from "../constants/welcomePage-urls";

import { getRoleSTitleService, getWidgetInfoService, updateWidgetInfoService, getRecentLinksService, updateWidgetPreferenceShownService, getWidgetPreferenceService, getMultiAccountWidgetService, setMultiWidgetInfoService, getMultiWidgetInfoService, getAccountSummaryService, getLatestUpdatesService, createLatestUpdatesService, updateLatestUpdatesService, deleteLatestUpdatesService, getAccountDropdownService, getStoredAccountService, updateStoredAccountService } from "../services/welcomePage.service";
import { createLatestReleasesSuccess, deleteLatestReleasesSuccess, getLatestReleasesSuccess, updateMultiWidgetSuccess, updateStoredAccountSuccess, updateWidgetInfoSuccess, WidgetPreferenceShown } from "../actions/welcomePage.action";




function* fetchRolesTitleData(action) {
    try {
        const response = yield call(getRoleSTitleService, welcomePageURLs('GET_ROLES_TITLE'));
        if (response.data) {
            yield put({
                type: actionTypes.ROLES_TITLE,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Roles Title  data", error);
    }
}


function* fetchWidgetInfo(action) {
    try {
        const response = yield call(getWidgetInfoService, welcomePageURLs('GET_WIDGET_INFO'));
        if (response.data) {
            yield put({
                type: actionTypes.WIDGET_INFO,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Widget data", error);
    }
}

function* fetchRecentLinks(action) {
    try {
        const response = yield call(getRecentLinksService, welcomePageURLs('GET_RECENT_LINKS'));
        if (response.data) {
            yield put({
                type: actionTypes.RECENT_LINKS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching recentlinks data", error);
    }
}



function* updateSelectedWidgetInfo({ payload: id }) {
    try {
        const response = yield call(updateWidgetInfoService, welcomePageURLs('UPDATE_WIDGET_INFO'), id);
        if (response.data) {
            yield put(
               
                updateWidgetInfoSuccess()

            );
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchWidgetPreference() {
    try {
        const response = yield call(getWidgetPreferenceService, welcomePageURLs('GET_WIDGET_PREFERENCE'));
        if (response.data) {
            yield put(
                {
                    type: actionTypes.WIDGET_PREFERENCE,
                    payload: response.data,
                }
            );
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}


function* fetchWidgetPreferenceShown({ payload: data }) {
    try {
        const response = yield call(updateWidgetPreferenceShownService, welcomePageURLs('UPDATE_WIDGET_PREFERENCE_SHOWN'), data);
        if (response.data) {
            yield put(
                
                WidgetPreferenceShown(),
            );
        }
    } catch (error) {
        console.log("Error while fetching Widget data", error);
    }
}


function* fetchCarouselTitle(action) {
    try {
        const response = yield call(getRecentLinksService, welcomePageURLs('GET_CAROUSEL_TITLE'));
        if (response.data) {
            yield put({
                type: actionTypes.CAROUSEL_TITLE,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching carousal title", error);
    }
}


function* fetchMultiAccountWidgets(action) {
    try {
        const response = yield call(getMultiAccountWidgetService, welcomePageURLs('GET_MULTI_ACCOUNTS_WIDGETS'));
        if (response.data) {
            yield put({
                type: actionTypes.MULTI_ACCOUNTS_WIDGETS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Multi Account", error);
    }
}


function* fetchMultiWidgetsInfo(action) {
    try {
        const response = yield call(getMultiWidgetInfoService, welcomePageURLs('GET_MULTI_WIDGET_INFO'));
        if (response.data) {
            yield put({
                type: actionTypes.MULTI_WIDGET_INFO,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Widget data", error);
    }
}

function* updateMultiWidgetInfo({ payload: id }) {
    try {
        const response = yield call(setMultiWidgetInfoService, welcomePageURLs('UPDATE_MULTI_WIDGET'), id);
        if (response.data) {
            yield put(
                
                updateMultiWidgetSuccess()

            );
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchAccountSummaryInfo({ custID,servID }) {
    let items = {
        custID: custID, servID: servID
    }
    try {
        const response = yield call(getAccountSummaryService, welcomePageURLs('GET_ACCOUNT_SUMMARY', items));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_SUMMARY,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Account Summary", error);
    }
}


function* fetchLatestUpdatesInfo(action) {
    try {
        const response = yield call(getLatestUpdatesService);
        if (response.status === 200) {
            yield put(getLatestReleasesSuccess(response.data));
        }
    } catch (error) {
        console.log("Error while fetching Account Summary", error);
    }
}
function* createLatestUpdatesInfo({ payload: { channel, data } }) {
    try {
        const response = yield call(createLatestUpdatesService, channel, data);
        if (response.data) {
            yield put(createLatestReleasesSuccess());
        }
    } catch (error) {
        console.log("Error while fetching Account Summary", error);
    }
}

function* updateLatestUpdatesInfo({ payload: { channel, serial, data } }) {
    try {
        const response = yield call(updateLatestUpdatesService, channel, serial, data);
        if (response.data) {
            yield put(updateWidgetInfoSuccess());
        }
    } catch (error) {
        console.log("Error while fetching Account Summary", error);
    }
}

function* deleteLatestUpdatesInfo({ payload: { channel, serial } }) {
    try {
        const response = yield call(deleteLatestUpdatesService, channel, serial);
        if (response.data) {
            yield put(deleteLatestReleasesSuccess());
        }
    } catch (error) {
        console.log("Error while fetching Account Summary", error);
    }
}

function* fetchRolesTabRolesData() {
    try {
        const response = yield call(getAccountDropdownService, welcomePageURLs('GET_ACCOUNT_DROPDOWN'));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_DROPDOWN,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Account Dropdown  data", error);
    }
}

function* fetchStoredAccount(action) {
    try {
        const response = yield call(getStoredAccountService, welcomePageURLs('GET_STORED_ACCOUNT'));
        if (response.data) {
            yield put({
                type: actionTypes.STORED_ACCOUNT,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Stored Account  data", error);
    }
}

function* updateStoredAccount({ payload: id }) {
    try {
        const response = yield call(updateStoredAccountService, welcomePageURLs('UPDATE_STORED_ACCOUNT'), id);
        if (response.data) {
            yield put(
               
                updateStoredAccountSuccess()

            );
        }
    } catch (error) {
        console.log("Error while updating STORED ACCOUNT data", error);
    }
}

function* fetchTaskLinks(action) {
    try {
        const response = yield call(getStoredAccountService, welcomePageURLs('GET_TASK_LINKS'));
        if (response.data) {
            yield put({
                type: actionTypes.TASK_LINKS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Task Links  data", error);
    }
}

function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_ROLES_TITLE, fetchRolesTitleData);
    yield takeEvery(actionTypes.GET_WIDGET_INFO, fetchWidgetInfo);
    yield takeEvery(actionTypes.UPDATE_WIDGET_INFO_START, updateSelectedWidgetInfo);
    yield takeEvery(actionTypes.GET_RECENT_LINKS, fetchRecentLinks);
    yield takeEvery(actionTypes.UPDATE_WIDGET_PREFERENCE_SHOWN, fetchWidgetPreferenceShown);
    yield takeEvery(actionTypes.GET_WIDGET_PREFERENCE, fetchWidgetPreference);
    yield takeEvery(actionTypes.GET_CAROUSEL_TITLE, fetchCarouselTitle)
    yield takeEvery(actionTypes.GET_MULTI_ACCOUNTS_WIDGETS, fetchMultiAccountWidgets);
    yield takeEvery(actionTypes.GET_MULTI_WIDGET_INFO, fetchMultiWidgetsInfo);
    yield takeEvery(actionTypes.UPDATE_MULTI_WIDGET_START, updateMultiWidgetInfo);
    yield takeEvery(actionTypes.GET_ACCOUNT_SUMMARY, fetchAccountSummaryInfo);
    yield takeEvery(actionTypes.GET_LATEST_RELEASES_START, fetchLatestUpdatesInfo);
    yield takeEvery(actionTypes.CREATE_LATEST_RELEASES_START, createLatestUpdatesInfo);
    yield takeEvery(actionTypes.UPDATE_LATEST_RELEASES_START, updateLatestUpdatesInfo);
    yield takeEvery(actionTypes.DELETE_LATEST_RELEASES_START, deleteLatestUpdatesInfo);
    yield takeEvery(actionTypes.GET_ACCOUNT_DROPDOWN, fetchRolesTabRolesData);
    yield takeEvery(actionTypes.GET_STORED_ACCOUNT, fetchStoredAccount);
    yield takeEvery(actionTypes.UPDATE_STORED_ACCOUNT_START,updateStoredAccount );
    yield takeEvery(actionTypes.GET_TASK_LINKS, fetchTaskLinks);


}
export default function* welcomePageSagas() {
    yield all([watchGetRequest()]);
}