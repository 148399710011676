export const STAFF_ADMIN_ACTIONTYPES = {
    GET_AVAILABLE_EMPLOYEES: "GET_AVAILABLE_EMPLOYEES",
    AVAILABLE_EMPLOYEES: "AVAILABLE_EMPLOYEES",

    GET_SUPERVISOR_LIST: "GET_SUPERVISOR_LIST",
    SUPERVISOR_LIST: "SUPERVISOR_LIST",

    UPDATE_NEW_USER_ROLE_START: "UPDATE_NEW_USER_ROLE_START",
    UPDATE_NEW_USER_ROLE_SUCCESS: "UPDATE_NEW_USER_ROLE_SUCCESS",

    UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_START: "UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_START",
    UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_SUCCESS: "UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_SUCCESS",

    UPDATE_ACCOUNT_EMPLOYEE_START: "UPDATE_ACCOUNT_EMPLOYEE_START",
    UPDATE_ACCOUNT_EMPLOYEE_SUCCESS: "UPDATE_ACCOUNT_EMPLOYEE_SUCCESS",

    GET_ROLE_CATEGORY: "GET_ROLE_CATEGORY",
    ROLE_CATEGORY: "ROLE_CATEGORY",

    GET_STAFF_ACCOUNTS: "GET_STAFF_ACCOUNTS",
    STAFF_ACCOUNTS: "STAFF_ACCOUNTS",

    GET_ACCOUNT_ASSIGNMENT: "GET_ACCOUNT_ASSIGNMENT",
    ACCOUNT_ASSIGNMENT: "ACCOUNT_ASSIGNMENT",

    GET_QUEUE_ASSIGNMENT: "GET_QUEUE_ASSIGNMENT",
    QUEUE_ASSIGNMENT: "QUEUE_ASSIGNMENT",

    GET_ID_ASSIGNMENT: "GET_ID_ASSIGNMENT",
    ID_ASSIGNMENT: "ID_ASSIGNMENT",

    GET_SLA_ASSIGNMENT: "GET_SLA_ASSIGNMENT",
    SLA_ASSIGNMENT: "SLA_ASSIGNMENT",

    GET_VOLUME_ASSIGNMENT: "GET_VOLUME_ASSIGNMENT",
    VOLUME_ASSIGNMENT: "VOLUME_ASSIGNMENT",

    UPDATE_QUEUE_ASSIGNMENT_START:"UPDATE_QUEUE_ASSIGNMENT_START",
    UPDATE_QUEUE_ASSIGNMENT_SUCCESS:"UPDATE_QUEUE_ASSIGNMENT_SUCCESS",

    UPDATE_ID_ASSIGNMENT_START:"UPDATE_ID_ASSIGNMENT_START",
    UPDATE_ID_ASSIGNMENT_SUCCESS:"UPDATE_ID_ASSIGNMENT_SUCCESS",

    UPDATE_SLA_ASSIGNMENT_START:"UPDATE_SLA_ASSIGNMENT_START",
    UPDATE_SLA_ASSIGNMENT_SUCCESS:"UPDATE_SLA_ASSIGNMENT_SUCCESS",

    UPDATE_VOLUME_ASSIGNMENT_START:"UPDATE_VOLUME_ASSIGNMENT_START",
    UPDATE_VOLUME_ASSIGNMENT_SUCCESS:"UPDATE_VOLUME_ASSIGNMENT_SUCCESS",

    GET_STAFF_PREVIEW: "GET_STAFF_PREVIEW",
    STAFF_PREVIEW: "STAFF_PREVIEW",

    GET_MAPPED_EMPLOYEES: "GET_MAPPED_EMPLOYEES",
    MAPPED_EMPLOYEES: "MAPPED_EMPLOYEES",

    GET_QUEUE_DATA: "GET_QUEUE_DATA",
    QUEUE_DATA: "QUEUE_DATA",

    GET_VOLUME_TEMPLATES:"GET_VOLUME_TEMPLATES",
    VOLUME_TEMPLATES:"VOLUME_TEMPLATES",

    GET_ACCOUNT_SLA:"GET_ACCOUNT_SLA",
    ACCOUNT_SLA:"ACCOUNT_SLA",

    GET_ID_ACCOUNTS:"GET_ID_ACCOUNTS",
    ID_ACCOUNTS:"ID_ACCOUNTS",

    GET_SLA_ACCOUNTS:"GET_SLA_ACCOUNTS",
    SLA_ACCOUNTS:"SLA_ACCOUNTS",

    GET_VALID_SUPERVISOR:"GET_VALID_SUPERVISOR",
    VALID_SUPERVISOR:"VALID_SUPERVISOR",

    GET_ACCOUNT_SLA_FOREDIT:"GET_ACCOUNT_SLA_FOREDIT",
    ACCOUNT_SLA_FOREDIT:"ACCOUNT_SLA_FOREDIT",

    GET_VOLUME_TEMPLATES_FOREDIT:"GET_VOLUME_TEMPLATES_FOREDIT",
    VOLUME_TEMPLATES_FOREDIT:"VOLUME_TEMPLATES_FOREDIT",

    SAVE_SUPERVISOR_MAPPING :"SAVE_SUPERVISOR_MAPPING",
    
}