import actionTypes from "../constants/actionTypes";
import RegionalSiteActionTypes from "../constants/regional-site-actionTypes";

export const getRegionalSiteData = () => ({
    type: actionTypes.GET_REGIONALSITE_DATA,
});

export const getRegionalSiteCustIDData = (cust_id, dt) => ({
    type: RegionalSiteActionTypes.GET_REGIONALSITE_CUSTID_DATA,
    custID: cust_id,
    dt: dt
});

export const updateRegionalSiteCustomerID = (Cust_id) => ({
    type: RegionalSiteActionTypes.UPDATE_CUSTOMER_ID,
    payload: Cust_id
});

export const updateRegionalSiteCustomerName = (Cust_Name) => ({
    type: RegionalSiteActionTypes.UPDATE_CUSTOMER_NAME,
    payload: Cust_Name
});

export const updateRegionalSiteServiceDeskID = (Service_id) => ({
    type: RegionalSiteActionTypes.UPDATE_SERVICEDESK_ID,
    payload: Service_id
});

export const getRegionalSiteGlobalSummaryData = (cust_id, serv_id, regn_id, dt) => ({
    type: RegionalSiteActionTypes.GET_REGIONALSITE_GLOBAL_SUMMARY_DATA,
    custID: cust_id,
    servID: serv_id,
    regnID: regn_id,
    dt: dt
});

export const updateRegionName = (Regn_Name) => ({
    type: RegionalSiteActionTypes.UPDATE_REGION_NAME,
    payload: Regn_Name
});

export const updateDrpdwnRegionName = (Regn_Name) => ({
    type: RegionalSiteActionTypes.UPDATE_DRPDWN_REGION_NAME,
    payload: Regn_Name
});

export const updateRegionID = (Regn_ID) => ({
    type: RegionalSiteActionTypes.UPDATE_REGION_ID,
    payload: Regn_ID
});

export const updateDropownSlctdRgnsList = (Regns) => ({
    type: RegionalSiteActionTypes.UPDATE_DRPDWN_REGIONS_LIST,
    payload: Regns
});

export const updateSlcdAccrdPnlID = (panelID) => ({
    type: RegionalSiteActionTypes.UPDATE_SELECTED_ACCRD_PANEL_ID,
    payload: panelID
});


export const getDTMAnalyticsData = (data) => ({
    type: RegionalSiteActionTypes.GET_DTM_ANALYTICS,
    data: data
});


export const updateSelectedSLA = (sla) => ({
    type: RegionalSiteActionTypes.UPDATE_SELECTED_SLA,
    payload: sla
});


export const getDTMAnalyticsHalfHour = (data) => ({
    type: RegionalSiteActionTypes.GET_DTM_ANALYTICS_HALF_HOUR,
    data: data
});

export const getDTMAnalyticsMonth = (data) => ({
    type: RegionalSiteActionTypes.GET_DTM_ANALYTICS_MONTH,
    data: data
});
