import actionTypes from "../constants/actionTypes";


const initialState = {
    financeData: {},
    dropdownData: {},
    accountData: {}
}
const getFinanceData = (state, action) => {
    console.log("Reducer - get Finance data" + action);
    return {
        ...state,
    }
};
const updateFinanceData = (state, action) => {
    console.log('Reducer -Update Finance Data ' + action)
    return {
        ...state,
        financeData: action.payload
    };
};

const getDropdownData = (state, action) => {
    console.log("Reducer - get Finance data" + action);
    return {
        ...state,
    }
};
const updateDropdownData = (state, action) => {
    console.log('Reducer -Update Finance Data ' + action)
    return {
        ...state,
        dropdownData: action.payload
    };
};

const getAccountData = (state, action) => {
    console.log("Reducer - get Finance data" + action);
    return {
        ...state,
    }
};
const updateAccountData = (state, action) => {
    console.log('Reducer -Update Finance Data ' + action)
    return {
        ...state,
        accountData: action.payload
    };
};



const financeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_FINANCE_DATA:
            return getFinanceData(state, action);
        case actionTypes.FINANCE_DATA:
            return updateFinanceData(state, action);
        case actionTypes.GET_DROPDOWN_DATA:
            return getDropdownData(state, action);
        case actionTypes.DROPDOWN_DATA:
            return updateDropdownData(state, action);
        case actionTypes.GET_ACCOUNT_DATA:
            return getAccountData(state, action);
        case actionTypes.ACCOUNT_DATA:
            return updateAccountData(state, action);

        default:
            return state;
    }


}


export default financeReducer