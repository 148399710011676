import React, { useEffect, useState } from 'react';
import './EmployeeDetails.css';
import Account_Assignment_HoverStateIcon from "../../../../assets/admin-images/Account_Assignment_HoverStateIcon.png";
import Account_Assignment_NormalStateIcon from "../../../../assets/admin-images/Account_Assignment_NormalStateIcon.png";
import AWSQueueLanguage_Assignment_HoverStateIcon from "../../../../assets/admin-images/AWSQueueLanguage_Assignment_HoverStateIcon.png";
import AWSQueueLanguage_Assignment_NormalStateIcon from "../../../../assets/admin-images/AWSQueueLanguage_Assignment_NormalStateIcon.png";
import ID_Assignment_HoverStateIcon from "../../../../assets/admin-images/ID_Assignment_HoverStateIcon.png";
import ID_Assignment_NormalStateIcon from "../../../../assets/admin-images/ID_Assignment_NormalStateIcon.png";
import Preview_HoverStateIcon from "../../../../assets/admin-images/Preview_HoverStateIcon.png";
import Preview_NormalStateIcon from "../../../../assets/admin-images/Preview_NormalStateIcon.png";
import SLA_KPI_AssignmentWeighting_HoverStateIcon from "../../../../assets/admin-images/SLA_KPI_AssignmentWeighting_HoverStateIcon.png";
import SLA_KPI_AssignmentWeighting_NormalStateIcon from "../../../../assets/admin-images/SLA_KPI_AssignmentWeighting_NormalStateIcon.png";
import Volumetric_Normal_Icon from "../../../../assets/admin-images/Volumetric_Normal_Icon.png";
import Volumetric_Hover_Icon from "../../../../assets/admin-images/Volumetric_Selected_Icon.png";
import dataSaveClose_icon from "../../../../assets/onboard-images/dataSaveClose_icon.png";
import dataSave_icon from "../../../../assets/onboard-images/dataSave_icon.png";
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import Newemployee from './Newemployee';
import EmployeeTabHandler from '../Tab-components/EmployeeTabHandler';
import PreviewTab from '../Tab-components/PreviewTab';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAccountAssignment, getIdAssignment, getQueueAssignment, getQueueData, getSlaAssignment, getStaffPreview, getVolumeAssignment, updateAccountEmployeeStart, updateIdAssignmentStart, updateQueueAssignmentStart, updateSlaAssignmentStart } from '../../../../actions/Administration/staffAdmin.action';
import { ActivateModelPopup, AdminCancelModel, AdminSubmitModel, UnsavedModel } from '../Employee-Assignment/AssignModel';
import { updateVolumeAssignmentStart } from './../../../../actions/Administration/staffAdmin.action';
import { AdminNextModel } from './../Employee-Assignment/AssignModel';
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';
import { CancelModalPopup, NextButtonFragment } from '../../../Onboard/OnboardComponents/Modals/OnboardButtonsPopup';

function Image(props) {
  return (<>
    <button className='staff_nav_btn' onClick={props.onClick} style={{ borderBottom: props.isSelected && '4px solid  #5F249F' }} onMouseOver={props.onMouseEnter} onMouseLeave={props.onMouseLeave} >
      <img src={props.src} alt='' className={props.imgclass} />
      <div id='staff' className='nav-text-staff' style={{ color: props.isSelected && 'black' }} >{props.label}</div>
      {props.children}
    </button>
  </>)
}

const BUTTONS = [
  { id: 1, label: 'PREVIOUS' },
  { id: 2, label: 'SAVE' },
  { id: 3, label: 'CANCEL' },
  { id: 4, label: 'NEXT' },
  { id: 5, label: 'SUBMIT' },

]


function EmployeeDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const supervisorInfo = useSelector((state) => state.staffAdminReducer.supervisorInfo);
  const accountAssignmentStaff = useSelector((state) => state.staffAdminReducer.accountAssignmentStaff);
  const idAssignment = useSelector((state) => state.staffAdminReducer.idAssignment);
  const queueAssignment = useSelector((state) => state.staffAdminReducer.queueLists);
  const staffPreview = useSelector((state) => state.staffAdminReducer.staffPreview);
  const slaAssignment = useSelector((state) => state.staffAdminReducer.slaAssignment);
  const volumeAssignment = useSelector((state) => state.staffAdminReducer.volumeAssignment);
  const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);

  useEffect(() => {
    dispatch(getRoleBasedView())
  }, [dispatch])
  // const updatedAccountResponse = useSelector((state) => state.staffAdminReducer.updatedAccountResponse);
  // const updatedQueueResponse = useSelector((state) => state.staffAdminReducer.updatedQueueResponse);
  // const updatedIDResponse = useSelector((state) => state.staffAdminReducer.updatedIDResponse);
  // const updatedSLAResponse = useSelector((state) => state.staffAdminReducer.updatedSLAResponse);
  // const updatedVolumeResponse = useSelector((state) => state.staffAdminReducer.updatedVolumeResponse);


  //const [generalTab, setGeneralTab] = useState(supervisorInfo?.view === 'edit' ? 'preview' : 'account');
  const [generalTab, setGeneralTab] = useState('preview')
  const [accountImgSrc, setAccountImgSrc] = useState();
  const [AWSImgSrc, setAWSImgSrc] = useState();

  const [SLAImgSrc, setSLAImgSrc] = useState();
  const [IDImgSrc, setIDImgSrc] = useState();
  const [VolumeImgSrc, setVolumeImgSrc] = useState();
  const [previewImgSrc, setPreviewImgSrc] = useState();
  const [showButtons, setShowButtons] = useState([0, 1, 1, 1, 0]);
  const [dataSaveInfo, setDataSaveInfo] = useState(false);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  // const [showCanceldModal, setShowCancelModal] = useState(false);
  // const [showSubmitModal, setShowSubmitModal] = useState(false);

  const [accountUpdatedData, setAccountUpdatedData] = useState([])
  const [queueUpdatedData, setQueueUpdatedData] = useState([])
  const [IDUpdatedData, setIDUpdatedData] = useState([])
  const [SLAUpdatedData, setSLAUpdatedData] = useState([])
  const [VolumeUpdatedData, setVolumeUpdatedData] = useState([])
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const [accountNotCreated, setAccountNotCreated] = useState(false);
 // const [accountNotSubmitted, setAccountNotSubmitted] = useState(false);
 const [showUnsavedNextModal,setShowUnsavedNextModal] = useState(false);
 const [showUnsavedPreviousModal,setShowUnsavedPreviousModal] = useState(false);
 const [showUnsavedbreadcrumbModal,setShowUnsavedbreadcrumbModal] = useState(false);

 const [accountModifiedData,setAccountModifiedData] = useState([]);
 const [queueModifiedData,setQueueModifiedData] = useState([]);
 const [IDModifiedData,setIDModifiedData] = useState([]);
 const [SLAModifiedData,setSLAModifiedData] = useState([]);
 const [VolumeModifiedData,setVolumeModifiedData] = useState([]);

 // const [showUnsavedData,setShowUnsavedData] = useState(false);

  const[compareValidTo,setcompareValidTo] = useState('');
  const dispatchNavigationHandle = () => {
    dispatch(getAccountAssignment(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
    dispatch(getQueueAssignment(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
    dispatch(getIdAssignment(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
    dispatch(getSlaAssignment(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
    dispatch(getVolumeAssignment(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
    dispatch(getStaffPreview(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
  }


  useEffect(() => {
    switch (generalTab) {
      case 'account': {
        setAccountImgSrc(Account_Assignment_HoverStateIcon);
        setAWSImgSrc(AWSQueueLanguage_Assignment_NormalStateIcon);
        setSLAImgSrc(SLA_KPI_AssignmentWeighting_NormalStateIcon);
        setIDImgSrc(ID_Assignment_NormalStateIcon);
        setVolumeImgSrc(Volumetric_Normal_Icon);
        setPreviewImgSrc(Preview_NormalStateIcon);
        setShowButtons([0, 1, 1, 1, 0]);
        dispatchNavigationHandle();
        break;
      }
      case 'queue': {
        setAccountImgSrc(Account_Assignment_NormalStateIcon);
        setAWSImgSrc(AWSQueueLanguage_Assignment_HoverStateIcon);
        setSLAImgSrc(SLA_KPI_AssignmentWeighting_NormalStateIcon);
        setIDImgSrc(ID_Assignment_NormalStateIcon);
        setVolumeImgSrc(Volumetric_Normal_Icon);
        setPreviewImgSrc(Preview_NormalStateIcon)
        setShowButtons([1, 1, 1, 1, 0]);
        dispatch(getQueueData(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
        dispatchNavigationHandle();
        break;
      }
      case 'assign': {
        setAccountImgSrc(Account_Assignment_NormalStateIcon);
        setAWSImgSrc(AWSQueueLanguage_Assignment_NormalStateIcon);
        setSLAImgSrc(SLA_KPI_AssignmentWeighting_NormalStateIcon);
        setIDImgSrc(ID_Assignment_HoverStateIcon);
        setVolumeImgSrc(Volumetric_Normal_Icon);
        setPreviewImgSrc(Preview_NormalStateIcon);
        setShowButtons([1, 1, 1, 1, 0]);
        dispatchNavigationHandle();
        break;
      }
      case 'slakpi': {
        setAccountImgSrc(Account_Assignment_NormalStateIcon);
        setAWSImgSrc(AWSQueueLanguage_Assignment_NormalStateIcon);
        setSLAImgSrc(SLA_KPI_AssignmentWeighting_HoverStateIcon);
        setIDImgSrc(ID_Assignment_NormalStateIcon);
        setVolumeImgSrc(Volumetric_Normal_Icon);
        setPreviewImgSrc(Preview_NormalStateIcon);
        setShowButtons([1, 1, 1, 1, 0]);
        dispatchNavigationHandle();
        break;
      }
      case 'volume': {
        setAccountImgSrc(Account_Assignment_NormalStateIcon);
        setAWSImgSrc(AWSQueueLanguage_Assignment_NormalStateIcon);
        setSLAImgSrc(SLA_KPI_AssignmentWeighting_NormalStateIcon);
        setIDImgSrc(ID_Assignment_NormalStateIcon);
        setVolumeImgSrc(Volumetric_Hover_Icon);
        setPreviewImgSrc(Preview_NormalStateIcon);
        setShowButtons([1, 1, 1, 1, 0]);
        dispatchNavigationHandle();
        break;
      }
      case 'preview': {
        setAccountImgSrc(Account_Assignment_NormalStateIcon);
        setAWSImgSrc(AWSQueueLanguage_Assignment_NormalStateIcon)
        setSLAImgSrc(SLA_KPI_AssignmentWeighting_NormalStateIcon);
        setIDImgSrc(ID_Assignment_NormalStateIcon);
        setVolumeImgSrc(Volumetric_Normal_Icon);
        setPreviewImgSrc(Preview_HoverStateIcon);
        setShowButtons([1, 0, 1, 0, 0]);
        dispatchNavigationHandle();
        break;
      }
      default: { }
    }
  }, [generalTab]);
  const generaltabRows = ['account', 'queue', 'assign', 'slakpi','volume', 'preview'];
  const ObjectEquateHandle = (data, updatedData) => {
    if (data?.length === updatedData?.length) {
      const eq = JSON.stringify(data) === JSON.stringify(updatedData)
      return eq;
    }
    else {
      return false;
    }
  }
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
};

  useEffect(() => {
    let tempacc = [...accountAssignmentStaff];
        let arr1 = tempacc.map(arr => ({
                        Customer: arr?.['account Name'],
                        CustomerType: arr.customerType,
                        ValidFrom: formatDate(arr.validFrom),
                        ValidTo: formatDate(arr.validTo),
                        ID: typeof arr.ID === 'string' ? '' : arr.id
        }))
        setAccountModifiedData(arr1)
        return arr1
}, [accountAssignmentStaff])

useEffect(() => {
  let tempqueue = [...queueAssignment];
      let arrqueue = tempqueue.map(arr => ({
                        Customer: arr.account,
                        QueueName: arr.queueName,
                        Language: arr.language,
                        ValidFrom: formatDate(arr?.validFrom),
                        ValidTo: formatDate(arr?.validTo),
                        ID: typeof arr.ID === 'string' ? '' : arr.id
      }))
      setQueueModifiedData(arrqueue)
      return arrqueue
}, [queueAssignment])

useEffect(() => {
  let tempid = [...idAssignment];
      let arrid = tempid.map(arr => ({
        Customer: arr.customer,
        IDAssigned: arr.idAssigned,
        SystemName: arr.systemName,
        ValidFrom: formatDate(arr?.validFrom),
        ValidTo: formatDate(arr?.validTo),
        ID: typeof arr.ID === 'string' ? '' : arr.id
      }))
      setIDModifiedData(arrid)
      return arrid
}, [idAssignment])

useEffect(() => {
  let tempsla = [...slaAssignment];
      let arrsla = tempsla.map(arr => ({
        Customer: arr.account,
        Target: arr.target,
        Weighting: arr.weighting,
        ValidFrom: formatDate(arr.validFrom),
        ValidTo: formatDate(arr.validTo),
        AccountSLAID: arr.accountSLAID,
        ID: typeof arr.ID === 'string' ? '' : arr.id
      }))
     setSLAModifiedData(arrsla)
     return arrsla
}, [slaAssignment])

useEffect(() => {
  let tempVolume = [...volumeAssignment];
      let arrVolume = tempVolume.map(arr => ({
        Customer: arr.account,
        Target: arr.target,
        Weighting: arr.weighting,
        ValidFrom: formatDate(arr.validFrom),
        ValidTo: formatDate(arr.validTo),
        AccountVolumetricID : arr.accountVolumetricTempalteID,
        ID: typeof arr.ID === 'string' ? '' : arr.id
      }))
     setVolumeModifiedData(arrVolume)
     return arrVolume
}, [volumeAssignment])
  

 const nextModelNohandler = () =>{
  let index = generaltabRows.indexOf(generalTab);
    let currentTab = generaltabRows[index + 1];
    setGeneralTab(currentTab);
    setShowUnsavedNextModal(false);
    setAccountUpdatedData(accountAssignmentStaff)
  setQueueUpdatedData(queueAssignment)
  setIDUpdatedData(idAssignment)
  setSLAUpdatedData(slaAssignment)
  setVolumeUpdatedData(volumeAssignment)
 }

 const previousModelNohandler = () => {
  let index = generaltabRows.indexOf(generalTab);
    let currentTab = generaltabRows[index - 1];
    setGeneralTab(currentTab);
    setShowUnsavedPreviousModal(false)
    setAccountUpdatedData(accountAssignmentStaff)
  setQueueUpdatedData(queueAssignment)
  setIDUpdatedData(idAssignment)
  setSLAUpdatedData(slaAssignment)
  setVolumeUpdatedData(volumeAssignment)
 }
const saveButtonHandler = () => {
if(supervisorInfo.view === "edit"){
  setDataSaveInfo(true)
}
else{
   if (isAccountCreated) {
    setDataSaveInfo(true);
    setAccountNotCreated(false);
   }  else{ setAccountNotCreated(true)}
  }


    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    switch (generalTab) {
      case 'account': dispatch(updateAccountEmployeeStart(accountUpdatedData));
        break;
      case 'queue':
        dispatch(updateQueueAssignmentStart(queueUpdatedData)); break;
      case 'assign':
        dispatch(updateIdAssignmentStart(IDUpdatedData)); break;
      case 'slakpi': dispatch(updateSlaAssignmentStart(SLAUpdatedData)); break;
      case 'volume': dispatch(updateVolumeAssignmentStart(VolumeUpdatedData)); break;

      default: { }
    }
    setShowUnsavedModal(false);
    setShowUnsavedNextModal(false);
    setShowUnsavedPreviousModal(false);
    let index = generaltabRows.indexOf(generalTab);
    let currentTab = generaltabRows[index + 1];
    setGeneralTab(currentTab);
    dispatchNavigationHandle();
    setAccountUpdatedData(accountAssignmentStaff)
  setQueueUpdatedData(queueAssignment)
  setIDUpdatedData(idAssignment)
  setSLAUpdatedData(slaAssignment)
  setVolumeUpdatedData(volumeAssignment)
  }
  const validationCancelHandle = (response, resolve) => {
    if (ObjectEquateHandle(response, resolve)) {
     // setShowCancelModal(true);
     history.push('/admin/staff')
      setShowUnsavedModal(false);
    }
    else {
    //  setShowCancelModal(false);
      setShowUnsavedModal(true);
    }
  }

  const cancelButtonHandler = () => {
    switch (generalTab) {
      case 'account': validationCancelHandle(accountModifiedData, accountUpdatedData?.[0]?.Details); break;
      case 'queue': validationCancelHandle(queueModifiedData, queueUpdatedData?.[0]?.Details); break;
      case 'assign': validationCancelHandle(IDModifiedData, IDUpdatedData?.[0]?.Details); break;
      case 'slakpi': validationCancelHandle(SLAModifiedData, SLAUpdatedData?.[0]?.Details); break;
      case 'volume': validationCancelHandle(VolumeModifiedData, VolumeUpdatedData?.[0]?.Details); break;
      case 'preview': validationCancelHandle(1,1); break;
      default: { }
    }
  }

  const validationNextHandle = (response, resolve) => {
    if (ObjectEquateHandle(response, resolve)) {
     // saveButtonHandler();
      let index = generaltabRows.indexOf(generalTab);
      let currentTab = generaltabRows[index + 1];
      setGeneralTab(currentTab);
    }
    else {
     // setShowCancelModal(false);
      setShowUnsavedNextModal(true);
    }
  }


  const nextButtonHandler = () => {
    switch (generalTab) {
      case 'account': validationNextHandle(accountModifiedData, accountUpdatedData?.[0]?.Details); break;
      case 'queue': validationNextHandle(queueModifiedData, queueUpdatedData?.[0]?.Details); break;
      case 'assign': validationNextHandle(IDModifiedData, IDUpdatedData?.[0]?.Details); break;
      case 'slakpi': validationNextHandle(SLAModifiedData, SLAUpdatedData?.[0]?.Details); break;
      case 'volume': validationNextHandle(VolumeModifiedData, VolumeUpdatedData?.[0]?.Details); break;
      default: { }
    }
  }

  
  const [showTabModal, setShowTabModal] = useState(false);
  const [tabForYesButton, setTabForYesButton] = useState(null);

  let accountValidation = accountUpdatedData?.[0]?.Details === undefined ? true : ObjectEquateHandle(accountModifiedData, accountUpdatedData?.[0]?.Details);
  let queueValidation = queueUpdatedData?.[0]?.Details === undefined ? true : ObjectEquateHandle(queueModifiedData, queueUpdatedData?.[0]?.Details);
  let IdValidation = IDUpdatedData?.[0]?.Details === undefined ? true : ObjectEquateHandle(IDModifiedData, IDUpdatedData?.[0]?.Details);
  let SLAValidation = SLAUpdatedData?.[0]?.Details === undefined ? true : ObjectEquateHandle(SLAModifiedData, SLAUpdatedData?.[0]?.Details);
  let volumeValidation = VolumeUpdatedData?.[0]?.Details === undefined ? true : ObjectEquateHandle(VolumeModifiedData, VolumeUpdatedData?.[0]?.Details)

let TabChangeValidation = accountValidation && queueValidation && IdValidation && SLAValidation && volumeValidation
// console.log('accountValidation',accountModifiedData, accountUpdatedData?.[0]?.Details,accountValidation)
const staffAdminTabButtonHandle = (tab) => {
  if(!TabChangeValidation){
    setShowTabModal(true);
    setTabForYesButton(tab)
  }
  else{
    setGeneralTab(tab)
  }
}

const tabYesButtonHandle = () => {
  saveButtonHandler();
  setShowTabModal(false);
  setAccountUpdatedData(accountAssignmentStaff)
  setQueueUpdatedData(queueAssignment)
  setIDUpdatedData(idAssignment)
  setSLAUpdatedData(slaAssignment)
  setVolumeUpdatedData(volumeAssignment)
  setGeneralTab(tabForYesButton)
}

const tabNoButtonHandle = () => {
  setShowTabModal(false)
  setGeneralTab(tabForYesButton)
  setAccountUpdatedData(accountAssignmentStaff)
  setQueueUpdatedData(queueAssignment)
  setIDUpdatedData(idAssignment)
  setSLAUpdatedData(slaAssignment)
  setVolumeUpdatedData(volumeAssignment)
}





  const ValidatePreviousData = (response, resolve) => {
    if (ObjectEquateHandle(response, resolve)) {
     // saveButtonHandler();
      let index = generaltabRows.indexOf(generalTab);
      let currentTab = generaltabRows[index - 1];
      setGeneralTab(currentTab);
    }
    else {
    //  setShowCancelModal(false);
      setShowUnsavedPreviousModal(true);
    }
  }
  const previousButtonHandler = () => {
    switch (generalTab) {
      case 'account': ValidatePreviousData(accountModifiedData, accountUpdatedData?.[0]?.Details); break;
      case 'queue': ValidatePreviousData(queueModifiedData, queueUpdatedData?.[0]?.Details); break;
      case 'assign': ValidatePreviousData(IDModifiedData, IDUpdatedData?.[0]?.Details); break;
      case 'slakpi': ValidatePreviousData(SLAModifiedData, SLAUpdatedData?.[0]?.Details); break;
      case 'volume': ValidatePreviousData(VolumeModifiedData, VolumeUpdatedData?.[0]?.Details); break;
      case 'preview' : ValidatePreviousData(1,1); break;
      default: { }
    }
  }
 
  // const submitButtonHandler = () => {
  //   if(isAccountCreated){
  //     setShowSubmitModal(true);
  //     setAccountNotSubmitted(false);
  //     setDataSaveInfo(false);
  //   } 
  //   else{
  //     setAccountNotSubmitted(true);
  //     setShowSubmitModal(false);
  //  setDataSaveInfo(false)
  //   }
      
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: 'smooth'
  //   });
  // }
 
  const ButtonClickHandler = (button) => {
    switch (button) {
      case 'next': nextButtonHandler(); break;
      case 'previous': previousButtonHandler(); break;
      case 'submit':  break;
      case 'save': saveButtonHandler(); break;
      case 'cancel': cancelButtonHandler(); break;
      default: { }
    }
  }
  const onClickViewDetails = title => {
    switch (title) {
      case 'Account Assignment': setGeneralTab('account'); break;
      case 'AWS Queue / Language Assignment': setGeneralTab('queue'); break;
      case 'ID Assignment': setGeneralTab('assign'); break;
      case 'SLA / KPI Assignment & Weighting': setGeneralTab('slakpi'); break;
      case 'Volumetrics': setGeneralTab('volume'); break;
      default: ;
    }
  }
  const DispatchObjectManager = (data) => {
    switch (generalTab) {
      case 'account': setAccountUpdatedData(data); break;
      case 'queue': setQueueUpdatedData(data); break;
      case 'assign': setIDUpdatedData(data); break;
      case 'slakpi': setSLAUpdatedData(data); break;
      case 'volume': setVolumeUpdatedData(data); break;
      default: { }
    }
  }
  const StaffAdminDataHandle = () => {
    switch (generalTab) {
      case 'account': {
        let temp = [...accountAssignmentStaff];
        let arr1 = temp.map(arr => ({
          ID: arr?.id,
          value1: arr?.['account Name'],
          value2: arr?.customerType,
          value3: formatDate(arr?.validFrom),
          value4: formatDate(arr?.validTo)
        }))
        return arr1

      }
      case 'queue': {
        let temp = [...queueAssignment];
        let arr1 = temp.map(arr => ({
          ID: arr?.id,
          value1: arr?.account,
          value2: arr?.queueName,
          value3: arr?.language,
          value4: formatDate(arr?.validFrom),
          value5: formatDate(arr?.validTo)
        }))
        return arr1
      }
      case 'assign': {
        let temp = [...idAssignment];
        let arr1 = temp.map(arr => ({
          ID: arr?.id,
          value1: arr?.customer,
          value2: arr?.idAssigned,
          value3: arr?.systemName,
          value4: formatDate(arr?.validFrom),
          value5: formatDate(arr?.validTo)
        }))
        return arr1
      }
      case 'slakpi': {
        let temp = [...slaAssignment];
        let arr1 = temp.map(arr => ({
          ID: arr?.id,
          value1: arr?.account,
          value2: arr?.['slA/KPI'],
          value3: arr?.['item Name'],
          value4: arr?.target,
          value5: arr?.weighting,
          value6: formatDate(arr?.validFrom),
          value7: formatDate(arr?.validTo),
          value8:arr?.accountSLAID
        }))
        return arr1
      }
      case 'volume': {
        let temp = [...volumeAssignment];
        let arr1 = temp.map(arr => ({
          ID: arr?.id,
          value1: arr?.account,
          value2: arr?.['slA/KPI'],
          value3: arr?.['item Name'],
          value4: arr?.target,
          value5: arr?.weighting,
          value6: formatDate(arr?.validFrom),
          value7: formatDate(arr?.validTo),
          value8:arr?.accountVolumetricTempalteID
        }))
        return arr1
      }
      default: { }
    }
  }


 let accountUpdateDetails = accountUpdatedData?.[0]?.Details !== undefined ? accountUpdatedData?.[0]?.Details : []
 let CompareAccountValidDates = Object.values(accountUpdateDetails).some((value) => value.ValidTo >  compareValidTo)

 let queueUpdateDetails = queueUpdatedData?.[0]?.Details !== undefined ? queueUpdatedData?.[0]?.Details : []
 let CompareQueueValidDates = Object.values(queueUpdateDetails).some((value) => value.ValidTo >  compareValidTo)

 let IDUpdateDetails = IDUpdatedData?.[0]?.Details !== undefined ? IDUpdatedData?.[0]?.Details : []
 let CompareIDValidDates = Object.values(IDUpdateDetails).some((value) => value.ValidTo >  compareValidTo)

 let SLAUpdateDetails = SLAUpdatedData?.[0]?.Details !== undefined ? SLAUpdatedData?.[0]?.Details : []
 let CompareSLAValidDates = Object.values(SLAUpdateDetails).some((value) => value.ValidTo >  compareValidTo)

 let VolumeUpdateDetails = VolumeUpdatedData?.[0]?.Details !== undefined ? VolumeUpdatedData?.[0]?.Details : []
 let CompareVolumeValidDates = Object.values(VolumeUpdateDetails).some((value) => value.ValidTo >  compareValidTo)


 const [showpath, setShowpath] = useState(false);
 const [NewEmployeeToggle,setNewEmployeeToggle ] = useState(false);
 const [ToggleValidation,setToggleValidation ] = useState(false);

 const BreadcrumbValidationHandler = (path) => {
  
   if (!NewEmployeeToggle) {
    setToggleValidation(true)
  }
  else if(!TabChangeValidation){
    setShowUnsavedbreadcrumbModal(true);
    setShowpath(path)
  }
  else{
    history.push(path)
  }
}

const BreadcrumbValidationYesButtonHandle = () => {
  saveButtonHandler();
  setShowUnsavedbreadcrumbModal(false)
  history.push(showpath)
}

const BreadcrumbValidationNoButtonHandle = () => {
  setShowUnsavedbreadcrumbModal(false)
  history.push(showpath)
}


  return (
    <>
      <div style={{ width: '98%' }}>
        <AdminBreadcrumb value1={()=>BreadcrumbValidationHandler('/welcome')} label1='Home' isActive2={true} value2={()=>BreadcrumbValidationHandler("/admin")} label2='Administration'
          isActive3={true} label3='Staff-Administration' isActive4={false} value3={()=>BreadcrumbValidationHandler("/admin/staff")} label4='Add Mapping' />
        <div className='new_employee'>New Employee Mapping</div>
        {dataSaveInfo && <div className='dataSave_div'>
          <div> <img src={dataSave_icon} alt='' /> &nbsp;&nbsp;Data has been saved successfully </div>
          <div><img src={dataSaveClose_icon} alt='' onClick={() => setDataSaveInfo(false)} /></div>
        </div>}
        {accountNotCreated && <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;Data has not been saved. Please check the toggle before saving </div>
          <div><img src={dataSaveClose_icon} alt='' onClick={() => setAccountNotCreated(false)} /></div>
        </div>}
        {ToggleValidation ? <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;General Informations data has not been saved. Please check the Initial Save before proceeding </div>
          <div><img src={dataSaveClose_icon} alt='' onClick={() => setToggleValidation(false)} /></div>
        </div> : ''}
        {/* {accountNotSubmitted && <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;Data has not been submitted. Please check the toggle before submitting </div>
          <div><img src={dataSaveClose_icon} alt='' onClick={() => setAccountNotCreated(false)} /></div>
        </div>} */}
        
        <Newemployee AccountCreatedHandle={(d) => setIsAccountCreated(d)} setcompareValidTo = {(v) => setcompareValidTo(v)}
         newEmployeeToggleChange={(checked) => setNewEmployeeToggle(checked)}
        />

        {(generalTab === 'account' && CompareAccountValidDates) ? <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;Account Assignment valid to date is after the Role valid to date </div></div> : ''}
        {(generalTab === 'queue' && CompareQueueValidDates) ? <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;Queue Assignment valid to date is after the Role valid to date </div></div> : ''}
        {(generalTab === 'assign' && CompareIDValidDates) ? <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;ID Assignment valid to date is after the Role valid to date </div></div> : ''}
        {(generalTab === 'slakpi' && CompareSLAValidDates) ? <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;SLA / KPI Assignment valid to date is after the Role valid to date </div></div> : ''}
        {(generalTab === 'volume' && CompareVolumeValidDates) ? <div className='dataSave_div' style={{ color: 'black', backgroundColor: '#e3e375' }}>
          <div>  &nbsp;&nbsp;Volumetrics valid to date is after the Role valid to date </div></div> : ''}


        <section className='staff_assign_container'>
          <div className='staff_assign_div'>
            <Image src={accountImgSrc} label={<span>Account <br /> Assignment</span>} isSelected={generalTab === 'account' ? true : false}
              onClick={() => { staffAdminTabButtonHandle('account') }} onMouseEnter={() => generalTab !== 'account' && setAccountImgSrc(Account_Assignment_HoverStateIcon)} onMouseLeave={() => generalTab !== 'account' && setAccountImgSrc(Account_Assignment_NormalStateIcon)}></Image>
            <Image src={AWSImgSrc} label='AWS Queue / Language Assignment' isSelected={generalTab === 'queue' ? true : false}
              onClick={() => staffAdminTabButtonHandle('queue')} onMouseEnter={() => generalTab !== 'queue' && setAWSImgSrc(AWSQueueLanguage_Assignment_HoverStateIcon)} onMouseLeave={() => generalTab !== 'queue' && setAWSImgSrc(AWSQueueLanguage_Assignment_NormalStateIcon)}> </Image>
            <Image src={IDImgSrc} label='ID Assignment' isSelected={generalTab === 'assign' ? true : false}
              onClick={() => staffAdminTabButtonHandle('assign')} onMouseEnter={() => generalTab !== 'assign' && setIDImgSrc(ID_Assignment_HoverStateIcon)} onMouseLeave={() => generalTab !== 'assign' && setIDImgSrc(ID_Assignment_NormalStateIcon)}> </Image>
            <Image src={SLAImgSrc} label='SLA / KPI Assignment & Weighting' isSelected={generalTab === 'slakpi' ? true : false}
              onClick={() => staffAdminTabButtonHandle('slakpi')} onMouseEnter={() => generalTab !== 'slakpi' && setSLAImgSrc(SLA_KPI_AssignmentWeighting_HoverStateIcon)} onMouseLeave={() => generalTab !== 'slakpi' && setSLAImgSrc(SLA_KPI_AssignmentWeighting_NormalStateIcon)}></Image>
              <Image src={VolumeImgSrc} label='Volumetrics' isSelected={generalTab === 'volume' ? true : false}
              onClick={() => staffAdminTabButtonHandle('volume')} onMouseEnter={() => generalTab !== 'volume' && setVolumeImgSrc(Volumetric_Hover_Icon)} onMouseLeave={() => generalTab !== 'volume' && setVolumeImgSrc(Volumetric_Normal_Icon)}></Image>
            <Image src={previewImgSrc} label='Preview' imgclass='imgClass' isSelected={generalTab === 'preview' ? true : false}
              onClick={() => staffAdminTabButtonHandle('preview')} onMouseEnter={() => generalTab !== 'preview' && setPreviewImgSrc(Preview_HoverStateIcon)} onMouseLeave={() => generalTab !== 'preview' && setPreviewImgSrc(Preview_NormalStateIcon)}></Image>
          </div>
        </section>
        {generalTab === 'preview' ? <span><PreviewTab onClickViewDetails={onClickViewDetails} PreviewToggle={staffPreview} /></span>
          : <EmployeeTabHandler tab={generalTab} StaffAdminData={StaffAdminDataHandle} DispatchObjectManager={DispatchObjectManager} />}
        {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1  ? <main className='button-wrapper'>{
          BUTTONS.map((button, i) => (
            <>  {showButtons[i] === 1 && <button className='btn' onClick={() => ButtonClickHandler(button.label.toLowerCase())}>
              <span id='span'>{button.label}</span></button>} </>
          ))
        }</main> : ''}


        <br /><br /><br /><br />
      </div>

      {showUnsavedModal && <ActivateModelPopup isOpen={showUnsavedModal} setShowModal={setShowUnsavedModal} >
        <UnsavedModel handleYesButton={() => history.push('/admin/staff')} handleNoButton={() => { setShowUnsavedModal(false) }}/>
      </ActivateModelPopup>
      }
      
      {/* {showCanceldModal && <ActivateModelPopup isOpen={showCanceldModal} setShowModal={setShowCancelModal} >
        <AdminCancelModel handleYesButton={() => history.push('/admin/staff')} handleNoButton={() => { setShowCancelModal(false) }} />
      </ActivateModelPopup>
      } */}

      {showUnsavedNextModal && <ActivateModelPopup isOpen={showUnsavedNextModal} setShowModal={setShowUnsavedNextModal} >
        <AdminNextModel handleYesButton={saveButtonHandler} handleNoButton={nextModelNohandler }/>
      </ActivateModelPopup>
      }

      {showUnsavedPreviousModal && <ActivateModelPopup isOpen={showUnsavedPreviousModal} setShowModal={setShowUnsavedPreviousModal} >
        <AdminNextModel handleYesButton={()=>{saveButtonHandler(); 
            let index = generaltabRows.indexOf(generalTab);
      let currentTab = generaltabRows[index - 1];
      setGeneralTab(currentTab)
          }} handleNoButton={previousModelNohandler}
          handleSaveButton={()=>{ }} />
      </ActivateModelPopup>
      }

      {/* {showSubmitModal && <ActivateModelPopup isOpen={showSubmitModal} setShowModal={setShowSubmitModal}>
        <AdminSubmitModel modalName={`New Employee Mapping`} buttonOne='CLOSE'
          handleSuccessAccount={() => {
            setShowSubmitModal(false);
            history.push('/admin/staff')
          }}
          queueName={supervisorInfo.EmployeeName}
        />
      </ActivateModelPopup>} */}

      {showTabModal && <CancelModalPopup isOpen={showTabModal} setShowModal={setShowTabModal}>
        <NextButtonFragment
          handleSaveQueue={tabYesButtonHandle}
          accountName=''
          queueName=''
          handleDeleteQueueCancel={tabNoButtonHandle}
          handleDeleteQueue={nextButtonHandler} tab={generalTab}
        />
      </CancelModalPopup>}

      {showUnsavedbreadcrumbModal && <ActivateModelPopup isOpen={showUnsavedbreadcrumbModal} setShowModal={setShowUnsavedbreadcrumbModal} >
        <AdminNextModel handleYesButton={BreadcrumbValidationYesButtonHandle} handleNoButton={BreadcrumbValidationNoButtonHandle }/>
      </ActivateModelPopup>
      }
    </>



  )
}

export default EmployeeDetails;