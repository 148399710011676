import React, { useState } from 'react';
import './DataAdmin.css';
import Contractual_SLA_Icon from './../../../assets/images/Contractual_SLA_Icon.png';
import edit from './../../../assets/images/Edit_Icon.png';

export function FormField(props) {
  const [showTooltip, setShowTooltip] = useState(false);

  const className = props.isSelected ? 'switch active' : 'switch';

  return (
    <section className='formField_container'>
      <div 
        id='field_text' 
        onMouseOver={() => setShowTooltip(true)} 
        onMouseLeave={() => setShowTooltip(false)}
      >
        <div style={{ cursor: 'pointer' }}>{props.fieldName}</div>
        {showTooltip && (
          <ul className='tooltip_ul'>
            {props.isSelected && (
              <li className='tooltip_li1' onClick={props.onClickEdit}>
                <img src={edit} alt='' style={{ marginRight: '10px', cursor: 'pointer' }} />
                <span style={{ cursor: 'pointer' }}>Edit</span>
              </li>
            )}
            <hr style={{ margin: '0px' }} />
            <li className='tooltip_li2'>{props.description}</li>
          </ul>
        )}
      </div>
      <div>
        <span style={{ marginRight: 50 }}>
          {props.contractual && <img src={Contractual_SLA_Icon} alt='' />}
        </span>
       {props.toggleView === true && <span className='toggle-container'>
          <label className={className}>
            <input
              type='checkbox'
              checked={props.isSelected}
              onChange={props.togglehandler}
            />
            <span className='slider' />
          </label>
        </span>}
      </div>
    </section>
  );
}
