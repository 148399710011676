import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import RequestDropdown from "../../Onboard/OnboardComponents/Dropdown/RequestDropdown";
import { OnboardSelect } from "../../Onboard/OnboardComponents/Dropdown/Select";
import Contractual_SLA_Icon from './../../../assets/images/Contractual_SLA_Icon.png';
import { useSelector } from "react-redux";
import { getSLAKPIVolumeTemplates, updateCustomTemplateStart } from "../../../actions/uploads.action";
import { useDispatch } from "react-redux";

const FormType = [
    { id: 1, value: "Percentage" },
    { id: 1, value: "Time" },
    { id: 1, value: "Number" },
    { id: 1, value: "Percentage = Numerator / Denominator" },
    { id: 1, value: "Number = Numerator / Denominator" },
    { id: 1, value: "Time = Numerator / Denominator" },

]
export function AddFormModal(props) {
    const dispatch = useDispatch();
    
    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
    const SelectedSLAKPIVolumeTab = useSelector((state) => state.uploadReducer.SelectedSLAKPIVolumeTab);
  console.log('SelectedSLAKPIVolumeTab',SelectedSLAKPIVolumeTab)
  const [AddFormField, setAddFormField] = useState({});
  const [validation, setValidation] = useState('');
    const [check, setCheck] = useState(false);
    const editMode = props.editMode;
    useEffect(() => {
        editMode && setAddFormField(props.editFormField)
        editMode && setCheck(props.editFormField.contractual)
    }, [editMode,props])
    

    const onChangeContractual = (event) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.checked;
        const newFormData = { ...AddFormField };
        newFormData[fieldName] = fieldValue;
        setAddFormField(newFormData)
        setCheck(!check)
    }

   
    const handleFormFieldChange = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...AddFormField };
        newFormData[fieldName] = fieldValue;
        setAddFormField(newFormData)
    }

    const addValueToUser = (name, value) => {
        const newFormData = { ...AddFormField };
        if (name !== 'Select') {
            newFormData[name] = value;
            setAddFormField(newFormData)
        }
    }
    console.log('newFormData', AddFormField)

    let DispObj = [{
        "Template":AddFormField.name,
        "Type":AddFormField.type,
        "Description":AddFormField.description,
        "Contractual":AddFormField.contractual === true ? 1 : 0,
        "CustomerID":SelectedAccountDetails.CustomerID,
        "Category" :SelectedSLAKPIVolumeTab
    }]

    const handleAddCustomTemplate = () => {

        const RequiredFields = {
        "Template":AddFormField.name,
        "Type":AddFormField.type,
        "Description":AddFormField.description,
        }
        const isEmptyInput = Object.values(RequiredFields).some(x => (x === null || x === '' || x === undefined));

        if(isEmptyInput){
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else{
            console.log('checked',DispObj)
            dispatch(updateCustomTemplateStart(DispObj))
            props.handleAddTemplateModelClose()
            setTimeout(() => {
                dispatch(getSLAKPIVolumeTemplates(
                    SelectedAccountDetails.CustomerID || '',
                    SelectedSLAKPIVolumeTab
                ));
            }, 1000);
        }
    }

    const handleEditCustomTemplate = () =>{
        
        props.handleEditFormField(AddFormField)
    }

    console.log('DispObj',props.editFormField)

    return <>
        <section >
            <div >
                <div className='Upload_model_title'>
                   {editMode ? 'Edit' : 'Add'} {props.title}
                </div>
            </div>
            <main>
                <div style={{ color: '#ED4F32' }}>{validation}</div>
                <div className="Upload_input_Height">
                    <label>Name * </label>
                    {editMode ?
                        <div className="edited_info">
                            {props.editFormField?.name}
                        </div>
                        : <input type='text'
                            name='name'
                            placeholder=''
                            onChange={handleFormFieldChange}
                        />}
                </div>
                <div className="Upload_input_Height" style={{ marginTop: 10 }}>
                    <label htmlFor="lang">Type *</label>
                    {editMode ?
                        <div className="edited_info">
                            {props.editFormField?.newFormatType}
                        </div>
                        : <RequestDropdown data={FormType} name='type' value='value' id='id'
                            addValueToQueue={addValueToUser} refresh={''}
                        />}
                </div>
                <div >
                    <label >Description * </label>
                    {editMode ?
                        <div className="edited_info">
                            {props.editFormField?.description}
                        </div>
                        : <input type='text'
                            name='description'
                            placeholder=''
                            onChange={handleFormFieldChange}
                        />}
                </div>
                <div className='remainder' style={{ cursor: 'pointer', marginLeft: '0px' }}>
                    <input type='checkbox'
                        name='contractual' id='contractual'
                        checked={check}
                        onChange={onChangeContractual}
                        style={{ cursor: 'pointer' }} />
                    <label htmlFor='contractual' style={{ cursor: 'pointer' }}>
                        <span className='text-remainder' style={{ cursor: 'pointer' }}>Contractual SLAs/KPIs <img src={Contractual_SLA_Icon} alt='' /> </span>
                    </label >
                </div>
            </main>

            <main className="Upload_Model_Buttons" style={{marginTop: '1em' }}>
                <button type='submit' className='btn_adduser' onClick={editMode ? handleEditCustomTemplate : handleAddCustomTemplate}>
                    {editMode ? 'UPDATE' : 'ADD'}
                </button>
                <button className='btn_canceluser' onClick={props.handleAddTemplateModelClose}>
                    CANCEL
                </button>
            </main>
        </section>
    </>
}


export function AddVolumePopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    background: '#63666A66 0% 0% no-repeat padding-box',
                    opacity: '1',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    margin:'20px',
                    padding:'30px',
                }
            }}>
            {children}
        </ReactModal>

    </>)
}

export function CancelFieldFragment(props) {
    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '4em' }}>
                <div className='delete-row-span' style={{ marginTop: '1em' }}>
                    Unsaved Changes
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>

                <div className='delete-para-span'> There are unsaved changes on this page. <br />
                    Are you sure you want to cancel the  <span className="queue-span"> {props.tab} Data Selection Processs</span>
                </div>
            </div>
            <main className='input-container-one' style={{ marginLeft: '2em', marginTop: '3em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleSaveQueue}>
                    SAVE
                </button>
                <button type='submit' className='btn_canceluser' onClick={props.handleDeleteQueue}>
                    YES
                </button>
                <button className='btn_canceluser' onClick={props.handleDeleteQueueCancel}>
                    NO
                </button>
            </main>
        </section>
    </>
}



export function CancelYesNoFragment(props) {
    return <>
        <section className="delete-row-container">
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em', marginTop: '6em' }}>
                <div className='delete-para-span'> You have not selected any Fields <br />
                    Are you sure you want to go to  <span className="queue-span"> File Upload Screen</span>
                </div>
            </div>
            <main className='input-container-five' style={{ marginLeft: '5em', marginTop: '3em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleDeleteQueue}>
                    YES
                </button>
                <button className='btn_canceluser' onClick={props.handleDeleteQueueCancel}>
                    NO
                </button>
            </main>
        </section>
    </>
}