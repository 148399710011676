import React from 'react'
import { PreviewNonvoiceCard } from '../../../Onboard/OnboardComponents/Preview/PreviewCard'
import { Row, Col } from 'react-bootstrap';

const PreviewTab = (props) => {
  const previewToggleHandler = (value, tab) => {
    return value === 'Yes' ? true : false
  }
  return (<>
  <section style={{paddingRight:400, marginLeft:'40px'}}>
  <div className='preview_admin_text'>This preview enables to view the previous steps filled status/update.<br/>
   Don’t forget to save you work.</div>
   <hr/>
    <div>
        <Row>
          <Col> 
          <PreviewNonvoiceCard title='Account Assignment' value={previewToggleHandler(props.PreviewToggle?.[0]?.['Account Assignment']?.[0]?.Completed)}  isDone={true} onClick={props.onClickViewDetails} table={false} />
            <PreviewNonvoiceCard title='ID Assignment' value={previewToggleHandler(props.PreviewToggle?.[0]?.['ID Assignment']?.[0]?.Completed)} isDone={true} onClick={props.onClickViewDetails} table={false}/>
            <PreviewNonvoiceCard title='Volumetrics' value={previewToggleHandler(props.PreviewToggle?.[0]?.Volumetrics?.[0]?.Completed)}  isDone={true} onClick={props.onClickViewDetails} table={false} />
          </Col>
          <Col>
          <PreviewNonvoiceCard title='AWS Queue / Language Assignment' value={previewToggleHandler(props.PreviewToggle?.[0]?.['AWS Queue \/ Language Assignment']?.[0]?.Completed)} isDone={true} onClick={props.onClickViewDetails} table={false}/>
            <PreviewNonvoiceCard title='SLA / KPI Assignment & Weighting' value={previewToggleHandler(props.PreviewToggle?.[0]?.['SLA \/ KPI Assignment & Weighting']?.[0]?.Completed)} isDone={true} onClick={props.onClickViewDetails} table={false}/>
          </Col>
         </Row>
      </div>
      </section>
      </>
  )
}

export default PreviewTab