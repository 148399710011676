import ReactModal from "react-modal";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateValidToDateStart } from'../../../actions/Administration/admin.action'
export function ActivationRequestModal(props) {

    const dispatch = useDispatch();
    const [validation, setValidation] = useState('');
    const [ValidFrom, setValidFrom] = useState('');
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
    }
    const onSubmitRequest = () => {
       
        let obj = [{
            Account: props.data?.['Account Name'],
            Servicedesk: props.data?.['Site Name'],
            ValidUntil: ValidFrom
        }]
        if(ValidFrom){
            props.handleSubmitClick()
            dispatch(updateValidToDateStart(obj))
            setValidation('')
        }
        else{
           props.handleinvalidSubmitClick()
           setValidation('Please Ensure you filled the inputs with  " * " mark ')
        }
        
       
    }
    return <>
        <section className="delete-row-container">
       
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '2em' }}>  Request for Account Re-Activation
                </div>
            </div>
            <div style={{ color: '#ED4F32',marginLeft: '3em'}}> {validation} </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                <div>
                    <div className="acc_site_name"><span style={{ color: "#969696" }}>Account Name:</span> <span style={{ color: "#000000" }}>{props.data?.['Account Name']}{props.data.AccountName}</span></div>
                    <div className="acc_site_name"><span style={{ color: "#969696" }}>Site Name:</span><span style={{ color: "#000000" }}> {props.data?.['Site Name']}{props.data.SiteName}</span></div>
                    <div className='container-one'>
                        <label><span>Set Valid To *</span>
                        </label>
                        <KeyboardDatePicker
                            autoOk
                            variant="inline"
                            name='valid_from'
                            clearable
                            value={ValidFrom}
                            placeholder="MM/DD/YYYY"
                            onChange={(date) => ValidFromDateHandler(date)}
                            format="MM/DD/YYYY"
                            style={{ width: 380 }}
                        />
                    </div>
                </div>
            </div>
            <main className='input-container-five' style={{ marginLeft: '5em', marginTop: '5em' }}>
                <button type='submit' className='add-button' onClick={onSubmitRequest}>
                    <span className='add-span' >SUBMIT</span>
                </button>
                <button className='cancel-button' onClick={props.handleCancelClick}>
                    <span className='cancel-span'>CANCEL</span>
                </button>
            </main>

        </section>
    </>
}


export function RequestSentModal(props) {

    return (<>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    Request for Account Re-Activation
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                <div className="active__text">The <b>{props.data?.['Account Name']} - {props.data?.['Site Name']}
                </b><b>{props.data.AccountName} - {props.data.SiteName}</b>
                 account activation request received successfully. You will receive an email
                    confirmation from the administrator to the registered mail ID ({props.data?.['Created By']}{props.data.CreatedBy}).
                </div>
            </div>

            <main className='input-container-five' style={{ marginLeft: '11em', marginTop: '3em' }}>
                <button type='submit' className='add-button' onClick={props.handleClose}>
                    <span className='add-span' >CLOSE</span>
                </button>
            </main>
        </section>
    </>)
}


export function ActivateModelPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '15em',
                    left: '30em',
                    width: '500px',
                    height: '300px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}