import React, { useEffect, useState } from 'react';
import ClearFilter from '../../ReportTables/ClearFilters/ClearFilter';
import Filter from '../../ReportTables/ReportFilters/ReportFilters';
import HomeBreadcrumb from '../Welcome-LandingPage/Home-Breadcrumb';
import AddReleaseUpdate, { AddReleaseModalPopup } from './AddReleaseUpdate';
import { ReleaseCard } from './ProductionPlanned';
import './ReleaseHandler.css';
import { DeleteFragment, DeleteModalPopup } from '../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { useDispatch, useSelector } from 'react-redux';
import { createLatestReleasesStart, deleteLatestReleasesStart, getLatestReleasesStart, updateLatestReleasesStart } from '../../../actions/welcomePage.action';

const DATA = {
    period_data: [
        { id: 33, value: '2022-December', isChecked: false },
        { id: 32, value: '2022-November', isChecked: false },
        { id: 31, value: '2022-October', isChecked: false },
        { id: 30, value: '2022-September', isChecked: false },
        { id: 29, value: '2022-August', isChecked: false },
        { id: 28, value: '2022-July', isChecked: false },
        { id: 27, value: '2022-June', isChecked: false },
        { id: 25, value: '2022-May', isChecked: false },
        { id: 24, value: '2022-April', isChecked: false },
        { id: 1, value: '2022-March', isChecked: false },
        { id: 2, value: '2022-February', isChecked: false },
        { id: 3, value: '2022-January', isChecked: false },
        { id: 4, value: '2021-December', isChecked: false },
        { id: 5, value: '2021-November', isChecked: false },
    ],
    category_data: [
        { id: 1, value: 'All', isChecked: false },
        { id: 2, value: 'Production Releases', isChecked: false },
        { id: 3, value: 'Planned Releases', isChecked: false },
    ]
};
const initialState = {
    category: '',
    publishedDate: '',
    title: '',
    description: '',
    filteredDate: '',
    link: '',
}

function ReleaseHandler() {

    const dispatch = useDispatch();
    const LatestReleases = useSelector(state => state.welcomePageReducer.LatestReleases);
    useEffect(() => {
        dispatch(getLatestReleasesStart());
    }, [dispatch]);

    const ProductionData = LatestReleases?.[0]?.items;
    const PlannedData = LatestReleases?.[1]?.items;


    const [filters, setFilters] = useState([]);
    const [clearFilters, setClearFilters] = useState(false);
    const [period_data, setPeriod_data] = useState([]);
    const [category_data, setCategory_data] = useState([]);
    const [showAddReleaseModal, setShowAddReleaseModal] = useState(false);
    const [showEditreleaseModal, setShowEditreleaseModal] = useState(false);
    const [showDeleteReleaseModal, setShowDeleteReleaseModal] = useState(false);
    const [deleteReleaseId, setDeleteReleaseId] = useState('');
    const [addRelease, setAddRelease] = useState(initialState)
    const [editReleaseId, setEditReleaseId] = useState('');
    const [editReleaseInfo, setEditReleaseInfo] = useState(initialState);
    const [releaseUpdateData, setReleaseUpdateData] = useState([]);
    const [releasePlannedData, setReleasePlannedData] = useState([]);
    const [clickedState, setClickedState] = useState('');
    const roleSelected = localStorage.getItem('RoleSelected');
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    const formatFilteredDate = date => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        return [month, '01', year].join('/');
    }

    useEffect(() => {
        setCategory_data(DATA.category_data);
        setPeriod_data(DATA.period_data);
    }, []);

    useEffect(() => {
        if (ProductionData) {
            ProductionData.map(data => {
                data.publishedDate = formatDate(data.date);
                data.filteredDate = formatFilteredDate(data.date);
                data.category = 'Production Releases';
            })
            setReleaseUpdateData(ProductionData);
        }
        if (PlannedData) {
            PlannedData.map(data => {
                data.publishedDate = formatDate(data.date);
                data.filteredDate = formatFilteredDate(data.date);
                data.category = 'Planned Releases';
            });
            setReleasePlannedData(PlannedData)
        }
    }, [PlannedData, ProductionData])



    const addDateToRelease = (name, value) => {
        if (name === 'publishedDate' && value === '') {


        }
        const newFormData = { ...addRelease };
        newFormData[name] = value;
        setAddRelease(newFormData)
    }
    const handleAddRelease = (event) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addRelease };
        newFormData[fieldName] = fieldValue;
        setAddRelease(newFormData);
    }

    function DateFormatHandler(date, fil) {
        let d = '';
        if (date === '') d = new Date();
        else d = new Date(date)
        let month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        if (fil === 'filtered') return [month, year].join('/01/')
        else return [month, day, year].join('/');
    }

    const dispatchItem = data => {
        let item = {}
        let temp_arr = [];
        item.title = data.title;
        item.date = data.publishedDate;
        item.link = data.link;
        item.description = data.description;
        temp_arr.unshift(item)
        return temp_arr
    }


    const [addReleaseValidation, setAddReleaseValidation] = useState(false);

    const AddReleaseSubmit = (event) => {
        event.preventDefault();
        const newRelease = {
            item: Math.floor(Math.random() * 1000),
            category: addRelease.category,
            publishedDate: DateFormatHandler(addRelease.publishedDate, 'published'),
            filteredDate: DateFormatHandler(addRelease.publishedDate, 'filtered'),
            title: addRelease.title,
            description: addRelease.description,
            link: addRelease.link,
        };
        const newReleaseObjCheck = {
            category: addRelease.category,
            publishedDate: DateFormatHandler(addRelease.publishedDate, 'published'),
            title: addRelease.title,
            description: addRelease.description,
        }
        const isEmpty = Object.values(newReleaseObjCheck).some(x => (x === null || x === '' || x === 'NaN/NaN/NaN'));
        if (addRelease.category === 'Production Releases' && !isEmpty) {
            const newReleases = [newRelease, ...releaseUpdateData];
            setReleaseUpdateData(newReleases);
            let item = dispatchItem(newRelease);
            dispatch(createLatestReleasesStart({ channel: 1, data: item }))
        }
        if (addRelease.category === 'Planned Releases' && !isEmpty) {
            const newReleases = [newRelease, ...releasePlannedData];
            setReleasePlannedData(newReleases);
            let item = dispatchItem(newRelease);
            !isEmpty && dispatch(createLatestReleasesStart({ channel: 2, data: item }))
        }
        const newReleaseArr = [];
        newReleaseArr.push(newReleaseObjCheck);
        var releaseProps = newReleaseArr.map(el => {
            return Object.keys(el).reduce((newObj, key) => {
                const value = el[key];
                if (value === '' && value === 'NaN/NaN/NaN') {
                    newObj[key] = value;
                }
                return newObj;
            }, {});
        });
        if (!isEmpty && releaseProps.length > 0) setShowAddReleaseModal(false)
        else setAddReleaseValidation(true);
        dispatch(getLatestReleasesStart())
    }


    const handleAddReleaseCancel = () => {
        setShowAddReleaseModal(false);
    }

    let today = new Date();
    let currentDate = []
    let newDate = `${(today.getMonth()) < 10 ? '0' + (today.getMonth()) : (today.getMonth())}/01/${today.getFullYear()}`;
    currentDate.unshift(newDate);
    let recentDate = `${(today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1)}/01/${today.getFullYear()}`;
    currentDate.unshift(recentDate)

    const handleEditReleaseDate = (name, value) => {
        const newFormData = { ...editReleaseInfo };
        newFormData[name] = value;
        setEditReleaseInfo(newFormData);
    }



    const editHandler = (data) => {
        setShowEditreleaseModal(true);
        setEditReleaseId(data.item);
        setDeleteReleaseId(data.item);
        const formValues = {
            category: data.category,
            publishedDate: DateFormatHandler(data.publishedDate, 'published'),
            filteredDate: DateFormatHandler(data.publishedDate, 'filtered'),
            title: data.title,
            description: data.description,
            link: data.link,
        };
        setEditReleaseInfo(formValues);
    }

    const handleEditReleaseUpdate = event => {
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...editReleaseInfo };
        newFormData[fieldName] = fieldValue;
        setEditReleaseInfo(newFormData);
    }

    const handleEditReleaseSubmit = (event) => {
        event.preventDefault();
        let category_release;
        if (clickedState === 'production') category_release = releaseUpdateData[releaseUpdateData.findIndex(data => data.item === editReleaseId)].category;
        if (clickedState === 'planned') category_release = releasePlannedData[releasePlannedData.findIndex(data => data.item === editReleaseId)].category;

        const editedRelease = {
            item: editReleaseId,
            category: editReleaseInfo.category,
            publishedDate: editReleaseInfo.publishedDate,
            filteredDate: editReleaseInfo.filteredDate,
            title: editReleaseInfo.title,
            description: editReleaseInfo.description,
            link: editReleaseInfo.link,
        };

        if (category_release === 'Production Releases') {
            console.log(deleteReleaseId, editReleaseId)
            if (editReleaseInfo.category === 'Production Releases') {
                const newReleases = [...releaseUpdateData];
                const index = releaseUpdateData.findIndex(data => data.item === editReleaseId);
                newReleases[index] = editedRelease;
                setReleaseUpdateData(newReleases);
                dispatch(updateLatestReleasesStart({ channel: 1, serial: editReleaseId, data: dispatchItem(editedRelease) }))
            }
            else {
                const newReleases = [...releaseUpdateData];
                const newPlanned = [...releasePlannedData];
                const index = releaseUpdateData.findIndex(data => data.item === editReleaseId);
                newReleases.splice(index, 1);
                newPlanned.unshift({ ...editedRelease, item: Math.floor(Math.random() * 1000) })
                setReleaseUpdateData(newReleases);
                setReleasePlannedData(newPlanned);
                dispatch(deleteLatestReleasesStart({ channel: 1, serial: deleteReleaseId }))
                dispatch(createLatestReleasesStart({ channel: 2, data: dispatchItem(editedRelease) }))
            }
        }
        if (category_release === 'Planned Releases') {
            console.log(deleteReleaseId, editReleaseId)
            if (editReleaseInfo.category === 'Planned Releases') {
                const newReleases = [...releasePlannedData];
                const index = releasePlannedData.findIndex(data => data.item === editReleaseId);
                newReleases[index] = editedRelease;
                setReleasePlannedData(newReleases);
                dispatch(updateLatestReleasesStart({ channel: 2, serial: editReleaseId, data: dispatchItem(editedRelease) }))
            }
            else {
                const newReleases = [...releaseUpdateData];
                const newPlanned = [...releasePlannedData];
                const index = releasePlannedData.findIndex(data => data.item === editReleaseId);
                newPlanned.splice(index, 1);
                newReleases.unshift({ ...editedRelease, item: Math.floor(Math.random() * 1000) })
                setReleaseUpdateData(newReleases);
                setReleasePlannedData(newPlanned);
                dispatch(deleteLatestReleasesStart({ channel: 2, serial: deleteReleaseId }))
                dispatch(createLatestReleasesStart({ channel: 1, data: dispatchItem(editedRelease) }))
            }
        }
        setShowEditreleaseModal(false);
    }
    const handleEditCancel = event => {
        setShowEditreleaseModal(false)
    }

    const onAddingPeriodFilters = (option, drpdwnID) => {
        let temp_ARR = [...period_data]
        temp_ARR.map(itm => {
            if (option.value === itm.value && option.isChecked === false) {
                itm.isChecked = !option.isChecked;
            }
            return ' ';
        })
        setPeriod_data(temp_ARR);
        let temp_arr = [...category_data];
        temp_arr.map((itm, i) => {
            if (option.value === itm.value && option.isChecked === false) {
                itm.isChecked = !option.isChecked;
            }
            return ' ';
        });
        setCategory_data(temp_arr);
        if (option.value === 'All' || filters.includes('All')) {
            let temp_arr = [...category_data];
            temp_arr.map(item => {
                if (item.value === 'Production Releases') {
                    item.isChecked = true;
                }
                if (item.value === 'Planned Releases') {
                    item.isChecked = true;
                }
                return '';
            })
            filters.includes('Production Releases') && filters.splice(filters.indexOf('Production Releases'), 1);
            filters.includes('Planned Releases') && filters.splice(filters.indexOf('Planned Releases'), 1);
            setCategory_data(temp_arr);
        }
        if ((filters.includes('Production Releases') && option.value === 'Planned Releases') ||
            (filters.includes('Planned Releases') && option.value === 'Production Releases')) {
            let temp_Arr = [...category_data];
            temp_Arr.map(item => {
                if (item.value === 'All') {
                    item.isChecked = true;
                }
                return '';
            });
            setCategory_data(temp_Arr);
        }
        if (filters.includes(option.value)) {
            return ' ';
        }

        if (filters.length === 0) {
            setClearFilters(false);
        }
        let fltrs;
        if ((filters.includes('All') && option.value === 'Production Releases') ||
            (filters.includes('All') && option.value === 'Planned Releases')) {
            fltrs = [...filters];

        }
        else {
            fltrs = [...filters, option.value];
        }
        setFilters(fltrs);
    }


    const filterByReferenceValue = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return arr2.find(element => {
                return element === el.value;
            });
        });
        return res;
    }
    const filterByReferenceDate = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return arr2.find(element => {
                return element === el.filteredDate;
            });
        });
        return res;
    }


    const FormatDateHandler = (filters) => {
        let fltrs = [];
        fltrs = filters.map(fil => {
            let sub_year = fil.substring(0, 4);
            let sub_month = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"].indexOf(fil.substring(5, fil.length).toLowerCase());
            return ((sub_month < 10 ? `0${sub_month + 1}` : sub_month + 1) + '/01/' + sub_year);
        })
        return fltrs;
    }


    const periodFilters = filterByReferenceValue(DATA.period_data, filters).map(fil => fil.value);
    const releaseFilters = filterByReferenceValue(DATA.category_data, filters).map(fil => fil.value);
    const productionFilters = filterByReferenceDate(releaseUpdateData, FormatDateHandler(periodFilters));
    const currentMonthProdFilters = filterByReferenceDate(releaseUpdateData, currentDate);
    const plannedFilters = filterByReferenceDate(releasePlannedData, FormatDateHandler(periodFilters));
    const currentMonthPlanFilters = filterByReferenceDate(releasePlannedData, currentDate);


    const clearFilterHandler = () => {
        dispatch(getLatestReleasesStart())
        setFilters([]);
        setClearFilters(true);
    }

    const deleteHandler = (data) => {
        setShowDeleteReleaseModal(true);
        setDeleteReleaseId(data.item);
    }


    const handleDeleteRelease = () => {
        if (clickedState === 'production') {
            const newRelease = [...releaseUpdateData];
            const index = releaseUpdateData.findIndex((data) => data.item === deleteReleaseId);
            newRelease.splice(index, 1);
            setReleaseUpdateData(newRelease);
            dispatch(deleteLatestReleasesStart({ channel: 1, serial: deleteReleaseId }))

        }
        if (clickedState === 'planned') {
            const newRelease = [...releasePlannedData];
            const index = releasePlannedData.findIndex((data) => data.item === deleteReleaseId);
            newRelease.splice(index, 1);
            setReleasePlannedData(newRelease);
            dispatch(deleteLatestReleasesStart({ channel: 2, serial: deleteReleaseId }))
        }
        setShowDeleteReleaseModal(false);
    }


    return <React.Fragment>
        <HomeBreadcrumb pageId='Latest Updates' />
        <section className='Release_handler_container' >
            <main style={{ display: 'flex' }}>
                <div className='Release_handler'>Latest Updates</div>
                {roleSelected === 'Admin' && <div><button className='add_release' onClick={() => setShowAddReleaseModal(true)}> Add Releases </button></div>}
            </main>
            <Filter
                category_data={DATA.category_data}
                period_data={DATA.period_data}
                onSelectingDropdown={onAddingPeriodFilters}
                clearFilters={clearFilters}
                refreshHandler={clearFilterHandler}
                downloadButton={false}
                latestUpdates={true}
            />
            {filters?.length > 0 ? (<ClearFilter
                onClearingFilters={clearFilterHandler}
                filteredPeriod={filters} />) : null}

            {(releaseFilters.length === 0 || releaseFilters.includes('All') || (releaseFilters.includes('Production Releases') && releaseFilters.includes('Planned Releases'))) ? (<>
                <ReleaseCard header='Production Releases'
                    data={periodFilters.length === 0 ? releaseUpdateData : productionFilters}
                    deleteHandler={deleteHandler} editHandler={editHandler} onClick={() => setClickedState('production')} />
                <ReleaseCard header='Planned Releases' data={periodFilters.length === 0 ? releasePlannedData : plannedFilters}
                    deleteHandler={deleteHandler} editHandler={editHandler} onClick={() => setClickedState('planned')} />
            </>) : <></>}
            {<>
                {(releaseFilters.includes('Production Releases') && !releaseFilters.includes('Planned Releases')) &&
                    <ReleaseCard header='Production Releases' data={periodFilters.length === 0 ? releaseUpdateData : productionFilters} />}
                {(releaseFilters.includes('Planned Releases') && !releaseFilters.includes('Production Releases')) &&
                    <ReleaseCard header='Planned Releases' data={periodFilters.length === 0 ? releasePlannedData : plannedFilters} />}
            </>}
        </section>
        {showAddReleaseModal && <AddReleaseModalPopup isOpen={showAddReleaseModal} setShowModal={setShowAddReleaseModal} >
            <AddReleaseUpdate modalName='Add Release Update' buttonOne='ADD' buttonTwo='CANCEL'
                editMode={false} handleAddRelease={handleAddRelease}
                editReleaseInfo={editReleaseInfo}
                AddReleaseSubmit={AddReleaseSubmit}
                handleAddReleaseCancel={handleAddReleaseCancel}
                addDateToRelease={addDateToRelease}
                addValueToSelect={addDateToRelease} editedValue=''
                addReleaseValidation={addReleaseValidation}
            />
        </AddReleaseModalPopup>}
        {showEditreleaseModal && <AddReleaseModalPopup isOpen={showEditreleaseModal} setShowModal={setShowEditreleaseModal}>
            <AddReleaseUpdate modalName='Edit Release Update' buttonOne='UPDATE' buttonTwo='CANCEL'
                editMode={true} editReleaseInfo={editReleaseInfo}
                handleAddRelease={handleEditReleaseUpdate}
                AddReleaseSubmit={handleEditReleaseSubmit}
                handleAddReleaseCancel={handleEditCancel}
                handleEditReleaseDate={handleEditReleaseDate}
                addValueToSelect={handleEditReleaseDate}
                editedValue={clickedState === 'production' ? 'Production Releases' : 'Planned Releases'}
            />
        </AddReleaseModalPopup>}
        {showDeleteReleaseModal && <DeleteModalPopup isOpen={showDeleteReleaseModal} setShowModal={setShowDeleteReleaseModal} >
            <DeleteFragment modalName='Delete Releases' buttonOne='YES' buttonTwo='NO' delete={true}
                handleDeleteQueue={handleDeleteRelease}
                handleDeleteQueueCancel={() => setShowDeleteReleaseModal(false)}
                queueName={(clickedState === 'production' ? releaseUpdateData : releasePlannedData)
                [(clickedState === 'production' ? releaseUpdateData : releasePlannedData)
                    .findIndex(data => data.item === deleteReleaseId)].title} />
        </DeleteModalPopup>}
    </React.Fragment>;
}

export default ReleaseHandler