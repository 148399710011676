const getTabName = () => {
  let tbName = window.localStorage.getItem("slctdTabName");
  return tbName;
}

const getKeyName = () => {
  let kyName = window.localStorage.getItem("slctdKeyName");
  return kyName;
}

const getSelectedMonth = () => {
  let dt = window.localStorage.getItem('selectedMonth');
  return dt;
}

const getRoleId = () => {
  return localStorage.getItem('Role_id');
}

const getCustNServID = () => {
  let custID = window.localStorage.getItem('CustomerID');
  let ServID = window.localStorage.getItem('ServiceDeskID');
  if (custID && ServID) {
    return custID + "/" + ServID;
  } else {
    return "71/7";
  }
}

const getDownloadURL = (tbName) => {
  if (tbName === 'Summary' || tbName === 'Monthly') {
    return `${process.env.REACT_APP_BASE_URL}/api/metrics/legacydtm/${getCustNServID()}?month=${getSelectedMonth()}&tabname=${getTabName()}&format=xlsx`;
  } else if (tbName === 'Weekly' || tbName === 'Daily' || tbName === 'Annual') {
    return `${process.env.REACT_APP_BASE_URL}/api/metrics/legacydtm/${getCustNServID()}?month=${getSelectedMonth()}&tabname=${getTabName()}&Category=${getKeyName()}&format=xlsx`;
  }
}
const getRegionalSiteURL = () => {
  return `/api/org/hierarchy/me?date=${(new Date().toISOString().split('T')[0])}&roleid=${getRoleId()}`
}
const ConstructURLs = (name) => {
  switch (name) {
    case 'GET_USER_DATA': {
      return "/api/users/me";
    }



    case 'GET_USER_TOKEN': {   
     var cookieArr = document.cookie.split(";");
     let name = cookieArr[0].split("=")[1];
     let key = cookieArr[1].split("=")[1];
     return `/auth/token?name=${name}&key=${key}`;     
    }
    case 'GET_TAB_MONTH_DATA': {
      return `/api/metrics/legacydtm/${getCustNServID()}?month=${getSelectedMonth()}&tabname=Monthly&hint=DTM`;
    }
    case 'GET_TAB_SUMMARY_DATA': {
      return `/api/metrics/legacydtm/${getCustNServID()}?month=${getSelectedMonth()}&tabname=Summary&hint=DTM`;
    }
    case 'GET_WEEK_TAB_DATA': {
      return `/api/metrics/legacydtm/${getCustNServID()}?month=${getSelectedMonth()}&tabname=${getTabName()}&Category=${getKeyName()}&hint=DTM`;
    }
    case 'DOWNLOAD_EXCEL_DATA': {
      return getDownloadURL(getTabName());
    }
    case 'GET_REGIONAL_DATA': {
      return '/api/org/hierarchy/me';
    }
    case 'LOG_OUT_URL': {
      return `${process.env.REACT_APP_BASE_URL}/auth/logout`;
    }
    case 'GET_HOME_FEED_DATA': {
      return `/api/feed`;
    }
    case 'GET_REGIONALSITE_DATA': {
      return getRegionalSiteURL();
    }
    default: { }
  }
}

export default ConstructURLs;
