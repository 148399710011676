import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./tabmenu.css";
//import PagerTabMenu from "../PagerTabMenu/pagertab";
import LegendComponent from "../Legend_CSV/legend";
import Box from "../Box/box";
//import data from "../../assets/data.json";
import TableBox from "../TableBox/tableBox";
import RptTable from "../ReportsTable/reportsTable";
import TabComponent from "../ScrollableTabs/tab";
import DataFormatHelper from "../ReportsTable/dataFormatter";
//import { getMonthData } from "../../actions/tabMenu.action";
import moment from 'moment'
import {
  getMonthData,
  getSummaryData,
  getWeeklyData,
  getDailyData,
  getAnnualData,
  saveAccountLocationName
} from "../../actions/tabMenu.action";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getDTMAnalyticsData, updateSelectedSLA } from "../../actions/regionalSite.action";

const TabBarMenu = () => {
  const history = useHistory()
  const [key, setKey] = useState("summary");
  const [summaryDataBlocks, setSummaryDataBlocks] = useState([]);
 // const [monthDataBlocks, setmonthDataBlocks] = useState([]);
  const [tbkey, setTBKey] = useState("Overall");
  const [isTblVisible, setTblVisibility] = useState(true);
  const [weeklyData, setWeeklyData] = useState([]);
  const [dailyData, setdailyData] = useState([]);
  const [annuallyData, setannuallyData] = useState([]);
  const dispatch = useDispatch();
  let monthData = useSelector((state) => state.tabReducer.month);
  const summaryData = useSelector((state) => state.tabReducer.summary);
  const wklyData = useSelector((state) => state.tabReducer.week);
  const DlyData = useSelector((state) => state.tabReducer.day);
  const AnnualData = useSelector((state) => state.tabReducer.annual);
  useEffect(() => {
    console.log("Updated month data ");
    window.localStorage.setItem("slctdTabName", "Summary");
    dispatch(getSummaryData());
    console.log("Key" + key);
  }, [dispatch]);

  useEffect(() => {
    if (summaryData && summaryData.path && summaryData.blocks) {
      const lcName = getAccLocationName(summaryData.path);
      if (summaryData.blocks.length > 0)
        setSummaryDataBlocks(summaryData.blocks);
      else
        setSummaryDataBlocks([]);
      dispatch(saveAccountLocationName(lcName));
    }
  }, [summaryData]);

  // useEffect(() => {
  //   setmonthDataBlocks(monthData)
  // }, [monthData]);

  useEffect(() => {
  //  if (wklyData && wklyData?.[0]?.path && wklyData?.[0]?.categories) {
    if (wklyData) {
      let dt = DataFormatHelper(wklyData, "week");
      setWeeklyData(dt);
    }
  }, [wklyData]);

  useEffect(() => {
   // if (DlyData && DlyData?.[0]?.path && DlyData?.[0]?.categories) {
    if (DlyData) {
      let dt = DataFormatHelper(DlyData, "day");
      setdailyData(dt);
    }
  }, [DlyData]);

  useEffect(() => {
   // if (AnnualData && AnnualData?.[0]?.path && AnnualData?.[0]?.categories) {
    if (AnnualData) {
      setannuallyData(DataFormatHelper(AnnualData, "annual"));
    }
  }, [AnnualData]);

  useEffect(() => {
    // clean up the table data and bredcrumb data
    dispatch(saveAccountLocationName(''));
    setSummaryDataBlocks([]);
    setWeeklyData([]);
    setannuallyData([]);
    setdailyData([]);
    monthData = [];
   // setmonthDataBlocks([]);
  }, []);

  const getAccLocationName = (path) => {
    if (path) {
      return path.split('/')[1];
    } else {
      return path;
    }
  }

  const handleTabSelect = (key) => {
    setKey(key);
    setTblVisibility(true);
    if (key === "summary") {
      window.localStorage.setItem("slctdTabName", "Summary");
      dispatch(getSummaryData());
    } else if (key === "month") {
      window.localStorage.setItem("slctdTabName", "Monthly");
      dispatch(getMonthData());
      console.log("Jay" + monthData);
    } else if (key === "week") {
      window.localStorage.setItem("slctdKeyName", "Overall");
      window.localStorage.setItem("slctdTabName", "Weekly");
      setTimeout(() => {
        dispatch(getWeeklyData());
      }, 0);
    } else if (key === "day") {
      window.localStorage.setItem("slctdKeyName", "Overall");
      window.localStorage.setItem("slctdTabName", "Daily");
      setTimeout(() => {
        dispatch(getDailyData());
      }, 0);
    } else {
      window.localStorage.setItem("slctdKeyName", "Overall");
      window.localStorage.setItem("slctdTabName", "Annual");
      setTimeout(() => {
        dispatch(getAnnualData());
      }, 0);
    }
    setTBKey(window.localStorage.getItem("slctdTabName"));
  };

  const onSubTabChange = (keyName) => {
    window.localStorage.setItem("slctdKeyName", keyName);
    const tabName = window.localStorage.getItem("slctdTabName");
    if (
      keyName !== "Overall" &&
      keyName !== "By Business Unit" &&
      keyName !== "By Language" &&
      keyName !== "Quality"
    ) {
      setTblVisibility(false);
    } else {
      setTblVisibility(true);
    }
    switch (tabName) {
      case "Weekly": {
        setTimeout(() => {
          dispatch(getWeeklyData());
        }, 0);
        break;
      }
      case "Daily": {
        setTimeout(() => {
          dispatch(getDailyData());
        }, 0);
        break;
      }
      case "Annual": {
        setTimeout(() => {
          dispatch(getAnnualData());
        }, 0);
        break;
      }
      default: {
      }
    }
  };
  const formatHandle = (value) => {
    if (value.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && value?.includes('%')) return 'Percentage';
    else if (moment(value, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A'], true)?.isValid()) return 'Time';
    else if (!isNaN(value)) return 'Number'
  }
  const SLAReportHandleClick = (item) => {
    console.log(item)
    let temp = {
      slaItem: item?.infoName,
      item:item?.itemname,
      format: formatHandle(item?.percentage)
    }
    dispatch(updateSelectedSLA(temp));
    (item.pagetype === 'target') ? history.push('/answer') : history.push('/dtm');
  }

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={handleTabSelect}
      className="mb-3"
    >
      <Tab eventKey="summary" title="Summary" key="summary">
        <LegendComponent shwSLA={true} shwDwnBtn={true}></LegendComponent>
        <div style={{ height: "45px" }}></div>
        <div className="flex-container">
          {summaryDataBlocks.map((el, i) => {
            return <Box data={el} click={true} SLAReportHandleClick={SLAReportHandleClick} />;
          })}
        </div>
      </Tab>
      <Tab eventKey="month" title="Month Details" key="month">
        <LegendComponent shwSLA={true} shwDwnBtn={true}></LegendComponent>
        <div style={{ height: "45px" }}></div>
        <div className="flex-container">
          {/* {monthDataBlocks.map((el, i) => {
            return <TableBox data={el} />;
          })} */}
          {monthData.map((el, i) => {
            return <TableBox data={el} />;
          })}
        </div>
      </Tab>
      <Tab eventKey="week" title="Week" key="week">
        <LegendComponent shwSLA={true} shwDwnBtn={true}></LegendComponent>
        <div style={{ height: "20px" }}></div>
        <TabComponent tabKey={tbkey} tabChange={onSubTabChange}></TabComponent>
        <div style={{ height: "20px" }}></div>
        <div className="flex-container">
          {isTblVisible ? (
            weeklyData?.map((data, indx) => (
              <RptTable
                headers={data?.headers}
                rows={data?.rows}
                tblName={data?.tblHdrName}
                colIndicator="2"
              />
            ))
          ) : null}
        </div>
      </Tab>
      <Tab eventKey="day" title="Day" key="day">
        <LegendComponent shwSLA={true} shwDwnBtn={true}></LegendComponent>
        <div style={{ height: "20px" }}></div>
        <TabComponent tabKey={tbkey} tabChange={onSubTabChange}></TabComponent>
        <div style={{ height: "20px" }}></div>
        <div className="flex-container">
          {isTblVisible ? (
            dailyData?.map((data, indx) => (
              <RptTable
                headers={data?.headers}
                rows={data?.rows}
                tblName={data?.tblHdrName}
                colIndicator="1"
              />
            ))
          ) : null}
        </div>
      </Tab>
      <Tab eventKey="year" title="Year" key="year">
        <LegendComponent shwSLA={true} shwDwnBtn={true}></LegendComponent>
        <div style={{ height: "20px" }}></div>
        <TabComponent tabKey={tbkey} tabChange={onSubTabChange}></TabComponent>
        <div style={{ height: "20px" }}></div>
        <div className="flex-container">
          {isTblVisible ? (
            annuallyData?.map((data, indx) => (
              <RptTable
                headers={data?.headers}
                rows={data?.rows}
                tblName={data?.tblHdrName}
                colIndicator="1"
              />
            ))
          ) : null}
        </div>
      </Tab>
    </Tabs>
  );
};

export default TabBarMenu;
