export const UploadActionTypes = {
    SET_SELECTED_VOLUME_FIELDS: "SET_SELECTED_VOLUME_FIELDS",
    SET_SELECTED_SLAKPI_FIELDS: "SET_SELECTED_SLAKPI_FIELDS",
    SET_SELECTED_ACCOUNT_DETAILS: "SET_SELECTED_ACCOUNT_DETAILS",
    SET_SELECTED_SLAKLP_VOLUME_TAB:"SET_SELECTED_SLAKLP_VOLUME_TAB",
    SET_SELECTED_VOICE_NONVOICE_TAB: "SET_SELECTED_VOICE_NONVOICE_TAB",
    SET_SELECTED_VOICE_NONVOICE_ITEM: "SET_SELECTED_VOICE_NONVOICE_ITEM",
             SET_DATA_ADMINISTRATION_VALIDATION:"SET_DATA_ADMINISTRATION_VALIDATION",
    SET_SELECTED_VOLUME_SLA_DATE:"SET_SELECTED_VOLUME_SLA_DATE",

    GET_UPLOAD_ACCOUNTS: "GET_UPLOAD_ACCOUNTS",
    UPLOAD_ACCOUNTS: "UPLOAD_ACCOUNTS",

    GET_SLAKPI_VOLUME_TEMPLATES: "GET_SLAKPI_VOLUME_TEMPLATES",
    SLAKPI_VOLUME_TEMPLATES: "SLAKPI_VOLUME_TEMPLATES",

    UPDATE_SELECTED_SLAKPI_VOLUME_START: "UPDATE_SELECTED_SLAKPI_VOLUME_START",
    UPDATE_SELECTED_SLAKPI_VOLUME_SUCCESS: "UPDATE_SELECTED_SLAKPI_VOLUME_SUCCESS",

    UPDATE_CUSTOM_TEMPLATE_START: "UPDATE_CUSTOM_TEMPLATE_START",
    UPDATE_CUSTOM_TEMPLATE_SUCCESS: "UPDATE_CUSTOM_TEMPLATE_SUCCESS",

    GET_FILE_UPLOAD_DATA: "GET_FILE_UPLOAD_DATA",
    FILE_UPLOAD_DATA: "FILE_UPLOAD_DATA",

    UPDATE_FILE_UPLOAD_DATA_START: "UPDATE_FILE_UPLOAD_DATA_START",
    UPDATE_FILE_UPLOAD_DATA_SUCCESS: "UPDATE_FILE_UPLOAD_DATA_SUCCESS",

    GET_NONVOICE_CARDS_DATA: "GET_NONVOICE_CARDS_DATA",
    NONVOICE_CARDS_DATA: "NONVOICE_CARDS_DATA",

    GET_UPLOAD_PRIVIEW_DATA: "GET_UPLOAD_PRIVIEW_DATA",
    UPLOAD_PRIVIEW_DATA: "UPLOAD_PRIVIEW_DATA",

    GET_VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA: "GET_VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA",
    VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA: "VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA",

    UPDATE_VOLUME_SLAKPI_DATA_START: "UPDATE_VOLUME_SLAKPI_DATA_START",
    UPDATE_VOLUME_SLAKPI_DATA_SUCCESS: "UPDATE_VOLUME_SLAKPI_DATA_SUCCESS",



};

