import React, { useEffect } from "react";
import AboveRequiredTargetIcon from "../../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";
import ContractualSLAIcon from "../../../assets/images/Contractual_SLAs.png";
import CustomWidgetsIcon from '../../../assets/images/CustomWidgets_Icon.png';
import { Row } from "react-bootstrap";
import Box from "../../Box/box";
import './WelcomePage.css'
import { useHistory } from 'react-router-dom'
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../actions/user.action";
import { getAccountDropdown, getAccountSummary, getStoredAccount, getWidgetInfo, saveSelectedAccountObj, saveSelectedCustIDServID } from "../../../actions/welcomePage.action";
import { RecentLinks, HelpCenterLinks, CarouselHandler, NOTIFICATIONS } from "./RecentLinks";
import AccountDropdown from "./AccountDropdown";
import { moment } from 'moment';
import { updateSelectedSLA } from './../../../actions/regionalSite.action';
// import { saveSelectedCustIDServID } from './../../../actions/welcomePage.action';

const WelcomePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let AccountSummaryInfo = useSelector((state) => state.welcomePageReducer.AccountSummary);
  let AccountDropdownData = useSelector((state) => state.welcomePageReducer.AccountDropdown);
  let storedAccount = useSelector((state) => state.welcomePageReducer.storedAccount);
  console.log('storedAccount',storedAccount)
  useEffect(() => {
    dispatch(getUserData());
   // dispatch(getWidgetInfo());
    dispatch(getStoredAccount())
  }, [dispatch]);


  

// const [storedAccountFilter,setStoredAccountFilter] = useState(storedAccountFilter2);
  useEffect(() => {
    let storedAccountFilter = AccountDropdownData.filter((el) =>{
      return el.CustomerName === storedAccount.account && el.ServiceDeskName === storedAccount.site
    }
    );
    if(Object.keys(storedAccountFilter).length === 0)
    {

    }
    else{

   
    setSelectedOption(storedAccountFilter?.[0].CustomerName);
    
    let selectedDropdownValue = {
      CustomerID : storedAccountFilter?.[0].CustomerID,
      CustomerName : storedAccountFilter?.[0].CustomerName,
      ServiceDeskID : storedAccountFilter?.[0].ServiceDeskID,
      ServiceDeskName : storedAccountFilter?.[0].ServiceDeskName
    }
    dispatch(saveSelectedCustIDServID(selectedDropdownValue))
   setSelectedOption(storedAccountFilter?.[0]);
   setNullWidgets(true)
    let noOfWidgets = [];
    let temp_no = [];
    if (storedAccountFilter?.[0].Widgets[0].Selected === true) {
      noOfWidgets.unshift(storedAccountFilter?.[0].Widgets[0].Name);
      temp_no.unshift(storedAccountFilter?.[0].Widgets[0].WidgetID);
    }
    if (storedAccountFilter?.[0].Widgets[1].Selected === true) {
      noOfWidgets.unshift(storedAccountFilter?.[0].Widgets[1].Name);
      temp_no.unshift(storedAccountFilter?.[0].Widgets[1].WidgetID);
    }
    setWidgetSelected(noOfWidgets);
    setSelectedWidgetIds(temp_no);
    dispatch(getAccountSummary(storedAccountFilter?.[0].CustomerID,storedAccountFilter?.[0].ServiceDeskID));
   
   }
}, [dispatch,AccountDropdownData,storedAccount]);



 // const [selectedOption, setSelectedOption] = useState();
  const [selectedOption, setSelectedOption] = useState([]);
  const [widgetSelected, setWidgetSelected] = useState([]);
  const [selectedWidgetIds, setSelectedWidgetIds] = useState([]);
  const [nullWidgets, setNullWidgets] = useState(false);
  // const dropdownSelectHandle = (account) => {
  //   console.log('Selectedaccount:::::',account)
  //   if(Object.keys(account).length === 0)
  //   {

  //   }
  //   else{
  //   setSelectedOption(account.CustomerName);
  //   let selectedDropdownValue = {
  //     CustomerID : account.CustomerID,
  //     CustomerName : account.CustomerName,
  //     ServiceDeskID : account.ServiceDeskID,
  //     ServiceDeskName : account.ServiceDeskName
  //   }
  //   dispatch(saveSelectedCustIDServID(selectedDropdownValue))
  //   setSelectedOption(account);
  //   setNullWidgets(true)
  //   let noOfWidgets = [];
  //   let temp_no = [];
  //   if (account.Widgets[0].Selected === true) {
  //     noOfWidgets.unshift(account.Widgets[0].Name);
  //     temp_no.unshift(account.Widgets[0].WidgetID);
  //   }
  //   if (account.Widgets[1].Selected === true) {
  //     noOfWidgets.unshift(account.Widgets[1].Name);
  //     temp_no.unshift(account.Widgets[1].WidgetID);
  //   }
  //   setWidgetSelected(noOfWidgets);
  //   setSelectedWidgetIds(temp_no);
  //   dispatch(getAccountSummary(account.CustomerID,account.ServiceDeskID));
  // //  dispatch(getAccountDropdown())
  // }
  // }
  
  const AccountWidgetsDataHandler = () => {
    switch (widgetSelected.length) {
      case 1: {
        if (widgetSelected[0] === 'Telephony') {
          let data = AccountSummaryInfo?.blocks?.filter(data => data.name === 'Telephony');
        return (data?.map(data => <Box data={data} SLAReportHandleClick={SLAReportHandleClick} click={true}/>));
      }
        if (widgetSelected[0] === 'Quality') {
          let data = AccountSummaryInfo?.blocks?.filter(data => data.name === 'Quality');
          return (data?.map(data => <Box data={data} SLAReportHandleClick={SLAReportHandleClick} click={true}/>));
        }
        break;
      }

      case 2: {
        return AccountSummaryInfo?.blocks?.map(box => <Box data={box} style={{ width: '96%' }} SLAReportHandleClick={SLAReportHandleClick} click={true}/>)
      }

      case 0: {
        return (
          <div className="box_alt">
            <div className="box_alt_text"> {nullWidgets ? "No records found. You have not selected any widgets for this account." :
              "Please select any Account from the dropdown"}
              <br /> To add/edit a widget, click the “Custom Widgets” link below.</div>
            <div className="box_alt_text_img" >
              <span style={{ marginRight: 10 }}><img src={CustomWidgetsIcon} alt='img' /></span>
              <span onClick={() => history.push('/welcome/filter')}>Custom Widgets</span>
            </div>
          </div>
        )
      }


      default: <>
      </>

    }

  }
  const getDeployedDates = () => {
    let d = new Date()
    let year = d.getFullYear();
    let month = d.toLocaleString('default', { month: 'long' });
    let day = d.getDate();
    return `${month} ${day},  ${year}`
}

 // const formatHandle = (value) => {
  //   if (value.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && value?.includes('%')) return 'Percentage';
  //   else if (moment(value, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A'], true)?.isValid()) return 'Time';
  //   else if (!isNaN(value)) return 'Number'
  // }

const formatHandle = (value) => {
  const uuuu = (!isNaN(value) ) ? 'Number'  : (value.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && value?.includes('%')) ? 'Percentage' : 'Time'
  return uuuu
}
const SLAReportHandleClick = (item) => {
  let temp = {
    slaItem: item?.infoName,
    item:item?.itemname,
    format: formatHandle(item?.percentage)
  }
  dispatch(updateSelectedSLA(temp));
  (item.pagetype === 'target') ? history.push('/answer') : history.push('/dtm');
}

  return (
    <React.Fragment>
      <br />
      <div className="john">Welcome, {userInfo.name}</div>
      <section className="full">
        <Row>
          <CarouselHandler />
          <RecentLinks />
          <HelpCenterLinks />
          <NOTIFICATIONS />
        </Row>
        <br />
        <AccountDropdown/>
        <main>
          <section className="legend_container">
            <span id='legend'>Legend: </span>
            <img src={AboveRequiredTargetIcon} alt='' />Above Required Target
            <img src={AboveMinimalTargetIcon} alt='' />Above Minimal Target
            <img src={BelowMinimalTargetIcon} alt='' />Below Minimal Target
            <img src={ContractualSLAIcon} alt='' />Contractual SLAs
          </section>
           <div style={{ margin: '20px' }}>
            {/* <b>Account Selected:</b> <strong style={{ color: '#5F249F' }}>{selectedOption.CustomerName}</strong> */}
            <b>Account Selected:</b> {selectedOption.length === 0 ? '' : <strong style={{ color: '#5F249F' }}>{selectedOption.CustomerName} ({selectedOption.ServiceDeskName}) - {getDeployedDates()} </strong>}
          </div>
          {(AccountSummaryInfo?.blocks?.length === 0 && selectedWidgetIds.length > 0) && <div className='noData-para' style={{ width: '96%' }} >
            <div className='noData-para-span'
              style={{ fontSize: '18px', marginLeft: '30%' }}>No records are available. Please select other Accounts.</div>
          </div>
          }
          <br /> <br />
          <div className="flex-container" >
            {AccountWidgetsDataHandler()}
          </div>
        </main>
      </section>
    </React.Fragment>
  )
}

export default WelcomePage