import React, { useEffect, useRef } from 'react'
import './AccountAdmin.css';
import Admindata from './Admin.json';
import menuIcon from '../../../assets/images/Dotted_ContextMenu_Icon.png';
import ActivateDisabledIcon from '../../../assets/images/Activate_Disabled_Icon.png';
import ActivateIcon from '../../../assets/images/Activate_Icon.png';
import StaffAdministration_Icon from '../../../assets/images/StaffAdministration_Icon.png';
import searchIcon from './../../../assets/onboard-images/Search_Icon.png';
import edit_Icon from '../../../assets/images/Edit_Icon.png';
import SLADataExclusion_Icon from '../../../assets/images/SLADataExclusion_Icon.png';
import SLAFailureExemption_Icon from '../../../assets/images/SLAFailureExemption_Icon.png';
import Eye_ViewDetails_Icon from '../../../assets/onboard-images/Eye_ViewDetails_Icon.png';

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ActivateModelPopup, ActivationRequestModal, RequestSentModal } from './ActivationModel';
import AdminBreadcrumb from '../General/Admin-breadcrumb';
import TooltipHandler from '../../Onboard/OnboardComponents/TooltipHandler';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminAccounts, getRoleBasedView, saveStaffAdminActiveTab, updateAccountDetails } from '../../../actions/Administration/admin.action';
import { saveAccountSite, viewAccountDetails } from '../../../actions/Onboard.action';
import { getMappedEmployees } from '../../../actions/Administration/staffAdmin.action';
const AdminLanding = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  let AdminAccounts = useSelector((state) => state.adminAccountReducer.AdminAccounts);
  const mappedEmployees = useSelector((state) => state.staffAdminReducer.mappedEmployees);
  const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch])
  const [filter, setFilter] = useState('');
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [showRequestSent, setShowRequestSent] = useState(false);
  const [searchColumns, setSearchColumns] = useState([
    'Account Name', 'Site Name', 'Created By'
  ]);
  function searchRows(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]
            .toString()
            .toLowerCase()
            .indexOf(filter.toLowerCase()) > -1,
      ),
    );
  };

  function formatDate(date) {
    if(date === null){
return <div style={{color:'black'}}> not defined </div>
    }
    else{
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
    }
  }
  useEffect(() => {
    dispatch(getAdminAccounts());
    dispatch(getMappedEmployees());
  }, [dispatch]);

const [adminData,setAdminData] = useState([]);

useEffect(() => {
  setAdminData((AdminAccounts.statusCode) ? [] : AdminAccounts)
}, [dispatch,AdminAccounts]);


  const menuOptions = [
    {
      id: 1,
      imageSrc: SLAFailureExemption_Icon,
      name: 'SLA Failure Exemption',
      value: "admin/Exemption"
    }, 
    // {
    //   id: 2,
    //   imageSrc: SLADataExclusion_Icon,
    //   name: 'SLA Data Exclusion',
    //   value: "admin/Exclusion"
    // },
     {
      id: 3,
      imageSrc: edit_Icon,
      name: 'Edit',
      value: "admin/account"
    }
  ];


  const [selectedAccount, setSelectedAccount] = useState({});
  const [openDownload, setOpenDownload] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);

  const onClickAccountRow = (data, id) => {
    let temp_arr = {
      AccountName: data?.['Account Name'],
      SiteName: data?.['Site Name'],
      ValidFrom: formatDate(data?.['Valid From']),
      ValidTo: formatDate(data?.['Valid To']),
      mode: 'edit',
      CustomerID:data?.CustomerID,
      ServiceDeskID: data?.ServiceDeskID
    }
    switch (data?.Status) {
      case 'Active': {
        setDropdownIndex(id)
        setSelectedAccount(data);
        dispatch(viewAccountDetails(temp_arr));
        dispatch(updateAccountDetails(temp_arr));
        dispatch(saveAccountSite(temp_arr));
        setOpenDownload(true);
        break;
      }
      case 'Inactive': {
        if (data?.Request !== 'Sent') {
          setSelectedAccount(data);
          setShowRequestForm(true);
          setShowRequestSent(false);
        }
        break;
      }
      default: { }
    }
  }


  const dropDownref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownref.current && !dropDownref.current.contains(event.target)) {
        if (!openDownload)
          setOpenDownload(openDownload);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropDownref]);

  const AccountViewDetailsHandle = (data,id) => {
    let temp_arr = {
      AccountName: data?.['Account Name'],
      SiteName: data?.['Site Name'],
      ValidFrom: formatDate(data?.['Valid From']),
      ValidTo: formatDate(data?.['Valid To']),
      mode: 'view',
      CustomerID:data?.CustomerID,
      ServiceDeskID: data?.ServiceDeskID
    }
    dispatch(viewAccountDetails(temp_arr))
   // dispatch(updateAccountDetails(temp_arr));
    dispatch(saveAccountSite(temp_arr));
    history.push('/admin/account')
  }
 
  
  return (
    <div>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={false} label2='Administration' />
      <header className='administration_text'>Administration</header>
    <section>
        <main className="container_acc" style={{ width: "94%", left: 10, marginTop: 20 }} >
          <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={()=>setOpenDownload(false)}><div className="accounts__text" >Accounts</div>
            <div> <span className='search-bar-role' style={{ marginTop: 10, marginRight: 20 }}>
              <input className='search-input-role' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Accounts' />
              <button className='search-icon'><img src={searchIcon} alt='' /> </button>
            </span></div>
          </div>
          {searchRows(adminData)?.length === 0 ?
          <section>
            <table style={{ width: '100%', marginTop: '1em' }} className='queue-table'>
              <thead className='admin_trclass'>
                <tr className='admin_trclass'>
                  {Admindata.headers.map(header => (<th className='table_head_admin' key={header.id}> {header.label}</th>))}
                </tr>
              </thead>
            </table>
            <section className='noData-table'>
              <div className='noData-para' style={{ marginTop: 0 }}>
                <div style={{ marginTop: 30 }}>
                  No records found.<br /> You need to have account level access. Please refer to this <span className='browse_link' onClick={()=>history.push('/getstarted')}>page</span> for more information regarding the access permissions. 
                </div>
              </div>
            </section>
          </section>
          :
          <section className='table-section' >
            <table className='report-table' style={{ width: '100%', maxHeight: '519px', paddingLeft: 20 }}>
              <thead className='admin_trclass'>
                <tr className='admin_trclass' onClick={()=>setOpenDownload(false)}>
                  {Admindata.headers.map(header => (<th className='table_head_admin' key={header.id}> {header.label}</th>))}
                </tr>
              </thead>
              <tbody className='admin_trclass'>
                {searchRows(adminData).map((info, rowId) => (
                <>  <tr className='admin_trclass' key={rowId} >
                    <td className='tbody_admin' onClick={()=>setOpenDownload(false)}><div><img
                    style={{maxHeight:'40px',maxWidth:'60px'}}
                    // height='20px'
                    // width='60px'
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/org/customer/${info?.CustomerID}/photo`}
                  alt="Client Logo"
                /></div></td>
                    <td className='tbody_admin' onClick={()=>setOpenDownload(false)}>{info?.['Account Name']}</td>
                    <td className='tbody_admin' onClick={()=>setOpenDownload(false)}>{info?.['Site Name']}</td>
                    <td className='tbody_admin' onClick={()=>setOpenDownload(false)}>{info?.['Created By']}</td>
                    <td className='tbody_admin' onClick={()=>setOpenDownload(false)}>{formatDate(info?.['Created Date'])}</td>
                    <td className='tbody_admin' onClick={()=>setOpenDownload(false)}>{formatDate(info?.['Valid From'])}</td>
                    <td className='tbody_admin' onClick={()=>setOpenDownload(false)}>{formatDate(info?.['Valid To'])}</td>
                    <td className='tbody_admin extra_td' onClick={()=>setOpenDownload(false)}> <div id='active' style={{ backgroundColor: info?.Status === 'Active' ? '#6CC24A' : '#FF0000' }}></div>  {info?.['Status']}</td>
                    {info?.['Access'] === 'Read' ? <td className='tbody_admin' onClick={() => AccountViewDetailsHandle(info,info?.RowNo)}><img src={Eye_ViewDetails_Icon} alt='' style={{ cursor: 'pointer' }} /></td> : 
                    <><td className='tbody_admin' onClick={() => onClickAccountRow(info, rowId)}>{
                      info?.Status === 'Active' ? <>
                       <img src={menuIcon} alt='' style={{ cursor: 'pointer' }} />
                        <div style={{position:'relative'}}>
                        {(openDownload && dropdownIndex === rowId) && (
                          <ul className='menu_options' >
                            {menuOptions.map(data => (
                              <li className='li_options' onClick={() => history.push(data.value)} >
                                <img src={data.imageSrc} alt='' /> {data.name} </li>)
                            )}
                          </ul>)}</div>
                      </> : <>
                        {info?.Request !== 'Sent' ?
                          <span><TooltipHandler src={ActivateIcon} position='top right'
                            arrowAlign='end' textBoxWidth='260px' text='Request for Account Activation' /> </span>
                          : <span > <TooltipHandler src={ActivateDisabledIcon} position='top right'
                            arrowAlign='end' textBoxWidth='340px' text='Activation request sent to administrator' /></span>}
                      </>} </td></>} </tr>
                     </>
                ))}
              </tbody>
              <div className='extra_tr' onClick={()=>setOpenDownload(false)}></div>
            </table>
            {showRequestForm && <ActivateModelPopup isOpen={setShowRequestForm} setShowModal={setShowRequestForm} style={{ height: '300px' }}>
              <ActivationRequestModal
                handleSubmitClick={() => {
                  setShowRequestForm(false);
                  setShowRequestSent(true);
                }} data={selectedAccount}  handleinvalidSubmitClick={() => { setShowRequestForm(true) }}
                handleCancelClick={() => setShowRequestForm(false)}
              />
            </ActivateModelPopup>}

            {showRequestSent && <ActivateModelPopup isOpen={setShowRequestSent} setShowModal={setShowRequestSent}>
              <RequestSentModal data={selectedAccount}
                handleClose={() => {
                  setShowRequestSent(false);
                  setShowRequestForm(false);
                  dispatch(getAdminAccounts())
                }}
              />
            </ActivateModelPopup>}
          </section>}
        </main>
      </section > 
     
     {RoleBasedView?.[0]?.StaffAdmin?.[0]?.Read === 1 ? <main className='staff_card' onClick={()=>setOpenDownload(false)}>
        <div style={{ flex: 1, marginLeft: 20 }}><img src={StaffAdministration_Icon} alt='' /></div>
        <div className='staff_admin_flex'><span className='staff_admin'>Staff Administration</span><br />
          <span className='staff_text'>Detailed administration of Employees (CCOW, RCOW).
            Data maintenance in relation to Role registration, Account assignments,
            Channel/Tool/Identifier  utilisation, Scorecard KPI/SLA assignments.</span>
        </div>
        <div style={{ flex: 3 }}>
          <span className='mapped_emp'>Mapped Employees</span><br />
          <div style={{ display: 'flex', justifyContent: 'left' }}><span className='mapped_no'>{mappedEmployees?.length}</span><span className='staff_map' onClick={() => {history.push('./admin/staff');dispatch(saveStaffAdminActiveTab('mapped'))}}>VIEW STAFF MAPPING</span></div>
        </div>
      </main> : ''}
    </div >
  )
}

export default AdminLanding