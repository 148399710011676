import React from 'react'
import './DesignPrinciples.css'
const DesignPrinciples = () => {

  const OFFICE_SITE = 'https://github.dxc.com/pages/ArchitectureOffice/Standards/Design_Principles/';
  return (
    <div>
    
        <section className='deve_principle'>
        <div style={{width:1200,margin:'auto'}}>
        <div id='title_devlop'>Design Principles</div>
        <div id='text_padding'>DXC Technology’s design principles guide DXC technologists and partners who are designing or developing a DXC product, offering, solution or service.</div>
        <div id='text_padding'>The design principles also offer a look into the heart and soul of DXC - how we work and approach problems. All contributors should understand the principles and adhere to them in their projects.</div>
        <div id='text_padding'>For the latest principles and guidelines please visit the <span id='link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={OFFICE_SITE} title=''>Architecture Office site</a></span></div>
      </div> </section>   
   </div>
  )
}

export default DesignPrinciples;