const GetAccountsHandler = (items) => {
    console.log('vvvvvvvv',items.agents === '')
    if(items.agents === '' || items.agents === null || items.agents === undefined){
      return  `/api/usp/ReportsTLGetAccounts`
    }
    else{
       return  `/api/usp/ReportsTLGetAccounts?${items.agents}`
    }
}


export const ScoreCardUrls = (name, items) => {
    switch (name) {
        case 'GET_SCORECARD_DATA': {
            return `/api/usp/GetAgentScorecardData?Date=${items.selecteddate}&agent=${items.agent}`
        }
        case 'GET_SCORECARD_PERFORMANCE_DATA': {
            return `/api/usp/Agent_MyScorecard`
        }
        case 'GET_SCORECARD_OFFCANVAS_DATA': {
            return `/api/usp/GetAgentAccounts?agent=${items.agent}`
        }
        case 'GET_AGENTS_KPI_SUMMARY_DATA': {
            return `/api/usp/ReportsTeamLeader?Team_KPI=${items.selectedtab}&YearMonth=${items.date}&${items.name}`
        }
        case 'GET_TEAMLEADER_AGENTS': {
            return `/api/usp/ReportsTLGetAgents?${items.accountID}`
        }
        case 'GET_TEAMLEADER_ACCOUNTS': {
            return GetAccountsHandler(items)
        }
        case 'GET_TEAMLEADER_ACCOUNTS_VIEW': {
            return '/api/usp/ReportsTLGetAccounts'
        }
        case 'GET_TEAMLEADER_DATES': {
            return `/api/usp/ReportsTLGetYearMonth`
        }
        case 'GET_AGENTS_KPI_ACCOUNTS_DATA': {
            return `/api/usp/ReportsTLGetAccountsByAgent?Agent=${items.email}`
        }
        case 'GET_TEAM_KPI_SUMMARY_DATA': {
            return `/api/usp/ReportsTeamKPISummary`
        }
        case 'GET_AGENTS_GRAPH_DATA': {
            return `/api/usp/ReportsTLAgentGraph?agent=${items.agent}&Team_KPI=${items.teamKPI}&metric=${items.metric}&month=${items.date}`
        }
        
        default: { }
    }

}   