import React, { useEffect, useRef, useState } from 'react';
// import './Select.css'
import downward_icon from './../../../../assets/images/downward_arrow.png';
import upward_icon from './../../../../assets/images/upward_arrow.png';
import contractIcon from './../../../../assets/onboard-images/Contractual_SLA_Icon.png'


export function OnboardSelect(props) {
    const { data, value, id, className, name, onChange, editValue,refresh } = props;
    const [openData, setOpenData] = useState(false);
    const [Inputvalue, setInputValue] = useState(props.inputvalue);
    const [query, setQuery] = useState('');
    const filterQuery = options => {
        return options?.filter(option => {
            if (query === '') return option;
            else if (option[value]?.toLowerCase().includes(query.toLowerCase().trim()))
                return option;
        })
    }

    useEffect(()=>{
        if(refresh===true){
            setInputValue('')
        } 
    },[refresh])

    useEffect(() => {
        if (!openData) setQuery('')
    }, [openData])

    useEffect(() => {
        setInputValue(editValue);
    }, [editValue, name, setInputValue]);

    const dropdownHandler = (event, option) => {
        setInputValue(option[value]);
        setOpenData(!openData);
        props.addValueToQueue(name, option[value], option[id]);
    }
    const dropDownref = useRef();
    const inputValueRef = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownref.current && !dropDownref.current.contains(event.target)) {
                if (!openData)
                    setOpenData(openData);
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropDownref]);

    return (
        <React.Fragment>
            <section className={className}>
                <div className=' wrapper'>
                    <input className='input-field' type='text' name={name} style={{ marginTop: props.marginTop }}
                        value={Inputvalue || query}
                        autoComplete="off"
                        onChange={e => {
                            setQuery(e.target.value);
                            setOpenData(true);
                            setInputValue(null)
                        }}
                        onClick={() => {
                            setOpenData(!openData);
                        }}
                        placeholder='Select'
                    />
                    <div className='button-arrow arrow-btn'
                        onClick={() => {
                            setOpenData(!openData);
                        }}
                        ref={dropDownref}
                    >
                        {!openData ? <img src={downward_icon} alt="arrow" /> :
                            <img src={upward_icon} alt="arrow" />}
                    </div>
                </div>
                <div>
                    {openData && <ul className={`ul-wrapper`}>
                        {filterQuery(data)?.map(option =>
                        (<li value={option?.[value]} key={option?.[id]} className='list-wrapper' ref={inputValueRef}
                            onClick={(event) => dropdownHandler(event, option)} >
                            <label className='label-region' htmlFor={option?.[id]}>  {option?.[value]} </label>
                            {option?.Contractual === 'Yes' && <span style={{ marginTop: 10, marginRight: 10 }}><img src={contractIcon} alt='' /></span>}
                        </li>)
                        )}
                    </ul>}
                </div>
            </section>
        </React.Fragment>
    )
}
