import React from 'react'
import DeveloperOffcanvas from './DeveloperOffcanvas';
import { useHistory } from 'react-router-dom';
import { Footer } from '../HeaderFooter';
import InfraStructure from './DeveloperCards/InfraStructure';
import { useSelector } from 'react-redux';
import DeveloperAccess from './DeveloperCards/DeveloperAccess';
import DeveloperGuidelines from './DeveloperCards/DeveloperGuidelines';
import DevelopmentEnvironment from './DeveloperCards/DevelopmentEnvironment';
import DocumentationStandards from './DeveloperCards/DocumentationStandards';
import ReleaseandBuildGuides from './DeveloperCards/ReleaseandBuildGuides';
import ProductMaintenance from './DeveloperCards/ProductMaintenance';
import DeveloperSupport from './DeveloperCards/DeveloperSupport';
import DesignPrinciples from './DeveloperCards/DesignPrinciples';



function DeveloperCards() {
    const history = useHistory();
    const developerCard = useSelector(state => state.githubReducer.developerCard)
    const DeveloperCardDisplay = () => {
        switch (developerCard.text) {
            case 'infra': return <InfraStructure />
            case 'access': return <DeveloperAccess />
            case 'guidelines': return <DeveloperGuidelines />
            case 'environment': return <DevelopmentEnvironment />
            case 'standard': return <DocumentationStandards />
            case 'release': return <ReleaseandBuildGuides />
            case 'product': return <ProductMaintenance />
            case 'support': return <DeveloperSupport />
            case 'principle': return <DesignPrinciples />
            default: { }
        }
    }
    return (
        <>
            <section style={{ marginTop: 0, marginLeft: -100 }}>
                <div className='breadcrumb_dev'>
                    <section>
                        <span style={{ color: '#5F249F', cursor: 'pointer' }} onClick={() => history.push('/githublanding')}>Home</span>
                        <span style={{ color: '#5F249F', cursor: 'pointer' }} onClick={() => history.push('/developer')} >{` / `} Developer</span>
                        <span style={{ color: '#63666A' }}>{` / `} {developerCard.title}</span>
                    </section>
                    <section><DeveloperOffcanvas /></section>
                </div>
                {DeveloperCardDisplay()}
                <Footer />
            </section>
        </>
    )
}

export default DeveloperCards