import actionTypes from "../constants/actionTypes";

export const getFinanceData = (data) => ({
  type: actionTypes.GET_FINANCE_DATA,
  financeID: data.financeID,
  filterID: data.filterID
});


export const getDropdownData = (data) => ({
  type: actionTypes.GET_DROPDOWN_DATA,
  payload: data,
});


export const getAccountData = (data) => ({
  type: actionTypes.GET_ACCOUNT_DATA,
  financeID: data.financeID,
  filterID: data.filterID

});
