import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateGeneralNonvoiceStart } from '../../../../actions/Onboard.action'
import { OnboardSelect } from '../../OnboardComponents/Dropdown/Select'
import closeIcon from './../../../../assets/onboard-images/CloseWindow_Hover_State.png'
import yesIcon from './../../../../assets/onboard-images/Complete_Yes_Icon.png'

import './General.css'
const options = [
    { id: 1, value: 'Yes' },
    { id: 2, value: 'No' },

]
const options2 = [
    { id: 1, value: 'Manual' },
    { id: 2, value: 'Semi-Automated' },
    { id: 3, value: 'Automated' },
]
const General = (props) => {

    const dispatch = useDispatch();
    const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
    let data = (props.GeneralNonvoicedata?.length > 0 ? (props.GeneralNonvoicedata) : []);

    const [GeneralData, setGeneralData] = useState([]);

    const [mode, setMode] = useState(null);
    const [doesDataExist, setDoesDataExist] = useState(null);

    const [AddFormGeneral, setAddFormGeneral] = useState();
    const [editFormGeneral, setEditFormGeneral] = useState([{
        system: '',
        version: '',
        private: '',
        multi: '',
        party: '',
        type: '',
        extract: '',
        remarks: '',
    }]);
    const [validation, setValidation] = useState('');
    // const [buttonOne, setButtonOne] = useState('');
    // const [buttonTwo, setButtoTwo] = useState('');
    const addValueToGeneral = (name, value, id) => {
        if (mode === 'create') {
            const newFormData = { ...AddFormGeneral };
            newFormData[name] = value;
            setAddFormGeneral(newFormData);
        }
        if (mode === 'edit') {
            const newFormData = { ...editFormGeneral };
            newFormData[name] = value;
            setEditFormGeneral(newFormData);
        }
    }
    const [dataSaveBar, setDataSaveBar] = useState(false);
    useEffect(() => {
        setGeneralData(data)
        setDoesDataExist(props.GeneralNonvoicedata.length > 0 ? true : false)
        if (props.accountDetails.mode === 'edit') {
            setGeneralData([]);
            setDoesDataExist(false);
            let formValues = {
                system: props.GeneralNonvoicedata?.[0]?.["system Name"],
                version: props.GeneralNonvoicedata?.[0]?.["system Version"],
                private: props.GeneralNonvoicedata?.[0]?.["private System Owned by Client"],
                multi: props.GeneralNonvoicedata?.[0]?.["multifactor / Othersecurity"],
                party: props.GeneralNonvoicedata?.[0]?.["third Party System"],
                type: props.GeneralNonvoicedata?.[0]?.["current Type Data Extraction Method"],
                extract: props.GeneralNonvoicedata?.[0]?.["current Data Extraction Description"],
                remarks: props.GeneralNonvoicedata?.[0]?.["remarks"]
            }
            setEditFormGeneral(formValues);
        }
        setMode(props.accountDetails.mode)

    }, [props.GeneralNonvoicedata])

    const onChangeGeneralInput = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (mode === 'create') {
            const newFormData = { ...AddFormGeneral };
            newFormData[fieldName] = fieldValue;
            if (newFormData[fieldName] === null) setValidation('Please enter the value');
            else setValidation('')
            setAddFormGeneral(newFormData);
        }

        if (mode === 'edit') {
            const newFormData = { ...editFormGeneral };
            newFormData[fieldName] = fieldValue;
            if (newFormData[fieldName] === null) setValidation('Please enter the value');
            else setValidation('')
            setEditFormGeneral(newFormData);
        }
    }


    const onSubmitFormGeneral = (event) => {
        event.preventDefault();
        let temp_arr =
            [{
                NonVoiceItem: props.NonVoiceItem, Account: accountSiteInfo.AccountName, ServiceDesk: accountSiteInfo.SiteName,
                SystemName: AddFormGeneral.system, SystemVersion: AddFormGeneral.version,
                PrivateSystemOwnedbyClient: AddFormGeneral.private, MultifactorOthersecurity: AddFormGeneral.multi,
                ThirdPartySystem: AddFormGeneral.party, CurrentTypeDataExtractionMethod: AddFormGeneral.type,
                CurrentDataExtractionDescription: AddFormGeneral.extract, Remarks: AddFormGeneral.remarks
            }]
        let temp_valid = {
            SystemName: AddFormGeneral.system, SystemVersion: AddFormGeneral.version,
            PrivateSystemOwnedbyClient: AddFormGeneral.private, MultifactorOthersecurity: AddFormGeneral.multi,
            ThirdPartySystem: AddFormGeneral.party, CurrentTypeDataExtractionMethod: AddFormGeneral.type,
            CurrentDataExtractionDescription: AddFormGeneral.extract
        }

        const isEmpty = Object.values(temp_valid).some(temp => (temp === null || temp === '' || temp === undefined));
        if (isEmpty) {
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }
        else {
            setGeneralData(temp_arr);
            props.dispatchGeneralHandler(temp_arr)
            dispatch(updateGeneralNonvoiceStart(temp_arr))
            setValidation('');
            setDataSaveBar(true)
            setTimeout(() => setDataSaveBar(false), 5000)
        }
    }

    const onSubmitEditFormGeneral = (event) => {
        event.preventDefault();
        let temp_arr =
            [{
                NonVoiceItem: props.NonVoiceItem, Account: accountSiteInfo.AccountName, ServiceDesk: accountSiteInfo.SiteName,
                SystemName: editFormGeneral.system, SystemVersion: editFormGeneral.version,
                PrivateSystemOwnedbyClient: editFormGeneral.private, MultifactorOthersecurity: editFormGeneral.multi,
                ThirdPartySystem: editFormGeneral.party, CurrentTypeDataExtractionMethod: editFormGeneral.type,
                CurrentDataExtractionDescription: editFormGeneral.extract, Remarks: editFormGeneral.remarks
            }]
        let temp_valid = {
            SystemName: editFormGeneral.system, SystemVersion: editFormGeneral.version,
            PrivateSystemOwnedbyClient: editFormGeneral.private, MultifactorOthersecurity: editFormGeneral.multi,
            ThirdPartySystem: editFormGeneral.party, CurrentTypeDataExtractionMethod: editFormGeneral.type,
            CurrentDataExtractionDescription: editFormGeneral.extract
        }
        const isEmpty = Object.values(temp_valid).some(temp => (temp === null || temp === '' || temp === undefined));
        if (isEmpty) {
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }

        else {
            setGeneralData(temp_arr);
            props.dispatchGeneralHandler(temp_arr)
            dispatch(updateGeneralNonvoiceStart(temp_arr))
            setValidation('')
            setDataSaveBar(true)
            setTimeout(() => setDataSaveBar(false), 5000)
        }
        if (mode === 'create') setMode('edit')
    }

    const onClickGeneralInput = () => {
        setValidation('');
        setDoesDataExist(true);
        setDoesDataExist(props.GeneralNonvoicedata.length > 0 ? true : false)
        if (props.accountDetails.mode === 'edit' || props.accountDetails.mode === 'create') {
            setGeneralData([]);
            setDoesDataExist(false);
            let formValues = {
                system: props.GeneralNonvoicedata?.[0]?.["system Name"],
                version: props.GeneralNonvoicedata?.[0]?.["system Version"],
                private: props.GeneralNonvoicedata?.[0]?.["private System Owned by Client"],
                multi: props.GeneralNonvoicedata?.[0]?.["multifactor / Othersecurity"],
                party: props.GeneralNonvoicedata?.[0]?.["third Party System"],
                type: props.GeneralNonvoicedata?.[0]?.["current Type Data Extraction Method"],
                extract: props.GeneralNonvoicedata?.[0]?.["current Data Extraction Description"],
                remarks: props.GeneralNonvoicedata?.[0]?.["remarks"]
            }
            setEditFormGeneral(formValues);
        }
        if (Object.keys(props.GeneralNonvoicedata?.[0]?.length > 0)) setMode('edit')

    }
    const onEditGeneralInput = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...editFormGeneral };
        newFormData[fieldName] = fieldValue;
        if (newFormData[fieldName] === null) setValidation('Please enter the value');
        else setValidation('')
        setEditFormGeneral(newFormData);
    }
    return (
        <>
            <section>
                {dataSaveBar && <div className='dataSave_div'>
                    <div> <img src={yesIcon} alt='' /> &nbsp;&nbsp;Data has been saved successfully </div>
                    <div><img src={closeIcon} alt='' onClick={() => setDataSaveBar(false)} /></div>
                </div>}
                <div>
                    {(validation) && <div style={{ color: '#ED4F32' }}>{validation}</div>}

                    <form onSubmit={mode === 'edit' ? onSubmitEditFormGeneral : onSubmitFormGeneral}>
                        <main className='input-section-three'>
                            <div className='container_3'>
                                <label >System Name *</label>
                                {!doesDataExist ? <input
                                    style={{ backgroundColor: 'transparent' }}
                                    value={editFormGeneral.system !== undefined ? editFormGeneral.system : ''}
                                    placeholder='Example: System II SLA Withholder'
                                    type='text'
                                    name='system'
                                    onChange={mode === 'edit' ? onEditGeneralInput : onChangeGeneralInput}
                                /> :
                                    <div>
                                        <b>  {GeneralData?.[0]?.["system Name"]} </b>
                                    </div>}
                            </div>
                            <div className='container_3'>

                                <label HtmlFor="lang" >System Version *</label>

                                {!doesDataExist ? <input
                                    style={{ backgroundColor: 'transparent' }}
                                    value={editFormGeneral.version !== undefined ? editFormGeneral.version : ''}
                                    placeholder='Example: Version 1.0.0.18'
                                    type='text'
                                    name='version'
                                    onChange={mode === 'edit' ? onEditGeneralInput : onChangeGeneralInput}
                                /> :
                                    <div>
                                        <b> {GeneralData?.[0]?.["system Version"]} </b>
                                    </div>
                                }

                            </div>
                            <div className='container_3'>

                                <label HtmlFor="lang" >Private System Owned by Client *</label>

                                {!doesDataExist ?
                                    <OnboardSelect data={options} name='private' value='value' id='id'
                                        editValue={mode === 'edit' ? editFormGeneral.private : ''}
                                        addValueToQueue={addValueToGeneral}
                                    />
                                    // <RequestDropdown data={options} name='private' value='value' id='id'
                                    //     Inputvalue={mode === 'edit' ? editFormGeneral.private : ''}
                                    //     addValueToQueue={addValueToGeneral} refresh={''}
                                    // />
                                    :
                                    <div>
                                        <b> {GeneralData?.[0]?.["private System Owned by Client"]} </b>
                                    </div>
                                }
                            </div>
                        </main>
                        <main className='input-section-three'>
                            <div className='container_3'>
                                <label HtmlFor="lang" >Multifactor / Othersecurity *</label>

                                {!doesDataExist ? <OnboardSelect data={options} name='multi' value='value' id='id'
                                    editValue={mode === 'edit' ? editFormGeneral.multi : ''}
                                    addValueToQueue={addValueToGeneral}
                                /> :
                                    <div>
                                        <b> {GeneralData?.[0]?.["multifactor / Othersecurity"]} </b>
                                    </div>
                                }
                            </div>
                            <div className='container_3'>

                                <label HtmlFor="lang" >Third Party System *</label>

                                {!doesDataExist ? <OnboardSelect data={options} name='party' value='value' id='id'
                                    editValue={mode === 'edit' ? editFormGeneral.party : ''}
                                    addValueToQueue={addValueToGeneral}
                                /> :
                                    <div>
                                        <b> {GeneralData?.[0]?.["third Party System"]} </b>
                                    </div>
                                }
                            </div>
                            <div className='container_3' style={{ minWidth: '' }}>

                                <label HtmlFor="lang" >Current Type Data Extraction Method *</label>

                                {!doesDataExist ?  <OnboardSelect data={options2} name='type' value='value' id='id'
                                        editValue={mode === 'edit' ? editFormGeneral.type : ''}
                                        addValueToQueue={addValueToGeneral}
                                    />:
                                    <div>
                                        <b> {GeneralData?.[0]?.["current Type Data Extraction Method"]} </b>
                                    </div>
                                }

                            </div>
                        </main>
                        <main className='input-section-one'>
                            <div className='container_1'>
                                <label >Current Data Extraction Description *</label>
                                {!doesDataExist ? <input
                                    type='text'
                                    name='extract'
                                    placeholder='Example: The data is confined within permitted time'
                                    value={editFormGeneral.extract !== undefined ? editFormGeneral.extract : ''}
                                    onChange={mode === 'edit' ? onEditGeneralInput : onChangeGeneralInput}
                                /> :
                                    <div>
                                        <b> {GeneralData?.[0]?.["current Data Extraction Description"]} </b>
                                    </div>}
                            </div>
                        </main>
                        <main className='input-section-one'>
                            <div className='container_1'>
                                <label >Remarks</label>
                                {!doesDataExist ? <input
                                    type='text'
                                    name='remarks'
                                    placeholder='Example: /*** Remarks ***/'
                                    value={editFormGeneral.remarks !== undefined ? editFormGeneral.remarks : ''}
                                    onChange={mode === 'edit' ? onEditGeneralInput : onChangeGeneralInput}
                                /> :
                                    <div>
                                        <b>  {GeneralData?.[0]?.["remarks"]}</b>
                                    </div>
                                }
                            </div>


                        </main>
                        {/* <main >
                            <div className='upload_text'>Select a file to upload</div>
                            <div className='EXCEL_text'>You can upload only EXCEL (.XLS) files. Maximum upload file size: 5MB.</div>
                            <div id="upload-box" className='upload_width' >
                                <input type="file" name="myImage" id='file' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleUpload} />
                                <label for="file" style={{ cursor: 'pointer' }}>Drag & Drop
                                    or   BROWSE  your files</label>
                            </div>
                            <div style={{ color: 'red' }}>{error}</div>
                            {datacard && <div className='card__upload_xls'> <span>{file.name}</span>

                                <span >{(file.size) / 1024} KB <img src={deleteIcon} alt='del' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} /></span>
                            </div>}
                        </main> */}
                        {(mode === 'create') && <main className='input-container-four fixed_footer_span' style={{ marginLeft: '20%' }}>
                            <button type='submit' className='btn_adduser'>SUBMIT </button>
                            <button className='btn_canceluser' onClick={onClickGeneralInput}> CANCEL</button>
                        </main>}
                        {(mode === 'edit') && <main className='input-container-four fixed_footer_span' style={{ marginLeft: '20%' }}>
                            <button type='submit' className='btn_adduser'>  UPDATE </button>
                            <button className='btn_canceluser' onClick={onClickGeneralInput}>  CANCEL  </button>
                        </main>}


                    </form>
                </div>
            </section>
        </>
    )
}
export default General;