import React, { useEffect, useState } from 'react';
import './AgentsKPISummaryTabs.css';
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import { useHistory } from 'react-router-dom';
import AboveRequiredTargetIcon from "../../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";
import { Tab, Tabs } from 'react-bootstrap';
import FilterTableComponent from '../FilterAndTable/AgentsTableComponent';
import AgentsFilterComponent from '../FilterAndTable/AgentsFilterComponent';
import { saveSelectedTab } from '../../../actions/ScoreCard&TeamKPI.action';
import { useDispatch } from 'react-redux';

const AgentsKPISummaryTabs = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [key, setKey] = useState('Schedule / Attendance');

    const TabData = [
      {
        eventKey:"Schedule / Attendance",
        title:"Schedule / Attendance"
      },
      {
        eventKey:"Customer Experience",
        title:"Customer Experience"
      },
      {
        eventKey:"Quantitative",
        title:"Quantitative"
      },
      {
        eventKey:"Qualitative",
        title:"Qualitative"
      },
      {
        eventKey:"Account Specific KPIs",
        title:"Account Specific KPIs"
      },
      {
        eventKey:"AWS Telephony",
        title:"AWS Telephony"
      },

    ]
    useEffect(()=>{
      dispatch(saveSelectedTab("Schedule / Attendance"))
  },[dispatch])
  
    

    console.log('key',key)
  return (
    
       <div style={{paddingBottom:'200px',marginRight:20}}>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Reports' value2={()=>history.push('/regional')}
        isActive3={false} label3='Agents KPI Summary'
      /> 
      <section style={{marginLeft:'15px'}}>
      <main className='Agents_kpi_header'>Agents KPI Summary</main>
      <main className="legend_container_TL">
            <span id='legend'>Legend: </span>
            <img src={AboveRequiredTargetIcon} alt='' />Above Required Target
            <img src={AboveMinimalTargetIcon} alt='' />Above Minimal Target
            <img src={BelowMinimalTargetIcon} alt='' />Below Minimal Target
          </main>

<AgentsFilterComponent/> <br/>

<main style={{marginTop:20}}>
          <Tabs activeKey={key}
          justify
      onSelect={(k) => {setKey(k);dispatch(saveSelectedTab(k))}}>
         {TabData.map(data =>( <Tab eventKey={data.eventKey} title={data.title} >
            <FilterTableComponent SelectedTab = {key} />
          </Tab>))}
        </Tabs>
</main>
          </section>
    </div>
  )
}

export default AgentsKPISummaryTabs