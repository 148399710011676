import React from 'react'
import GSDInsight_Logo from './../../assets/images/GSDInsight_Logo.png';
import DXC_Logo_Footer_Normal from './../../assets/images/DXC_Logo_Footer_Normal.png';
import Menu_DownArrow from './../../assets/images/Menu_DownArrow.png';
import Menu_UpArrow from './../../assets/images/Menu_UpArrow.png';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { EmailTab, RequestTab, RequestTabPopup } from './GitHub_LandingPage/Models';
import { Link } from 'react-router-dom';
export function GithubHeader() {
    const history = useHistory();
    const [solution,setSolution] = useState(false);
    const dropDownref = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownref.current && !dropDownref.current.contains(event.target)) {
                if (!solution)
                setSolution(solution);
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropDownref]);

    return (
        <>
            <main className='Git_Tabs' style={{ display: 'flex' }}>
                <div className='gsd_logo_text' onClick={() => history.push("/githublanding")}><img src={GSDInsight_Logo} alt='gsd' /> GSD Insight </div>
                <section className='why_gsd_text'>
                <div style={{cursor:'pointer'}} onClick={() => history.push("/whygsd")}>Why GSD Insight</div> &nbsp; &nbsp; &nbsp;&nbsp;
                <div style={{cursor:'pointer'}} onClick={() => setSolution(!solution)}  ref={dropDownref}>Solution &nbsp; 
                <img src={solution ? Menu_UpArrow : Menu_DownArrow } alt=''/>  </div>&nbsp; &nbsp;&nbsp;&nbsp;
                <div style={{cursor:'pointer'}} onClick={() => history.push("/getstarted")}>  Get Started</div>
                {solution && <div className='solution_option' >
                        <div className="solution_widget1" ><span className="arch_text" onClick={() => history.push("/architecture")}>Architecture</span></div>
                        <div className="solution_widget1" ><span className="arch_text" onClick={() => history.push("/product")}>Product Requirements</span></div>
                    </div>}
                </section>
            </main>
            
        </>
    )
}


const CONTACT = [
    {
        id: 1,
        href: "mailto:GSD_Insight_Support@dxc.com",
        name: 'Email'
    },
    {
        id: 2,
        href: "https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&tenantId=93f33571-550f-43cf-b09f-cd331338d086",
        name: ' MS Teams'
    }
]
const WHY_GSD = [
    {
        id: 1,
        href: "/architecture",
        name: 'SOLUTION'
    },
    {
        id: 2,
        href: '/getstarted',
        name: 'GET STARTED'
    },
    {
        id: 3,
        href: "/developer",
        name: 'DEVELOPER'
    },
    {
        id: 4,
        href: "/security",
        name: 'SECURITY'
    },
    {
        id: 5,
        href: "/user",
        name: 'USER'
    },
    {
        id: 6,
        href: "/training",
        name: 'TRAINING'
    }
    
]
const PRODUCTS = [
    {
        id: 1,
        href: "/overview",
        name: 'Overview'
    },
    {
        id: 2,
        href: "/design",
        name: 'Design'
    },
    {
        id: 3,
        href: "/features",
        name: 'Features'
    }
]
const RELEASES = [
    {
        id: 1,
        href: "/welcome/release",
        name: 'What’s New '
    },
    {
        id: 2,
        href: "/welcome/release",
        name: 'Upcoming Releases'
    }
]
const SUPPORT = [
    {
        id: 1,
        href: "/notinproduction",
        name: 'Incidents / Requests'
    },
    {
        id: 2,
        href: "/notinproduction",
        name: 'FAQ'
    }
]
export function Footer() {
    const history = useHistory();
  const [showRequestModel, setShowRequestModel] = useState(false);
  const [showEmailModel, setShowEmailModel] = useState(false);
    return (
        <>
            <main className='releases_features'>
                <div>
                    <div class="navFooterVerticalColumn navAccessibility" role="presentation">
                        <div class="navFooterVerticalRow navAccessibility" style={{display:'table-row'}}>
                        
                            <div className='navFooterLinkCol navAccessibility' style={{paddingLeft:80}}>
                                <div className='why_gsd_list' onClick={() => history.push("/whygsd")}>WHY GSD INSIGHT</div>
                                <ul>
                                    {WHY_GSD.map((link, index) => (<li key={index} className='why_gsd_list' onClick={() => history.push(link.href)}>
                                        {link.name}
                                    </li>))}
                                </ul>
                            </div>
                            <div className='navFooterColSpacerInner navAccessibility'></div>
                            <div className='navFooterLinkCol navAccessibility'>
                                <div className='why_gsd_list_head' >PRODUCT & FEATURES</div>
                                <ul>
                                    {PRODUCTS.map((link, index) => (<li key={index} className='products_list' onClick={() => history.push(link.href)}>
                                        {link.name}
                                    </li>))}
                                </ul>
                            </div>
                            <div className='navFooterColSpacerInner navAccessibility'></div>
                            <div className='navFooterLinkCol navAccessibility'>
                                <div className='why_gsd_list_head' >RELEASES</div>
                                <ul>
                                    {RELEASES.map((link, index) => (<li key={index} className='products_list'>
                                       <Link to={link.href} target='_blank'> <span className='products_list'>{link.name}</span></Link>
                                    </li>))}
                                </ul>
                            </div>
                            <div className='navFooterColSpacerInner navAccessibility'></div>
                            <div className='navFooterLinkCol navAccessibility'>
                                <div className='why_gsd_list_head'>SUPPORT</div>
                                <ul>
                                    {SUPPORT.map((link, index) => (<li key={index} className='products_list' 
                                    onClick={()=>(link.name === 'Incidents / Requests') ? setShowRequestModel(true) : history.push('/faq')}>
                                 {link.name}
                                    </li>))}
                                </ul>
                            </div>
                            <div className='navFooterColSpacerInner navAccessibility'></div>
                            <div className='navFooterLinkCol navAccessibility'>
                                <div className='why_gsd_list_head'>CONTACT</div>
                                <ul>
                                    {/* {CONTACT.map((link, index) => (<li key={index} className='products_list'>
                                    <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={link.href}
                      title="" >  <span className='products_list'>{link.name}</span> </a> 
                                    </li>))} */}
                                    <li className='products_list' onClick={()=>setShowEmailModel(true)}>Email</li>
                                    <li className='products_list'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href="https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&tenantId=93f33571-550f-43cf-b09f-cd331338d086"
                      title="" >  <span className='products_list'>MS Teams</span> </a> </li>
                                </ul>
                            </div>
                            <div className='navFooterColSpacerInner navAccessibility'></div>
                        </div>

                    </div>
                </div>

                <br />
                <div style={{ paddingRight: '30px' }}>
                    <hr />
                    <div className='footer_dxc' style={{ display: 'flex', justifyContent: 'space-between' }}><div><img src={DXC_Logo_Footer_Normal} alt='' /></div><div>© DXC Technology Company</div></div>
                </div>
            </main>


            {showRequestModel && <RequestTabPopup isOpen={showRequestModel} setShowModal={setShowRequestModel}>
        <RequestTab modalName='request' closeWindow={()=>setShowRequestModel(false)}
        />
      </RequestTabPopup>}

      {showEmailModel && <RequestTabPopup isOpen={showEmailModel} setShowModal={setShowEmailModel}>
        <EmailTab modalName='request' closeWindow={()=>setShowEmailModel(false)}
        />
      </RequestTabPopup>}
        </>
    )
}



