import React from 'react'
import { Accordion } from 'react-bootstrap';
import './InfraStructure.css';
import VerticalLine from './../../../../assets/images/VerticalLine_Headings.png';
import BackupRetention from './../../../../assets/github-images/BackupRetention.png';
import Maintenance from './../../../../assets/github-images/Maintenance.png';


const sql_headers = [
    { id: '1', caption: "Instance" },
    { id: '2', caption: "Role" },
    { id: '3', caption: "FQDN" },
    { id: '4', caption: "Operating System" }
]
const SQL_DATA = [
    { id: '1', 'Instance': 'DEV', 'Role': 'SQL Database', 'FQDN': 'zc4w02439.itcs.houston.dxccorp.net', 'OperatingSystem': 'Server 2019' },
    { id: '2', 'Instance': 'UAT', 'Role': 'SQL Database', 'FQDN': 'zc4w02440.itcs.houston.dxccorp.net', 'OperatingSystem': 'Server 2019' }
]

const window_platform = [
    { id: '1', caption: "Instance" },
    { id: '2', caption: "Role" },
    { id: '3', caption: "FQDN" },
    {id:'4',caption:'IP'},
    { id: '5', caption: "Operating System" },
    { id: '6', caption: "RDP" }
]
const window_platform_data = [
    { id: '1', 'Instance': 'DEV', 'Role': 'IIS Web Server', 'FQDN': 'zc4w02443.itcs.houston.dxccorp.net',
    'IP':'15.140.135.88','OperatingSystem': 'Server 2016 R2','RDP':'zc4w02443.rdp',
     'href':'https://github.dxc.com/pages/MWSGSD/GSD-Insight/developer/infrastructure/images/zc4w02443.rdp'
},
    { id: '2', 'Instance': 'UAT', 'Role': 'SQL Database', 'FQDN': 'zc4w02439.itcs.houston.dxccorp.net',
    'IP':'15.140.143.93', 'OperatingSystem': 'Server 2019','RDP':'zc4w02439.rdp',
    'href':'https://github.dxc.com/pages/MWSGSD/GSD-Insight/developer/infrastructure/images/zc4w02439.rdp' 
},
    { id: '3', 'Instance': 'UAT', 'Role': 'IIS Web Server', 'FQDN': 'zc4w02443.itcs.houston.dxccorp.net',
    'IP':'15.140.135.88', 'OperatingSystem': 'Server 2016 R2','RDP':'zc4w02443.rdp',
    'href':'https://github.dxc.com/pages/MWSGSD/GSD-Insight/developer/infrastructure/images/zc4w02443.rdp'
 },
    { id: '4', 'Instance': 'PROD', 'Role': 'SQL Database', 'FQDN': 'zc4w02440.itcs.houston.dxccorp.net',
    'IP':'15.140.135.87', 'OperatingSystem': 'Server 2019','RDP':'zc4w02440.rdp' ,
    'href':'https://github.dxc.com/pages/MWSGSD/GSD-Insight/developer/infrastructure/images/zc4w02440.rdp'
},
    { id: '5', 'Instance': 'PROD', 'Role': 'IIS Web Server', 'FQDN': 'zc4w02441.itcs.houston.dxccorp.net',
    'IP':'15.140.143.95', 'OperatingSystem': 'Server 2016 R2','RDP':'zc4w02441.rdp',
    'href':'https://github.dxc.com/pages/MWSGSD/GSD-Insight/developer/infrastructure/images/zc4w02441.rdp' 
},
    { id: '6', 'Instance': 'PROD', 'Role': 'IIS Web Server', 'FQDN': 'zc4w02442.itcs.houston.dxccorp.net',
    'IP':'15.140.143.96', 'OperatingSystem': 'Server 2016 R2','RDP':'zc4w02442.rdp',
    'href':'https://github.dxc.com/pages/MWSGSD/GSD-Insight/developer/infrastructure/servers/images/zc4w002442.rdp' 
},
    { id: '7', 'Instance': 'BACKUP', 'Role': 'Backup Server', 'FQDN': 'zc4w01327.itcs.houston.dxccorp.net',
    'IP':'15.140.131.24', 'OperatingSystem': 'Server 2012 R2','RDP':'zc4w01327.rdp',
    'href':'https://github.dxc.com/pages/MWSGSD/GSD-Insight/developer/infrastructure/images/zc4w01327.rdp'
 },
]

const AWS_Instance = [
    { id: '1', caption: "Instance Name" },
    { id: '2', caption: "Instance Region" }
]
const AWS_Instance_DATA = [
    { id: '1', 'InstanceName': 'dxc-ito-mws-asia', 'InstanceRegion':'eu-central-1'},
    { id: '2', 'InstanceName': 'dxc-ito-mws-india-e', 'InstanceRegion':'eu-central-1'},
    { id: '3', 'InstanceName': 'interim-dxc-ito-mws-nce-se', 'InstanceRegion':'eu-central-1'},
    { id: '4', 'InstanceName': 'dxc-ito-mws-uki', 'InstanceRegion':'eu-central-1'},
    { id: '5', 'InstanceName': 'dxc-ito-mws-amr-us-wr', 'InstanceRegion':'dxc-ito-mws-amr-us-wr'},
]

const AWS_Instance_DATA2 = [
    { id: '1', 'InstanceName': 'dxc-ito-mws-anz', 'InstanceRegion':'ap-southeast-2'},
    { id: '2', 'InstanceName': 'dxc-ito-mws-amr', 'InstanceRegion':'us-west-2'},
    { id: '3', 'InstanceName': 'dxc-ito-mws-nce-se', 'InstanceRegion':'eu-central-1'},
    { id: '4', 'InstanceName': 'dxc-ito-mws-asia-ml', 'InstanceRegion':'eu-central-1'}
]

const Balancer = [
    { id: '1', caption: "IP Address" },
    { id: '2', caption: "Name" }
]
const Balancer_DATA = [
    { id: '1', 'IPAddress': '15.140.145.114', 'Name':'gsdinsightlb.itcs.houston.dxccorp.net'}
]

const SERVER='https://dxcportal.sharepoint.com/:w:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7B446F54B9-952F-43B5-A53B-D850035A2E12%7D&file=GSD_Insight_Server_Maintenance_Process_v20220328.docx&action=default&mobileredirect=true&wdOrigin=TEAMS-ELECTRON.teams.files&wdExp=TEAMS-CONTROL&wdhostclicktime=1648474113072&cid=ed3cd107-5935-4e85-9f2a-ed4a13a08d91';
const SERVER_APP='https://dxcportal.sharepoint.com/:w:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7BA42B84C3-0C9D-42A2-A2ED-B046A16B4062%7D&file=GSD_Insight_Devenv_Runtime_Update_Process_v20220531.docx&action=default&mobileredirect=true';
function InfraStructure() {
    return (
        <>
            <main className='accordion_handle' >
            <div style={{width:1200,margin:'auto'}}>
                <div className='Infrastructure'>Infrastructure</div>
                <div style={{ width: '98%' }}>
                    <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                        <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Servers</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section>
                                        <div className='server_acc'>The GSD Insight solution is architected to run on Windows platform.</div>
                                        <main>  <table className='infra_table' style={{width:'96%'}}>
                                            <thead><tr>
                                                {window_platform.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='infra_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {window_platform_data.map((data, index) => (<tr key={data.id}>
                                                    <td className='infra_td'>{data?.Instance}</td>
                                                    <td className='infra_td'>{data?.Role}</td>
                                                    <td className='infra_td'>{data?.FQDN}</td>
                                                    <td className='infra_td'>{data?.IP}</td>
                                                    <td className='infra_td'>{data?.OperatingSystem}</td>
                                                    <td className='infra_td_link'><a  rel="noreferrer" style={{ listStyleType: 'none' }}
                                                     href={data.href} title="" > {data?.RDP} </a> </td>
                                                </tr>))} </tbody>
                                        </table></main>
                                        <main>
                                            <div className='server_head_text'>AWS [ASD] Synchronized Instances</div>
                                            <main className='img_flex_div' style={{gridGap:'2%',width:'96%'}}>
                                            <section className='sql_width'> 
                                            <main>  <table className='infra_table' style={{width:'100%'}}>
                                            <thead><tr>
                                                {AWS_Instance.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='infra_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {AWS_Instance_DATA.map((data, index) => (<tr key={data.id}>
                                                    <td className='infra_td'>{data?.InstanceName}</td>
                                                    <td className='infra_td'>{data?.InstanceRegion}</td>
                                                </tr>))} </tbody>
                                        </table></main> 
                                            </section>
                                            <section className='sql_width'>
                                            <main>  <table className='infra_table' style={{width:'100%'}}>
                                            <thead><tr>
                                                {AWS_Instance.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='infra_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                            {AWS_Instance_DATA2.map((data, index) => (<tr key={data.id}>
                                                    <td className='infra_td'>{data?.InstanceName}</td>
                                                    <td className='infra_td'>{data?.InstanceRegion}</td>
                                                </tr>))} </tbody>
                                        </table></main>
                                            </section>
                                        </main>
                                        </main>
                                        <main>
                                        <div className='server_head_text'>Balancer</div>
                                        <main>  <table className='infra_table' style={{width:'47%'}}>
                                            <thead><tr>
                                                {Balancer.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='infra_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {Balancer_DATA.map((data, index) => (<tr key={data.id}>
                                                    <td className='infra_td'>{data?.IPAddress}</td>
                                                    <td className='infra_td'>{data?.Name}</td>
                                                </tr>))} </tbody>
                                        </table></main>
                                        </main>
                                    </section>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>SQL Database</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section className='sql'>
                                        <main style={{ width: '60%' }}>The selected database platform is <span >Microsoft SQL Server 2019</span> due to <b>Reliability, Scalability,</b> and <b>Security</b> out of the box features.</main>
                                        <main>  <table className='infra_table' style={{width:'80%'}}>
                                            <thead><tr>
                                                {sql_headers.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='infra_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {SQL_DATA.map((data, index) => (<tr key={data.id}>
                                                    <td className='infra_td'>{data?.Instance}</td>
                                                    <td className='infra_td'>{data?.Role}</td>
                                                    <td className='infra_td'>{data?.FQDN}</td>
                                                    <td className='infra_td'>{data?.OperatingSystem}</td>
                                                </tr>))} </tbody>
                                        </table></main>
                                        <main className='img_flex_div'>
                                            <section className='sql_width'> <div className='policy_sql'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Backup & Retention</div>
                                                <div><img src={BackupRetention} alt='' /></div>
                                                <div style={{ width: '80%', marginTop: 20 }}>On both instance the following settings are configured:</div>
                                                <main style={{ paddingLeft: 20, paddingTop: '20px' }}>
                                                    <ul className='infra_list'  >
                                                        <li id='disc'><b>Daily full backup</b> from all user databases - running at 01:00 AM UTC</li>
                                                        <li id='disc'>Backups are kept for <b>2 weeks</b> on local drive (<b>F:\SQLBackups</b> folder)</li>
                                                        <li id='disc'>Backups are automatically copied to a 2nd location (scheduled task copy job)</li>
                                                    </ul>
                                                </main>
                                            </section>
                                            <section className='sql_width'><div className='policy_sql'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Maintenance</div>
                                                <div><img src={Maintenance} alt='' /></div>
                                                <div style={{ width: '80%', marginTop: 20 }}>Automatic maintenance is configured to run every Sunday at 6:00 PM UTC.</div>
                                            </section>
                                        </main>
                                    </section>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={2} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Server Maintenance</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <div className='server_acc'><span id='server_text'>Server Maintenance Process :&nbsp;</span><span id='server_link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={SERVER} title=''>Control Document</a></span></div>
                                    <div className='server_acc'><span id='server_text'>Server App Maintenance Process :&nbsp;</span><span id='server_link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={SERVER_APP} title=''>GSD Insight Devend and Runtime Update Process Document</a></span>
                                        
                                    </div>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div></div>
            </main>

        </>)
}

export default InfraStructure