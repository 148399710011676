import React from "react";
import './call-type.css';
import TotalCalls_Icon from '../../../assets/images/TotalCalls_Icon.png';        
import Accounts_Icon from '../../../assets/images/Accounts_Icon.png';
import Agents_Icon from '../../../assets/images/Agents_Icon.png';
import AHT_Icon from '../../../assets/images/AHT_Icon.png';
import Languages_Icon from '../../../assets/images/Languages_Icon.png';
import Abandoned_Icon from '../../../assets/images/Abandoned_Icon.png';
import EDashboardBarChart from "../BarChart/bar-chart";

const EDashboradCallType = (props) => {

const { tileData, chartBkgrndData, barChartData, chartLabelData, chartLegendData, analyticsChartValAddtnlData, analyticsChartValAddtnlTltpData } = props;

    return(
        <div className="call-type-container">
            <div className="row call-type-hdr">
                <label>Call Type</label>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                    <EDashboardBarChart legends={chartLegendData} bkgrndClr={chartBkgrndData} chartData={barChartData}  labels={chartLabelData}
                    analyticsChartValAddtnlData = {analyticsChartValAddtnlData} analyticsChartValAddtnlTltpData={analyticsChartValAddtnlTltpData}/>
                </div>
                <div style={{padding: '2em'}} className="col-sm-12 col-md-6 col-lg-6">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row img-spc-text border-btm border-rght">
                                <div className="col-sm-12 col-md-4 col-lg-6 text-center">
                                <img src={TotalCalls_Icon} className="call-type-img"  alt="Total Calls Icon" />
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-6">
                                    <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-hdr">{tileData?.Label1}</label>
                                    </div>
                                    <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-val">{tileData?.Value1}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">                            
                                <div className="row img-spc-text border-btm">
                                    <div className="col-sm-12 col-md-4 col-lg-6 text-center">
                                    <img src={Accounts_Icon} className="call-type-img" style={{paddingBottom: '11px'}} alt="Accounts Icon" />
                                    </div>
                                    <div className="col-sm-12 col-md-8 col-lg-6">
                                        <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-hdr">{tileData?.Lable2}</label>
                                        </div>
                                        <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-val">{tileData?.Value2}</label>
                                        </div>
                                    </div>
                                </div>                           
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row img-spc-text border-btm border-rght">
                                <div className="col-sm-12 col-md-4 col-lg-6 text-center">
                                <img src={Abandoned_Icon} className="call-type-img"  alt="Abandoned Icon" />
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-6">
                                    <div className="col-sm col-md col-lg">
                                    <label className="call-type-lbl-hdr">{tileData?.Label3}</label>
                                    </div>
                                    <div className="col-sm col-md col-lg">
                                    <label className="call-type-lbl-val">{tileData?.Value3}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">                            
                                <div className="row img-spc-text border-btm">
                                    <div className="col-sm-12 col-md-4 col-lg-6 text-center">
                                    <img src={Agents_Icon} className="call-type-img"  alt="Agents Icon" />
                                    </div>
                                    <div className="col-sm-12 col-md-8 col-lg-6">
                                        <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-hdr">{tileData?.Lable4}</label>
                                        </div>
                                        <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-val">{tileData?.Value4}</label>
                                        </div>
                                    </div>
                                </div>                            
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row img-spc-text border-rght">
                                <div className="col-sm-12 col-md-4 col-lg-6 text-center">
                                <img src={Languages_Icon} className="call-type-img"  alt="Languages Icon" />
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-6">
                                    <div className="col-sm col-md col-lg">
                                    <label className="call-type-lbl-hdr">{tileData?.Label5}</label>
                                    </div>
                                    <div className="col-sm col-md col-lg">
                                    <label className="call-type-lbl-val">{tileData?.Value5}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">                            
                                <div className="row img-spc-text">
                                    <div className="col-sm-12 col-md-4 col-lg-6 text-center">
                                    <img src={AHT_Icon} className="call-type-img"  alt="AHT Icon" />
                                    </div>
                                    <div className="col-sm-12 col-md-8 col-lg-6">
                                        <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-hdr">{tileData?.Label6}</label>
                                        </div>
                                        <div className="col-sm col-md col-lg">
                                        <label className="call-type-lbl-val">{tileData?.Value6}</label>
                                        </div>
                                    </div>
                                </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default EDashboradCallType;