import React from 'react'
import AdminBreadcrumb from '../Administration/General/Admin-breadcrumb'
import { Footer} from './HeaderFooter'
import GetStarted_HeroBanner from './../../assets/images/GetStarted_HeroBanner_Image.png';
import VerticalLine from './../../assets/images/VerticalLine_Headings.png';
import {Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';


const GetStarted = () => {
 const history = useHistory();

   return (
    <>
       <section style={{ marginLeft: -100 }}>
        <div className='breadcrumb_gsd'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Get Started' />
        </div>
        <main><img src={GetStarted_HeroBanner} alt='' width='100%' height='400px' style={{objectFit:'cover'}}/></main>
        <main className='get_started_text'><div id='header'>Get Started</div>The below simple “how to steps” will help you to get started quickly <br/> and easily utilising numerous features of GSD Insight.</main>
        <section>
        
         <main className='card_background'>
         <div style={{width:1200,margin:'auto',display:'flex',gap:'40px'}}>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>First Time User</span> <hr/> As a first time visitor, we would like to redirect you to the following <Link to='/intro' target='_blank'><span id='link_font'>page</span></Link> and by following the simple instructions your access may be obtained. </div>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>Executive User</span> <hr/> If you are an Executive or have responsibility over multiple accounts and / or multiple sites / locations and /or have a regional / global function, please send us an email to 
          <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href="mailto:GSD_Insight_Support@dxc.com"
                      title="GSD_Insight_Support@dxc.com" >
                   &nbsp; <span id='link_font'>GSD Insight Support</span> &nbsp;
                    </a>detailing your access requirements. </div></div>
         </main>
         <main className='card_background' style={{marginTop:'-40px'}}>
         <div style={{width:1200,margin:'auto',display:'flex',gap:'40px'}}>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>Existing User - Role Change</span> <hr/> Please be informed your standard access level is determined by your particular role. Should you be changing roles please apply the &nbsp;
          <Link to ='/security'><span id='link_font'>RBAC</span> </Link>
          model accordingly, which further defines your access rights to reports /data / metrics. </div>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>Existing User - Onboarding / Administration</span> <hr/> To be able to onboard and / or administer an account, it is necessary to have Site Leader and / or Service Delivery Manager role for the particular account. 
          <br/><br/>To onboard a new account please follow the <Link to='/onboard' target='_blank'><span id='link_font'>Onboard</span></Link> section in GSD Insight. 
          <br/><br/>To administer an account please contact your Site Leader and / or Service Delivery Manager [responsible for the particular account/s] located in the <Link to='/notinproduction' target='_blank'><span id='link_font'>Administration</span></Link> section in GSD Insight.</div>
         </div></main>
        </section>
        <Footer/>
        </section>
    </>
  )
}

export default GetStarted