import React from 'react'
import editIcon from '../../../assets/images/Edit_Icon.png';
import deleteIcon from '../../../assets/images/Delete_Icon.png';
import TooltipHandler from '../../Onboard/OnboardComponents/TooltipHandler';


export function ReleaseCard(props) {
  const roleSelected = localStorage.getItem('RoleSelected');
  const DateConverter = (date) => {
    let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let now = new Date(date);
    return days[now.getDay()] + ', ' + months[now.getMonth()] + ' ' + now.getDate() + ', ' + now.getFullYear();
  }
  console.log(props.data.length)
  return (<>
    <section className='production_card'>
      <header className='production_text'> {props.header}</header>
    {props.data.length === 0? <div className='no_records'>No Releases Found for Selected Year & Month </div> :  <> {props.data.map((data, i) =>
        <section key={i} onClick={props.onClick}>
          <main style={{ display: 'flex', justifyContent: 'space-between', marginTop: 20 }}>
            <div className='production_title'>{data.title}</div>
           {roleSelected==='Admin' && <div style={{display:'flex'}}>
              <span onClick={()=>props.editHandler(data)} ><TooltipHandler src={editIcon} width='14px' height='14px' textBoxWidth='100px' text='Edit'  /> </span>
              <span onClick={()=>props.deleteHandler(data)} style={{marginLeft:'30px'}}><TooltipHandler src={deleteIcon} width='14px' height='14px' textBoxWidth='100px' text='Delete'/></span>
            </div>}
          </main>
          <div className='production_description'>{data.description}</div>
          <footer className='production_date_div'>
            <div className='production_date'>{DateConverter(data.publishedDate)}</div>
          { (data.link!==null && data.link!=="") && 
            <div className='readmore'><a href={data.link}>READ MORE</a></div>
           }
          </footer>
          <hr />
        </section>)}</>}
    </section>
  </>)
}
