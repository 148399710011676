import { welcomePage_actionTypes as actionTypes } from "../constants/welcomePage-actionTypes"

const initialState = {
    rolesTitle: [],
    widgetInfo: [],
    selectedRole: '',
    recentLinks: [],
    widgetPreferences: {},
    carouselTitle: {},
    widgetsSelected: '',
    selectedAccounts: [],
    multiAccounts: [],
    multiWidgets: [],
    dropdownAccounts: [],
    AccountSummary: {},
    LatestReleases: [],
    githubCarouselData: {},
    AccountDropdown:[],
    selectedCustIDServID:[],
    selectedAccountObj:{},
    storedAccount:{},
    taskLinks:[]
}


const welcomePageReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_WIDGET_INFO_START:
        case actionTypes.UPDATE_WIDGET_PREFERENCE_SHOWN:
        case actionTypes.WIDGET_PREFERENCE_SHOWN:
        case actionTypes.UPDATE_WIDGET_INFO_SUCCESS:
        case actionTypes.GET_MULTI_ACCOUNTS_WIDGETS:
        case actionTypes.GET_MULTI_WIDGET_INFO:
        case actionTypes.UPDATE_MULTI_WIDGET_SUCCESS:
        case actionTypes.UPDATE_MULTI_WIDGET_START:
        case actionTypes.GET_ACCOUNT_SUMMARY:
        case actionTypes.GET_ACCOUNT_DROPDOWN:
        case actionTypes.GET_ROLES_TITLE:
        case actionTypes.GET_WIDGET_INFO:
        case actionTypes.GET_RECENT_LINKS:
        case actionTypes.GET_WIDGET_PREFERENCE:
        case actionTypes.GET_CAROUSEL_TITLE:
        case actionTypes.GET_LATEST_RELEASES_START:
        case actionTypes.CREATE_LATEST_RELEASES_START:
        case actionTypes.UPDATE_LATEST_RELEASES_START:
        case actionTypes.DELETE_LATEST_RELEASES_START:
        case actionTypes.CREATE_LATEST_RELEASES_SUCCESS:
        case actionTypes.UPDATE_LATEST_RELEASES_SUCCESS:
        case actionTypes.DELETE_LATEST_RELEASES_SUCCESS:
        case actionTypes.GET_STORED_ACCOUNT:
        case actionTypes.GET_TASK_LINKS:
            return {
                ...state,
            };
        case actionTypes.MULTI_ACCOUNTS_WIDGETS:
            return { ...state, multiAccounts: action.payload }
        case actionTypes.ROLES_TITLE:
            return { ...state, rolesTitle: action.payload };
        case actionTypes.WIDGET_INFO:
            return { ...state, widgetInfo: action.payload };
        case actionTypes.RECENT_LINKS:
            return { ...state, recentLinks: action.payload };
        case actionTypes.WIDGET_PREFERENCE:
            return { ...state, widgetPreferences: action.payload };
        case actionTypes.CAROUSEL_TITLE:
            return { ...state, carouselTitle: action.payload };
        case actionTypes.SET_WIDGET_SELECTED:
            return { ...state, widgetsSelected: action.payload };
        case actionTypes.SET_SELECTED_ACCOUNTS:
            return { ...state, selectedAccounts: action.payload };
        case actionTypes.MULTI_WIDGET_INFO:
            return { ...state, multiWidgets: action.payload }
        case actionTypes.SAVE_SELECTED_ACCOUNTS:
            return { ...state, selectedAccounts: action.payload }
        case actionTypes.SAVE_SELECTED_CUSTID_SERVID:
            return { ...state, selectedCustIDServID: action.payload }
        case actionTypes.SAVE_SELECTED_ACCOUNT_OBJ:
            return { ...state, selectedAccountObj: action.payload }
        case actionTypes.SAVE_DROPDOWN_ACCOUNTS:
            return { ...state, dropdownAccounts: action.payload };
        case actionTypes.ACCOUNT_SUMMARY:
            return { ...state, AccountSummary: action.payload };
        case actionTypes.ACCOUNT_DROPDOWN:
            return { ...state, AccountDropdown: action.payload };
        case actionTypes.GET_LATEST_RELEASES_SUCCESS:
            return { ...state, LatestReleases: action.payload };
        case 'SET_GITHUB_SOLUTION_CAROUSEL_DATA':
            return { ...state, githubCarouselData: action.payload };
        case actionTypes.STORED_ACCOUNT:
            return { ...state, storedAccount: action.payload };
        case actionTypes.TASK_LINKS:
            return { ...state, taskLinks: action.payload };
        default: return state;
    }
}


export default welcomePageReducer

