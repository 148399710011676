export const request_actionTypes = {
    GET_REQUEST_DATA: "GET_REQUEST_DATA",
    REQUEST_DATA: "REQUEST_DATA",

    UPDATE_NEW_REQUEST_START: "UPDATE_NEW_REQUEST_START",
    UPDATE_NEW_REQUEST_SUCCESS: "UPDATE_NEW_REQUEST_SUCCESS",

    UPDATE_EDIT_REQUEST_START: "UPDATE_EDIT_REQUEST_START",
    UPDATE_EDIT_REQUEST_SUCCESS: "UPDATE_EDIT_REQUEST_SUCCESS",

    GET_ADMIN_REQUEST_DATA: "GET_ADMIN_REQUEST_DATA",
    ADMIN_REQUEST_DATA: "ADMIN_REQUEST_DATA",

    UPDATE_EDIT_ADMIN_REQUEST_START: "UPDATE_EDIT_ADMIN_REQUEST_START",
    UPDATE_EDIT_ADMIN_REQUEST_SUCCESS: "UPDATE_EDIT_ADMIN_REQUEST_SUCCESS",

    GET_REQUEST_DROPDOWN_DATA: "GET_REQUEST_DROPDOWN_DATA",
    REQUEST_DROPDOWN_DATA: "REQUEST_DROPDOWN_DATA",

    GET_UPLOADED_FILE_DATA: "GET_UPLOADED_FILE_DATA",
    UPLOADED_FILE_DATA: "UPLOADED_FILE_DATA",
}
