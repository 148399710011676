import React, { useEffect, useState } from 'react';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png';
import menuIcon from './../../../../assets/images/Dotted_ContextMenu_Icon.png';
import Edit_Icon from './../../../../assets/images/Edit_Icon.png';
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png';
import filterIcon from './../../../../assets/images/Filter_Icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getEditUserData, getUserflowCount, getUserflowUsers, saveUserStoredFilter, updateDeleteUserStart } from '../../../../actions/Administration/adminView.action';
import { DeleteModalPopup } from '../../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { AdminViewDeleteModel } from '../AdminViewModels';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';
import { UserDropdown, UserDropdownStatus } from './UserModels';
import { useHistory } from 'react-router-dom';

const Type = [
  {
    Type: "All users",
      id: "1"
  }, {
    Type: "Active users",
      id: "2"
  },
  {
    Type: "Inactive users",
      id: "3"
  }
]

const UsersMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let usernameData = useSelector((state) => state.adminViewReducer.userflowUsers);
  let EditUserData = useSelector((state) => state.adminViewReducer.EditUserData);
  let userflowCount = useSelector((state) => state.adminViewReducer.userflowCount);
  let saveUserStoredFilters = useSelector((state) => state.adminViewReducer.saveUserStoredFilter);
  let saveUserStoredStatus = useSelector((state) => state.adminViewReducer.saveUserStoredStatus);
console.log('saveUserStoredFilter',saveUserStoredStatus)
  


  const [openDownload, setOpenDownload] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [usernameInfo, setUsernameInfo] = useState([]);
  const [filter, setFilter] = useState(saveUserStoredFilters ? saveUserStoredFilters : '');
  const [status, setStatus] = useState(saveUserStoredStatus ? saveUserStoredStatus : '');
  const [APIstatus, setAPIStatus] = useState((saveUserStoredStatus === 'Active users' ? true : (saveUserStoredStatus === 'Inactive users' ? false : '')));
  const [editDataID,setEditDataID] = useState()

console.log('APIstatus',APIstatus)
  const addValueToStatus = (name, value) => {
    const newFormData = { ...status };
    if (name !== 'Select') {
        newFormData[name] = value;
        setStatus(newFormData)
        localStorage.setItem('selectedStatusInUserFlow', newFormData.Type)
         setAPIStatus(newFormData.Type === 'Active users' ? true : (newFormData.Type === 'Inactive users' ? false : ''))
    }
}




useEffect(() => {
  dispatch(getUserflowCount(APIstatus))
    },[dispatch,APIstatus])
  
  useEffect(() => {
    setUsernameInfo(usernameData)
  }, [usernameData])

  useEffect(() => {
    if(editDataID){
    dispatch(getEditUserData(editDataID))
    }
  }, [dispatch,editDataID])


  useEffect(() => {
    const getData = setTimeout(() => {
      dispatch(getUserflowUsers(filter,APIstatus));
    }, 1000)

    return () => clearTimeout(getData)
  }, [dispatch,filter,APIstatus])

  

  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
const [deleteUserId,setDeleteUserId] = useState()
  const deleteuserHandle = () =>{
   let Data= [{"UserID":deleteUserId.id}]
   dispatch(updateDeleteUserStart(Data))
 dispatch(getUserflowUsers('',''));
 setOpenDownload(false);
 setShowDeleteRoleModal(false)
  }
  

  
  const addmode = {
    Mode: 'Add',
    data:'',
    editDataID:''
  };

  const editmode = {
    Mode: 'Edit',
    data: EditUserData,
    editDataID:editDataID
  };

  return (
    <div style={{ paddingBottom: '200px', marginRight: 20 }}>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={()=>history.push('/adminviewlanding')}
        isActive3={false} label3='Users'
      />
      <header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='administration_text'>Users: {userflowCount?.[0]?.count}</div>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ marginTop: 20 }}>
            <Link to={{ pathname: '/admin/users/adduser', state: addmode }}>
              <span className='btn_addAdminFlow'>ADD USER</span>
            </Link>
          </div>
          <span className='search_bar_AdminFlow' style={{padding:0,paddingRight:20}}>
          <div className='filter_in_user'> <img src={filterIcon} alt='' width='25px' height='25px'/></div>
          <div className='search_input_AdminFlow' style={{paddingTop:7,paddingLeft:20}}>
                                <UserDropdown data={Type} name='Type' value='Type' id='TypeID'
                                    editValue={saveUserStoredStatus} addValueToQueue={addValueToStatus}
                                    
                                />
                            </div>
          </span>
          <span className='search_bar_AdminFlow'>
            <input className='search_input_AdminFlow' value={filter || ''} onChange={e => { setFilter(e.target.value);dispatch(saveUserStoredFilter(e.target.value))}} placeholder='Search User' />
            <button className='search_icon_AdminFlow'><img src={searchIcon} alt='' /> </button>
          </span>
        </div>
      </header>
      <section style={{ marginTop: 20 }}>
        <ul style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            (usernameInfo).map((data, index) => (<li className='grid_item_users'>
              <div className='image_text_users'>
                <div><img src={`${data.photo}`} alt='' width='82px' height='82px' /></div>
                <div className='username_userflow'>{data.name}</div>
                <main> <div className='image_holder_user' onClick={() => { setOpenDownload(true); setDropdownIndex(index);setEditDataID(data.id) }}><img src={menuIcon} alt='' /><div style={{ position: 'relative' }}>
                  {(openDownload && dropdownIndex === index) && (
                    <ul className='menu_options' style={{ width: '150px' }}>
                    <Link to={{ pathname: '/admin/users/adduser', state: editmode }}> <li className='li_options'><img src={Edit_Icon} alt='' />&nbsp;&nbsp;&nbsp;Edit</li></Link>
                      <li className='li_options' onClick={()=>{setDeleteUserId(data);setShowDeleteRoleModal(true)}}><img src={deleteIcon} alt='' />&nbsp;&nbsp;&nbsp;Delete</li>
                    </ul>)}</div>
                            </div>
                </main>
              </div>
              <div className='email_userflow' onClick={() => setOpenDownload(false)}>Email ID: <span style={{ color: '#5F249F' }}>{data.email}</span></div>

            </li>
            ))}
        </ul>
      </section>

      {showDeleteRoleModal && <DeleteModalPopup isOpen={showDeleteRoleModal} setShowModal={setShowDeleteRoleModal}>
            <AdminViewDeleteModel modalName='Delete User' buttonOne='YES' buttonTwo='NO'
            InformationText = {deleteUserId.name}
            AfterInfoText='user?'
            handleDeleteYes={deleteuserHandle}
            handleDeleteNo={()=>setShowDeleteRoleModal(false)}
            />
        </DeleteModalPopup>}
    </div>

    
  )
}

export default UsersMain