import React from 'react'
import { Accordion } from 'react-bootstrap'
import './DeveloperAccess.css';
import VerticalLine from './../../../../assets/images/VerticalLine_Headings.png';
import DeveloperAccess_logo from './../../../../assets/github-images/DeveloperAccess_domain_account_image_1.png';
import DeveloperAccess_step1 from './../../../../assets/github-images/DeveloperAccess_domain_account_image_2.png';
import DeveloperAccess_step2 from './../../../../assets/github-images/DeveloperAccess_domain_account_image_3.png';
import { Link } from 'react-router-dom';

const CREATE_ACCOUNT = [
    { id: '1', caption: "Set a password, this will need to change every 2 months and needs to be at least 25 characters long so it’s recommended to use the password generator. " },
    { id: '2', caption: "On the right box under Unix Information, click yes to enable Unix." },
    { id: '3', caption: "Verify your information. it will fill automatically except for the optional fields." },
    { id: '4', caption: "Click next to go to submit your account." }
]
const REQUEST_ACCOUNT = [
    { id: '1', caption: "After all approvals are in place - your account will be created" },
    { id: '2', caption: "Send the account name (x-username) to project architect" },
    { id: '3', caption: "Architect will grant access to the required servers and SQL databases" }
]
const VERIFY_ACCOUNT = [
    { id: '1', caption: "Connect to Secure Pulse" },
    { id: '2', caption: "Use RDP from your PC and connect using the provided IP address or FQDN of the servers" },
    { id: '3', caption: "Login to the server using your “SSZ / x-username” & related password" }
]
const DeveloperAccess = () => {

    const VIRTUALSM = 'https://virtualsm.houston.dxccorp.net/';
    return (
        <>
            <main className='accordion_handle' >
            <div style={{width:1200,margin:'auto'}}>
                <div className='Infrastructure'>Developer Access</div>
                <div style={{ width: '98%' }}>
                    <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                        <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header >
                                <span className='accordion-header'>Developer Access Guide</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section className='guide_font'>
                                        <div id='head_text'>New members (developer / support) should follow the steps below to get the required accesses:</div>
                                        <div id='body_text'>
                                            <ol>
                                                <li>If you have been nominated / contacted to start working on this application & platform - please get approval from your direct manager & the project leaders.</li>
                                                <li>Request your special administrator account by following the New Member - Access request.</li>
                                                <li>After account is ready - send it to Architect & Lead developer - so they can grant the required access </li>
                                                <li>Follow their instructions and tests to validate your access</li>
                                            </ol>
                                        </div>
                                    </section>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>New Member - Access Request</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section className='access_request'>
                                        <main id='text_1'>In order to access all the different environments for GSD Insight you will need the following:</main>
                                        <div id='list_style'><ul><li className='list_access'>DXC Email & UID</li><li className='list_access'>Secure Pulse VPN</li></ul></div>
                                        <main id='text_2'>If you don’t have one or more of the previous requirements please contact your onboarding contact or manager. After following this process you will be able to do the following tasks:</main>
                                        <div id='list_style2'><ol>
                                            <li>Connect via RDP to GSD Insight environments (UAT / PROD)</li>
                                            <li>Start developing a tile/module as required</li>
                                        </ol></div>
                                        <main>
                                            <div className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Requesting an Admin Account in VirtualSM</div>
                                            <section className='img_flex_access'>
                                                <div><img src={DeveloperAccess_logo} alt='' /></div>
                                                <main><div className='virtualsm'>If you already have a VirtualSM account please skip this process</div>
                                                    <div id='list_style2'><ol>
                                                        <li>Connect to Secure Pulse VPN to your nearest location (or Houston)</li>
                                                        <li>Go to <span id='link_style' onClick={() => window.open(VIRTUALSM, '_blank')}>https://virtualsm.houston.dxccorp.net/</span></li>
                                                        <li>On the left menu, under Domain Services, click Create Domain Account</li>
                                                    </ol></div>
                                                    <div id='ul_ssz'> <ul  ><li className='list_access'>You are going to create a user for all the different servers that you'll be accessing</li>
                                                        <li className='list_access'>On the main box under domain, select <b>SSZ</b></li>
                                                        <li className='list_access'>Select <b>Admin Account</b></li>
                                                        <li className='list_access'>On the right box, create a username, most people use the same as their username in the DXC email. (! do NOT remove the "x-" part ! - example: x-username)</li>
                                                        <li className='list_access'>Click next to configure the new user</li>
                                                    </ul></div>
                                                </main>
                                            </section>
                                        </main>
                                        <main><img src={DeveloperAccess_step1} alt='' />
                                            <div className='create_acc_steps'><ol> {CREATE_ACCOUNT.map((data, index) => (<li>{data.caption}</li>))}</ol></div>
                                        </main>
                                        <main><img src={DeveloperAccess_step2} alt='' />
                                            <div className='create_acc_steps'><ol><li>Verify all information and click on <b>Request Account.</b></li>
                                                <li>The request require approval from your direct manager!</li></ol></div>
                                        </main>
                                        <main>
                                            <div className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Request Access to Servers</div>
                                            <div className='create_acc_steps'><ol> {REQUEST_ACCOUNT.map((data, index) => (<li>{data.caption}</li>))}</ol></div>
                                        </main>
                                        <main>
                                            <div className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Request Access to Servers</div>
                                            <div className='create_acc_steps'><ol> {VERIFY_ACCOUNT.map((data, index) => (<li>{data.caption}</li>))}</ol></div>
                                            <section className='pass_reset'><div id='pass_reset_title'>Password Reset</div>
                                                <div id='pass_reset_text'>Check if your password has expired in VirtualSM. Go to Account Domains, manage Accounts. Then go to the tab “Domain Accounts” and check the password status for your account. Under password, you can reset the password. Set automatic notification so the tool to remind you to change the password before expiration.</div>
                                                <div id='pass_reset_title'>SRPA Request Expired or Deleted</div>
                                                <div id='pass_reset_text'>Important that you will need to renew your admin account in VirtualSM periodically. If not renewed it will get expired and deleted resulting in access removal from the servers.<br /><br /> In the left menu click SRPA, SRPA Manager. <br /><br />You’ll see a list of your requests. verify that the expiration date is good. If the request has expired, or will expire soon, click the Edit button to the right. Select a new expiration date and submit your request.</div>
                                            </section></main>
                                    </section>

                                </>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div></div>
            </main>
        </>
    )
}

export default DeveloperAccess