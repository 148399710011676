
let today = new Date();
let currentDate = []
let newDate = `${today.getFullYear()}-${today.getMonth() < 10 ? "0" + (today.getMonth()) : (today.getMonth())}-01`;
currentDate.unshift(newDate);
let recentDate = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)}-01`;
currentDate.unshift(recentDate);


const getReportTableURL = (widget, hint, contactVolumeID) => {
    if (contactVolumeID === null || contactVolumeID === undefined || contactVolumeID === '&&&&')
        return `${process.env.REACT_APP_BASE_URL}/api/usp/EDKPIViewReport?widget=${widget}&yearmonth[]=2021-Dec&yearmonth[]=2021-Nov&yearmonth[]=2022-Jan&hint=${hint}`
    else
        return `${process.env.REACT_APP_BASE_URL}/api/usp/EDKPIViewReport?widget=${widget}&${contactVolumeID}`;
}

const getReportDownloadURL = (format, widget, contactVolumeID) => {
    if (format === 'excel') {
        if (contactVolumeID === null || contactVolumeID === undefined || contactVolumeID === '&&&&')
            return `${process.env.REACT_APP_BASE_URL}/api/usp/EDKPIViewReport?widget=${widget}&yearmonth[]=2021-Dec&yearmonth[]=2021-Nov&&yearmonth[]=2022-Jan&format=excel&filename=${widget}_excel_report.xlsx`
        else
            return `${process.env.REACT_APP_BASE_URL}/api/usp/EDKPIViewReport?widget=${widget}&${contactVolumeID}format=excel&filename=${widget}_excel_report.xlsx`;
    }
}



const getCurrentDate = () => {
    let today = new Date();
    let currentDate = []
    let newDate = `${today.getFullYear()}-${today.getMonth() < 10 ? "0" + (today.getMonth()) : (today.getMonth())}-01`;
    currentDate.unshift(newDate);
    let recentDate = `${today.getFullYear()}-${today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)}-01`;
    currentDate.unshift(recentDate);
    return currentDate;
}
const getFinanceText = (financeID) => {
    console.log(financeID)
    if (financeID === null || financeID === undefined || financeID === '&&&')
        return `${process.env.REACT_APP_BASE_URL}/api/usp/AWSCrossCharge?month[]=2021-12-01&month[]=2021-11-01&region[]=APAC&region[]=EMEA&region[]=AMS&hint=finance`
    else
        return `${process.env.REACT_APP_BASE_URL}/api/usp/AWSCrossCharge?${financeID}&hint=finance`;
}

const getFinanceDownloadURL = (format, financeID) => {
    if (format === 'excel') {
        if (financeID === null || financeID === undefined || financeID === '&&&')
            return `${process.env.REACT_APP_BASE_URL}/api/usp/AWSCrossCharge?month[]=2021-12-01&month[]=2021-11-01&region[]=APAC&region[]=EMEA&region[]=AMS&format=excel&filename=finance_excel_report.xlsx`
        else
            return `${process.env.REACT_APP_BASE_URL}/api/usp/AWSCrossCharge?${financeID}&format=excel&filename=finance_excel_report.xlsx`;
    }
}


const getreportDropDownURL = (filterID) => {
    if (filterID === null || filterID === undefined)
        return `${process.env.REACT_APP_BASE_URL}/api/usp/Mastersaws`
    else
        return `${process.env.REACT_APP_BASE_URL}/api/usp/Masters?${filterID}`;
}


const reportConstructURLs = (name, filterText, filterID) => {
    console.log(name, filterText, filterID)
    switch (name) {
        case 'GET_DROPDOWN_INFO':
            return '/api/usp/Masters';
        case 'GET_ACCOUNT_INFO':
            return getreportDropDownURL(filterID);
        case 'GET_AVERAGE_HANDLE_TIME':
            return getReportTableURL('AVGHandleTime', 'aht', filterText);
        case 'DOWNLOAD_AVERAGE_HANDLE':
            return getReportDownloadURL('excel', 'AVGHandleTime', filterText);
        case 'GET_ABANDON_RATE':
            return getReportTableURL('AbondonRate', 'abrate', filterText);
        case 'DOWNLOAD_ABANDON_RATE':
            return getReportDownloadURL('excel', 'AbondonRate', filterText);
        case 'GET_DIGITAL_TRANS':
            return getReportTableURL('DigitalTrans', 'digital', filterID);
        case 'DOWNLOAD_DIGITAL_TRANS':
            return getReportDownloadURL('excel', 'DigitalTrans', filterText);
        case 'GET_COST_PER_CONTACT':
            return getReportTableURL('CostPerContact', 'cpc', filterText);
        case 'DOWNLOAD_COST_PER_CONTACT':
            return getReportDownloadURL('excel', 'CostPerContact', filterText);
        case 'GET_CONTACT_FTE_DATA':
            return getReportTableURL('ContactsPerFTE', 'fte', filterText);
        case 'DOWNLOAD_CONTACT_FTE_DATA':
            return getReportDownloadURL('excel', 'ContactsPerFTE', filterText);
        case 'GET_PENALTY_AT_RISK':
            return getReportTableURL('PenalityatRisk', 'penalty', filterText);
        case 'DOWNLOAD_PENALTY_AT_RISK':
            return getReportDownloadURL('excel', 'PenalityatRisk', filterText);
        case 'GET_FIRST_CONTACT_RESOLUTION':
            return getReportTableURL('FirstContactResolution', 'fcr', filterText);
        case 'DOWNLOAD_FIRST_CONTACT_RESOLUTION':
            return getReportDownloadURL('excel', 'FirstContactResolution', filterText);
        case 'GET_ATTRITION_DATA':
            return getReportTableURL('Attrition', 'attrition', filterText);
        case 'DOWNLOAD_ATTRITION_DATA':
            return getReportDownloadURL('excel', 'Attrition', filterText);
        case 'GET_SLA_COMPLIANCE':
            return getReportTableURL('SLACompliance', 'sla', filterText);
        case 'DOWNLOAD_SLA_COMPLIANCE':
            return getReportDownloadURL('excel', 'SLACompliance', filterText);
        case 'GET_CSAT_DATA':
            return getReportTableURL('CSAT', 'csat', filterText);
        case 'DOWNLOAD_CSAT_DATA':
            return getReportDownloadURL('excel', 'CSAT', filterText);
        case 'GET_DELIVERY_SUPPORT':
            return getReportTableURL('DeliverySupport', 'delivery', filterText);
        case 'DOWNLOAD_DELIVERY_SUPPORT':
            return getReportDownloadURL('excel', 'DeliverySupport', filterText);
        case 'GET_CONTACT_VOLUME_DATA':
            return getReportTableURL('ContactVolume', 'contact', filterText);
        case 'DOWNLOAD_CONTACT_VOLUME_DATA':
            return getReportDownloadURL('excel', 'ContactVolume', filterText);


        case 'GET_FINANCE_DATA': {
            return getFinanceText(filterText);
        }
        case 'DOWNLOAD_FINANCE_DATA': {
            return getFinanceDownloadURL('excel', filterID)
        }
        case 'GET_DROPDOWN_DATA':
            return '/api/usp/Mastersaws';
        case 'GET_ACCOUNT_DATA':
            return getreportDropDownURL(filterID);
        default:
            { }
    }
}


export default reportConstructURLs;