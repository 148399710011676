const DataFormatHelper = (data, selectedTabName) => {
    let tabData = data[0], tempArr = [], allDataArr = [], lineItemName = '';
    if(tabData.categories && tabData.categories.length > 0){
        let categoryData = tabData.categories[0];
    for(let i=0;i< categoryData.lineitems.length; i++ ){
        let tableData = {'headers': [], 'rows': [], 'tblHdrName': []}
        tempArr = [];
        let lineItems = categoryData.lineitems[i];
        lineItemName = categoryData.lineitems[i].lineitem;
        let items = lineItems.items;
        let initialItem = items[i];   
        switch(selectedTabName){
            case "week":{           
                if(initialItem.points && initialItem.points.length > 0){
                    let itemMonthObj = {'name': 'Month', 'points': []};
                    let itemWeekObj = {'name': 'Week', 'points': []};
                    initialItem.points.map((val, indx)=>{
                        let weekVal = val.date ? val.date?.toString()?.split(' ')[1] : val.date;
                        let objMonth = {'value': val.month};
                        let objWeek = {'value': weekVal};
                        itemMonthObj.points.push(objMonth);
                        itemWeekObj.points.push(objWeek);
                        return itemWeekObj;
                    });
                    // items.splice(items.length, 0, itemMonthObj);
                    // items.splice((items.length-1), 0, itemWeekObj);
                    items.splice(0, 0, itemMonthObj);
                    items.splice((1), 0, itemWeekObj);
                }     
                break;      
            }
            case "day":{           
                if(initialItem.points && initialItem.points.length > 0){
                    let itemDayObj = {'name': dateToYMD(new Date(window.localStorage.getItem('selectedMonth'))), 'points': []};                
                    initialItem.points.map((val, indx)=>{
                        let dayVal = val.date ? val.date?.toString()?.split('/') : val.date;
                        if(dayVal)
                        dayVal = dayVal[0] + " " + dayVal[1] + "/" + dayVal[2];
                        else
                        dayVal =  val.date;
                        let objDay = {'value': dayVal};
                        itemDayObj.points.push(objDay);
                        return itemDayObj;
                    });
                    //items.splice(items.length, 0, itemDayObj);   
                    items.splice(0, 0, itemDayObj);                            
                } 
                break;              
            }
            case "annual":{           
                if(initialItem.points && initialItem.points.length > 0){
                    let itemAnnualObj = {'name': dateToYMD(new Date(window.localStorage.getItem('selectedMonth'))), 'points': []};                
                    initialItem.points.map((val, indx)=>{
                        let objAnnual = {'value': val.date};
                        itemAnnualObj.points.push(objAnnual);
                        return itemAnnualObj;
                    });
                    //items.splice(items.length, 0, itemAnnualObj);   
                    items.splice(0, 0, itemAnnualObj);             
                } 
                break;              
            }
            default: {}
        } 
        items = items.reverse();        
        items.map((obj, indx)=>{
            tableData.headers.push(obj.name);           
            tempArr.push(obj.points);
            //return tempArr;
        });
        tableData.tblHdrName.push(lineItemName);
        tableData.headers = tableData.headers.reverse();
        tempArr = tempArr.reverse();
        // tableData.headers =  tableData.headers;
        // tempArr = tempArr;
        tableData.rows = tempArr.reduce((r, a, i) =>
        (a.forEach((b, j) => (r[j] = r[j] || [])[i] = b), r), []);    
        console.log(tableData.rows);
        //return tableData;
        allDataArr.push(tableData);
    }
        return allDataArr;
    }else{
        return [];
    }
    
}

const dateToYMD = (date) => {
    var strArray=['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];   
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return  m + '  ' + y;
}

export default DataFormatHelper;