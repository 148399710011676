import { request_actionTypes as actionTypes} from './../constants/request-actionTypes';

export const getRequestData = (date, requesttype, status) => (
    {
      type: actionTypes.GET_REQUEST_DATA,
      date: date,
      requesttype: requesttype,
      status: status
    }
  )

  export const updateNewRequestStart = (data) => (
    {
      type: actionTypes.UPDATE_NEW_REQUEST_START,
      payload: data,
    }
  )
  
  export const updateNewRequestSuccess = () => (
    {
      type: actionTypes.UPDATE_NEW_REQUEST_SUCCESS,
    }
  )

  export const updateEditRequestStart = (data) => (
    {
      type: actionTypes.UPDATE_EDIT_REQUEST_START,
      payload: data,
    }
  )
  
  export const updateEditRequestSuccess = () => (
    {
      type: actionTypes.UPDATE_EDIT_REQUEST_SUCCESS,
    }
  )

  export const getAdminRequestData = (date, requesttype, status) => (
    {
      type: actionTypes.GET_ADMIN_REQUEST_DATA,
      date: date,
      requesttype: requesttype,
      status: status
    }
  )

  // export const getAdminRequestData = (data) => (
  //   {
  //     type: actionTypes.GET_ADMIN_REQUEST_DATA,
  //     payload: data,
  //   }
  // )
  
  export const updateEditAdminRequestStart = (data) => (
    {
      type: actionTypes.UPDATE_EDIT_ADMIN_REQUEST_START,
      payload: data,
    }
  )
                                  
  
  export const updateEditAdminRequestSuccess = () => (
    {
      type: actionTypes.UPDATE_EDIT_ADMIN_REQUEST_SUCCESS,
    }
  )

  export const getRequestDropdownData = (data) => (
    {
      type: actionTypes.GET_REQUEST_DROPDOWN_DATA,
      payload: data,
    }
  )

  export const getUplodedFileData = (uploadfileid) => (
    {
      type: actionTypes.GET_UPLOADED_FILE_DATA,
      uploadfileid: uploadfileid
    }
  )