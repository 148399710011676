import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import ClearFilter from '../../ReportTables/ClearFilters/ClearFilter';
import './FinanceHandler.css';
import FinanceTable from '../FinanceTable/FinanceTable';
import Financeinfo from '../FinanceInfo/Financeinfo';
import { getAccountData, getDropdownData, getFinanceData } from '../../../actions/finance.action';
import { saveAccountDropdownList } from '../../../actions/reports.action';
import Filter from '../../ReportTables/ReportFilters/ReportFilters';
import { useHistory } from 'react-router-dom';
function FinanceHandler() {

  const dispatch = useDispatch();
  let TableInfo = useSelector((state) => state.financeReducer.financeData);
  let DropdownInfo = useSelector((state) => state.financeReducer.dropdownData);
  let AccountFilters = useSelector((state) => state.financeReducer.accountData);
  let prevAccountdrpdwnList = useSelector((state) => state.reportTableReducer.accountDrpdwnListData);

  const DOWNLOAD_ARRAY = [
    { value: 'excel', id: 'Excel' },
    { value: 'svg', id: '.SVG' },
    { value: 'csv', id: '.CSV' },
    { value: 'pdf', id: 'PDF' }
  ]
  useEffect(() => {
    clearFilterHandler();
  }, [])


  useEffect(() => {
    dispatch(getFinanceData(ContactTableTrails([])));
    dispatch(getDropdownData());
    dispatch(getAccountData(ContactTableTrails([])));
  }, [dispatch]);

  const [filters, setFilters] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [period_data, setPeriod_data] = useState([]);
  const [region_data, setRegion_data] = useState([]);
  const [account_data, setAccount_data] = useState([]);
  const RegionInfo = DropdownInfo?.PLRegion;
  const PeriodInfo = DropdownInfo?.YearMonth;
  const AccountInfo = AccountFilters?.Account;

  useEffect(() => {
    if (RegionInfo?.length > 0) {
      RegionInfo.map(info => info.isChecked = false);
      setRegion_data(RegionInfo);
    }
    if (PeriodInfo?.length > 0) {
      PeriodInfo.map(info => info.isChecked = false);
      setPeriod_data(PeriodInfo)
    }

  }, [RegionInfo, PeriodInfo])


  useEffect(() => {
    if (AccountInfo?.length > 0) {
      AccountInfo.map(info => info.isChecked = false);
      setAccount_data(AccountInfo);
    }
  }, [AccountInfo])


  const dropDownLocationFilter = (filters) => {
    region_data.map(info =>
      (info.isChecked === true) && dispatch(getAccountData(ContactTableTrails(filters)))
    )
    period_data.map(info =>
      (info.isChecked === true) && dispatch(getAccountData(ContactTableTrails(filters)))
    )
  }

  // useEffect(() => {
  //   if (AccountFilters?.Account?.length > 0 && prevAccountdrpdwnList?.length > 0 && clearFilters === false) {
  //     let updatedAccountFilers = uniqueArray([...prevAccountdrpdwnList, ...AccountFilters?.Account]);
  //     setAccount_data(updatedAccountFilers);
  //   }
  // }, [AccountFilters])

  // const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));

  function removeDuplicates(originalArray, prop) {
    let account_arr = [];
    let accountObj = {};
    for (var i in originalArray) {
      accountObj[originalArray[i][prop]] = originalArray[i];
    }
    for (i in accountObj) {
      account_arr.push(accountObj[i]);
    }
    return account_arr;
  }
  useEffect(() => {
    if (AccountFilters?.Account?.length > 0 && prevAccountdrpdwnList?.length > 0 && clearFilters === false) {
      let temp_arr = [...AccountFilters?.Account, ...prevAccountdrpdwnList];
      let uniqueArray = removeDuplicates(temp_arr, "label");
      setAccount_data(uniqueArray);

    }

  }, [AccountFilters, clearFilters, prevAccountdrpdwnList]);

  const onAddingPeriodFilters = (option, drpdwnID) => {
    let temp_arr = [...region_data];
    temp_arr.map((itm, i) => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return;
    });
    setRegion_data(temp_arr);

    let temp_ARR = [...period_data]
    setPeriod_data(temp_ARR);
    temp_ARR.map(itm => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return;
    })
    setPeriod_data(temp_ARR);

    let temp_Arr = [...account_data];
    temp_Arr.map((itm, i) => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return;
    })
    setAccount_data(temp_Arr);

    switch (drpdwnID) {
      case 'accountDrpdwn': {
        dispatch(saveAccountDropdownList(account_data));
      }
      default: {

      }
    }
    if (filters.includes(option.value)) {
      return;
    }

    if (filters.length > 0) {
      setClearFilters(false);
    }
    let fltrs = [...filters, option.value];
    setFilters(fltrs);
    let ID = ContactTableTrails(fltrs);
    dispatch(getFinanceData(ID));
    dropDownLocationFilter(fltrs);

  }
  const clearFilterHandler = () => {
    setFilters([]);
    setClearFilters(true);
    ContactTableTrails([]);
    dispatch(getDropdownData());
    dispatch(getAccountData(ContactTableTrails([])));
  }
  const refreshHandler = () => {
    setFilters([]);
    setClearFilters(true);
    dispatch(getFinanceData(ContactTableTrails([])));
    dispatch(getDropdownData());
    dispatch(getAccountData(ContactTableTrails([])));

  }
  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter(el => {
      return arr2.find(element => {
        return element === el.value;
      });
    });
    return res;
  }


  const ContactTableTrails = filteredRegions => {
    let IntValues = filterByReference(period_data, filteredRegions).map(fil => fil.label);
    let periodFilters = IntValues.map(val => val.substring(0, 4) + '-' + val.substring(4, 6) + '-01');
    let periodValues = filterByReference(period_data, filteredRegions).map(fil => fil.value);
    let regionFilters = filterByReference(region_data, filteredRegions).map(fil => fil.value);
    let AccountFilters = filterByReference(account_data, filteredRegions).map(fil => fil.value);
    let financeID = periodFilters.map(val => `month[]=${val}`).join('&') + '&' + regionFilters.map(val => `region[]=${val}`).join('&') + '&' + AccountFilters.map(val => `account[]=${val}`).join('&') + '&';
    let filterID = periodValues.map(val => `yearmonth[]=${val}`).join('&') + '&' + regionFilters.map(val => `plregion[]=${val}`).join('&');
    let ID = { financeID: financeID, filterID: filterID }
    return ID;
  }

  const history = useHistory()
  return <React.Fragment>
    {/* <Header isMenu={true} isInfo={false} > </Header> */}
    <section className='finance-container'>
      <section className='view_report_breadcrumb'>
        <span onClick={() => history.push('/welcome')}>Home / </span>
        <span onClick={() => history.push('/regional')}>Report / </span>
        <span style={{ color: '#63666A' }}>Finance</span>

      </section>

      <br />
      <h3 className='finance'>Finance</h3>
      <Filter downloadArray={DOWNLOAD_ARRAY}
        region_data={region_data}
        period_data={period_data}
        account_data={account_data}
        onSelectingDropdown={onAddingPeriodFilters}
        clearFilters={clearFilters}
        refreshHandler={refreshHandler}
        downloadURL='DOWNLOAD_FINANCE_DATA'
        ID={ContactTableTrails(filters)}
        downloadButton={true}
      />
      {filters?.length > 0 ? (<ClearFilter
        onClearingFilters={clearFilterHandler}
        filteredPeriod={filters} />) : null}
      <Financeinfo tabData={TableInfo} />
      <br />
      <FinanceTable tblData={TableInfo} />
    </section>
  </React.Fragment>;
}

export default FinanceHandler;
