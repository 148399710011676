import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setDeveloperCard } from '../../../actions/github.action';
import Menu_Hover from './../../../assets/images/Menu_Hover.png'
import { Redirect } from 'react-router-dom';

const Developer_Cards = [
  { id: 1, title: 'Infrastructure',
   text: 'infra' },
  {
    id: 2,
    title: 'Developer Access',
    text: 'access'
  },
  {
    id: 3,
    title: 'Developer Guidelines',
    text: 'guidelines'
  },
  {
    id: 4,
    title: 'Development Environment',
    text: 'environment'
  },
  {
    id: 5,
    title: 'Documentation Standards',
    text: 'standard'
  },
  {
    id: 6,
    title: 'Release and Build Guides',
    text: 'release'
  },
  {
    id: 7,
    title: 'Product Maintenance',
    text: 'product'
  },
  {
    id: 8,
    title: 'Developer Support',
    text: 'support'
  },
  {
    id: 9,
    title: 'Design Principles ',
    text: 'principle'
  }
]
const DeveloperOffcanvas = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onClickDeveloperCards = (card) => {
    dispatch(setDeveloperCard(card));
    // history.push('/developer/card');
    <Redirect to='/developer/card'  />
    setShow(false);
  }


  return (
    <>
      <div className='icon_wrapper' onClick={handleShow} style={{ cursor: 'pointer', marginRight: 40 }}> <img src={Menu_Hover} alt='' /> </div>
      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ marginTop: '1.5em' }}><span className='offcanvas_title'>Developer</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: '#FFFFFF', border: ' 1px solid #ECEEF1' }}>
          {Developer_Cards.map((link, index) => (<div onClick={() => onClickDeveloperCards(link)} className='offcanvas_list'>{link.title}</div>))}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default DeveloperOffcanvas