import React, { useEffect, useState } from 'react';


const Exemption_initialState = {
    name: [],
    data: [],
    comments: '',
    type: '',
    penalty: '',
}

export function ExemptionModalHandler(props) {
    const [selectedType, setSelectedType] = useState('Valid');
    const [editFormInfo, setEditFormInfo] = useState({});
    const [addExemptionForm, setAddExemptionForm] = useState(Exemption_initialState);

    let editMode = props.editMode;

    useEffect(() => {
        let temp = {
            comments: props.ExemptionData.Comments,
            type: props.ExemptionData.Type,
            penalty: props.ExemptionData.Penalty,
        };
        setSelectedType(props.ExemptionData.Type)
        setEditFormInfo(temp)
    }, [])

    const onChangeExemptionRadio = (value) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData['type'] = value;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addExemptionForm };
            newFormData['type'] = value;
            setAddExemptionForm(newFormData)
        }
    }
    const onChangeExemptionInput = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData[fieldName] = fieldValue;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addExemptionForm };
            newFormData[fieldName] = fieldValue;
            setAddExemptionForm(newFormData)
        }
    }

    const onClickFormSubmit = (event) => {
        console.log(editFormInfo)
        props.handleExemptionFormSubmit(editMode ? editFormInfo : addExemptionForm)
    }
    return (
        <>
            <form onSubmit={onClickFormSubmit}>
                <header className="modal_heading">{editMode ? 'Edit' : 'Add'} SLA Failure Exemption Comments</header>
                <div style={{ color: '#ED4F32',marginBottom:'20px',marginTop:'-10px'}}>Penalty amount to be entered only in USD $ [only numbers are accepted]</div>
                <div className='slaname'> <span style={{ color: '#969696' }}>SLA Name : </span>{props.SLA_name}<span></span></div>
                <div className="radio_buttons">Select Exemption Type <br />
                    <input type="radio" id="valid" name="type" value="Valid" required
                    checked={editMode ? editFormInfo?.type === 'Valid' : selectedType === 'Valid'}
                        className="radio_input" onClick={() => {
                            onChangeExemptionRadio('Valid')
                            setSelectedType('Valid')
                        }} />
                    <label for="valid">Valid</label>
                    <input type="radio" id="Exemption" name="type" value="Exemption" 
                    checked={editMode ? editFormInfo?.type === 'Exemption' : selectedType === 'Exemption'}
                        className="radio_input" onClick={() => {
                            onChangeExemptionRadio('Exemption')
                            setSelectedType('Exemption')
                        }} />
                    <label for="Exemption">Exempted</label>
                </div>
                <div className="input_exemption">
                    <label>Comments *</label>
                    {editMode ? <input type='text'
                        name='comments'
                        placeholder='Example: Audi account has undergone changes in Brazil.....'
                        value={editMode && editFormInfo?.comments} required='required'
                        onChange={onChangeExemptionInput}
                    /> :
                        <input type='text'
                            name='comments' required='required'
                            placeholder='Example: Audi account has undergone changes in Brazil.....'
                            onChange={onChangeExemptionInput} />
                    }
                </div>
                <div className={selectedType === 'Exemption' ? 'penalty_exemption' : 'input_exemption'} style={{ width: '50%' }}>
                    <label style={{ color: (selectedType === 'Valid') ? '#969696' : '#D5D5D5' }}>Penalty Amount ($) </label>
                    {editMode ? <input type='text'
                        name='penalty'
                        required='required'
                        pattern="^[0-9]+$"
                        disabled={(selectedType === 'Valid') ? false : true}
                        placeholder='Example: 5000$'
                        value={editMode && editFormInfo?.penalty}
                        onChange={onChangeExemptionInput}
                    /> :
                        <input type='text'
                            name='penalty'
                            required='required'
                            pattern="^[0-9]+$"
                            disabled={(selectedType === 'Valid') ? false : true}
                            placeholder='Example: 5000$'
                            onChange={onChangeExemptionInput}
                        />}
                </div>
                <main className='input-container-four' style={{ marginLeft: '-120px' }}>
                    <button type='submit' className='add-button'>
                        <span className='add-span' >SUBMIT</span>
                    </button>
                    <button className='cancel-button' onClick={props.CancelBtnHandler}>
                        <span className='cancel-span'>CANCEL </span>
                    </button>
                </main>
            </form>
        </>
    )
}




export function RequestModalHandler(props) {
    return (
        <>
            <section className="delete-row-container">
                <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                    <div className='delete-row-span' style={{ marginTop: '3em' }}>
                        Request for SLA Data Exclusion
                    </div>
                </div>
                <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                    <div className="active__text">The <b>{props.ExclusionName}</b> SLA date exclusion request received successfully.
                        You will receive an email confirmation from the administrator to the registered mail ID ({props.userEmail}).</div>
                </div>
                <main style={{ marginLeft: '170px', marginTop: '70px' }}>
                    <button type='submit-button' className='add-button' onClick={props.RequestClosehandler}>
                        <span className='add-span' >CLOSE</span>
                    </button></main>
            </section>
        </>
    )
}