import {ScoreCardActionTypes as actionTypes} from "../constants/ScoreCard&TeamKPI-actionTypes";

export const getScoreCardData = (selecteddate,agent) => ({
    type: actionTypes.GET_SCORECARD_DATA,
    selecteddate: selecteddate,
    agent:agent
  });

  export const getScoreCardPerformanceData = (data) => ({
    type: actionTypes.GET_SCORECARD_PERFORMANCE_DATA,
    payload: data,
  });

  export const getScoreCardOffCanvasData = (agent) => ({
    type: actionTypes.GET_SCORECARD_OFFCANVAS_DATA,
    agent: agent,
  });

  export const getAgentsKPISummaryData = (name,account,date,selectedtab) => ({
    type: actionTypes.GET_AGENTS_KPI_SUMMARY_DATA,
    name: name,
    account: account,
    date:date,
    selectedtab:selectedtab
  });

  export const getTeamleaderAgentsData = (accountID) => ({
    type: actionTypes.GET_TEAMLEADER_AGENTS,
    accountID: accountID,
  });

  export const getTeamleaderAccountsData = (agents) => ({
    type: actionTypes.GET_TEAMLEADER_ACCOUNTS,
    agents: agents,
  });

  export const getTeamleaderAccountsViewData = (data) => ({
    type: actionTypes.GET_TEAMLEADER_ACCOUNTS_VIEW,
    payload: data,
  });

  export const getTeamleaderDatesData = (agents) => ({
    type: actionTypes.GET_TEAMLEADER_DATES,
    agents: agents,
  });

  export const AgentsKPIAccountsData = (email) => ({
    type: actionTypes.GET_AGENTS_KPI_ACCOUNTS_DATA,
    email: email,
  });

  export const getTeamKPISummaryData = (date) => ({
    type: actionTypes.GET_TEAM_KPI_SUMMARY_DATA,
    date:date,
  });

  export const getAgentsGraphData = (agent,teamKPI,metric,date) => ({
    type: actionTypes.GET_AGENTS_GRAPH_DATA,
    agent: agent,
    teamKPI: teamKPI,
    metric: metric,
    date:date,
  });








  export const saveSelectedTab = (data) => (
    {
      type: actionTypes.SAVE_SELECTED_TAB,
      payload: data,
    }
  )

  export const saveSelectedDate = (data) => (
    {
      type: actionTypes.SAVE_SELECTED_DATE,
      payload: data,
    }
  )

  export const saveSelectedAgentScoreCardDate = (data) => (
    {
      type: actionTypes.SAVE_SELECTED_AGENTSCORECARD_DATE,
      payload: data,
    }
  )
  
  