import ReactModal from "react-modal";
import { useState } from 'react';
import { OnboardSelect } from './../../../Onboard/OnboardComponents/Dropdown/Select';
import RequestDropdown from "../../../Onboard/OnboardComponents/Dropdown/RequestDropdown";
import { updateNewUserRoleStart } from "../../../../actions/Administration/staffAdmin.action";
import { useDispatch } from "react-redux";

export function RequestRole(props) {
    const [roleData, setRoleData] = useState();
    const dispatch = useDispatch();
    const ROLE_CATEGORY = [
        { id: 1, value: 'Delivery' },
        { id: 2, value: 'Delivery Support' },
        { id: 3, value: 'Overhead' },
        { id: 4, value: 'Hosted Resource' },
    ]

    const onAddinputhandler = (name, value) => {
        let temp = { ...roleData };
        temp[name] = value;
        setRoleData(temp)
    }
    const onChangeinputhandler = (e) => {
        setRoleData({ ...roleData, [e.target.name]: e.target.value });
    }

    const handleSubmitRequest = () => {
        dispatch(updateNewUserRoleStart(roleData))
        props.handleDeleteQueue(roleData);
    }

    return <>
        <section className="request-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    Request New Role
                </div>
            </div>
            <div style={{ marginLeft: '3em', marginBottom: '1em' }}>
                {props.delete === true ?
                    <div>
                        <div className="success_text">We have successfully received your request to add a new role. </div>
                        <div style={{ marginTop: '20px' }}>
                            <div className="success_text">Role Category:<b>{props.roles.RoleCategory} </b></div>
                            <div className="success_text">Role Name :<b>{props.roles.UserRole}</b> </div>
                        </div>
                        <div className="success_text" style={{ marginTop: '20px' }}>You will receive an email confirmation from the administrator to the registered mail ID </div>
                    </div>
                    : <>
                        <main style={{ display: 'flex', justifyContent: 'space-between' }}> <div style={{ width: '55%', marginRight: 30 }}>
                            <label HtmlFor="role"> Role Category *</label><br />
                            <div style={{ height: '50px', marginTop: '10px' }}>
                                <RequestDropdown data={ROLE_CATEGORY} name='RoleCategory' value='value' id='role'
                                    inputvalue='Select'
                                    addValueToQueue={onAddinputhandler}
                                /></div>
                        </div>
                            <div>
                                <label> Role Name *</label>
                                <input type='text'
                                    name='UserRole'
                                    required='required'
                                    placeholder='Example: Manager 1'
                                    onChange={onChangeinputhandler}
                                />
                            </div>
                        </main>
                        <main>
                            <div style={{ marginTop: 20 }}>
                                <label>Description *</label>
                                <input type='text'
                                    name='Description'
                                    required='required'
                                    placeholder='Example: Short information about role'
                                />
                            </div>
                        </main>
                    </>}
            </div>
            {props.delete === true ?
                <main className='input-container-five' style={{ marginLeft: '15em', marginTop: '2em' }}>
                    <button type='submit' className='btn_adduser' onClick={props.handleClose}>
                        {props.buttonOne}
                    </button>
                </main> :
                <main className='input-container-five' style={{ marginLeft: '10em', marginTop: '2em' }}>
                    <button type='submit' className='btn_adduser' onClick={handleSubmitRequest}>
                       {props.buttonOne}
                    </button>
                    <button className='btn_canceluser' onClick={props.handleDeleteQueueCancel}>
                        {props.buttonTwo} 
                    </button>
                </main>
            }
        </section>
    </>
}


export function RequestRoleModelPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '15em',
                    left: '30em',
                    width: '650px',
                    height: '350px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}
