import React, { Component } from "react";
import Insights from "../Insights/insights";

export class container extends Component {
  render() {
    return (
      <div className="bckgrnd-container-color">       
        <Insights />   
      </div>
    );
  }
}

export default container;
