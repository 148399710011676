import React, { useEffect, lazy, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { withRouter } from 'react-router';
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "./components/Container/container";
import SiteLanding from "./components/RegionalSiteLanding/regionalSiteLanding";
import RegionalSite from "./components/RegionalSiteLanding/regionalSite";
import AccountGlobal from "./components/RegionalSiteLanding/Account-Global/account-global";
import GlobalSummary from "./components/RegionalSiteLanding/GlobalSummary/global-summary";
import RegionalSummary from "./components/RegionalSiteLanding/RegionalSummary/regional-summary";
import FinanceHandler from "./components/Finance/FinanceHandler/FinanceHandler";
import WelcomePage from './components/LandingPage/Welcome-LandingPage/WelcomePage';
import Roleselectionpage from './components/LandingPage/RoleSelection/Roleselectionpage';
import Profilepage from './components/LandingPage/ProfilePage/ProfileDetails';
import Navigationbar from "./components/Onboard/Navigation/Navigationbar";
import ExecutiveDashboard from "./components/ExecutiveDashBoard/executive-dashboard";
import KPIReportHandler from "./components/ReportTables/ReportHandler/KPIReportHandler";
import LandingPage from "./components/LandingPage/CustomWidget/LandingPage";
import AccountSelect from "./components/LandingPage/Welcome-LandingPage/AccountSelect";
import EmployeeAssign from "./components/Administration/Staff-Administration/Employee-Assignment/EmployeeAssign";
import ReleaseHandler from "./components/LandingPage/Releases/ReleaseHandler";
import EmployeeDetails from "./components/Administration/Staff-Administration/EmployeeDetails/EmployeeDetails";
import UserLandingPage from "./components/LandingPage/FirstTimeVisitor/UserLandingPage";
import SLAExemptionHandler from "./components/Administration/SLA-Administration/SLA-Exemption/SLAExemptionHandler";
import SLAExclusionHandler from "./components/Administration/SLA-Administration/SLA-Exclusion/SLAExclusionHandler";
import FirstVisitor from "./components/LandingPage/FirstTimeVisitor/FirstVisitor";
import AgentScoreCard from "./components/Agent-ScoreCard/AgentScoreCard";
import OnboardLanding from "./components/Onboard/OnboardComponents/onboardLanding/OnboardLanding";
import RequestIncidentMain from "./components/Requests/RequestIncidentMain";
import WhyGsd from "./components/GitHub/WhyGsd";
import GitHubPage from "./components/GitHub/GitHub_LandingPage/GitHubPage";
import UploadAccountList from './components/Uploads/Upload-Navigation/UploadAccountList';
import UploadNavigation from './components/Uploads/Upload-Navigation/UploadNavigation';
import DataAdministration from "./components/Uploads/Data-Administration/DataAdministration";
import DTMAnalyticsHandler from "./components/DTM-Analytics/DTM-Analytics-NonSLA";
import DTMAnalyticsSLA from "./components/DTM-Analytics/DTM-Analytics-SLA";
import AdminLanding from "./components/Administration/Administration_firstpage.js/AdminLanding";
import ReactTrails from "./components/DTM-Analytics/trails";
import GetStarted from "./components/GitHub/GetStarted";
import Architecture from './components/GitHub/Solutions/Architecture';
import ProductRequirements from "./components/GitHub/Solutions/ProductRequirements";
import ArchitectCards from "./components/GitHub/Solutions/ArchitectCards";
import DeveloperLanding from "./components/GitHub/Developer/DeveloperLanding";
import DeveloperCards from "./components/GitHub/Developer/DeveloperCards";
import User from './components/GitHub/user/User';
import Security from "./components/GitHub/Security";
import OverView from "./components/GitHub/Overview/OverView";
import Training from "./components/GitHub/Training/Training";
import Design from './components/GitHub/Design/Design';
import DesignCards from './components/GitHub/Design/DesignCards';
import Features from "./components/GitHub/Features/Features";
import NotDesigned from "./components/GitHub/NotDesigned/NotDesigned";
import Database from './components/GitHub/Design/Database';
import FAQ from "./components/GitHub/FAQ/FAQ";
import AdminRequestMain from './components/Requests/AdminRequest/AdminRequestMain';
import AdminRolesFlow from "./components/Administration/AdminFirstPage/Roles/Roles";
import { AddAdminViewRolePage } from "./components/Administration/AdminFirstPage/Roles/AddRolesModel";
import OperationsMain from "./components/Administration/AdminFirstPage/Operations/OperationsMain";
import AdminViewFirstPage from "./components/Administration/AdminFirstPage/AdminViewFirstPage";
import AccessGroupMain from "./components/Administration/AdminFirstPage/AccessGroups/AccessGroupMain";
import AccessGroupAddEdit from "./components/Administration/AdminFirstPage/AccessGroups/AccessGroupAddEdit";
import UsersMain from "./components/Administration/AdminFirstPage/Users/UsersMain";
import AddUsers from "./components/Administration/AdminFirstPage/Users/AddUsers";
import AgentsKPISummaryTabs from './components/TeamLeaderView/AgentsKPISummary/AgentsKPISummaryTabs';
import AuditRole from "./components/Administration/AdminFirstPage/Audit/AuditRole";
import AuditRBAC from "./components/Administration/AdminFirstPage/Audit/AuditRBAC";
import TestingFile from "./components/hamburger-menu/TestingFile";


function AppRoutes(props) {
  const location = useLocation();
  useEffect(() => {
    props.localPathnameHandler(location.pathname);
  }, [location.pathname]);
  return (
    <>
    <Suspense fallback={<div>Loading...</div>}>

      <Switch>
      <Route path="/architecture/card" exact={true}>
          <ArchitectCards />
        </Route>
        <Route path="/developer/card" exact={true}>
          <DeveloperCards />
        </Route>
        <Route path="/reports" exact={true}>
          <Container />
        </Route>
        <Route path="/eDashboard" exact={true}>
          <ExecutiveDashboard />
        </Route>
        <Route path="/regional_old" exact={true}>
          <SiteLanding />
        </Route>
        <Route path="/regional" exact={true}>
          <RegionalSite />
        </Route>
        <Route path="/regional_global_accounts" exact={true}>
          <AccountGlobal />
        </Route>
        <Route path="/regional_global_summary" exact={true}>
          <GlobalSummary />
        </Route>
        <Route path="/regional_summary" exact={true}>
          <RegionalSummary />
        </Route>
        <Route path="/finance" exact={true}>
          <FinanceHandler />
        </Route>
        <Route path="/welcome" exact={true}>
          <WelcomePage />
        </Route>

        <Route path='/welcome/profile' exact>
          <Profilepage />
        </Route>

        <Route path='/aht' exact>
          <KPIReportHandler />
        </Route>
        <Route path='/abrate' exact>
          <KPIReportHandler />

        </Route>
        <Route path='/digitalTrans' exact>
          <KPIReportHandler />

        </Route>
        <Route path='/cpc' exact>
          <KPIReportHandler />

        </Route>
        <Route path='/fte' exact>
          <KPIReportHandler />

        </Route>
        <Route path='/penalty' exact>
          <KPIReportHandler />
        </Route>
        <Route path='/fcr' exact>
          <KPIReportHandler />
        </Route>
        <Route path='/attrition' exact>
          <KPIReportHandler />
        </Route>
        <Route path='/sla' exact>
          <KPIReportHandler />

        </Route>
        <Route path='/csat' exact>
          <KPIReportHandler />

        </Route>
        <Route path='/delivery' exact>
          <KPIReportHandler />
        </Route>
        <Route path='/contact' exact>
          <KPIReportHandler />
        </Route>

        <Route path="/widget" exact>
          <LandingPage />
        </Route>
        <Route path='/role' exact>
          <Roleselectionpage />
        </Route>
        <Route path='/' exact>
          <UserLandingPage />
        </Route>
        <Route path='/welcome/filter' exact>
          <AccountSelect />
        </Route>
        <Route path='/welcome/release' exact>
          <ReleaseHandler />
        </Route>
        <Route path='/admin/Exemption' exact>
          <SLAExemptionHandler />
        </Route>
        <Route path='/admin/Exclusion' exact>
          <SLAExclusionHandler />
        </Route>
        <Route path='/Admin' exact>
          <AdminLanding />
        </Route>
        <Route path='/admin/staff' exact>
          <EmployeeAssign />
        </Route>
        <Route path='/admin/staff/assign' exact>
          <EmployeeDetails />
        </Route>
        <Route path='/admin/account' exact>
          <Navigationbar />
        </Route>
        <Route path='/request' exact>
          <RequestIncidentMain />
        </Route>
        <Route path='/adminrequest' exact>
          <AdminRequestMain />
        </Route>
        <Route path='/dtm' exact>
          <DTMAnalyticsHandler />
        </Route>
        <Route path='/answer' exact>
          <DTMAnalyticsSLA />
        </Route>
        <Route path='/intro' exact>
          <FirstVisitor />
        </Route>
        <Route path='/agent' exact>
          <AgentScoreCard />
        </Route>
        <Route path="/onboardAccount" exact={true}>
          <Navigationbar />
        </Route>
        <Route path='/onboard' exact>
          <OnboardLanding />
        </Route>
        <Route path='/githublanding' exact>
          <GitHubPage />
        </Route>
        <Route path='/whygsd' exact>
          <WhyGsd />
        </Route>
        <Route path='/getstarted' exact>
          <GetStarted />
        </Route>
        <Route path='/architecture' exact>
          <Architecture />
        </Route>
        <Route path='/product' exact>
          <ProductRequirements />
        </Route>
        <Route path='/developer' exact>
          <DeveloperLanding />
        </Route>
        <Route path='/user' exact>
          <User/>
        </Route>
        <Route path='/security' exact>
          <Security/>
        </Route>
        <Route path='/overview' exact>
          <OverView/>
        </Route>
        <Route path='/training' exact>
          <Training/>
        </Route>
        <Route path='/design' exact>
          <Design/>
        </Route>
        <Route path='/design/card' exact>
          <DesignCards/>
        </Route>
        <Route path='/database' exact>
          <Database/>
        </Route>
        <Route path='/features' exact>
          <Features />
        </Route>
        <Route path='/faq' exact>
          <FAQ />
        </Route>
        <Route path='/notinproduction' exact>
          <NotDesigned />
        </Route>
        <Route path='/upload' exact>
          <UploadAccountList />
        </Route>
        <Route path='/trails' exact>
          <ReactTrails />
        </Route>
        <Route path='/upload/account' exact>
          <UploadNavigation />
        </Route>
        <Route path='/upload/dataAdmin' exact>
          <DataAdministration />
        </Route>
        

        <Route path='/adminviewlanding' exact>
          <AdminViewFirstPage />
        </Route>
        <Route path='/admin/roles' exact>
          <AdminRolesFlow />
        </Route>
        <Route path='/admin/roles/addRole' exact>
          <AddAdminViewRolePage />
        </Route>
        <Route path='/admin/accessgroup' exact>
          <AccessGroupMain />
        </Route>
        <Route path='/admin/accessgroup/addaccessgroup' exact>
          <AccessGroupAddEdit />
        </Route>
        <Route path='/admin/operations' exact>
          <OperationsMain />
        </Route>
        <Route path='/admin/users' exact>
          <UsersMain />
        </Route>
        <Route path='/admin/users/adduser' exact>
          <AddUsers />
        </Route>
        <Route path='/auditrole' exact>
          <AuditRole />
        </Route>
        <Route path='/auditrbac' exact>
          <AuditRBAC />
        </Route>
        <Route path='/agentskpisummary' exact>
          <AgentsKPISummaryTabs />
        </Route>
        <Route path='/account' exact>
          <TestingFile />
        </Route>
      </Switch>
      </Suspense>
    </>
  );
}

export default withRouter(AppRoutes);
