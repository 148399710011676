import React from 'react'
import WindowClose_Icon from './../../../assets/github-images/WindowClose_Icon.png';

const PlatformVideo = (props) => {
  return (
    <section className='Video_Model'>
<div  onClick={props.closeWindow} style={{marginLeft:'98%',cursor:'pointer'}}><img src={WindowClose_Icon} alt='' /></div>
    <div>  <video src='/static/video/GSDInsight3.mp4' height="300" controls="controls" autoplay="true" style={{width:'90%'}}/></div>
    <div className='Video_infoTxt'>This GSD Insight Demo Video is intended exclusively for the users and viewers of GSD Insight Portal.
     The content of this video is based on the GSD Insight design concepts and sample account data, hence the 
     inserted screens may slightly differ from what the user can find in GSD Insight production environment.</div>

    </section>
  )
}

export default PlatformVideo