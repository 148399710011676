import { put, takeEvery, all, call } from "redux-saga/effects";
import actionTypes from "../constants/actionTypes";
import {
  getMonthTabDataService,
  getSummaryTabDataService,
  getWeekTabDataService,
  getDayTabDataService,
  getAnnualTabDataService,
  getHomeFeedDataService
} from "../services/tabMenu.service";
import ConstructURLs from "../constants/urls";

function* fetchMonthData() {
  try {    
    const response = yield call(getMonthTabDataService, ConstructURLs('GET_TAB_MONTH_DATA'));
    console.log("Fetching month tab data is successfull");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.MONTH_TAB_DATA,
        payload: response.data.blocks,       
      });
    }
  } catch (error) {
    console.log("fetching monthTab data failed " + error);    
  }
}

function* fetchSummaryData() {
  try {
    const response = yield call(
      getSummaryTabDataService,
      ConstructURLs('GET_TAB_SUMMARY_DATA')
    );
    console.log("Fetching Summary tab data is successfull");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.SUMMARY_TAB_DATA,       
        payload: response.data
      });
    }
  } catch (error) {
    console.log("fetching summary tab data failed " + error);
    
  }
}

function* fetchWeekData() {
  try {
    const response = yield call(
      getWeekTabDataService,
      ConstructURLs('GET_WEEK_TAB_DATA')
    );
    console.log("Fetching Week tab data is successfull");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.WEEK_TAB_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("fetching Week tab data failed " + error);    
  }
}

function* fetchDayData() {
  try {
    const response = yield call(
      getDayTabDataService,
      ConstructURLs('GET_WEEK_TAB_DATA')
    );
    console.log("Fetching Day tab data is successfull");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.DAY_TAB_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("fetching Day tab data failed " + error);    
  }
}

function* fetchAnnualData() {
  try {
    const response = yield call(
      getAnnualTabDataService,
      ConstructURLs('GET_WEEK_TAB_DATA')
    );
    console.log("Fetching Annual tab data is successfull");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.ANNUAL_TAB_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("fetching Annual tab data failed " + error);    
  }
}

function* fetchHomeFeedData() {
  try {
    const response = yield call(
      getHomeFeedDataService,
      ConstructURLs('GET_HOME_FEED_DATA')
    );
    console.log("Fetching Annual tab data is successfull");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.HOME_FEED_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("fetching Annual tab data failed " + error);    
  }
}

function* actionWatcher() {
  yield takeEvery(actionTypes.GET_MONTH_TAB_DATA, fetchMonthData);
  yield takeEvery(actionTypes.GET_SUMMARY_TAB_DATA, fetchSummaryData);
  yield takeEvery(actionTypes.GET_WEEK_TAB_DATA, fetchWeekData);
  yield takeEvery(actionTypes.GET_DAY_TAB_DATA, fetchDayData);
  yield takeEvery(actionTypes.GET_ANNUAL_TAB_DATA, fetchAnnualData);
  yield takeEvery(actionTypes.GET_HOME_FEED_DATA, fetchHomeFeedData);
}

export default function* tabSagas() {
  yield all([actionWatcher()]);
}
