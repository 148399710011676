import React, { useState } from 'react'
import ToolTip from 'react-power-tooltip'
const TooltipHandler = (props) => {
  const [showEditInfo, setShowEditInfo] = useState(false)
  return (
    <div>
      <span style={{ position: "relative" }}>
        <img src={props.src} alt='info' width={props.width} height={props.height} 
        onMouseOver={() => setShowEditInfo(true)} onMouseLeave={() => setShowEditInfo(false)} style={{cursor:'pointer'}}/>
        <ToolTip show={showEditInfo}
        //  position={props.info? 'bottom right' : 'top center'}
        position={props.position}
        //  arrowAlign={props.info? "start":"center"}
        arrowAlign={props.arrowAlign}
         borderRadius='10px' fontSize='14/19'
          color='#1D2833' fontWeight='bold'
          textBoxWidth={props.textBoxWidth}>
            <span >{props.text}</span>
        </ToolTip></span>
    </div>
  )
}
export default TooltipHandler;