import React, {  useState } from 'react';
import './SLATable.css'
import { useDispatch } from 'react-redux';
import {  getSLATemplates, updateNewTemplateStart } from '../../../../actions/Onboard.action';
import moment from 'moment';
import DatePickerHandle from '../KeyboardDatePicker';
import RequestDropdown from '../Dropdown/RequestDropdown';
import { useSelector } from 'react-redux';
import { OnboardSelect } from './../Dropdown/Select';

const TargetType = [
    {
        "id": 1,
        "value": "Percentage"
    },
    {
        "id": 2,
        "value": "Time"
    },
    {
        "id": 3,
        "value": "Number"
    }
]
const TemplateCategory = [
    {
        "id": 1,
        "value": "SLA"
    },
    {
        "id": 2,
        "value": "KPI"
    }
]
const Contractual = [
    {
        "id": 1,
        "value": "Yes"
    },
    {
        "id": 2,
        "value": "No"
    }
]

export function TemplateAddModal(props) {
    const dispatch = useDispatch();
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const [addNewTemplate, setAddNewTemplate] = useState([]);
    const [validation, setValidation] = useState('');

    const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }



    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        addValueToTemplate('ValidFrom', formatDate(date))
    }
    const ValidToDateHandler = (date) => {
        setValidTo(formatDate(date));
        addValueToTemplate('ValidTo', formatDate(date))
    }



    const addValueToTemplate = (name, value, id) => {
        const newFormData = { ...addNewTemplate };
        newFormData[name] = value;
        setAddNewTemplate(newFormData);
    }

    const handleAddTemplate = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = { ...addNewTemplate };
        newFormData[fieldName] = fieldValue;
        setAddNewTemplate(newFormData);
    }

    const [placeholder, setPlaceholder] = useState('Example : 38.56 %');
    const [selectedtype, setSelectedType] = useState('Percentage');
    const [targetValue, setTargetValue] = useState('');
    const addValueToFormTable = (name, value) => {
        switch (value) {
            case 'Percentage': setPlaceholder('Example : 38.56 %'); setSelectedType('Percentage'); break;
            case 'Time': setPlaceholder('HH : MM : SS'); setSelectedType('Time'); break;
            case 'Number': setPlaceholder('Example: 000'); setSelectedType('Number'); break;
            default: { }
        }
        if (selectedtype !== value) {
            setTargetValue('')
        }
    }

    const FormatTypeValidation = (type, value) => {
        switch (type) {
            case 'Percentage': {
                return value.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && value?.includes('%');
            }
            case 'Time': {
                return moment(value, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A'], true)?.isValid();
            }
            case 'Number': {
                return !isNaN(value);
            }
            default: { }
        }
    }
    const templateNameHandle = (name, custom) => {
        const cons = ['custom', 'standard', 'kpi', 'sla'];
        let perName = name;
        let test1 = cons.filter(el => (perName.toLowerCase().includes(el)) && el)
        test1.forEach(el => {
            perName = perName.toLowerCase().replace(el, '').trim();
        })
        console.log(perName)
        // let arr2 = perName.split(' ').map(el => el[0]?.toUpperCase() + el?.slice(1, el.length)).join(' ').trim();
       // console.log(arr2)
        return `Custom: ${perName}`
       // return `Custom ${custom}: ${arr2}`
    }
    const handleAddTemplateSubmit = (event) => {
        event.preventDefault();
        let temp_new = { ...addNewTemplate };
        temp_new.VoiceNonVoice = props.TabName;
        temp_new.Customer = props.AccountName;
        temp_new.TemplateName = templateNameHandle(addNewTemplate.TemplateName, addNewTemplate.SLAKPI)
        let temp_Arr = [], temp_arr = [];
        temp_Arr.push(temp_new)
        console.log('temp_Arr:::::::::1',temp_new)
        const dispObj = {
            TemplateName : temp_new.TemplateName,
            FormatType : temp_new.TargetType === 'Percentage' ? '%' : (temp_new.TargetType === 'Time' ? 'hh:mm:ss' : '0'),
            Numerator:temp_new.Numerator,
            Denominator:temp_new.Denominator,
            ValidFrom : temp_new.ValidFrom,
            ValidTo : temp_new.ValidTo,
            Description: temp_new.Description,
            URL :temp_new.URL,
           // SLAKPI : temp_new.SLAKPI,
            VoiceNonVoice : temp_new.VoiceNonVoice,
          //  Contractual : temp_new.Contractual,
            Customer : temp_new.Customer,
            
        }







        console.log('temp_Arr:::::::::2',dispObj)
        temp_arr = {
          //  SLAKPI: addNewTemplate.SLAKPI,
            TemplateName: addNewTemplate.TemplateName,
            ValidFrom: addNewTemplate.ValidFrom,
            ValidTo: addNewTemplate.ValidTo,
            FormatType: addNewTemplate.TargetType === 'Percentage' ? '%' : (addNewTemplate.TargetType === 'Time' ? 'hh:mm:ss' : '0'),
           // Contractual: addNewTemplate.Contractual,

        }
        const isEmpty = Object.values(temp_arr).some(temp => (temp === null || temp === '' || temp === undefined));
        const validDate = moment(addNewTemplate.ValidTo).isAfter(addNewTemplate.ValidFrom)
       // const isValidType = addNewTemplate.FormatType && FormatTypeValidation(selectedtype, addNewTemplate.FormatType)
        if (isEmpty ) {
            setValidation("Please ensure you have filled all ' * ' marked inputs.")
        }
        else if (!validDate) {
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ');
        }
        else{
            console.log('temp_Arr::::::::::::',temp_Arr)
            dispatch(updateNewTemplateStart(dispObj))
            props.handleAddTemplateCancel()
            setValidation('')
            dispatch(getSLATemplates(accountSiteInfo.AccountName, props.TabName));
        }
       
    };
    return (
        <>
            <section className='new-template-container '>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span fixed_add_span'>
                        SLA/KPI Template
                    </div>
                    {<div style={{ color: '#ED4F32' }}>{validation}</div>}
                    <form onSubmit={handleAddTemplateSubmit} >
                        <section className='scrollable_Section'>
                            <main className='input-container-one'>
                                {/* <div className='container-one' style={{ width: '22%' }}>
                                    <label HtmlFor="lang" >Template Category *</label> 
                                    
                                     <OnboardSelect data={TemplateCategory} name='SLAKPI' value='value' id='id'
                                    editValue='' addValueToQueue={addValueToTemplate}
                                />
                                </div> */}
                                <div className='container-one'>
                                    <label>Template Name*</label>
                                    <input type='text'
                                        style={{ marginTop: '-10px', backgroundColor: 'transparent' }}
                                        name='TemplateName'
                                        required='required'
                                        placeholder='Own SLA'
                                        onChange={handleAddTemplate}
                                    />
                                </div>
                                <div className='container-three' style={{ width: '18%' }}>
                                    <label >Target Type*</label>
                                    {/* <OnboardSelect data={TargetType} name='TargetType' value='value' id='id'
                                        QueryHandler={() => { }} editValue={selectedtype}
                                        addValueToQueue={addValueToFormTable} OnClickSelect={props.OnClickSelect}
                                    /> */}
                                    {/* <RequestDropdown data={TargetType} name='TargetType' value='value' id='id'
                                        addValueToQueue={addValueToTemplate} refresh={''}
                                    /> */}
                                    <OnboardSelect data={TargetType} name='TargetType' value='value' id='id'
                                    editValue='' addValueToQueue={addValueToTemplate}
                                />
                                </div>
                                <div className='target_container'>
                                  Ex: {addNewTemplate.TargetType === 'Percentage' ? '%' : (addNewTemplate.TargetType === 'Time' ? 'hh:mm:ss' : '0')}
                                    {/* <input type='text'
                                        placeholder={placeholder}
                                        name='FormatType'
                                        //required='required'
                                        value={targetValue}
                                        onChange={(event) => {
                                            setTargetValue(event.target.value)
                                            handleAddTemplate(event)
                                        }}
                                    /> */}
                                </div>
                            </main>
                            <main className='input-container-three'>
                                {/* <div className='container-three'>
                                    <label HtmlFor='type' style={{ marginBottom: '1em' }}> Format Type</label>
                                    <OnboardSelect data={timeFormats} name='FormatType' value='format' id='id'
                                        QueryHandler={() => { }} editValue=''
                                        addValueToQueue={addValueToTemplate}
                                    />
                                </div> */}
                                <div className='container-three'>
                                    <label>Formula  Numerator</label>
                                    <input type='text'
                                        name='Numerator'
                                        // required='required'
                                        placeholder='Example: Abandoned in X'
                                        onChange={handleAddTemplate}
                                    />
                                </div>
                                <div className='container-three'>
                                    <label>Formula Denominator (if any)</label>
                                    <input type='text'
                                        name='Denominator'
                                        // required='required'
                                       placeholder='Example: Offered - Abandoned<X'
                                        onChange={handleAddTemplate}
                                    />
                                </div>
                                {/* <div className='container-three' style={{ width: '22%' }}>
                                    <label HtmlFor="lang" >Contractual SLA/KPIs *</label>
                                    
                                    <OnboardSelect data={Contractual} name='Contractual' value='value' id='id'
                                    editValue='' addValueToQueue={addValueToTemplate}
                                />
                                </div> */}
                            </main><br />
                            <main className='input-container-three'>


                                <DatePickerHandle className='container-three' label='Valid From *' id='label'
                                    value={ValidFrom}
                                    onChange={(date) => ValidFromDateHandler(date)}
                                />
                                <DatePickerHandle className='container-three' label='Valid To *' id='label'
                                    value={ValidTo}
                                    onChange={(date) => ValidToDateHandler(date)}
                                />

                            </main>
                            <main className='input-container-one'>
                                <div className='container-six'>
                                    <label>Description</label>
                                    <input type='text'
                                        name='Description'
                                        placeholder='description text'
                                        onChange={handleAddTemplate}
                                    />
                                </div>
                            </main>
                            <main className='input-container-one'>
                                <div className='container-six'>
                                    <label>See more URL</label>
                                    <input type='text'
                                        name='URL'
                                        placeholder='https://dxc.com'
                                        onChange={handleAddTemplate}
                                    />
                                </div>
                            </main>
                        </section>

                        <main className='input-container-four fixed_footer_span'>
                            <button type='submit' className='btn_adduser'> ADD  </button>
                            <button className='btn_canceluser' onClick={props.handleAddTemplateCancel}> CANCEL  </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
