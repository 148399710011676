import React, { useState, useRef, useEffect } from "react";
import "./hamburger-menu.css";
import Tooltip from "react-power-tooltip";
import HomeImg from "../../assets/images/Home_Normal.png";
import HomeHovImg from "../../assets/images/Home_Hover.png";
import ReportsImg from "../../assets/images/Reports_Normal.png";
import ReportsHovImg from "../../assets/images/Reports_Hover.png";
import UploadsImg from "../../assets/images/Uploads_Normal.png";
import UploadsHovImg from "../../assets/images/Uploads_Hover.png";
import AccountImg from "../../assets/images/Account_Normal.png";
import AccountHovImg from "../../assets/images/Account_Hover.png";
import OnboardImg from "../../assets/images/Onboard_Normal.png";
import OnboardHovImg from "../../assets/images/Onboard_Hover.png";
import RequestsImg from "../../assets/images/Requests_Normal.png";
import RequestsHovImg from "../../assets/images/Requests_Hover.png";
import AdministrationImg from "../../assets/images/Administration_Normal.png";
import AdministrationHovImg from "../../assets/images/Administration_Hover.png";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { saveSelectedMenuItem } from "../../actions/tabMenu.action";
import { getRoleBasedView } from "../../actions/Administration/admin.action";
import { getRegionalSiteData } from "../../actions/regionalSite.action";
const Panel = (ref) => {
  let activClasses, activTooltip;
  let url = window.location.href;
  const history = useHistory();
  const dispatch = useDispatch();
  const roleSelected = localStorage.getItem('RoleSelected');
  const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch])
  const [isOpen, setOpen] = React.useState(true);
  const [showHoverEffect, setHoverEffect] = React.useState([]);
  const [activeMenu, setActiveMenu] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [tltpMenu, setTltpMenu] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [actvBtn, setActvBtn] = useState(false);
  const [pnlWdth, setPnlWdth] = useState(false);
  const selectedMenuName = useSelector((state) => state.tabReducer.selectedMenuName);

  useEffect(() => {
    switch (selectedMenuName) {
      case 'home': {
        setActiveMenu([true, false, false, false, false, false, false]);
        break;
      }
      case 'reports': {
        setActiveMenu([false, true, false, false, false, false, false]);
        break;
      }
      case 'upload': {
        setActiveMenu([false, false, true, false, false, false, false]);
        break;
      }
      // case 'account': {
      //   setActiveMenu([false, false, false, true, false, false, false]);
      //   break;
      // }
      case 'onboard': {
        setActiveMenu([false, false, false, false, true, false, false]);
        break;
      }
      case 'request': {
        setActiveMenu([false, false, false, false, false, true, false]);
        break;
      }
      case 'admin': {
        setActiveMenu([false, false, false, false, false, false, true]);
        break;
      }
      default: { }
    }

  }, [selectedMenuName]);

  const addActiveClass = (index, mnName) => {
    activClasses = activeMenu.map((element) => (element = false));
    activClasses = [
      ...activClasses.slice(0, index),
      !activClasses[index],
      activClasses.slice(index + 1),
    ].flat();
    switch (mnName) {
      case 'home': {
        dispatch(saveSelectedMenuItem('home'));
        history.push("/welcome");
        // history.push('/');
        break;
      }
      case 'reports': {
        // if(url.includes('/reports')){
        //   dispatch(saveSelectedMenuItem('reports'));
        //   history.push("/reports");         
        // }else{
        dispatch(saveSelectedMenuItem(''));
        history.push("/regional");
        dispatch(getRegionalSiteData());
        //}
        break;
      }
      case 'upload': {
        if(RoleBasedView?.[0]?.['File Upload']?.[0]?.Read === 0){

        }
        else{
        dispatch(saveSelectedMenuItem('upload'));
        history.push('/upload')
        }
        break;
      }
      //  case 'account': {
      //   dispatch(saveSelectedMenuItem('account'));
      //   history.push('/account')
      //   break;
      // }
      case 'onboard': {
        if(RoleBasedView?.[0]?.AccountOnboard?.[0]?.ReadWrite === 0){

        }
        else{
        dispatch(saveSelectedMenuItem('onboard'));
        history.push('/onboard')
      }
        break;
      }
      case 'request': {
        dispatch(saveSelectedMenuItem('request'));
        if(roleSelected !== 'Admin'){
        history.push('/request')
        }
        else{
          history.push('/adminrequest')
        }
        break;
      }
      case 'admin': {
        dispatch(saveSelectedMenuItem('admin'));
         if(roleSelected !== 'Admin'){
          history.push('/admin')
          }
          else{
            history.push('/adminviewlanding')
          }
          break;
        }
       
      default: { }
    }
    setActiveMenu(activClasses);
  };

  const showTooltip = (bool, index) => {
    activTooltip = activeMenu.map((element) => (element = false));
    activTooltip = [
      ...activTooltip.slice(0, index),
      (activTooltip[index] = bool),
      activTooltip.slice(index + 1),
    ].flat();
    setTltpMenu(activTooltip);
  };

  const setActBtn = (bool) => {
    setActvBtn(bool);
  };

  const setPnlWidth = (bool) => {
    if (bool) setPnlWdth(bool);
  };

  useEffect(() => {
    if (tltpMenu && tltpMenu.length > 0) {
      setHoverEffect(tltpMenu);
    }
  }, [tltpMenu]);

  //   useEffect(() => {
  //     /**
  //      * Alert if clicked on outside of element
  //      */
  //     const handleClickOutside = (event) => {
  //         if (ref.current && !ref.current.contains(event.target)) {
  //             setPnlWidth(false);
  //         }
  //     }

  //     // Bind the event listener
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //         // Unbind the event listener on clean up
  //         document.removeEventListener("mousedown", handleClickOutside);
  //     };
  // }, [ref]);

  return (
    <div>
      <button
        onClick={() => {
          setOpen(!isOpen);
        }}
        className={
          (`hamburger-button ${isOpen ? "open" : "close"}`,
            actvBtn ? "hamburger-hover-button" : "hamburger-button")
        }
        onMouseOver={() => setActBtn(true)}
        onMouseLeave={() => setActBtn(false)}
      />
      {/* className={`panel ${isOpen ? "open" : "close"}`} */}
      <ul id="hamburger-ul"
        style={{ maxWidth: !isOpen ? "175px" : "80px" }}
        className={`panel open`}
      >
        <li style={{height:65}}>
          <div
            id="home"
            className={(activeMenu[0] || showHoverEffect[0]) ? "menu-item-active" : "menu-item"}
            onClick={() => addActiveClass(0, 'home')}
            style={{ position: "relative" }}
            onMouseOver={() => { showTooltip(true, 0) }}
            onMouseLeave={() => { showTooltip(false, 0) }}
          >
            {isOpen ? (
              <Tooltip zIndex="99999" show={tltpMenu[0]} textAlign="center">
                <span>Home</span>
              </Tooltip>
            ) : null}
            <div
              className={
                pnlWdth ? "flex-container-legnd-expnd" : "flex-container-legnd"
              }
              style={{ justifyContent: "left !important" }}
            >
              <img
                className="items-text-center"
                src={(activeMenu[0] || showHoverEffect[0]) ? HomeHovImg : HomeImg}
                alt="Home Icon"
              />
              {!isOpen ? (
                <span
                  className={`items-text-center mrgn-lft-10 ${activeMenu[0] ? "clr-purple" : "clr-grey"
                    }`}
                >
                  Home
                </span>
              ) : null}
            </div>
          </div>
        </li>
        <li style={{height:65}}>
          <div
            id="about"
            style={{ position: "relative" }}
            className={(activeMenu[1] || showHoverEffect[1]) ? "menu-item-active" : "menu-item"}
            onClick={() => addActiveClass(1, 'reports')}
            onMouseOver={() => { showTooltip(true, 1) }}
            onMouseLeave={() => { showTooltip(false, 1) }}
          >
            {isOpen ? (
              <Tooltip show={tltpMenu[1]} textAlign="center">
                <span>Reports</span>
              </Tooltip>
            ) : null}
            <div
              className={
                pnlWdth ? "flex-container-legnd-expnd" : "flex-container-legnd"
              }
            >
              <img
                src={(activeMenu[1] || showHoverEffect[1]) ? ReportsHovImg : ReportsImg}
                alt="Reports Icon"
              />
              {!isOpen ? (
                <span
                  className={`items-text-center mrgn-lft-10 ${activeMenu[1] ? "clr-purple" : "clr-grey"
                    }`}
                >
                  Reports
                </span>
              ) : null}
            </div>
          </div>
        </li>
        <li style={{height:65}}>
          <div
          //  className="menu-item-disabled"
            id="contact"
             style={{ position: "relative" }}
            className={RoleBasedView?.[0]?.['File Upload']?.[0]?.Read === 0 ? "menu-item-disabled" : (activeMenu[2] || showHoverEffect[2]) ? "menu-item-active" : "menu-item"}
            onClick={() => addActiveClass(2, 'upload')}
            onMouseOver={() => showTooltip(true, 2)}
            onMouseLeave={() => showTooltip(false, 2)}
          >
            {isOpen ? (
              <Tooltip show={tltpMenu[2]} textAlign="center">
                <span>Uploads</span>
              </Tooltip>
            ) : null}
            <div
              className={
                pnlWdth ? "flex-container-legnd-expnd" : "flex-container-legnd"
              }
            >
              <img
               // src={(activeMenu[2]) ? UploadsHovImg : UploadsImg}
                src={RoleBasedView?.[0]?.['File Upload']?.[0]?.Read === 0 ? UploadsImg : (activeMenu[2] || showHoverEffect[2] ) ? UploadsHovImg : UploadsImg}
                alt="Uploads Icon"
              />
              {!isOpen ? (
                <span
                  className={`items-text-center mrgn-lft-12 ${activeMenu[2] ? "clr-purple" : "clr-grey"
                    }`}
                >
                  Uploads
                </span>
              ) : null}
            </div>
          </div>
        </li>
        <li style={{height:65}}>
          <div
           className="menu-item-disabled"
            id="account"
             style={{ position: "relative" }}
          //   className={activeMenu[3] ? "menu-item-active" : "menu-item"}
          //  onClick={() => addActiveClass(3, 'account')}
            onMouseOver={() => showTooltip(true, 3)}
            onMouseLeave={() => showTooltip(false, 3)}
          >
            {isOpen ? (
              <Tooltip show={tltpMenu[3]} textAlign="center">
                <span>Accounts</span>
              </Tooltip>
            ) : null}
            <div
              className={
                pnlWdth ? "flex-container-legnd-expnd" : "flex-container-legnd"
              }
            >
              <img
                src={activeMenu[3] ? AccountHovImg : AccountImg}
                alt="Acounts Icon"
              />
              {!isOpen ? (
                <span
                  className={`items-text-center mrgn-lft-10 ${activeMenu[3] ? "clr-purple" : "clr-grey"
                    }`}
                >
                  Accounts
                </span>
              ) : null}
            </div>
          </div>
        </li>
        <li style={{height:65}}>
          <div
           //className="menu-item-disabled"
            id="onboard"
            style={{ position: "relative" }}
            className={RoleBasedView?.[0]?.AccountOnboard?.[0]?.ReadWrite === 0 ? "menu-item-disabled" : (activeMenu[4] || showHoverEffect[4]) ? "menu-item-active" : "menu-item"}
            onClick={() => addActiveClass(4, 'onboard')}
            onMouseOver={() => showTooltip(true, 4)}
            onMouseLeave={() => showTooltip(false, 4)}
          >
            {isOpen ? (
              <Tooltip show={tltpMenu[4]} textAlign="center">
                <span>Onboard</span>
              </Tooltip>
            ) : null}
            <div
              className={
                pnlWdth ? "flex-container-legnd-expnd" : "flex-container-legnd"
              }
            >
              <img
                src={RoleBasedView?.[0]?.AccountOnboard?.[0]?.ReadWrite === 0 ? OnboardImg : (activeMenu[4] || showHoverEffect[4] ) ? OnboardHovImg : OnboardImg}
                alt="Onboard Icon"
              />
              {!isOpen ? (
                <span
                  className={`items-text-center mrgn-lft-10 ${activeMenu[4] ? "clr-purple" : "clr-grey"
                    }`}
                >
                  Onboard
                </span>
              ) : null}
            </div>
          </div>
        </li>
        <li style={{height:65}}>
          <div
          //  className="menu-item-disabled"
            id="contact"
            style={{ position: "relative" }}
            className={(activeMenu[5] || showHoverEffect[5]) ? "menu-item-active" : "menu-item"}
            onClick={() => addActiveClass(5, 'request')}
            onMouseOver={() => showTooltip(true, 5)}
            onMouseLeave={() => showTooltip(false, 5)}
          >
            {isOpen ? (
              <Tooltip show={tltpMenu[5]} textAlign="center">
                <span>Request / Incident</span>
              </Tooltip>
            ) : null}
            <div
              className={
                pnlWdth ? "flex-container-legnd-expnd" : "flex-container-legnd"
              }
            >
              <img
                src={(activeMenu[5] || showHoverEffect[5]) ? RequestsHovImg : RequestsImg}
                alt="Contact Icon"
              />
              {!isOpen ? (
                <span
                  className={`items-text-center mrgn-lft-10 ${activeMenu[5] ? "clr-purple" : "clr-grey"
                    }`}
                >
                  Requests
                </span>
              ) : null}
            </div>
          </div>
        </li>
        <li style={{height:65}}>
          <div
           // className="menu-item-disabled"
            id="administration"
            style={{ position: "relative" }}
            className={(activeMenu[6] || showHoverEffect[6]) ? "menu-item-active" : "menu-item"}
            onClick={() => addActiveClass(6, 'admin')}
            onMouseOver={() => showTooltip(true, 6)}
            onMouseLeave={() => showTooltip(false, 6)}
          >
            {isOpen ? (
              <Tooltip show={tltpMenu[6]} textAlign="center">
                <span>Administration</span>
              </Tooltip>
            ) : null}
            <div
              className={
                pnlWdth ? "flex-container-legnd-expnd" : "flex-container-legnd"
              }
            >
              <img
                src={(activeMenu[6] || showHoverEffect[6]) ? AdministrationHovImg : AdministrationImg}
                alt="Administration Icon"
              />
              {!isOpen ? (
                <span
                  className={`items-text-center mrgn-lft-10 ${activeMenu[6] ? "clr-purple" : "clr-grey"
                    }`}
                >
                  Administration
                </span>
              ) : null}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

const MnApp = (props) => {
  const wrapperRef = useRef(null);
  Panel(wrapperRef);
  return (
    <div ref={wrapperRef}>
      <Panel />
    </div>
  );
};

export default MnApp;
