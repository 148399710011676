import API from "../api";
let token = localStorage.getItem("token");
export const getFinanceDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getFinanceDropdownService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getFinanceAccountService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

