import React from 'react';
import { useHistory } from "react-router-dom";
import  './WelcomePage.css';

function HomeBreadcrumb(props) {
  const history = useHistory();
  return (
    <>
   <section className='home_breadcrumb' style={props.style}> 
     <span  style={{color:'#5F249F'}} onClick={()=>history.push('/welcome')}> Home / </span> 
     <span  style={{color:'#63666A'}}>{props.pageId} </span>
   </section>
    </>
  )
}

export default HomeBreadcrumb