import { all, call, put, takeEvery } from "redux-saga/effects";
import { requestUrls } from "../constants/request-urls";
import { getAdminRequestDataService, getRequestDataService, getRequestDropdownDataService, getUploadedFileDataService, updateEditRequestService, updateNewRequestService } from "../services/request.service";
import { request_actionTypes as actionTypes} from './../constants/request-actionTypes';
import { updateNewRequestSuccess, updateEditRequestSuccess } from './../actions/request.action';

function* fetchRequestData({ date, requesttype, status }) {
    let items = {
        date: date,
        requesttype: requesttype,
        status: status
    }
    try {
        const response = yield call(getRequestDataService, requestUrls('GET_REQUEST_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.REQUEST_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while TILES  data", error);
    }
}

function* updateNewRequest({ payload: id }) {
    try {
        const response = yield call(updateNewRequestService, requestUrls('UPDATE_NEW_REQUEST'), id);
        if (response.data) {
            yield put(
               
                updateNewRequestSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateEditRequest({ payload: id }) {
    try {
        const response = yield call(updateEditRequestService, requestUrls('UPDATE_EDIT_REQUEST'), id);
        if (response.data) {
            yield put(
               
                updateEditRequestSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

// function* fetchAdminRequestData(action) {
//     try {
//         const response = yield call(getAdminRequestDataService, requestUrls('GET_ADMIN_REQUEST_DATA'));
//         console.log('response:::::::::::::',response);
//         if (response.data) {
//             yield put({
//                 type: actionTypes.ADMIN_REQUEST_DATA,
//                 payload: response.data,
//             });
//         }
//     } catch (error) {
//         console.log("Error while TILES  data", error);
//     }
// }

function* fetchAdminRequestData({ date, requesttype, status }) {
    let items = {
        date: date,
        requesttype: requesttype,
        status: status
    }
    try {
        const response = yield call(getAdminRequestDataService, requestUrls('GET_ADMIN_REQUEST_DATA',items));
        console.log('response:::::::::::::',response);
        if (response.data) {
            yield put({
                type: actionTypes.ADMIN_REQUEST_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while TILES  data", error);
    }
}

function* updateEditAdminRequest({ payload: id }) {
    try {
        const response = yield call(updateEditRequestService, requestUrls('UPDATE_EDIT_ADMIN_REQUEST'), id);
        if (response.data) {
            yield put(
               
                updateEditRequestSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchRequestDropdownData(action) {
    try {
        const response = yield call(getRequestDropdownDataService, requestUrls('GET_REQUEST_DROPDOWN_DATA'));
        console.log('response:::::::::::::',response);
        if (response.data) {
            yield put({
                type: actionTypes.REQUEST_DROPDOWN_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while request dropdown  data", error);
    }
}

function* fetchUploadedFileData({ uploadfileid }) {
    let items = {
        uploadfileid:uploadfileid
    }
    try {
        const response = yield call(getUploadedFileDataService, requestUrls('GET_UPLOADED_FILE_DATA',items));
     //   console.log('response:::::::::::::',response);
        if (response.data) {
            yield put({
                type: actionTypes.UPLOADED_FILE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while UPLOADED FILE  data", error);
    }
}

function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_REQUEST_DATA, fetchRequestData);
    yield takeEvery(actionTypes.UPDATE_NEW_REQUEST_START, updateNewRequest);
    yield takeEvery(actionTypes.UPDATE_EDIT_REQUEST_START, updateEditRequest);
    yield takeEvery(actionTypes.GET_ADMIN_REQUEST_DATA, fetchAdminRequestData);
    yield takeEvery(actionTypes.UPDATE_EDIT_ADMIN_REQUEST_START, updateEditAdminRequest);
    yield takeEvery(actionTypes.GET_REQUEST_DROPDOWN_DATA, fetchRequestDropdownData);
    yield takeEvery(actionTypes.GET_UPLOADED_FILE_DATA, fetchUploadedFileData);


}
export default function* requestSagas() {
    yield all([watchGetRequest()]);
}