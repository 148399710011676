import React, { useEffect, useState } from 'react';
import './SLATable.css'
import contractIcon from './../../../../assets/onboard-images/Contractual_SLA_Icon.png'
import templateIcon from './../../../../assets/onboard-images/ViewDetailsLink_Arrow_Hover_Icon.png'
import { OnboardSelect } from '../Dropdown/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getSLATemplates } from '../../../../actions/Onboard.action';
import DatePickerHandle from '../KeyboardDatePicker';
import TooltipHandler from '../TooltipHandler';
import Information_Icon from './../../../../assets/onboard-images/Information_Icon.png'
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';


export function AddAccountModal(props) {
    const dispatch = useDispatch();
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const [templateLink, showTemplateLink] = useState(false)
    const newSlATemplate = useSelector(state => state.onboardReducer.SLATemplate);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    console.log('newSlATemplate',newSlATemplate)
    useEffect(() => {
        dispatch(getSLATemplates(props.accountSiteInfo.AccountName, props.TabName));
    }, [])

    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch])

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        props.addDateToAccount('valid_from', formatDate(date));
    }
    const ValidToDateHandler = (date) => {
        setValidTo(formatDate(date));
        props.addDateToAccount('valid_to', formatDate(date));
    }

    const EditMode = props.EditMode;
    let templateData = newSlATemplate;
    const [newTemplateData, setNewTemplateData] = useState([]);
    const HandleaddValueToAccount = (name, value, id) => {
        dispatch(getSLATemplates())
        let temp_arr = [];
        temp_arr = templateData?.filter(temp => temp.ID === id && temp);
        props.TargetTypeHandle(temp_arr[0]?.Type)
        props.addValueToAccount(name, value, id,temp_arr[0]?.Type,temp_arr[0].Comparison)
        setNewTemplateData(temp_arr[0]);
    }
    useEffect(() => {
        if (EditMode) {
            let temp_arr = [];
            temp_arr = templateData?.filter(temp => temp.ID === props.editAccountInfo.sla_template_id && temp);
            setNewTemplateData(temp_arr[0]);
        }
    }, [EditMode])
    const targetPlaceholder = () => {
        switch (props.targetType) {
            case 'Percentage': {
                return ' Example : 35.45%'
            }
            case 'Time': {
                return ' Example: 00:00:00'
            }
            case 'Number': {
                return 'Example: 4657'
            }
            default: { }
        }
    }

    const TemplateCategory = [
        {
            "id": 1,
            "value": "SLA"
        },
        {
            "id": 2,
            "value": "KPI"
        }
    ]
    const Contractual = [
        {
            "id": 1,
            "value": "Yes"
        },
        {
            "id": 2,
            "value": "No"
        }
    ]
    return (
        <>
            <section className='new-template-container-SLA'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span fixed_add_span'>
                        <div id='modal-name'> {props.modalName}</div>
                     {RoleBasedView?.[0]?.SLATemplate?.[0]?.ReadWrite === 1 ? <>  {EditMode === false && <div className='link_sla' style={{ cursor: 'pointer' }} id='link-name'
                            onMouseEnter={() => showTemplateLink(true)}
                            onMouseLeave={() => showTemplateLink(false)}
                            onClick={props.TemplateModalHandle}>
                            {!templateLink ? <div id='line-span'></div> :
                                <img src={templateIcon} alt='' />}
                            New Template </div>}</> : ''}
                    </div>
                    {props.validation && <div style={{ color: '#ED4F32' }}>{props.validation}</div>}

                    <form onSubmit={EditMode === true ? props.handleEditAccountSubmit : props.handleAddAccountSubmit} >
                        <section className='scrollable_Section'>
                            <main className='input_container_one_temp'>
                                <div className='container-one extra-six '>
                                    <div className='container-extra' >
                                    <label style={{ display: 'flex' }}><span>Template*</span> &nbsp;  &nbsp; <span style={{ position: 'relative' }} >
                                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom left'
                                            arrowAlign='start'
                                            textBoxWidth='300px' info={true} text='Please select the desired standard measurement template from the drop-down menu.' />
                                    </span> </label>
                                    </div>
                                    {EditMode ? <span><b>{props.editAccountInfo.sla_template}</b></span> :<OnboardSelect data={templateData} name='sla_template' value='TemplateName' id='ID'
                                        className='extra-six' editValue={EditMode ? props.editAccountInfo.sla_template : ''}
                                        addValueToQueue={HandleaddValueToAccount}
                                    />}
                                </div>
                                <div className='container-one' style={{ width: '33%' }}>
                                    <label HtmlFor="lang" >Type *</label>
                                    {EditMode ? <span><br/><b>{props.editAccountInfo.SLAKPI}</b></span> : <OnboardSelect data={TemplateCategory} name='SLAKPI' value='value' id='id'
                                    editValue={EditMode ? props.editAccountInfo.SLAKPI : ''} addValueToQueue={props.addValueToSlaKpi}
                                />}
                                </div>
                            </main>
                            <main className='input-container-three'>
                                <div className='container-three' style={{ width: '33%' }}>
                                <label style={{ display: 'flex' }}><span>Contractual *</span> &nbsp;  &nbsp; <span style={{ position: 'relative' }} >
                                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom left'
                                            arrowAlign='start'
                                            textBoxWidth='300px' info={true} text={`The "shaking hand" icon will be visible if the chosen metric is contractually required.`} />
                                    </span> </label>
                                    {EditMode ? <span><br/><b>{props.editAccountInfo.Contractual}</b></span> : <OnboardSelect data={Contractual} name='Contractual' value='value' id='id'
                                    editValue={EditMode ? props.editAccountInfo.Contractual : ''} addValueToQueue={props.addValueToSlaKpi}
                                />}
                                </div>
                                {/* Format Accepted: ${props.targetType} */}
                                <div className='container-three'>
                                    <label style={{ display: 'flex' }}><span>Minimum Target</span> &nbsp; &nbsp;<span style={{ position: 'relative' }} >
                                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right'
                                            arrowAlign='start'
                                            textBoxWidth='300px' info={true} text={`The minimum performance level in respect of each Service Level and/or Key Performance Indicator set [not meeting the minimum target results in "red" colored performance metrics].`} />
                                    </span> </label>
                                    {EditMode ? <input type='text'
                                        name='minimum_target'
                                        placeholder={targetPlaceholder()}
                                        value={props.editAccountInfo.minimum_target}
                                        onChange={props.handleFormAddAccount}
                                    /> : <input type='text'
                                        name='minimum_target'
                                        placeholder={targetPlaceholder()}
                                        onChange={props.handleFormAddAccount}
                                    />}
                                </div>
                                <div className='container-three'>
                                    <label style={{ display: 'flex' }}><span>Expected Target</span> &nbsp;  &nbsp; <span style={{ position: 'relative' }} >
                                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom left'
                                            arrowAlign='end'
                                            textBoxWidth='300px' info={true} text={`The expected performance level in respect of each Service Level and/or Key Performance Indicator set [being between the minimum and the expected targets result in "amber" colored performance metrics, whilst exceeding the expected target results in "green" colored performance metrics].`} />
                                    </span> </label>
                                    {EditMode ? <input type='text'
                                        name='expected_target'
                                        placeholder={targetPlaceholder()}
                                        value={props.editAccountInfo.expected_target || ''}
                                        onChange={props.handleFormAddAccount}
                                    /> : <input type='text'
                                        name='expected_target'
                                        placeholder={targetPlaceholder()}
                                        onChange={props.handleFormAddAccount}
                                    />}
                                </div>
                            </main><br />
                            <main className='input-container-three'>
                             <div className='container-three'>
                                    <label style={{ display: 'flex' }}><span>Threshold seconds</span> &nbsp; &nbsp;<span style={{ position: 'relative' }} >
                                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right'
                                            arrowAlign='start'
                                            textBoxWidth='300px' info={true} text='The threshold, in seconds, that an interaction must meet to be counted in the Service Level and/or Key Performance Indicator statistics.' />
                                    </span> </label>
                                    {EditMode ? <input type='text'
                                        name='threshold_seconds'
                                        pattern="[0-9]{1,4}\d?"
                                        placeholder='130'
                                        value={props.editAccountInfo.threshold_seconds}
                                        onChange={props.handleFormAddAccount}
                                    /> : <input type='text'
                                        name='threshold_seconds'
                                        pattern="[0-9]{1,4}\d?"
                                        placeholder='130'
                                        onChange={props.handleFormAddAccount}
                                    />}
                                </div>
                               
                                <DatePickerHandle className='container-three' label='Valid From *'
                                    value={EditMode ? props.editAccountInfo.valid_from : ValidFrom} id='label'
                                    onChange={(date) => EditMode ? props.addDateToAccount('valid_from', formatDate(date)) : ValidFromDateHandler(date)}
                                />
                                <DatePickerHandle className='container-three' label='Valid To *' id='label'
                                    value={EditMode ? props.editAccountInfo.valid_to : ValidTo}
                                    onChange={(date) => EditMode ? props.addDateToAccount('valid_to', formatDate(date)) : ValidToDateHandler(date)}
                                />
                            </main>

                            <main className='input-container-one'>
                             <div className='container-three'>
                                    <label style={{ display: 'flex' }}><span>Short Call Exclusion Seconds</span> &nbsp; &nbsp;<span style={{ position: 'relative' }} >
                                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right'
                                            arrowAlign='start'
                                            textBoxWidth='300px' info={true} text='The threshold in seconds which defines the exclusions of interactions which are not applicable and do not need to be counted in the Service Level and/or Key Performance Indicator statistics.' />
                                    </span> </label>
                                    {EditMode ? <input type='text'
                                        name='short_call'
                                        pattern="[0-9]{1,4}\d?"
                                        placeholder='130'
                                        value={props.editAccountInfo.short_call || ''}
                                        onChange={props.handleFormAddAccount}
                                    /> : <input type='text'
                                        name='short_call'
                                        pattern="[0-9]{1,4}\d?"
                                        placeholder='130'
                                        onChange={props.handleFormAddAccount}
                                    />}
                                </div>
                                <div className='container-one extra-six'>
                                    <label style={{ display: 'flex' }}><span>SLA/KPI Alias</span> &nbsp;  &nbsp; <span style={{ position: 'relative' }} >
                                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom left'
                                            arrowAlign='start'
                                            textBoxWidth='300px' info={true} text='Please define the alternative name which will be displayed in the reports.' />
                                    </span> </label>
                                    {EditMode ? <input type='text'
                                        name='alias'
                                        placeholder='Speed, Answer, Average'
                                        value={props.editAccountInfo.alias}
                                        onChange={props.handleFormAddAccount}
                                    /> : <input type='text'
                                        name='alias'
                                        placeholder='Speed, Answer, Average'
                                        onChange={props.handleFormAddAccount}
                                    />}
                                </div>
                            </main>
                            {newTemplateData && <section>
                                <main className='input-container-three'>
                                    {newTemplateData?.Type && <div className='container-three'>
                                        <label>Type</label>
                                        <div className='span-label'>{newTemplateData.Type}</div>
                                    </div>}
                                    {newTemplateData.Numerator && <div className='container-three'>
                                        <label>Formula: Numerator</label>
                                        <div className='span-label'>{newTemplateData.Numerator}</div>
                                    </div>}
                                    {newTemplateData.Denominator && <div className='container-three'>
                                        <label>Formula: Denominator</label>
                                        <div className='span-label'>{newTemplateData.Denominator}</div>
                                    </div>}
                                </main>
                                {newTemplateData.Description && <main className='input-container-one extra-one'>
                                    <div className='container-six'>
                                        <label>Description</label>
                                        <div className='desc-span'>{newTemplateData.Description}</div>
                                    </div>
                                </main>}
                                {newTemplateData.URL && <main className='input-container-one extra-one'>
                                    <div className='container-six'>
                                        <label>URL</label>
                                        <div className='desc-span'><a href={newTemplateData.URL}>{newTemplateData.URL}</a></div>
                                    </div>
                                </main>}
                            </section>}
                        </section>
                        <main className='input-container-four extra-four fixed_footer_span'>
                            <button type='submit' className='btn_adduser'> {props.buttonOne} </button>
                            <button className='btn_canceluser' onClick={props.handleAddAccountCancel}> {props.buttonTwo}  </button>
                        </main>
                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
