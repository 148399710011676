const RegionalSiteActionTypes = {
  UPDATE_CUSTOMER_ID: "UPDATE_CUSTOMER_ID",
  UPDATE_CUSTOMER_NAME: "UPDATE_CUSTOMER_NAME",
  UPDATE_SERVICEDESK_ID: "UPDATE_SERVICEDESK_ID",
  UPDATE_REGION_NAME: "UPDATE_REGION_NAME",
  GET_REGIONALSITE_CUSTID_DATA: "GET_REGIONALSITE_CUSTID_DATA",
  UPDATE_REGIONALSITE_CUSTID_DATA: "UPDATE_REGIONALSITE_CUSTID_DATA",
  GET_REGIONALSITE_GLOBAL_SUMMARY_DATA: "GET_REGIONALSITE_GLOBAL_SUMMARY_DATA",
  UPDATE_REGIONALSITE_GLOBAL_SUMMARY_DATA: "UPDATE_REGIONALSITE_GLOBAL_SUMMARY_DATA",
  UPDATE_REGION_ID: "UPDATE_REGION_ID",
  UPDATE_DRPDWN_REGIONS_LIST: "UPDATE_DRPDWN_REGIONS_LIST",
  UPDATE_SELECTED_ACCRD_PANEL_ID: "UPDATE_SELECTED_ACCRD_PANEL_ID",
  UPDATE_DRPDWN_REGION_NAME: "UPDATE_DRPDWN_REGION_NAME",
  GET_DTM_ANALYTICS: "GET_DTM_ANALYTICS",
  UPDATE_DTM_ANALYTICS: "UPDATE_DTM_ANALYTICS",
  GET_DTM_ANALYTICS_HALF_HOUR: "GET_DTM_ANALYTICS_HALF_HOUR",
  UPDATE_DTM_ANALYTICS_HALF_HOUR: "UPDATE_DTM_ANALYTICS_HALF_HOUR",
  GET_DTM_ANALYTICS_MONTH: "GET_DTM_ANALYTICS_MONTH",
  UPDATE_DTM_ANALYTICS_MONTH: "UPDATE_DTM_ANALYTICS_MONTH",
  UPDATE_SELECTED_SLA:"UPDATE_SELECTED_SLA"
};

export default RegionalSiteActionTypes;