import ReactModal from "react-modal";

ReactModal.setAppElement('#root')
export function GenericModal({ isOpen, setShowModal, children,height,width }) {
  return (<>
    <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
      onRequestClose={() => setShowModal(false)}
      portalClassName='backdrop'
      style={{
        overlay: {
          position: 'fixed',
          top: '10em',
          left: '20em',
          width: '600px',
          height: '500px',
          background: ' #FFFFFF 0% 0% no-repeat padding-box',
          boxShadow: ' 6px 6px 45px #00000066',
          border: ' 1px solid #ECEEF1',
          opacity: '1',
        },
        content: {
          position: 'absolute',
          top: '0',
          left: '0em',
          right: '0px',
          bottom: '0px',
          border: 'none',
          background: 'none',
          overflow: 'none',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '2em'
        }
      }}>
      {children}
    </ReactModal>

  </>)
}




