const EDashboardActionTypes = {
    GET_KPI_DATA: "GET_KPI_DATA",
    UPDATE_KPI_DATA: "UPDATE_KPI_DATA",
    GET_COMPLIANCE_DATA: "GET_COMPLIANCE_DATA",
    UPDATE_COMPLIANCE_DATA: "UPDATE_COMPLIANCE_DATA",
    GET_CONTACT_VOLUME_DATA: "GET_CONTACT_VOLUME_DATA",
    UPDATE_CONTACT_VOLUME_DATA: "UPDATE_CONTACT_VOLUME_DATA",
    GET_CALLTYPE_DATA: "GET_CALLTYPE_DATA",
    UPDATE_CALLTYPE_DATA: "UPDATE_CALLTYPE_DATA",
    GET_HORIZONTAL_BAR_CHART_DATA: "GET_HORIZONTAL_BAR_CHART_DATA",
    UPDATE_HORIZONTAL_BAR_CHART_DATA: "UPDATE_HORIZONTAL_BAR_CHART_DATA",
    GET_PROFIT_LOSS_ANALYTICS_DATA: "GET_PROFIT_LOSS_ANALYTICS_DATA",
    UPDATE_PROFIT_LOSS_ANALYTICS_DATA:"UPDATE_PROFIT_LOSS_ANALYTICS_DATA",
    GET_YEARMONTH_REGION_DATA: "GET_YEARMONTH_REGION_DATA",
    UPDATE_YEARMONTH_REGION_DATA: "UPDATE_YEARMONTH_REGION_DATA",
    GET_INCIDENTS_CARDS_DATA: "GET_INCIDENTS_CARDS_DATA",
    UPDATE_INCIDENTS_CARDS_DATA: "UPDATE_INCIDENTS_CARDS_DATA",
    GET_EDASHBOARD_NOTIFICATION_TEXT: "GET_EDASHBOARD_NOTIFICATION_TEXT",
    UPDATE_EDASHBOARD_NOTIFICATION_TEXT: "UPDATE_EDASHBOARD_NOTIFICATION_TEXT"
  };
  
  export default EDashboardActionTypes;
  