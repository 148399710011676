import EDashboardActionTypes from "../../constants/eDashboard-actionTypes";

const initialState = { kpiData: {}, complianceData: [], contactVolumeData: [], callTypeData: {}, hrzntlBarChrtData: [], profitLossAnalyticData: {},
 yearMonthRegionDrpdwnData: {}, totalIncidentCards: [], eDashBrdNtfnMsg: '' };


const fetchKPIData = (state, action) => {      
    return {
      ...state,
    };
  };
  
const updateKPIData = (state, action) => {
return { ...state, kpiData: action.payload };
};

const fetchComplianceData = (state, action) => {      
  return {
    ...state,
  };
};

const updateComplianceData = (state, action) => {
  return { ...state, complianceData: action.payload };
  };

const fetchContactVolumeData = (state, action) => {      
  return {
    ...state,
  };
};

const updateContactVolumeData = (state, action) => {
  return { ...state, contactVolumeData: action.payload };
  };

const fetchCallTypeData = (state, action) => {
  return { ...state };
}

const updateCallTypeData = (state, action) => {
  return { ...state, callTypeData: action.payload };
}

const fetchHorizontalBarChartData = (state, action) => {
  return {...state};
}

const updateHorizontalBarChartData = (state, action) => {
  return { ...state, hrzntlBarChrtData: action.payload };
}

const fetchProfitLossAnalyticData = (state, action) => {
  return {...state};
};

const updateProfitLossAnalyticData = (state, action) => {
    return { ...state, profitLossAnalyticData: action.payload };
};


const fetchYearMonthRegionDropdownData = (state, action) => {
    return { ...state };
}

const updateYearMonthRegionDropdownData = (state, action) => {
  return {...state, yearMonthRegionDrpdwnData: action.payload};
}

const fetchIncidentCardsData = (state) => {
  return {...state};
}

const updateIncidentCardsData = (state, action) => {
  return {...state, totalIncidentCards: action.payload};
}

const fetchEDNotificationTextMsg = (state) => {
  return {...state};
}

const updateEDNotificationTextMsg = (state, action) => {
  return {...state, eDashBrdNtfnMsg: action.payload};
}

const eDashboardReducer = (state = initialState, action) => {
    switch (action.type) {
      case EDashboardActionTypes.GET_KPI_DATA:
        return fetchKPIData(state, action);
      case EDashboardActionTypes.UPDATE_KPI_DATA:
        return updateKPIData(state, action);     
      case EDashboardActionTypes.GET_COMPLIANCE_DATA:
        return fetchComplianceData(state, action); 
      case EDashboardActionTypes.UPDATE_COMPLIANCE_DATA:
        return updateComplianceData(state, action); 
      case EDashboardActionTypes.GET_CONTACT_VOLUME_DATA:
        return fetchContactVolumeData(state, action);
      case EDashboardActionTypes.UPDATE_CONTACT_VOLUME_DATA:
        return updateContactVolumeData(state, action);     
      case EDashboardActionTypes.GET_CALLTYPE_DATA:
        return fetchCallTypeData(state, action);
      case EDashboardActionTypes.UPDATE_CALLTYPE_DATA:
        return updateCallTypeData(state, action);
      case EDashboardActionTypes.GET_HORIZONTAL_BAR_CHART_DATA:
        return fetchHorizontalBarChartData(state, action);
      case EDashboardActionTypes.UPDATE_HORIZONTAL_BAR_CHART_DATA:
        return updateHorizontalBarChartData(state, action);
      case EDashboardActionTypes.GET_PROFIT_LOSS_ANALYTICS_DATA:
        return fetchProfitLossAnalyticData(state, action);
      case EDashboardActionTypes.UPDATE_PROFIT_LOSS_ANALYTICS_DATA:
        return updateProfitLossAnalyticData(state, action);
      case EDashboardActionTypes.GET_YEARMONTH_REGION_DATA:
        return fetchYearMonthRegionDropdownData(state, action);
      case EDashboardActionTypes.UPDATE_YEARMONTH_REGION_DATA:
        return updateYearMonthRegionDropdownData(state, action);
      case EDashboardActionTypes.GET_INCIDENTS_CARDS_DATA:
        return fetchIncidentCardsData(state, action);
      case EDashboardActionTypes.UPDATE_INCIDENTS_CARDS_DATA:
        return updateIncidentCardsData(state, action);
      case EDashboardActionTypes.GET_EDASHBOARD_NOTIFICATION_TEXT:
        return fetchEDNotificationTextMsg(state, action);
      case EDashboardActionTypes.UPDATE_EDASHBOARD_NOTIFICATION_TEXT:
        return updateEDNotificationTextMsg(state, action);
      default:
        return state;
    }
  };
  
  export default eDashboardReducer;