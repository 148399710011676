import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './EmployeeAssign.css';
import DATA from './assign.json';
import Edit_Icon from "../../../../assets/images/Edit_Icon.png"
import Saved_Indication_Icon from "../../../../assets/images/Saved_Indication_Icon.png"
import Inactive_Indication_Icon from "../../../../assets/images/Inactive_Indication_Icon.png"
import Done_Indication_Icon from "../../../../assets/images/Done_Indication_Icon.png"
import Active_Indication_Icon from "../../../../assets/images/Active_Indication_Icon.png"
import Eye_ViewDetails_Icon from '../../../../assets/onboard-images/Eye_ViewDetails_Icon.png';
import Search_Icon from "../../../../assets/images/Search_Icon.png"

import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMappedEmployees, saveEditMappedEmployees, saveSupervisorMapping } from '../../../../actions/Administration/staffAdmin.action';
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';


const Header = [
    { caption: 'Employee ID', id: '1' },
    { caption: 'Employee Name', id: '2' },
    { caption: 'Short Name', id: '3' },
    { caption: 'Email', id: '4' },
    { caption: 'Hire Date', id: '5' },
    { caption: 'Supervisor ID', id: '6' },
    { caption: 'Functional Supervisor', id: '7' },
    { caption: 'Role Category', id: '8' },
    { caption: 'User Role', id: '9' },
    { caption: 'Valid From', id: '10' },
    { caption: 'Valid To', id: '11' },
    { caption: 'Status', id: '12' },
]
function MappedEmployeesHandle(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const mappedEmployees = useSelector((state) => state.staffAdminReducer.mappedEmployees);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getMappedEmployees());
        dispatch(getRoleBasedView())
    }, [dispatch])


    
    const ValueHandler = (value) => {
        switch (value) {
            case true:
                return <span > <img src={Active_Indication_Icon} alt='' />&nbsp;&nbsp;Active</span>;
            case false:
                return <span > <img src={Inactive_Indication_Icon} alt='' />&nbsp;&nbsp;Inactive</span>;
            case "done":
                return <span > <img src={Done_Indication_Icon} alt='' /> &nbsp;&nbsp;Done</span>;
            case "undone":
                return <span > <img src={Saved_Indication_Icon} alt='' /> &nbsp;&nbsp;Saved</span>;
            default:
                return value;
        }
    }

    const onClickEditEmployee = (data) => {
        let obj2 = {
            EmployeeName: data?.['Employee Name'],
            EmployeeID: data?.['Employee ID'],
            Email: data?.['Email'],
            SupervisorID: data['SupervisorEmployee ID'],
            Supervisor: data['Seperior Name'],
            RoleCategory: data['RoleCategoryName'],
            UserRole: data.UserRole,
            ValidFrom: data?.ValidFrom,
            ValidTo: data?.ValidTo,
            view: 'edit'
        }
        dispatch(saveSupervisorMapping(obj2));
        history.push('/admin/staff/assign')
    }

    const [filter, setFilter] = useState('');
    const [searchColumns, setSearchColumns] = useState([
        'Employee ID', 'Employee Name', 'Email'
    ]);
    function searchRows(rows) {
        return rows?.filter((row) =>
            searchColumns?.some(
                (column) =>
                    row?.[column]
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.toLowerCase()) > -1,
            ),
        );
    }
    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    };
    return (<>
        <section className='employee_mapped'>
            <header style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='header_total' > Total: {searchRows(mappedEmployees)?.length}</div>
                <div className='search-bar'>
                    <input className='search-input' value={filter || ''} onChange={e => setFilter(e.target.value)} />
                    <button className='search-icon'><img src={Search_Icon} alt='' /> </button>
                </div>        </header>
            {searchRows(mappedEmployees)?.length === 0 ?
                <section className='zero_avail_contain'>
                    <div className='zero_avail_head'>  {DATA.header.map(head => (<>
                        <span key={head.id}>{head.caption}</span>
                    </>))} </div>
                    <div className='zero_avail_div'>
                        No records found.<br /> To add a new employee mapping, select the “Available Employees” tab button and click “Add Mapping” link.
                    </div>
                </section>
                :
                <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                    <thead>
                        <tr>
                            {Header.map(header => (
                                <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>))}
                            <th>{'  '}</th>
                        </tr>
                    </thead>
                    <tbody className='mapped_tbody'>
                        {searchRows(mappedEmployees)?.map((info, i) =>
                        (<tr key={i}>
                            <td style={{ border: 'none' }}>{info?.['Employee ID']}</td>
                            <td style={{ border: 'none' }}>{info?.['Employee Name']}</td>
                            <td style={{ border: 'none' }}>{info?.['Short Name']}</td>
                            <td style={{ border: 'none' }}>{info?.['Email']}</td>
                            <td style={{ border: 'none' }}>{info?.['Hire Date']}</td>
                            <td style={{ border: 'none' }}>{info?.['SupervisorEmployee ID']}</td>
                            <td style={{ border: 'none' }}>{info?.['Seperior Name']}</td>
                            <td style={{ border: 'none' }}>{info?.['RoleCategoryName']}</td>
                            <td style={{ border: 'none' }}>{info?.['UserRole']}</td>
                            <td style={{ border: 'none' }}>{info?.['ValidFrom']}</td>
                            <td style={{ border: 'none' }}>{info?.['ValidTo']}</td>
                            <td style={{ border: 'none' }}>{info?.['ValidTo'] > formatDate() ? <span><img src={Active_Indication_Icon} alt='' />&nbsp;&nbsp;Active</span> : <span><img src={Inactive_Indication_Icon} alt='' />&nbsp;&nbsp;InActive</span>}</td>
                            {/* <td style={{ border: 'none' }}> <span > <img src={Active_Indication_Icon} alt='' />&nbsp;&nbsp;Active</span> </td>
                            <td style={{ border: 'none' }}>  <span > <img src={Saved_Indication_Icon} alt='' /> &nbsp;&nbsp;Saved</span></td> */}
                           {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1 ? 
                           <td style={{ border: 'none' }} onClick={() => onClickEditEmployee(info)}> <img src={Edit_Icon} alt='' style={{cursor:'pointer'}}/></td>
                           : <td style={{ border: 'none' }} onClick={() => onClickEditEmployee(info)}> <img src={Eye_ViewDetails_Icon} alt='' style={{cursor:'pointer'}}/></td>}
                        </tr>))}
                    </tbody>
                </table>}
            <br />  <br /><br />
        </section>
    </>)
}


export default MappedEmployeesHandle




