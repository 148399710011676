import React, { Fragment, useEffect, useState } from 'react';
import './SLATable.css'
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png'
import addAccountIcon from './../../../../assets/onboard-images/Add_SLA_KPI_Icon.png'
import editIcon from './../../../../assets/onboard-images/Edit_Icon.png'
import ContractualSLAIcon from "./../../../../assets/images/Contractual_SLAs.png";
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png'
import { AddAccountModal } from './AddAccountModal';
import { TemplateAddModal } from './TemplateAddModal';
import { ModalAccountPopup } from '../Modals/ModalQueuePopup';
import { DeleteModalPopup, DeleteFragment, AdminValidDateChange } from '../Modals/DeleteModalPopup';
import TooltipHandler from '../TooltipHandler';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSLATemplates, setValidforAddingTabRows } from '../../../../actions/Onboard.action';
import { useLocation } from 'react-router-dom';
import ActivateDisabledIcon from './../../../../assets/images/Activate_Disabled_Icon.png';
import ActivateIcon from './../../../../assets/images/Activate_Icon.png';
import { getRoleBasedView, updateSLARequestStart } from '../../../../actions/Administration/admin.action';

const headers = [
    {
        caption: "Template",
        id: "1"
    },
    {
        caption: "SLA/KPI",
        id: "2"
    },
    {
        caption: "Contractual",
        id: "3"
    },
    {
        caption: "Threshold Seconds",
        id: "4"
    },
    {
        caption: "Minimum Target",
        id: "5"
    },
    {
        caption: "Expected Target ",
        id: "6"
    },
    {
        caption: "Short Call Conclusion Seconds ",
        id: "7"
    },
    {
        caption: "Valid From",
        id: "8"
    },
    {
        caption: "Valid To",
        id: "9"
    },
    {
        caption: "SLA/KPI Alias",
        id: "10"
    }
];


function SLAComponent(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const newSlATemplate = useSelector(state => state.onboardReducer.SLATemplate);
    const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
console.log('accountSiteInfo',accountSiteInfo)

    useEffect(() => {
        dispatch(getSLATemplates(accountSiteInfo.AccountName, props.TabName));
        dispatch(getRoleBasedView())
    }, [dispatch])



    const renderVoiceSaveSLAData = (data) => {
        let temp_Arr = [...data];
        let arr2 = temp_Arr?.map((temp, i) => {
            return {
                sla_template: temp?.['slA/KPI Template'],
                slaKpi: temp?.['slA/KPI'],
                contractual: temp?.contractual,
                threshold_seconds: temp?.thresholdSeconds,
                minimum_target: temp?.minimumTarget,
                expected_target: temp?.expectedTarget,
                short_call: temp?.shortExclusion,
                valid_from: formatDate(temp?.validFrom),
                valid_to: formatDate(temp?.validTo),
                alias: temp?.alias,
                sla_template_id: temp?.slaTemplateID,
                slaID: temp?.accountSLAId,
                status: temp?.status,
                request: temp?.request,
                tooltip: temp?.toolTip,
                formatType: temp?.formatType
            }
        })
        return arr2;

    }
    
    const onClickSelect = (name) => {
        const newFormData = { ...editAccountInfo };
        newFormData[name] = '';
        setEditAccountInfo(newFormData)

    }
    let data = (props.voiceSLAData?.length > 0 ? renderVoiceSaveSLAData(props.voiceSLAData) : []);
    const [accountData, setAccountData] = useState([])
    useEffect(() => {
        setAccountData(data)
    }, [props.voiceSLAData])

    useEffect(() => {
    }, [])
    const [filter, setFilter] = useState('');
    const [showAddAccountModal, setShowAddAccountModal] = useState(false);
    const [showEditAccountModal, setShowEditAccountModal] = useState(false);
    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
    const [searchColumns, setSearchColumns] = useState([
        'sla_template', 'alias'
    ]);
    const [addFormAccount, setAddFormAccount] = useState({
        sla_template: '',
        threshold_seconds: '',
        minimum_target: '',
        expected_target: '',
        short_call: '',
        valid_from: '',
        valid_to: '',
        alias: '',
        sla_template_id: '',
    })
    const [mode, setMode] = useState(null);
    useEffect(() => {
        if (props.accountDetails) setMode(props.accountDetails.mode)
    }, [props.accountDetails])
    const [editAccountID, setEditAccountID] = useState(null);
    const [editAccountInfo, setEditAccountInfo] = useState({
        sla_template: '',
        threshold_seconds: '',
        minimum_target: '',
        expected_target: '',
        short_call: '',
        valid_from: '',
        valid_to: '',
        alias: '',
        sla_template_id: '',
    });
    const [validation, setValidation] = useState('');
    const [deleteRowID, setDeleteRowID] = useState(null);
    const [dispatchAccountObj, setDispatchAccountObj] = useState([]);
    const configureAccountData = (accountData) => {
        console.log('accountData',accountData)
        const temp_arr = [...accountData];
        const temp_Arr = temp_arr.map(data => {
            let temp1 = {
                SLATemplate: data.sla_template,
                SLAKPI: data?.slaKpi,
                Contractual: data?.contractual,
                ThresholdSeconds: data.threshold_seconds || '',
                MinimumTarget: data.minimum_target || '',
                ExpectedTarget: data.expected_target || '',
                ShortExclusion: data.short_call || '',
                ValidFrom: data.valid_from,
                ValidTo: data.valid_to,
                Alias: data.alias || '',
                SLATemplateId: data.sla_template_id,
            }
            let temp2 = {
                SLATemplate: data.sla_template,
                SLAKPI: data?.slaKpi,
                Contractual: data?.contractual,
                ThresholdSeconds: data.threshold_seconds || '',
                MinimumTarget: data.minimum_target || '',
                ExpectedTarget: data.expected_target || '',
                ShortExclusion: data.short_call || '',
                ValidFrom: data.valid_from,
                ValidTo: data.valid_to,
                Alias: data.alias || '',
                SLATemplateId: data.sla_template_id,
                AccountSLAId: data.slaID || ''
            }
            return location.pathname === '/onboardAccount' ? temp1 : temp2
        })
        let tempArray3 = [{
            Servicedesk: accountSiteInfo.SiteName,
            Account: accountSiteInfo.AccountName,
            VoiceNonVoice: props.TabName,
            NonVoiceItem: props.NonVoiceItem,
            Details: temp_Arr
        }]
        setDispatchAccountObj(tempArray3);
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    };

    const [targetType, setTargetType] = useState('Number');
    const TargetTypeValidation = (value) => {
        if (value?.includes('%')) return 'Percentage';
       // if (moment(value, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A', 'hh:mm:ss'], true).isValid()) return 'Time';
       if(value?.includes('hh:mm:ss')) return 'Time'
        if ((!isNaN(value)) && 'Number') return 'Number'
        else return 'Number'
    }
    const TargetTypeHandle = (type) => {
        let target = TargetTypeValidation(type)
        setTargetType(target);
    }

    const addValueToAccount = (name, value, id, TargetType,Comparison) => {
        const newFormData = { ...addFormAccount };
        if (value !== 'Select') {
            newFormData[name] = [value, id];
            setAddTargetType(TargetType)
            setComparison(Comparison)
            setAddFormAccount(newFormData);
        }
    }
    const addDateToAccount = (name, value, id) => {
        const newFormData = { ...addFormAccount };
        newFormData[name] = value;
        setAddFormAccount(newFormData);
    }

    const addValueToSlaKpi = (name, value) => {
        if (value !== 'Select') {
            const newFormData = { ...addFormAccount };
            newFormData[name] = value;
            setAddFormAccount(newFormData)
        }
    }

    const handleFormAddAccount = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...addFormAccount };
            newFormData[fieldName] = fieldValue;
            setAddFormAccount(newFormData);
        }
    }
    const TargetFormatValidation = (value, type) => {
        switch (type) {
            case 'Percentage': {
                return value?.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && value?.includes('%');
            }
            case 'Time': {
                return moment(value, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A', 'hh:mm:ss'], true).isValid();
            }
            case 'Number': {
                return !isNaN(value)
            }
            default: { }
        }
    }
  const [addTargetType,setAddTargetType] = useState()
  const [comparison,setComparison] = useState()
    // const FormatTypeHandle = (type) => {
    //     let formatType = type
    //     setAddTargetType(formatType)
    // }
    const handleAddAccountSubmit = event => {
        event.preventDefault();
        const newAccount = {
            id: accountData.length + 1,
            sla_template: addFormAccount?.sla_template?.[0],
            slaKpi: addFormAccount?.SLAKPI,
            contractual: addFormAccount?.Contractual,
            threshold_seconds: addFormAccount?.threshold_seconds,
            minimum_target: (addFormAccount?.minimum_target),
            expected_target: (addFormAccount?.expected_target),
            short_call: addFormAccount?.short_call,
            valid_from: (addFormAccount?.valid_from),
            valid_to: (addFormAccount?.valid_to),
            alias: addFormAccount?.alias,
            sla_template_id: addFormAccount?.sla_template?.[1],
            slaID: addFormAccount?.slaID,
            status: 'Active',
            request: 'Completed',
            tooltip: null,
            formatType: addTargetType,
        }

        const newAccounts = [newAccount, ...accountData]
        
        console.log('addFormAccount::::::::',newAccounts)
        let temp_arr = {
            sla_template: addFormAccount.sla_template,
            slaKpi: addFormAccount?.SLAKPI,
            contractual: addFormAccount?.Contractual,
            valid_from: (addFormAccount.valid_from),
            valid_to: (addFormAccount.valid_to)
        };
        const isEmpty = Object.values(temp_arr).some(temp => (temp === null || temp === '' || temp === undefined));
        const isValidDate = (addFormAccount.valid_to)>(addFormAccount.valid_from);
        console.log(isValidDate)

        
        const numberValidate = (a,b) => {
            const arr = [a,b]
            if (Math.max.apply(Math,arr) === parseInt(b)) return true;
            return false;
        };

        const numberValidateMinimumGreater = (a,b) => {
            const arr = [a,b]
            if (Math.max.apply(Math,arr) === parseInt(a)) return true;
            return false;
        };

        const percentageValidate = (a,b) =>{
            const A = parseFloat(a);
            const B = parseFloat(b);
            const arr = [A,B]
            if (Math.max.apply(Math,arr) === parseFloat(b)) return true;
            return false;
        }

        const percentageValidateMinimumGreater = (a,b) =>{
            const A = parseFloat(a);
            const B = parseFloat(b);
            const arr = [A,B]
            if (Math.max.apply(Math,arr) === parseFloat(a)) return true;
            return false;
        }
        
       const validate = addFormAccount.minimum_target === undefined || addFormAccount.expected_target === undefined || addFormAccount.minimum_target === '' || addFormAccount.expected_target === ''
       const minexpvalidate = !validate ? ( (comparison === '0') ? true : ((comparison === "E >= M") ? 
       (addTargetType === '0' ? numberValidate(addFormAccount.minimum_target,addFormAccount.expected_target) : (addTargetType.includes('%') ? percentageValidate(addFormAccount.minimum_target,addFormAccount.expected_target)  : addFormAccount.minimum_target <= addFormAccount.expected_target)) :
       (addTargetType === '0' ? numberValidateMinimumGreater(addFormAccount.minimum_target,addFormAccount.expected_target) : (addTargetType.includes('%') ? percentageValidateMinimumGreater(addFormAccount.minimum_target,addFormAccount.expected_target)  : addFormAccount.minimum_target >= addFormAccount.expected_target)))
       ) : true
         console.log('minexpvalidate',minexpvalidate)

         
        const minimum_target = (addFormAccount.minimum_target === undefined || TargetFormatValidation(addFormAccount.minimum_target, targetType) || addFormAccount.minimum_target === "")
        const expected_target =(addFormAccount.expected_target === undefined || TargetFormatValidation(addFormAccount.expected_target, targetType) || addFormAccount.expected_target === "")
        console.log('minimum_target:::::::::',minimum_target,expected_target)
        if (isEmpty) {
            setShowAddAccountModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }

        else if (!isValidDate) {
            setShowAddAccountModal(true);
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date');
        }
        
        else if(!minimum_target || !expected_target){
            setShowAddAccountModal(true);
         setValidation('Please ensure "Minimum & Expected Targets" is relevant to the format selected');
        }
        else if(!minexpvalidate){
            setShowAddAccountModal(true);
         setValidation(`Please ensure "Expected Target is ${comparison === "E >= M" ? 'Greater' : 'Less'} than or equal to Minimum Target"`);
        }
        else {
            setAccountData(newAccounts);
            configureAccountData(newAccounts)
            setShowAddAccountModal(false);
            setValidation('')
        }

    }
    const handleAddAccountCancel = event => {
        event.preventDefault();
        setShowAddAccountModal(false);
        setValidation('')
    }



    const onClickEditRow = (index, data) => {
        setEditAccountID(index)
        setShowEditAccountModal(true)
        const formValues = {
            sla_template: data.sla_template,
            SLAKPI: data?.slaKpi,
            Contractual: data?.contractual,
            threshold_seconds: data.threshold_seconds,
            minimum_target: data.minimum_target,
            expected_target: data.expected_target,
            short_call: data.short_call,
            valid_from: data.valid_from,
            valid_to: data.valid_to,
            alias: data.alias,
            sla_template_id: data.sla_template_id,
            slaID: data.slaID,
            status: 'Active',
            request: 'Completed',
            tooltip: null,
            formatType:data.formatType
        };
        setEditAccountInfo(formValues);
        if(formValues.formatType === 'hh:mm:ss'){
            setTargetType('Time')
        }
        else if(formValues.formatType.includes('%')){
            setTargetType('Percentage')
        }
        else if(formValues.formatType.includes('0')){
            setTargetType('Number')
        }
    }

    const editValueToAccount = (name, value, id, TargetType) => {
        const newFormData = { ...editAccountInfo };
        console.log('editAccountInfo',editAccountInfo)
        if (value !== 'Select') {
            newFormData[name] = value;
            newFormData['sla_template_id'] = id
            newFormData['formatType'] = TargetType
            setEditAccountInfo(newFormData)
        }
    }
    const editDateToAccount = (name, value) => {
        const newFormData = { ...editAccountInfo };
        newFormData[name] = value;
        setEditAccountInfo(newFormData);
    }

    const handleEditAccountUpdate = event => {
        event.preventDefault();
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...editAccountInfo };
        newFormData[fieldName] = fieldValue;
        setEditAccountInfo(newFormData);
    }

    const handleEditSlaKpi = (name, value) => {
        const newFormData = { ...editAccountInfo };
        if (value !== 'Select') {
            newFormData[name] = value;
            console.log('newFormData',newFormData)
            setEditAccountInfo(newFormData);
        }
    }
console.log('editAccountInfo:::::::::',editAccountInfo)
    const handleEditAccountSubmit = event => {
        event.preventDefault();
        const editedAccounts = {
            id: editAccountID,
            sla_template: editAccountInfo.sla_template,
            slaKpi: editAccountInfo?.SLAKPI,
            contractual: editAccountInfo?.Contractual,
            threshold_seconds: editAccountInfo.threshold_seconds,
            minimum_target: (editAccountInfo.minimum_target),
            expected_target: (editAccountInfo.expected_target),
            short_call: editAccountInfo.short_call,
            valid_from: formatDate(editAccountInfo.valid_from),
            valid_to: formatDate(editAccountInfo.valid_to),
            alias: editAccountInfo.alias,
            sla_template_id: editAccountInfo.sla_template_id,
            slaID: editAccountInfo?.slaID,
            status: 'Active',
            formatType: editAccountInfo.formatType
        };
      console.log('editedAccounts',editedAccounts)
        const newAccounts = [...accountData];
         console.log('newAccounts',newAccounts,accountData)
        let temp_arr = {
            sla_template: editedAccounts.sla_template,
            valid_from: editAccountInfo.valid_from,
            valid_to: editAccountInfo.valid_to
        };
        const isEmpty = Object.values(temp_arr).some(temp => (temp === null || temp === '' || temp === undefined));
        const isValidDate = moment(editAccountInfo.valid_to).isAfter(editAccountInfo.valid_from)
        const emptyValidDate = editAccountInfo.valid_to > editAccountInfo.valid_from
        console.log('isValidDate::::::::::',isValidDate,emptyValidDate)
        const numberValidate = (a,b) => {
            const arr = [a,b]
            if (Math.max.apply(Math,arr) === parseInt(b)) return true;
            return false;
        };

        const percentageValidate = (a,b) =>{
            const A = parseFloat(a);
            const B = parseFloat(b);
            const arr = [A,B]
            if (Math.max.apply(Math,arr) === parseFloat(b)) return true;
            return false;
        }

        const numberValidateMinimumGreater = (a,b) => {
            const arr = [a,b]
            if (Math.max.apply(Math,arr) === parseInt(a)) return true;
            return false;
        };
        const percentageValidateMinimumGreater = (a,b) =>{
            const A = parseFloat(a);
            const B = parseFloat(b);
            const arr = [A,B]
            if (Math.max.apply(Math,arr) === parseFloat(a)) return true;
            return false;
        }

       const validate = editAccountInfo.minimum_target === undefined || editAccountInfo.expected_target === undefined || editAccountInfo.minimum_target === '' || editAccountInfo.expected_target === ''
     //  const minexpvalidate = !validate ? (editAccountInfo.formatType === '0' ? numberValidate(editAccountInfo.minimum_target,editAccountInfo.expected_target) : (editAccountInfo.formatType.includes('%') ? percentageValidate(editAccountInfo.minimum_target,editAccountInfo.expected_target) : editAccountInfo.minimum_target <= editAccountInfo.expected_target)) : true
        
     const minexpvalidate = !validate ? ( (comparison === '0') ? true : ((comparison === "E >= M") ? 
     (editAccountInfo.formatType === '0' ? numberValidate(editAccountInfo.minimum_target,editAccountInfo.expected_target) : (editAccountInfo.formatType.includes('%') ? percentageValidate(editAccountInfo.minimum_target,editAccountInfo.expected_target) : editAccountInfo.minimum_target <= editAccountInfo.expected_target)):
     (editAccountInfo.formatType === '0' ? numberValidateMinimumGreater(editAccountInfo.minimum_target,editAccountInfo.expected_target) : (editAccountInfo.formatType.includes('%') ? percentageValidateMinimumGreater(editAccountInfo.minimum_target,editAccountInfo.expected_target) : editAccountInfo.minimum_target >= editAccountInfo.expected_target)))
     ) : true





        const minimum_target = (editAccountInfo.minimum_target === undefined || TargetFormatValidation(editAccountInfo.minimum_target, targetType) || editAccountInfo.minimum_target === "")
        const expected_target =(editAccountInfo.expected_target === undefined || TargetFormatValidation(editAccountInfo.expected_target, targetType) || editAccountInfo.expected_target === "")
        if (isEmpty) {
            setShowEditAccountModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }
       else if (!isValidDate) {
            setShowEditAccountModal(true);
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ');
        }
   
           else if(!minimum_target || !expected_target){
            setShowEditAccountModal(true);;
           setValidation('Please ensure "Minimum & Expected Targets" is relevant to the format selected');
           }
           else if(!minexpvalidate){
            setShowEditAccountModal(true);
         setValidation('Please ensure "Expected Target is greater than or equal to Minimum Target"');
        }
        else {
            const index = accountData.findIndex((_, index) => index === editAccountID);
            newAccounts[index] = editedAccounts;
            setAccountData(newAccounts);
            configureAccountData(newAccounts)
            setEditAccountID(null);
            setShowEditAccountModal(false);
            setValidation('')
        }
    }
    const handleEditAccountCancel = event => {
        event.preventDefault();
        setEditAccountID(null);
        setShowEditAccountModal(false);
        setValidation('')
    }

    const handleDeleteAccount = () => {
        const newAccount = [...accountData];
        const index = accountData.findIndex((_, index) => index === deleteRowID);
        newAccount.splice(index, 1);
        setAccountData(newAccount);
        setShowDeleteAccountModal(false);
        configureAccountData(newAccount)

    }
    const handleDeleteAccountCancel = event => {
        event.preventDefault();
        setShowDeleteAccountModal(false)
    }


    function searchRows(rows) {
        return rows?.filter((row) => searchColumns?.some((column) => row?.[column]?.toString()?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1,))
    }
    const [showTemplateModal, setShowTemplateModal] = useState(false)
    const TemplateModalHandle = () => {
        setShowAddAccountModal(false);
        setShowTemplateModal(true);
    }


    const handleAddTemplateCancel = () => {
        setShowTemplateModal(false);
        setShowAddAccountModal(true);
        dispatch(getSLATemplates(accountSiteInfo.AccountName, props.TabName));
    }

    useEffect(() => {
        console.log('dispatchAccountObj',dispatchAccountObj)
        props.dispatchAccountHandler(dispatchAccountObj)
    }, [dispatchAccountObj])

    const [showReuqestModal, setShowReuqestModal] = useState(false);
    const [AccountSLAId, setAccountSLAId] = useState(null);
    const ActivationHandler = (id, data) => {
        if (data.request !== 'Sent') {
            setEditAccountID(id);
            setAccountSLAId(data?.slaID);
            setShowReuqestModal(true);
        }
    }

    const onSubmitRequest = (date) => {
        const temp_Arr = [{
            Account: accountSiteInfo.AccountName,
            Servicedesk: accountSiteInfo.SiteName,
            ValidUntil: date,
            AccountSLAKPIId: AccountSLAId
        }]
        dispatch(updateSLARequestStart(temp_Arr))
        console.log(temp_Arr)
        setShowReuqestModal(false);
        let temp_arr = [...accountData];
        temp_arr.map(temp => {
            if (temp.slaID === AccountSLAId) {
                temp.request = 'Sent';
                temp.tooltip = 'Activation Request already sent to Administrator'
                console.log(temp.slaID, AccountSLAId)
            }
        });
        setAccountData(temp_arr)
    }

    const ValidUser = () => {
         if(props.GeneralInfoToggle){
            setAddFormAccount([])
            setShowAddAccountModal(true)
            dispatch(setValidforAddingTabRows(false))
         }
         else{
            window.scrollTo(0, 0);
            dispatch(setValidforAddingTabRows(true))
         }
     }

    return (<>
        <Fragment>
            <section className='search-container'>
                <div className='search-bar'>
                    <input className='search-input' value={filter || ''} onChange={e => setFilter(e.target.value)} />
                    <button className='search-icon'><img src={searchIcon} alt='' /> </button>
                </div>

              { (RoleBasedView?.[0]?.SLA?.[0]?.ReadWrite === 0 && location.pathname === '/admin/account') ? '' : <> {(mode === 'create' || mode === 'edit') && <div className='add-queue' onClick={ValidUser}
                    style={{ cursor: 'pointer' }}>
                    <img src={addAccountIcon} alt='' />
                    <span className='add-queue-span'>Add SLA/KPI Account</span>
                </div>}</> }
            </section>
        </Fragment>
        {searchRows(accountData)?.length === 0 ?

            <section>
                <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                    <thead>
                        <tr>
                            {headers.map(header => (
                                <th style={{ border: 'none' }} key={header.id}><span className='queue_header'>{header.caption}</span></th>
                            ))
                            }
                            <th>{'  '}</th>
                        </tr>
                    </thead>
                </table>
                <section className='noData-table'>
                    <div className='noData-para' style={{ marginTop: 0 }}>
                        <div style={{ marginTop: 30 }}>
                            No records found. This data grid enables adding/editing the contractually defined service level metrics.<br /> To add new SLA/KPI account, click on "Add SLA/KPI Account"  link.
                        </div>
                    </div>
                </section>
            </section> :
            <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th style={{ border: 'none' }} key={header.id}><span className='queue_header'>{header.caption}</span></th>
                        ))
                        }
                        <th>{'  '}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        searchRows(accountData)?.map((data, index) => (
                            <tr key={data.id}>
                                <td className='queue_tablebody'>{data.sla_template}</td>
                                <td className='queue_tablebody'>{data?.slaKpi}</td>
                                <td className='queue_tablebody'>{(data.contractual === 'Yes' ? <span> <img src={ContractualSLAIcon} alt='' /></span> : '')}</td>
                                <td className='queue_tablebody'>{data.threshold_seconds}</td>
                                <td className='queue_tablebody'>{data.minimum_target}</td>
                                <td className='queue_tablebody'>{data.expected_target}</td>
                                <td className='queue_tablebody'>{data.short_call}</td>
                                <td className='queue_tablebody'>{data.valid_from}</td>
                                <td className='queue_tablebody'>{data.valid_to}</td>
                                <td className='queue_tablebody'>{data.alias}</td>
                                {location.pathname === '/onboardAccount' ? <>
                                    {(mode === 'create' || mode === 'edit') && <td className='table-button'>
                                        <span onClick={() => onClickEditRow(index, data)}>
                                            <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                                        </span>
                                        <span onClick={() => { setDeleteRowID(index); setShowDeleteAccountModal(true) }}>
                                            <TooltipHandler src={deleteIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Delete' /></span>
                                    </td>}</>
                                    :
                                    <>{RoleBasedView?.[0]?.SLA?.[0]?.ReadWrite === 1 ? <> {(mode === 'create' || mode === 'edit') && <td className='table-button'>  {data.status === 'Active' ?
                                        <span onClick={() => onClickEditRow(index, data)}>
                                            <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' /></span>
                                        : <>
                                            <span onClick={() => ActivationHandler(index, data)}>
                                                <TooltipHandler src={data?.request === 'Sent' ? ActivateDisabledIcon : ActivateIcon} width='14px' height='14px' position='top center'
                                                    arrowAlign='center' textBoxWidth='300px'
                                                    text={data?.request === 'Sent' ? 'Request Already sent to Administrator' : ' Send Activation Request to Administrator'} />
                                            </span>
                                        </>
                                    }</td>}</> : ''}</>
                                    
                                    }
                            </tr>
                        ))
                    }
                </tbody>
            </table>}
        {showAddAccountModal && <ModalAccountPopup isOpen={showAddAccountModal} setShowModal={setShowAddAccountModal}>
            <AddAccountModal modalName='Add SLA/KPI Account' buttonOne='ADD' buttonTwo='CANCEL'
                EditMode={false} data={newSlATemplate}
                editAccountInfo={editAccountInfo} validation={validation}
                TemplateModalHandle={TemplateModalHandle}
                handleFormAddAccount={handleFormAddAccount}
                handleAddAccountSubmit={handleAddAccountSubmit}
                handleAddAccountCancel={handleAddAccountCancel}
                addValueToAccount={addValueToAccount}
                addValueToSlaKpi={addValueToSlaKpi}
                addDateToAccount={addDateToAccount}
                TargetTypeHandle={TargetTypeHandle}
                targetType={targetType}
                onClickSelect={() => { }}
                accountSiteInfo={accountSiteInfo}
            />
        </ModalAccountPopup>
        }
        {showEditAccountModal && <ModalAccountPopup isOpen={showEditAccountModal} setShowModal={setShowEditAccountModal}>
            <AddAccountModal modalName='Edit SLA/KPI Account' buttonOne='UPDATE' buttonTwo='CANCEL'
                EditMode={true} data={newSlATemplate} validation={validation}
                editAccountInfo={editAccountInfo}
                handleFormAddAccount={handleEditAccountUpdate}
                handleEditAccountSubmit={handleEditAccountSubmit}
                handleAddAccountCancel={handleEditAccountCancel}
                addValueToAccount={editValueToAccount}
                addValueToSlaKpi={handleEditSlaKpi}
                addDateToAccount={editDateToAccount}
                TargetTypeHandle={TargetTypeHandle}
                targetType={targetType}
                onClickSelect={onClickSelect}
                accountSiteInfo={accountSiteInfo}
            />
        </ModalAccountPopup>
        }
        {
            showTemplateModal && <ModalAccountPopup isOpen={showTemplateModal} setShowModal={setShowTemplateModal}>
                <TemplateAddModal
                    handleAddTemplateSubmit={handleAddTemplateCancel}
                    handleAddTemplateCancel={handleAddTemplateCancel}
                    TabName={props.TabName}
                    AccountName={accountSiteInfo.AccountName}
                />
            </ModalAccountPopup>
        }
        {showDeleteAccountModal && <DeleteModalPopup isOpen={showDeleteAccountModal} setShowModal={setShowDeleteAccountModal}>
            <DeleteFragment modalName='Delete SLA/KPI Account' buttonOne='YES' buttonTwo='NO' delete={true}
                handleDeleteQueue={handleDeleteAccount}
                handleDeleteQueueCancel={handleDeleteAccountCancel}
                queueName={accountData[accountData.findIndex((_, index) => index === deleteRowID)].sla_template}
            />
        </DeleteModalPopup>
        }

        {
            showReuqestModal && <DeleteModalPopup isOpen={showReuqestModal} setShowModal={setShowReuqestModal}>
                <AdminValidDateChange
                    requestHeading="Request for SLA/KPI Re-Activation" title1='SLA/KPI Template' title2='SLA/KPI Alias'
                    solution1={accountData[accountData.findIndex((_, index) => index === editAccountID)].sla_template}
                    solution2={accountData[accountData.findIndex((_, index) => index === editAccountID)]?.alias}
                    onSubmit={onSubmitRequest} notValidDate = {() => { setShowReuqestModal(true) }}
                    onCancel={() => { setShowReuqestModal(false) }}
                />
            </DeleteModalPopup>
        }



    </>);
}

export default SLAComponent