const adminActionTypes = {
    GET_ADMIN_ACCOUNTS: "GET_ADMIN_ACCOUNTS",
    ADMIN_ACCOUNTS: "ADMIN_ACCOUNTS",

    UPDATE_VALIDTO_DATE_START: "UPDATE_VALIDTO_DATE_START",
    UPDATE_VALIDTO_DATE_SUCCESS: "UPDATE_VALIDTO_DATE_SUCCESS",
    UPDATE_ACCOUNT_DEATILS: "UPDATE_ACCOUNT_DEATILS",

    GET_ADMIN_QUEUE_TABLE_DATA: "GET_ADMIN_QUEUE_TABLE_DATA",
    ADMIN_QUEUE_TABLE_DATA: 'ADMIN_QUEUE_TABLE_DATA',

    UPDATE_ADMIN_QUEUE_TABLE_DATA_START: "UPDATE_ADMIN_QUEUE_TABLE_DATA_START",
    UPDATE_ADMIN_QUEUE_TABLE_DATA_SUCCESS: "UPDATE_ADMIN_QUEUE_TABLE_DATA_SUCCESS",

    GET_ADMIN_SLA_TABLE_DATA: "GET_ADMIN_SLA_TABLE_DATA",
    ADMIN_SLA_TABLE_DATA: 'ADMIN_SLA_TABLE_DATA',

    UPDATE_ADMIN_SLA_TABLE_DATA_START: "UPDATE_ADMIN_SLA_TABLE_DATA_START",
    UPDATE_ADMIN_SLA_TABLE_DATA_SUCCESS: "UPDATE_ADMIN_SLA_TABLE_DATA_SUCCESS",

    GET_ADMIN_SUPPORT_TABLE_DATA: "GET_ADMIN_SUPPORT_TABLE_DATA",
    ADMIN_SUPPORT_TABLE_DATA: 'ADMIN_SUPPORT_TABLE_DATA',

    UPDATE_ADMIN_SUPPORT_TABLE_DATA_START: "UPDATE_ADMIN_SUPPORT_TABLE_DATA_START",
    UPDATE_ADMIN_SUPPORT_TABLE_DATA_SUCCESS: "UPDATE_ADMIN_SUPPORT_TABLE_DATA_SUCCESS",

    GET_ADMIN_VOICE_SLA_TABLE_DATA: "GET_ADMIN_VOICE_SLA_TABLE_DATA",
    ADMIN_VOICE_SLA_TABLE_DATA: 'ADMIN_VOICE_SLA_TABLE_DATA',

    GET_ADMIN_VOICE_SUPPORT_TABLE_DATA: "GET_ADMIN_VOICE_SUPPORT_TABLE_DATA",
    ADMIN_VOICE_SUPPORT_TABLE_DATA: 'ADMIN_VOICE_SUPPORT_TABLE_DATA',

    UPDATE_ADMIN_UPDATE_START: "UPDATE_ADMIN_UPDATE_START",
    UPDATE_ADMIN_UPDATE_SUCCESS: "UPDATE_ADMIN_UPDATE_SUCCESS",



    QUEUE_ADMIN_REQUEST_START: "QUEUE_ADMIN_REQUEST_START",
    QUEUE_ADMIN_REQUEST_SUCCESS: "QUEUE_ADMIN_REQUEST_SUCCESS",

    SLA_ADMIN_REQUEST_START: "SLA_ADMIN_REQUEST_START",
    SLA_ADMIN_REQUEST_SUCCESS: "SLA_ADMIN_REQUEST_SUCCESS", 
    
    SUPPORT_ADMIN_REQUEST_START: "SUPPORT_ADMIN_REQUEST_START",
    SUPPORT_ADMIN_REQUEST_SUCCESS: "SUPPORT_ADMIN_REQUEST_SUCCESS",

    GET_PREVIEW_ADMIN: "GET_PREVIEW_ADMIN",
    PREVIEW_ADMIN: "PREVIEW_ADMIN",

    GET_ROLE_BASED_VIEW: "GET_ROLE_BASED_VIEW",
    ROLE_BASED_VIEW: "ROLE_BASED_VIEW",

    SAVE_STAFFADMIN_ACTIVE_TAB: 'SAVE_STAFFADMIN_ACTIVE_TAB',
}


export default adminActionTypes;