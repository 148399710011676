import React from 'react'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import filterIcon from '../../../assets/images/Filter_Icon.png';
import RefreshIcon from '../../../assets/images/Reset_Icon.png';
import { KeyboardDatePicker } from '@material-ui/pickers';
import RequestDropdown from '../../Onboard/OnboardComponents/Dropdown/RequestDropdown';
import Button from './../../ReportTables/ReportFilters/Button';
import searchIcon from './../../../assets/onboard-images/Search_Icon.png';
import { useState } from 'react';
import AdminTableHandle from './AdminTableHandle';
import { AdminRequestModel, AdminRequestPopup } from './../RequestModals';
import ADMIN_DATA from './RequestData.json'
import DATA from './../DATA.json'
import { useSelector } from 'react-redux';
import { getUserData } from '../../../actions/user.action';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAdminRequestData } from '../../../actions/request.action';
import { updateEditAdminRequestStart, getRequestDropdownData } from './../../../actions/request.action';
import Dropdown from './../../ReportTables/ReportFilters/Dropdown';
import ClearFilter from './../../ReportTables/ClearFilters/ClearFilter';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
const AdminRequestMain = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let userInfo = useSelector((state) => state.userReducer.userInfo);
    let AdminRequestData = useSelector((state) => state.requestReducer.AdminRequestData);
    let RequestDropdownData = useSelector((state) => state.requestReducer.RequestDropdownData);
    
    useEffect(() => {
        dispatch(getUserData());
       dispatch(getAdminRequestData("",'',''));
       // dispatch(getAdminRequestData());
        dispatch(getRequestDropdownData())
    }, [dispatch]);
    useEffect(() => {
        setRequestIncidentData(AdminRequestData)
    }, [AdminRequestData])

   // const [showAddAdminRequestModel, setShowAddAdminRequestModel] = useState(false);
    const [showEditAdminRequestModel, setShowEditAdminRequestModel] = useState(false);
   // const [AddRequestForm, setAddRequestForm] = useState({});
    const [editRequestID, setEditRequestID] = useState(null);
    const [EditRequestForm, setEditrequestForm] = useState();
    const [isRefreshClicked, setIsRefreshedClicked] = useState(false);
    const [filterSelected, setFilterSelected] = useState();
    const [statusSelected, setStatusSelected] = useState('');
    const [selectedType, setSelectedType] = useState('')
    const [assigneeSelected, setAssigneeSelected] = useState()
    const [RequestIncidentData, setRequestIncidentData] = useState([]);
    const [reuqestDate, setRequestDate] = useState(new Date(''));
    const [filter, setFilter] = useState('');
    const [validation, setValidation] = useState('');
     const [searchColumns, setSearchColumns] = useState(['Request / Incident']);
     const [updatedFileData, setUpdatedFileData] = useState()


     function searchRows(rows) {
        return rows.filter((row) =>
            searchColumns.some(
                (column) =>
                    row[column]
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.toLowerCase()) > -1,
            ),
        );
    }

    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    };
    // function formatTime() {
    //     return new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
    // }
    function formatTime() {
        var d = new Date(),
            hours = '' + d.getHours() ,
            min = '' + d.getMinutes(),
            sec = d.getSeconds();
    
        if (hours.length < 2)
        hours = '0' + hours;
        if (min.length < 2)
        min = '0' + min;
        if (sec.length < 2)
        sec = '0' + sec;
    
        return [hours, min, sec].join(':');
    }
   

    const HandleEditRequestClick = (info) => {
        setEditRequestID(info?.IncidentID)
        setShowEditAdminRequestModel(true)
        const formValues = {
            type: info?.Type,
            status: info?.Status,
            title: info?.['Request / Incident'],
            createdBy: info?.['Created By'],
            createdDate: info?.['Created Date'] ,
            comments: '',
            assignee:info?.Assignee,
            file: info?.UserAttachedFiles,
            UserDetails:info?.TaskDetails?.[0]?.UserDetails,
            ValidUntil:info?.TaskDetails?.[0]?.ValidUntil,
        };
        setEditrequestForm(formValues);
    }

    function RequestDateFormat(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    const handleEditRequestCancel = event =>{
        event.preventDefault();
        setValidation('')
        setShowEditAdminRequestModel(false);
    }

    const editValueToRequest = (name, value) => {
        const newFormData = { ...EditRequestForm };
        if (value !== 'Select') {
            newFormData[name] = value;
            setEditrequestForm(newFormData);
        }
    }

    const onClickSelect = (name) => {
        const newFormData = { ...EditRequestForm };
        newFormData[name] = '';
        setEditrequestForm(newFormData)

    }

    const handleUserEditRequest = event => {
        event.preventDefault();
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...EditRequestForm };
        newFormData[fieldName] = fieldValue;
        setEditrequestForm(newFormData);
    }

    
const[uploadedFileId,setUploadedFileId] = useState();
const[uploadedFileName,setUploadedFileName] = useState()
useEffect(()=>{
    if(updatedFileData){
        const formData = new FormData();

    formData.append('File', updatedFileData);

    fetch(
        `${
    process.env.REACT_APP_BASE_URL
  }/api/file`,
        {
            method: 'POST',
            body: formData,
        }
    )
        .then((response) => response.json())
        .then((result) => {
            console.log('Success:', result);
            setUploadedFileId(result.id);
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    }
},[updatedFileData]) 

const deleteFile = async (fileId) => {
    try {
      const response = await axios.delete(`/api/file/${fileId}`);
      if (response.status === 200) {
        setUploadedFileId(null);
        setUploadedFileName(null)
      } else {
        alert('file not deleted')
      }
    } catch (error) {
      // Handle error.
    }
  };
    
const DeleteUploadedFile = () =>{
    deleteFile(uploadedFileId);
    }
    
	
    

    console.log('uploadedFileId',uploadedFileId,uploadedFileName)



    const handleRequesEditSubmit = event => {
        event.preventDefault();
        const temp_details = {
            id: editRequestID,
            'Request / Incident': EditRequestForm.title,
            'Created By': EditRequestForm.createdBy,
            'Created Date': EditRequestForm.createdDate,
            Type: EditRequestForm.type,
            Assignee:EditRequestForm.assignee,
            Status: EditRequestForm.status,
            TaskDetails:[
                {
                    UserDetails :EditRequestForm.UserDetails,
                    ValidUntil: EditRequestForm.ValidUntil
                }
            ]
        }
        console.log('temp_details::::::' ,temp_details)
        let temp_updates = {
            UpdatedTS: `${formatDate()}, ${formatTime()}`,
            Comments: EditRequestForm.comments,
            FileStore: uploadedFileId,
            FileName: uploadedFileName
        }
        console.log('tempupdates',temp_updates)
        const dispatchEditObj ={
            Assignee:EditRequestForm.assignee,
            Status:EditRequestForm.status,
            Comments:EditRequestForm.comments,
            IncidentID:editRequestID,
            ValidUntil:EditRequestForm.ValidUntil,
            FileStore: uploadedFileId,
            FileName: uploadedFileName
        }
        console.log(dispatchEditObj)
        const newRequests = [...RequestIncidentData];
        const temp_Arr = {
            status: EditRequestForm.status,
            assignee: EditRequestForm.assignee,
            comments: EditRequestForm.comments
        }
        console.log('nnnnnnnnnnnn',EditRequestForm.type, EditRequestForm.status ,EditRequestForm)
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        if (isEmptyInput) {
            setShowEditAdminRequestModel(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else if(EditRequestForm.type === 'Task' && EditRequestForm.status !== 'Completed'){
            setShowEditAdminRequestModel(true);
            setValidation('Please Ensure that Status Selected is set to Completed to Update the Role Validity')
        }
        else {
            const index = RequestIncidentData.findIndex((data, index) => data.IncidentID === editRequestID);
            let editedRequestUpdates = newRequests[index]?.AdminComments;
            editedRequestUpdates.unshift(temp_updates)
            let obj2 = { AdminComments: editedRequestUpdates }
            let UserRequestUpdates = newRequests[index]?.UserComments;
            let userData = { UserComments: UserRequestUpdates }
            const newRequest = Object.assign(temp_details, obj2, userData)
            newRequests[index] = newRequest;
            setRequestIncidentData(newRequests);
            dispatch(updateEditAdminRequestStart(dispatchEditObj))
            setEditRequestID(null);
            setShowEditAdminRequestModel(false)
            setValidation('');
        }
    }

    function formatcreatedDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }


 const AssigneeFilter = () => {
    const arr1 = [...RequestIncidentData];
  if(assigneeSelected === null || assigneeSelected === undefined || assigneeSelected === '') {
           return arr1;     
   }
   else {
    const filterData =  arr1.filter(obj => obj.Assignee === assigneeSelected);
    return filterData;
   }
 }


const [filters, setFilters] = useState([]);
const [clearFilters, setClearFilters] = useState(false);
const [region_data, setRegion_data] = useState([]);
const RegionInfo = ADMIN_DATA.Status;



useEffect(() => {
    if (RegionInfo?.length > 0) {
      RegionInfo.map(info => info.isChecked = false);
      setRegion_data(RegionInfo);
    }
  }, [RegionInfo])
  const clearFilterHandler = (option) => {
    setFilters([]);
    setClearFilters(true);
    let temp_arr = [...region_data];
    temp_arr.map((itm, i) => {
        itm.isChecked = false;
      
      return '';
    });
  }
  return (
    <>
         
                <section style={{ width: '98%' }}>
                    <main>
                        <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive1={false} label2='Requests' />
                        <header className='request_header'>Requests</header>
                        <header className='sla_date_filter_tab' style={{ marginLeft: '15px', width: '98%' }}>
                        <main className='exclude_refresh'>
                            <span className='filter_icon'><img src={filterIcon} alt='' /></span>
                            <span style={{ marginTop: '15px' }}><label id='label'>Date</label>&nbsp;<br />
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    name='request_date'
                                    clearable
                                    value={reuqestDate}
                                    placeholder="MM/DD/YYYY"
                                    onChange={(date) => {
                                        setRequestDate(RequestDateFormat(date));
                                        setFilterSelected(RequestDateFormat(date));
                                        dispatch(getAdminRequestData(RequestDateFormat(date),selectedType ? selectedType : '',statusSelected ? statusSelected :''))
                                    }}
                                    format="MM/DD/YYYY"
                                    style={{ width: '250px' }}
                                    error={false}
                                    helperText={null}
                                />
                            </span>
                            <span className='dropdown_sla'> Status
                                <RequestDropdown data={RequestDropdownData?.[0]?.filter?.Status} name='Status' value='Status' id='id'
                                    addValueToQueue={(name, value) => {setStatusSelected(value);dispatch(getAdminRequestData(filterSelected ? filterSelected : '' ,selectedType ? selectedType : '',value));setIsRefreshedClicked(false)}} refresh={isRefreshClicked}
                                     
                                />
                                {/* <Dropdown data={ADMIN_DATA.Status} id='regionDrpdwn'
              onSelectingDropdown={onAddingPeriodFilters}
              clearFilters={clearFilters} /> */}
                            </span>
                            <span className='dropdown_sla'> Type
                                <RequestDropdown data={RequestDropdownData?.[0]?.filter?.Type} name='Type' value='Type' id='id'
                                    addValueToQueue={(name, value) => {setSelectedType(value); dispatch(getAdminRequestData(filterSelected ? filterSelected :'',value,statusSelected ? statusSelected :''));setIsRefreshedClicked(false)}} refresh={isRefreshClicked}
                                   
                                />
                            </span>
                             <span className='dropdown_sla'> Assignee
                                <RequestDropdown data={DATA.AssigneeMail} name='yearMonth' value='value' id='id'
                                    addValueToQueue={(name, value) => {setAssigneeSelected(value);setIsRefreshedClicked(false)}} refresh={isRefreshClicked}
                                /></span>
                                </main>
                                <main>
                            <div className='container_reset_request_admin' >
                                <Button className={`reset-icon download-btn `} item='Refresh Table'
                                    onClick={() => {
                                        setRequestDate(null)
                                        setStatusSelected('')
                                        setFilterSelected('')
                                        setAssigneeSelected()
                                        setIsRefreshedClicked(true)
                                        dispatch(getAdminRequestData('','',''))
                                    }} icon={RefreshIcon} /></div></main>
                        </header>
                    </main>
                    {filters?.length > 0 ? (<ClearFilter
        onClearingFilters={clearFilterHandler}
        filteredPeriod={filters} />) : null}
                    <main className='hints_row' style={{ marginTop: 30, marginLeft: 15, width: '98%' }}>
                        <header className='search_adduser'>

                            <div> <span className='search-bar-role'>
                                <input className='search-input-role' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Request/Incident Title' />
                                <button className='search-icon'><img src={searchIcon} alt='' /> </button>
                            </span></div>
                            
                        </header>

                        <section className='sla_exemption_table' style={{ width: '100%' }}>
                            <table  >
                                <thead style={{ width: '100%' }} >
                                    <tr style={{ position: 'sticky', top: -1, backgroundColor: '#F6F8FA' }}>{ADMIN_DATA.UserHeader.map(data => (<th style={{ border: 'none' }} key={data.id}>{data.value}</th>))}</tr>   </thead>
                                <tbody style={{ minHeight: '0px' }} >
                                    {searchRows(AssigneeFilter())?.map((info, i) =>
                                    
                                        <AdminTableHandle key={i} info={info} HandleEditRequestClick={HandleEditRequestClick} />)}
                                </tbody>
                            </table>
                        </section>
                    </main>
                   
                </section>

           
            {/* {showAddAdminRequestModel && <AdminRequestPopup isOpen={showAddAdminRequestModel} setShowModal={setShowAddAdminRequestModel}>
                <AdminRequestModel handleEditRequestCancel={handleAddRequestCancel} editMode={false}
                addValueToQueue={addValueToRequest} handleUserAddRequest={handleUserAddRequest}
                handleRequestFormSubmit={handleRequestFormSubmit} onClickSelect={() => { }}
                    EditRequestForm={EditRequestForm}
                    buttonOne='UPDATE' buttonTwo='CANCEL'
                />
            </AdminRequestPopup>
            } */}
            {showEditAdminRequestModel && <AdminRequestPopup isOpen={showEditAdminRequestModel} setShowModal={setShowEditAdminRequestModel}>
                <AdminRequestModel handleEditRequestCancel={handleEditRequestCancel} editMode={true}
                    buttonOne='UPDATE' buttonTwo='CANCEL' addValueToQueue={editValueToRequest}
                    handleUserAddRequest={handleUserEditRequest} 
                    handleRequestFormSubmit={handleRequesEditSubmit} onClickSelect={onClickSelect}
                    EditRequestForm={EditRequestForm} validation={validation} dispatchFileUpload={data =>
            setUpdatedFileData(data)} dispatchFileName={data => setUploadedFileName(data)} DeleteUploadedFile={DeleteUploadedFile}
                />
            </AdminRequestPopup>
            }

    </>
  )
}

export default AdminRequestMain