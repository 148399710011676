import React, { useEffect, useState } from 'react';
import TooltipHandler from '../../Onboard/OnboardComponents/TooltipHandler';
import './../Upload-Navigation/UploadNavigation.css';
import Information_Icon from './../../../assets/onboard-images/Information_Icon.png';
import ContractualSLAIcon from "./../../../assets/images/Contractual_SLAs.png";
import { useSelector } from 'react-redux';


export function isEditableForDate(selectedVolumeSlaDate) {
   // console.log('Selected SLA Date:', selectedVolumeSlaDate);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-indexed
    const currentYear = currentDate.getFullYear();

    // Check if selectedVolumeSlaDate is valid
    if (!selectedVolumeSlaDate) {
       // console.error('Invalid selectedVolumeSlaDate:', selectedVolumeSlaDate);
        return false; // Not editable if date is invalid
    }

    // Selected date
    const selectedDate = new Date(selectedVolumeSlaDate);
    const selectedMonth = selectedDate.getMonth();
    const selectedYear = selectedDate.getFullYear();

    // Determine if the selected date is in the current month
    const isCurrentMonth = (selectedYear === currentYear && selectedMonth === currentMonth);
    const isPreviousMonth = (selectedYear === currentYear && selectedMonth === currentMonth - 1) ||
                            (selectedYear === currentYear - 1 && selectedMonth === 11 && currentMonth === 0); // for December to January
    const isFutureMonth = (selectedYear > currentYear) || 
                          (selectedYear === currentYear && selectedMonth > currentMonth);

   // console.log(`isCurrentMonth: ${isCurrentMonth}, isPreviousMonth: ${isPreviousMonth}, isFutureMonth: ${isFutureMonth}`);

    // Input is editable if it's the current month, the previous month, or any future month
    return isCurrentMonth || isPreviousMonth || isFutureMonth;
}

export function FormInputFormat({ field, AddValueToUploadForm ,RBACEditable}) {
    const selectedVolumeSlaDate = useSelector((state) => state.uploadReducer.selectedVolumeSlaDate);
    const isEditable = isEditableForDate(selectedVolumeSlaDate);

    const [hasInteracted, setHasInteracted] = useState(false);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    
        return [year,month, '01'].join('/');
    }

    const placeholderHandle = () => {
        switch (field.FormatType) {
            case '0':
            case 'Number = Numerator / Denominator': return '12345';
            case '%':
            case 'Percentage = Numerator / Denominator': return '45%';
            case 'hh:mm:ss':
            case 'Time = Numerator / Denominator': return 'HH:MM:SS';
            default: return '';
        }
    }

    const [inputValues, setInputValues] = useState({
        item1: '',
        item2: '',
        item3: ''
    });

    useEffect(() => {
        if (field) {
            const newValues = {
                item1: '',
                item2: field.Value !== null ? field.Value : '',
                item3: field.Divider !== null ? field.Divider : ''
            };

            // Check if field.Value or field.Divider is null to clear item2/item3
            if (field.Value === null) newValues.item2 = '';
            if (field.Divider === null) newValues.item3 = '';

            setInputValues(newValues);
            // Trigger calculation immediately after setting initial values
            calculateResult(newValues.item2, newValues.item3);
        }
    }, [field]);

    const calculateResult = (item2, item3) => {
        let result = '';
        if (item2 && item3) {
            switch (field.FormatType) {
                case '0':
                    const num2 = parseFloat(item2);
                    const num3 = parseFloat(item3);
                    if (!isNaN(num2) && !isNaN(num3) && num3 !== 0) {
                        result = (num2 / num3).toFixed(2);
                    }
                    break;
                case '%':
                    const perc2 = parseFloat(item2);
                    const perc3 = parseFloat(item3);
                    if (!isNaN(perc2) && !isNaN(perc3) && perc3 !== 0) {
                        result = ((perc2 / perc3) * 100).toFixed(2) + '%';
                    }
                    break;
                case 'hh:mm:ss':
                    const seconds2 = parseInt(item2, 10) || 0;
                    const seconds3 = parseInt(item3, 10) || 1; // Avoid division by zero
                    if (seconds3 > 0) {
                        const totalSecondsResult = seconds2 / seconds3;
                        const hours = Math.floor(totalSecondsResult / 3600);
                        const minutes = Math.floor((totalSecondsResult % 3600) / 60);
                        const seconds = Math.floor(totalSecondsResult % 60);
                        result = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    }
                    break;
                default:
                    break;
            }
        }

        // Update item1 with the calculated result
        setInputValues(prevValues => ({
            ...prevValues,
            item1: result || ''
        }));
    };

    const onUploadFormChange = (event) => {
        const newFormData = { ...inputValues };
        const name = event.target.name;
        const value = event.target.value;

        // Allow only numeric input for time fields
        if (field.FormatType.startsWith('Time') && (name === 'item2' || name === 'item3')) {
            if (!/^\d*$/.test(value)) {
                return; // Ignore non-numeric input
            }
        }

        newFormData[name] = value;
        setInputValues(newFormData);
        setHasInteracted(true);
    };

    // Calculate result whenever item2 or item3 changes
    useEffect(() => {
        const { item2, item3 } = inputValues;
        if (hasInteracted) {
            calculateResult(item2, item3);
        }
    }, [inputValues.item2, inputValues.item3, hasInteracted]);

    // Log inputValues whenever they change
    useEffect(() => {
        console.log('Current input values:', inputValues);
    }, [inputValues]);

    useEffect(() => {
        const { item2, item3 } = inputValues;
        if (hasInteracted) {
            const temp_Arr = {
                "AccountSLAID": field.AccountSLAID,
                "Value": item2,
                "Divider": item3
            };
            console.log('Submitting:', temp_Arr);
            AddValueToUploadForm(temp_Arr);
            setHasInteracted(false);
        }
    }, [inputValues.item2, inputValues.item3, hasInteracted]); // Keep this to handle subsequent changes

    return (
        <section>
            <label style={{ display: 'flex' }}>
                <span>{field.SLATemplate}</span>&nbsp;&nbsp;&nbsp;
                {field.Contractual && <span> <img src={ContractualSLAIcon} alt='' /></span>}
                &nbsp; &nbsp; &nbsp;
                <span style={{ position: 'relative' }}>
                    <TooltipHandler
                        src={Information_Icon}
                        width='14px'
                        height='14px'
                        position='bottom right'
                        arrowAlign='start'
                        textBoxWidth='300px'
                        info={true}
                        text={field.TemplateDescription}
                    />
                </span>
            </label>
            <div style={{ display: 'flex', gap: '15px' }}>
                <input
                    type='text'
                    name='item1'
                    placeholder={placeholderHandle()}
                    value={inputValues.item1}
                    readOnly
                />
                <span style={{ marginTop: '20px' }}>=</span>
                <input
                    type='text'
                    name='item2'
                    placeholder='000'
                    value={inputValues.item2}
                    onChange={onUploadFormChange}
                    autoComplete="off"
                    readOnly={RBACEditable ? !isEditable : true}
                />
                <span style={{ marginTop: '20px' }}>/</span>
                <input
                    type='text'
                    name='item3'
                    placeholder='000'
                    value={inputValues.item3}
                    onChange={onUploadFormChange}
                    autoComplete="off"
                    readOnly={RBACEditable ? !isEditable : true}
                />
            </div>
        </section>
    );
}




export function FormInputText({ field, AddValueToUploadForm, RBACEditable }) {
    const selectedVolumeSlaDate = useSelector((state) => state.uploadReducer.selectedVolumeSlaDate);
    const isEditable = isEditableForDate(selectedVolumeSlaDate);

    console.log('isEditable',isEditable)

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
    
        return [year,month, '01'].join('/');
    }

    const fieldNamehandle = (fieldName) => {
        return fieldName.split(' ').join('_');
    }

    const placeholderHandle = () => {
        switch (field.FormatType) {
            case '0': return '12345'
            case '%': return '45%'
            case 'hh:mm:ss': return 'HH:MM:SS'
            default: { }
        }
    }
const[value,setValue] = useState()
const [error, setError] = useState('');
useEffect(() => {
    // Clear the value if the field is null
    if (field.Value === null) {
        setValue('');
    } else if (field.FormatType === 'hh:mm:ss') {
        // Convert seconds to hh:mm:ss for display
        setValue(secondsToTime(field.Value) || ''); 
    } else {
        setValue(field.Value || ''); // Set to field.Value or empty string if undefined
    }
}, [field]);

const validateInput = (value) => {
    switch (field.FormatType) {
        case '0':
        case '%':
            if (!/^\d*$/.test(value)) {
                return 'Please enter a valid number.';
            }
            break;
        // case '%':
        //     if (!/^\d+(\.\d+)?%$/.test(value)) {
        //         return 'Please enter a valid percentage (e.g., 45%).';
        //     }
        //     break;
        case 'hh:mm:ss':
            if (!/^\d+:[0-5]\d:[0-5]\d$/.test(value)){
                return 'Please enter a valid time (HH:MM:SS).';
            }
            break;
        default:
            return '';
    }
    return '';
}

const convertTimeToSeconds = (time) => {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return (hours * 3600) + (minutes * 60) + seconds;
};

const secondsToTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const onUploadFormChange = (event) => {
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;

    // Validate the input
    const validationError = validateInput(fieldValue);
    setError(validationError);
    setValue(fieldValue);

    const temp_Arr = {
        "AccountSLAID": field.AccountSLAID,
        "Value": fieldValue
    };

    // Update AddValueToUploadForm when the input is cleared or there's no validation error
    if (fieldValue === '' || !validationError) {
        if (field.FormatType === 'hh:mm:ss') {
            const seconds = convertTimeToSeconds(fieldValue);
            temp_Arr.Value = seconds;
        }
        AddValueToUploadForm(temp_Arr);
    }
}
    return (
        <>
            <section>
                <label style={{ display: 'flex' }}> {field.SLATemplate}  &nbsp;
                    {field.Contractual && <span> <img src={ContractualSLAIcon} alt='' /> </span>}
                    &nbsp;   <span style={{ position: 'relative' }} >
                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right' arrowAlign='start'
                            textBoxWidth='300px' info={true} text={field.TemplateDescription} />
                    </span>
                </label>
                <input type='text'
                    name={fieldNamehandle(field.SLATemplate)}
                    placeholder={placeholderHandle()}
                    onChange={onUploadFormChange}
                    value={value}
                    readOnly={RBACEditable ? !isEditable : true}
                />
                {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
            </section>
        </>
    )
}


