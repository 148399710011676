import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import MyprofileImg from "../../assets/images/MyProfile_Icon.png";
import CustomWidgets from "../../assets/images/CustomWidgets_Icon.png";
import Heatmap from "../../assets/images/HeatMap.png"
import GSD from "../../assets/images/GSDInsight_Logo.png"
import './styles.css'

const settinglinks = (props) => {
    const { closeSettings, openModal } = props;
    const GetLinks = () => {

        const history = useHistory();
        const redirectTo = (path) => {
            closeSettings();
            history.push(path);
        }

        const handleHeatmapUrl = () => {
           
            window.open(`${process.env.REACT_APP_BASE_URL}/nav/to/heatmap`);
        }
        const heatMapURL = 'https://gsd-insight.houston.dxccorp.net/ReportServer?%2fReports%2fGeneric%2fIISLog%2fLOGIIS&rs:Command=Render&rc:Toolbar=false&rs:embed=true';
        const aboutURL = 'https://github.dxc.com/pages/MWSGSD/GSD-Insight/'
        // switch (props.location.pathname) {
        //     case '/welcome':
        //     case '/welcome/profile':
        //     case '/welcome/filter':
                return (
                    <div className='settings_set' onChange={props.onChange}>
                        <div className="set_widget1" onClick={() => {
                            redirectTo("/welcome/profile");
                        }} ><img src={MyprofileImg} alt="profile"></img>   <span className="profile_text" >My Profile</span></div>
                        <div className="set_widget1" onClick={() => redirectTo('/welcome/filter')}><img src={CustomWidgets} alt="widgets"></img> <span className="profile_text">Custom Widgets</span></div>
                        <div className="set_widget1" onClick={() => handleHeatmapUrl()}><img src={Heatmap} alt="widgets"></img> <span className="profile_text">Heat Map</span></div>
                        <Link to='/githublanding' target='_blank'><div className="set_widget1">
                              <img className='GSD-image' src={GSD} alt="GSD"></img>   <span className="profile_text">About</span> 
                        </div></Link>
                    </div>)
            // default:
            //     return (
            //         <div className='settings_set'>
            //             <div className="set_widget1"><img src={MyprofileImg} alt="profile"></img>   <span className="profile_text_disabled" >My Profile</span></div>
            //             <div className="set_widget1"><img src={CustomWidgets} alt="widgets"></img> <span className="profile_text_disabled">Custom Widgets</span></div>
            //             <div className="set_widget1" onClick={() => window.open(heatMapURL, '_blank')}><img src={Heatmap} alt="widgets"></img> <span className="profile_text">Heat Map</span></div>
            //             <div className="set_widget1" >
            //                 <Link to='/githublanding' target='_blank'>  <img className='GSD-image' src={GSD} alt="GSD"></img>   <span className="profile_text">About</span> </Link>
            //             </div>
            //         </div>
            //     )
       // }
    }
    return GetLinks();


};
export default withRouter(settinglinks)