
import { github_actionTypes as actionTypes} from './../constants/github-actionTypes';


const initialState = {
    developerCard: {},
    errorVisibility:{},
    designCard:{},
    TilesData:[]
}

const githubReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DEVELOPER_CARD_DATA':
            return {
                ...state, developerCard: action.payload
            };
            case 'SET_AXIOS_ERROR_DATA':
            return {
                ...state, errorVisibility: action.payload
            };
            case 'SET_DESIGN_CARD_DATA':
            return {
                ...state, designCard: action.payload
            };
            case actionTypes.GET_TILES_DATA:
                return { ...state };
                case actionTypes.TILES_DATA:
                    return { ...state, TilesData: action.payload };
        default: return state

    }
}

export default githubReducer ;