import React from 'react'
import './../GitHub/GitHub_LandingPage/GitHubPage.css';
import AdminBreadcrumb from '../Administration/General/Admin-breadcrumb'
import WhyGSDInsight_HeroBanner from './../../assets/images/WhyGSDInsight_HeroBanner.png';
import CentralisedData_Image from './../../assets/images/CentralisedData_Image.png';
import Availability_Image from './../../assets/images/Availability_Image.png';
import Customisation_Image from './../../assets/images/Customisation_Image.png';
import EasytoAccess_Image from './../../assets/images/EasytoAccess_Image.png';
import Presentation_Image from './../../assets/images/Presentation_Image.png';
import TopDownBottomUp_Image from './../../assets/images/TopDownBottomUp_Image.png';
import AgentScorecard_Icon from './../../assets/images/AgentScorecard_Icon.png';
import SupervisorDashboard_Icon from './../../assets/images/SupervisorDashboard_Icon.png';
import SiteLevel_Icon from './../../assets/images/SiteLevel_Icon.png';
import GlobalKPI_Icon from './../../assets/images/GlobalKPI_Icon.png';
import TrendAnalysis_Icon from './../../assets/images/TrendAnalysis_Icon.png';
import RoleBasedAccess_Icon from './../../assets/images/RoleBasedAccess_Icon.png';
import PreviousArrow_Disabled_Icon from './../../assets/images/PreviousArrow_Disabled_Icon.png';
import PreviousArrow_Icon from './../../assets/images/PreviousArrow_Icon.png';
import NextArrow_Disabled_Icon from './../../assets/images/NextArrow_Disabled_Icon.png';
import NextArrow_Icon from './../../assets/images/NextArrow_Icon.png';
import { useState } from 'react';
import { Footer} from './HeaderFooter';
import { useHistory } from 'react-router-dom';
const WhyGsd = () => {
  const history = useHistory();
  const [row1, setRow1] = useState(true);
  const [row2, setRow2] = useState(false);
  const previousHandler = () => {
    setRow1(true);
    setRow2(false);
  }
  const nexthandler = () => {
    setRow1(false);
    setRow2(true);
  }
  const column1 = [
    {
      id: 1,
      imageSrc: CentralisedData_Image,
      name: 'Centralised Data',
      description: 'The unique platform integrating all of your data from multiple channels, supported by fully or semi-automated mechanisms. Secure your data in a modernised, state of the art solution developed by the Global Service Desk organisation.'
    },
    {
      id: 2,
      imageSrc: Availability_Image,
      name: 'Availability',
      description: 'Time or geography creates no boundaries in getting the data available to you anytime, anywhere. Reporting services with ‘round the clock’ accessibility for constant control and use of data.'
    },
    {
      id: 3,
      imageSrc: Customisation_Image,
      name: 'Customization',
      description: 'GSD Insight enables the user to tailor their needs with personalised reporting views and metrics.'
    }
  ]
  const column2 = [
    {
      id: 1,
      imageSrc: EasytoAccess_Image,
      name: 'Easy to Access',
      description: 'Simplified steps to define your role and Account information, you are just one click away from GSD Insight experience. The platform is straightforward to use, intuitive navigation through the site will help to find the searched content effortlessly.'
    },
    {
      id: 2,
      imageSrc: Presentation_Image,
      name: 'Presentation Format & Downloadable Content',
      description: 'A very modern GSD Insight interface offers exclusive presentation format and user-friendly downloadable content.'
    },
    {
      id: 3,
      imageSrc: TopDownBottomUp_Image,
      name: 'Top Down – Bottom Up',
      description: 'Data driven approach delivering detailed and high level, cumulated representation to oversee all layers of operational performance.'
    }
  ]
  const score_card = [
    {
      id: 1,
      imageSrc: AgentScorecard_Icon,
      description: 'Agent Score Card for monthly agent performance assessment.'
    },
    {
      id: 2,
      imageSrc: SupervisorDashboard_Icon,
      description: 'Supervisor Dashboard for desk managers to manage client contractual performance.'
    },
    {
      id: 3,
      imageSrc: SiteLevel_Icon,
      description: 'Site level balanced scorecard and operational metrices across accounts served (Views dissected by Account / Language / Channel / P&L Region).'
    }
  ]
  const global_KPI = [
    {
      id: 1,
      imageSrc: GlobalKPI_Icon,
      description: 'Global KPI / Metrices and key indices.'
    },
    {
      id: 2,
      imageSrc: TrendAnalysis_Icon,
      description: 'Trend analytics across time.'
    },
    {
      id: 3,
      imageSrc: RoleBasedAccess_Icon,
      description: 'Non-functional Role Based Access / Reports / compliance with applicable legislation.'
    }
  ]

  return (
    <>
      <section style={{ marginLeft: -100 }}>
        <div className='breadcrumb_gsd'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Why GSD Insight' />
        </div>
        
        <main className='hero_image_container'>
          <img src={WhyGSDInsight_HeroBanner} alt='HeroBanner' width='100%' height='480px' style={{objectFit:'cover'}} />
          {/* <div>
          <div class="text_on_herobanner">
          <div  style={{width:1260,margin:'auto'}}>
            <div className='value_propagation_text'>Why GSD Insight</div>
            <div className='unified_text'>GSD Insight brings all relevant data together from various sources and visualises it to enable data-driven analytics and reporting.</div>
          </div></div></div> */}
          <div class="text_on_herobanner" >
          <div className='value_propagation_text'>Why GSD Insight</div>
            <div className='unified_text'>GSD Insight brings all relevant data together from various sources and visualises it to enable data-driven analytics and reporting.</div>
          </div>
        </main>
        <div style={{width:1260,margin:'auto'}}>
        <main>
          <div className='customer_system'>Customer Centric System</div>
        </main>
        {row1 && <main className='image_bar'> {column1.map((data, index) =>
          <main className='img_txt'><div><img src={data.imageSrc} alt='' width='100%' style={{marginBottom:'20px'}}/></div>
            <div className='img_head'>{data.name}</div><div style={{ width: '80%' }}>{data.description}</div></main>)}
        </main>}
        {row2 && <main className='image_bar'>{column2.map((data, index) =>
          <main className='img_txt'><div ><img src={data.imageSrc} alt='' width='100%' style={{marginBottom:'20px'}}/></div>
            <div className='img_head2'>{data.name}</div><div style={{ width: '80%' }}>{data.description}</div></main>)}
        </main>}
        <main className='arrow_section'>
          {row1 ? <img src={PreviousArrow_Disabled_Icon} alt='' /> : <img src={PreviousArrow_Icon} alt='' onClick={previousHandler} style={{ cursor: 'pointer' }} />}
          &nbsp;&nbsp;&nbsp;<span>{row1 ? 1 : 2}/2</span>&nbsp;&nbsp;&nbsp;
          {row1 ? <img src={NextArrow_Icon} alt='' onClick={nexthandler} style={{ cursor: 'pointer' }} /> : <img src={NextArrow_Disabled_Icon} alt='' />}
        </main></div>
        
        <section className='score_card'>
        <div style={{width:1260,margin:'auto'}}>
          <main className='row_1'>{score_card.map((data, index) =>
            <div className='agent_div'><img src={data.imageSrc} alt='' /><hr style={{ color: '1px solid #FFFFFF' }} /><div style={{ width: '90%' }}>{data.description}</div></div>)}
          </main>
          <main className='row_1'>{global_KPI.map((data, index) =>
            <div className='agent_div'><img src={data.imageSrc} alt='' /><hr style={{ color: '1px solid #FFFFFF' }} /><div style={{ width: '90%' }}>{data.description}</div></div>)}
          </main></div>
        </section> 
        <Footer/>
      </section>
     
    </>
  )
}

export default WhyGsd