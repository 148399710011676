import React, { Fragment, useState } from 'react';
import './TableTemplate.css'
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png'
import addQueueIcon from './../../../../assets/onboard-images/Add_Queue_Icon.png'
import editIcon from './../../../../assets/onboard-images/Edit_Icon.png'
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png';
import ActivateDisabledIcon from './../../../../assets/images/Activate_Disabled_Icon.png';
import ActivateIcon from './../../../../assets/images/Activate_Icon.png';
import { QueueModalPopup } from '../Modals/ModalQueuePopup';
import { AddQueueModal } from './AddQueueModal';
import { AdminValidDateChange, DeleteFragment, DeleteModalPopup } from '../Modals/DeleteModalPopup';
import TooltipHandler from '../TooltipHandler';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getQueueTabLanguages, getOriginatingCountry, setValidforAddingTabRows } from './../../../../actions/Onboard.action';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { getRoleBasedView, updateQueueRequestStart } from '../../../../actions/Administration/admin.action';
const headers =
    [
        {
            caption: "Queue Name",
            id: "1"
        }, {
            caption: "Language",
            id: "2"
        },
        {
            caption: "Business Unit",
            id: "3"
        },
        {
            caption: "Originating Country",
            id: "4"
        },
        {
            caption: "Claim Number",
            id: "5"
        },
        {
            caption: " Valid From",
            id: "6"
        },
        {
            caption: "Valid To",
            id: "7"
        }
    ]
function QueueComponent(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    let languageInfo = useSelector((state) => state.onboardReducer.queueTabLanguages);
    let countryInfo = useSelector((state) => state.onboardReducer.originatingCountry);
    const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    };


    useEffect(() => {
        dispatch(getQueueTabLanguages());
        dispatch(getOriginatingCountry());
        dispatch(getRoleBasedView())
    }, [dispatch])

    const renderQueuesTabData = (data) => {
        let temp_Arr = [...data];
        let temp_arr = temp_Arr?.map((temp, i) => {
            return {
                queue_name: temp.queueName,
                business_unit: temp.businessUnit,
                language: temp.language,
                country: temp.originatingCountry,
                claim_no: temp.claimedNumber,
                valid_from: formatDate(temp.validFrom),
                valid_to: formatDate(temp.validTo),
                status: temp?.status,
                queueID: temp?.QueueID,
                request: temp?.Request,
                tooltip: temp?.ToolTip
            }
        });
        return temp_arr;
    }

    let data = (props.QueueTableData?.length > 0 ? renderQueuesTabData(props.QueueTableData) : []);
    const [queueData, setQueueData] = useState(data);
    useEffect(() => {
        setQueueData(data)
    }, [props.QueueTableData])
    const [filter, setFilter] = useState('');
    const [showAddQueueModal, setShowAddQueueModal] = useState(false);
    const [showEditQueueModal, setShowEditQueueModal] = useState(false);
    const [showDeleteQueueModal, setShowDeleteQueueModal] = useState(false);
    const [searchColumns, setSearchColumns] = useState([
        'queue_name',
        'language', 'country', 'business_unit', 'claim_no'
    ]);
    const [dispatchQueueObj, setDispatchQueueObj] = useState([])
    const [validation, setValidation] = useState('')

    const [addFormQueue, setAddFormQueue] = useState({
        queue_name: '',
        language: '',
        business_unit: '',
        country: '',
        claim_no: '',
        valid_from: '',
        valid_to: ''
    })

    const [editQueueID, setEditQueueID] = useState(null);
    const [editQueueInfo, setEditQueueInfo] = useState({
        queue_name: '',
        language: '',
        business_unit: '',
        country: '',
        claim_no: '',
        valid_from: '',
        valid_to: ''
    });

    const [deleteRowID, setDeleteRowID] = useState(null);
    const [AccountQueueId, setAccountQueueId] = useState(null);
    const [mode, setMode] = useState(null);
    useEffect(() => {
        if (props.accountDetails) setMode(props.accountDetails.mode)
    }, [props.accountDetails])


    const addValueToQueue = (name, value) => {
        const newFormData = { ...addFormQueue };
        if (name !== 'Select') {
            newFormData[name] = value;
            setAddFormQueue(newFormData)
        }
    }
    const HandleAddQueue = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...addFormQueue };
            newFormData[fieldName] = fieldValue;
            setAddFormQueue(newFormData)
        }
    }

    const configureQueueData = queueData => {
        let temp_arr = [...queueData];
        let tempArray2 = temp_arr.map(queue => {
            let temp1 = {
                QueueName: queue.queue_name,
                BusinessUnit: queue.business_unit || '',
                LanguageName: queue.language || '',
                OriginatingCountry: queue.country || '',
                ClaimedNumber: queue.claim_no || '',
                ValidFrom: queue.valid_from,
                ValidTo: queue.valid_to,
            }
            let temp2 = {
                QueueName: queue.queue_name,
                BusinessUnit: queue.business_unit || '',
                LanguageName: queue.language || '',
                OriginatingCountry: queue.country || '',
                ClaimedNumber: queue.claim_no || '',
                ValidFrom: queue.valid_from,
                ValidTo: queue.valid_to,
                QueueId: queue?.queueID || '',
            }
            return location.pathname === '/onboardAccount' ? temp1 : temp2
        });
        let tempArray3 = [{
            Servicedesk: accountSiteInfo.SiteName,
            Account: accountSiteInfo.AccountName,
            Details: tempArray2
        }]
        setDispatchQueueObj(tempArray3);
    }



    const handleAddQueueSubmit = event => {
        event.preventDefault();
        const newQueue = {
            id: (Math.random() * 10000).toString(),
            queue_name: addFormQueue.queue_name,
            language: addFormQueue.language,
            business_unit: addFormQueue.business_unit,
            country: addFormQueue.country,
            claim_no: addFormQueue.claim_no,
            valid_from: (addFormQueue.valid_from),
            valid_to: (addFormQueue.valid_to),
            queueID: addFormQueue?.queueID,
            status:'Active' ,
            request: 'Completed',
            tooltip:  null
        }
        const newQueues = [newQueue, ...queueData]
        const temp_Arr = {
            queue_name: addFormQueue.queue_name,
            valid_from: (addFormQueue.valid_from),
            valid_to: (addFormQueue.valid_to),
            language: addFormQueue.language,
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        const isValidDate = moment(addFormQueue.valid_to).isAfter(addFormQueue.valid_from)
        if (isEmptyInput) {
            // setRoleAddSubmit(true);
            setShowAddQueueModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else if (!isValidDate) {
            setShowAddQueueModal(true);
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ')
        }
        else {
            setQueueData(newQueues)
            setShowAddQueueModal(false);
            configureQueueData(newQueues);
            setValidation('')
        }
    }
    const handleAddQueueCancel = event => {
        event.preventDefault();
        setShowAddQueueModal(false);
    }

    const onClickSelect = (name) => {
        const newFormData = { ...editQueueInfo };
        newFormData[name] = '';
        setEditQueueInfo(newFormData)

    }

    const onClickEditRow = (index, data) => {
        setEditQueueID(index)
        setShowEditQueueModal(true)
        const formValues = {
            queue_name: data.queue_name,
            language: data.language,
            business_unit: data.business_unit,
            country: data.country,
            claim_no: data.claim_no,
            valid_from: data.valid_from,
            valid_to: data.valid_to,
            queueID: data?.queueID
        };
        setEditQueueInfo(formValues);
    }

    const handleEditQueueSelect = (name, value) => {
        const newFormData = { ...editQueueInfo };
        if (value !== 'Select') {
            newFormData[name] = value;
            setEditQueueInfo(newFormData);
        }
    }
    const handleEditQueueUpdate = event => {
        event.preventDefault();
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...editQueueInfo };
        newFormData[fieldName] = fieldValue;
        setEditQueueInfo(newFormData);
    }


    const handleEditQueueSubmit = event => {
        event.preventDefault();
        const editedQueue = {
            id: editQueueID,
            queue_name: editQueueInfo.queue_name,
            language: editQueueInfo.language,
            business_unit: editQueueInfo.business_unit,
            country: editQueueInfo.country,
            claim_no: editQueueInfo.claim_no,
            valid_from: editQueueInfo.valid_from,
            valid_to: editQueueInfo.valid_to,
            queueID: editQueueInfo?.queueID,
            status:'Active' ,
            request: 'Completed',
            tooltip:  null
        };
        const newQueues = [...queueData];
        const temp_Arr = {
            queue_name: editedQueue.queue_name,
            valid_from: (editedQueue.valid_from),
            valid_to: (editedQueue.valid_to),
            language: editQueueInfo.language,
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        const isValidDate = moment(editedQueue.valid_to).isAfter(editedQueue.valid_from)
        if (isEmptyInput) {
            // setRoleAddSubmit(true);
            setShowEditQueueModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else if (!isValidDate) {
            setShowEditQueueModal(true);
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ')
        }
        else {
            const index = queueData.findIndex((_, index) => index === editQueueID);
            newQueues[index] = editedQueue;
            setQueueData(newQueues);
            configureQueueData(newQueues);
            setEditQueueID(null);
            setShowEditQueueModal(false)
            setValidation('');
        }

    }
    const handleEditQueueCancel = event => {
        event.preventDefault();
        setEditQueueID(null);
        setShowEditQueueModal(false)
    }


    const handleDeleteQueue = () => {
        const newQueue = [...queueData];
        const index = queueData.findIndex((_, index) => index === deleteRowID);
        newQueue.splice(index, 1);
        setQueueData(newQueue);
        configureQueueData(newQueue);
        setShowDeleteQueueModal(false);
    }
    const handleDeleteQueueCancel = event => {
        event.preventDefault();
        setShowDeleteQueueModal(false)
    }


    function searchRows(rows) {
        return rows?.filter((row) =>
            searchColumns?.some(
                (column) =>
                    row?.[column]
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.toLowerCase()) > -1,
            ),
        );
    }
    useEffect(() => {
        props.DispatchQueuehandler(dispatchQueueObj)
    }, [dispatchQueueObj]);

    const [showReuqestModal, setShowReuqestModal] = useState(false);
    const ActivationHandler = (id, data) => {
        if (data.request !== 'Sent') {
            setEditQueueID(id);
            setAccountQueueId(data?.queueID);
            setShowReuqestModal(true);
        }
    }

    const onSubmitRequest = (date) => {
        const temp_Arr = [{
            Account: accountSiteInfo.AccountName,
            Servicedesk: accountSiteInfo.SiteName,
            ValidUntil: date,
            QueueId: AccountQueueId
        }]
        dispatch(updateQueueRequestStart(temp_Arr))
        setShowReuqestModal(false);
        let temp_arr = [...queueData];
        temp_arr.map(temp => {
            if (temp.queueID === AccountQueueId)
                temp.request = 'Sent';
            temp.tooltip = 'Activation Request already sent to Administrator'

        });
        setQueueData(temp_arr)
    }


    const ValidUser = () => {
         if(props.GeneralInfoToggle){
            setAddFormQueue([])
            setShowAddQueueModal(true)
            dispatch(setValidforAddingTabRows(false))
         }
         else{
            window.scrollTo(0, 0);
            dispatch(setValidforAddingTabRows(true))
         }
     }

    return (<>
        <Fragment>
            <section className='search-container'>
                <div className='search-bar'>
                    <input className='search-input' value={filter || ''} onChange={e => setFilter(e.target.value)} />
                    <button className='search-icon'><img src={searchIcon} alt='' /> </button>
                </div>

              {(RoleBasedView?.[0]?.Queue?.[0]?.ReadWrite === 0 && location.pathname === '/admin/account') ? '' : <>{(mode === 'create' || mode === 'edit') && <div className='add-queue' onClick={ValidUser} style={{ cursor: 'pointer' }}>
                    <img src={addQueueIcon} alt='' />
                    <span className='add-queue-span'>Add Queue</span>
                </div>}</>}
            </section>
        </Fragment>

        {searchRows(queueData).length === 0 ?
            <section>
                <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                    <thead>
                        <tr>
                            {headers.map(header => (
                                <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>
                            ))
                            }
                            <th>{'  '}</th>
                        </tr>
                    </thead>
                </table>
                <section className='noData-table'>
                    <div className='noData-para' style={{ marginTop: 0 }}>
                        <div style={{ marginTop: 30 }}>
                            No records found. This data grid enables adding/editing the corresponding sub-categories. <br /> To add new queue entries, click on "Add Queue"  link.
                        </div>
                    </div>
                </section>
            </section> :
            <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>
                        ))
                        }
                        <th>{'  '}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        searchRows(queueData)?.map((data, index) => (
                            <tr key={data.id}>
                                <td className='queue_tablebody'>{data.queue_name}</td>
                                <td className='queue_tablebody'>{data.language}</td>
                                <td className='queue_tablebody'>{data.business_unit}</td>
                                <td className='queue_tablebody'>{data.country}</td>
                                <td className='queue_tablebody'>{data.claim_no}</td>
                                <td className='queue_tablebody'>{data.valid_from}</td>
                                <td className='queue_tablebody'>{data.valid_to}</td>
                                {location.pathname === '/onboardAccount' ? <>  {(mode === 'create' || mode === 'edit') && <td className='table-button'>
                                    <span onClick={() => onClickEditRow(index, data)}>
                                        <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' /></span>
                                    <span onClick={() => { setDeleteRowID(index); setShowDeleteQueueModal(true) }}>
                                        <TooltipHandler src={deleteIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Delete' />
                                    </span>
                                </td>} </> :<>
                                  {RoleBasedView?.[0]?.Queue?.[0]?.ReadWrite === 1 ?  <> {(mode === 'create' || mode === 'edit') && <td className='table-button'>  {data.status === 'Active' ?
                                        <span onClick={() => onClickEditRow(index, data)}>
                                            <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' /></span>
                                        : <>
                                            <span onClick={() => ActivationHandler(index, data)}>
                                                <TooltipHandler src={data?.request === 'Sent' ? ActivateDisabledIcon : ActivateIcon} width='14px' height='14px' position='top center'
                                                    arrowAlign='center' textBoxWidth='300px'
                                                     text={data?.request === 'Sent' ? 'Request Already sent to Administrator' : ' Send Activation Request to Administrator'} />
                                            </span>
                                        </>
                                    }</td>}</> : ''}</>
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>}
        {showAddQueueModal && <QueueModalPopup isOpen={showAddQueueModal} setShowModal={setShowAddQueueModal}>
            <AddQueueModal modalName='Add Queue' buttonOne='ADD' buttonTwo='CANCEL'
                editMode={false} handleFormAddQueue={HandleAddQueue} languageInfo={languageInfo} countryInfo={countryInfo}
                editQueueInfo={editQueueInfo}
                handleAddQueueSubmit={handleAddQueueSubmit}
                handleAddQueueCancel={handleAddQueueCancel}
                addValueToQueue={addValueToQueue}
                addDateToQueue={addValueToQueue}
                QueryHandler={() => { }}
                onClickSelect={() => { }}
                validation={validation}
            />
        </QueueModalPopup>}
        {showEditQueueModal && <QueueModalPopup isOpen={showEditQueueModal} setShowModal={setShowEditQueueModal}>
            <AddQueueModal modalName='Edit Queue' buttonOne='UPDATE' buttonTwo='CANCEL'
                editMode={true} editQueueInfo={editQueueInfo} languageInfo={languageInfo} countryInfo={countryInfo}
                handleFormAddQueue={handleEditQueueUpdate}
                handleEditQueueSubmit={handleEditQueueSubmit}
                handleAddQueueCancel={handleEditQueueCancel}
                handleQueueEditDate={handleEditQueueSelect}
                addValueToQueue={handleEditQueueSelect}
                QueryHandler={() => { }} validation={validation}
                onClickSelect={onClickSelect}

            />
        </QueueModalPopup>}
        {showDeleteQueueModal && <DeleteModalPopup isOpen={showDeleteQueueModal} setShowModal={setShowDeleteQueueModal}>
            <DeleteFragment modalName='Delete Queue' buttonOne='YES' buttonTwo='NO' delete={true}
                handleDeleteQueue={handleDeleteQueue}
                handleDeleteQueueCancel={handleDeleteQueueCancel}
                queueName={queueData[queueData.findIndex((_, index) => index === deleteRowID)].queue_name}
            />
        </DeleteModalPopup>}
        {
            showReuqestModal && <DeleteModalPopup isOpen={showReuqestModal} setShowModal={setShowReuqestModal}>
                <AdminValidDateChange
                    requestHeading="Request for Queue Re-Activation" title1='Queue Name' title2='Language'
                    solution1={queueData[queueData.findIndex((_, index) => index === editQueueID)].queue_name}
                    solution2={queueData[queueData.findIndex((_, index) => index === editQueueID)].language}
                    onSubmit={onSubmitRequest} notValidDate = {() => { setShowReuqestModal(true) }}
                    onCancel={() => { setShowReuqestModal(false) }}
                />
            </DeleteModalPopup>
        }
    </>);
}

export default QueueComponent