import { all, call, put, takeEvery } from "redux-saga/effects";
import actionTypes from "../constants/actionTypes";
import reportConstructURLs from "../constants/reportURL";
import { getAbandonTimeService, getAttritionDataService, getAverageHandleTimeService, getContactAccountsService, getContactFTEService, getContactVolumeDataService, getCostPerContactService, getCSATdataService, getDeliverySupportService, getDigitalTransService, getDropdownInfoServive, getFirstContactResolutionService, getPenaltyAtRiskService, getSLAconplainceService } from "../services/reports.service";




function* fetchAccountInfo({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getContactAccountsService, reportConstructURLs('GET_ACCOUNT_INFO', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_INFO,
                payload: response.data.filter,
            });
        }
    } catch (error) {
        console.log("Error while fetching  DropDown Info data", error);
    }
}

function* fetchDropdownInfo(action) {
    try {
        const response = yield call(getDropdownInfoServive, reportConstructURLs('GET_DROPDOWN_INFO'));
        if (response.data) {
            yield put({
                type: actionTypes.DROPDOWN_INFO,
                payload: response.data.filter,
            });
        }
    } catch (error) {
        console.log("Error while fetching  DropDown Info data", error);
    }
}


function* fetchAverageHandleTime({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getAverageHandleTimeService, reportConstructURLs('GET_AVERAGE_HANDLE_TIME', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.AVERAGE_HANDLE_TIME,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchAbandonRate({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getAbandonTimeService, reportConstructURLs('GET_ABANDON_RATE', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.ABANDON_RATE,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchDigitalTrans({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getDigitalTransService, reportConstructURLs('GET_DIGITAL_TRANS', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.DIGITAL_TRANS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchCostPerContact({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getCostPerContactService, reportConstructURLs('GET_COST_PER_CONTACT', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.COST_PER_CONTACT,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchContactFTEData({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getContactFTEService, reportConstructURLs('GET_CONTACT_FTE_DATA', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.CONTACT_FTE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching FTE Region data", error);
    }
}


function* fetchPenaltyAtRisk({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getPenaltyAtRiskService, reportConstructURLs('GET_PENALTY_AT_RISK', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.PENALTY_AT_RISK,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchFirstContactResolution({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getFirstContactResolutionService, reportConstructURLs('GET_FIRST_CONTACT_RESOLUTION', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.FIRST_CONTACT_RESOLUTION,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchAttritionData({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getAttritionDataService, reportConstructURLs('GET_ATTRITION_DATA', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.ATTRITION_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchSLAcompliance({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getSLAconplainceService, reportConstructURLs('GET_SLA_COMPLIANCE', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.SLA_COMPLIANCE,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchCSATdata({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getCSATdataService, reportConstructURLs('GET_CSAT_DATA', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.CSAT_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}


function* fetchDeliverySupport(ContactVolumeID, filterID) {
    try {
        const response = yield call(getDeliverySupportService, reportConstructURLs('GET_DELIVERY_SUPPORT', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.DELIVERY_SUPPORT,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Region data", error);
    }
}



function* fetchContactVolumeInfo({ ContactVolumeID, filterID }) {
    try {
        const response = yield call(getContactVolumeDataService, reportConstructURLs('GET_CONTACT_VOLUME_DATA', ContactVolumeID, filterID));
        if (response.data) {
            yield put({
                type: actionTypes.CONTACT_VOLUME_INFO,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while fetching Contact Volume data", error);
    }
}

function* watchGetRequest() {
    // yield takeEvery(actionTypes.GET_CONTACT_REGIONS, fetchContactRegions)
    // yield takeEvery(actionTypes.GET_CONTACT_ACCOUNTS, fetchContactAccounts)
    yield takeEvery(actionTypes.GET_DROPDOWN_INFO, fetchDropdownInfo);
    yield takeEvery(actionTypes.GET_ACCOUNT_INFO, fetchAccountInfo);


    yield takeEvery(actionTypes.GET_AVERAGE_HANDLE_TIME, fetchAverageHandleTime);
    yield takeEvery(actionTypes.GET_ABANDON_RATE, fetchAbandonRate);
    yield takeEvery(actionTypes.GET_DIGITAL_TRANS, fetchDigitalTrans);
    yield takeEvery(actionTypes.GET_COST_PER_CONTACT, fetchCostPerContact);
    yield takeEvery(actionTypes.GET_CONTACT_FTE_DATA, fetchContactFTEData);
    yield takeEvery(actionTypes.GET_PENALTY_AT_RISK, fetchPenaltyAtRisk);
    yield takeEvery(actionTypes.GET_FIRST_CONTACT_RESOLUTION, fetchFirstContactResolution);
    yield takeEvery(actionTypes.GET_ATTRITION_DATA, fetchAttritionData);
    yield takeEvery(actionTypes.GET_SLA_COMPLIANCE, fetchSLAcompliance);
    yield takeEvery(actionTypes.GET_CSAT_DATA, fetchCSATdata);
    yield takeEvery(actionTypes.GET_DELIVERY_SUPPORT, fetchDeliverySupport);
    yield takeEvery(actionTypes.GET_CONTACT_VOLUME_INFO, fetchContactVolumeInfo);
}
export default function* reportsSagas() {
    yield all([watchGetRequest()]);
}