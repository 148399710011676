import { KeyboardDatePicker } from '@material-ui/pickers'
import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { OnboardSelect } from '../../Onboard/OnboardComponents/Dropdown/Select';

export function AddReleaseModalPopup({ isOpen, setShowModal, children }) {

    return (<>

        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '10em',
                    left: '20em',
                    width: '873px',
                    height: '500px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0px',
                    bottom: '0px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>
    </>)
}


const category = [
    { id: 1, value: "Production Releases" },
    { id: 2, value: "Planned Releases" }
]

function AddReleaseUpdate(props) {
    const editMode = props.editMode;
    const [publishedDate, setPublishedDate] = useState();
    
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
   
    const PublishedDateHandler = (date) => {
        setPublishedDate(formatDate(date));
        props.addDateToRelease('publishedDate', formatDate(date))
    }

    return (
        <>
            <header className='header_release'>{props.modalName}</header>
          { props.addReleaseValidation && <div style={{ color: '#ED4F32' }}>  Please ensure you have filled all the inputs with "*" mark</div>}
            <br />
            <form onSubmit={props.AddReleaseSubmit}>
                <section className='main_Scrollbar_container'>
                    <main className='input-container-two'>
                        <div className='container-one '>
                            <label HtmlFor='country'> Category *</label>
                            <OnboardSelect data={category} name='category' value='value' id='id'
                                QueryHandler={() => { }}
                                inputvalue={'Select'} editValue={props.editedValue}
                                addValueToQueue={props.addValueToSelect} />
                        </div>
                        <div className='container-one'>
                            <label style={{ marginBottom: '5px' }}>Published Date *  </label>
                            <KeyboardDatePicker
                                autoOk
                                variant="inline"
                                name='publishedDate'
                                helperText={''}
                                clearable
                                value={editMode ? props.editReleaseInfo.publishedDate :publishedDate}
                                placeholder="MM/DD/YYYY"
                                onChange={(date) => editMode ? props.handleEditReleaseDate('publishedDate', formatDate(date)) : PublishedDateHandler(date)}
                                minDate={new Date('01/01/2020')}
                                format="MM/DD/YYYY"
                                style={{ width: 380 }}
                                
                            />
                        </div>
                    </main>
                    <main className='input-container-one'>
                        <div className='container-six input_hold'>
                            <label>Title *</label>
                            {editMode ? <input type='text'
                                name='title'
                                placeholder='title'
                                value={editMode && props.editReleaseInfo.title}
                                onChange={props.handleAddRelease}
                            /> :
                                <input type='text'
                                    name='title'
                                    placeholder='title'
                                    onChange={props.handleAddRelease}
                                />
                            }
                        </div>
                    </main>
                    <main className='input-container-one'>
                        <div className='container-six input_hold'>
                            <label>Description *</label>
                            {editMode ? <input type='text'
                                name='description'
                                placeholder='description text'
                                value={props.editReleaseInfo.description}
                                onChange={props.handleAddRelease}
                            /> :
                                <input type='text'
                                    name='description'
                                    placeholder='description text'
                                    onChange={props.handleAddRelease} />
                            }
                        </div>
                    </main>
                    <main className='input-container-one'>
                        <div className='container-six input_hold'>
                            <label>URL</label>
                            {editMode ? <input type='text'
                                name='link'
                                placeholder='https://dxc.com'
                                value={props.editReleaseInfo.link}
                                onChange={props.handleAddRelease}
                            /> :
                                <input type='text'
                                    name='link'
                                    placeholder='https://dxc.com'
                                    onChange={props.handleAddRelease}
                                />
                            }
                        </div>
                    </main>
                </section>
                <main>
                    <div className='nav-bar__button' style={{ paddingTop: 20 }}>
                        <button type='submit' className='btn_adduser' >{props.buttonOne}</button>
                        <button className='btn_canceluser' onClick={props.handleAddReleaseCancel}>{props.buttonTwo}</button>
                    </div>
                </main>
            </form>
        </>
    )
}

export default AddReleaseUpdate