import React from 'react'

const TestingFile = () => {
  return (<>
    <div style={{padding:10,font:'normal normal 600 32px/43px Open Sans',color:'#5F249F'}}>
        ACCOUNTS
    </div>
    <div style={{font:'normal normal 400 22px/23px Open Sans'}}>Production Release Testing</div>
    </>
  )
}

export default TestingFile