import { all, call, put, take, takeEvery } from "redux-saga/effects";
import EDashboardActionTypes from "../../constants/eDashboard-actionTypes";
import EDashboardURLs from "../../constants/eDashboard-urls";
import { getKPIDataService, getComplianceDataService, getContactVolumeDataService, getCallTypeDataService, getHrzntBarChrtDataService,
getProfitLossAnalyticsDataService, getYearMonthRegionDrpdwnDataService, getTotalIncidentCardDataService, getEDashBrdNotfnTextMsgDataService } from "../../services/EDashboard/eDashboard.service";

function* fetchKPIDetails({dt, region, location}) {
  try {
    const response = yield call(getKPIDataService, EDashboardURLs('GET_KPI_DATA', dt, region, location));
    console.log("Fetching EDAshboard KPI Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_KPI_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard KPI Data");
    console.log(error);
  }
}

function* fetchComplianceDetails({dt, region, location}) {
  try {
    const response = yield call(getComplianceDataService, EDashboardURLs('GET_COMPLIANCE_DATA', dt, region, location));
    console.log("Fetching EDAshboard Compliance Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_COMPLIANCE_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard Compliance Data");
    console.log(error);
  }
}

function* fetchContactVolumeDetails({dt, region, location}) {
  try {
    const response = yield call(getContactVolumeDataService, EDashboardURLs('GET_CONTACT_VOLUME_DATA', dt, region, location));
    console.log("Fetching EDAshboard CONTACT_VOLUME Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_CONTACT_VOLUME_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard CONTACT_VOLUME Data");
    console.log(error);
  }
}

function* fetchCallTypeDetails({dt, region, location}) {
  try {
    const response = yield call(getCallTypeDataService, EDashboardURLs('GET_CALLTYPE_DATA', dt, region, location));
    console.log("Fetching EDAshboard CALL TYPE Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_CALLTYPE_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard CALL TYPE Data");
    console.log(error);
  }
}

function* fetchHorizontalBarChrtDetails({dt, region, location}) {
  try {
    const response = yield call(getHrzntBarChrtDataService, EDashboardURLs('GET_HRZNTLBARCHRT_DATA', dt, region, location));
    console.log("Fetching EDAshboard HRZNTLBARCHRT Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_HORIZONTAL_BAR_CHART_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard CALL TYPE Data");
    console.log(error);
  }
}

function* fetchProfitLossAnalyticDetails({dt, region, location}) {
  try {
    const response = yield call(getProfitLossAnalyticsDataService, EDashboardURLs('GET_PROFITLOSSANALYTICS_DATA', dt, region, location));
    console.log("Fetching EDAshboard ProfitLoss Analytics Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_PROFIT_LOSS_ANALYTICS_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard ProfitLoss Analytics Data");
    console.log(error);
  }
}

function* fetchYearMonthRegionDropdownDetails(action){
  try {
    const response = yield call(getYearMonthRegionDrpdwnDataService, EDashboardURLs('GET_YEARMONTHREGION_DATA'));
    console.log("Fetching EDAshboard Year Month Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_YEARMONTH_REGION_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard Year Month Data");
    console.log(error);
  }
}

function* fetchTotalIncidentCardsDetails({dt}){
  try {
    const response = yield call(getTotalIncidentCardDataService, EDashboardURLs('GET_TOTALINCIDENT_CARDS_DATA', dt, null));
    console.log("Fetching EDAshboard GET_TOTALINCIDENT_CARDS_DATA API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_INCIDENTS_CARDS_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching E Dashboard GET_TOTALINCIDENT_CARDS_DATA");
    console.log(error);
  }
}

function* fetchEDashbBrdNotfnMsg(){
  try {
    const response = yield call(getEDashBrdNotfnTextMsgDataService, EDashboardURLs('GET_EDASHBRD_NOTFN_TEXT'));
    console.log("Fetching EDAshboard Notification Text Message API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: EDashboardActionTypes.UPDATE_EDASHBOARD_NOTIFICATION_TEXT,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching EDAshboard Notification Text Message");
    console.log(error);
  }
}

function* watchGetRequest() {
  yield takeEvery(EDashboardActionTypes.GET_KPI_DATA, fetchKPIDetails); 
  yield takeEvery(EDashboardActionTypes.GET_COMPLIANCE_DATA,  fetchComplianceDetails);
  yield takeEvery(EDashboardActionTypes.GET_CONTACT_VOLUME_DATA, fetchContactVolumeDetails); 
  yield takeEvery(EDashboardActionTypes.GET_CALLTYPE_DATA, fetchCallTypeDetails);
  yield takeEvery(EDashboardActionTypes.GET_HORIZONTAL_BAR_CHART_DATA, fetchHorizontalBarChrtDetails);
  yield takeEvery(EDashboardActionTypes.GET_PROFIT_LOSS_ANALYTICS_DATA, fetchProfitLossAnalyticDetails);
  yield takeEvery(EDashboardActionTypes.GET_YEARMONTH_REGION_DATA, fetchYearMonthRegionDropdownDetails);
  yield takeEvery(EDashboardActionTypes.GET_INCIDENTS_CARDS_DATA, fetchTotalIncidentCardsDetails);
  yield takeEvery(EDashboardActionTypes.GET_EDASHBOARD_NOTIFICATION_TEXT, fetchEDashbBrdNotfnMsg);
}

export default function* eDashboardSagas() {
  yield all([watchGetRequest()]);
}
