import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from "react-redux";
import './account-details.css';
import viewIcon from "../../../assets/images/ViewDetails_Icon.png";
import AboveRequiredTargetIcon from "../../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";
import {getRegionalSiteGlobalSummaryData, updateRegionName, updateRegionID} from "../../../actions/regionalSite.action";
import { updateSelectedAccountName, saveSelectedMenuItem , saveAccountLocationName} from "../../../actions/tabMenu.action";
const AccountDetails = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isoDateString = useSelector((state) => state.userReducer.cal_isostring_frmt);
    const { servicedesks, regionid, region } = props.data ? props.data : [];
    let customers = [];
    if(servicedesks && servicedesks.length > 0)
    customers = servicedesks[0]?.customers;

    const selectedAccCustName = useSelector((state) => state.regionalSiteReducer.rgnCustName);
    const selectedAccCustID = useSelector((state) => state.regionalSiteReducer.rgnCustId);    
   

    const getArrowIcon = (color) => {
        let colr;
        switch(color){
            case 'yellow' : { colr = AboveMinimalTargetIcon; break;}
            case 'green' : { colr = AboveRequiredTargetIcon; break;}
            case 'red' : { colr = BelowMinimalTargetIcon; break;}
            default : {}
        }
        return colr;
    };

    const viewRegionalHandler = (regionID) => {
        dispatch(updateRegionID(regionID));
        dispatch(updateRegionName(region));
        dispatch(getRegionalSiteGlobalSummaryData(selectedAccCustID,0,regionID,isoDateString));
        history.push('/regional_summary');
    }

    const handleViewDTMReport = (serviceID, serviceDesk) => {
        if(serviceID){
            window.localStorage.setItem('ServiceDeskID', serviceID);           
        }
        if(selectedAccCustID){
            window.localStorage.setItem('CustomerID', selectedAccCustID);
        }
        dispatch(updateSelectedAccountName(selectedAccCustName));
        dispatch(saveSelectedMenuItem("reports"));
        dispatch(saveAccountLocationName(serviceDesk));
        history.push("/reports");
    }

    return (         
        <div className="global-acc-cntr" style={{marginTop: '20px'}}>     
            <div className="row global-acc-label">
                <div className='col-sm-12 col-md-6 col-lg-6'>
                    {props?.data?.region}
                </div>
                <div className='col-sm-12 col-md-6 col-lg-6 text-end'>
                    <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-8'></div>
                        <div className='col-sm-12 col-md-6 col-lg-1'>
                            <span className='dash'></span>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                        <span className='rgn-summry-btn' onClick={()=>viewRegionalHandler(regionid)}>View Regional Summary</span>
                        </div>
                    </div>                 
                </div>               
            </div>       
            <div className="row acc-table-header-row">
            <div className="col-md-1 redu-width">Site Name</div>
            <div className="col-md-1 redu-width">SLAs Met</div>
            <div className="col-md-1 redu-width">SLA Over Delivery</div>           
            <div className="col-md-1 redu-width"></div>
            </div>
            <div style={{height: '100px', overflowY : 'auto', overflowX: 'hidden', marginTop: '10px'}}>
            { customers.map((itm,idx)=>{

            return(
                
            <div className="row acc-table-row" key={idx}>                                
                <div className="col-md-1 redu-width">{servicedesks[0]?.servicedesk}</div>
                <div className="col-md-1 redu-width mrgn-5">
                {itm?.items?.slamet?.value}
                <span>
                    <img
                    src={getArrowIcon(itm?.items?.slamet?.color)}
                    className="legend-space"
                    alt=""
                    />
                </span>
                </div>
                <div className="col-md-1 redu-width">
                {itm?.items?.slaovd?.value}
                <span>
                    <img
                    className="legend-space"
                    src={getArrowIcon(itm?.items?.slaovd?.color)}
                    alt=""
                    />
                </span>
                </div>
                <div className="col-md-1 redu-width">
                <img src={viewIcon} onClick={()=>handleViewDTMReport(servicedesks[0]?.servicedeskid, servicedesks[0]?.servicedesk)} className="view-img" alt="viewImg" />
                </div>
                </div>
                );
            }) }
            </div>
        </div>   
    );
};

export default AccountDetails;