import {ScoreCardActionTypes as actionTypes} from "../constants/ScoreCard&TeamKPI-actionTypes";

const initialState = {
    ScorCardData:{},
    ScoreCardPerformanceData:[],
    ScorCardOffCanvasData:[],
    agentsKPISummaryData:[],
    teamleaderAgents:[],
    teamleaderAccounts:[],
    teamleaderAccountsView:[],
    teamleaderDates:[],
    agentsKPIAccountsData:[],
    teamKPISummaryData:[],
    agentsGraphData:[],
    selectedTab:'',
    selectedDate:'',
    selectedAgentScoreCardDate:''
}

const ScoreCardReducer = (state = initialState, action) => {
    
    switch (action.type) {
       
            case actionTypes.GET_SCORECARD_DATA:
            case actionTypes.GET_SCORECARD_PERFORMANCE_DATA:
            case actionTypes.GET_SCORECARD_OFFCANVAS_DATA:
            case actionTypes.GET_AGENTS_KPI_SUMMARY_DATA:
            case actionTypes.GET_TEAMLEADER_AGENTS:
            case actionTypes.GET_TEAMLEADER_ACCOUNTS:
            case actionTypes.GET_TEAMLEADER_ACCOUNTS_VIEW:
            case actionTypes.GET_TEAMLEADER_DATES:
            case actionTypes.GET_AGENTS_KPI_ACCOUNTS_DATA:
            case actionTypes.GET_TEAM_KPI_SUMMARY_DATA:
            case actionTypes.GET_AGENTS_GRAPH_DATA:
                 return { ...state };
            case actionTypes.SCORECARD_DATA:
                    return { ...state, ScorCardData: action.payload };
            case actionTypes.SCORECARD_PERFORMANCE_DATA:
                    return { ...state, ScoreCardPerformanceData: action.payload };
            case actionTypes.SCORECARD_OFFCANVAS_DATA:
                    return { ...state, ScorCardOffCanvasData: action.payload };
            case actionTypes.AGENTS_KPI_SUMMARY_DATA:
                    return { ...state, agentsKPISummaryData: action.payload };
            case actionTypes.TEAMLEADER_AGENTS:
                    return { ...state, teamleaderAgents: action.payload };
            case actionTypes.TEAMLEADER_ACCOUNTS:
                    return { ...state, teamleaderAccounts: action.payload };
            case actionTypes.TEAMLEADER_ACCOUNTS_VIEW:
                    return { ...state, teamleaderAccountsView: action.payload };
            case actionTypes.TEAMLEADER_DATES:
                    return { ...state, teamleaderDates: action.payload };
            case actionTypes.AGENTS_KPI_ACCOUNTS_DATA:
                    return { ...state, agentsKPIAccountsData: action.payload };
            case actionTypes.TEAM_KPI_SUMMARY_DATA:
                    return { ...state, teamKPISummaryData: action.payload };
            case actionTypes.AGENTS_GRAPH_DATA:
                    return { ...state, agentsGraphData: action.payload };
            case actionTypes.SAVE_SELECTED_TAB: {
                    return { ...state, selectedTab: action.payload }
                    }
            case actionTypes.SAVE_SELECTED_DATE: {
                    return { ...state, selectedDate: action.payload }
                    }
            case actionTypes.SAVE_SELECTED_AGENTSCORECARD_DATE: {
                    return { ...state, selectedAgentScoreCardDate: action.payload }
                        }
          
        default: return state

    }
}

export default ScoreCardReducer ;