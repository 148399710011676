import React, { useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './EmployeeAssign.css';
import DATA from './assign.json';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import AvailableEmployeesHandle from './AvailableEmployees';
import MappedEmployeesHandle from './MappedEmployees';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';



function EmployeeAssign() {
  const history = useHistory();
  let staffAdminActiveTab = useSelector((state) => state.adminAccountReducer.staffAdminActiveTab);
  return (
    <>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} value2={()=>history.push('/admin')} label2='Administration'
        isActive3={false} label3='Staff-Administration' />
      <section className='employee_assign_container'>
        <div className='header_staff'>Staff Administration</div>
        <br />
        <Tabs defaultActiveKey={staffAdminActiveTab ? staffAdminActiveTab : 'mapped'}>
          <Tab eventKey="mapped" title="Mapped Employees" key="mapped">
            <MappedEmployeesHandle />
          </Tab>
          <Tab eventKey="available" title=' Available Employees' key="available">
            <AvailableEmployeesHandle />
          </Tab>
        </Tabs>
      </section>
    </>
  )
}

export default EmployeeAssign;



