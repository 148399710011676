import { all, call, put, takeEvery } from "redux-saga/effects";
import { updateAdminSlaTableDataSuccess, updateAdminSupportTableDataSuccess, updateAdminUpdateSuccess, updateQueueRequestSuccess, updateSLARequestSuccess, updateSupportRequestSuccess, updateValidToDateSuccess } from './../../actions/Administration/admin.action'
import AdminAccountURLS from  "../../constants/Administration/admin-account-urls";
import adminActionTypes from "../../constants/Administration/admin-action-types";
import { getAdminAccountsService, getAdminQueueTableDataService, getAdminVoiceSlaTableDataService, getAdminVoiceSupportTableDataService, getPreviewAdminService, updateAdminQueueTableDataService, updateAdminSlaTableDataService, updateAdminSupportTableDataService, updateAdminUpdateService, updateQueueAdminRequestService, updateSLAAdminRequestService, updateSupportAdminRequestService, updateValidToDateService } from './../../services/Administration/admin.service'
import { updateAdminQueueTableDataSuccess } from './../../actions/Administration/admin.action'
import { getAdminSlaTableDataService, getAdminSupportTableDataService } from './../../services/Administration/admin.service'

function* fetchAdminAccountsData() {
    try {
        const response = yield call(getAdminAccountsService, AdminAccountURLS('GET_ADMIN_ACCOUNTS'));
        if (response.data) {
            yield put({
                type: adminActionTypes.ADMIN_ACCOUNTS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Admin data", error);
    }
}

function* updateValidToDate({ payload: id }) {
    try {
        const response = yield call(updateValidToDateService, AdminAccountURLS('UPDATE_VALIDTO_DATE'), id);
        if (response.data) {
            yield put(

                updateValidToDateSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchAdminQueueTableData({ account, site }) {
    let items = {
        account: account,
        site: site,
    }
    try {
        const response = yield call(getAdminQueueTableDataService, AdminAccountURLS('GET_ADMIN_QUEUE_TABLE_DATA', items));
        if (response) {
            yield put({
                type: adminActionTypes.ADMIN_QUEUE_TABLE_DATA,
                payload: response.data,
            });
        }
        console.log(response.data)
    } catch (error) {
        console.log("Error while Admin data", error);
    }
}

function* updateAdminQueueTableData({ payload: id }) {
    try {
        const response = yield call(updateAdminQueueTableDataService, AdminAccountURLS('UPDATE_ADMIN_QUEUE_TABLE_DATA'), id);
        if (response.data) {
            yield put(

                updateAdminQueueTableDataSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchAdminSlaTableData({ account, site, tab, card }) {
    let items = {
        account: account,
        site: site,
        tab: tab,
        card: card
    }
    try {
        const response = yield call(getAdminSlaTableDataService, AdminAccountURLS('GET_ADMIN_SLA_TABLE_DATA', items));
        if (response.data) {
            yield put({
                type: adminActionTypes.ADMIN_SLA_TABLE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Admin data", error);
    }
}

function* updateAdminSlaTableData({ payload: id }) {
    try {
        const response = yield call(updateAdminSlaTableDataService, AdminAccountURLS('UPDATE_ADMIN_SLA_TABLE_DATA'), id);
        if (response) {
            yield put(

                updateAdminSlaTableDataSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchAdminSupportTableData({ account, site, tab, card }) {
    let items = {
        account: account,
        site: site,
        tab: tab,
        card: card
    }
    try {
        const response = yield call(getAdminSupportTableDataService, AdminAccountURLS('GET_ADMIN_SUPPORT_TABLE_DATA', items));
        if (response.data) {
            yield put({
                type: adminActionTypes.ADMIN_SUPPORT_TABLE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Admin data", error);
    }
}

function* updateAdminSupportTableData({ payload: id }) {
    try {
        const response = yield call(updateAdminSupportTableDataService, AdminAccountURLS('UPDATE_ADMIN_SUPPORT_TABLE_DATA'), id);
        if (response) {
            yield put(
                updateAdminSupportTableDataSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchAdminVoiceSlaTableData({ account, site }) {
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getAdminVoiceSlaTableDataService, AdminAccountURLS('GET_ADMIN_VOICE_SLA_TABLE_DATA', items));
        if (response.data) {
            yield put({
                type: adminActionTypes.ADMIN_VOICE_SLA_TABLE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Admin data", error);
    }
}

function* fetchAdminVoiceSupportTableData({ account, site }) {
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getAdminVoiceSupportTableDataService, AdminAccountURLS('GET_ADMIN_VOICE_SUPPORT_TABLE_DATA', items));
        if (response.data) {
            yield put({
                type: adminActionTypes.ADMIN_VOICE_SUPPORT_TABLE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Admin data", error);
    }
}

function* updateAdminRequestQueue({ payload: id }) {
    try {
        const response = yield call(updateQueueAdminRequestService, AdminAccountURLS('QUEUE_ADMIN_REQUEST_START'), id);
        if (response) {
            yield put(
                updateQueueRequestSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* updateAdminRequestSLA({ payload: id }) {
    try {
        const response = yield call(updateSLAAdminRequestService, AdminAccountURLS('SLA_ADMIN_REQUEST_START'), id);
        if (response) {
            yield put(
                updateSLARequestSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* updateAdminRequestSupport({ payload: id }) {
    try {
        const response = yield call(updateSupportAdminRequestService, AdminAccountURLS('SUPPORT_ADMIN_REQUEST_START'), id);
        if (response) {
            yield put(
                updateSupportRequestSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* updateAdminUpdateData({ payload: id }) {
    try {
        const response = yield call(updateAdminUpdateService, AdminAccountURLS('UPDATE_ADMIN_UPDATE'), id);
        if (response.data) {
            yield put(
               
                updateAdminUpdateSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchPreviewAdminData({ account, site }) {
    
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getPreviewAdminService, AdminAccountURLS('GET_PREVIEW_ADMIN', items));
        console.log('adminpreviewresponce',response)
        if (response.data) {
            yield put({
                type: adminActionTypes.PREVIEW_ADMIN,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchRoleBasedView() {
    try {
        const response = yield call(getAdminAccountsService, AdminAccountURLS('GET_ROLE_BASED_VIEW'));
        if (response.data) {
            yield put({
                type: adminActionTypes.ROLE_BASED_VIEW,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while GET_ROLE_BASED_VIEW", error);
    }
}

function* watchGetRequest() {
    yield takeEvery(adminActionTypes.GET_ADMIN_ACCOUNTS, fetchAdminAccountsData)
    yield takeEvery(adminActionTypes.UPDATE_VALIDTO_DATE_START, updateValidToDate);
    yield takeEvery(adminActionTypes.GET_ADMIN_QUEUE_TABLE_DATA, fetchAdminQueueTableData)
    yield takeEvery(adminActionTypes.UPDATE_ADMIN_QUEUE_TABLE_DATA_START, updateAdminQueueTableData);

    yield takeEvery(adminActionTypes.GET_ADMIN_SLA_TABLE_DATA, fetchAdminSlaTableData)
    yield takeEvery(adminActionTypes.UPDATE_ADMIN_SLA_TABLE_DATA_START, updateAdminSlaTableData);

    yield takeEvery(adminActionTypes.GET_ADMIN_SUPPORT_TABLE_DATA, fetchAdminSupportTableData)
    yield takeEvery(adminActionTypes.UPDATE_ADMIN_SUPPORT_TABLE_DATA_START, updateAdminSupportTableData);

    yield takeEvery(adminActionTypes.GET_ADMIN_VOICE_SLA_TABLE_DATA, fetchAdminVoiceSlaTableData)
    yield takeEvery(adminActionTypes.GET_ADMIN_VOICE_SUPPORT_TABLE_DATA, fetchAdminVoiceSupportTableData);


    yield takeEvery(adminActionTypes.QUEUE_ADMIN_REQUEST_START, updateAdminRequestQueue);
    yield takeEvery(adminActionTypes.SLA_ADMIN_REQUEST_START, updateAdminRequestSLA);
    yield takeEvery(adminActionTypes.SUPPORT_ADMIN_REQUEST_START, updateAdminRequestSupport);
    yield takeEvery(adminActionTypes.UPDATE_ADMIN_UPDATE_START, updateAdminUpdateData);
     yield takeEvery(adminActionTypes.GET_PREVIEW_ADMIN, fetchPreviewAdminData)

     yield takeEvery(adminActionTypes.GET_ROLE_BASED_VIEW, fetchRoleBasedView)
}
export default function* adminAccountSagas() {
    yield all([watchGetRequest()]);
}

