import { all, call, put, takeEvery } from "redux-saga/effects";
import {  updateAccountEmployeeSuccess, updateIdAssignmentSuccess, updateNewUserRoleSuccess, updateQueueAssignmentSuccess, updateSlaAssignmentSuccess, updateSupervisorEmployeeMappingSuccess, updateVolumeAssignmentStart, updateVolumeAssignmentSuccess } from './../../actions/Administration/staffAdmin.action'
import { STAFF_ADMIN_ACTIONTYPES as actionTypes } from "../../constants/Administration/staffAdmin-actionTypes";
import StaffAdminURLS from "../../constants/Administration/StaffAdmin-urls";
import { getAccountAssignmentService, getAccountSLAService, getAvailableEmployeesService, getIdAssignmentService, getMappedEmployeesService, getQueueAssignmentService, getRoleCategoryService, getSLAAccountsService, getSlaAssignmentService, getStaffAccountsService, getStaffPreviewService, getSupervisorListService, getValidSupervisorService, getVolumeAssignmentService, updateAccountEmployeeService, updateIdAssignmentService, updateNewUserRoleService, updateSlaAssignmentService, updateSupervisorEmployeeMappingService, updateVolumeAssignmentService } from "../../services/Administration/staffAdmin.service";
import { updateQueueAssignmentService, getQueueDataService, getIDAccountsService, getAccountSLAForEditService, getVolumeTemplatesService, getVolumeTemplatesForEditService } from './../../services/Administration/staffAdmin.service';



function* fetchAvailableEmployeesData({ items }) {

    try {
        const response = yield call(getAvailableEmployeesService, StaffAdminURLS('GET_AVAILABLE_EMPLOYEES', items));
        if (response.data) {
            yield put({
                type: actionTypes.AVAILABLE_EMPLOYEES,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchSupervisorList() {

    try {
        const response = yield call(getSupervisorListService, StaffAdminURLS('GET_SUPERVISOR_LIST'));
        if (response.data) {
            yield put({
                type: actionTypes.SUPERVISOR_LIST,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* updateNewUserRole({ payload: id }) {
    try {
        const response = yield call(updateNewUserRoleService, StaffAdminURLS('UPDATE_NEW_USER_ROLE'), id);
        if (response.data) {
            yield put(
                updateNewUserRoleSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* updateSupervisorEmployeeMapping({ payload: id }) {
    try {
        const response = yield call(updateSupervisorEmployeeMappingService, StaffAdminURLS('UPDATE_SUPERVISOR_EMPLOYEE_MAPPING'), id);
        if (response.status === 200) {
            yield put(
                updateSupervisorEmployeeMappingSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* updateAccountEmployee({ payload: id }) {
    try {
        const response = yield call(updateAccountEmployeeService, StaffAdminURLS('UPDATE_ACCOUNT_EMPLOYEE'), id);
        if (response.data) {
            yield put(
                updateAccountEmployeeSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchRoleCategory() {

    try {
        const response = yield call(getRoleCategoryService, StaffAdminURLS('GET_ROLE_CATEGORY'));
        if (response.data) {
            yield put({
                type: actionTypes.ROLE_CATEGORY,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchStaffAccounts({ empId, supId }) {
    let items = { empId, supId }
    try {
        const response = yield call(getStaffAccountsService, StaffAdminURLS('GET_STAFF_ACCOUNTS', items));
        if (response.data) {
            yield put({
                type: actionTypes.STAFF_ACCOUNTS,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchAccountAssignment({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    console.log(items)
    try {
        const response = yield call(getAccountAssignmentService, StaffAdminURLS('GET_ACCOUNT_ASSIGNMENT', items));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_ASSIGNMENT,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchQueueAssignment({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getQueueAssignmentService, StaffAdminURLS('GET_QUEUE_ASSIGNMENT', items));
        if (response.data) {
            yield put({
                type: actionTypes.QUEUE_ASSIGNMENT,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchIdAssignment({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getIdAssignmentService, StaffAdminURLS('GET_ID_ASSIGNMENT', items));
        if (response.data) {
            yield put({
                type: actionTypes.ID_ASSIGNMENT,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchSlaAssignment({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getSlaAssignmentService, StaffAdminURLS('GET_SLA_ASSIGNMENT', items));
        if (response.data) {
            yield put({
                type: actionTypes.SLA_ASSIGNMENT,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchVolumeAssignment({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getVolumeAssignmentService, StaffAdminURLS('GET_VOLUME_ASSIGNMENT', items));
        if (response.data) {
            yield put({
                type: actionTypes.VOLUME_ASSIGNMENT,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* updateQueueAssignment({ payload: id }) {
    try {
        const response = yield call(updateQueueAssignmentService, StaffAdminURLS('UPDATE_QUEUE_ASSIGNMENT'), id);
        if (response.data) {
            yield put(
                updateQueueAssignmentSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Queue Assignment", error);
    }
}

function* updateIdAssignment({ payload: id }) {
    try {
        const response = yield call(updateIdAssignmentService, StaffAdminURLS('UPDATE_ID_ASSIGNMENT'), id);
        if (response.data) {
            yield put(
                updateIdAssignmentSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Id Assignment", error);
    }
}

function* updateSlaAssignment({ payload: id }) {
    try {
        const response = yield call(updateSlaAssignmentService, StaffAdminURLS('UPDATE_SLA_ASSIGNMENT'), id);
        if (response.data) {
            yield put(
                updateSlaAssignmentSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Queue Assignment", error);
    }
}

function* updateVolumeAssignment({ payload: id }) {
    try {
        const response = yield call(updateVolumeAssignmentService, StaffAdminURLS('UPDATE_VOLUME_ASSIGNMENT'), id);
        if (response.data) {
            yield put(
                updateVolumeAssignmentSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating volume Assignment", error);
    }
}

function* fetchStaffPreview({ empId, supId }) {
    let items = { empId, supId }
    try {
        const response = yield call(getStaffPreviewService, StaffAdminURLS('GET_STAFF_PREVIEW', items));
        if (response.data) {
            yield put({
                type: actionTypes.STAFF_PREVIEW,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchMappedEmployees() {

    try {
        const response = yield call(getMappedEmployeesService, StaffAdminURLS('GET_MAPPED_EMPLOYEES'));
        if (response.data) {
            yield put({
                type: actionTypes.MAPPED_EMPLOYEES,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchQueueData({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getQueueDataService, StaffAdminURLS('GET_QUEUE_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.QUEUE_DATA,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchAccountSLA({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getAccountSLAService, StaffAdminURLS('GET_ACCOUNT_SLA', items));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_SLA,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchVolumeTemplates({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getVolumeTemplatesService, StaffAdminURLS('GET_VOLUME_TEMPLATES', items));
        if (response.data) {
            yield put({
                type: actionTypes.VOLUME_TEMPLATES,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchIDAccounts({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getIDAccountsService, StaffAdminURLS('GET_ID_ACCOUNTS', items));
        if (response.data) {
            yield put({
                type: actionTypes.ID_ACCOUNTS,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchSLAAccounts({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getSLAAccountsService, StaffAdminURLS('GET_SLA_ACCOUNTS', items));
        if (response.data) {
            yield put({
                type: actionTypes.SLA_ACCOUNTS,
                payload: response.data,
            });
        }
        console.log('??????????????',response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchValidSupervisor() {

    try {
        const response = yield call(getValidSupervisorService, StaffAdminURLS('GET_VALID_SUPERVISOR'));
        if (response.data) {
            yield put({
                type: actionTypes.VALID_SUPERVISOR,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchAccountSLAForEdit({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getAccountSLAForEditService, StaffAdminURLS('GET_ACCOUNT_SLA_FOREDIT', items));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_SLA_FOREDIT,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* fetchVolumeTemplatesForEdit({ empId, supId }) {
    let items = {
        empId: empId,
        supId: supId
    }
    try {
        const response = yield call(getVolumeTemplatesForEditService, StaffAdminURLS('GET_VOLUME_TEMPLATES_FOREDIT', items));
        if (response.data) {
            yield put({
                type: actionTypes.VOLUME_TEMPLATES_FOREDIT,
                payload: response.data,
            });
        }
        console.log(response.data);
    } catch (error) {
        console.log(error);
    }
}

function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_AVAILABLE_EMPLOYEES, fetchAvailableEmployeesData);
    yield takeEvery(actionTypes.GET_SUPERVISOR_LIST, fetchSupervisorList);
    yield takeEvery(actionTypes.UPDATE_NEW_USER_ROLE_START, updateNewUserRole);
    yield takeEvery(actionTypes.UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_START, updateSupervisorEmployeeMapping);
    yield takeEvery(actionTypes.UPDATE_ACCOUNT_EMPLOYEE_START, updateAccountEmployee);
    yield takeEvery(actionTypes.GET_ROLE_CATEGORY, fetchRoleCategory);
    yield takeEvery(actionTypes.GET_STAFF_ACCOUNTS, fetchStaffAccounts);
    yield takeEvery(actionTypes.GET_ACCOUNT_ASSIGNMENT, fetchAccountAssignment);
    yield takeEvery(actionTypes.GET_QUEUE_ASSIGNMENT, fetchQueueAssignment);
    yield takeEvery(actionTypes.GET_ID_ASSIGNMENT, fetchIdAssignment);
    yield takeEvery(actionTypes.GET_SLA_ASSIGNMENT, fetchSlaAssignment);
    yield takeEvery(actionTypes.GET_VOLUME_ASSIGNMENT, fetchVolumeAssignment);
    yield takeEvery(actionTypes.UPDATE_QUEUE_ASSIGNMENT_START, updateQueueAssignment);
    yield takeEvery(actionTypes.UPDATE_ID_ASSIGNMENT_START, updateIdAssignment);
    yield takeEvery(actionTypes.UPDATE_SLA_ASSIGNMENT_START, updateSlaAssignment);
    yield takeEvery(actionTypes.UPDATE_VOLUME_ASSIGNMENT_START, updateVolumeAssignment);
    yield takeEvery(actionTypes.GET_STAFF_PREVIEW, fetchStaffPreview);
    yield takeEvery(actionTypes.GET_MAPPED_EMPLOYEES, fetchMappedEmployees);
    yield takeEvery(actionTypes.GET_QUEUE_DATA, fetchQueueData);
    yield takeEvery(actionTypes.GET_ACCOUNT_SLA, fetchAccountSLA);
    yield takeEvery(actionTypes.GET_VOLUME_TEMPLATES, fetchVolumeTemplates);
    yield takeEvery(actionTypes.GET_ID_ACCOUNTS, fetchIDAccounts);
    yield takeEvery(actionTypes.GET_SLA_ACCOUNTS, fetchSLAAccounts);
    yield takeEvery(actionTypes.GET_VALID_SUPERVISOR,fetchValidSupervisor );
    yield takeEvery(actionTypes.GET_ACCOUNT_SLA_FOREDIT, fetchAccountSLAForEdit);
    yield takeEvery(actionTypes.GET_VOLUME_TEMPLATES_FOREDIT, fetchVolumeTemplatesForEdit);
}
export default function* StaffAdminSagas() {
    yield all([watchGetRequest()]);
}