import React from 'react'
import './Development_env.css'

const headers = [
  {id:'1', caption: "Instance"},
  {id:'2', caption: "Role"},
  {id:'3', caption: "FQDN"},
  {id:'4', caption: "Operating System"}
]
const DATA =[
  {id:'1', 'Instance':'DEV', 'Role':'IIS Web Server', 'FQDN':'zc4w02443.itcs.houston.dxccorp.net', 'OperatingSystem':'Server 2016 R2'},
  {id:'2', 'Instance':'UAT', 'Role':'SQL Database', 'FQDN':'zc4w02439.itcs.houston.dxccorp.net', 'OperatingSystem':'Server 2019'},
  {id:'3', 'Instance':'UAT', 'Role':'IIS Web Server', 'FQDN':'zc4w02443.itcs.houston.dxccorp.net', 'OperatingSystem':'Server 2016 R2'}
]
const CODE_REPOSITORY ='https://github.dxc.com/MWSGSD/GSD-Insight-Code';

const DevelopmentEnvironment = () => {
  return (
    <> 
       <section className='deve_env'>
       <div style={{width:1200,margin:'auto'}}>
        <div id='title_env'>Development Environment</div>
        <div style={{paddingBottom:'20px'}}>The GSD Insight solution development environment is setup as described:</div>
        <div style={{paddingLeft:20}}><ul>
          <li className='env_ul_list'>The code development is done on the developers PC</li>
          <li className='env_ul_list'>The generated code & build files are uploaded to the GitHub <span id='link' onClick={() => window.open(CODE_REPOSITORY, '_blank')}>Code Repository</span></li>
          <li className='env_ul_list'>The build files are copied manually to the DEV & UAT server</li>
        </ul></div>
        <div style={{paddingBottom:'20px',paddingTop:20}}><b>Note:</b> Important to notice that UAT and DEV instances are running on the same server, but under different web instances So changes to any of the instance does not affect the other one! Both instance is connected to the same database server / databases</div>
        <main>
        <table className='env_table'>
                <thead>
                    <tr>
                        {headers.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='env_header'>{header.caption}</span></th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {
                        DATA.map((data, index) => (
                            <tr key={data.id}>
                                <td className='env_td'>{data?.Instance}</td>
                                <td className='env_td'>{data?.Role}</td>
                                <td className='env_td'>{data?.FQDN}</td>
                                <td className='env_td'>{data?.OperatingSystem}</td>
                               </tr>
                        ))
                    }
                </tbody>
            </table>
        </main>
        </div>
       </section>    

        </>
  )
}

export default DevelopmentEnvironment