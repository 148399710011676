import React, { useState } from 'react';
import ToolTip from 'react-power-tooltip';

function Button( props) {
    const [showTooltip, setShowTooltip] = useState(false);

  return <>
      <button  className={props.className} type={props.type} {...props} onClick={props.onClick}
                style={{width:'50px', height:'50px', position: 'relative' }}
                onMouseOver={() => setShowTooltip(true)} 
                onMouseLeave={() => setShowTooltip(false)} >
              <img src={props.icon} alt="icon" />
              <ToolTip show={showTooltip} position="top bottom">
                    <span >{props.item}</span>
               </ToolTip>
            </button>
  </>;
}

export default Button;
