import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import './FileUpload.css';
import { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";

import RequestDropdown from "../../Onboard/OnboardComponents/Dropdown/RequestDropdown";
import DatePickerHandle from "../../Onboard/OnboardComponents/KeyboardDatePicker";
import deleteIcon from './../../../assets/images/Delete_Icon.png';
import CloseWindow_Normal from '../../../assets/images/CloseWindow_Normal.png';
import { getFileUploadData, updateFileUploadDataStart } from "../../../actions/uploads.action";
import axios from "axios";

ReactModal.setAppElement('#root')
export function FileUploadModalPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    background: ' #63666A66 0% 0% no-repeat padding-box',
                    opacity: '1',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-30%',
                    transform: 'translate(-50%, -50%)',
                    margin: '20px',
                    padding: '30px',

                }
            }}>
            {children}
        </ReactModal>

    </>)
}

const PERIOD = [
    { id: 1, value: 'Day' },
    { id: 2, value: 'Week' },
    { id: 3, value: 'Custom Period' },
    { id: 4, value: 'Month' }
]
export function FileUploadFragment(props) {
    const dispatch = useDispatch();

    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
    const SelectedVoiceNonVoiceTab = useSelector((state) => state.uploadReducer.SelectedVoiceNonVoiceTab);
    const SelectedVoiceNonVoiceItem = useSelector((state) => state.uploadReducer.SelectedVoiceNonVoiceItem);
   

    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const [month, setMonth] = useState(new Date(''));
    const [day, setDay] = useState(new Date(''));

    function formatDate(date) {
        var d = new Date(date)
        let dt = d.toLocaleDateString('en-us', { month: "short", year: "numeric" }),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [month, day, year].join('/');
    }
    function formatMonthDate(date) {
        return new Date(date).toLocaleDateString('en-us', { month: "long", year: "numeric" })
    }


    // useEffect(() => {
    //     if (props.editMode) {
    //         setSelectedValue(props.editFileUpload.selectedValue[0])
    //         switch(props.editFileUpload.selectedValue[0]){
    //             case 'Month':
    //                 {
    //                     setMonth(props.editFileUpload.selectedValue[1])
    //                     break;
    //                 }
    //              case 'Day' :
    //                 {
    //                     setDay(props.editFileUpload.selectedValue[1])
    //                     break;
    //                 }
    //             default:{
    //                 setValidFrom(props.editFileUpload.selectedValue[1])
    //                 setValidTo(props.editFileUpload.selectedValue[2])
    //             }
    //         }
    //         let temp_Obj = {
    //             fileSize: props.editFileUpload.fileSize,
    //             fileName: props.editFileUpload.fileName,
    //             fileType: props.editFileUpload.fileType,
    //             uploadedDate: props.editFileUpload.uploadedDate,
    //             uploader: props.editFileUpload.uploader
    //         }
    //         setUploadedFile(temp_Obj)
    //         setDatacard(true)
    //     }
    // }, [props.editMode])

    const [addFileUpload, setAddFileUpload] = useState({});
    const addDateToFileUpload = (period) => {
        const newFormData = { ...addFileUpload };
        newFormData['period'] = period;
        setAddFileUpload(newFormData)
    }




    const [selectedValue, setSelectedValue] = useState('')
    const DisplayPeriodHandle = () => {
        switch (selectedValue) {
            case 'Day': return (<>
                <div> <label>Select Day *</label>
                    <br />
                    <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        name='period_day'
                        clearable
                        value={day}
                        placeholder="MM/DD/YYYY"
                        onChange={(date) => { DateHandler(date, selectedValue) }}
                        format="MM/DD/YYYY"
                        style={{ width: '300px', marginTop: 10 }}
                        error={false}
                        helperText={null}
                    />
                </div>
            </>);
            case 'Custom Period':
            case 'Week': return (<>
                <DatePickerHandle className='container-three' label='Valid From *' id='label'
                    value={ValidFrom}
                    onChange={(date) => { DateHandler(date, selectedValue, 'valid_from') }}
                />
                <DatePickerHandle className='container-three' label='Valid To *' id='label'
                    value={ValidTo}
                    onChange={(date) => { DateHandler(date, selectedValue, 'valid_to') }}
                />

            </>)
            case 'Month': return (<>
                <div> <label>Select Month *</label>
                    <br />
                    <KeyboardDatePicker
                        views={['month', 'year']}
                        autoOk
                        variant="inline"
                        name='period_month'
                        clearable
                        value={month}
                        placeholder="MM/YYYY"
                        onChange={(date) => { DateHandler(date, selectedValue) }}
                        format="MM/YYYY"
                        style={{ width: '300px', marginTop: 10 }}
                        error={false}
                        helperText={null}
                    />
                </div>
            </>);
            default: { }
        }
    }
    const [dates, setDates] = useState([]);
    const DateHandler = (date, value, validity) => {
        switch (value) {
            case 'Month': {
                setMonth(formatMonthDate(date));
                let period = `Month - ${formatMonthDate(date)}`
                addDateToFileUpload(period, value);
                break;
            }
            case 'Day': {
                setDay(formatDate(date));
                let period = `Day - ${formatDate(date)}`
                addDateToFileUpload(period, value);
                break;
            }

            case 'Custom Period':
            case 'Week':
                {
                    let valids = [...dates]
                    if (validity === 'valid_from') {
                        setValidFrom(formatDate(date));
                        valids.push(formatDate(date));
                        setDates(valids)
                    }
                    if (validity === 'valid_to') {
                        setValidTo(formatDate(date))
                        valids.push(formatDate(date))
                        setDates(valids)
                    }
                    const isValidDate = moment(valids[valids.length - 1]).isAfter(valids[valids.length - 2])
                    let str = '';
                    if (isValidDate) {
                        str = `${value} - ${valids[valids.length - 2]} to ${valids[valids.length - 1]}`
                    }
                    else str = `${value} - ${valids[valids.length - 1]} to ${valids[valids.length - 2]}`
                    addDateToFileUpload(str, value)

                    break;
                }

            default: { }
        }
    }


    const [uploadedFileId, setUploadedFileId] = useState();
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileSize, setFileSize] = useState("");
    const [fileFormat, setFileFormat] = useState("");
    const [error, setError] = useState();

    const handleUpload = async (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const MAX_SIZE_MB = 20;
        const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
      
        if (file && file.size > MAX_SIZE_BYTES) {
          setError('Please upload a file less than 20MB');
          setFile('');
        } else if (file) {
          setError('');
          setFile(file);
      
          const formData = new FormData();
          formData.append('File', file);
      
          try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/file`, {
              method: 'POST',
              body: formData,
            });
            const result = await response.json();
            console.log('Success:', result);
            setUploadedFileId(result.id);
            setFileName(file.name);
          setFileSize(file.size);
          setFileFormat(file.type === 'text/csv' ? 'csv' : (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx' : ''));
          } catch (error) {
            console.error('Error:', error);
          }
        }
      }

    useEffect(() => {
        if(props.EditMode){
        setFileName(props.editFileUpload?.['File Name'])
        setFileSize(props.editFileUpload?.['Size'])
        setFileFormat(props.editFileUpload?.['Format'])
        setUploadedFileId(props.editFileUpload?.FileStoreID)
        setAddFileUpload({period : props.editFileUpload.Period})
        }
    }, [props.editFileUpload,props.EditMode])


    function generateRandomId() {
        return 'file-' + Math.random().toString(36).substr(2, 9);
    }

    const hiddenFileInput = useRef(null);

    const deleteFile = async (fileId) => {
        try {
          const response = await axios.delete(`/api/file/${fileId}`);
          if (response.status === 200) {
            setUploadedFileId(null);
            setFileName(null);
            setFile('')
          } else {
            alert('file not deleted')
          }
        } catch (error) {
            alert('delete error')
        }
      };



    function deleteHandler() {
        setFileName('');
        setFileSize('');
        setFileFormat('');
        deleteFile(uploadedFileId)
        hiddenFileInput.current.value = null;
    }



    // const deleteFile = async (fileId) => {
    //     try {
    //       const response = await axios.delete(`/api/file/${fileId}`);
    //       if (response.status === 200) {
    //         setUploadedFileId(null);
    //         setUploadedFileName(null)
    //       } else {
    //         alert('file not deleted')
    //       }
    //     } catch (error) {
    //       // Handle error.
    //     }
    //   };

    // const DeleteUploadedFile = () =>{
    //     deleteFile(uploadedFileId);
    //     }



    // const handleFileUploadSubmit = () => {

    //     const dispObj = [{
    //         "ID":props.EditMode ? props.editFileUpload.ID :'',
    //         "FileName": file.name,
    //         "FileFormat": "xsxl",
    //         "FileStoreID": uploadedFileId,
    //         "FileSize": file.size,
    //         "Period": addFileUpload.period,
    //         "VoiceNonVoice": SelectedVoiceNonVoiceTab,
    //         "VoiceNonVoiceItemName": SelectedVoiceNonVoiceItem,
    //         "CustomerID": SelectedAccountDetails.CustomerID,
    //         "ServicedeskID": SelectedAccountDetails.ServiceDeskID
    //     }]
    //     console.log('dispObj', dispObj)
    //     dispatch(updateFileUploadDataStart(dispObj))
    //     dispatch(getFileUploadData(SelectedAccountDetails.CustomerID ? SelectedAccountDetails.CustomerID : '', SelectedAccountDetails.ServiceDeskID ? SelectedAccountDetails.ServiceDeskID : '', SelectedVoiceNonVoiceItem))

    //     props.handleFileUploadCancel()
    // }

    const [validation, setValidation] = useState('')

    const handleFileUploadSubmit = async () => {
            const dispObj = [{
                "ID": props.EditMode ? props.editFileUpload.ID : '',
                "FileName": fileName,
                "FileFormat": fileFormat,
                "FileStoreID": uploadedFileId,
                "FileSize": fileSize,
                "Period": addFileUpload.period,
                "VoiceNonVoice": SelectedVoiceNonVoiceTab,
                "VoiceNonVoiceItemName": SelectedVoiceNonVoiceItem,
                "CustomerID": SelectedAccountDetails.CustomerID,
                "ServicedeskID": SelectedAccountDetails.ServiceDeskID
            }];
            let ValidationData = {
               "FileName": fileName,
                "FileFormat": fileFormat,
                "FileStoreID": uploadedFileId,
                "FileSize": fileSize,
                "Period": addFileUpload.period,
                "VoiceNonVoice": SelectedVoiceNonVoiceTab,
                "VoiceNonVoiceItemName": SelectedVoiceNonVoiceItem,
                "CustomerID": SelectedAccountDetails.CustomerID,
                "ServicedeskID": SelectedAccountDetails.ServiceDeskID

            }
            console.log('ValidationData::::::::::', ValidationData, dispObj, props.editFileUpload)
            const isEmptyInput = Object.values(ValidationData).some(x => (x === null || x === '' || x === undefined));
           if(isEmptyInput){
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
           }
           else{
            dispatch(updateFileUploadDataStart(dispObj));
            setTimeout(() => {
            dispatch(getFileUploadData(
                SelectedAccountDetails.CustomerID || '',
                SelectedAccountDetails.ServiceDeskID || '',
                SelectedVoiceNonVoiceItem
            ));
        }, 1000);
            props.handleFileUploadCancel();
           }
    }

   
    

    return (
        <>
            <section className='add-row-container-upload'>
                <div >
                    <div className='Model_Title_Container'>
                        <div>{props.EditMode ? 'Edit Uploaded File' : 'File Upload'}</div><div onClick={props.handleFileUploadCancel} style={{ cursor: 'pointer' }}><img src={CloseWindow_Normal} alt='' /></div>
                    </div>
                    <div style={{ color: '#ED4F32' }}>{validation}</div>
                    <form>
                        <main className='input-container-one' style={{ width: '100%' }}>
                            <div className='container-three' >
                                <label HtmlFor="lang" style={{ marginBottom: '10px' }} >Period *</label>
                                {props.EditMode ? <div> {props.editFileUpload?.Period} </div> : <RequestDropdown data={PERIOD} name='period' value='value' id='id'
                                    addValueToQueue={(name, value) => setSelectedValue(value)}
                                    Inputvalue={props.editMode ? props.editFileUpload.selectedValue[0] : 'Select'}
                                />}
                            </div>
                            {props.EditMode ? '' : DisplayPeriodHandle()}
                        </main>

                        <section>    <div id="upload-box" style={{ position: 'relative' }}>
                        <label id='upload-file' >Attach File *</label>
                                <div className='role_preview'>You can upload only CSV and XLSX files. Maximum upload file size is 20 MB.</div>
                            <form>
                                <input type="file" id={generateRandomId()} ref={hiddenFileInput} name="myImage" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onChange={handleUpload} style={{ borderBottom: 0, opacity: 0, position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', cursor: 'pointer' }} />
                                <label htmlFor="file" style={{ cursor: 'pointer' }}>Drag & Drop or   BROWSE  your files</label>
                            </form>
                        </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}> {fileName && (
                                <>

                                <div className='card__upload'> <span>{fileName}</span>
                                <span >{fileSize} KB <img src={deleteIcon} alt='del'
                                    style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} /></span>
                            </div>

                                    {/* <span>{fileName}</span>
                                    <img
                                        src={deleteIcon}
                                        alt="del"
                                        style={{ marginLeft: 20, cursor: 'pointer' }}
                                        onClick={deleteHandler}
                                    /> */}
                                </>
                            )}
                            </div></section>
                        <div style={{ color: 'red' }}>{error}</div>

                        {/* <main >
                            <div id="upload-box">
                                <label id='upload-file' >Attach File</label>
                                <div className='role_preview'>You can upload only CSV and XLSX files. Maximum upload file size is 20 MB.</div>
                                <input type="file" name="myImage" id='file'
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    onInputCapture={uploadFileHandler} style={{ display: 'none'}}/>
                                <label htmlFor="file" style={{ cursor: 'pointer' }}>Drag & Drop or   BROWSE  your files</label>
                            </div>
                            {datacard && <div className='card__upload'> <span>{UploadedFile.fileName + '.' + UploadedFile.fileType}</span>
                                <span >{UploadedFile.fileSize} <img src={deleteIcon} alt='del'
                                    style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} /></span>
                            </div>}
                        </main> */}

                        <main className="Upload_Buttons_Container">
                            <button type='button' className='btn_adduser' onClick={handleFileUploadSubmit}>
                                UPLOAD
                            </button>
                            <button className='btn_canceluser' onClick={props.handleFileUploadCancel}>
                                CANCEL
                            </button>
                        </main>
                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>)

}
