
import { UploadActionTypes as actionTypes} from "../constants/uploads-actionTypes";

export const setSelectedVolumeFields = (data) => ({
    type: actionTypes.SET_SELECTED_VOLUME_FIELDS,
    payload: data
});


export const setSelectedSLAKPIFields = (data) => ({
    type: actionTypes.SET_SELECTED_SLAKPI_FIELDS,
    payload: data
});

export const setSelectedAccountDetails = (data) => ({
  type: actionTypes.SET_SELECTED_ACCOUNT_DETAILS,
  payload: data
});

export const setSelectedSLAKPIVolumeTab = (data) => ({
  type: actionTypes.SET_SELECTED_SLAKLP_VOLUME_TAB,
  payload: data
});

export const setSelectedVoiceNonVoiceTab = (data) => ({
  type: actionTypes.SET_SELECTED_VOICE_NONVOICE_TAB,
  payload: data
});

export const setSelectedVoiceNonVoiceItem = (data) => ({
  type: actionTypes.SET_SELECTED_VOICE_NONVOICE_ITEM,
  payload: data
});

               export const setDataAdministrationValidation = (data) => ({
                      type: actionTypes.SET_DATA_ADMINISTRATION_VALIDATION,
                      payload: data
                       });

export const setSelectedVolumeSlaDate = (data) => ({
  type: actionTypes.SET_SELECTED_VOLUME_SLA_DATE,
  payload: data
});

export const getUploadAccounts = (data) => (
    {
      type: actionTypes.GET_UPLOAD_ACCOUNTS,
      payload: data,
    }
  )

  export const getSLAKPIVolumeTemplates = (customerID,category) => (
    {
      type: actionTypes.GET_SLAKPI_VOLUME_TEMPLATES,
      customerID:customerID,
      category: category
    }
  )

  export const updateSelectedSLAKPIVolumeStart = (data) => (
    {
      type: actionTypes.UPDATE_SELECTED_SLAKPI_VOLUME_START,
      payload: data,
    }
  )
  
  
  export const updateSelectedSLAKPIVolumeSuccess = () => (
    {
      type: actionTypes.UPDATE_SELECTED_SLAKPI_VOLUME_SUCCESS,
    }
  )

  export const updateCustomTemplateStart = (data) => (
    {
      type: actionTypes.UPDATE_CUSTOM_TEMPLATE_START,
      payload: data,
    }
  )
  
  
  export const updateCustomTemplateSuccess = () => (
    {
      type: actionTypes.UPDATE_CUSTOM_TEMPLATE_SUCCESS,
    }
  )

  export const getFileUploadData = (customerID,servicedeskID,selectedVoiceNonVoiceTab) => (
    {
      type: actionTypes.GET_FILE_UPLOAD_DATA,
      customerID:customerID,
      servicedeskID:servicedeskID,
      selectedVoiceNonVoiceTab:selectedVoiceNonVoiceTab

    }
  )

  export const updateFileUploadDataStart = (data) => (
    {
      type: actionTypes.UPDATE_FILE_UPLOAD_DATA_START,
      payload: data,
    }
  )
  
  export const updateFileUploadDataSuccess = () => (
    {
      type: actionTypes.UPDATE_FILE_UPLOAD_DATA_SUCCESS,
    }
  )

  export const getNonvoiceCardsData = (customerID,servicedeskID,selectedVoiceNonVoiceTab,month) => (
    {
      type: actionTypes.GET_NONVOICE_CARDS_DATA,
      customerID:customerID,
      servicedeskID:servicedeskID,
      selectedVoiceNonVoiceTab:selectedVoiceNonVoiceTab,
      month:month
    }
  )

  export const getUploadPreviewData = (customerID,servicedeskID,month) => (
    {
      type: actionTypes.GET_UPLOAD_PRIVIEW_DATA,
      customerID:customerID,
      servicedeskID:servicedeskID,
      month:month
    }
  )

  export const getVolumeSlakpiSelectedTemplatesData = (customerID,servicedeskID,category,month,itemname) => (
    {
      type: actionTypes.GET_VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA,
      customerID:customerID,
      servicedeskID:servicedeskID,
      category:category,
      month:month,
      itemname:itemname
    }
  )

  export const updateVolumeSLAKPIDataStart = (data) => (
    {
      type: actionTypes.UPDATE_VOLUME_SLAKPI_DATA_START,
      payload: data,
    }
  )
  
  
  export const updateVolumeSLAKPIDataSuccess = () => (
    {
      type: actionTypes.UPDATE_VOLUME_SLAKPI_DATA_SUCCESS,
    }
  )