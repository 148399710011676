import { all, call, put, takeEvery } from "redux-saga/effects";
import { github_actionTypes as actionTypes} from './../constants/github-actionTypes';
import { getTilesDataService } from './../services/github.service';
import { githubUrls } from './../constants/github-urls';

function* fetchTilesData(action) {
    try {
        const response = yield call(getTilesDataService, githubUrls('GET_TILES_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.TILES_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while TILES  data", error);
    }
}

function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_TILES_DATA, fetchTilesData)
    

}
export default function* githubSagas() {
    yield all([watchGetRequest()]);
}