import React, { useEffect, useState } from 'react'
import backArrow from './../../../assets/onboard-images/Back_Arrow.png';
import FileUploadhandler from '../File-Upload/FileUploadhandler';
import SLAKPIUploadManager from '../File-Upload/SLAKPIUploadManager';
import VolumeUploadManager from '../File-Upload/VolumeUploadManager';
import { useDispatch, useSelector } from 'react-redux';
import { getNonvoiceCardsData, getVolumeSlakpiSelectedTemplatesData, setSelectedVoiceNonVoiceItem } from './../../../actions/uploads.action';
import { KeyboardDatePicker } from '@material-ui/pickers';
import FormFieldHandler from '../File-Upload/FormFieldHandler';
import UploadAccordion from './UploadAccordion';


function NonVoicetabDetails(props) {
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const ACCORDION_INFO = [
        {
            id: 1,
            label: 'File Upload',
            Info: <FileUploadhandler/>
        },
        {
            id: 2,
            label: 'Volume Data',
            Info: <FormFieldHandler error={error} setError={setError}/>
        },
        {
            id: 3,
            label: 'SLA/KPI Data',
            Info: <FormFieldHandler error={error} setError={setError}/>
        }
    ];
    const [widget, setWidget] = useState();
    const widgetHandler = card => {
        if (card.Deleted === false) {
            setWidget(card)
            // props.widgetCardHandler('widget');
        }
    }

    console.log('widget',widget)
   

    const NonvoiceCardsData = useSelector((state) => state.uploadReducer.NonvoiceCardsData);
    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
    const dataAdministrationValidation = useSelector((state) => state.uploadReducer.dataAdministrationValidation);

  console.log('NonvoiceCardsData',NonvoiceCardsData)

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year,month, '01'].join('/');
}

const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));


const SelectedDateHandler = (date) => {
    setSelectedDate((date === null || date === undefined) ? null : formatDate(date));
}

console.log('selectedDate',selectedDate)

  useEffect(()=>{
    dispatch(getNonvoiceCardsData(SelectedAccountDetails.CustomerID ? SelectedAccountDetails.CustomerID : '', SelectedAccountDetails.ServiceDeskID ? SelectedAccountDetails.ServiceDeskID : '','Non-Voice',selectedDate))
},[dispatch,SelectedAccountDetails,selectedDate])





const [openAccordion, setOpenAccordion] = useState(null);
// const validation = true; // Assuming this will be dynamically set in a real application
const SelectedVoiceNonVoiceItem = useSelector((state) => state.uploadReducer.SelectedVoiceNonVoiceItem);
const selectedVolumeSlaDate = useSelector((state) => state.uploadReducer.selectedVolumeSlaDate);

useEffect(() => {
    console.log('useEffect triggered');
    console.log('openAccordion',openAccordion === '1')
    if (openAccordion) {
        console.log('Dispatching action'); // Debug log
        dispatch(getVolumeSlakpiSelectedTemplatesData(
            SelectedAccountDetails.CustomerID || '',
            SelectedAccountDetails.ServiceDeskID || '',
            openAccordion === '1' ? 'Volume' : 'SLA/KPI',
            selectedVolumeSlaDate,
            SelectedVoiceNonVoiceItem
        ));
    }
}, [openAccordion, dispatch, SelectedAccountDetails, SelectedVoiceNonVoiceItem,selectedVolumeSlaDate]);

const handleAccordionOpen = (id) => {
    // Check if the clicked accordion is already open
    if (openAccordion === id) {
        // If it's already open, check validation before closing
        if (dataAdministrationValidation) {
            setOpenAccordion(null); // Close the accordion if validation passes
            setError(null);
        } else {
            console.log('Data Not Saved. Cannot close this section.'); // Prevent closing if validation fails
            // Optionally, show an alert or message to the user
            setError('Validation failed. Cannot close this section.');
        }
    } else {
        // If trying to open a different accordion, validate first
        if (dataAdministrationValidation) {
            setOpenAccordion(id); // Update the state if validation passes
            setError(null);
        } else {
            console.log('Validation failed. Cannot open this section.'); // Prevent the accordion from opening
            // Optionally, show an alert or message to the user
            setError('Data Not Saved. Cannot open this section.');
        }
    }
};




    return (
        <>
     {!widget ? <> <div className='role__main' style={{marginTop:20}}>Select Month</div>
          <div >
          <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  value={selectedDate}
                  onChange={(date) => SelectedDateHandler(date)}
                />
          </div> </> : ''}
        
            {!widget ? <div className='nonvoice_cards'> <div className="nonvoice_heading">Non Voice Item</div> <ul className='card-section' style={{ cursor: 'pointer' }}>
                {NonvoiceCardsData && NonvoiceCardsData.map(card => (
                    <li className={card.Deleted ? 'nonvoice-cards-disabled' : 'nonvoice-cards'}
                        onClick={() => {
                            dispatch(setSelectedVoiceNonVoiceItem(card.ItemName))
                          //  localStorage.setItem('NonVoiceItem', card.card);
                            widgetHandler(card)
                        }}>{card.ItemName}
                         <div className='img-src'>
                            <br />
                            <span style={{ color: '#969696' }}>File Upload: {card?.['File Uploaded']}</span>
                        </div>
                    </li>
                ))}
            </ul>
                <div className='nonvoice_note'><b>Note:</b> Greyed out items related data feed is managed globally</div>
            </div> :
                <section className='non-voice-container' style={{paddingBottom:'100px'}}>
                    {widget && <div >
                        <span style={{ cursor: 'pointer' }} onClick={() => {
                            setWidget('')
                            localStorage.removeItem('NonVoiceItem');
                            // props.widgetCardHandler('nonvoice');
                        }}>
                            <img src={backArrow} alt='' style={{ marginRight: 15, marginBottom: 10 }} />
                            <span className='back-button' >{widget.ItemName}</span></span>
                            <UploadAccordion
                    ACCORDION_INFO={ACCORDION_INFO}
                    openAccordion={openAccordion}
                    onAccordionOpen={handleAccordionOpen}
                />
                        {/* {widget.ItemName === "Chat Tool" && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                        {widget.ItemName === 'workforce' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                        {widget.ItemName === 'ticket' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                        {widget.ItemName === 'email' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                        {widget.ItemName === 'customer' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                        {widget.ItemName === 'non_aquaa' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                        {widget.ItemName === 'web' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />} */}

                    </div>}
                </section>
            }
        </>
    )
}

export default NonVoicetabDetails