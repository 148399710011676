import API from "../../api";
let token = localStorage.getItem("token");



export const getAdminViewService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAccessGroupService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateAccessGroupDataService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateRoleDeleteService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


// OPERATIONS FLOW

export const getOperationsDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateOperationsDataService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateOperationsDeleteService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


// ACCESS GROUP FLOW

export const getAccessGroupDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAccessGroupEditDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAccessGroupAddDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateAccessGroupAddEditService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateAccessGroupDeleteService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

// USER FLOW

export const getTimeZoneDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateAddUserService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateDeleteUserService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getEditUserDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateEditUserService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getRegionDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getUPNValidationService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getUserflowUsersSevice = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getUserflowCountSevice = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

// AUDIT RBAC

export const getAuditRBACDataSevice = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

// AUDIT ROLE

export const getAuditRoleDataSevice = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};