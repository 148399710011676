import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../General/Admin-breadcrumb'
import { useHistory, useLocation } from 'react-router-dom'
import ReactModal from 'react-modal';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessGroup, getAdminRoles, updateAccessGroupDataStart } from '../../../../actions/Administration/adminView.action';
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png'
import {DeleteModalPopup } from '../../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { AdminViewDeleteModel } from '../AdminViewModels';

export function AddAdminViewRolePage(props) {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    console.log('location::::::', location.state)
    const EditData = location.state=== undefined ? '' : location.state.editInfo;
    const Mode = location.state=== undefined ? '' :location.state.Mode;

    const [showAccessGroupModal, setShowAccessGroupModal] = useState(false);
    const [addAccessGroup, setAddAccessGroup] =useState(Mode === 'Edit' ? EditData  : {});
    const [addTableRow, setAddTableRow] = useState([])
    const [deleteRoleID, setDeleteRoleID] = useState(null);
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);

    console.log('deleteRoleID',deleteRoleID)

    const HandleAddQueue = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...addAccessGroup };
            newFormData[fieldName] = fieldValue;
            setAddAccessGroup(newFormData)
        }
    }
  
const addValueToTable = (value) => {
    if(addTableRow.length === 0){
        setAddTableRow([value])
    }
    else{
        setAddTableRow([...addTableRow,value])
    }
setShowAccessGroupModal(false)
}

const handleDeleteRole = () => {
    const newRoles = [...addTableRow];
    const index = addTableRow.findIndex((_, index) => index === deleteRoleID);
    newRoles.splice(index, 1);
    setAddTableRow(newRoles);
    setShowDeleteRoleModal(false)
}
const handleDeleteRoleCancel = event => {
    event.preventDefault();
    setShowDeleteRoleModal(false)
}
 
useEffect(() => {
    if(Mode === 'Edit'){
        if(EditData?.['Access Group'] === undefined){
setAddTableRow([])
        }
        else{
     let temp = EditData?.['Access Group'].map(accessdata =>{
        return {
            AccessGroup : accessdata.AccessGroup,
            AccessGroupID : accessdata.AccessGroupID,
            OperationList : accessdata?.OperationList
        }
     })
       setAddTableRow(temp)
    }
    }
}, [dispatch,EditData,Mode])
   
const [validation, setValidation] = useState(false);
const dispobj = () =>{
    let AccessIds = addTableRow.map(role => {
        return {
            AccessGroupId : role.AccessGroupID
        }
    });
    let temparr = {
        RoleID : Mode === 'Edit' ? EditData.RoleID : '',
        Role : addAccessGroup?.['Role Name'],
        Description : addAccessGroup.Description,
        Details : AccessIds

    }
    const temp_Arr = {
        Role : addAccessGroup?.['Role Name'],
        Description : addAccessGroup.Description,
    }
    const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
    if(isEmptyInput){
        setValidation(true);
        window.scrollTo(0, 0);
    }
    else{
    console.log('dispOBJ',[temparr])
    dispatch(updateAccessGroupDataStart([temparr]))
  history.push('/admin/roles');
  dispatch(getAdminRoles());
  setValidation(false)
    }
}

console.log('addTableRow',addTableRow.length)
    return (<>
        <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={()=>history.push('/adminviewlanding')}
            isActive3={true} label3='Roles' value3={() => history.push('/admin/roles')} isActive4={false} label4={Mode === 'Edit' ? 'Edit Role' : 'Add Role'}
        />
        <div className='administration_text'>{Mode === 'Edit' ? 'Edit Role'  : 'Add Role'}</div>
        {validation && <div style={{ color: '#ED4F32',marginLeft:10 }}>Please Ensure you filled all the inputs with  " * " mark </div>}
       <main className='Roles_name_section'>
            <div className='rolename_inputfield'>
                <label>Name * </label>
                <input type='text'
                    name='Role Name'
                    value = {addAccessGroup?.['Role Name']}
                    placeholder='Example:Associate Manager (AM)'
                    onChange={(e)=>HandleAddQueue(e)}
                />
            </div>
            <div className='description_inputfield'>
                <label>Descriptions * </label>
                <input type='text'
                    name='Description'
                    value = {addAccessGroup?.['Description']}
                    placeholder='Example:Similar responsibilities are applicable here as for the SDM, however an AM usually has a dedicated to…'
                    onChange={(e)=>HandleAddQueue(e)}
                />
            </div>
        </main>
        <main className='button_section'>
            <button className='btn_addAccessGroup' onClick={() => setShowAccessGroupModal(true)}>ADD ACCESS GROUP & OPERATIONS</button>
        </main>
        
       {addTableRow.length === 0 ? 
       

<section>  <table style={{ width: '97%', marginTop: '1em' }} className='adminview_table'>
    <thead>
    <tr >
     <th style={{ border: 'none', width: '35%' }}>Access Group Name</th>
                    <th style={{ border: 'none', width: '65%' }}>Operation</th>
                    <th style={{ border: 'none'}}></th>
                </tr>
    </thead>
</table>
    <section className='noData-table'>
        <div className='noData-para' style={{ marginTop: 0 }}>
            <div style={{ marginTop: 30 }}>
            No records found. This data grid enables adding/editing/deleting the Access Group and Operations.<br/> 
            To add a new Access Group and Operations, select the “ADD ACCESS GROUP & OPERATIONS” button.
            </div>
        </div>
    </section>
</section>

        : <table style={{ width: '97%', marginTop: '1em' }} className='adminview_table'>
            <thead>
            
                <tr >
                    <th style={{ border: 'none', width: '35%' }}>Access Group Name</th>
                    <th style={{ border: 'none', width: '65%' }}>Operation</th>
                    <th style={{ border: 'none'}}></th>
                </tr>
        
            </thead>
            <tbody>
            {
                (addTableRow).map((data, index) => (
                <tr key={data.id}>
                    <td >{data.AccessGroup}</td>
                    <td >{data.OperationList !== undefined ? (data.OperationList).map((data,i,row)=>( <span>{data.Operation} {i+1 === row.length ? '' : '|' } &nbsp;</span> )) : ''}</td>
                    <td><img src={deleteIcon} alt='' style={{marginRight:'50px',cursor:'pointer'}} onClick={() => {setShowDeleteRoleModal(true); setDeleteRoleID(index) }}/></td>
                </tr>
                ))
                    }
            </tbody>
        </table>}

        <main className='Button_container_accessGroup' style={{marginLeft:'500px'}}>
                        <button className='btn_adduser' onClick={()=>dispobj()}>{Mode === 'Edit' ? 'UPDATE'  : 'ADD'}</button>
                        <button className='btn_canceluser' onClick={()=>history.push('/admin/roles')}>CANCEL</button>
                    </main>


        {showAccessGroupModal && <AccessModalPopup isOpen={showAccessGroupModal} setShowModal={setShowAccessGroupModal}>
            <AccessGroupDropdownModel 
            handleCancelAccessGroup={()=>{setShowAccessGroupModal(false)}}
            addValueToTable={addValueToTable}
            />
        </AccessModalPopup>}

        {showDeleteRoleModal && <DeleteModalPopup isOpen={showDeleteRoleModal} setShowModal={setShowDeleteRoleModal}>
            <AdminViewDeleteModel modalName='Delete Access Group and Operations' buttonOne='YES' buttonTwo='NO'
            InformationText = {addTableRow[addTableRow.findIndex((_, index) => index === deleteRoleID)].AccessGroup} 
            AfterInfoText='access group and operations?'
            handleDeleteYes={handleDeleteRole}
            handleDeleteNo={handleDeleteRoleCancel}
            />
        </DeleteModalPopup>}


    </>)
}

export function AccessGroupDropdownModel(props) {
    const dispatch = useDispatch();
    let accessGroupData = useSelector((state) => state.adminViewReducer.accessGroupData);
    useEffect(() => {
        dispatch(getAccessGroup());

    }, [dispatch]);


    const [addselectedAccessGroup, setAddselectedAccessGroup] = useState()

    const addValueToAccessGroup = (name, value) => {
        if (value !== 'Select') {
            const newFormData = { ...addselectedAccessGroup };
            newFormData[name] = value;
            setAddselectedAccessGroup(newFormData)
        }
    }
   
    let operationlist = addselectedAccessGroup === undefined ? {} : accessGroupData.filter((el) =>{
        return el.AccessGroup === addselectedAccessGroup.AccessGroup 
      }
      );

      const [validation, setValidation] = useState()
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('addselectedAccessGroup',addselectedAccessGroup)

        if(addselectedAccessGroup === undefined){
            setValidation(true)
        }
        else{
    props.addValueToTable(operationlist?.[0])
    setValidation(false)
        }
    }

    return (
        <>
            <section className='AccessGroup_container'>
                <form>
                    <div className='add_access_text'>Add Access Group and Operations</div>
                    {validation && <div style={{ color: '#ED4F32'}}>Please Ensure you filled the Access Group </div>}
                    <main className='AccessGroup_dropdown'>
                        <label HtmlFor="role">Access Group *</label>
                        <OnboardSelect data={accessGroupData} name='AccessGroup' value='AccessGroup' id='RoleId'
                            editValue='' addValueToQueue={addValueToAccessGroup} />
                    </main>
                    <main className='AccessGroup_dropdown'>
                        <label HtmlFor="role">List of Operations</label>
                        <div class="ScrollStyle_accessGroup">
                        {operationlist?.[0]?.OperationList !== undefined &&
                            operationlist?.[0]?.OperationList.map((data, index) => ( <div className='scroll_Inside_div'>{data.Operation}</div>))}
                        </div>
                    </main>
                    <main className='Button_container_accessGroup'>
                        <button className='btn_adduser' onClick={(e)=>handleSubmit(e)}> ADD </button>
                        <button className='btn_canceluser' onClick={props.handleCancelAccessGroup}>CANCEL</button>
                    </main>
                </form>
            </section>
        </>
    )
}

export function AccessModalPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '25%',
                    left: '35%',
                    width: '400px',
                    height: '420px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}
