import ReactModal from "react-modal";
import "../QueueTable/TableTemplate.css";
import Success_Icon from '../../../../assets/onboard-images/Success_Icon.png'
import { useLocation } from 'react-router';

ReactModal.setAppElement('#root')
export function CancelModalPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '40%',
                    left: '35%',
                    width: '550px',
                    height: '   280px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}

export function CancelFragment(props) {
    const location = useLocation()
    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '4em' }}>
                <div className='delete-row-span' style={{ marginTop: '1em' }}>
                    {props.unsavedDataExist ? 'Unsaved Changes' : 'Account not Submitted'}
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>

                {props.unsavedDataExist ? <div className='delete-para-span'> There are unsaved changes on this page. <br />
                    Are you sure you want to cancel the  <span className="queue-span"> {location.pathname === '/onboardAccount' ? 'Onboarding' : 'Administration of'} {props.accountName} Account</span>
                </div> :
                    <div className='delete-para-span'>
                        Are you sure you want to cancel the  <span className="queue-span">  {location.pathname === '/onboardAccount' ? 'Onboarding' : 'Administration of'} {props.accountName} Account</span>
                    </div>
                }
            </div>
            {props.unsavedDataExist && <main className='input-container-one' style={{ marginLeft: '2em', marginTop: '3em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleSaveQueue}> {props.buttonThree}
                </button>
                <button type='submit' className='btn_yes' onClick={props.handleDeleteQueue}> {props.buttonOne}
                </button>
                <button className='btn_canceluser' onClick={props.handleDeleteQueueCancel}> {props.buttonTwo} 
                </button>
            </main>}
            {props.noUnsavedData && <main className='input-container-five' style={{ marginLeft: '5em', marginTop: '3em' }}>

                <button type='submit' className='btn_adduser' onClick={props.handleDeleteQueue}> {props.buttonOne}
                </button>
                <button className='btn_canceluser' onClick={props.handleDeleteQueueCancel}> {props.buttonTwo} 
                </button>
            </main>}
        </section>
    </>
}



export function NextButtonFragment(props) {
    console.log("unsaved data exists: ", props.unsavedDataExist)

    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '4em' }}>
                <div className='delete-row-span' style={{ marginTop: '1em' }}>
                    Unsaved Changes
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>

                <div className='delete-para-span'> You still have unsaved changes. <br />
                Before proceeding do you wish to save these changes?
                </div>

            </div>
            <main className='input-container-one' style={{ marginLeft: '6em', marginTop: '3em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleSaveQueue}> YES
                </button>
               
                <button className='btn_canceluser' onClick={props.handleDeleteQueueCancel}>
                   NO 
                </button>
            </main>
        </section>

    </>
}

export function SuccessModalPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '30%',
                    left: '35%',
                    width: '600px',
                    height: '320px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}















