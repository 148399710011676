import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./regionalSite.css";
import BredCrumb from "../Breadcrumb/bredcrumb";
import AboveRequiredTargetIcon from "../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../assets/images/BelowMinimalTarget_Status.png";
import FinaceReport_Icon from "../../assets/images/FinaceReport_Icon.png";
import ExecutiveDashboard_Icon from "../../assets/images/ExecutiveDashboard_Icon.png" 
import GlobalAccounts from "./GlobalAccounts/global-accounts";
import AccordionPanels from "./Accordion-panels/accordion";
import CustomSelect from "../custom-select/custom-select";
import { getRegionalSiteData, updateDrpdwnRegionName, updateDropownSlctdRgnsList, updateSlcdAccrdPnlID } from "../../actions/regionalSite.action";
import RegionalCardWdget from "./Regional-Card-Widget/regional-card-widget";
import AgentCardinReports from "../Agent-ScoreCard/AgentCardinReports";
import TeamKPISummary from "../TeamLeaderView/TeamKPISummary";
const RegionalSite = () => {

  const [globalCustomerData, setGlobalCustomerData] = useState([]);
  const [regionDropdownData, setRegionDropdownData] = useState([{id: 0, value: ''}]);
  const [accordionPanelData, setAccordionPanelData] = useState({});  
  const [isAccrdPnlActive, setIsAccrdPnlActive] = useState('');
  const ASDTextHdr = 'ASD Cost Recovery Report';
  const ASDText = 'Baseline report for ASD cost recovery purposes. ASD base data input (Agent FTE, Call duration, Call volume data) for financial purposes.';
  const ASDTextLink = 'EXPLORE DETAILED FINANCE REPORT';
  const ASDLink ="/finance";

  const EDTextHdr = 'Executive Dashboard';
  const EDText = 'Combined ASD Global Dashboard and GSD Global KPI Dashboard.';
  const EDTextLink = 'EXPLORE DETAILED GLOBAL DASHBOARD';
  const EDLink ="/eDashboard";

  const dispatch = useDispatch();
  let regionalSiteData = useSelector((state) => state.regionalSiteReducer.regionalSiteData);
  let drpdwnSlctdRgnName = useSelector((state) => state.regionalSiteReducer.drpdwnRegionName);
  let drpdwnSlctdRgnList = useSelector((state) => state.regionalSiteReducer.drpdwnSlctdRgns);
  let slctAccrdPnlID = useSelector((state) => state.regionalSiteReducer.slcdAccrdPnlID);
  useEffect(() => {
    if(!regionalSiteData.regions){
    dispatch(getRegionalSiteData());
    }else if(drpdwnSlctdRgnName && drpdwnSlctdRgnList?.servicedesks?.length > 0){
      buildRegionDropdownData();
      setAccordionPanelData(drpdwnSlctdRgnList);
      setIsAccrdPnlActive(slctAccrdPnlID);
    }    
  }, [dispatch]);

  useEffect(() => {
    buildRegionDropdownData();
  }, [regionalSiteData]);

  const buildRegionDropdownData = () => {
    let regnDtTempArr = [];
    if (regionalSiteData && regionalSiteData.globalcustomers) {      
      setGlobalCustomerData(regionalSiteData.globalcustomers);
      regionalSiteData.regions.map((itm, idx)=>{
        let val = {id: itm.regionid, value: itm.region};
        regnDtTempArr.push(val);
      });
      setRegionDropdownData(regnDtTempArr);
    }
  }

  const selectedDrpDwnRegionHandler = (e, rgnName) => {
    if(regionalSiteData && regionalSiteData.regions && regionalSiteData.regions.length > 0){
        const region = regionalSiteData.regions.filter((itm)=> {
            return itm.regionid === e;
          });
        setAccordionPanelData(region[0]); 
        dispatch(updateDrpdwnRegionName(rgnName));
        dispatch(updateDropownSlctdRgnsList(region[0]));         
        setIsAccrdPnlActive('');
    }
  }


const slctdAccrdPnlID = (pnlID) => {
  if(pnlID || pnlID === 0){
    dispatch(updateSlcdAccrdPnlID(pnlID));
    setIsAccrdPnlActive(pnlID);
  }  
}

const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
  return (
    <div>
      {/* <Header isMenu={true} isInfo={true} /> */}
      <div>
        <BredCrumb />
        <div className="row no-rght-mrgn" >
          <div className="col-md-3 report-lbl">Reports</div>
          <div className="col-md-2"></div>
          <div className="col-md-7 legend-row">
            <b>Legend:</b>
            <span className="legend-name">
              <span>
                <img
                  src={AboveRequiredTargetIcon}
                  className="margin-top-0"
                  alt="AbvRqdTgt Icon"
                />
              </span>
              {"Above Required Target"}
              <span>
                <img
                  src={AboveMinimalTargetIcon}
                  className="margin-top-0"
                  alt="AbvRqdTgt Icon"
                />
              </span>{" "}
              {"Above Minimal Target"}
              <span>
                <img
                  src={BelowMinimalTargetIcon}
                  className="margin-top-0"
                  alt="AbvRqdTgt Icon"
                />
              </span>
              {" Below Minimal Target"}
            </span>
          </div>
          { RoleBasedView?.[0]?.['Agent View']?.[0]?.Read === 1 ? <AgentCardinReports/> : ''}
          { RoleBasedView?.[0]?.['Team Leader View']?.[0]?.Read === 1 ? <TeamKPISummary/> : ''}
       { RoleBasedView?.[0]?.['ASD Cost Recovery Report']?.[0]?.Read === 1 ? <RegionalCardWdget img={FinaceReport_Icon} txthdr={ASDTextHdr} txt={ASDText} lnktxt={ASDTextLink} lnk={ASDLink} /> : ''}
       { RoleBasedView?.[0]?.['Executive Dashboard']?.[0]?.Read === 1 ?  <RegionalCardWdget img={ExecutiveDashboard_Icon} txthdr={EDTextHdr} txt={EDText} lnktxt={EDTextLink} lnk={EDLink} />: ''}
          <div className="row dropdown-card" >
              <div className="card no-pddng">
              <h5 className="card-header slct-regn">Select Region</h5>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4"></div>
                  <div className="col-sm-4 col-md-4 col-lg-4">                      
                      <CustomSelect options={regionDropdownData} selectedVal={drpdwnSlctdRgnName} selectedRegionHandler={selectedDrpDwnRegionHandler}/>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4"></div>
                </div>              
              </div>
            </div>
            </div>
          <AccordionPanels data={accordionPanelData} slctdAccrdPnlID={slctdAccrdPnlID} selectedPanel={slctAccrdPnlID} isActive={isAccrdPnlActive}/>
          <GlobalAccounts  data={globalCustomerData}/> 
        </div>
      </div>
      
    </div>
  );
};

export default RegionalSite;
