import React from "react";
import "rc-tabs/assets/index.css";
import "./tab.css";
import Tabs, { TabPane } from "rc-tabs";
import TabContent from "rc-tabs/lib/SwipeableTabContent";
import ScrollableInkTabBar from "rc-tabs/lib/ScrollableInkTabBar";

const defaultTabKey = "Overall";

// const PanelContent = ({ children }) => (
//   <div style={{ height: 200, overflow: "auto" }}>{children}</div>
// );

class TabComponent extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        start: 0,
        tabKey: defaultTabKey,
        activKey: defaultTabKey
      };
     
      this.onChange = this.onChange.bind(this);
      this.onTabClick = this.onTabClick.bind(this);
      this.tick = this.tick.bind(this);
    }

    componentWillReceiveProps = (nextProps) => {
      if (nextProps.tabKey !== this.props.tabKey) {
        this.setState({activKey: defaultTabKey});
      }     
    }

    
  
    onChange(key) {
      console.log(`onChange ${key}`);     
      this.props.tabChange(key);
    }
  
    onTabClick(key) {
      console.log(`onTabClick ${key}`);
      this.setState({
        tabKey: key,
        activKey: key
      });
    }
  
    tick() {
      const { start } = this.state;
  
      this.setState({
        start: start + 10
      });
    }
    // defaultActiveKey={defaultTabKey}
    render() {
      return (
        <div>          
          <Tabs
            activeKey={this.state.activKey}
            renderTabBar={() => (
              <ScrollableInkTabBar onTabClick={this.onTabClick} />
            )}
            renderTabContent={() => <TabContent />}
            onChange={this.onChange}
          >
            <TabPane tab="Telephony" key="Overall">             
            </TabPane>
            <TabPane tab="Business Unit" key="By Business Unit">
              {/* <PanelContent id={start}>Content for Drug Coverage</PanelContent> */}
            </TabPane>
            <TabPane tab="Language" key="By Language"></TabPane>
            <TabPane tab="Quality" key="Quality"></TabPane>
            <TabPane tab="Portal" key="Portal"></TabPane>
            <TabPane tab="Email" key="Email"></TabPane>
            <TabPane tab="Chat" key="Chat"></TabPane>
            <TabPane tab="Ticketing" key="Ticketing"></TabPane>
            <TabPane tab="FCR" key="FCR"></TabPane>
          </Tabs>
        </div>
      );
    }
  }

  export default TabComponent;