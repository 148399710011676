import React from 'react'
import './Design.css'
import AdminBreadcrumb from './../../Administration/General/Admin-breadcrumb';
import Design_HeroBanner_Image from './../../../assets/github-images/Design_HeroBanner_Image.png';
import RightArrow_Hover from './../../../assets/github-images/RightArrow_Hover.png';
import { Footer } from '../HeaderFooter';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setDesignCard } from '../../../actions/github.action';

const DESIGN_CARDS = [
    { id: 1, title: 'Database Design', text: 'database' },
    {
      id: 2,
      title: 'MS Team Design',
      text: 'msteam'
    }
  ]
  
   
const Design = () => {
    const dispatch = useDispatch();
    const history= useHistory();
    const onClickDesignCards=(card)=>{
      dispatch(setDesignCard(card));
      history.push('/design/card')
    }
  return (
    <>
     <section style={{ marginLeft: -100 }}>
        <main className='breadcrumb_solution'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Design' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>Design</div>This section includes information about the database models and organisation / classification of data determining what data must be stored and how the data elements are connected. Database design involves also identification of all the interrelationships.</div>
          <div style={{paddingBottom:20,paddingLeft:80}}><img src={Design_HeroBanner_Image} alt=''/></div></div>
        </main>
        <main className='conceptual_arch_devp'>
        <div style={{width:1200,margin:'auto'}}>
          <div className='grid_design'>
          <div class="grid-item-dev" onClick={()=>history.push('/database')}><div>Database Design</div><div><img src={RightArrow_Hover} alt='' /></div></div>
          <div><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
           href='https://teams.microsoft.com/l/channel/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/General?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&tenantId=93f33571-550f-43cf-b09f-cd331338d086'
           title="" >  
          <div class="grid-item-dev">MS Team Design<div><img src={RightArrow_Hover} alt='' /></div></div></a></div>
          </div></div>
        </main>
        <Footer />
      </section>
    </>
  )
}

export default Design