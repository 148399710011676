import actionTypes from "../constants/actionTypes";



export const getDropdownInfo = (data) => (
  {
    type: actionTypes.GET_DROPDOWN_INFO,
    payload: data,
  }
)

export const getAccountInfo = (data) => (
  {
    type: actionTypes.GET_ACCOUNT_INFO,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getAverageHandleTime = (data) => (
  {
    type: actionTypes.GET_AVERAGE_HANDLE_TIME,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getAbandonRate = (data) => (
  {
    type: actionTypes.GET_ABANDON_RATE,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getDigitalTrans = (data) => (
  {
    type: actionTypes.GET_DIGITAL_TRANS,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getCostPerContact = (data) => (
  {
    type: actionTypes.GET_COST_PER_CONTACT,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)
export const getContactFTEData = (data) => (
  {
    type: actionTypes.GET_CONTACT_FTE_DATA,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getPenaltyAtRisk = (data) => (
  {
    type: actionTypes.GET_PENALTY_AT_RISK,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getFirstContactResolution = (data) => (
  {
    type: actionTypes.GET_FIRST_CONTACT_RESOLUTION,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)
export const getAttritionData = (data) => (
  {
    type: actionTypes.GET_ATTRITION_DATA,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)
export const getSLAcompliance = (data) => (
  {
    type: actionTypes.GET_SLA_COMPLIANCE,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getCSATdata = (data) => (
  {
    type: actionTypes.GET_CSAT_DATA,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)
export const getDeliverySupport = (data) => (
  {
    type: actionTypes.GET_DELIVERY_SUPPORT,
    ContactVolumeID: data.ContactVolumeID,
    filterID: data.filterID
  }
)

export const getContactVolumeData = (data) => ({
  type: actionTypes.GET_CONTACT_VOLUME_INFO,
  ContactVolumeID: data.ContactVolumeID,
  filterID: data.filterID
});

export const saveAccountDropdownList = (listVal) => ({
  type: actionTypes.SAVE_ACCOUNT_DRPDWN_LIST_DATA,
  payload: listVal,
});


export const saveLocationDropdownList = (listVal) => ({
  type: actionTypes.SAVE_LOCATION_DRPDWN_LIST_DATA,
  payload: listVal,
});
