import React from "react";
import AboveRequiredTargetIcon from "../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../assets/images/BelowMinimalTarget_Status.png";
import ContractualSLAIcon from "../../assets/images/Contractual_SLAs.png";
import "./reportsTable.css";
import Table from 'react-bootstrap/Table';

const getArrowMark = (value, color, sla) => {
  switch(color){
    case 'green': {
      return (<div><span>{value ? <b>{value?.trim()}</b> : null}</span><span><img src={AboveRequiredTargetIcon} className="margn-5 mrgn-top-ngv-5"  alt="AbvRqdTgt Icon" /></span> {getSLDt(sla)}</div>);
     // break;
    }
    case 'yellow' : {
      return (<div><span>{value ? <b>{value?.trim()}</b> : null}</span><span><img src={AboveMinimalTargetIcon}  className="margn-5 mrgn-top-ngv-5"  alt="AbvMnmlTgt Icon" /></span>{getSLDt(sla)}</div>);
     // break;
    }
    case 'red' : {
      return(<div><span>{value ? <b>{value?.trim()}</b> : null}</span><span><img src={BelowMinimalTargetIcon}  className="margn-5 mrgn-top-ngv-5"  alt="BlwMnmlTgt Icon" /></span>{getSLDt(sla)}</div>);
     // break;
    }
    default: {
      return(<div><span>{value ? <b>{value?.trim()}</b> : null}</span>{getSLDt(sla)}</div>);
    }
  }
}

const getSLDt = (isSLA) => {
  if(isSLA)
  return <img src={ContractualSLAIcon} className="margn-5"  alt="Contractual SLA Icon" />;
  else
  return;
}

const TableHeader = (props) => {
    const { headers, indctr } = props;
    return(
      <thead  key="header-1">
          <tr style={{position: 'sticky', top: -1, backgroundColor: '#F6F8FA'}} key="header-0">
            { headers && headers.map((value, index) => {
                return <th  className={index < indctr ? 'hghltBckgrd': null} key={index}><div className="thContent">{value}</div></th>
            })}
          </tr>
      </thead>
    );
  }

const TableBody = (props) => {
    const { rows, indctr } = props;  
    function buildRow(row, indx) {
     // const headrs = Object.keys(row);
      return (
           <tr key={indx}>
           { row.map((value, index) => {
               return <td key={index} style={{border: index > (indctr-1) ? 'none': ''}} className={index < indctr ? 'hghltBckgrd': null, index === 0 ? 'wrd-wrdspc' : 'wrd-whtspc'}>
                 { (value['value']?.indexOf('%') > -1) ? getArrowMark(value['value'], value['color'], value['sla']) : <b>{value['value']?.trim()}</b> } 
                 </td>
            })}
           </tr>
       )
    };
  
    return(
        <tbody>
          { rows && rows.map((row, indx) => {
                  return buildRow(row, indx);
              })}
        </tbody>
  );
}

const RptTable = (props) => {
    const { headers, rows, tblName } = props;
    let colIndicator = parseInt(props.colIndicator);
    return (       
    <div className="tbl-container">
        <h4>{tblName}</h4>
        <Table className="table-fixed" responsive bordered>
            <TableHeader headers={headers} indctr={colIndicator}></TableHeader>
            <TableBody headers={headers} rows={rows} indctr={colIndicator}></TableBody>
         </Table>
    </div>
    );
}

export default RptTable;