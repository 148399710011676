import React, { useEffect, useState } from 'react';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';
import moment from 'moment';
import DatePickerHandle from '../../../Onboard/OnboardComponents/KeyboardDatePicker';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}


export function SLAAssignmentModal(props) {
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const editMode = props.editMode;
    const [addFormInfo, setAddFormInfo] = useState({})
    const [editFormInfo, setEditFormInfo] = useState({});
    const [placeholder, setPlaceholder] = useState('Example : 38.45 %');
    const [selectedtype, setSelectedType] = useState('');
    const [description, setDescription] = useState('');
    const [AccountsSLA, setAccountsSLA] = useState([])

    const [AccountSLAID, setAccountSLAID] = useState(null)
    useEffect(() => {
        if (editMode) {
            setValidFrom(props.editedInfo.value6);
            setValidTo(props.editedInfo.value7);
            let temp_Arr = {
                value1: props.editedInfo.value1,
                value2: props.editedInfo.value2,
                value3: (props.editedInfo.value3),
                value4: (props.editedInfo.value4),
                value5: (props.editedInfo.value5),
                value6: (props.editedInfo?.value6),
                value7: (props.editedInfo?.value7),
                value8: props.editedInfo?.value8
            }
            setEditFormInfo(temp_Arr);
            console.log(props.data,)
            let SLA = props.SLATemplatesForEdit.find((a, i, arr) => a?.['template Name'] === props.editedInfo.value3)
            console.log(SLA)
             setDescription(SLA?.description)
             setAccountSLAID(SLA?.accountSLAID)
             formatTypeManager(SLA?.formatType)
        }
    }, []);



    const formatTypeManager = (value) => {
        if (!isNaN(value)) {
            setPlaceholder('Example: 000'); setSelectedType('Number');
        }
        else if (value.includes('%')) {
            setPlaceholder('Example : 38.56 %'); setSelectedType('Percentage');
        }
        else if (value.includes(':')) {
            setPlaceholder('HH : MM : SS'); setSelectedType('Time');
        }
    }

    const addValueToTemplate = (name, value, id) => {
        const newFormData = { ...addFormInfo };
        newFormData.value1 = value;
        let accounts = props.data.filter((a, i, arr) => a.account === value)
        setAccountsSLA(accounts)
        setAddFormInfo(newFormData)
    }


    const addValueToSLA = (name, value, id) => {
        const newFormData = { ...addFormInfo };
        newFormData.value3 = value;
        let SLA = props.data.find((a, i, arr) => a.accountSLAID === id)
        newFormData.value2 = SLA?.['slA/KPI'];
        setDescription(SLA?.description)
        formatTypeManager(SLA?.formatType)
        setAccountSLAID(SLA?.accountSLAID)
        setAddFormInfo(newFormData)
    }

    const handleAddFormSLA = event => {
        if (editMode) {
            event.preventDefault();
            const fieldName = event.target.getAttribute("name");
            const fieldValue = event.target.value;
            const newFormData = { ...editFormInfo };
            newFormData[fieldName] = fieldValue;
            setEditFormInfo(newFormData);
        }
        else {
            event.preventDefault();
            const fieldName = event.target.getAttribute("name");
            const fieldValue = event.target.value;
            const newFormData = { ...addFormInfo };
            newFormData[fieldName] = fieldValue;
            setAddFormInfo(newFormData);
        }
    }

    const addDateToSLA = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData[name] = value;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData[name] = value;
            setAddFormInfo(newFormData)
        }
    }
    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        addDateToSLA('value6', formatDate(date))
    }
    const ValidToDateHandler = (date) => {
        setValidTo(formatDate(date));
        addDateToSLA('value7', formatDate(date))
    }
    const [validation, setValidation] = useState('');

    const FormatTypeValidation = (type, value) => {
        switch (type) {
            case 'Percentage': {
                return value?.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && value?.includes('%');
            }
            case 'Time': {
                return moment(value, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A'], true).isValid();
            }
            case 'Number': {
                return !isNaN(value);
            }
            default: { }
        }
    }
    const onSubmitAccount = (event) => {
        event.preventDefault();
        const temp_Arr = !editMode ? {
            value1: addFormInfo.value1,
            value2: addFormInfo.value2,
            value3: addFormInfo.value3,
            value4: (addFormInfo.value4),
            value5: (addFormInfo.value5),
            value6: (addFormInfo.value6),
            value7: (addFormInfo.value7)
        } : {
            value1: editFormInfo.value1,
            value2: editFormInfo.value2,
            value3: editFormInfo.value3,
            value4: (editFormInfo.value4),
            value5: (editFormInfo.value5),
            value6: (editFormInfo.value6),
            value7: (editFormInfo.value7)
        }
        const slaRepeat = Object.values(props.SLAData).some(x => x.value3 === addFormInfo.value3);
        const isEmpty = Object.values(temp_Arr).some(temp => (temp === null || temp === '' || temp === undefined));
        const isValidType = FormatTypeValidation(selectedtype, temp_Arr.value4)
        const isValidDate = moment(temp_Arr.value7).isAfter(temp_Arr.value6)
        const isWeightingValidated = temp_Arr.value5?.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && temp_Arr.value5?.includes('%');
        if (isEmpty) {
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else if (!isValidDate) {
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ')
        }
        else if (!isValidType) {
            setValidation('Please ensure Target type entry is relevant to the format selected ')
        }
        else if (!isWeightingValidated) {
            setValidation('Please ensure Weighting entry is relevant to the "%" format')
        }
        else if (slaRepeat){
            setValidation('Item Name already exists.Please Select Different Item Name')
        }
        else {
            props.onAccountSubmitHandle(editMode ? editFormInfo : addFormInfo, AccountSLAID)
            setValidation('');
            setAddFormInfo(null);
        }
    }
    return (
        <>
            <section className='add-role-container'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'>{editMode ? 'EDIT' : 'ADD'} SLA/KPI   </div>
                    <div style={{ color: '#ED4F32' }}>{validation}</div>
                    <form onSubmit={onSubmitAccount} >
                        <main className='input-container-one' style={{ marginTop: 0 }}>
                            <div className='container-one' style={{ width: "30%" }}>
                                <label HtmlFor="role">Account Name *</label>
                                {editMode ? <div><b>{editFormInfo?.value1}</b></div>
                                    : <OnboardSelect data={props.slaAccounts} name='value1' value='customer' id='customer'
                                        addValueToQueue={addValueToTemplate}
                                    />}
                            </div>
                            <div className='container-one' style={{ width: '40%' }} >
                                <label >Item Name *</label>
                                {editMode ? <div><b>{editFormInfo?.value3}</b></div> :
                                    <OnboardSelect data={AccountsSLA} name='value2' value='template Name' id='accountSLAID'
                                        editValue={editMode ? props.editFormInfo.value1 : ''} addValueToQueue={addValueToSLA}
                                    />}
                            </div>
                        </main>
                        <main className='input-container-three' style={{ marginTop: -10 }}>
                            <div className='container-three'>
                                <label >Target *</label>
                                {editMode ? <input type='text'
                                    name='value4'
                                    style={{ marginTop: '-10px' }}
                                    required='required'
                                    placeholder={placeholder}
                                    value={editFormInfo.value4}
                                    onChange={handleAddFormSLA} /> :
                                    <input type='text'
                                        name='value4'
                                        style={{ marginTop: '-10px' }}
                                        required='required'
                                        placeholder={placeholder}
                                        onChange={handleAddFormSLA}
                                    />}
                            </div>
                            <div className='container-three'>
                                <label >Weighting *</label>
                                {editMode ? <input type='text'
                                    name='value5'
                                    style={{ marginTop: '-10px' }}
                                    placeholder='Example: 40 %'
                                    value={editFormInfo.value5}
                                    onChange={handleAddFormSLA}
                                /> : <input type='text'
                                    name='value5'
                                    style={{ marginTop: '-10px' }}
                                    placeholder='Example: 40 %'
                                    onChange={handleAddFormSLA}
                                />}
                            </div>
                        </main>

                        <main className='input-container-three'>
                            <DatePickerHandle label='Valid From *'
                                value={ValidFrom}
                                onChange={(date) => ValidFromDateHandler(date)}
                            />
                            <DatePickerHandle label='Valid To *'
                                value={ValidTo}
                                onChange={(date) => ValidToDateHandler(date)}
                            />
                        </main>
                        <main className='input-container-two' style={{ width: 600, marginBottom: 0 }}>
                            <div className='container-two'>
                                <label >Description</label>
                                <div>{description}</div>
                            </div>
                        </main>

                        <main style={{ display: 'flex', marginLeft: '130px', marginTop: '10px', gap: '30px' }}>
                            <button type='submit' className='btn_adduser'>{editMode ? 'UPDATE' : 'ADD'} 
                            </button>
                            <button className='btn_canceluser' onClick={props.handleAddRoleCancel}>CANCEL 
                            </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export function VolumeAssignmentModal(props) {
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const editMode = props.editMode;
    const [addFormInfo, setAddFormInfo] = useState({})
    const [editFormInfo, setEditFormInfo] = useState({});
    const [placeholder, setPlaceholder] = useState('Example : 38.45 %');
    const [selectedtype, setSelectedType] = useState('');
    const [description, setDescription] = useState('');
    const [AccountsVolumetric, setAccountsVolumetric] = useState([])

    const [AccountVolumeID, setAccountVolumeID] = useState(null)
    useEffect(() => {
        if (editMode) {
            setValidFrom(props.editedInfo.value6);
            setValidTo(props.editedInfo.value7);
            let temp_Arr = {
                value1: props.editedInfo.value1,
                value2: props.editedInfo.value2,
                value3: (props.editedInfo.value3),
                value4: (props.editedInfo.value4),
                value5: (props.editedInfo.value5),
                value6: (props.editedInfo?.value6),
                value7: (props.editedInfo?.value7),
                value8: props.editedInfo?.value8
            }
            setEditFormInfo(temp_Arr);
            let SLA = props.VolumeTemplatesForEdit.find((a, i, arr) => a?.['template Name'] === props.editedInfo.value3)
            console.log('sla::::::::::',SLA)
             setDescription(SLA?.description)
             setAccountVolumeID(SLA?.accountVoumetricID)
             formatTypeManager(SLA?.formatType)
        }
    }, []);



    const formatTypeManager = (value) => {
        if (!isNaN(value)) {
            setPlaceholder('Example: 000'); setSelectedType('Number');
        }
        else if (value.includes('%')) {
            setPlaceholder('Example : 38.56 %'); setSelectedType('Percentage');
        }
        else if (value.includes(':')) {
            setPlaceholder('HH : MM : SS'); setSelectedType('Time');
        }
    }

    const addValueToTemplate = (name, value, id) => {
        const newFormData = { ...addFormInfo };
        newFormData.value1 = value;
        let accounts = props.VolumeTemplates.filter((a, i, arr) => a.account === value)
        setAccountsVolumetric(accounts)
        setAddFormInfo(newFormData)
    }


    const addValueToSLA = (name, value, id) => {
        const newFormData = { ...addFormInfo };
        newFormData.value3 = value;
        let SLA = props.VolumeTemplates.find((a, i, arr) => a.accountVoumetricID === id)
        newFormData.value2 = SLA?.['slA/KPI'];
        setDescription(SLA?.description)
        formatTypeManager(SLA?.formatType)
        setAccountVolumeID(SLA?.accountVoumetricID)
        setAddFormInfo(newFormData)
    }

    const handleAddFormSLA = event => {
        if (editMode) {
            event.preventDefault();
            const fieldName = event.target.getAttribute("name");
            const fieldValue = event.target.value;
            const newFormData = { ...editFormInfo };
            newFormData[fieldName] = fieldValue;
            setEditFormInfo(newFormData);
        }
        else {
            event.preventDefault();
            const fieldName = event.target.getAttribute("name");
            const fieldValue = event.target.value;
            const newFormData = { ...addFormInfo };
            newFormData[fieldName] = fieldValue;
            setAddFormInfo(newFormData);
        }
    }

    const addDateToSLA = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData[name] = value;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData[name] = value;
            setAddFormInfo(newFormData)
        }
    }
    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        addDateToSLA('value6', formatDate(date))
    }
    const ValidToDateHandler = (date) => {
        setValidTo(formatDate(date));
        addDateToSLA('value7', formatDate(date))
    }
    const [validation, setValidation] = useState('');

    const FormatTypeValidation = (type, value) => {
        switch (type) {
            case 'Percentage': {
                return value?.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && value?.includes('%');
            }
            case 'Time': {
                return moment(value, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A'], true).isValid();
            }
            case 'Number': {
                return !isNaN(value);
            }
            default: { }
        }
    }
    const onSubmitAccount = (event) => {
        event.preventDefault();
        const temp_Arr = !editMode ? {
            value1: addFormInfo.value1,
            value2: addFormInfo.value2,
            value3: addFormInfo.value3,
            value4: (addFormInfo.value4),
            value5: (addFormInfo.value5),
            value6: (addFormInfo.value6),
            value7: (addFormInfo.value7)
        } : {
            value1: editFormInfo.value1,
            value2: editFormInfo.value2,
            value3: editFormInfo.value3,
            value4: (editFormInfo.value4),
            value5: (editFormInfo.value5),
            value6: (editFormInfo.value6),
            value7: (editFormInfo.value7)
        }
        const volumeRepeat = Object.values(props.VolumeData).some(x => x.value3 === addFormInfo.value3);
        const isEmpty = Object.values(temp_Arr).some(temp => (temp === null || temp === '' || temp === undefined));
        const isValidType = FormatTypeValidation(selectedtype, temp_Arr.value4)
        const isValidDate = moment(temp_Arr.value7).isAfter(temp_Arr.value6)
        const isWeightingValidated = temp_Arr.value5?.match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) === null && temp_Arr.value5?.includes('%');
        if (isEmpty) {
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else if (!isValidDate) {
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ')
        }
        else if (!isValidType) {
            setValidation('Please ensure Target type entry is relevant to the format selected ')
        }
        else if (!isWeightingValidated) {
            setValidation('Please ensure Weighting entry is relevant to the "%" format')
        }
        else if (volumeRepeat){
            setValidation('Item Name already exists.Please Select Different Item Name')
        }
        else {
            console.log(AccountVolumeID)
            props.onAccountSubmitHandle(editMode ? editFormInfo : addFormInfo, AccountVolumeID)
            setValidation('');
            setAddFormInfo(null);
        }
    }
    return (
        <>
            <section className='add-role-container'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'>{editMode ? 'EDIT' : 'ADD'} Volumetrics   </div>
                    <div style={{ color: '#ED4F32' }}>{validation}</div>
                    <form onSubmit={onSubmitAccount} >
                        <main className='input-container-one' style={{ marginTop: 0 }}>
                            <div className='container-one' style={{ width: "30%" }}>
                                <label HtmlFor="role">Account Name *</label>
                                {editMode ? <div><b>{editFormInfo?.value1}</b></div>
                                    : <OnboardSelect data={props.slaAccounts} name='value1' value='customer' id='customer'
                                        addValueToQueue={addValueToTemplate}
                                    />}
                            </div>
                            <div className='container-one' style={{ width: '40%' }} >
                                <label >Item Name *</label>
                                {editMode ? <div><b>{editFormInfo?.value3}</b></div> :
                                    <OnboardSelect data={AccountsVolumetric} name='value2' value='template Name' id='accountVoumetricID'
                                        editValue={editMode ? props.editFormInfo.value1 : ''} addValueToQueue={addValueToSLA}
                                    />}
                            </div>
                        </main>
                        <main className='input-container-three' style={{ marginTop: -10 }}>
                            <div className='container-three'>
                                <label >Target *</label>
                                {editMode ? <input type='text'
                                    name='value4'
                                    style={{ marginTop: '-10px' }}
                                    required='required'
                                    placeholder={placeholder}
                                    value={editFormInfo.value4}
                                    onChange={handleAddFormSLA} /> :
                                    <input type='text'
                                        name='value4'
                                        style={{ marginTop: '-10px' }}
                                        required='required'
                                        placeholder={placeholder}
                                        onChange={handleAddFormSLA}
                                    />}
                            </div>
                            <div className='container-three'>
                                <label >Weighting *</label>
                                {editMode ? <input type='text'
                                    name='value5'
                                    style={{ marginTop: '-10px' }}
                                    placeholder='Example: 40 %'
                                    value={editFormInfo.value5}
                                    onChange={handleAddFormSLA}
                                /> : <input type='text'
                                    name='value5'
                                    style={{ marginTop: '-10px' }}
                                    placeholder='Example: 40 %'
                                    onChange={handleAddFormSLA}
                                />}
                            </div>
                        </main>

                        <main className='input-container-three'>
                            <DatePickerHandle label='Valid From *'
                                value={ValidFrom}
                                onChange={(date) => ValidFromDateHandler(date)}
                            />
                            <DatePickerHandle label='Valid To *'
                                value={ValidTo}
                                onChange={(date) => ValidToDateHandler(date)}
                            />
                        </main>
                        <main className='input-container-two' style={{ width: 600, marginBottom: 0 }}>
                            <div className='container-two'>
                                <label >Description</label>
                                <div>{description}</div>
                            </div>
                        </main>

                        <main style={{ display: 'flex', marginLeft: '130px', marginTop: '10px', gap: '30px' }}>
                            <button type='submit' className='btn_adduser'>{editMode ? 'UPDATE' : 'ADD'} 
                            </button>
                            <button className='btn_canceluser' onClick={props.handleAddRoleCancel}>CANCEL 
                            </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}