import React from 'react';
import './trails.css';


function ReactTrails() {
  return (
    <><input />
    
    </>
  )
}

export default ReactTrails