import { adminViewActionTypes as actionTypes } from "./../../constants/Administration/adminView-actionTypes";

const initialState = {
   adminViewRoleData:[],
   accessGroupData:[],
   operationsData:[],
   accessGroupMainData:[],
   accessGroupEditData:[],
   accessGroupAddData:[],
   timezoneData:[],
   EditUserData:[],
   regionData:[],
   UPNValidation:[],
   userflowUsers:[],
   userflowCount:[],
   saveUserStoredFilter:[],
   saveUserStoredStatus:[],
   auditRBACData:[],
   auditRoleData:[]
}

const adminViewReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ADMIN_ROLES:
        case actionTypes.GET_ACCESS_GROUP:
        case actionTypes.GET_OPERATIONS_DATA:
        case actionTypes.GET_ACCESSGROUP_DATA:
        case actionTypes.GET_ACCESSGROUP_EDIT_DATA:
        case actionTypes.GET_ACCESSGROUP_ADD_DATA:
        case actionTypes.GET_TIMEZONE_DATA:
        case actionTypes.GET_EDIT_USER_DATA:
        case actionTypes.GET_REGION_DATA:
        case actionTypes.GET_UPN_VALIDATION:
        case actionTypes.GET_USERFLOW_USERS:
        case actionTypes.GET_USERFLOW_COUNT:
        case actionTypes.GET_AUDITRBAC_DATA:
        case actionTypes.GET_AUDITROLE_DATA:
            return {
                ...state,
            };
        case actionTypes.ADMIN_ROLES:
            return { ...state, adminViewRoleData: action.payload };
        case actionTypes.ACCESS_GROUP:
            return { ...state, accessGroupData: action.payload };
        case actionTypes.OPERATIONS_DATA:
            return { ...state, operationsData: action.payload };
        case actionTypes.ACCESSGROUP_DATA:
            return { ...state, accessGroupMainData: action.payload };
        case actionTypes.ACCESSGROUP_EDIT_DATA:
            return { ...state, accessGroupEditData: action.payload };
        case actionTypes.ACCESSGROUP_ADD_DATA:
            return { ...state, accessGroupAddData: action.payload };
        case actionTypes.TIMEZONE_DATA:
            return { ...state, timezoneData: action.payload };
        case actionTypes.EDIT_USER_DATA:
            return { ...state, EditUserData: action.payload };
        case actionTypes.REGION_DATA:
            return { ...state, regionData: action.payload };
        case actionTypes.UPN_VALIDATION:
            return { ...state, UPNValidation: action.payload };
        case actionTypes.USERFLOW_USERS:
            return { ...state, userflowUsers: action.payload };
        case actionTypes.USERFLOW_COUNT:
            return { ...state, userflowCount: action.payload };
        case actionTypes.SAVE_USER_STORED_FILTER:
            return { ...state, saveUserStoredFilter: action.payload };
        case actionTypes.SAVE_USER_STORED_STATUS:
            return { ...state, saveUserStoredStatus: action.payload };
        case actionTypes.AUDITRBAC_DATA:
            return { ...state, auditRBACData: action.payload };
        case actionTypes.AUDITROLE_DATA:
            return { ...state, auditRoleData: action.payload };
        default: return state;
    }
}


export default adminViewReducer