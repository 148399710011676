import { KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react'
// import Information_Icon from '../../../../assets/images/Information_Icon.png';
import Information_Icon from './../../../assets/onboard-images/Information_Icon.png'

import TooltipHandler from './TooltipHandler';

function DatePickerHandle(props) {
    return (
        <>
            <div className={props.className}>
                <label id={props.id} style={{ display: 'flex' }}>
                    <span>{props.label} &nbsp;</span>
                    <span style={{ position: 'relative' }} >
                        <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right' arrowAlign={props.label === 'Valid From' ? 'start' : 'end'}
                            textBoxWidth='260px' info={true} text={props.label === 'Valid From' ? 'Please set the start date of the defined measurement.' : 'Please set the end date of the defined measurement.'} />
                    </span>
                </label>
                <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    name='valid_from'
                    clearable
                    value={props.value}
                    placeholder="MM/DD/YYYY"
                    onChange={(date) => props.onChange(date)}
                    // minDate={new Date('01/01/2020')}
                    format="MM/DD/YYYY"
                    style={{ width: props.width }}
                />
            </div>

        </>
    )
}

export default DatePickerHandle