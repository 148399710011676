import React, { useEffect } from 'react'
import { OnboardSelect } from './../../../Onboard/OnboardComponents/Dropdown/Select';
import { useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import './EmployeeDetails.css'
import { RequestRole, RequestRoleModelPopup } from './RequestNewRoleModel';
import DatePickerHandle from './../../../Onboard/OnboardComponents/KeyboardDatePicker';
import InformationIcon from "./../../../../assets/images/Information_Icon.png";
import dataSaveClose_icon from "./../../../../assets/onboard-images/dataSaveClose_icon.png";


import ToolTip from 'react-power-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRoleCategory, updateSupervisorEmployeeMappingStart } from '../../../../actions/Administration/staffAdmin.action';
import moment from 'moment';
import RequestDropdown from '../../../Onboard/OnboardComponents/Dropdown/RequestDropdown';
import { ActivateModelPopup, DataRetainModel } from '../Employee-Assignment/AssignModel';
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';
const Newemployee = (props) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const supervisorInfo = useSelector((state) => state.staffAdminReducer.supervisorInfo);
  const roleCategory = useSelector((state) => state.staffAdminReducer.roleCategory);
  const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
  useEffect(() => {
    dispatch(getRoleCategory());
    dispatch(getRoleBasedView())
  }, [dispatch])
console.log('supervisorInfo::::::::::',supervisorInfo)

  const [supervisorMapData, setSupervisorMapData] = useState();
  const [showRequestRole, setShowRequestRole] = useState();
  const [showRoleRequestSent, setShowRoleRequestSent] = useState();
  const [showDataRetainModel,setShowDataRetainModel] = useState(false)
  const [ValidFrom, setValidFrom] = useState(supervisorInfo.HireDate);
  const [ValidTo, setValidTo] = useState(new Date(''));
  const [showInfobar, setShowInfobar] = useState(false);
  const [validation, setValidation] = useState(false);
  const [toggleValue, setToggleValue] = useState(supervisorInfo.view === 'edit' ? true : false);
  const [showTooltip, setShowTooltip] = useState(false);
  const[dataRetain,setDataRetain] =useState('')

  const [userRoleSelect, setUserRoleSelect] = useState([])
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const handleRoleCategorySelect = (name, value, id) => {
    let temp = { ...supervisorMapData };
    temp[name] = value;
    setSupervisorMapData(temp);
    let arr1 = [...roleCategory];
    let arr2 = arr1.find((a, i, arr) => a.RoleCategory === value)
    let selectedRole = arr2.RoleName;
    setUserRoleSelect(selectedRole);
  }

  const handleUserRoleSelect = (name, value, id) => {
    let temp = { ...supervisorMapData };
    temp[name] = value;
    setSupervisorMapData(temp);
  }
  const ValidFromDateHandler = (date) => {
    setValidFrom(formatDate(date));
    let temp = { ...supervisorMapData };
    temp.ValidFrom = formatDate(date);
    setSupervisorMapData(temp);
    setToggleValue(false)
  }
  const ValidToDateHandler = (date) => {
    setValidTo(formatDate(date));
    let temp = { ...supervisorMapData };
    temp.ValidTo = formatDate(date);
    setSupervisorMapData(temp);
    setToggleValue(false)
  }
 props.setcompareValidTo(supervisorMapData?.ValidTo)

 const formatDatefromString = (seldate) =>{
  var date = new Date(seldate); // M-D-YYYY
var d = date.getDate();
var m = date.getMonth() + 1;
var y = date.getFullYear();

return (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
}


  const onChangeGeneraltoggle = (event) => {
    const checked = event.target.checked;
    props.AccountCreatedHandle(checked)
    let temp = { ...supervisorMapData };
    temp.EmployeeID = supervisorInfo.EmployeeID;
    temp.SupEmployeeID = supervisorInfo.SupervisorID;
    let dispObj = {
      EmployeeID : temp.EmployeeID,
      RoleCategory : temp.RoleCategory,
      SupEmployeeID : temp.SupEmployeeID,
      UserRole : temp.UserRole,
      ValidFrom : temp.ValidFrom ? temp.ValidFrom : supervisorInfo.HireDate,
      ValidTo : temp.ValidTo,
      YesNo: 1
    }
    console.log(dispObj)
    const temp_Arr = {
      EmployeeID: temp.EmployeeID,
      SupervisorID: temp.SupEmployeeID,
      ValidFrom: temp.ValidFrom ? temp.ValidFrom : supervisorInfo.HireDate,
      ValidTo: temp.ValidTo,
      roleCategory: temp.RoleCategory,
      UserRole: temp.UserRole
    };
    const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined || x === 'Invalid Date'));
    const isValidDate = moment(temp.ValidTo).isAfter(temp.ValidFrom ? temp.ValidFrom : supervisorInfo.HireDate)
    console.log(temp.ValidTo , formatDatefromString(supervisorInfo.ValidTo))
    console.log(moment(temp.ValidTo).isAfter(formatDatefromString(supervisorInfo.ValidTo)))
    const compareValidToDate = moment(temp.ValidTo).isAfter(formatDatefromString(supervisorInfo.ValidTo))
    const compareValidFromDate = moment(temp.ValidFrom ? temp.ValidFrom : supervisorInfo.HireDate).isAfter(formatDatefromString(supervisorInfo.ValidTo))
    console.log(compareValidToDate)
    const ExistingValidToEmployee = supervisorInfo.NewExisting === 1 ? compareValidToDate : true ;
    const ExistingValidFromEmployee = supervisorInfo.NewExisting === 1 ? compareValidFromDate : true ;
    
    if (isEmptyInput) {
      setShowInfobar(true);
      setValidation('Please Ensure you filled all the above input to onboard an Account ')
    }
    else if(!ExistingValidFromEmployee){
      setShowInfobar(true);
      setValidation(`Please ensure  ValidFrom is after ${formatDatefromString(supervisorInfo.ValidTo)}`)
    }
    else if(!ExistingValidToEmployee){
      setShowInfobar(true);
      setValidation(`Please ensure  ValidTo is after ${formatDatefromString(supervisorInfo.ValidTo)}`)
    }
    else if (!isValidDate) {
      setShowInfobar(true);
      setValidation('Please ensure  "Valid To" date is placed after "Valid From" date')
    }
    if (!isEmptyInput && isValidDate && ExistingValidToEmployee && ExistingValidFromEmployee) {
      setValidation('')
      setShowInfobar(false)
      if(supervisorInfo.NewExisting === 1){
        setShowDataRetainModel(true)
      }
      else{
        console.log(dispObj)
      dispatch(updateSupervisorEmployeeMappingStart(dispObj))
      setToggleValue(true)
      }
    }
  }

  useEffect(() => {
    if (supervisorInfo?.view === 'edit') {
      var temp_Arr = {
        EmployeeID: supervisorInfo.EmployeeID,
        SupEmployeeID: supervisorInfo.SupervisorID,
        ValidFrom: formatDate(supervisorInfo.ValidFrom),
        ValidTo: formatDate(supervisorInfo.ValidTo),
        RoleCategory: supervisorInfo.RoleCategory,
        UserRole: supervisorInfo.UserRole
      }
      setSupervisorMapData(temp_Arr)
      setValidFrom(supervisorInfo.ValidFrom);
      setValidTo(supervisorInfo.ValidTo);
    }
  }, [])

  const [roleData, setRoleData] = useState({})
  const handleRequestSubmit = data => {
    setRoleData(data);
    setShowRequestRole(false);
    setShowRoleRequestSent(true)
  }

  let disptemp = { ...supervisorMapData };
  disptemp.EmployeeID = supervisorInfo.EmployeeID;
  disptemp.SupEmployeeID = supervisorInfo.SupervisorID;
  let dispYesDataRetainObj = {
          EmployeeID : disptemp.EmployeeID,
          RoleCategory : disptemp.RoleCategory,
          SupEmployeeID : disptemp.SupEmployeeID,
          UserRole : disptemp.UserRole,
          ValidFrom : disptemp.ValidFrom ? disptemp.ValidFrom : supervisorInfo.HireDate,
          ValidTo : disptemp.ValidTo,
          YesNo: 1
        }
        console.log(dispYesDataRetainObj)


const YesDataRetainHandle = () =>{
dispatch(updateSupervisorEmployeeMappingStart(dispYesDataRetainObj));
setShowDataRetainModel(false)
setToggleValue(true)
   }

   let dispNoDataRetainObj = {
          EmployeeID : disptemp.EmployeeID,
          RoleCategory : disptemp.RoleCategory,
          SupEmployeeID : disptemp.SupEmployeeID,
          UserRole : disptemp.UserRole,
          ValidFrom : disptemp.ValidFrom ? disptemp.ValidFrom : supervisorInfo.HireDate,
          ValidTo : disptemp.ValidTo,
          YesNo: 0
        }
        console.log(dispNoDataRetainObj)


const NoDataRetainHandle = () =>{
dispatch(updateSupervisorEmployeeMappingStart(dispNoDataRetainObj));
setShowDataRetainModel(false)
setToggleValue(true)
   }
 
 
 
   useEffect(() => {
    props.newEmployeeToggleChange(toggleValue);
  }, [toggleValue,props])
 
 
 
 
 
 
 
 
   // const DataRetain = (data) => {
  //   console.log('data',data)
  //   if(data === 'YES'){
  //     let dispDataRetainObj = {
  //       EmployeeID : disptemp.EmployeeID,
  //       RoleCategory : disptemp.RoleCategory,
  //       SupEmployeeID : disptemp.SupEmployeeID,
  //       UserRole : disptemp.UserRole,
  //       ValidFrom : disptemp.ValidFrom ? disptemp.ValidFrom : supervisorInfo.HireDate,
  //       ValidTo : disptemp.ValidTo,
  //       YesNo: 1
  //     }
  //     dispatch(updateSupervisorEmployeeMappingStart(dispDataRetainObj))
  //     setShowDataRetainModel(false);
  //     setToggleValue(true)
  //   }
  //   else if(data === 'NO'){
  //     let dispNoDataRetainObj = {
  //       EmployeeID : disptemp.EmployeeID,
  //       RoleCategory : disptemp.RoleCategory,
  //       SupEmployeeID : disptemp.SupEmployeeID,
  //       UserRole : disptemp.UserRole,
  //       ValidFrom : disptemp.ValidFrom ? disptemp.ValidFrom : supervisorInfo.HireDate,
  //       ValidTo : disptemp.ValidTo,
  //       YesNo: 0
  //     }
  //     dispatch(updateSupervisorEmployeeMappingStart(dispNoDataRetainObj))
  //     setShowDataRetainModel(false);
  //     setToggleValue(true)
  //   }
  // }
  return (
    <>
      <div className='general_card'>
        <div className='info_header'>General Informations</div>
        <div className='page_text'>This page enables to add new employee mapping. For more guidance, click on the Hints link on the right.</div>
        <section style={{ display: 'flex' }}>
          <main className="emp_border"><div className='emp_name_text'>Employee Name</div><div className='emp_name_selected'>{supervisorInfo.EmployeeName}</div></main>
          <main><div className='emp_name_text'>Primary Functional Supervisor</div><div className='emp_name_selected'>{supervisorInfo.Supervisor}</div></main>
        </section>
        <section style={{ display: "flex" }}>
          <main style={{ width: "25%" }}>
            <div className='container-one_general'>
              <label HtmlFor="role"> Role Category *</label><br />
              <div style={{ width: '90%' }}>
                {supervisorInfo.RoleCategory ? <div><b>{supervisorInfo?.RoleCategory}</b></div> :
                  <RequestDropdown data={roleCategory} name='RoleCategory' value='RoleCategory' id='RoleCategory'
                    inputvalue='Select'
                    addValueToQueue={handleRoleCategorySelect} />}
              </div>
            </div></main>
          <main style={{ width: "25%" }}>
            <div className='container-one_general'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}> <div><label HtmlFor="user"> User Role *</label></div>{supervisorInfo.view === "edit" ? '' :<div className='request_role' onClick={() => setShowRequestRole(true)}>REQUEST NEW ROLE</div>}</div>
              <div style={{ width: '90%' }}>
                {supervisorInfo.UserRole ? <div><b>{supervisorInfo?.UserRole}</b></div> :
                  <OnboardSelect data={userRoleSelect} name='UserRole' value='UserRole' id='UserRole' inputvalue='Select'
                    addValueToQueue={handleUserRoleSelect} />}</div>
            </div></main>
          {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1 ? <main style={{ display: 'flex', gap: '20px' }}>
            <DatePickerHandle label='Valid From *' style={{ marginBottom: 5 }}
              value={ValidFrom} id='label'
              onChange={(date) => ValidFromDateHandler(date)}
            />
            <DatePickerHandle label='Valid To *'
              value={ValidTo} id='label'
              onChange={(date) => ValidToDateHandler(date)}
            />
          </main> : 
          <main style={{ display: 'flex', gap: '100px' }}><div className='container-one_general' style={{ width: '200px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}> <div><label HtmlFor="user"> Valid From*</label></div></div>
              <div><b>{supervisorInfo.ValidFrom}</b></div> </div>
            
            <div className='container-one_general' style={{ width: '200px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}> <div><label HtmlFor="user"> Valid To *</label></div></div>
              <div><b>{supervisorInfo.ValidTo}</b></div></div></main>}

         {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1 ? <div style={{ marginLeft: 10 }}>
            <div className='site' style={{ marginLeft: '20px' }}>Initial Save</div>
            <div style={{ display: 'flex' }}> <span >
              <label class="toggle" style={{ marginLeft: '30%' }}>
                <input type="checkbox"
                  name='accountToggle'
                  checked={toggleValue}
                  onChange={onChangeGeneraltoggle}
                />
                <div class="slider_on"></div>
                <span class="labels" data-on="YES" data-off="NO"></span>
              </label>
            </span>&nbsp; &nbsp;
              <span style={{ position: "relative", cursor: 'pointer' }}><img src={InformationIcon} alt='none' className='i_img'
                onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}  ></img>
                <ToolTip show={showTooltip} textAlign="left" arrowAlign="end" moveRight='33px'
                  position="bottom right" textBoxWidth="400px" borderRadius='0px' fontSize='14/19' color='#1D2833' padding='15px' hoverBackground=''>
                  <span >By selecting the toggle you set an initial saving of this record and being populated in your mapped employees list.</span>
                </ToolTip>
              </span>
            </div>
          </div> : ''}
        </section>
        {showInfobar && <div className='info_bar' style={{ width: '98%', backgroundColor: '#F0AD4E' }} >
          <div> &nbsp;&nbsp;{validation}</div>
          <div><img src={dataSaveClose_icon} alt='' onClick={() => setShowInfobar(false)} /></div>
        </div>}
        {showRequestRole && <RequestRoleModelPopup isOpen={setShowRequestRole} setShowModal={setShowRequestRole} >
          <RequestRole modalName='Delete Role' buttonOne='SUBMIT' buttonTwo='CANCEL'
            handleDeleteQueue={handleRequestSubmit}
            handleDeleteQueueCancel={() => setShowRequestRole(false)}
          />
        </RequestRoleModelPopup>
        }

        {showRoleRequestSent && <RequestRoleModelPopup isOpen={setShowRoleRequestSent} setShowModal={setShowRoleRequestSent} >
          <RequestRole modalName='Delete Role' buttonOne='CLOSE'
            roles={roleData} delete={true} handleClose={() => {
              dispatch(getRoleCategory())
              setShowRoleRequestSent(false); setShowRequestRole(false)
            }}
          />
        </RequestRoleModelPopup>
        }

        {showDataRetainModel && <ActivateModelPopup isOpen={setShowDataRetainModel} setShowModal={setShowDataRetainModel} >
          <DataRetainModel modalName='Retain Assignments' 
          handleYesDataRetain={YesDataRetainHandle}
          handleNoDataRetain={NoDataRetainHandle}
          />
        </ActivateModelPopup>
        }

      </div>
    </>
  )
}

export default Newemployee