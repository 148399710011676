import { combineReducers } from "redux";
import userReducer from "./users.reducer";
import tabReducer from "./tab.reducer";
import financeReducer from "./finance.reducer";
import regionalSiteReducer from "./regionalSite.reducer";
import eDashboardReducer from "./EDashboard/eDashboard.reducer";
import reportTableReducer from "./reports.reducer";
import settingsReducer from "./settings.reducer";
import { onboardReducer } from "./onboard.reducer";
import { uploadReducer } from "./uploads.reducer";
import welcomePageReducer from "./welcomePage.reducer";
import adminAccountReducer from "./Administration/admin.reducer";
import githubReducer from "./github.reducer";
import staffAdminReducer from './Administration/staffAdmin.reducer';
import slaFailureReducer from './Administration/slaFailure.reducer';
import requestReducer from './request.reducer';
import adminViewReducer from './Administration/adminView.reducer';
import ScoreCardReducer from './ScoreCard&TeamKPI.reducer';

const rootReducer = combineReducers({
  userReducer,
  tabReducer,
  financeReducer,
  regionalSiteReducer,
  eDashboardReducer,
  reportTableReducer,
  settingsReducer,
  onboardReducer,
  welcomePageReducer,
  uploadReducer,
  adminAccountReducer,
  staffAdminReducer,
  githubReducer,
  slaFailureReducer,
  requestReducer,
  adminViewReducer,
  ScoreCardReducer
});

export default rootReducer;
