import React, { useEffect, useState } from 'react';
import FilterTab from '../FilterTab';
import AboveMinimalTargetIcon from "../../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../../assets/images/BelowMinimalTarget_Status.png";
import SLAFailureExemption from "../../../../assets/images/SLAFailureExemption_Icon.png";
import DATA from './../DATA.json';
import './../SLA-Admin.css';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import TableBodyHandler from './SLA-Exclusion-Table';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getExclusionDataStart } from '../../../../actions/Administration/SLAFailure.action';
import { useHistory } from 'react-router-dom';


function SLAExclusionHandler() {
    const dispatch = useDispatch();
    const history = useHistory();
    let ExclusionData = useSelector((state) => state.slaFailureReducer.ExclusionData);
    console.log(ExclusionData)
    let AccountDetails = useSelector((state) => state.adminAccountReducer.AccountDetails);
    console.log(ExclusionData)
    const [selectedDate, setSelectedDate] = useState()
    useEffect(() => {
      //  dispatch(getExclusionDataStart('20', '15', '2022-07-01'))
        dispatch(getExclusionDataStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID,formatMonthDate(new Date())))
        setSelectedDate(formatMonthDate(new Date()))
    }, [])


    const formatMonthDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        return [year, month, '01'].join('-');
    }

    const HandleSLAMonth = (value) => {
        setSelectedDate(formatMonthDate(value))
        console.log(formatMonthDate(value))
        dispatch(getExclusionDataStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID,formatMonthDate(value)))
    }
    return (
        <>
            <section className='sla_container'>
            <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} value2={()=>history.push('/admin')} label2='Administration' isActive3={false} 
            label3={ExclusionData?.[0]?.Customer?.Name + ' - ' + ExclusionData?.[0]?.ServiceDesk?.Name + " - SLA Data Exclusion"} />
            <br/>
                <FilterTab selectedItem={ExclusionData?.[0]?.Customer?.Name + ' - ' + ExclusionData?.[0]?.ServiceDesk?.Name +" - SLA Data Exclusion"} 
                buttonValue='SLA Failure Exemption' pathname='exemption' src={SLAFailureExemption} counters={ExclusionData?.[0]?.Counters?.[0]} 
                HandleSLAMonth={HandleSLAMonth} displayimage={AccountDetails.CustomerID}/>
                <div className='legend_div'>
                    <span id='legend'>Legend: </span>
                    <img src={AboveMinimalTargetIcon} alt='' />Above Minimal Target  <img src={BelowMinimalTargetIcon} alt='' />Below Minimal Target
                    <span>  &nbsp; &nbsp;&nbsp;&nbsp; <span id='legend'>WDE</span>&nbsp;&nbsp; With Data Exclusion</span>
                </div>
                <main className='sla_exemption_table'>
                    <table style={{ width: '100%' }} >
                        <thead style={{ width: '100%' }}>
                            <tr>{DATA.headerWDE.map(data => (<th style={{ border: 'none' }} key={data.id}>{data.label}</th>))}</tr>   </thead>
                        <tbody>
                            {ExclusionData?.[0]?.Rows?.map((info, i) => <TableBodyHandler key={i} info={info} selectedDate={selectedDate}/>)}
                        </tbody>
                    </table>
                    <br/><br/>
                    {(ExclusionData?.[0]?.Rows === '' || ExclusionData?.[0]?.Rows === undefined || ExclusionData?.[0]?.Rows === null) ? 
<div className='no_records'>No Data Found for Selected Year & Month </div> : '' }
                    <br/><br/><br/>
                </main>
            </section>
        </>
    )
}

export default SLAExclusionHandler