import React, { useEffect } from 'react'
import { Carousel, CarouselItem, Col, ListGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getRegionalSiteGlobalSummaryData, updateRegionalSiteCustomerID, updateRegionalSiteCustomerName,
    updateRegionID, updateRegionName
} from '../../../actions/regionalSite.action';
import { saveAccountLocationName, saveSelectedMenuItem, updateSelectedAccountName } from '../../../actions/tabMenu.action';
import { getcarouselTitle, getRolesTitleInfo, getTaskLinks, recentlinksInfo, saveSelectedRole } from '../../../actions/welcomePage.action';
import './WelcomePage.css'
import UserGuidesIcon from "../../../assets/images/UserGuides_Icon.png";
import TrainingVideosIcon from "../../../assets/images/TrainingVideos_Icon.png";
import EmailToReportingTeamIcon from "../../../assets/images/EmailToReportingTeam_Icon.png";
import MSChatIcon from "../../../assets/images/MSChat_Icon.png";
import ITSMPortalIcon from "../../../assets/images/ITSMPortal.png";
import { updateRegionalSiteServiceDeskID } from './../../../actions/regionalSite.action';
import { Link } from 'react-router-dom';
import { viewAccountDetails } from '../../../actions/Onboard.action';
import { saveStaffAdminActiveTab } from '../../../actions/Administration/admin.action';

export function CarouselHandler() {
    const dispatch = useDispatch();
    const history = useHistory();
    let carouselItems = useSelector((state) => state.welcomePageReducer.carouselTitle);
    let RolesInfo = useSelector((state) => state.welcomePageReducer.rolesTitle);
    let roleSelected = localStorage.getItem('RoleSelected');
    useEffect(() => {
        dispatch(getcarouselTitle());
        dispatch(saveSelectedRole());
        dispatch(getRolesTitleInfo());
    }, [dispatch]);
   

    useEffect(() => {
        RolesInfo?.length === 1 && localStorage.setItem('RoleSelected', RolesInfo?.[0]?.name)
    }, [])
    const getDeployedDates = (date) => {
        let d = new Date(date)
        let year = d.getFullYear();
        let month = d.toLocaleString('default', { month: 'long' });
        let week = d.toLocaleDateString('default', { weekday: 'long' });
        let day = d.getDate();
        return `${week} ${month} ${day},  ${year}`
    }
    return (
        <>
            <div className="column_1">
                <div className="card_1">
                    <div className="column_agent">
                        <div className="card_agent">
                            <div className='d-flex'>
                                <Col>
                                {/* <img className='img_agent' src={AgentPhoto} alt="none"></img> */}
                                <img
                    style={{maxHeight:'86px',maxWidth:'86px'}}
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/users/me/photo`}
                  alt="Client Logo"
                />
                                </Col>
                                <Col><div className="account">{RolesInfo?.length === 1 ? RolesInfo?.[0]?.name : roleSelected}</div>
                                    <div><div className='btn_profile' onClick={() => history.push("/welcome/profile")}>VIEW PROFILE</div></div></Col>
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="column_latest">
                        <div className="card_latest">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='latest'>Latest Updated</div>
                                <div style={{ marginLeft: '7px', cursor: "pointer" }}
                                    onClick={() => history.push('/welcome/release')} className="view_arrow">VIEW ALL</div>
                            </div>
                            <hr />
                            <Carousel style={{ cursor: 'pointer' }}>
                                {carouselItems?.[0]?.items?.map((item, index) => (<CarouselItem key={index}>
                                  <a href={item.link}>  <div className='carousel_card' key={index}> <span className='Release'>{item.title}</span>
                                        <br />
                                        <span className='date_welcome'>{getDeployedDates(item.date)}</span></div></a>
                                </CarouselItem>))}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export function RecentLinks() {
    let Recentlinks = useSelector((state) => state.welcomePageReducer.recentLinks);
    const dispatch = useDispatch();
    const history = useHistory();
    const viewDTMReports = (service_id, c_name, cust_id, service_desk) => {
        if (service_id) {
            window.localStorage.setItem('ServiceDeskID', service_id);
            dispatch(updateRegionalSiteServiceDeskID(service_id))
        }
        if (cust_id) {
            window.localStorage.setItem('CustomerID', cust_id);
        }
        dispatch(updateRegionalSiteCustomerID(cust_id));
        dispatch(updateRegionalSiteCustomerName(c_name));
        dispatch(updateSelectedAccountName(c_name));
        dispatch(saveAccountLocationName(service_desk));
        dispatch(saveSelectedMenuItem("reports"));
        history.push("/reports");
    };

    const viewRegionalHandler = (regionID, region, c_name, selectedAccCustID, isoDateString) => {
        dispatch(updateRegionID(regionID));
        dispatch(updateRegionName(region));
        dispatch(updateRegionalSiteCustomerName(c_name));
        dispatch(updateSelectedAccountName(c_name));
        dispatch(getRegionalSiteGlobalSummaryData(selectedAccCustID, 0, regionID, isoDateString));
        history.push('/regional_summary');
    }

    const glblSmmryBtnClickHandler = (selectedAccCustID, c_name, isoDateString) => {
        dispatch(getRegionalSiteGlobalSummaryData(selectedAccCustID, 0, 0, isoDateString));
        dispatch(updateRegionalSiteCustomerName(c_name));
        dispatch(updateSelectedAccountName(c_name));
        history.push('/regional_global_summary');
    };
    useEffect(() => {
        dispatch(recentlinksInfo());
    }, [dispatch]);

    // let temp_arr = {
    //     AccountName:"Commerzbank (Germany)",
    //     SiteName: "Ariana, Tunisia",
    //     ValidFrom: "2023-03-01",
    //     ValidTo:"2023-03-31",
    //     mode: 'edit',
    //   }
    const roleSelected = localStorage.getItem('RoleSelected');
    const RecentLinksHandler = (hint, s_id, c_name, c_id, s_name, r_id, r_name) => {
        let dt = '2022-04-01';
        switch (hint) {
            case 'DTM': viewDTMReports(s_id, c_name, c_id, s_name); break;
            case 'regional': viewRegionalHandler(r_id, r_name, c_name, c_id, dt); break;
            case 'global': glblSmmryBtnClickHandler(c_id, c_name, dt); break;
            case 'eDashboard': history.push('/eDashboard'); break;
            // case 'onboard' : dispatch(viewAccountDetails(temp_arr))
            // history.push('/onboardAccount'); break;
            case 'request': history.push(roleSelected !== 'Admin' ? '/request' : '/adminrequest'); break;
            default: history.push(`/${hint}`);
        }
    }

    const RecentLinkNamesHandle = (hint, customer) => {
        switch (hint) {
            case 'undefined': return 'Executive Dashboard (Charts and graphs)';
            case 'abrate': return 'Abandon Rate Report';
            case 'contact': return 'Contact Volume Report';
            case 'aht': return 'Average Handle Time Report';
            case 'digital': return 'Digital Trans Report';
            case 'cpc': return 'Cost per Contact Report';
            case 'fte': return 'Contact per FTE Report';
            case 'penalty': return 'Penalty at Risk Report';
            case 'fcr': return 'First Contact Resolution Report';
            case 'attrition': return 'Attrition Report';
            case 'sla': return 'SLA Compliance Report';
            case 'csat': return 'CSAT Report';
            case 'delivery': return 'Delivery Support Report';
            case 'DTM': return `${customer} (DTM reports) `;
            case 'regional': return `${customer} (regional summary) `;
            case 'global': return `${customer} (global summary) `;
            case 'finance': return 'ASD Cost Recovery Finance Report'
            case 'eDashboard': return 'Executive Global Dashboard';
            case 'onboard' : return 'OnBoard';
            case 'admin' : return 'Administration'
            case 'request' : return 'Request'
            default: return customer;
        }
    }


    return (
        <>
            <div className="column_1">
                <div className="card_2">
                    <div className="card_links">
                        <ListGroup variant="flush">
                            <ListGroup.Item><span className='linkhead'>My Recent Links</span></ListGroup.Item>
                            {Recentlinks?.map((links, index) =>
                                <ListGroup.Item style={{ minHeight: '48px', padding: "10px" }} key={index}>
                                    <span onClick={() => RecentLinksHandler(links.hint,
                                    links.serviceDeskId, links.customerName,
                                    links.customerId, links.serviceDeskName,
                                    links.regionId, links.regionName)} key={index} className='link'>
                                    {RecentLinkNamesHandle(links.hint, links.customerName)}</span></ListGroup.Item>
                            )}
                        </ListGroup>
                    </div>
                </div>
            </div>
        </>
    )
}

const HELP_CENTER_LINKS = [
    {
        id: 1,
        imageSrc: UserGuidesIcon,
        href: "/githublanding",
        name: 'User Guides',
        title: ''
    },
    {
        id: 2,
        imageSrc: TrainingVideosIcon,
        href: "/training",
        name: 'Trainings',
        title: ''
    },
    {
        id: 3,
        imageSrc: EmailToReportingTeamIcon,
        title: "GSD_Insight_Support@dxc.com",
        name: 'Email to Reporting Team',
        href: "mailto:GSD_Insight_Support@dxc.com"
    },
    {
        id: 4,
        imageSrc: MSChatIcon,
        href: "https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&amp;tenantId=93f33571-550f-43cf-b09f-cd331338d086",
        name: 'Microsoft Teams Chat',
        title: "https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&amp;tenantId=93f33571-550f-43cf-b09f-cd331338d086"
    },
    {
        id: 5,
        imageSrc: ITSMPortalIcon,
        href: "/request",
        name: 'ITSM Service Portal',
        title: ''
    }
]

export function HelpCenterLinks() {
    return (
        <div className="column_1">
            <div className="card_2">
                <div className="card_links">
                    <ListGroup variant="flush">
                        <ListGroup.Item><span className='linkhead'>Help Center</span></ListGroup.Item>
                        {HELP_CENTER_LINKS.map((link, index) => (<ListGroup.Item style={{ height: '52px' }} key={index}>
                            <span><img src={link.imageSrc} className="margin_1" alt="none" /></span>
                            {(link.name === 'User Guides' || link.name === 'Trainings' || link.name === 'ITSM Service Portal') ? <Link to={link.href} target='_blank'> <span className='link_userlinks'> {link.name}</span></Link>  : 
                            <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={link.href}
                      title="" >  <span className='link_userlinks'>{link.name}</span> </a>}
                        </ListGroup.Item>))}
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}


export function NOTIFICATIONS() {
    const history = useHistory();
    const dispatch = useDispatch();
  const roleSelected = localStorage.getItem('RoleSelected');
   let taskLinks = useSelector((state) => state.welcomePageReducer.taskLinks);
 
  console.log('taskLinks',taskLinks)
  useEffect(() => {
      dispatch(getTaskLinks());
     
  }, [dispatch]);

  const  SwitchHandler = (name) =>{
    switch (name) {
        case 'Role assignment expiry': {
            roleSelected === 'Admin' ? history.push('/adminrequest') : history.push('/request')
          break;
        }
        case 'New employee mapping': {
            history.push('/admin/staff');dispatch(saveStaffAdminActiveTab('available'))
          break;
        }
        default: { }
      }
  }
 
    return (
        <div className="column_1">
            <div className="card_2">
                <div className="card_links">
                    <ListGroup variant="flush">
                        <ListGroup.Item><span className='linkhead'>Tasks</span></ListGroup.Item>
                        {taskLinks.map((link, index) => (<ListGroup.Item style={{ height: '52px' }} key={index}>
                             <div onClick={()=>SwitchHandler(link.name)}> <span className='link'> {link.name} ( <span style={{color:'red'}}>{link.number}</span> )</span></div>
                        </ListGroup.Item>))}
                    </ListGroup>
                </div>
            </div>
        </div>
    )
}


