import { request_actionTypes as actionTypes} from './../constants/request-actionTypes';


const initialState = {
    AdminRequestData:[],
    RequestData:[],
    RequestDropdownData:[],
    uploadedFileData:[]
}

const requestReducer = (state = initialState, action) => {
    
    switch (action.type) {
       
            case actionTypes.GET_REQUEST_DATA:
            case actionTypes.GET_ADMIN_REQUEST_DATA:
                case actionTypes.GET_REQUEST_DROPDOWN_DATA:
                    case actionTypes.GET_UPLOADED_FILE_DATA:
                return { ...state };
            case actionTypes.REQUEST_DATA:
                    return { ...state, RequestData: action.payload };
            case actionTypes.ADMIN_REQUEST_DATA:
                    return { ...state, AdminRequestData: action.payload };
            case actionTypes.REQUEST_DROPDOWN_DATA:
                    return { ...state, RequestDropdownData: action.payload };
                    case actionTypes.UPLOADED_FILE_DATA:
                    return { ...state, uploadedFileData: action.payload };
        default: return state

    }
}

export default requestReducer ;