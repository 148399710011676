import React, { useState } from 'react';

import Expand_DownArrow from './../../../../assets/onboard-images/Expand_DownArrow.png';
import Collapse_UpArrow from './../../../../assets/onboard-images/Collapse_UpArrow.png';
import Edit_Icon from './../../../../assets/onboard-images/Edit_Icon.png';
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png'
import { Link } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import { getAdminRoles, updateRoleDeleteStart } from '../../../../actions/Administration/adminView.action';
import { DeleteModalPopup } from '../../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { AdminViewCannotDeleteModel, AdminViewDeleteModel } from '../AdminViewModels';

const RolesTableAdminView = ({info,key}) => {
    let dispatch = useDispatch();
    console.log('info:::::::::',info,info?.['Access Group'])
    const AccessGroupData = info?.['Access Group']
  const [showAccordion, setShowAccordion] = useState(false);
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);

  const editData = {
    Mode: 'Edit',
    editInfo: info
    };
  

    const handleDeleteRole = () =>{
      console.log('roleid::::::::',info.RoleID)
      let deleteObj = [{"RoleID":info.RoleID}]
      dispatch(updateRoleDeleteStart(deleteObj))
      setShowDeleteRoleModal(false)
      dispatch(getAdminRoles());
    }
  return (
    <>
     <main className='Table_border_AdminFlow' key={key}>
              <span style={{ border: 'none'}}>{info?.['Role Name']}</span>
              <span className='Icon_holder_AdminFlow'>
              <div> <Link to={{ pathname: '/admin/roles/addRole', state: editData}}
  ><img src={Edit_Icon} alt='' style={{ cursor: 'pointer' }} /></Link></div>
                <div > <img src={deleteIcon} alt='' style={{ cursor: 'pointer' }} onClick={()=>{info?.Delete === 'Y' ? setShowDeleteRoleModal(true) : setShowCannotDeleteModal(true)}}/></div>
                <div> <img src={showAccordion ? Collapse_UpArrow : Expand_DownArrow} alt='' style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowAccordion(!showAccordion)
                  }} /></div>
              </span>
          </main>
      
            {(showAccordion) &&
       <th colSpan='15' style={{backgroundColor: '#F6F8FA',width:'20%'}}>
      <div className='AdminFlow_Description'>
        Role Descriptions: {info.Description}
        </div>
        <section className='table_insider_AdminFlow' >
          <main style={{ display: 'flex',backgroundColor:'#F6F8FA' }}>
            <span className='table_insider_box' style={{ width: '35%' }}> Access Group Name</span>
            <span className='table_insider_box' style={{ width: '65%' }}> Operation</span>
          </main>
          {AccessGroupData?.map(data => (
            <main style ={{backgroundColor:'white'}}>
          <div style={{ display: 'flex',borderTop:'1px solid #63666A4D',padding:'20px' }}>
            <span className='table_insider_body' style={{ width: '35%' }} >{data.AccessGroup}</span>
            <span className='table_insider_body' style={{ width: '65%' }} >
            {(data?.OperationList)?.map((data,i,row)=>(<span>{data.Operation} {i+1 === row.length ? '' : '|' } &nbsp;</span>))}
            </span>
          </div>
      </main>
            ))}
      
        </section>
      </th>}

      {showDeleteRoleModal && <DeleteModalPopup isOpen={showDeleteRoleModal} setShowModal={setShowDeleteRoleModal}>
            <AdminViewDeleteModel modalName='Delete Access Group and Operations' buttonOne='YES' buttonTwo='NO'
            InformationText = {info?.['Role Name']} 
            AfterInfoText='role?'
            handleDeleteYes={handleDeleteRole}
            handleDeleteNo={()=>setShowDeleteRoleModal(false)}
            />
        </DeleteModalPopup>}

        {showCannotDeleteModal && <DeleteModalPopup isOpen={showCannotDeleteModal} setShowModal={setShowCannotDeleteModal}>
            <AdminViewCannotDeleteModel modalName='Cannot be Deleted' 
            InformationText = 'This Role cannot be deleted. Assigned to User'
            handleClose={()=>{setShowCannotDeleteModal(false)}}
           
            />
        </DeleteModalPopup>}

      </>

  )
}

export default RolesTableAdminView