import ReactModal from "react-modal";
import { OnboardSelect } from './../../../Onboard/OnboardComponents/Dropdown/Select';
import Success_Icon from './../../../../assets/onboard-images/Success_Icon.png';

export function ActivationRequest(props) {

    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    Assign To Me
                </div>
            </div>
            {props.supervisorValidation && <div className="error_msg">Please Ensure you filled all the inputs with  " * " mark</div>}
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                <div className="emp_name">Employee Name</div>
                <div className="selected_emp">{props.selectedRow.EmployeeName} ({props.selectedRow.Email})</div>
                <div className="emp_name"></div>
                <div>
                    <div className='container-one'>
                        <label HtmlFor='username'> Primary Functional Supervisor *</label>
                        <OnboardSelect data={props.SupervisorData} name='supervisor' value='Supervisor' id='Employee ID'
                            inputvalue='Select' addValueToQueue={props.addValueToRole} />
                    </div>
                </div>
            </div>

            <main className='input-container-five' style={{ marginLeft: '5em', marginTop: '5em' }}>
                <button type='submit' className='add-button-mapping' onClick={props.handleDeleteQueue}>
                    <span className='add-span' >{props.buttonOne}</span>
                </button>
                <button className='cancel-button' onClick={props.handleDeleteQueueCancel}>
                    <span className='cancel-span'>{props.buttonTwo} </span>
                </button>
            </main>

        </section>
    </>
}


export function ActivateModelPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '15em',
                    left: '30em',
                    width: '500px',
                    height: '320px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}

export function AdminCancelModel(props) {
    const { handleYesButton, handleNoButton } = props
    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    Account not Submitted
                </div>


                <div className="cancel_text">Are you sure you want to cancel the Account</div></div>


            <main className='input-container-five' style={{ marginLeft: '5em', marginTop: '5em' }}>
                <button type='submit' className='add-button-mapping' onClick={handleYesButton} >
                    <span className='add-span' >YES</span>
                </button>
                <button className='cancel-button' onClick={handleNoButton}>
                    <span className='cancel-span'>NO </span>
                </button>
            </main>

        </section>
    </>
}


export function UnsavedModel(props) {
    const { handleYesButton, handleNoButton, handleSaveButton } = props

    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    Unsaved Changes
                </div>


                <div className="cancel_text"> There are unsaved changes on this page. </div>
                <div className="cancel_text">Please save the  details to continue. </div></div>


            <main className='input-container-one' style={{ marginLeft: '2em', marginTop: '3em', gap: 20 }}>
                <button type='submit' className='add-button' onClick={handleSaveButton} >
                    <span className='add-span'  >SAVE</span>
                </button>
                <button type='submit' className='cancel-button' onClick={handleYesButton}>
                    <span className='cancel-span' >YES</span>
                </button>
                <button className='cancel-button' onClick={handleNoButton}>
                    <span className='cancel-span'>NO </span>
                </button>
            </main>

        </section>
    </>
}

export function AdminNextModel(props) {
    const { handleYesButton, handleNoButton} = props

    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    Unsaved Changes
                </div>


                <div className="cancel_text"> There are unsaved changes on this page. </div>
                <div className="cancel_text" style={{marginTop: '1em'}}>Before proceeding do you wish to save these changes?</div></div>


            <main className='input-container-one' style={{ marginLeft: '6em', marginTop: '3em', gap: 20 }}>
                <button type='submit' className='btn_adduser' onClick={handleYesButton}>YES</button>
                <button className='btn_canceluser' onClick={handleNoButton}>NO </button>
            </main>

        </section>
    </>
}

export function AdminSubmitModel(props) {
    return <>
    
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                {props.modalName}
                </div>


                <div className='delete-para' style={{marginBottom: '1em' }}>
                <div className='delete-para-span'>
                    <img src={Success_Icon} alt='' />  &nbsp; &nbsp;   <span className="queue-span " style={{ font: 'normal normal bold 24px/29px Open Sans', marginTop: 10 }}> Success</span>
                </div>
                <div className='delete-para-span' style={{ marginTop: '10px',width:'520px' }}>
                    <span className="queue-span"> {props.queueName}</span> account submitted successfully.
                   <div> {props.ReopenText}</div>
                </div>
            </div></div>


                <main className='input-container-five' style={{ marginLeft: '12em',marginTop:'5em' }}>
                <button type='submit' className='add-button' onClick={props.handleSuccessAccount}>
                    <span className='add-span' >{props.buttonOne}</span>
                </button>
            </main>

        </section>
    </>
}

export function DataRetainModel(props) {
    return <>
    
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                {props.modalName}
                </div>
                <div className='delete-para' style={{marginBottom: '1em' }}>
                
                <div className='delete-para-span' style={{ marginTop: '10px',width:'520px' }}>
                   Do you want to Retain the mapped <b>employee previous assignments ?</b>
                </div>
            </div></div>
                <main className='input-container-five' style={{ marginLeft: '5em',marginTop:'3em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleYesDataRetain}>YES
                </button>
                <button className='btn_canceluser' onClick={props.handleNoDataRetain}>NO
                </button>
            </main>

        </section>
    </>
}




