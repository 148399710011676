import actionTypes from "../constants/actionTypes";
import RegionalSiteActionTypes from "../constants/regional-site-actionTypes";

const initialState = {
  regionalSiteData: {}, rgnCustId: null, rgnCustName: null, rgnCustIdData: [], rgnGlobal_Regional_Summary: {},
  regionName: '', regionID: null, drpdwnSlctdRgns: [], drpdwnRegionName: '', DTMAnalytics: {}, rgnServiceID: '',
  selectedSLA: {}, DTMHalfHour: {}, DTMMonthlyData: {}
};


const getRegionalSiteData = (state, action) => {
  return {
    ...state
  };
}

const updateRegionalSiteData = (state, action) => {
  return { ...state, regionalSiteData: action.payload };
};

const updateRegionalSiteCustomerID = (state, action) => {
  return { ...state, rgnCustId: action.payload };
};

const updateRegionalSiteCustomerName = (state, action) => {
  return { ...state, rgnCustName: action.payload };
};

const getRegionalSiteCustIdData = (state, action) => {
  return {
    ...state
  };
}
const updateRegionalSiteCustIdData = (state, action) => {
  return { ...state, rgnCustIdData: action.payload };
};

const getRegionalSiteGlobalSummaryData = (state, action) => {
  return {
    ...state
  };
}

const updateRegionalSiteGlobalSummaryData = (state, action) => {
  return { ...state, rgnGlobal_Regional_Summary: action.payload };
};

const updateRegionName = (state, action) => {
  return { ...state, regionName: action.payload };
};

const updateDrpdwnRegionName = (state, action) => {
  return { ...state, drpdwnRegionName: action.payload };
}

const updateRegionID = (state, action) => {
  return { ...state, regionID: action.payload }
}

const updateDropownSlctdRgnsList = (state, action) => {
  return { ...state, drpdwnSlctdRgns: action.payload }
}

const updateSlcdAccrdPnlID = (state, action) => {
  return { ...state, slcdAccrdPnlID: action.payload }
}

const updateRegionalSiteServiceDeskID = (state, action) => {
  return { ...state, rgnServiceID: action.payload }
}

const regionalSiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REGIONALSITE_DATA:
      return getRegionalSiteData(state, action);
    case actionTypes.REGIONALSITE_DATA:
      return updateRegionalSiteData(state, action);
    case RegionalSiteActionTypes.UPDATE_CUSTOMER_ID:
      return updateRegionalSiteCustomerID(state, action)
    case RegionalSiteActionTypes.UPDATE_CUSTOMER_NAME:
      return updateRegionalSiteCustomerName(state, action);
    case RegionalSiteActionTypes.GET_REGIONALSITE_CUSTID_DATA:
      return getRegionalSiteCustIdData(state, action);
    case RegionalSiteActionTypes.UPDATE_REGIONALSITE_CUSTID_DATA:
      return updateRegionalSiteCustIdData(state, action);
    case RegionalSiteActionTypes.GET_REGIONALSITE_GLOBAL_SUMMARY_DATA:
      return getRegionalSiteGlobalSummaryData(state, action);
    case RegionalSiteActionTypes.UPDATE_REGIONALSITE_GLOBAL_SUMMARY_DATA:
      return updateRegionalSiteGlobalSummaryData(state, action);
    case RegionalSiteActionTypes.UPDATE_REGION_NAME:
      return updateRegionName(state, action);
    case RegionalSiteActionTypes.UPDATE_REGION_ID:
      return updateRegionID(state, action);
    case RegionalSiteActionTypes.UPDATE_DRPDWN_REGIONS_LIST:
      return updateDropownSlctdRgnsList(state, action);
    case RegionalSiteActionTypes.UPDATE_SELECTED_ACCRD_PANEL_ID:
      return updateSlcdAccrdPnlID(state, action);
    case RegionalSiteActionTypes.UPDATE_DRPDWN_REGION_NAME:
      return updateDrpdwnRegionName(state, action);
    case RegionalSiteActionTypes.UPDATE_SERVICEDESK_ID:
      return updateRegionalSiteServiceDeskID(state, action);
    case RegionalSiteActionTypes.GET_DTM_ANALYTICS:
      return { ...state }
    case RegionalSiteActionTypes.UPDATE_DTM_ANALYTICS:
      return { ...state, DTMAnalytics: action.payload };
    case RegionalSiteActionTypes.GET_DTM_ANALYTICS_HALF_HOUR:
      return { ...state }
    case RegionalSiteActionTypes.UPDATE_DTM_ANALYTICS_HALF_HOUR:
      return { ...state, DTMHalfHour: action.payload };
    case RegionalSiteActionTypes.GET_DTM_ANALYTICS_MONTH:
      return { ...state }
    case RegionalSiteActionTypes.UPDATE_DTM_ANALYTICS_MONTH:
      return { ...state, DTMMonthlyData: action.payload }
    case RegionalSiteActionTypes.UPDATE_SELECTED_SLA:
      return { ...state, selectedSLA: action.payload }
    default:
      return state;
  }
};

export default regionalSiteReducer;