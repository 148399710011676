import React, { useEffect, useState } from 'react';
import FilterTab from '../FilterTab';
import AboveMinimalTargetIcon from "../../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../../assets/images/BelowMinimalTarget_Status.png";
import SLADataExclusion_Icon from '../../../../assets/admin-images/SLADataExclusion_Icon.png';
import DATA from './../DATA.json'
import TableBodyHandler from './SLA-Exemption-Table';
import './../SLA-Admin.css';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { getExemptionDataStart, updateExistingCommentStart } from '../../../../actions/Administration/SLAFailure.action';
import { useHistory } from 'react-router-dom';
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';


function SLAExemptionHandler() {
    const dispatch = useDispatch();
    const history = useHistory();
    let ExemptionData = useSelector((state) => state.slaFailureReducer.ExemptionData);
    let AccountDetails = useSelector((state) => state.adminAccountReducer.AccountDetails);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
console.log(AccountDetails)
    console.log(ExemptionData, AccountDetails);
    const [selectedDate, setSelectedDate] = useState()
    useEffect(() => {
         dispatch(getExemptionDataStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID, formatMonthDate(new Date())))
   // dispatch(getExemptionDataStart('20', '15', '2022-07-01'))
        setSelectedDate(formatMonthDate(new Date()))
    }, [])
    const formatMonthDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        return [year,month,'01'].join('-');
    }
    const HandleSLAMonth = (value) => {
        setSelectedDate(formatMonthDate(value))
        console.log(formatMonthDate(value))
        // dispatch(getExemptionDataStart('20', '15', formatMonthDate(value)))
        dispatch(getExemptionDataStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID, formatMonthDate(value)))
    }
    console.log(selectedDate)
    const updateExistingComment = newExemption => {
        console.log(newExemption)
        
        let temp = {
            Comment: newExemption.Comments,
            Type: newExemption.Type,
            Penalty: Number(newExemption.Penalty)
        }
        console.log(temp)
        console.log(newExemption.sla)
      //  dispatch(updateExistingCommentStart('20', '15', '2022-07-01',newExemption.sla, temp))
      //  dispatch(getExemptionDataStart('20', '15', '2022-07-01'))
        
        dispatch(updateExistingCommentStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID, (selectedDate),newExemption.sla, temp))
         dispatch(getExemptionDataStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID, (selectedDate)))
    }
    

    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch]);

    console.log('RoleBasedView',RoleBasedView?.[0]?.SLAExemption?.[0]?.ReadWrite)
    return (
        <>
            <section className='sla_container'>
                <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} value2={()=>history.push('/admin')} label2='Administration' isActive3={false}
                    label3={ExemptionData?.[0]?.Customer?.Name + ' - ' + ExemptionData?.[0]?.ServiceDesk?.Name +  " - SLA Failure Exemption " } /><br />
                <FilterTab selectedItem={ExemptionData?.[0]?.Customer?.Name + ' - ' + ExemptionData?.[0]?.ServiceDesk?.Name +  " - SLA Failure Exemption"} HandleSLAMonth={HandleSLAMonth}
                    buttonValue=' SLA Data Exclusion' pathname='exclusion' src={SLADataExclusion_Icon} counters={ExemptionData?.[0]?.Counters?.[0]} displayimage = {AccountDetails.CustomerID}/>
                <div className='legend_div'>
                    <span id='legend'>Legend: </span>
                    <img src={AboveMinimalTargetIcon} alt='' />Above Minimal Target  <img src={BelowMinimalTargetIcon} alt='' />Below Minimal Target
                </div>
                <main className='sla_exemption_table'>
                    <table style={{ width: '100%' }} >
                        <thead style={{ width: '100%' }}>
                            <tr>{DATA.header.map(data => (<th style={{ border: 'none' }} key={data.id}>{data.label}</th>))}</tr>   </thead>
                        <tbody>
                            {ExemptionData?.[0]?.Rows?.map((info, i) => <TableBodyHandler key={i} info={info} updateExistingComment={updateExistingComment} RoleBasedView={RoleBasedView?.[0]?.SLAExemption?.[0]?.ReadWrite}/>)}
                        </tbody>
                    </table>
                    <br/><br/>
{(ExemptionData?.[0]?.Rows === '' || ExemptionData?.[0]?.Rows === undefined || ExemptionData?.[0]?.Rows === null) ? 
<div className='no_records'>No Data Found for Selected Year & Month </div> : '' }
                    <br/><br/><br/>
                </main>
            </section>
        </>
    )
}

export default SLAExemptionHandler