import React from 'react'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb'
import { Footer } from '../HeaderFooter'
import VerticalLine from './../../../assets/images/VerticalLine_Headings.png';
import Features_HeroBanner from './../../../assets/github-images/Features_HeroBanner_Image.png';
import Features_Recommendation from './../../../assets/github-images/Features_Recommendation_Image.png';
import Vertical_Strip_Icon from './../../../assets/images/Vertical_Strip_Icon.png'
import './Features.css';
import { Link, useHistory } from 'react-router-dom';

const  reports= [
    {
        id: 1,
        title:'ASD Cost Recovery',
        text:'Specific limited financial based data reports for designated audience.',
        href:'finance'
    },
    {
        id: 2,
        title:'Executive Dashboard',
        text:'Global high level data of ASD consumption and GSD KPI metrics.',
        href:'eDashboard'
    },
    {
        id: 3,
        title:'DTM (Day-To-Month) Report',
        text:'Essential breakdown of all SLA and KPI metrics for operations / delivery purposes.',
        href:'regional'
    },
    {
      id: 4,
      title:'Heat Map',
      text:'Utilization report of GSD Insight platform displaying different logs generated by users.',
      href:'https://gsd-insight.houston.dxccorp.net/ReportServer?%2fReports%2fGeneric%2fIISLog%2fLOGIIS&rs:Command=Render&rc:Toolbar=false&rs:embed=true'
  },
  {
      id: 5,
      title:'Agent Score Card',
      text:'Agent level granular operational SLA and KPI reports.',
      href:'/notinproduction'
  }]

  const INFO_CARDS = [
    {
        id: 1,
        title:'Onboard',
        name: 'Self-service function allowing the users themselves to onboard new accounts/clients.',
        href:'/onboard'
    },
    {
        id: 2,
        title:'Requests / Incidents',
        name: 'Specific requests not supported by self-service functionality and any other related business/ideas/enhancements.',
        href:'/request'
    },
    {
        id: 3,
        title:'Administration',
        name: 'Self-service function allowing the users themselves to administer existing accounts/clients/staff.',
        href:'/notinproduction'
    }
]
const Features = () => {
   const history = useHistory();
  return (
    <>
    <section style={{ marginLeft: -100 }}>
         <main className='breadcrumb_solution'>
           <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Features' />
           <div style={{width:1200,margin:'auto'}}>
           <div id='arch_text'><div id='arch_head'>Features</div>The overview on the distinctive attributes and aspects of GSD Insight Platform such as onboard and administration, requests & incidents, and various reporting options can be further explored below and there is also space for enhancements and improvement suggestions.</div>
           <div style={{paddingBottom:20,paddingLeft:80}}><img src={Features_HeroBanner} alt='' /></div></div>
         </main>
         <main className='conceptual_arch_user'>
         <div style={{width:1200,margin:'auto',display:'flex',gap:'40px'}}>
         <section>
          <main className='features_model'>
             <div id='text_security'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Reports</div>
             <div className='grid_features'>
               {reports.map((link, index) => (<main class="grid_item_features" ><div id='grid_title'>{link.title === 'Heat Map' ? <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={link.href}
                      title="" >  <span id='link_font'>{link.title}</span> </a> : <Link to={link.href} target='_blank'>  {link.title}</Link>}</div><div id='grid_text'>{link.text}</div></main>))}
               </div>
          </main>
         </section>
         </div>
         </main>
         <main className='Features_cards_container'>
            <div style={{width:1200,display:'flex',gap:40,margin:'auto'}}>
                {INFO_CARDS.map((cards, i) =>
                    <div className='Features_text_cards'><div className='features_card_title'><img src={Vertical_Strip_Icon} alt='' /> &nbsp; {cards.title}</div>
                    <div style={{ paddingTop: '20px',paddingLeft:"20px",height:'130px' }}>{cards.name}</div>
                    <Link to={cards.href} target='_blank'><div className='button_explore'>EXPLORE</div></Link>
                </div>
                )}
                </div>
               </main>
               <main className='hero_image_container'>
                <img src={Features_Recommendation} alt='HeroBanner' width='100%' height='480px' style={{objectFit:'cover'}}/>
                <div class="text_on_herobanner_features">
                    <div className='improvement_text'>Do you have improvement suggestion or feature recommendation?</div>
                    <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href='mailto:GSD_Insight_Support@dxc.com'
                      title="" > <div className='button_getintouch'> GET IN TOUCH WITH US </div></a>
                </div>
            </main>
         <Footer/>
         </section>
      </>
  )
}

export default Features