import React, { useEffect } from 'react';
import './Profilepage.css';

import { Col } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import agentPic from '../../../assets/images/AgentPhoto.png'
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../actions/user.action';
import { getRolesTitleInfo } from '../../../actions/welcomePage.action';
import '../../ReportTables/ReportFilters/ReportTable.css';
import { getRegionalSiteGlobalSummaryData, updateRegionalSiteCustomerID, updateRegionalSiteCustomerName, updateRegionID, updateRegionName } from '../../../actions/regionalSite.action';
import { useHistory } from 'react-router-dom';
import { saveAccountLocationName, saveSelectedMenuItem, updateSelectedAccountName } from '../../../actions/tabMenu.action';
import HomeBreadcrumb from '../Welcome-LandingPage/Home-Breadcrumb';

const Profilepage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let RolesInfo = useSelector((state) => state.welcomePageReducer.rolesTitle);
  let roleSelected = localStorage.getItem('RoleSelected');
  useEffect(() => {
    dispatch(getUserData());
    dispatch(getRolesTitleInfo());
  }, [dispatch]);

  let RolesData = userInfo.roles;
  const SelectedRoleDescription = (role) => {
    return RolesData?.find(dt => dt.name === role);
  }

  const RolesDescription = SelectedRoleDescription(roleSelected);
  const viewDTMReports = (service_id, c_name, cust_id, service_desk) => {
    if (service_id) {
      window.localStorage.setItem('ServiceDeskID', service_id);
    }
    if (cust_id) {
      window.localStorage.setItem('CustomerID', cust_id);
    }
    dispatch(updateRegionalSiteCustomerID(cust_id));
    dispatch(updateRegionalSiteCustomerName(c_name));
    dispatch(updateSelectedAccountName(c_name));
    dispatch(saveAccountLocationName(service_desk));
    dispatch(saveSelectedMenuItem("reports"));
    history.push("/reports");
  };

  const viewRegionalHandler = (regionID, region,c_name, selectedAccCustID, isoDateString) => {
    dispatch(updateRegionID(regionID));
    dispatch(updateRegionName(region));
    dispatch(updateRegionalSiteCustomerName(c_name));
    dispatch(updateSelectedAccountName(c_name));

    dispatch(getRegionalSiteGlobalSummaryData(selectedAccCustID, 0, regionID, isoDateString));
    history.push('/regional_summary');
  }

  const glblSmmryBtnClickHandler = (selectedAccCustID,c_name, isoDateString) => {
    dispatch(getRegionalSiteGlobalSummaryData(selectedAccCustID, 0, 0, isoDateString));
    dispatch(updateRegionalSiteCustomerName(c_name));
    dispatch(updateSelectedAccountName(c_name));

    history.push('/regional_global_summary');
  };

  const RecentLinksHandler = (s_id, c_name, c_id, s_name, r_id, r_name) => {
    let dt = '2022-04-01';
    if (c_id !== null && c_name !== null && s_id !== null && s_name !== null) viewDTMReports(s_id, c_name, c_id, s_name);
    if (r_id !== null && r_name !== null) viewRegionalHandler(r_id, r_name,c_name, c_id, dt);
    if (r_id === null && r_name === null && s_id === null && c_id !== null) glblSmmryBtnClickHandler(c_id,c_name, dt);
  }

  return (
    <div style={{ marginLeft: -100 }}>
      <HomeBreadcrumb pageId='My Profile' style={{ marginLeft: '110px' }} />
      <br />
      <div className='prof'>Profile</div><br />
      <div className='first'>
        <div className='card_prof'>
          <div>
            <div className='d-flex'>
              <Col className='flex_agent'>
              <img
                    style={{maxHeight:'130px',maxWidth:'130px'}}
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/users/me/photo`}
                  alt="Client Logo"
                />
              </Col>
              <Col className='flex_text'><span><span className="doe_text">{userInfo.name}</span> <br />
                <span className='role_text'>Role:</span> <span className='acc_del'>
                  {RolesInfo?.length <= 1 ? RolesInfo?.[0]?.name : roleSelected}
                </span><br />
                <br /><span>
                  <Row>
                    <Col className='flex_uid'><div><span className='Uid'>User ID</span><br /><span className='prague'>{userInfo.upn}</span></div></Col>
                    <Col className='flex_eid'><div><span className='Uid'>Email ID</span><br /><span className='prague'>{userInfo.email}</span></div></Col>
                    <Col className='flex_loc'><div><span className='Uid'>Location</span><br /><span className='prague'>{userInfo.city}</span></div></Col>
                  </Row>
                </span></span></Col>
            </div>
          </div><hr />
          <div>
            <div className='role_des'>Role Description</div>
            <div className='para'>
              {RolesDescription?.description}
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="container_acc" >
        <div className="acc_info" >Membership/Account Information</div>
        <section className='table-section' >
          <table className='report-table' style={{ width: '100%', maxHeight: '20em' }}>
            <thead style={{ backgroundColor: '#969696' }}>
              <tr>
                <th width="250px">Customer</th>
                <th width="250px">Service Desk</th>
                <th width="250px">Role</th>
                <th width="250px">Region</th>
                <th width="250px">Valid From</th>
                <th width="250px">Valid To</th>
              </tr>
            </thead>
            <tbody >
              {RolesDescription?.membership?.map((member, i) =>
              (<tr style={{ cursor: 'pointer' }} key={i}
                onClick={() => RecentLinksHandler(member.serviceDeskId, member.customerName, member.customerId, member.serviceDeskName, member.regionId, member.regionName)}
              >
                <td className='name_col'>{member.customerName}</td>
                <td className='name_col'>{member.serviceDeskName}</td>
                <td className='name_col'>{member.roleName}</td>
                <td className='name_col'>{member.regionName}</td>
                <td>{member.validSince}</td>
                <td>{member.validUntil}</td>
              </tr>))}
            </tbody>
          </table>
        </section>
      </div>
      <br />
    </div>






  )
};
export default Profilepage