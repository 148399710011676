import React, { useEffect, useState } from 'react';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';
import MOCKDATA from './Tab.json';
import DatePickerHandle from '../../../Onboard/OnboardComponents/KeyboardDatePicker';
import filterIcon from './../../../../assets/images/Filter_Icon.png';
import moment from 'moment';
import { UserDropdown } from '../../AdminFirstPage/Users/UserModels';




function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}


export function AccountAssignmentModal(props) {
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const editMode = props.editMode;
    const [addFormInfo, setAddFormInfo] = useState({})
    const [editFormInfo, setEditFormInfo] = useState({})

    useEffect(() => {
        if (editMode) {
            setValidFrom(props.editedInfo.value3);
            setValidTo(props.editedInfo.value4);
            let temp_Arr = {
                value1: props.editedInfo.value1,
                value2: props.editedInfo.value2,
                value3: (props.editedInfo.value3),
                value4: (props.editedInfo.value4),
            }
            setEditFormInfo(temp_Arr)
        }
    }, [])
    const addValueToAccount = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData[name] = value;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData[name] = value;
            setAddFormInfo(newFormData)
        }
    }
    const ValidfromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        addValueToAccount('value3', formatDate(date))
    }
    const ValidtoDateHandler = (date) => {
        setValidTo(formatDate(date));
        addValueToAccount('value4', formatDate(date))
    }

    const [validation, setValidation] = useState('');
    const onSubmitAccount = (event) => {
        event.preventDefault();
        let temp_arr;
        temp_arr = !editMode ? {
            value1: addFormInfo.value1,
            value2: addFormInfo.value2,
            value3: (addFormInfo.value3),
            value4: (addFormInfo.value4),
        } : {
            value2: editFormInfo.value2,
            value3: (editFormInfo.value3),
            value4: (editFormInfo.value4),
        }
        const accountRepeat = Object.values(props.accountData).some(x =>  x.value1 === addFormInfo.value1);
        const isEmptyInput = Object.values(temp_arr).some(x => (x === null || x === '' || x === undefined));
        const isValidDate = moment(temp_arr.value4).isAfter(temp_arr.value3)
        if (isEmptyInput) {
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }
        else if (!isValidDate) {
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ');
        }
        else if (accountRepeat){
            setValidation('Account already exists. Please Select Different Account')
        }
        else {
            props.onAccountSubmitHandle(editMode ? editFormInfo : addFormInfo)
            setValidation('');
            setAddFormInfo(null);
        }
    }
    return (
        <>
            <section className='add-role-container'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'> {editMode ? 'EDIT' : 'ADD'} ACCOUNT</div>
                    <div style={{ color: '#ED4F32' }}>{validation}</div>
                    <form onSubmit={onSubmitAccount} >
                        <main className='input-container-three'>
                            <div className='container-one' style={{ width: "30%" }}>
                                <label HtmlFor="role">Account Name *</label>
                                {!editMode ? <OnboardSelect data={props.AccountsInfo} name='value1' value='customerName' id='customerName'
                                    editValue='' addValueToQueue={addValueToAccount}
                                /> :
                                    <div><b>{props.editedInfo.value1}</b></div>
                                }
                            </div>
                            <div className='container-one' style={{ width: "30%" }}>
                                <label HtmlFor='username'>Account Type *</label>
                                <OnboardSelect data={MOCKDATA.ACCOUNT_ASSIGNMENTS.Values2} name='value2' value='value' id='id'
                                    editValue={editMode ? props.editedInfo.value2 : ''} addValueToQueue={addValueToAccount}
                                />
                            </div>
                        </main>
                        <main className='input-container-three'>
                            <DatePickerHandle label='Valid From *'
                                value={ValidFrom} id='label'
                                onChange={(date) => ValidfromDateHandler(date)}
                            />
                            <DatePickerHandle label='Valid To *'
                                value={ValidTo} id='label'
                                onChange={(date) => ValidtoDateHandler(date)}
                            />
                        </main>

                        <main style={{ display: 'flex', gap: '30px', marginLeft: '80px', marginTop: '10px' }}>
                            <button type='submit' className='btn_adduser'>{editMode ? 'UPDATE' : 'ADD'}
                            </button>
                            <button className='btn_canceluser' onClick={props.handleAddRoleCancel}>CANCEL 
                            </button>
                        </main>
                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export function QueueAssignmentAddModal(props) {
    const [ValidFrom, setValidFrom] = useState(null);
    const [ValidTo, setValidTo] = useState(null);
    const editMode = props.editMode;
    const [addFormInfo, setAddFormInfo] = useState({})
    const [editFormInfo, setEditFormInfo] = useState({})

    
    const addValueToQueue = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData.value2 = value;
            let queue = props.data.find((a, i, arr) => a.QueueID === id)
            newFormData.value1 = queue?.Customer;
            newFormData.value3 = queue?.Language;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData.value2 = value;
            let queue = props.data.find((a, i, arr) => a.QueueID === id)
            newFormData.value1 = queue?.Customer;
            newFormData.value3 = queue?.Language;
            setAddFormInfo(newFormData)
        }
    }

    const addDateToQueue = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData[name] = value;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData[name] = value;
            setAddFormInfo(newFormData)
        }
    }
    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        addDateToQueue('value4', formatDate(date))
    }
    const ValidToDateHandler = (date) => {
        setValidTo(formatDate(date));
        addDateToQueue('value5', formatDate(date))
    }

const [selectedData, setSelectedData] = useState()

useEffect(() => {
    
   setSelectedData(props.data)
}, [props]);

const toggle =(opdata) => {
        const newData = [...selectedData];
        newData.map((itm, i) => {
            if (opdata?.QueueID === itm?.QueueID) {
                newData.splice(i, 1, {
                        QueueID: itm.QueueID,
                        QueueName: itm.QueueName,
                        Customer:itm.Customer,
                        Language:itm.Language,
                        Selected: !itm.Selected
                    });
                    setSelectedData(newData);
            }})
    };

    const [checkedAll, setCheckedAll] = useState(false)

    const handleChange = event => {
        if (event.target.checked) {
            const newData = [...selectedData];
            newData.map((itm, i) => {
                    newData.splice(i, 1, {
                        QueueID: itm.QueueID,
                        QueueName: itm.QueueName,
                        Customer:itm.Customer,
                        Language:itm.Language,
                        Selected: true
                        });
                        setSelectedData(newData);
                })
        } else {
            const newData = [...selectedData];
            newData.map((itm, i) => {
                    newData.splice(i, 1, {
                        QueueID: itm.QueueID,
                        QueueName: itm.QueueName,
                        Customer:itm.Customer,
                        Language:itm.Language,
                        Selected: false
                        });
                        setSelectedData(newData);
                })
        }
        setCheckedAll(current => !current);
      };

console.log('selectedData',selectedData)

    const [validation, setValidation] = useState('');

    const onSubmitAccount = (event) => {
        event.preventDefault();

        let DispQueues = selectedData.filter((el) =>{
            return el.Selected === true});
        let Queues = DispQueues.map(queue => {
            return {
                value1:queue.Customer,
                value2:queue.QueueName,
                value3:queue.Language,
                value4: (addFormInfo.value4),
                value5: (addFormInfo.value5),
            }
        });

            console.log('Queues',Queues,DispQueues)
        let temp_arr;
        temp_arr = !editMode ? {
            value2: addFormInfo.value2,
            value4: (addFormInfo.value4),
            value5: (addFormInfo.value5),
        } : {
            value2: editFormInfo.value2,
            value4: (editFormInfo.value4),
            value5: (editFormInfo.value5),
        }
       // const queueRepeat = Object.values(props.queueData).some(x => x.value2 === addFormInfo.value2);
       // const isEmptyInput = Object.values(temp_arr).some(x => (x === null || x === '' || x === undefined));
        const isValidDate = moment(temp_arr.value5).isAfter(temp_arr.value4)
        // if (isEmptyInput) {
        //     setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        // }
         if (!isValidDate) {
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ');
        }
        // else if (queueRepeat){
        //     setValidation('Queue already exists. Please Select Different Queue')
        // }
        else {
            props.onAccountSubmitHandle(editMode ? editFormInfo : Queues)
            setValidation('');
            setAddFormInfo(null);
        }
    }

    const Type = [
        {
          Type: "All Queues",
            id: "1"
        }, {
          Type: "Selected Queues",
            id: "2"
        }
      ]

      const [queues, setqueues] = useState();
      const addValueToStatus = (name, value) => {
        const newFormData = { ...queues };
        if (name !== 'Select') {
            newFormData[name] = value;
            setqueues(newFormData)
            
        }
    }
    console.log('queues',queues?.Type)

    const QueueDataHandler = () =>{
        if(queues?.Type === 'Selected Queues'){
            let DispQueues = selectedData.filter((el) =>{
                return el.Selected === true});
                return DispQueues
        }
        else{
           return selectedData  
        }
    }

    return (
        <>
            <section className='add-role-container' style={{ width:650 }}>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'>
                        {editMode ? 'EDIT' : 'ADD'} Queue
                    </div>
                    <div style={{ color: '#ED4F32' }}>{validation}</div>
                    <form onSubmit={onSubmitAccount} >
                    <section className='scrollable_Section_queues'>
                        <main  style={{ marginTop: 10,display:'flex',gap:20 }}>
                            <DatePickerHandle label='Valid From *'
                                value={ValidFrom} id='label'
                                onChange={(date) => ValidFromDateHandler(date)}
                            />
                            <DatePickerHandle label='Valid To *'
                                value={ValidTo} id='label'
                                onChange={(date) => ValidToDateHandler(date)}
                            />
                        </main>
                        <main style={{width:580}}>
                <div className='select_operations_text'><label>Queue Names *</label></div>
                <div className='account_list select_all' style={{ width: '95%', marginTop: '0px'}}>
                        <div style={{ display: 'flex', justifyContent: 'space-between',marginLeft:-10 }}>
                            <div> <span style={{ cursor: 'pointer' }}>
                            <input type='checkbox' id='selectAll' name="selectAll" style={{ cursor: 'pointer' }}
                                    onChange={handleChange}
                                    checked={checkedAll}
                                />

                                <label className='label-region' htmlFor='selectAll' style={{ cursor: 'pointer' }}>  Select All Queues </label></span></div>
                                <span className='search_bar_AdminFlow' style={{padding:0,paddingRight:20,marginTop:'-8px'}}>
          <div className='filter_in_user'> <img src={filterIcon} alt='' width='25px' height='25px'/></div>
          <div className='search_input_AdminFlow' style={{paddingTop:7,paddingLeft:20}} >
                                <UserDropdown data={Type} name='Type' value='Type' id='TypeID'
                                     addValueToQueue={addValueToStatus}
                                    
                                />
                            </div>
          </span>
                        </div>
                               
                        
                    </div>
             {QueueDataHandler()?.length === 0 ? <div className='empty_queues'>
             No records found for Selected filter
             </div> :
             
               <section className='scroll_bar_accessGroup'>
                    <div>
                        
                    {QueueDataHandler()?.map((item, index) => (
                            <div>
                                <span class="grid_item_accessgroup" key={index}  >
                                    <input type='checkbox' id={item.QueueID} style={{ cursor: 'pointer' }}
                                        name={item?.QueueID}
                                        checked={item?.Selected}
                                        onChange={()=>toggle(item)}
                                    />
                                    <label style={{ color: 'black', cursor: 'pointer' }} htmlFor={item?.QueueID} >
                                        {item?.Customer} - {item?.QueueName} - {item?.Language}</label></span>
                            </div>
                        ))
                        }
                            
                       
                    </div>
                </section>}
            </main>
            </section>
                        <main style={{ display: 'flex', gap: '30px', marginLeft: '80px' }}>
                            <button type='submit' className='btn_adduser'>{editMode ? 'UPDATE' : 'ADD'}
                            </button>
                            <button className='btn_canceluser' onClick={props.handleAddRoleCancel}>CANCEL 
                            </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export function QueueAssignmentModal(props) {
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const editMode = props.editMode;
    const [addFormInfo, setAddFormInfo] = useState({})
    const [editFormInfo, setEditFormInfo] = useState({})

    useEffect(() => {
        if (editMode) {
            setValidFrom(props.editedInfo.value4);
            setValidTo(props.editedInfo.value5);
            let temp_Arr = {
                value1: props.editedInfo.value1,
                value2: props.editedInfo.value2,
                value3: (props.editedInfo.value3),
                value4: (props.editedInfo.value4),
                value5: (props.editedInfo.value5),
            }
            setEditFormInfo(temp_Arr)
        }
    }, [])
    const addValueToQueue = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData.value2 = value;
            let queue = props.data.find((a, i, arr) => a.QueueID === id)
            newFormData.value1 = queue?.Customer;
            newFormData.value3 = queue?.Language;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData.value2 = value;
            let queue = props.data.find((a, i, arr) => a.QueueID === id)
            newFormData.value1 = queue?.Customer;
            newFormData.value3 = queue?.Language;
            setAddFormInfo(newFormData)
        }
    }

    const addDateToQueue = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData[name] = value;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData[name] = value;
            setAddFormInfo(newFormData)
        }
    }
    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        addDateToQueue('value4', formatDate(date))
    }
    const ValidToDateHandler = (date) => {
        setValidTo(formatDate(date));
        addDateToQueue('value5', formatDate(date))
    }
    const [validation, setValidation] = useState('');

    const onSubmitAccount = (event) => {
        event.preventDefault();
        let temp_arr;
        temp_arr = !editMode ? {
            value2: addFormInfo.value2,
            value4: (addFormInfo.value4),
            value5: (addFormInfo.value5),
        } : {
            value2: editFormInfo.value2,
            value4: (editFormInfo.value4),
            value5: (editFormInfo.value5),
        }
        const queueRepeat = Object.values(props.queueData).some(x => x.value2 === addFormInfo.value2);
        const isEmptyInput = Object.values(temp_arr).some(x => (x === null || x === '' || x === undefined));
        const isValidDate = moment(temp_arr.value5).isAfter(temp_arr.value4)
        if (isEmptyInput) {
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }
        else if (!isValidDate) {
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ');
        }
        else if (queueRepeat){
            setValidation('Queue already exists. Please Select Different Queue')
        }
        else {
            props.onAccountSubmitHandle(editMode ? editFormInfo : addFormInfo)
            setValidation('');
            setAddFormInfo(null);
        }
    }


    return (
        <>
            <section className='add-role-container'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'>
                        {editMode ? 'EDIT' : 'ADD'} Queue
                    </div>
                    <div style={{ color: '#ED4F32' }}>{validation}</div>
                    <form onSubmit={onSubmitAccount} >
                        <main className='input-container-one' style={{ marginTop: 10 }}>
                            <div className='container-one' style={{ width: '63.5%' }}>
                                <label HtmlFor="role">Queue Name *</label>
                                {editMode ? <div><b> {editFormInfo.value2}</b> (Language : {editFormInfo.value3})</div> : <OnboardSelect data={props.data} name='value2' value='QueueName' id='QueueID'
                                    editValue={editMode ? props.editedInfo.value2 : ''} addValueToQueue={addValueToQueue}
                                />}
                            </div>
                        </main>

                        <main className='input-container-three' style={{ marginTop: -10 }}>
                            <DatePickerHandle label='Valid From *'
                                value={ValidFrom} id='label'
                                onChange={(date) => ValidFromDateHandler(date)}
                            />
                            <DatePickerHandle label='Valid To *'
                                value={ValidTo} id='label'
                                onChange={(date) => ValidToDateHandler(date)}
                            />
                        </main>

                        <main style={{ display: 'flex', gap: '30px', marginLeft: '80px' }}>
                            <button type='submit' className='btn_adduser'>{editMode ? 'UPDATE' : 'ADD'}
                            </button>
                            <button className='btn_canceluser' onClick={props.handleAddRoleCancel}>CANCEL 
                            </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export function IDAssignmentModal(props) {
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    const editMode = props.editMode;
    const [addFormInfo, setAddFormInfo] = useState({})
    const [editFormInfo, setEditFormInfo] = useState({})

    useEffect(() => {
        if (editMode) {
            setValidFrom(props.editedInfo.value4);
            setValidTo(props.editedInfo.value5);
            let temp_Arr = {
                value1: props.editedInfo.value1,
                value2: props.editedInfo.value2,
                value3: (props.editedInfo.value3),
                value4: (props.editedInfo.value4),
                value5: (props.editedInfo.value5),
            }
            setEditFormInfo(temp_Arr)
        }
    }, [])
    const addValueToID = (name, value, id) => {
        if (editMode) {
            const newFormData = { ...editFormInfo };
            newFormData[name] = value;
            setEditFormInfo(newFormData)
        }
        else {
            const newFormData = { ...addFormInfo };
            newFormData[name] = value;
            setAddFormInfo(newFormData)
        }
    }
    const handleAddFormValue = (event) => {
        if (editMode) {
            event.preventDefault();
            const fieldName = event.target.getAttribute("name");
            const fieldValue = event.target.value;
            const newFormData = { ...editFormInfo };
            newFormData[fieldName] = fieldValue;
            setEditFormInfo(newFormData);
        }
        else {
            event.preventDefault();
            const fieldName = event.target.getAttribute("name");
            const fieldValue = event.target.value;
            const newFormData = { ...addFormInfo };
            newFormData[fieldName] = fieldValue;
            setAddFormInfo(newFormData);
        }
    }
    const ValidfromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        addValueToID('value4', formatDate(date))
    }
    const ValidtoDateHandler = (date) => {
        setValidTo(formatDate(date));
        addValueToID('value5', formatDate(date))
    }

    const [validation, setValidation] = useState('');
    const onSubmitAccount = (event) => {
        event.preventDefault();
        let temp_arr;
        temp_arr = !editMode ? {
            value1: addFormInfo.value1,
            value2: addFormInfo.value2,
            value3: (addFormInfo.value3),
            value4: (addFormInfo.value4),
            value5: (addFormInfo.value5)
        } : {
            value1: editFormInfo.value1,
            value2: editFormInfo.value2,
            value3: (editFormInfo.value3),
            value4: (editFormInfo.value4),
            value5: (editFormInfo.value5)
        }
        console.log('idrepeat',addFormInfo,props.IDdata)
        const IDaccountRepeat = Object.values(props.IDdata).some(x => x.value1 === addFormInfo.value1 && x.value3 === addFormInfo.value3);
        const isEmptyInput = Object.values(temp_arr).some(x => (x === null || x === '' || x === undefined));
        const isValidDate = moment(temp_arr.value5).isAfter(temp_arr.value4)
        if (isEmptyInput) {
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }
        else if (!isValidDate) {
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date ');
        }
        else if (IDaccountRepeat){
            setValidation('Account already exists with the selected ID. Please Use the different ID.')
        }
        else {
            props.onAccountSubmitHandle(editMode ? editFormInfo : addFormInfo)
            setValidation('');
            setAddFormInfo(null);
        }
    }

    return (
        <>
            <section className='add-role-container'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'>{editMode ? 'EDIT' : 'ADD'} ID </div>
                    <div style={{ color: '#ED4F32' }}>{validation}</div>
                    <form onSubmit={onSubmitAccount} >
                        <main className='input-container-three'>
                            <div className='container-one' style={{ width: "30%" }}>
                                <label HtmlFor="role">Account Name *</label>
                                {editMode ? <div><b>{editFormInfo?.value1}</b></div>
                                    : <OnboardSelect data={props.data} name='value1' value='customer' id='customer'
                                        addValueToQueue={addValueToID}
                                    />}
                            </div>
                            <div className='container-one' style={{ width: "30%" }}>
                                <label HtmlFor='username'>System Name *</label>
                                {editMode ? <div><b>{editFormInfo?.value2}</b></div>
                                    : <input type='text'
                                        style={{ marginTop: '-10px' }}
                                        name='value2'
                                        placeholder='Example:01234577865'
                                        onChange={handleAddFormValue}
                                    />}
                            </div>
                        </main>
                        <main className='input-container-three' style={{ width: "60%" }}>
                            <div className='container-three'>
                                <label >ID *</label>
                                {editMode ? <div><b>{editFormInfo?.value3}</b></div>
                                    : <input type='text'
                                        name='value3'
                                        style={{ marginTop: '-15px' }}
                                        placeholder='Example:01234577865'
                                        onChange={handleAddFormValue}
                                    />}
                            </div>
                            <main className='input-container-three'>
                                <DatePickerHandle label='Valid From *' value={ValidFrom} id='label'
                                    onChange={(date) => ValidfromDateHandler(date)}
                                />
                                <DatePickerHandle label='Valid To *' value={ValidTo} id='label'
                                    onChange={(date) => ValidtoDateHandler(date)}
                                />
                            </main>
                        </main>
                        <main style={{ display: 'flex', gap: '30px', marginLeft: '80px', marginTop: '10px' }}>
                            <button type='submit' className='btn_adduser'> {editMode ? 'UPDATE' : 'ADD'}
                            </button>
                            <button className='btn_canceluser' onClick={props.handleAddRoleCancel}>CANCEL 
                            </button>
                        </main>
                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}