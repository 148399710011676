import react, { useState, useEffect } from 'react';
import './pie-chart.css';
import { Pie } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { LineAreaChartModel, LineAreChartOptions } from "../chart-config";
import AboveRequiredTargetIcon from "../../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";


const PieChart = (props) => {
     const labels = [];
     const data = [];
     let tcvVal, tcvVal2, color;
     const backgroundClrs = [];
     let defaultLineChartData = new LineAreaChartModel(); 
     props?.data?.Compliance?.map((itm, i)=>{
      defaultLineChartData?.labels.push(itm.YearMonth);
      defaultLineChartData?.datasets[0]?.data?.push(parseFloat(itm.Value1));
    }); 
    if(props?.data && props?.data?.ComplianceColour && props?.data?.ComplianceColour[0]?.ComplianceColour)
    defaultLineChartData?.datasets[0]?.backgroundColor.push(props?.data?.ComplianceColour[0]?.ComplianceColour);

    if(props?.data['ContactVolume'])
    tcvVal = props?.data['ContactVolume'][0]?.Value1 ? props?.data['ContactVolume'][0]?.Value1 : null;

    if(props?.data['ContactVolume']){
      tcvVal = props?.data['ContactVolume'][0]?.Value1 ? props?.data['ContactVolume'][0]?.Value1 : null;
      tcvVal2 = props?.data['ContactVolume'][0]?.Value2 ? props?.data['ContactVolume'][0]?.Value2 : null;
      color = props?.data['ContactVolume'][0]?.Color ? props?.data['ContactVolume'][0]?.Color : null;
    }   

    props?.data?.Channels?.map((v) => {
      labels.push(v?.Lable1);
      data.push(v?.Value1);
      backgroundClrs.push(v?.Colour);
    });

    const options = {
      responsive: true,     
      plugins:{
        legend: {
          display: false
        },
        tooltip:{
                displayColors: true,
                backgroundColor: 'white',
                bodyColor: 'black',
                titleMarginBottom: 0,
                borderColor: '#969696',
                borderWidth: 1,
                titleColor: '#63666A',
                titleAlign: 'center',
                titleFont: {weight: '400'},
                displayColors: false,
                padding: 10,
                cornerRadius: 0,
                caretSize:5,
                bodyFont: {weight: 'bolder'}
        }
      },
      maintainAspectRatio: false,
      series: {
        pie: {
            show: true
        }
    },
    legend: {
        show: false
    },
    layout: {
      padding: {
        top: 50,
        left: 0,
        right: 0,
        bottom: 0
      }
    }
    };
    
    const gdata = {
      labels: labels,
      datasets: [
        {
          label: '# of Votes',
          data: data,
          backgroundColor: backgroundClrs,
          borderColor: backgroundClrs,
          borderWidth: 1,
          polyline: {
            color: "grey",
            labelColor: "grey",
            formatter: (value) => `${value}`
          }
        },
      ],
    };

    const handleContctVolClick = (data) => {
        props.pieChartClicked(data);
    };

    const tcvValClicked = (data) => {
      props.tcvValueClickedHandler(data);
    }

    const getSuitableY = (y, yArray = [], direction) => {
      let result = y;
      yArray.forEach((existedY) => {
        if (existedY - 16 < result && existedY + 16 > result) {
          if (direction === "right") {
            result = existedY + 16;
          } else {
            result = existedY - 16;
          }
        }
      });
      return result;
    }

    const getArrowMark = (color) => {
      switch(color){
        case '#6CC24A': {
          return (<img src={AboveRequiredTargetIcon} className="margn-5 mrgn-top-arrw-1"  alt="AbvRqdTgt Icon" />);
          }
        case 'Yellow' : {
          return (<img src={AboveMinimalTargetIcon}  className="margn-5 mrgn-top-arrw-1"  alt="AbvMnmlTgt Icon" />);
          }
        case 'Red' : {
          return(<img src={BelowMinimalTargetIcon}  className="margn-5 mrgn-top-arrw-1"  alt="BlwMnmlTgt Icon" />);
          }
        default: {
          return(<div></div>);
        }
      }
    }

    const getValColor = (clr) => {
      switch(clr){
          case '#6CC24A': {
            return '#6CC24A';
            }
          case 'Yellow' : {
            return 'yellow';
            }
          case 'Red' : {
            return '#EA333D';
            }
          case 'Green' : {
            return '#6CC24A';
          }
          default: {
            return(<div></div>);
          }
        }
  }

      return (       
        <div className='pie-contct-vol-container'>
            <div className='row pie-contctvol-label'>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <label className='pie-contct-vo-lbl'>Contact Volume</label>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6'>
              <div className="row">                                            
                      <div className="col-sm col-md col-lg text-end"> 
                          <span style={{display: 'inline-block',marginLeft: '15px'}}><span style={{marginLeft: '-50px'}} className="dash-rpt"></span><Link className="dash-line-color" to="/contact"><h6 className="dash-line-color"><b>VIEW REPORT</b></h6></Link></span>
                      </div>
                  </div>
            </div>              
            </div>
            <div className='row pie-chrt-container'>
              <div className='col-sm-12 col-md-12 col-lg-5 pie-tcv-middle-line'>
                <div className='row pie-totalcontctvol-label'><label className='pie-tcv-label'>Total Contact Volume (TCV)</label></div>
                <div className='row'>
                  <div className='col-sm-12 col-md-6 col-lg-6'>
                      <div onClick={()=>tcvValClicked(props?.data)} className='pie-totalcontctvol-value'><label className='pie-totalcontctvol-lbl-value1'>{tcvVal}</label></div>
                      <div className='pie-totalcontctvol-value'><label ><span >{getArrowMark(color)}</span><span style={{color: getValColor(color)}} className='pie-totalcontctvol-lbl-prcnt'>{tcvVal2}</span><span className='pie-totalcontctvol-lbl-lastMnth'>Last Month</span></label></div>
                  </div>
                  <div className='col-sm-12 col-md-6 col-lg-6 pie-card-line-container'>
                      <Line data={defaultLineChartData} options={LineAreChartOptions} />
                  </div>
                </div>
              </div>

              <div className='col-sm-12 col-md-12 col-lg-7'>
              <div className='row pie-totalcontctvol-label'><label className='pie-tcv-label'>Contact Volume By Channels</label></div>
                  <div className='row'>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                  <div onClick={()=>handleContctVolClick(props?.data)} style={{ height:'250px', width:'400px',margin:'10px 20px', cursor: 'pointer' }}>                       
                       <Pie data={gdata} options={options} plugins={[
                         {
                          afterDraw: (chart) => {
                            const { ctx, chartArea: { top, bottom, left, right, width, height}} = chart;
                            ctx.save();
                            ctx.font = "normal normal 600 14px/19px 'Open Sans'";
                            const leftLabelCoordinates = [];
                            const rightLabelCoordinates = [];
                            const chartCenterPoint = {
                              x:
                                (chart.chartArea.right - chart.chartArea.left) / 2 +
                                chart.chartArea.left,
                              y:
                                (chart.chartArea.bottom - chart.chartArea.top) / 2 +
                                chart.chartArea.top
                            };
                            chart.config.data.labels.forEach((label, i) => {
                              const meta = chart.getDatasetMeta(0);
                              const arc = meta.data[i];
                              const dataset = chart.config.data.datasets[0];
                      
                              // Prepare data to draw
                              // important point 1
                               const centerPoint = arc.getCenterPoint();
                               //const model = arc._model;
                              let color = 'grey';
                              let labelColor = 'grey';
                              if (dataset.polyline && dataset.polyline.color) {
                                color = dataset.borderColor[i];
                              }
                      
                              if (dataset.polyline && dataset.polyline.labelColor) {
                                color = dataset.borderColor[i];
                              }
                      
                              const angle = Math.atan2(
                                centerPoint.y - chartCenterPoint.y,
                                centerPoint.x - chartCenterPoint.x
                              );
                              // important point 2, this point overlapsed with existed points
                              // so we will reduce y by 14 if it's on the right
                              // or add by 14 if it's on the left
                              const point2X =
                                chartCenterPoint.x + Math.cos(angle) * (arc.outerRadius + 15);
                              let point2Y =
                                chartCenterPoint.y + Math.sin(angle) * (arc.outerRadius + 15);
                      
                              let suitableY;
                              if (point2X < chartCenterPoint.x) {
                                // on the left
                                suitableY = getSuitableY(point2Y, leftLabelCoordinates, "left");
                              } else {
                                // on the right
                      
                                suitableY = getSuitableY(point2Y, rightLabelCoordinates, "right");
                              }
                      
                              point2Y = suitableY;
                      
                              let value = dataset.data[i];
                              if (dataset.polyline && dataset.polyline.formatter) {
                                value = dataset.polyline.formatter(value);
                              }
                              let edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;
                      
                              if (point2X < chartCenterPoint.x) {
                                leftLabelCoordinates.push(point2Y);
                              } else {
                                rightLabelCoordinates.push(point2Y);
                              }
                              //DRAW CODE
                              // first line: connect between arc's center point and outside point
                              ctx.strokeStyle = color;
                              ctx.beginPath();
                              ctx.moveTo(centerPoint.x, centerPoint.y);
                              ctx.lineTo(point2X, point2Y);
                              ctx.stroke();
                              // second line: connect between outside point and chart's edge
                              ctx.beginPath();
                              ctx.moveTo(point2X, point2Y);
                              ctx.lineTo(edgePointX, point2Y);
                              ctx.stroke();
                              //fill custom label
                              const labelAlignStyle =
                                edgePointX < chartCenterPoint.x ? "left" : "right";
                              const labelX = edgePointX;
                              const labelY = point2Y;
                              ctx.textAlign = labelAlignStyle;
                              ctx.textBaseline = "bottom";
                      
                              ctx.fillStyle = labelColor;
                              ctx.fillText(value, labelX, labelY);
                            });
                            ctx.restore();
                          }
                        }
                       ]}  />
                  </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-6'>
                    <ul id="pie-lgnd">   
                      {props?.data?.Channels?.map((itm,i)=>{
                          return <li key={i}><div className="pie-dot" style={{backgroundColor: itm.Colour}}></div><span className="bar-lgnd-text">{itm.Lable1}</span></li>
                      })}
                  </ul>
                  </div>
                  </div>
              </div>

            </div>
        </div>
        
      );
};

export default PieChart;