import React from "react";
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch} from "react-redux";
import "./clientCards.css";
import { Button } from "react-bootstrap";
import SiteLevelReport_Icon from "../../../assets/images/SiteLevelReport_Icon.png";
import AboveRequiredTargetIcon from "../../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";
import { updateSelectedAccountName, saveSelectedMenuItem, saveAccountLocationName } from "../../../actions/tabMenu.action";
import { updateRegionalSiteCustomerID, updateRegionalSiteCustomerName, updateRegionalSiteServiceDeskID } from '../../../actions/regionalSite.action';
const ClientCards = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    

    const getArrowIcon = (color) => {
        let colr;
        switch(color){
            case 'yellow' : { colr = AboveMinimalTargetIcon; break;}
            case 'green' : { colr = AboveRequiredTargetIcon; break;}
            case 'red' : { colr = BelowMinimalTargetIcon; break;}
            default : {}
        }
        return colr;
    }

    const viewDTMReports = (service_id,c_name, cust_id, service_desk) => {
        if(service_id){
            window.localStorage.setItem('ServiceDeskID', service_id);           
        }
        if(cust_id){
            window.localStorage.setItem('CustomerID', cust_id);
        }
        dispatch(updateRegionalSiteCustomerID(cust_id));
        dispatch(updateRegionalSiteCustomerName(c_name));
        dispatch(updateSelectedAccountName(c_name));
        dispatch(saveAccountLocationName(service_desk));
        dispatch(saveSelectedMenuItem("reports"));
        dispatch(updateRegionalSiteServiceDeskID(service_id));
        history.push("/reports");
    };

    const handleSLASelection = (serv_id) => {
        if(serv_id)   
        window.open(`${process.env.REACT_APP_BASE_URL}/api/metrics/redirecttositereport/${serv_id}`);
    }

    return (

        <div className="serv">
        <ul>
            <li>
            <div className="card card-hght item-animation">
            <div className="card-body">
            <h5 className="card-title" style={{color: '#fff'}}>Card title</h5>
                <div style={{borderBottom: '1px solid #DDDDDD', height: '80px'}} className="card-title">
                    <img style={{maxHeight: '75px'}} src={SiteLevelReport_Icon} className="card-image" alt=''/>
                </div>                
                <h4  className="card-text card-text-site-lvl">{props.siteName} Site Level Report</h4>               
            </div>
            <div className="item-animation-overlay top-animation">
                <Button onClick={()=>handleSLASelection(props?.serviceDeskID)}   className='clnt-card-btn'  variant="outline-secondary">View Site Level SLA</Button>
            </div>
            </div>
            </li>
           
            {props?.data?.map((itm,idx)=>{
                return (
                    <li key={idx}>
                    <div className="card card-hght item-animation">
                        <div className="card-body">
                            <div className="card-title-hght"><h5 className="card-title card-title-hdr">{itm?.customer}</h5></div>
                            <div style={{borderBottom: '1px solid #DDDDDD', height: '80px'}} className="card-title">
                                <img className="image fluid" style={{maxHeight: '75px'}} alt="client logo" src={`${process.env.REACT_APP_BASE_URL}/api/org/customer/${itm?.customerid}/photo`} />
                            </div>  
                            <div className="card-text">
                                <div className="row card-no-top-space">
                                    <div style={{borderRight: '1px solid #DDDDDD'}} className="col-sm-6 col-md-6 col-lg-6 card-sla-height">                            
                                        <div className="row card-sla-label">
                                            <h6>SLAs Met</h6>
                                        </div>
                                        <div className="row mrgn-tp-20">
                                            <b>{itm?.items?.slamet?.value} <img className="arrw-top" src={getArrowIcon(itm?.items?.slamet?.color)}/></b>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 card-sla-height">
                                        <div className="row card-sla-label">
                                            <h6>SLA Over Delivery</h6>
                                        </div>
                                        <div className="row mrgn-tp-20">
                                            <b>{itm?.items?.slaovd?.value} <img className="arrw-top" src={getArrowIcon(itm?.items?.slaovd?.color)}/></b>
                                        </div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                        <div className="item-animation-overlay top-animation">
                            <Button onClick={()=>viewDTMReports(props?.serviceDeskID, itm?.customer, itm.customerid, props?.siteName)}   className='clnt-card-btn'  variant="outline-secondary">View DTM</Button>
                        </div>
                        </div>
                    </li>  
                );
            })}                   
        </ul>
        </div>
      
        );
};


export default ClientCards;