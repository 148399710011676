





const StaffAdminURLS = (name, items) => {
    switch (name) {
        case 'GET_AVAILABLE_EMPLOYEES': {
            return `/api/usp/StaffAdmnGetEmployeesAvailable?name=${items}`;
        }
        case 'GET_SUPERVISOR_LIST': {
            return `/api/usp/StaffAdmnGetSupervisors`;
        }
        case 'UPDATE_NEW_USER_ROLE': {
            return `/api/usp/StaffAdmnNewUserRole`
        }
        case 'UPDATE_SUPERVISOR_EMPLOYEE_MAPPING': {
            return `/api/usp/StaffAdmnSupervisorEmployeeMapping`
        }
        case 'UPDATE_ACCOUNT_EMPLOYEE': {
            return `/api/usp/StaffAdmnAcccountEmployee`
        }
        case 'GET_ROLE_CATEGORY': {
            return `/api/usp/StaffAdmnGetRoleCategoryRoles`;
        }
        case 'GET_STAFF_ACCOUNTS': {
            return `/api/usp/StaffAdminGetAccounts?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_ACCOUNT_ASSIGNMENT': {
            return `/api/usp/StaffAdminGetAccountAssignment?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_QUEUE_ASSIGNMENT': {
            return `/api/usp/StaffAdmnGetQueueAssigned?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_ID_ASSIGNMENT': {
            return `/api/usp/StaffAdmnGetIDAssignment?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_SLA_ASSIGNMENT': {
            return `/api/usp/StaffAdmnGetAccountSLAAssigned?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_VOLUME_ASSIGNMENT': {
            return `/api/usp/StaffAdmnGetVolumetricTemplatesAssigned?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'UPDATE_QUEUE_ASSIGNMENT': {
            return `/api/usp/StaffAdmnQueueAssignment`;
        }
        case 'UPDATE_ID_ASSIGNMENT': {
            return `api/usp/StaffAdmnIDAssignment`;
        }
        case 'UPDATE_SLA_ASSIGNMENT': {
            return `api/usp/StaffAdmnAccountSLAAssignment`;
        }
        case 'UPDATE_VOLUME_ASSIGNMENT': {
            return `api/usp/StaffAdmnAccountVolumitric`;
        }
        case 'GET_STAFF_PREVIEW': {
            return `/api/usp/StaffAdmnPreview?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_MAPPED_EMPLOYEES': {
            return `/api/usp/StaffAdmnGetMappedEmployees`;
        }
        case 'GET_QUEUE_DATA': {
            return `/api/usp/StaffAdmnGetQueues?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_ACCOUNT_SLA': {
            return `/api/usp/StaffAdmnGetAccountSLAs?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_VOLUME_TEMPLATES': {
            return `/api/usp/StaffAdmnGetAccountVolumetricTemplates?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_ID_ACCOUNTS': {
            return `/api/usp/StaffAdmnGetAccountIDAssignment?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_SLA_ACCOUNTS': {
            return `/api/usp/StaffAdmnGetAccountsAssigned?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_VALID_SUPERVISOR': {
            return `/api/usp/StaffAdminCheckSupervisor`;
        }
        case 'GET_ACCOUNT_SLA_FOREDIT': {
            return `/api/usp/StaffAdmnGetAccountSLAsValidate?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        case 'GET_VOLUME_TEMPLATES_FOREDIT': {
            return `/api/usp/StaffAdmnGetAccountVolumetricTemplatesValidate?EmployeeID=${items.empId}&SupEmployeeID=${items.supId}`;
        }
        default: { }
    }
}


export default StaffAdminURLS



