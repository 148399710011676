import React, { useState } from 'react'
import { ListGroup, Offcanvas } from 'react-bootstrap'

export const AccountsOffcanvas = (props) => {
  
  return (
    <>
      <Offcanvas show={props.show} onHide={props.handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ marginTop: '1.5em' }}><span className='title' style={{color:'#5F249F' }}>{props.Title}</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: '#FFFFFF', border: ' 1px solid #ECEEF1',padding:0 }}>
        <div>
          {
             <ListGroup variant="flush">
                        {props.ScorCardOffCanvasData.map((data, index) => (<ListGroup.Item style={{ height: '52px' }} key={index}>
                         <span className='offcanvas_listacc'> {data.Account}</span>
                        </ListGroup.Item>))}
                    </ListGroup>
            }
        </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export const AccountsOffcanvasTL = (props) => {
  
  return (
    <>
      <Offcanvas show={props.show} onHide={props.handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ marginTop: '1.5em' }}><span className='title' style={{color:'#5F249F' }}>{props.Title}</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: '#FFFFFF', border: ' 1px solid #ECEEF1',padding:0 }}>
        <div>
          {
             <ListGroup variant="flush">
                        {props.ScorCardOffCanvasData.map((data, index) => (<ListGroup.Item style={{ height: '52px' }} key={index}>
                         <span className='offcanvas_listacc'> {data.value}</span>
                        </ListGroup.Item>))}
                    </ListGroup>
            }
        </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}