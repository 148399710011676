import { all } from "redux-saga/effects";
import userSagas from "./userDataSaga";
import tabSagas from "./tabDataSaga";
import financeSagas from "./finance.saga";
import regionalSiteSagas from "./regionalSiteSaga";
import eDashboardSagas from "./EDashboard/eDashboard.saga";
import reportsSagas from "./reportsSaga";
import welcomePageSagas from "./welcomePageSaga";
import OnboardSagas from "./Onboard.saga";
import adminAccountSagas from "./Administration/admin.saga";
import StaffAdminSagas from "./Administration/staffAdminSaga";
import slaFailureSagas from "./Administration/slaFailure.saga";
import githubSagas from "./githubSaga";
import requestSagas from "./requestSaga";
import AdminViewSagas from "./Administration/adminView.saga";
import ScoreCardSagas from "./ScoreCard&TeamKPI.saga";
import UploadSagas from "./Upload.saga";

export default function* rootSaga() {
  yield all([userSagas(),
  tabSagas(),
  financeSagas(),
  regionalSiteSagas(),
  eDashboardSagas(),
  reportsSagas(),
  welcomePageSagas(),
  OnboardSagas(),
  adminAccountSagas(),
  StaffAdminSagas(),
  slaFailureSagas(),
  githubSagas(),
  requestSagas(),
  AdminViewSagas(),
  ScoreCardSagas(),
  UploadSagas()
  ]);
};

