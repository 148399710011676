import React from 'react';
import { useHistory } from 'react-router-dom';
import './General.css';



function AdminBreadcrumb({
   isActive2, isActive3, isActive4, isActive5,isActive6,
  value1, value2, value3, value4, value5, value6,
  label1, label2, label3, label4, label5,label6 }) {
  const history = useHistory();
  return (<>
    <section className='admin_breadcrumb'>
      <span style={{ color: '#5F249F',cursor: 'pointer' }} onClick={value1}> {label1}</span> 
      <span style={{ color: isActive2 ? '#5F249F' : '#63666A',cursor: isActive2&& 'pointer' }} onClick={value2} >{` / `} {label2}</span>
      {label3 &&  <span style={{ color: isActive3 ? '#5F249F' : '#63666A',cursor: isActive3&& 'pointer' }} onClick={value3} >{` / `} {label3}</span>}
      {label4 && <span style={{ color: isActive4 ? '#5F249F' : '#63666A',cursor: isActive4&& 'pointer' }} onClick={value4} >{` / `} {label4}</span>}
      {label5 && <span style={{ color: isActive5 ? '#5F249F' : '#63666A',cursor: isActive5&& 'pointer' }} onClick={value5} >{` / `} {label5}</span>}
      {label6 && <span style={{ color: isActive6 ? '#5F249F' : '#63666A',cursor: isActive5&& 'pointer' }} onClick={value6} >{` / `} {label6}</span>}
    </section>


  </>

  )
}

export default AdminBreadcrumb