import React from "react";
import "./box.css";
import greenStatusImg from "../../assets/images/green_status.png";
import redStatusImg from "../../assets/images/red_status.png";
import ContractualSLAIcon from "../../assets/images/Contractual_SLAs.png";
const Box = (props) => {
  return (
    <div className="box">
      <div className="border-box"></div>
      <div className="box-body">
        <div className="box-name">
          {props.data ? props.data.name + (props.data.line !== null ? "  --  " + props.data.line : "") : ""}
        </div>
        <div className="info">
          {props.data
            ? props.data.info.map((item, i) => (
              <div className="box-info" key={i} onClick={() => props.SLAReportHandleClick(item)}  >
                <div className={props.click ? "box-text1" : "box-text1-normal"} style={{ cursor: props.click && 'pointer' }} >
                  {item.percentage}
                  <span className="box-status-img">
                    {item.status ? (
                      <img
                        src={
                          item.status === "green"
                            ? greenStatusImg
                            : redStatusImg
                        }
                        alt="status"
                      />
                    ) : null}
                    {item.sla ? (
                      <img src={ContractualSLAIcon} alt="status" />
                    ) : null}
                  </span>
                </div>
                <div className="box-text2" style={{ cursor: props.click && 'pointer' }} >{item.infoName}</div>
              </div>
            ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default Box;
