import React from 'react'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import { Footer } from '../HeaderFooter';
import FAQ_HeroBanner from './../../../assets/github-images/FAQ_HeroBanner.png';
import './FAQ.css'
import { Accordion } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { EmailTab, RequestTab, RequestTabPopup } from '../GitHub_LandingPage/Models';
import { Link } from 'react-router-dom';

const PROD_LINK = 'https://gsd-insight.houston.dxccorp.net/' ;
const MS_TEAMS = 'https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&tenantId=93f33571-550f-43cf-b09f-cd331338d086' ;
const ISSUES = 'https://github.dxc.com/MWSGSD/GSD-Insight_Support/issues/new/choose' ;

const FAQ = () => {
    const history=useHistory(); 
    const [showRequestModel, setShowRequestModel] = useState(false);
  const [showEmailModel, setShowEmailModel] = useState(false);
  return (
    <>
       <section style={{ marginLeft: -100 }}>
        <main className='breadcrumb_solution'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Security' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>FAQ</div>This part of the website provides answers to the most common user 
          issues and queries related to the usage of the GSD Insight Tool, to overcome any concerns.</div>
          <main className='faq_image_container'>
          <div ><img src={FAQ_HeroBanner} alt='HeroBanner'  width= '1010px' height= '274px' style={{objectFit:'cover'}}/></div>
                <div class="text_on_faq" >
                <div className='faq_img'>F</div><div className='faq_img'>A</div><div className='faq_img'>Q</div>
                </div>
            </main>
          </div>
        </main>
        <main className='conceptual_arch_user'>
        <section style={{width:1200,margin:'auto'}}>
         <main className='select_category'>Select a category below</main>
         <div style={{ width: '98%' }}>
                    <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                        <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion_header'>General</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <section className='accordian_subtext'>
                                   <main id='block'><div id='header'>How can I raise change request/incident?</div>The&nbsp; 
                                   <span id='link' onClick={()=>setShowRequestModel(true)}>Request/Incident</span> can be raised directly on the GSD Insight platform.</main> 
                                   <main id='block'><div id='header'>What are the GSD Insight trainings available?</div> Detailed information can be found   <Link to ='/training' target='_blank'><span id='link'>here</span></Link>.</main>
                                   <main id='block'><div id='header'>How are the data privacy and special contract requirements governed?</div> Detailed information can be found  <Link to ='/security' target='_blank'><span id='link'>here</span></Link>.</main>
                                   <main id='block'><div id='header'>What are communication channels to get in touch with GSD Insight support team?</div>
                                   There are various forms how to get in touch: <br/>
                                   <span id='link' onClick={()=>setShowEmailModel(true)}>Email</span>
                                   <br/>
                                   <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={MS_TEAMS} title="" >
                                   <span id='link'>MS Team channel</span></a>
                                   </main>


                                   
                                   
                                </section>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion_header'>Tools / Access</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                            <section className='accordian_subtext'>
                                    <main id='block'><div id='header'>What is the link/URL to GSD Insight platform?</div>  GSD Insight production&nbsp;
                                   <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={PROD_LINK} title="" >
                                   <span id='link'>link / URL</span></a>.</main> 
                                  <main id='block'><div id='header'>How to get access to GSD Insight? </div> Detailed information can be found&nbsp;<Link to ='/getstarted' target='_blank'><span id='link'>here</span></Link>.</main>
                                   <main id='block'><div id='header'>Is VPN (Appgate) required for login? </div> Yes</main>
                                   
                                   
                                </section>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={2} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion_header'>Content / Reporting</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                            <section className='accordian_subtext'>
                            <main id='block'><div id='header'>Can GSD Insight address all the reporting needs?</div>
                                   No, GSD Insight Platform has been designed for Global Standard Reporting. Accounts/Sites custom reporting needs to be addressed individually through specific solutions.
                                   <br/> GSD SLA & KPI Standards can be checked <Link to ='/user' target='_blank'><span id='link'>here</span></Link>.</main>


                                   <main id='block'><div id='header'>How to onboard and administer Account?</div> 
                                   To be able to onboard and / or administer an account, it is necessary to have Site Leader and / or Service Delivery Manager role 
                                   for the particular account. To onboard a new account please follow the <Link to ='/onboard' target='_blank'><span id='link'>Onboard</span></Link> section in GSD Insight. To administer an account please 
                                   contact your Site Leader and / or Service Delivery Manager [responsible for the particular account/s] located in the <Link to ='/notinproduction' target='_blank'><span id='link'>Administration</span></Link> section in GSD Insight.
                                   </main> 
                                   <main id='block'><div id='header'>How to onboard and administer agent in the GSD Insight solution? </div> 
                                   This component of the administration module is available only to authorized resources (people managers, team leads/supervisors and site managers). 
                                   Data synchronization with Workday/Smart-Labor database is established and executed on monthly basis to manage joiner/leaver process (active/inactive). 
                                   Agent onboard and administration can be performed  <Link to ='/notinproduction' target='_blank'><span id='link'>here</span></Link>.</main>
                                   <main id='block'><div id='header'>What are the data types available in GSD Insight? </div>
                                   Telephony (voice) with established data connection to AWS. Non-voice data in the pipeline and it will be subject of data provisioner how and in which format they can supply the
                                    non-voice data (manual, database connection, semi-automatic, etc.).</main>
                                   
                                </section>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
        </section>
        </main>
        <Footer/>
        </section>

        {showRequestModel && <RequestTabPopup isOpen={showRequestModel} setShowModal={setShowRequestModel}>
        <RequestTab modalName='request' closeWindow={()=>setShowRequestModel(false)}
        />
      </RequestTabPopup>}
      {showEmailModel && <RequestTabPopup isOpen={showEmailModel} setShowModal={setShowEmailModel}>
        <EmailTab modalName='request' closeWindow={()=>setShowEmailModel(false)}
        />
      </RequestTabPopup>}
    </>
  )
}

export default FAQ