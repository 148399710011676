import React, { useEffect, useState } from 'react'
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png';
import './../../AdminFirstPage/AdminFirstPage.css';
import { useDispatch, useSelector } from 'react-redux';
import RolesTableAdminView from './RolesTableAdminView';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import { getAdminRoles } from './../../../../actions/Administration/adminView.action';
import { Link} from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const AdminRolesFlow = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filter, setFilter] = useState('');
  const [searchColumns, setSearchColumns] = useState(['Role Name']);
  
  let adminViewRoleData = useSelector((state) => state.adminViewReducer.adminViewRoleData);
console.log('adminViewRoleData',adminViewRoleData)

const mode = {
  Mode : 'Add'
  };

useEffect(() => {
  dispatch(getAdminRoles());
  
}, [dispatch]);

  function searchRows(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]
            .toString()
            .toLowerCase()
            .indexOf(filter.toLowerCase()) > -1,
      ),
    );
  }
  return (
    <div style={{paddingBottom:'200px',marginRight:20}}>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={()=>history.push('/adminviewlanding')}
        isActive3={false} label3='Roles'
      />
      <header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='administration_text'>Roles : {adminViewRoleData.length}</div>
        <div style={{ display: 'flex', gap: '20px' }}>
       <div style={{marginTop:20}}> <Link to={{ pathname: '/admin/roles/addRole', state: mode}}
  ><span className='btn_addAdminFlow'>ADD ROLE</span></Link></div>
          <span className='search_bar_AdminFlow'>
            <input className='search_input_AdminFlow' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Roles' />
            <button className='search_icon_AdminFlow'><img src={searchIcon} alt='' /> </button>
          </span>
        </div>
      </header>
      <section className='adminView_table' style={{ width: '100%'}}>
      
      {searchRows(adminViewRoleData).map((info, i) => <RolesTableAdminView info={info} key={i}/>)}
      </section>
    </div>
  )
}

export default AdminRolesFlow