import { all, call, put, takeEvery } from "redux-saga/effects";
import { updateExclusionCommentSuccess, updateExistingCommentSuccess } from "../../actions/Administration/SLAFailure.action";
import { slaFailure_actionTypes as actionTypes } from './../../constants/Administration/SLAFailure-actionTypes';
import SlaFailureURLs from "./../../constants/Administration/SLAFailure-urls";
import { getExemptionDataService, updateExistingCommentService, getExclusionDataService, updateExclusionCommentService } from './../../services/Administration/slaFailure.service';

function* fetchExemptionData({ custId, servId, month }) {
    let items = { custId, servId, month }

    try {
        const response = yield call(getExemptionDataService, SlaFailureURLs('GET_EXEMPTION_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.GET_EXEMPTION_DATA_SUCCESS,
                payload: response.data,
            });
        }
        console.log(response.data)
    } catch (error) {
        console.log("Error while Exemption data", error);
    }
}


function* updateExistingComment({ custId, servId, month,sla, id }) {
    let items = { custId, servId, month, sla }
    try {
        const response = yield call(updateExistingCommentService, SlaFailureURLs('UPDATE_EXISTING_COMMENT', items), id);
        if (response.data) {
            yield put(updateExistingCommentSuccess());
        }
        console.log(response.data)
    } catch (error) {
        console.log("Error while updating existing comment", error);
    }
}

function* fetchExclusionData({ custId, servId, month }) {
    let items = { custId, servId, month }

    try {
        const response = yield call(getExclusionDataService, SlaFailureURLs('GET_EXCLUSION_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.GET_EXCLUSION_DATA_SUCCESS,
                payload: response.data,
            });
        }
        console.log(response.data)
    } catch (error) {
        console.log("Error while Exemption data", error);
    }
}


function* updateExclusionComment({ custId, servId, month,sla, id }) {
    let items = { custId, servId, month, sla }
    try {
        const response = yield call(updateExclusionCommentService, SlaFailureURLs('UPDATE_EXCLUSION_COMMENT', items), id);
        if (response.data) {
            yield put(updateExclusionCommentSuccess());
        }
        console.log(response.data)
    } catch (error) {
        console.log("Error while updating existing comment", error);
    }
}



function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_EXEMPTION_DATA_START, fetchExemptionData);
    yield takeEvery(actionTypes.UPDATE_EXISTING_COMMENT_START, updateExistingComment);
    yield takeEvery(actionTypes.GET_EXCLUSION_DATA_START, fetchExclusionData);
    yield takeEvery(actionTypes.UPDATE_EXCLUSION_COMMENT_START, updateExclusionComment);
}
export default function* slaFailureSagas() {
    yield all([watchGetRequest()]);
}