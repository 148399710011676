import React, { useState } from "react";
import { HashRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "./App.layout.css";
import AppRoutes from "./App.routes";
import Header from "./components/Header/header";
import { GithubHeader } from './components/GitHub/HeaderFooter';
const AppLayout = () => {
    // This is the main layout
    const [localPath, setLocalPath] = useState('')
    const localPathnameHandler = (name) => {
        setLocalPath(name)
    }
    const HeaderHandler = (localPath) => {
        switch (localPath) {
            case '/widget':
                return <Header show={false} logo={true} />
            case '/role':
                return <Header show={false} logo={false} introPage={false} />
            case '/':
            case '/intro':
                return <Header show={false} logo={false} introPage={true} />
            case '/githublanding':
            case '/whygsd':
            case '/getstarted':
            case '/architecture':
            case '/architecture/card':
            case '/developer/card':
            case '/product':
            case '/developer':
            case '/user':
            case '/security':
            case '/overview':
            case '/training':
            case '/design':
            case '/design/card':
            case '/database':
            case '/features':
            case '/faq':
            case '/notinproduction':
                return <GithubHeader />
            default:
                return <Header isMenu={true} isInfo={false} show={true} />
            
        }
    }


    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <HashRouter>
                <div className="app">
                    {HeaderHandler(localPath)}
                    <div style={{ marginTop: '125px', marginLeft: '100px' }}>
                        <AppRoutes
                            localPathnameHandler={localPathnameHandler}
                        />
                    </div>
                </div>
            </HashRouter>
        </MuiPickersUtilsProvider>
    );
};

export default AppLayout;