export const ScoreCardActionTypes = {
    GET_SCORECARD_DATA: "GET_SCORECARD_DATA",
    SCORECARD_DATA: "SCORECARD_DATA",

    GET_SCORECARD_PERFORMANCE_DATA: "GET_SCORECARD_PERFORMANCE_DATA",
    SCORECARD_PERFORMANCE_DATA: "SCORECARD_PERFORMANCE_DATA",

    GET_SCORECARD_OFFCANVAS_DATA: "GET_SCORECARD_OFFCANVAS_DATA",
    SCORECARD_OFFCANVAS_DATA: "SCORECARD_OFFCANVAS_DATA",

    GET_AGENTS_KPI_SUMMARY_DATA:"GET_AGENTS_KPI_SUMMARY_DATA",
    AGENTS_KPI_SUMMARY_DATA:"AGENTS_KPI_SUMMARY_DATA",

    GET_TEAMLEADER_AGENTS: "GET_TEAMLEADER_AGENTS",
    TEAMLEADER_AGENTS: "TEAMLEADER_AGENTS",

    GET_TEAMLEADER_ACCOUNTS: "GET_TEAMLEADER_ACCOUNTS",
    TEAMLEADER_ACCOUNTS: "TEAMLEADER_ACCOUNTS",

    GET_TEAMLEADER_ACCOUNTS_VIEW: "GET_TEAMLEADER_ACCOUNTS_VIEW",
    TEAMLEADER_ACCOUNTS_VIEW: "TEAMLEADER_ACCOUNTS_VIEW",

    GET_TEAMLEADER_DATES: "GET_TEAMLEADER_DATES",
    TEAMLEADER_DATES: "TEAMLEADER_DATES",

    GET_AGENTS_KPI_ACCOUNTS_DATA:"GET_AGENTS_KPI_ACCOUNTS_DATA",
    AGENTS_KPI_ACCOUNTS_DATA:"AGENTS_KPI_ACCOUNTS_DATA",

    GET_TEAM_KPI_SUMMARY_DATA:"GET_TEAM_KPI_SUMMARY_DATA",
    TEAM_KPI_SUMMARY_DATA:"TEAM_KPI_SUMMARY_DATA",

    GET_AGENTS_GRAPH_DATA:"GET_AGENTS_GRAPH_DATA",
    AGENTS_GRAPH_DATA:"AGENTS_GRAPH_DATA",



    SAVE_SELECTED_TAB: "SAVE_SELECTED_TAB",
    SAVE_SELECTED_DATE: "SAVE_SELECTED_DATE",
    SAVE_SELECTED_AGENTSCORECARD_DATE: "SAVE_SELECTED_AGENTSCORECARD_DATE",


  };
  
  export default ScoreCardActionTypes;