
const getUserKeyword = (keyword) => {
    console.log(keyword)
    if (keyword === null || keyword === undefined) {
        return '/api/users'
    }
    else {
        return `/api/users/?keyword=${keyword}&$top=100`
    }
}

const getRoleId = () => {
    return localStorage.getItem('Role_id');
}


export const onboardURLS = (name, items) => {
    switch (name) {
        case 'GET_ACCOUNT_SITE': {
            return '/api/usp/OBAccountSite'
        }
        case 'GET_GENERAL_INFO_SITE': {
            return '/api/usp/OBGetSites'
        }

        case 'GET_ROLES_TAB_ROLES': {
            return '/api/usp/OBGetRoles'
        }
        case 'GET_ROLES_TAB_USERS': {
            return getUserKeyword(items.query);
        }
        case 'GET_ROLES_TAB_DATA': {
            return `/api/usp/OBGetRolesByAccount?customer=${items.account}&servicedesk=${items.site}`;
        }
        case 'UPDATE_ROLES_DATA': {
            return `/api/usp/OnBoardAccount`
        }
        case 'UPDATE_QUEUE_TABLE': {
            return `/api/usp/OnBoardQueues`
        }
        case 'GET_QUEUE_LANGUAGES': {
            return '/api/usp/OBGetLanguage '
        }
        case 'GET_ORIGINATING_COUNTRIES': {
            return '/api/usp/OBGetCountry'
        }
        case 'GET_QUEUE_TABLE_DATA': {
            return `/api/usp/OBGetQueuesByAccount?customer=${items.account}&servicedesk=${items.site}`;
        }
        case 'GET_SLA_TEMPLATES': {
            return `/api/usp/OBGetSLATemplate?Customer=${items.account}&VoiceNonVoice=${items.tab}`;
        }

        case 'UPDATE_SLA_TABLE': {
            return `/api/usp/OnBoardSLAKPIAccount`;
        }

        case 'GET_SLA_KPT_DATA': {
            return `/api/usp/OBGetSLAKPIByAccount?customer=${items.account}&servicedesk=${items.site}&VoiceNonVoice=voice&NonVoiceItem=AWS%20(ASD)%20Telephony`
        }
        case 'UPDATE_NEW_TEMPLATE': {
            return `/api/usp/OnBoardSLATemplate`
        }
        case 'UPDATE_SUPPORT_HOURS': {
            return `/api/usp/OnBoardSupportHours`
        }
        case 'GET_SUPPORT_HOURS_VOICE': {
            return `/api/usp/OBGetSupportHoursByaccount?customer=${items.account}&servicedesk=${items.site}&VoiceNonVoice=voice&NonVoiceItem=AWS%20(ASD)%20Telephony`;
        }
        case 'GET_NON_VOICE_SLA_DATA': {
            return `/api/usp/OBGetSLAKPIByAccount?customer=${items.account}&servicedesk=${items.site}&VoiceNonVoice=non-voice&NonVoiceItem=${items.widget}`;
        }
        case 'GET_NON_VOICE_SUPPORT_HOURS': {
            return `/api/usp/OBGetSupportHoursByaccount?customer=${items.account}&servicedesk=${items.site}&VoiceNonVoice=non-voice&NonVoiceItem=${items.widget}`;
        }
        case "UPDATE_GENERAL_NON_VOICE": {
            return `/api/usp/OnBoardNonVoiceGeneral`;
        }
        case 'GET_GENERAL_NON_VOICE': {
            return `/api/usp/OBGetNonVoiceGeneral?customer=${items.account}&Servicedesk=${items.site}&NonVoiceItem=${items.widget}`
        }
        case 'GET_PREVIEW_ONBOARD': {
            return `/api/usp/OBPreview?Customer=${items.account}&Servicedesk=${items.site}`;
        }

        case 'GET_ONBOARD_ACCOUNTS': {
            return `/api/usp/OBGetOnBoardAccounts?&hint=onboard`;
        }

        case 'UPDATE_ONBOARD_TOGGLE': {
            return `/api/usp/OnBoardCompleteAccount`;
        }

        case 'UPDATE_ONBOARD_SUBMIT': {
            return `/api/usp/OnBoardAccountSubmit`;
        }
        case 'GET_ONBOARD_VALID_USER': {
            return `/api/usp/OBCheckAccountbyServicedesk?Customer=${items.account}&Servicedesk=${items.site}`;
        }
        case 'UPDATE_REOPEN_REQUEST': {
            return `/api/usp/OnBoardAccountReOpen`;
        }
        case 'UPDATE_DELETE_ACCOUNT': {
            return `/api/usp/OnBoardDeleteAccount`;
        }
        default: { }
    }

}   