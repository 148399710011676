import API from "../api";

let token = localStorage.getItem("token");
export const getScoreCardDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getScoreCardPerformanceDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getScoreCardOffCanvasDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAgentsKPISummaryDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getTeamleaderAgentsDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getTeamleaderAccountsDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getTeamleaderAccountsViewDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getTeamleaderDatesDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAgentsKPIAccountsDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getTeamKPISummaryDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAgentsGraphDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};