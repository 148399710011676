import React from 'react'
import { Accordion } from 'react-bootstrap';
import user from './../../../assets/github-images/user-db.png';
import Organizational from './../../../assets/github-images/Organizational.png';
import RBAC from './../../../assets/github-images/RBAC.png';
import Measure from './../../../assets/github-images/Measure.png';
import OLAP from './../../../assets/github-images/OLAP.png';
import VerticalLine from './../../../assets/images/VerticalLine_Headings.png';
import { useHistory } from 'react-router-dom';
import { Footer } from '../HeaderFooter';

const List = [
    { id: '1', text: 'If not otherwise noted all text are nvarcar (240)' },
    { id: '2', text: 'All primary keys are integers (int)' },
    { id: '3', text: 'If we need GUID it will be added as a new column' },
    { id: '4', text: 'No complex key in use' },
    { id: '5', text: 'If we need complex key (or -ish) it will be a unique index' },
    { id: '6', text: 'Matrix type tables are denormalized (UserMembership, WidgetPreference, GenericDim, and any further dimension tables)' }
]
const Database = () => {
    const history = useHistory();
    return (
        <>
        <section style={{ marginTop: 0, marginLeft: -100 }}>
        <div className='breadcrumb_dev'>
            <section>
                <span style={{ color: '#5F249F', cursor: 'pointer' }} onClick={() => history.push('/githublanding')}>Home</span>
                <span style={{ color: '#5F249F', cursor: 'pointer' }} onClick={() => history.push('/design')} >{` / `} Design</span>
                <span style={{ color: '#6c757d', cursor: 'pointer' }} >{` / `} Database Design</span>
            </section>
           
        </div>
   
            <main className='accordion_handle' >
                <div style={{ width: 1200, margin: 'auto' }}>
                    <div className='Infrastructure'>Database Design</div>
                    <section className='maingoals_data'>
                        <main className='policy_sql'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Main goals</main>
                        <div style={{marginLeft:30}}>
                        <div>The ultimate goal is to have a normalised database for analytical purpose. It includes data tables for user management, organisational,analytical processing, role-based access control, user preferences and the session data.</div>
                        <main><div className='design_list_head'>Current State</div>The initial set of tables, database diagrams, and relations between them have been created.</main>
                        <main><div className='design_list_head'>Principles</div>The structure was designed with the aim to fulfil the requirement of avoiding the redundancy especially applicable for the text type objects.</main>
                        <main><div className='design_list_head'>Constrains</div>
                            <div>
                                <ol className='design_ol'>{List.map((list, index) => (<li>{list.text}</li>))}</ol>
                            </div>
                        </main></div>
                        <main className='policy_sql'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Diagrams</main>
                        <div className='Diagrams_data'>The database plan is too large to fit in one page, it is subdivided into multiple functional areas:</div>
                    </section>
                    <div style={{ width: '98%' }}>
                        <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                            <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                                <Accordion.Header style={{
                                    width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                    boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                                }}>
                                    <span className='accordion-header'>Users</span></Accordion.Header>
                                <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                    <>
                                        <img src={user} alt='' />
                                    </>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                                <Accordion.Header style={{
                                    width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                    boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                                }}>
                                    <span className='accordion-header'>Organizational</span></Accordion.Header>
                                <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                    <>
                                        <img src={Organizational} alt='' />
                                    </>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={2} style={{ marginTop: '0.3em' }}  >
                                <Accordion.Header style={{
                                    width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                    boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                                }}>
                                    <span className='accordion-header'>RBAC</span></Accordion.Header>
                                <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                    <>
                                        <img src={RBAC} alt='' />
                                    </>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={3} style={{ marginTop: '0.3em' }}  >
                                <Accordion.Header style={{
                                    width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                    boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                                }}>
                                    <span className='accordion-header'>Measures</span></Accordion.Header>
                                <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                    <>
                                        <img src={Measure} alt='' />
                                    </>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={4} style={{ marginTop: '0.3em' }}  >
                                <Accordion.Header style={{
                                    width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                    boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                                }}>
                                    <span className='accordion-header'>OLAP</span></Accordion.Header>
                                <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                    <>
                                        <img src={OLAP} alt='' />
                                    </>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div></div>
            </main>
            <Footer/> </section>
           
        </>
    )
}

export default Database