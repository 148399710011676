import React from 'react';
import Download_Normal from './../../../assets/images/Download_Icon.png';
import Close_NormalState from './../../../assets/images/CloseWindow_Icon.png';
import './Solution.css'
import * as htmlToImage from 'html-to-image';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';


function ArchitectCards(props) {
    const history = useHistory();
    let displayInfo = useSelector((state) => state.welcomePageReducer.githubCarouselData);
    const [isActive, setIsActive] = useState(false)
    console.log(isActive)
    const exportAsPicture = (image) => {
        htmlToImage.toPng(document.getElementById('whatever'), {
            quality: 0.90,
        })
            .then(function (dataUrl) {
                let link = document.createElement('a');
                link.download = image;
                link.href = dataUrl;
                link.click()
            })
            .catch(function (error) {
                console.error('oops, something went wrong!', error);
            });
    }
    return (
        <>

            <section className='card_container'>
                <header className='download_div'>
                  
                   <div> &nbsp; &nbsp;{displayInfo.title}</div>
                    <div><img src={Download_Normal} alt='' style={{ cursor: 'pointer' }} onClick={() => exportAsPicture(displayInfo.title.split(' ').join('_'))} />
                        &nbsp;  &nbsp;  &nbsp;  &nbsp;  <img src={Close_NormalState} alt='' style={{ cursor: 'pointer' }} onClick={() => history.push("/architecture")} /></div>
                 
                </header>
                <section style={{margin:'auto',width:'1160px'}}>
                    <main className='card_image' id='whatever'>
                        <div style={{ margin: 'auto', marginLeft: 20 }}>
                            
                                
                                   <img src={displayInfo.display} alt='' id='card_image' width={!isActive ? displayInfo.width : displayInfo.imgWidth} height={!isActive ? displayInfo.height : displayInfo.imgHeight}
                                    onClick={()=>setIsActive(!isActive)}  marginLeft={isActive ? '-100px' :'auto'} className={!isActive ? 'margin_auto' :'margin_100' }/>
                                   
                               

                        </div></main>
                </section>
            </section>
        </>
    )
}

export default ArchitectCards