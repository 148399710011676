import React, { useEffect, useRef } from 'react'
import './Request.css';
import { useState } from 'react';
import ReactModal from "react-modal";
import { OnboardSelect } from '../Onboard/OnboardComponents/Dropdown/Select';
import deleteIcon from '../../assets/images/Delete_Icon.png';
import DATA from './DATA.json'
import RequestDropdown from '../Onboard/OnboardComponents/Dropdown/RequestDropdown';
import DatePickerHandle from '../Onboard/OnboardComponents/KeyboardDatePicker';
import { useDispatch } from 'react-redux';


ReactModal.setAppElement('#root')
export function AdminRequestPopup({ isOpen, setShowModal, children }) {
    return (<>
        <div >
            <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={false}
                onRequestClose={() => setShowModal(false)}
                portalClassName='backdrop'
                style={{
                    overlay: {
                        position: 'fixed',
                        top: '10em',
                        left: '20em',
                        width: '680px',
                        height: '500px',
                        background: ' #FFFFFF 0% 0% no-repeat padding-box',
                        boxShadow: ' 6px 6px 45px #00000066',
                        border: ' 1px solid #ECEEF1',
                        opacity: '1',
                    },
                    content: {
                        // position: 'absolute',
                        top: '0',
                        left: '-22em',
                        right: '40px',
                        bottom: '40px',
                        border: 'none',
                        background: 'none',
                        overflow: 'none',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '4px',
                        outline: 'none',
                        padding: '2em'
                    }
                }}>
                {children}
            </ReactModal>
        </div>
    </>)
}

export function AdminRequestModel(props) {
    const dispatch = useDispatch();
    const [file, setFile] = useState("");
    const [error, setError] = useState();
    const [datacard, setDatacard] = useState(false);
    const [ValidUntil, setValidUntil] = useState(null);
    function handleUpload(event) {
        event.preventDefault();
        const file = event.target.files[0];
        if (file && file.size > 1000000) {
            setError('Please upload a file less than 1MB');
            setFile('');
            setDatacard(false);
        } else if (file) {
            setFile(file);
            setError('');
            setDatacard(true);
        }
    }

    function generateRandomId() {
        return 'file-' + Math.random().toString(36).substr(2, 9);
    }

    const hiddenFileInput = useRef(null);
    function deleteHandler() {
        setFile('');
        setDatacard(false);
        props.DeleteUploadedFile()
        hiddenFileInput.current.value = null;
    }


    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    console.log('mmmmmmmmmmmmmmmmmmmm', props.EditRequestForm)

    useEffect(() => {
        if (props.editMode) {
            setValidUntil(props.EditRequestForm?.['ValidUntil'])
        }
    }, [dispatch, props.EditRequestForm, props.editMode])

    const ValidToDateHandler = (date) => {
        console.log('date:::::', date)
        props.addValueToQueue('ValidUntil', (date === null || date === undefined) ? null : formatDate(date));
        setValidUntil((date === null || date === undefined) ? null : formatDate(date));

    }

    useEffect(() => {
        props.dispatchFileUpload(file);
        props.dispatchFileName(file.name)
    }, [file, props])

    return (
        <>
            <section className='new-template-container-request' >
                <div style={{ marginLeft: '3em' }}>
                    <div className='add_incident_text'>{props.EditRequestForm.title}</div>
                    <div style={{ color: '#ED4F32' }}>{props.validation}</div>
                    <form onSubmit={props.handleRequestFormSubmit}>
                        <section className='scrollable_Section_request'>
                            <main style={{ display: 'flex', gap: '30px' }}>
                                <div className='container-one' style={{ width: '45%' }}>
                                    <label HtmlFor="asignee" >Assignee *</label>
                                    <OnboardSelect data={DATA.AssigneeMail} name='assignee' value='value' id='id'
                                        editValue={props.editMode ? props.EditRequestForm.assignee : ""} addValueToQueue={props.addValueToQueue}
                                    />
                                </div>
                                <div className='container-three' style={{ width: '25%' }}>
                                    <label HtmlFor='status'> Status *</label>
                                    <OnboardSelect data={DATA.AdminStatus} name='status' value='value' id='id'
                                        editValue={props.editMode ? props.EditRequestForm.status : ""} addValueToQueue={props.addValueToQueue}
                                    />
                                </div>
                            </main>
                            {(props.editMode && props.EditRequestForm.type === "Task") && <main className='input-container-three' style={{ width: 600 }}>
                                <div className='container-six'>
                                    <label>UserDetails*</label>
                                    <div className='status_Add'>{props.EditRequestForm.UserDetails}</div>
                                </div>
                            </main>}
                            <main style={{ display: 'flex', gap: '20px' }}>
                                {(props.editMode && props.EditRequestForm.type === "Task") && <main>
                                    <DatePickerHandle className='container-three' label='ValidUntil' id='label' width='260px'
                                        value={ValidUntil}
                                        onChange={(date) => ValidToDateHandler(date)}
                                    />
                                </main>}
                                <main style={{ marginBottom: 0 }}>
                                    <div className='container-one' style={{ width: '90%' }}>
                                        <label >Comments *</label>
                                        {props.editMode ?
                                            <input type='text'
                                                name='comments'
                                                value={props.EditRequestForm.comments}
                                                placeholder='Speed, Answer, Average'
                                                onChange={props.handleUserAddRequest}
                                            />
                                            : <input type='text'
                                                name='comments'
                                                placeholder='Speed, Answer, Average'
                                                onChange={props.handleUserAddRequest}
                                            />}
                                    </div>
                                </main>
                            </main>

                            <main style={{ marginTop: '0px' }}>
                                <div className='complete_preview'>Attach File</div>
                                <div className='role_preview'>You can upload only PNG, JPEG and SVG files. Maximum upload file size is 1MB.</div>
                                {/* <div id="upload-box">
                                    <form>
                                        <input type="file" id="file" name="myImage" accept="image/png, image/svg, image/jpeg" onChange={handleUpload} style={{ display: 'none' }} />
                                        <label htmlFor="file" style={{ cursor: 'pointer' }}>BROWSE your files</label>
                                    </form>
                                </div> */}
                                <section style={{ display: 'flex', gap: 20, marginTop: 20 }}>    <div id="upload-box">
                                    <form>
                                        <input type="file" id={generateRandomId()} ref={hiddenFileInput} name="myImage" accept="image/png, image/svg, image/jpeg" onChange={handleUpload} style={{ borderBottom: 0 }} />
                                    </form>
                                </div>
                                    <div> {file.name && <img src={deleteIcon} alt='del' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} />}</div></section>
                                <div style={{ color: 'red' }}>{error}</div>
                                {/* {datacard && <div className='card__upload'> <span>{file.name}</span>
                                    <span >{(file.size) / 1024} KB <img src={deleteIcon} alt='del' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} /></span>
                                </div>} */}
                            </main>
                        </section>
                        <main style={{ display: 'flex', marginLeft: '130px', gap: '30px', marginTop: '20px' }}>
                            <button type='submit-button' className='btn_adduser' >
                                {props.buttonOne}
                            </button>
                            <button className='btn_canceluser' onClick={props.handleEditRequestCancel}>
                                {props.buttonTwo}
                            </button>
                        </main>

                    </form>
                </div>
            </section>
        </>
    )
}


export const UserReuqestModal = (props) => {
    const dispatch = useDispatch();
    const [file, setFile] = useState("");
    const [error, setError] = useState();
    const [datacard, setDatacard] = useState(false);
    const [ValidUntil, setValidUntil] = useState(null);
    const editMode = props.editMode
    function handleUpload(event) {
        event.preventDefault();
        const file = event.target.files[0];
        if (file && file.size > 1000000) {
            setError('Please upload a file less than 1MB');
            setFile('');
            setDatacard(false);
        } else if (file) {
            setFile(file);
            setError('');
            setDatacard(true);
        }
    }

    function generateRandomId() {
        return 'file-' + Math.random().toString(36).substr(2, 9);
    }

    const hiddenFileInput = useRef(null);
    function deleteHandler() {
        setFile('');
        setDatacard(false);
        props.DeleteUploadedFile()
        hiddenFileInput.current.value = null;
    }

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    console.log('mmmmmmmmmmmmmmmmmmmm', props.EditRequestForm)

    useEffect(() => {
        if (editMode) {
            setValidUntil(props.EditRequestForm?.['ValidUntil'])
        }
    }, [dispatch, props.EditRequestForm, editMode])

    const ValidToDateHandler = (date) => {
        console.log('date:::::', date)
        props.addValueToQueue('ValidUntil', (date === null || date === undefined) ? null : formatDate(date));
        setValidUntil((date === null || date === undefined) ? null : formatDate(date));

    }
    useEffect(() => {
        props.dispatchFileUpload(file);
        props.dispatchFileName(file.name)
    }, [file, props])

    return (
        <>
            <section className='new-template-container'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add_incident_text'>{editMode ? 'Edit' : 'Add'} Request / Incident</div>
                    <div style={{ color: '#ED4F32' }}>{props.validation}</div>
                    <form onSubmit={props.handleRequestFormSubmit}>
                        <section className='scrollable_Section'>
                            <main className='input-container-three'>
                                <div className='container-one'>
                                    <label HtmlFor="role">Type *</label>
                                    <br />
                                    {editMode ? <div className='status_Add'>{props.EditRequestForm.type}</div> :
                                        <RequestDropdown data={DATA.types} name='type' value='value' id='value'
                                            Inputvalue={editMode ? props.EditRequestForm.type : 'Select'}
                                            addValueToQueue={props.addValueToQueue} refresh={''}
                                        />}
                                </div>
                                <div className='container-one'>
                                    <label HtmlFor='username'>Status *</label>
                                    <br />
                                    {!editMode ? <div className='status_Add'>New</div> :
                                        <RequestDropdown data={DATA.UserStatus} name='status' value='value' id='id'
                                            Inputvalue={editMode ? props.EditRequestForm.status : 'Select'}
                                            addValueToQueue={props.addValueToQueue} refresh={''}
                                        />
                                    }
                                </div>
                            </main>

                            <main className='input-container-six' style={{ width: '96%' }}>
                                <div className='container-six'>
                                    <label>Title*</label>
                                    {editMode ?
                                        <div className='status_Add'>{props.EditRequestForm.title}</div> :
                                        <input type='text'
                                            name='title'
                                            placeholder='Speed, Answer, Average'
                                            onChange={props.handleUserAddRequest}
                                        />}
                                </div>
                            </main>
                            {(editMode && props.EditRequestForm.type === "Task") && <main className='input-container-three'>
                                <div className='container-six'>
                                    <label>UserDetails*</label>
                                    <div className='status_Add'>{props.EditRequestForm.UserDetails}</div>
                                </div>
                            </main>}
                            <main style={{ display: 'flex', gap: '20px' }}>
                                {(editMode && props.EditRequestForm.type === "Task") && <main>
                                    <DatePickerHandle className='container-three' label='ValidUntil' id='label' width='260px'
                                        value={ValidUntil}
                                        onChange={(date) => ValidToDateHandler(date)}
                                    />
                                </main>}
                                <br />
                                <main className='input-container-six' style={{ width: '96%' }}>
                                    <div className='container-six '>
                                        <label>Comments*</label>
                                        {editMode ? <input type='text'
                                            name='comments'
                                            value={props.EditRequestForm.comments}
                                            placeholder='Speed, Answer, Average'
                                            onChange={props.handleUserAddRequest}
                                        /> :
                                            <input type='text'
                                                name='comments'
                                                placeholder='Speed, Answer, Average'
                                                onChange={props.handleUserAddRequest}
                                            />}
                                    </div>
                                </main>

                            </main>
                            <br />
                            <main>
                                <div className='complete_preview'>Attach File</div>
                                <div className='role_preview'>You can upload only PNG, JPEG and SVG files. Maximum upload file size is 1MB.</div>
                                {/* <div id="upload-box">
                                    <form>
                                        <input type="file" id="file" name="myImage" accept="image/png, image/svg, image/jpeg" onChange={handleUpload} style={{ display: 'none' }} />
                                        <label htmlFor="file" style={{ cursor: 'pointer' }}>BROWSE your files</label>
                                    </form>
                                </div> */}

                                {/* <div id="upload-box">
                                <input type="file" onChange={handleUpload} />
                                </div> */}

                                <section style={{ display: 'flex', gap: 20, marginTop: 20 }}>    <div id="upload-box">
                                    <form>
                                        <input type="file" id={generateRandomId()} ref={hiddenFileInput} name="myImage" accept="image/png, image/svg, image/jpeg" onChange={handleUpload} style={{ borderBottom: 0 }} />
                                    </form>
                                </div>
                                    <div> {file.name && <img src={deleteIcon} alt='del' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} />}</div></section>
                                <div style={{ color: 'red' }}>{error}</div>
                                {/* {datacard && <div className='card__upload'> <span>{file.name}</span>
                                    <span >{(file.size) / 1024} KB <img src={deleteIcon} alt='del' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} /></span>
                                </div>} */}
                            </main>
                        </section>
                        <main className='input-container-four extra-four fixed_footer_span' style={{ marginTop: '10px' }}>
                            <button type='submit' className='btn_adduser'>
                                {props.buttonOne}
                            </button>
                            <button className='btn_canceluser' onClick={props.handleRequestFormCancel}>
                               {props.buttonTwo} 
                            </button>
                        </main>
                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default UserReuqestModal