import React, { useEffect } from 'react';
import './EmployeeAssign.css';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { ActivateModelPopup, AdminCancelModalPopup, AdminCancelModel, UnsavedModel, UnsavedModelPopUp } from './AssignModel';
import { ActivationRequest } from './AssignModel';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableEmployees, getRoleCategory, getStaffAccounts, getSupervisorList, saveSupervisorMapping } from './../../../../actions/Administration/staffAdmin.action';
import Active_Indication_Icon from "../../../../assets/images/Active_Indication_Icon.png"
import Search_Icon from "../../../../assets/images/Search_Icon.png"
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';


const Header = [
    { caption: 'Employee ID', id: '1' },
    { caption: 'Employee Name', id: '2' },
    { caption: 'Short Name', id: '3' },
    { caption: 'Email', id: '4' },
    { caption: 'Hire Date', id: '5' },
    { caption: 'Status', id: '6' },
]
function AvailableEmployeesHandle(props) {

    const dispatch = useDispatch();
    const history = useHistory();
    const availableEmployees = useSelector((state) => state.staffAdminReducer.availableEmployees);
    const supervisorList = useSelector((state) => state.staffAdminReducer.supervisorList);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getAvailableEmployees(''));
        dispatch(getSupervisorList());
        dispatch(getRoleBasedView())
    }, [dispatch])
    // const [showRequestForm, setShowRequestForm] = useState(false);
    // const [supervisorValidation, setSupervisorValidation] = useState(false)
    // const [selectedRow, setSelectedRow] = useState({})
    const onClickSupervisorAssign = (info, i) => {
        console.log(info);
        let temp_arr = {
            EmployeeName: info?.['Employee Name'],
            EmployeeID: info?.['Employee ID'],
            Email: info?.['Email'],
            Supervisor:supervisorList?.[0]?.Supervisor,
            SupervisorID:supervisorList?.[0]?.['Employee ID'],
            HireDate:info?.['Hire Date'],
            ValidFrom:info?.ValidFrom,
            ValidTo:info?.ValidTo,
            NewExisting:info?.NewExisting,
            view:'create'
        }
        // setSelectedRow(temp_arr);
        dispatch(saveSupervisorMapping(temp_arr));
        history.push('/admin/staff/assign');
        // setShowRequestForm(true)
    }

    // const saveSupervisorToList = (name, value, id) => {
    //     let temp = { ...selectedRow };
    //     temp.Supervisor = value;
    //     temp.SupervisorID = id;
    //     setSelectedRow(temp)
    // }
    // const handleSuperVisiorMapping = () => {
    //     console.log(selectedRow)
    //     if(!selectedRow.Supervisor){
    //         setShowRequestForm(true);
    //         setSupervisorValidation(true);
    //     }
    //     else{
    //     dispatch(saveSupervisorMapping(selectedRow));
    //     history.push('/admin/staff/assign');
    //     setSupervisorValidation(false);
    //     }
    // }
    const [filter, setFilter] = useState('');
    const onChangeSearchHandle=e=>{
        let query = e.target.value;
        setFilter(query);
        dispatch(getAvailableEmployees(query));
    }
    return (<>
        <section className='employee_mapped'>
            <header style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='header_total' > Total: {availableEmployees?.length}</div>
                <div className='search-bar'>
                    <input className='search-input' value={filter || ''} onChange={onChangeSearchHandle} />
                    <button className='search-icon'><img src={Search_Icon} alt='' /> </button>
                </div>
            </header>
            <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                <thead>
                    <tr>
                        {Header.map(header => (
                            <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>))}
                        <th>{'  '}</th>
                    </tr>
                </thead>
                <tbody className='mapped_tbody'>
                    {availableEmployees.map((info, i) =>
                    (<tr key={i}>
                        <td  >{info?.['Employee ID']}</td>
                        <td >{info?.['Employee Name']}</td>
                        <td >{info?.['Short Name']}</td>
                        <td  >{info?.['Email']}</td>
                        <td  >{info?.['Hire Date']}</td>
                        <td ><span > <img src={Active_Indication_Icon} alt='' />&nbsp;&nbsp;Active</span></td>
                        {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1 ? <td onClick={() => onClickSupervisorAssign(info, i)}><span className='assign_to_me' > ASSIGN TO ME</span></td> : ''}
                    </tr>)
                    )}
                </tbody>
            </table>
            <br />  <br /><br />
        </section>
        {/* {showRequestForm && <ActivateModelPopup isOpen={setShowRequestForm} setShowModal={setShowRequestForm} style={{ height: '300px' }}>
            <ActivationRequest modalName='Delete Role' buttonOne='ADD MAPPING' buttonTwo='CANCEL' selectedRow={selectedRow}
                handleDeleteQueue={handleSuperVisiorMapping} SupervisorData={supervisorList}
                handleDeleteQueueCancel={() => setShowRequestForm(false)}
                addValueToRole={saveSupervisorToList} supervisorValidation={supervisorValidation}
            />
        </ActivateModelPopup>
        } */}

    </>)
}


export default AvailableEmployeesHandle




