import React from 'react'
import { Accordion } from 'react-bootstrap'
import './DocumentationStandards.css';
import VerticalLine from './../../../../assets/images/VerticalLine_Headings.png';
import DocumentationStandards_commits from './../../../../assets/github-images/DocumentationStandards_commits.png';
import CopyToClipboard from './../../../../assets/github-images/CopyToClipboard.png';
import ToolTip from 'react-power-tooltip';
import { useState } from 'react';


const DOC_LOCATION = [
    { id: '1', caption: "Document" },
    { id: '2', caption: "Description" }
]
const DOC_LOCATION_DATA = [
    { id: '1', Document: 'How to Create/Update Articles in github',
     Description: 'A documented process for the contribution of documentation to this github repository',
      href:'https://github.dxc.com/pages/Innovation-Automation/innovation_team/standards/documentation/create_wiki_article/'
    }
]
const ARTICLE = [
    { id: '1', caption: "Other Articles" },
    { id: '2', caption: "Description" }
]
const ARTICLE_DATA = [
    { id: '1', OtherArticles: 'mkdocs-awesome-pages-plugin',
     Description: 'This module is used to control the layout of your pages (using .pages files) located in your repo',
    href:'https://github.com/lukasgeiter/mkdocs-awesome-pages-plugin' },
    { id: '2', OtherArticles: 'docs-mkdocs-template',
     Description: 'This is the initial instructions that have been used to originally configure your github repo and to link all required automation to build github pages via the jenkins pipeline',
    href:'https://github.dxc.com/Platform-DXC/docs-mkdocs-template#setup'
    }
]

const GITHUB='https://github.dxc.com/MWSGSD/GSD-Insight';

const arrange_list = [
    { id: '1', caption: "- Team" },
    { id: '2', caption: "- enhancement_questionaire.md" },
    { id: '3', caption: "- product_release_notes.md" },
    { id: '4', caption: "- Product_status_reports" },
    { id: '5', caption: "- roadmaps" }
]
const DocumentationStandards = () => {
    const [showArrangeTooltip,setShowArrangeTooltip] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [showArchiveTooltip, setShowArchiveTooltip] = useState(false);
    const [tooltipText, setTooltipText] = useState(false);
    function copyElementToClipboard(element) {
        window.getSelection().removeAllRanges();
        let range = document.createRange();
        range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
      }

      
    return (
        <>
            <main className='accordion_handle' >
            <div style={{width:1200,margin:'auto'}}>
                <div className='Infrastructure'>Documentation Standards</div>
                <div style={{ width: '98%'}}>
                    <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                        <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Documentation Location</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <div className='doc_title'>Some standards are located in alternate github locations. Please refer:</div>
                                    <main>  <table className='doc_table' style={{ width: '96%' }}>
                                        <thead><tr>
                                            {DOC_LOCATION.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='doc_header'>{header.caption}</span></th>))}
                                        </tr></thead>
                                        <tbody>
                                            {DOC_LOCATION_DATA.map((data, index) => (<tr key={data.id}>
                                                <td className='doc_td_link' ><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={data.href} title=''>{data.Document}</a></td>
                                                <td className='doc_td'>{data?.Description}</td></tr>))} </tbody>
                                    </table></main>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>GitHub Pages</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                    <section className='github_acc_data'>
                                        <div className='doc_title'>Our documentation, whilst created in markdown and stored in github is linked to an automated jenkins pipeline - and is typically presented to our users via github pages. This process doesn’t necessarily need to be understood in great detail - as this process is fully automated - but there are a few items that you should be aware of:</div>
                                        <main>  <table className='doc_table' style={{ width: '96%' }}>
                                            <thead><tr>
                                                {ARTICLE.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='doc_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {ARTICLE_DATA.map((data, index) => (<tr key={data.id}>
                                                    <td className='doc_td_link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={data.href} title=''>{data?.OtherArticles}</a></td>
                                                    <td className='doc_td'>{data?.Description}</td></tr>))} </tbody>
                                        </table></main>
                                        <main className='standard_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Overview</main>
                                        <div>Our documentation, whilst created in markdown and stored in github is linked to an automated jenkins pipeline - and is typically presented to our users via github pages. This process doesn’t necessarily need to be understood in great detail - as this process is fully automated - but there are a few items that you should be aware of:</div>
                                        <main className='standard_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; .pages files</main>
                                        <div>Sometimes you may want to change the behavior of github pages and change the order of articles/folders, hide articles/folders or change the name of the menus. This is done via .pages files that are placed strategically in the folder structure within the repository.</div>
                                        <div className='margin_adj'>A .pages file - may contain something like:</div>
                                        <main className='copy_list_ul' style={{ display: 'flex' }}>
                                           <main id='productManagementList'><div>title: Product Management</div>
                                           <div>arrange:</div>
                                           <ul>{arrange_list.map((data, index) => (<li className='arrange_list'>{data.caption}</li>))}</ul>
                                           </main>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('productManagementList');setTooltipText('Copied')}} onMouseOver={() => {setShowArrangeTooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowArrangeTooltip(false)}/>
                                            <ToolTip show={showArrangeTooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                        </main>
                                        <div className='margin_adj'>Note: This is an awesome-pages file in YAML format. Therefore you must follow the YAML rules relating to spaces (not tabs).</div>
                                        <div className='margin_adj'>The <b>title: Product Management</b> instructs for this page to be known as “Product Management” and NOT by its folder name “product_management”. Hopefully the reason why you would want this is obvious. </div>
                                        <div className='margin_adj'>The <b>arrange:</b> section allows both folders and markdown files to be ordered to your liking.</div>
                                        <div className='margin_adj'>If you want article1.md to come first followed by article2.md - and then everything else present to come after that - use … as below</div>
                                        <main className='copy_list_ul' style={{ display: 'flex' }}>
                                           <main id='ArrangeArticle'><div>title: Example Section</div>
                                           <div>arrange:</div>
                                           <ul><li className='arrange_list'> - article1.md</li><li className='arrange_list'> - article2.md</li><li className='arrange_list'> - ...</li></ul>
                                           </main>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('ArrangeArticle');setTooltipText('Copied')}} onMouseOver={() => {setShowTooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowTooltip(false)}/>
                                            <ToolTip show={showTooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                        </main>
                                        <div className='margin_adj'>If you have a folder - that you would like to keep in the repo - but do NOT want it shown in the menu structure In the archive folder - create a .pages file and include</div>
                                        <main className='copy_list_ul' style={{ display: 'flex' }}>
                                           <main id='ArchiveTitle'><div>title: Archive</div>
                                           <div>hide: true</div>
                                           </main>
                                            <div style={{position:'relative',cursor:'pointer'}}><img src={CopyToClipboard} alt='' width='20px' onClick={()=>{copyElementToClipboard('ArchiveTitle');setTooltipText('Copied')}} onMouseOver={() => {setShowArchiveTooltip(true);setTooltipText('Copy')}} onMouseLeave={() => setShowArchiveTooltip(false)}/>
                                            <ToolTip show={showArchiveTooltip} textAlign="center" arrowAlign="center"  position="top center"><span >{tooltipText}</span></ToolTip></div>
                                            
                                        </main>
                                        <main className='standard_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Checking Progress and Status of Jenkins Pipeline</main>
                                        <div className='margin_adj'>After a successful github merge request - you can verify the progress of publishing github pages by clicking on the <b>Commits:</b></div>
                                        <main><b>Step1</b><div className='step1_link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={GITHUB} title=''>https://github.dxc.com/MWSGSD/GSD-Insight</a></div>
                                            <img src={DocumentationStandards_commits} alt='' style={{ marginTop: 20, marginBottom: 20 }} />
                                        </main>
                                        <main><b>Step2</b><div>Click on the green / or / red sign near to the release and select <b>Details</b> <br />It may well be that your pipeline is still running - or perhaps it has failed (you will see a red cross). <br />A successful deploy - should result in a successful publish step:</div>
                                        </main>
                                        <main className='standard_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Troubleshooting</main>
                                        <div>One thing to be careful about is the contents of your .pages must be syntactically correct AND valid. If you reference files/foldersthat do NOT exist - you will break the publishing process. Easiest way to check this is to follow the instructions above to see your “failed” publish <br /><br />If something else causing problems - please read carefully the error message - if needed ask for help! :)</div>

                                    </section>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div></div>
            </main>

        </>
    )
}

export default DocumentationStandards