import React, { useState } from 'react';
import './VoiceHandler.css'
import OffCanvasHandler from '../OnboardComponents/OffCanvas/OffCanvas';
import AccordionHandler from '../OnboardComponents/Accordion/Accordion';
import QueueComponent from '../OnboardComponents/QueueTable/QueueComponent';
import SLAComponent from '../OnboardComponents/SLAComponents/SLAComponent';
import SupportHoursMain from '../OnboardComponents/SupportHoursComponent/SupportHoursMain';


function VoiceHandler(props) {
  const ACCORDION_INFO = [
    {
      id: 1,
      label: 'Queues',
      Info: <QueueComponent GeneralInfoToggle={props.GeneralInfoToggle}
        DispatchQueuehandler={props.DispatchQueuehandler}
        accountDetails={props.accountDetails}
        QueueTableData={props.QueueTableData} />
    },
    {
      id: 2,
      label: 'SLA/KPIs',
      Info: <SLAComponent GeneralInfoToggle={props.GeneralInfoToggle}
        dispatchAccountHandler={props.dispatchAccountHandler}
        TabName='Voice'
        voiceSLAData={props.voiceSLAData}
        accountDetails={props.accountDetails}
        NonVoiceItem='AWS (ASD) Telephony' />

    },
    {
      id: 3,
      label: 'Support Hours',
      Info: <SupportHoursMain GeneralInfoToggle={props.GeneralInfoToggle}
        dispatchSupportHandler={props.dispatchSupportHandler}
        TabName='Voice'
        supportHoursVoice={props.supportHoursVoice}
        accountDetails={props.accountDetails}
        NonVoiceItem='AWS (ASD) Telephony'
      />
    },
  ]

  return (<>
    <section className='voice-container'>
    <div className='dataSave_div_voice'>During the Account Onboarding and Administration process populate only Voice and Non-Voice channels that are applicable for selected account and site. Non-applicable channels can be left blank.</div>
      <main className='main-wrapper'>  <div className='aws-div'>
        <span className='aws-span'>AWS (ASD) Telephony</span>
      </div>
        <OffCanvasHandler />
      </main>
      <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />
      {props.children}
    </section>

  </>);
}

export default VoiceHandler;

