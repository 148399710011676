import React, { useEffect, useState } from 'react';
import "./GeneralInfo.css";
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import InformationIcon from "./../../../assets/images/Information_Icon.png";
import dataSaveClose_icon from "./../../../assets/onboard-images/dataSaveClose_icon.png";
import ToolTip from 'react-power-tooltip';
import { OnboardSelect } from '../OnboardComponents/Dropdown/Select';
import { getAccountSite, getGeneralInfoSites, getOnboardValidUser, saveAccountSite, setValidforAddingTabRows, updateOnboardToggleStart } from '../../../actions/Onboard.action'
import DatePickerHandle from '../OnboardComponents/KeyboardDatePicker';
import moment from 'moment'
import { HintsGeneralInfo } from '../OnboardComponents/OffCanvas/HintsRoles';

const GeneralInfo = (props) => {
  const dispatch = useDispatch();
  const generalSiteInfo = useSelector((state) => state.onboardReducer.generalSiteInfo);
  const onboardValidUser = useSelector((state) => state.onboardReducer.onboardValidUser);

  useEffect(() => {
    dispatch(getAccountSite());
    dispatch(getGeneralInfoSites());
  }, [dispatch])
  const [showTooltip, setShowTooltip] = useState(false);
  const [notEditable,setNotEditable] =useState(false);
  const [notEditableData,setNotEditableData] = useState();
  const [accountSiteData, setAccountSiteData] = useState({
    AccountName: '', ValidFrom: '', ValidTo: '', SiteName: ''
  })
  const [validation, setValidation] = useState(false);
  useEffect(() => {
    let temp_Arr = {
      AccountName: props.accountDetails.AccountName,
      SiteName: props.accountDetails.SiteName,
      ValidFrom: props.accountDetails.ValidFrom,
      ValidTo: props.accountDetails.ValidTo,
    };
    setAccountSiteData(temp_Arr);
    dispatch(saveAccountSite(temp_Arr));
  }, [])
  const [toggleValue, setToggleValue] = useState(props.accountDetails?.mode === 'edit' ? true : false);
  const handleAccountName = (event) => {
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...accountSiteData };
    newFormData[fieldName] = fieldValue;
    setAccountSiteData(newFormData);
    setToggleValue(false);
  }

  const [editSiteValue, setEditSiteValue] = useState('');
  const SiteNameValueHandler = (name, value) => {
    const newFormData = { ...accountSiteData };
    newFormData[name] = value;
    setAccountSiteData(newFormData);
    dispatch(saveAccountSite(newFormData));
    if (name === 'SiteName') {
      setEditSiteValue(value)
    };
    setToggleValue(false);
  }
  const [ValidFrom, setValidFrom] = useState(new Date(''));
  const [ValidTo, setValidTo] = useState(new Date(''));
  const [showInfobar, setShowInfobar] = useState(false);
  const [showSavedbar,setShowSavedbar] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [month, day, year].join('/');
  }
  const ValidFromDateHandler = (date) => {
    SiteNameValueHandler('ValidFrom', formatDate(date))
    setValidFrom(formatDate(date));
  }
  const ValidToDateHandler = (date) => {
    SiteNameValueHandler('ValidTo', formatDate(date))
    setValidTo(formatDate(date));
  }
  const onChangeGeneraltoggle = (event) => {
    const checked = event.target.checked;
    props.generalToggleChange(checked);
    dispatch(getOnboardValidUser(accountSiteData.AccountName, accountSiteData.SiteName))
    const temp_Arr = [{
      Account: accountSiteData.AccountName,
      Servicedesk: accountSiteData.SiteName,
      ValidFrom: accountSiteData.ValidFrom,
      ValidTo: accountSiteData.ValidTo,
    }];
    const isEmptyInput = Object.values(accountSiteData).some(x => (x === null || x === '' || x === undefined || x === 'Invalid Date'));
    const isValidDate = moment(accountSiteData.ValidTo).isAfter(accountSiteData.ValidFrom)
    let isAccountExist = onboardValidUser?.[0]?.column1 === 'Invalid User'
    console.log(onboardValidUser?.[0]?.column1, accountSiteData.AccountName, accountSiteData.SiteName)
    if (isEmptyInput) {
      setShowInfobar(true);
      setValidation('Please Ensure you filled all the above input to onboard an Account ');
      setConfirmation('');
      setShowSavedbar(false)
    }
    else if (!isValidDate) {
      setShowInfobar(true);
      setValidation('Please ensure  "Valid To" date is placed after "Valid From" date');
      setConfirmation('');
      setShowSavedbar(false)
    }
    else if (isAccountExist) {
      setShowInfobar(true);
      setValidation(`${accountSiteData.AccountName} account already exists with ${accountSiteData.SiteName} location`)
      setConfirmation('');
      setShowSavedbar(false)
    }
    if (!isEmptyInput && isValidDate && !isAccountExist) {
      setValidation('')
      dispatch(updateOnboardToggleStart(temp_Arr))
      setShowInfobar(false)
      setNotEditable(true);
      setNotEditableData(temp_Arr)
      dispatch(setValidforAddingTabRows(false));
      setConfirmation('Initial save has been completed.');
      setShowSavedbar(true)
      setToggleValue(true)
    }
  }
console.log('notEditableData',notEditableData?.[0]?.Account)
  useEffect(() => {
    props.generalToggleChange(toggleValue);
  }, [toggleValue,props])
  return (
    <>

      <div className='card_general'>
        <Row>
          <Col style={{ flex: 13 }}>
            <div className='gen_info'>General Informations</div>
          </Col>
          <Col style={{ flex: 1 }}><HintsGeneralInfo /></Col>
        </Row>
        <div className='on_info'>This page enables to add new accounts.</div>
        <div className='on_info'> For more guidance, click on the Hints link on the right.</div>
        <div className='selection_box'>
          <div>
            <div className='site'>
              <label> Account Name</label></div>
            {props.accountDetails.AccountName === null || props.accountDetails.AccountName === undefined ?
              <div>
               {notEditable === true ? <div className='nonEditableData'><b>{notEditableData?.[0]?.Account}</b></div> : <input className='input_on' type='text' style={{ borderBottom: '1px solid #969696', marginTop: '-4px' }}
                  name='AccountName'
                  required='required'
                  placeholder='Example: Audi'
                  onChange={handleAccountName}
                />}
              </div> :
              <div className='nonEditableData'>
                <b>  {props.accountDetails?.AccountName} </b>
              </div>}
          </div>
          <div>
            <div className='container-one_general '>
              <label HtmlFor="lang" >Site Name</label>
              {props.accountDetails.SiteName === null || props.accountDetails.SiteName === undefined ?
                <div style={{ width: '90%', marginTop: 5 }}>
                 {notEditable === true ? <div className='nonEditableData'><b>{notEditableData?.[0]?.Servicedesk}</b></div> : <OnboardSelect data={generalSiteInfo} name='SiteName' value='SiteName' id='SiteId'
                    QueryHandler={() => { }}
                    inputvalue={'Select'} editValue={editSiteValue} OnClickSelect={() => { }}
                    addValueToQueue={SiteNameValueHandler} />}
                </div> :
                <div className='nonEditableData'>
                  <b>  {props.accountDetails?.SiteName} </b>
                </div>}
            </div>
          </div>


          <div>
            {props.accountDetails.mode !== 'view' ? <DatePickerHandle className='container-three' label='Valid From *' id='label'
              value={accountSiteData.ValidFrom}
              onChange={(date) => ValidFromDateHandler(date)}
            />
              :
              <><div className='site'>
                <label> Valid From</label></div>
                <div>
                  <b>  {props.accountDetails?.ValidFrom} </b>
                </div>
              </>
            }

          </div>
          <div>
            {/* {props.accountDetails.ValidTo === null || props.accountDetails.ValidTo === undefined ? */}
            {props.accountDetails.mode !== 'view' ? <DatePickerHandle className='container-three' id='label'
              label='Valid To *'
              value={accountSiteData.ValidTo}
              onChange={(date) => ValidToDateHandler(date)}
            />
              :
              <><div className='site'>
                <label> Valid To</label></div>
                <div>
                  <b>  {props.accountDetails?.ValidTo} </b>
                </div>
              </>}
          </div>

          {props.accountDetails.mode !== 'view' && <div>
            <div className='site' style={{ marginLeft: '20px' }}>Initial Save</div>
            <div style={{ display: 'flex' }}> <span >
              <label class="toggle" style={{ marginLeft: '30%' }}>
                <input type="checkbox"
                  name='accountToggle'
                  checked={ toggleValue }
                  onChange={onChangeGeneraltoggle}
                />
                <div class="slider_on"></div>
                <span class="labels" data-on="YES" data-off="NO"></span>
              </label>
            </span>&nbsp; &nbsp;
              <span style={{ position: "relative", cursor: 'pointer' }}><img src={InformationIcon} alt='none' className='i_img'
                onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}  ></img>
                <ToolTip show={showTooltip} textAlign="left" arrowAlign="end" moveRight='33px'
                  position="bottom right" textBoxWidth="600px" borderRadius='0px' fontSize='14/19' color='#1D2833' padding='15px' hoverBackground=''>
                  <span >By marking ‘Initial Save’ to YES, you perform the mandatory save of the account record. Once YES is selected, Account name and Site selection become non-editable but you are able to delete the record from your Onboard List (applicable only for records with ‘Saved’ status). Without marking ‘Initial Save’ with YES, the account record will be lost.</span>
                </ToolTip>
              </span>
            </div>
          </div>}
        </div>
      </div>
      {showInfobar && <div className='info_bar' style={{ width: '98%', backgroundColor: '#F0AD4E' }} >
        <div> &nbsp;&nbsp;{validation}</div>
        <div><img src={dataSaveClose_icon} alt='' onClick={() => setShowInfobar(false)} /></div>
      </div>}
      {showSavedbar && <div className='info_bar' style={{ width: '98%', backgroundColor: '#67B946' }} >
        <div style={{color:'white'}}> &nbsp;&nbsp;{confirmation}</div>
        <div><img src={dataSaveClose_icon} alt='' onClick={() => setShowSavedbar(false)} /></div>
      </div>}
    </>
  )
};
export default GeneralInfo
