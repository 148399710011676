import React, { useEffect, useState } from 'react';
import './AgentsTableComponent.css';
import filterIcon from '../../../assets/images/Filter_Icon.png';
import RefreshIcon from '../../../assets/images/Reset_Icon.png';
import Dropdown from '../../ReportTables/ReportFilters/Dropdown';
import Button from '../../ReportTables/ReportFilters/Button';
import TableData from './FilterTable.json';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentsKPISummaryData, getTeamleaderAccountsData, getTeamleaderAccountsViewData, getTeamleaderAgentsData, getTeamleaderDatesData, saveSelectedDate } from '../../../actions/ScoreCard&TeamKPI.action';
import { OnboardSelect } from '../../Onboard/OnboardComponents/Dropdown/Select';
import { AccountsOffcanvasTL } from '../../Agent-ScoreCard/AccountsOffcanvas';

const AgentsFilterComponent = (props) => {
  const dispatch = useDispatch();

  let teamleaderAgents = useSelector((state) => state.ScoreCardReducer.teamleaderAgents);
  let teamleaderAccounts = useSelector((state) => state.ScoreCardReducer.teamleaderAccounts);
  let teamleaderAccountsView = useSelector((state) => state.ScoreCardReducer.teamleaderAccountsView);
  let teamleaderDates = useSelector((state) => state.ScoreCardReducer.teamleaderDates);
  let selectedTab = useSelector((state) => state.ScoreCardReducer.selectedTab);
  console.log('agentsKPISummaryData', teamleaderAgents, teamleaderAccounts)
  console.log('teamleaderDates', teamleaderDates?.[0]?.YearMonth)
  useEffect(() => {
    dispatch(getTeamleaderAgentsData(''))
    dispatch(getTeamleaderAccountsViewData())
    dispatch(getTeamleaderDatesData());
  }, [dispatch])
  useEffect(()=>{
    if(teamleaderDates?.[0]?.YearMonth){
    dispatch(getAgentsKPISummaryData('', '', teamleaderDates?.[0]?.YearMonth, 'Schedule / Attendance'));
    }
  },[dispatch,teamleaderDates])

  const [yearMonth,setYearMonth] = useState('');

  useEffect(()=>{
setYearMonth(teamleaderDates?.[0]?.YearMonth)
  },[teamleaderDates])

  const addValueToDate = (name, value) => {
    if (value !== 'Select') {
        const newFormData = { ...yearMonth };
        newFormData[name] = value;
        setYearMonth(value)
        setIsRefreshedClicked(false)
    }
}
useEffect(()=>{
dispatch(saveSelectedDate(yearMonth))
},[dispatch,yearMonth])

  const [addFormRole, setAddFormRole] = useState([])
  useEffect(() => {
    if (teamleaderAgents?.length > 0) {
      teamleaderAgents.map(info => info.isChecked = false);
      setAddFormRole(teamleaderAgents);
    }
  }, [teamleaderAgents])

  const addValueToRole = (option, drpdwnID) => {
    let temp_arr = [...addFormRole];
    temp_arr.map((itm, i) => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return '';
    });
    setAddFormRole(temp_arr);
  }

  let selectedvalues = addFormRole.filter((el) => {
    return el.isChecked === true
  })

  let selectedArray = selectedvalues.map((el) => {
    return `${el.value}`
  }).join(' , ')

  let selectedAgents = selectedvalues.map((el) => {
    return `Agent[]=${el.value}`
  }).join('&')

  console.log('selectedvalues',selectedvalues.length)

  useEffect(() => {
    if (selectedAgents || yearMonth || selectedTab) {
      dispatch(getTeamleaderAccountsData(selectedAgents));
      dispatch(getAgentsKPISummaryData(selectedAgents, '', yearMonth, selectedTab));
    }
  }, [dispatch, selectedAgents,yearMonth,selectedTab])

   const [accountsData, setAccountsData] = useState([])
  useEffect(() => {
    if (teamleaderAccountsView?.length > 0) {
      teamleaderAccountsView.map(info => info.isChecked = false);
      setAccountsData(teamleaderAccountsView);
    }
  }, [teamleaderAccountsView])

  console.log('account',accountsData)

  const addValueToAccounts = (option, drpdwnID) => {
    let temp_arr = [...accountsData];
    temp_arr.map((itm, i) => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return '';
    });
    setAccountsData(temp_arr);
  }

  let selectedAccountvalues = accountsData.filter((el) => {
    return el.isChecked === true
  })

  let selectedAccountArray = selectedAccountvalues.map((el) => {
    return `${el.value}`
  }).join(' , ')


   let selectedAccounts = selectedAccountvalues.map((el) => {
    return `AccountID[]=${el.CustomerID}`
  }).join('&')

  // console.log('selectedAccountvalues',selectedAccounts)

  useEffect(() => {
    if (selectedAccounts) {
      dispatch(getTeamleaderAgentsData(selectedAccounts))
    }
  }, [dispatch,selectedAccounts])

  const clearfilterHandler = () => {
    let temp_arr = [...addFormRole];
    temp_arr.map((itm, i) => {
      itm.isChecked = false;
      return '';
    });
    setAddFormRole(temp_arr);
    dispatch(getTeamleaderAccountsData(''))
    dispatch(getAgentsKPISummaryData('', '', yearMonth, selectedTab));
  }

  const clearfilterHandlerAccounts = () =>{
    dispatch(getTeamleaderAccountsViewData())
    dispatch(getTeamleaderAgentsData(''));
    dispatch(getAgentsKPISummaryData('', '', yearMonth, selectedTab));
  }

  
  const [showOffCanvas,setShowOffCanvas] = useState(false);
  const [isRefreshClicked, setIsRefreshedClicked] = useState(false);
console.log('yearMonth',yearMonth)

const refreshIconHandler = () => {
  dispatch(getTeamleaderAccountsViewData())
  dispatch(getTeamleaderAgentsData(''))
  dispatch(getTeamleaderAccountsData(''))
  dispatch(getAgentsKPISummaryData('', '', yearMonth, selectedTab));
  setYearMonth(teamleaderDates?.[0]?.YearMonth);
  setIsRefreshedClicked(true)
};

  return (
    <>
      <div className='filter-tab'>
        <span className='filter-icon'>
          <img className='filter-image' src={filterIcon} alt="filter" />
        </span>
        <section className='filter-container'>
          <div className='dropdown_Width'>
            <p className='year-month'>Name</p>
            <Dropdown data={teamleaderAgents} id='ID'
              onSelectingDropdown={addValueToRole}
              clearFilters={props.clearFilters} latestUpdates={props.latestUpdates} />
          </div>
          <div className='dropdown_Width'>
            <p className='pl-region' style={{textAlign:selectedvalues.length === 0 ? 'left' : 'center'}}>Account Assigned</p>
           {selectedvalues.length === 0 ? <Dropdown data={teamleaderAccountsView} id='regionDrpdwn'
              onSelectingDropdown={addValueToAccounts}
              clearFilters={props.clearFilters} /> : <div onClick={()=>setShowOffCanvas(true)} className='Accounts_Details'>{teamleaderAccounts.length}</div>}
              <AccountsOffcanvasTL title='Roles Hints' style={{ textAlign: 'right' }} ScorCardOffCanvasData={teamleaderAccounts} handleClose={()=>setShowOffCanvas(false)} show={showOffCanvas} Title='Account Assignment'/>
          </div>
          <div className='container_YearMonth'>
            <label HtmlFor="YearMonth">Year & Month</label>
            <OnboardSelect data={teamleaderDates} name='YearMonth' value='YearMonth' id='RoleId'
              editValue='' addValueToQueue={addValueToDate} refresh={isRefreshClicked} />
          </div>

        </section>
        <div className='container-reset' >
          <Button className={`reset-icon download-btn `} item='Refresh Table'
            onClick={refreshIconHandler} icon={RefreshIcon} />
        </div>
      </div>

      {selectedvalues.length !== 0 ? <section className='selectedOpt_Tab'><div>{selectedArray}</div>
        <div className='btn_clearfilter' onClick={() => clearfilterHandler()}>Clear filters</div></section> : ''}

        {selectedAccountvalues.length !== 0 ? <section className='selectedOpt_Tab'><div>{selectedAccountArray}</div>
        {selectedvalues.length === 0 ?<div className='btn_clearfilter' onClick={() => clearfilterHandlerAccounts()}>Clear filters</div> : ''}</section> : ''}
    </>
  )
}

export default AgentsFilterComponent