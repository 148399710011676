import React, { useState } from 'react'
import ToolTip from 'react-power-tooltip';
import { useLocation } from 'react-router-dom';




function Widget(props) {

    const { widgetvalue, widgettooltip } = props;
    const location = useLocation();
    const [showTooltip, setShowTooltip] = useState(false)
    const [isActive, setIsActive] = useState(props.isselected)
    let className = isActive === true ? 'switch active' : 'switch ';
    return <>
        <section  >
            <div className='widget_list'>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div> <span className='widget_span' style={{ position: 'absolute' }}
                        onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>{widgetvalue}
                        {location.pathname === '/widget' && <ToolTip show={showTooltip} arrowAlign="start"
                            position="bottom left" textBoxWidth="389px" borderRadius='0px' moveDown='10px'
                            fontSize='14/19' color='#1D2833' fontWeight='bold'><span className='tooltip_text'>{widgettooltip}</span>
                        </ToolTip>}
                    </span></div>

                    <span className='toggle-container'  >
                        <label className={className}>
                            <input
                                value={props.widgetName}
                                type='checkbox'
                                checked={isActive}
                                onChange={() => {
                                    setIsActive(!isActive);
                                    props.togglehandler(!isActive)
                                }}
                                {...props}
                            />
                            <span className='slider' />
                        </label>
                    </span>

                </div>
            </div>
        </section>
    </>;
}
export default Widget;