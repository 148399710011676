export const welcomePage_actionTypes = {
    GET_ROLES_TITLE: "GET_ROLES_TITLE",
    ROLES_TITLE: "ROLES_TITLE",
    GET_WIDGET_INFO: "GET_WIDGET_INFO",
    WIDGET_INFO: "WIDGET_INFO",

    UPDATE_WIDGET_INFO_START: "UPDATE_WIDGET_INFO_START",
    UPDATE_WIDGET_INFO_SUCCESS: "UPDATE_WIDGET_INFO_SUCCESS",
    SET_SELECTED_ROLE: "SET_SELECTED_ROLE",


    GET_RECENT_LINKS: "GET_RECENT_LINKS",
    RECENT_LINKS: "RECENT_LINKS",

    UPDATE_WIDGET_PREFERENCE_SHOWN: "UPDATE_WIDGET_PREFERENCE_SHOWN",
    WIDGET_PREFERENCE_SHOWN: "WIDGET_PREFERENCE_SHOWN",

    GET_WIDGET_PREFERENCE: "GET_WIDGET_PREFERENCE",
    WIDGET_PREFERENCE: "WIDGET_PREFERENCE",

    GET_CAROUSEL_TITLE: "GET_CAROUSEL_TITLE",
    CAROUSEL_TITLE: "CAROUSEL_TITLE",

    SET_WIDGET_SELECTED: "SET_WIDGET_SELECTED",
    SET_SELECTED_ACCOUNTS: 'SET_SELECTED_ACCOUNTS',

    GET_MULTI_ACCOUNTS_WIDGETS: "GET_MULTI_ACCOUNTS_WIDGETS",
    MULTI_ACCOUNTS_WIDGETS: "MULTI_ACCOUNTS_WIDGETS",

    GET_MULTI_WIDGET_INFO: "GET_MULTI_WIDGET_INFO",
    MULTI_WIDGET_INFO: "MULTI_WIDGET_INFO",

    UPDATE_MULTI_WIDGET_START: "UPDATE_MULTI_WIDGET_START",
    UPDATE_MULTI_WIDGET_SUCCESS: "UPDATE_MULTI_WIDGET_SUCCESS",


    SAVE_SELECTED_ACCOUNTS: "SAVE_SELECTED_ACCOUNTS",
    SAVE_DROPDOWN_ACCOUNTS: "SAVE_DROPDOWN_ACCOUNTS",
    SAVE_SELECTED_CUSTID_SERVID: "SAVE_SELECTED_CUSTID_SERVID",
    SAVE_SELECTED_ACCOUNT_OBJ: "SAVE_SELECTED_ACCOUNT_OBJ",


    GET_ACCOUNT_SUMMARY: "GET_ACCOUNT_SUMMARY",
    ACCOUNT_SUMMARY: "ACCOUNT_SUMMARY",

    GET_ACCOUNT_DROPDOWN: "GET_ACCOUNT_DROPDOWN",
    ACCOUNT_DROPDOWN: "ACCOUNT_DROPDOWN",

    // POST
    CREATE_LATEST_RELEASES_START: "CREATE_LATEST_RELEASES_START",
    CREATE_LATEST_RELEASES_SUCCESS: "CREATE_LATEST_RELEASES_SUCCESS",

    // EDIT 
    UPDATE_LATEST_RELEASES_START: "UPDATE_LATEST_RELEASES_START",
    UPDATE_LATEST_RELEASES_SUCCESS: "UPDATE_LATEST_RELEASES_SUCCESS",

    // DELETE
    DELETE_LATEST_RELEASES_START: "DELETE_LATEST_RELEASES_START",
    DELETE_LATEST_RELEASES_SUCCESS: "DELETE_LATEST_RELEASES_SUCCESS",

    // GET
    GET_LATEST_RELEASES_START: "GET_LATEST_RELEASES_START",
    GET_LATEST_RELEASES_SUCCESS: "GET_LATEST_RELEASES_SUCCESS",



    GET_STORED_ACCOUNT: "GET_STORED_ACCOUNT",
    STORED_ACCOUNT: "STORED_ACCOUNT",

    UPDATE_STORED_ACCOUNT_START: "UPDATE_STORED_ACCOUNT_START",
    UPDATE_STORED_ACCOUNT_SUCCESS: "UPDATE_STORED_ACCOUNT_SUCCESS",


    GET_TASK_LINKS: "GET_TASK_LINKS",
    TASK_LINKS: "TASK_LINKS",
};















