import React, { useEffect, useRef, useState } from 'react';
import downward_icon from './../../../../assets/images/downward_arrow.png';
import upward_icon from './../../../../assets/images/upward_arrow.png';


function RequestDropdown(props) {
    const { data, value, id, className, name,refresh } = props;
    const [openData, setOpenData] = useState(false);
    const [Inputvalue, setInputValue] = useState(props.Inputvalue ||'Select');

    const dropdownHandler = (event, option) => {
        setInputValue(option[value]);
        setOpenData(!openData);
        props.addValueToQueue(name, option[value], option[id]);
    }
    useEffect(()=>{
        if(refresh===true){
            setInputValue('Select')
        } 
    },[refresh])
    const dropDownref = useRef();
    const inputValueRef = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownref.current && !dropDownref.current.contains(event.target)) {
                if (!openData)
                    setOpenData(openData);
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropDownref])
    return (<>
        <section className={className}>
            <div className=' wrapper'>
                <input className='input-field' type='text' name={name}
                    value={Inputvalue}
                    onClick={() => { setOpenData(!openData); }}
                    placeholder='Select'
                />
                <div className='button-arrow arrow-btn'
                    onClick={() => {
                        setOpenData(!openData);
                    }}
                    ref={dropDownref}
                >
                    {!openData ? <img src={downward_icon} alt="arrow" /> :
                        <img src={upward_icon} alt="arrow" />}
                </div>
            </div>
            <div>
                {openData && <ul className={`ul-wrapper`}>
                    {(data)?.map(option =>
                    (<li value={option?.[value]} key={option?.[id]} className='list-wrapper' ref={inputValueRef}
                        onClick={(event) => dropdownHandler(event, option)} >
                        <label className='label-region' htmlFor={option?.[id]}>  {option?.[value]} </label>
                    </li>)
                    )}
                </ul>}
            </div>
        </section>

    </>
    )
}

export default RequestDropdown