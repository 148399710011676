import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./executive-dashboard.css";
import BredCrumb from "../Breadcrumb/bredcrumb";
import { Col } from 'react-bootstrap';
import CloseWindow_Normal from "../../assets/images/CloseWindow_Normal.png";
import Calls_Contact_Volume_By_Channels from "../../assets/images/Calls_Contact_Volume_By_Channels.png";
import Calls_Contact_Volume_By_Channels_3 from "../../assets/images/Calls_Contact_Volume_By_Channels_3.png";
import Calls_Contact_Volume_By_Channels_7 from "../../assets/images/Calls_Contact_Volume_By_Channels_7.png";
import Calls_Contact_Volume_By_Channels_9 from "../../assets/images/Calls_Contact_Volume_By_Channels_9.png";
import Calls_Contact_Volume_By_Channels_10 from "../../assets/images/Calls_Contact_Volume_By_Channels_10.png";
import Calls_Contact_Volume_By_Channels_14 from "../../assets/images/Calls_Contact_Volume_By_Channels_14.png";
import Calls_Contact_Volume_By_Channels_5 from "../../assets/images/Calls_Contact_Volume_By_Channels_5.png";

import Calls_Contact_Volume_By_Channels_11 from "../../assets/images/Calls_Contact_Volume_By_Channels_11.png";
import Calls_Contact_Volume_By_Channels_4 from "../../assets/images/Calls_Contact_Volume_By_Channels_4.png";
import Calls_Contact_Volume_By_Channels_13 from "../../assets/images/Calls_Contact_Volume_By_Channels_13.png";
import Calls_Contact_Volume_By_Channels_12 from "../../assets/images/Calls_Contact_Volume_By_Channels_12.png";
import Calls_Contact_Volume_By_Channels_6 from "../../assets/images/Calls_Contact_Volume_By_Channels_6.png";
import Calls_Contact_Volume_By_Channels_8 from "../../assets/images/Calls_Contact_Volume_By_Channels_8.png";

import AboveRequiredTargetIcon from "../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../assets/images/BelowMinimalTarget_Status.png";

import EDashboardAreaCard from './AreaCards/area-card';
import EDashboradCallType from './CallType/call-type';
import DoughnutCards from "./DoughnutCards/doughnut-cards";
import ModalPopup from "../Generic/modal-popup";
import Analytics from "./Analytics/analytics";
import EDashboardFilter from "./Filters/eDashboardfilter";
import StackedBarLineChart from "./StackedBar-LineChart/stacked_bar_line";
import HorizontalBarChart from "./HorizontalBarCharts/horizontal-bar-chart";
import PieChart from './PieChart/pie-chart';
import {Bar} from 'react-chartjs-2';
import {getKPIData, getComplianceData, getContactVolumeData, getHorizontalBarChartData, getProfitLossAnalyticData,
        getYearMonthRegionData, getIncidentsCardsData, getCallTypeData, getEDashBrdNotfnText } from "../../actions/eDashboard.action";
import { LineAreaChartModel, LineAreChartOptions, ModalLineAreChartOptions, ModalLineAreChartOptionsTlptip, ModalBarChartOptions, ModalBarChartData } from "./chart-config";
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Filler,
    ArcElement
  } from "chart.js";  
  import { Line } from "react-chartjs-2";
  import { Chart } from "react-chartjs-2";
import { id } from "chartjs-plugin-doughnut-innertext";
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Tooltip,
    Filler,
    ArcElement
  )
const ExecutiveDashboard = () => {

   const dispatch = useDispatch();  
   const [kpiData, setKPIData] = useState([]); 
   const [brdData, setBrdData] = useState([{name : 'Home', reDirect: '/welcome' }, {name : 'Reports', reDirect: '/regional' }, {name : 'EDashboard', reDirect: '/eDashboard' }]);    
   const [firstKPIData, setFirstKPIData] = useState([]);   
   const [secondKPIData, setSecondKPIData] = useState([]); 
   const gKPIData = useSelector((state) => state.eDashboardReducer.kpiData);
   const complianceData = useSelector((state) => state.eDashboardReducer.complianceData);   
   const [gComplianceData, setgComplianceData] = useState([]);
   const contactVolumeData = useSelector((state) => state.eDashboardReducer.contactVolumeData);
   const hrzntBarChrtAllData = useSelector((state) => state.eDashboardReducer.hrzntlBarChrtData);
   const profitLossAnalyticsChartData = useSelector((state)=> state.eDashboardReducer.profitLossAnalyticData);
   const yearMonthRegionDrpdwnData = useSelector((state)=>  state.eDashboardReducer.yearMonthRegionDrpdwnData);
   const totalIncidentCardsData =  useSelector((state)=>  state.eDashboardReducer.totalIncidentCards);
   const callTypeData = useSelector((state)=> state.eDashboardReducer.callTypeData);
   const eDashBrdNotfnText = useSelector((state)=> state.eDashboardReducer.eDashBrdNtfnMsg);
   const [showLineModalPopup, setLineModalPopup] = useState(false);
   const [showBarLineModalPopup, setBarLineModalPopup] = useState(false);
   const [showContactVolLineChartModalPopup ,setContactVolLineChartModalPopup] = useState(false);
   const [showContactVolBarChartModalPopup ,setContactVolBarChartModalPopup] = useState(false);
   const [keyModalTitle, setKeyModalTitle] = useState(null);
   const [complianceModalTitle, setComplianceModalTitle] = useState(null);
   const [modalLineChartData, setModalLineChartData]= useState({
      labels: [],
      datasets: [{
         label: 'Value',
         data: [],
         fill: true,
         backgroundColor: 'rgba(75,192,192,1)',
         borderColor: 'rgb(75, 192, 192)',
         tension: 0.1
      }]
   });
   const [modalLineAreChartOptionsConfig, setModalLineAreChartOptionsConfig]= useState({});
   const [modalBarChartData, setModalBarChartData]= useState(ModalBarChartData);
   const [contactVolumeLineChartData, setcontactVolumeLineChartData]= useState([]);
   const [contactVolumeLineChartLegendData, setcontactVolumeLineChartLegendData]= useState([]);
   const [DoughnutModalStackedChartLegendData, setDoughnutModalStackedChartLegendData]= useState(null);
   const [DoughnutModalChartLegendData, setDoughnutModalChartLegendData]= useState([]);
   const [modalStackedChartTxtNameData, setModalStackedChartTxtNameData]= useState('');
   const [accountsHrzntlBarChrtData, setAccountsHrzntlBarChrtData]= useState(null);
   const [languagesHrzntlBarChrtData, setLanguagesHrzntlBarChrtData]= useState(null);
   const [callDisbtnActiveStatus, setcallDisbtnActiveStatus]= useState(true);
   const prftLossHrzntLgnds = [ Calls_Contact_Volume_By_Channels_11,
                                Calls_Contact_Volume_By_Channels_4,
                                Calls_Contact_Volume_By_Channels_13,
                                Calls_Contact_Volume_By_Channels_12,
                                Calls_Contact_Volume_By_Channels_6,
                                Calls_Contact_Volume_By_Channels_8];

   const [prftLossVrtclLgnds, setPrftLossVrtclLgnds]= useState([]);
    const [profitLossRegionalChartData, setProfitLossRegionalChartData] = useState(null);
   const [pfLossChartYaxisTxtName, setPFLossChartYaxisTxtName] = useState('Call Distribution (%)');
   const [pfLossChartYaxisScndTxtName, setPFLossChartYaxisScndTxtName] = useState('Millions');
   const [pfLossChartYaxisPrcntTxt, setPFLossChartYaxisPrcntTxt] = useState('%');
   const [yearMonthDropdownData, setYearMonthDropdownData] = useState([{id: 0, value: ''}]);
   const [regionDropdownData, setRegionDropdownData] = useState([{id: 0, value: ''}]);
   const [locationGroupDropdownData, setLocationGroupDropdownData] = useState([{id: 0, value: ''}]);
   const [selectedyearMonthDropdownData, setSelectedYearMonthDropdownData] = useState('');
   const [selectedRegionDropdownData, setSelectedRegionDropdownData] = useState('');
   const [selectedLocationGroupDropdownData, setSelectedLocationGroupDropdownData] = useState('');
   const [totalIncidntData, setTotalIncidntData] = useState();

    const [chartBkgrndData, setChartBkgrndData] = useState([]);
    const [barChartData, setBarChartData] = useState([]);
    const [chartLabelData, setChartLabelData] = useState([]);  
    const [chartLegendData, setChartLegendData] = useState([]);
    const [analyticsChartValAddtnlData, setAnalyticsChartValAddtnlData] = useState([]);
    const [analyticsChartValAddtnlTltpData, setAnalyticsChartValAddtnlTltpData] = useState([]);
    const [tileData, setTileData] = useState({});
    const [eDashBrdNotfnTextMsg, setEDashBrdNotfnTextMsg] = useState('');
    
   useEffect(()=>{
      dispatch(getEDashBrdNotfnText());
      dispatch(getYearMonthRegionData());
      FetchAPIData(selectedyearMonthDropdownData, selectedRegionDropdownData, selectedLocationGroupDropdownData);
   }, [dispatch]);

   useEffect(()=>{
        if(eDashBrdNotfnText && eDashBrdNotfnText.length > 0){
            setEDashBrdNotfnTextMsg(eDashBrdNotfnText[0].value);
        }
   } ,[eDashBrdNotfnText]);

   const FetchAPIData = (yr, rgn, lctn) => {
    dispatch(getKPIData(yr, rgn, lctn));
    dispatch(getComplianceData(yr, rgn, lctn));
    dispatch(getContactVolumeData(yr, rgn, lctn));
    dispatch(getHorizontalBarChartData(yr, rgn, lctn));
    dispatch(getIncidentsCardsData(yr));
    dispatch(getProfitLossAnalyticData(yr, rgn, lctn));
    dispatch(getCallTypeData(yr, rgn, lctn));
   }

   useEffect(()=>{
    if(gKPIData && gKPIData.length > 0){
        let data = gKPIData[0];
        let keyDt = Object.keys(data);
        setKPIData(data);
        setFirstKPIData(keyDt.slice(0, (keyDt.length/2)));
        setSecondKPIData(keyDt.slice(((keyDt.length/2)), keyDt.length));
    }
   }, [gKPIData]);

   useEffect(()=>{
    if(complianceData && complianceData.length > 0){
        let data = complianceData[0];
        setgComplianceData(Object.keys(data));
    }
   }, [complianceData]);
  
   useEffect(()=>{
       let bar_data;
        if(hrzntBarChrtAllData && (hrzntBarChrtAllData.length > 0)){
            bar_data = hrzntBarChrtAllData[0];
            setAccountsHrzntlBarChrtData(bar_data['Calls By Account (Top 10)']);
            setLanguagesHrzntlBarChrtData(bar_data['Calls By Language (Top 10)']);
        }
   }, [hrzntBarChrtAllData]);

   useEffect(()=>{  
    if(profitLossAnalyticsChartData){ 
        loadPFLossData('call');
    }
}, [profitLossAnalyticsChartData]);

useEffect(()=>{
    if(totalIncidentCardsData && totalIncidentCardsData?.length > 0){
        setTotalIncidntData(totalIncidentCardsData[0]);
    }
}, [totalIncidentCardsData]);

useEffect(()=>{
    let yrMnth = []; let rgns = []; let locations = [];
    if(yearMonthRegionDrpdwnData?.filter && yearMonthRegionDrpdwnData?.filter?.YearMonth && yearMonthRegionDrpdwnData?.filter?.PLRegion){
        yearMonthRegionDrpdwnData?.filter?.YearMonth.map((itm, idx)=>{
            //let val = {id: itm.value, value: itm.label};
            let val = {id: itm.value, value: itm.value};
            yrMnth.push(val);
          });
          yearMonthRegionDrpdwnData?.filter?.PLRegion.map((itm, idx)=>{
            let val = {id: itm.value, value: itm.label};
            rgns.push(val);
          });

          yearMonthRegionDrpdwnData?.filter?.LocationGroup.map((itm, idx)=>{
            let val = {id: itm.value, value: itm.label};
            locations.push(val);
          });
          setYearMonthDropdownData(yrMnth);
          setRegionDropdownData(rgns);
          setLocationGroupDropdownData(locations);
    }
}, [yearMonthRegionDrpdwnData]);

useEffect(()=>{
    let bkgrnds = [];
    let chrtData = [];
    let labels = [];
    let legends = [];
    let val_addtnl_info = [];
    let yr_mnth_tlptip = [];
    if(callTypeData && callTypeData?.calltype && callTypeData?.tiles){
        setTileData(callTypeData?.tiles[0]);
        callTypeData?.calltype?.map((itm, idx)=>{
            let lgnd = {color: itm?.colour, lbl: itm?.Label};
            bkgrnds.push(itm?.colour);
            chrtData.push(parseInt(itm?.Value2?.split('%')[0]));
            labels.push(itm?.Value2);
            val_addtnl_info.push(itm?.Value3);
            yr_mnth_tlptip.push(itm?.Value1);
            legends.push(lgnd);
        });
        setChartBkgrndData(bkgrnds);
        setBarChartData(chrtData);
        setChartLabelData(labels);
        setChartLegendData(legends);
        setAnalyticsChartValAddtnlData(val_addtnl_info);
        setAnalyticsChartValAddtnlTltpData(yr_mnth_tlptip);
    }   
},[callTypeData]);

const loadPFLossData = (type) => {
    let dtObj = {'trend': [], 'pfLoss': [] }; let vrtclLgnds = [];
    if(type === 'call'){
        dtObj.trend = profitLossAnalyticsChartData['trend'];
        dtObj.pfLoss = profitLossAnalyticsChartData['Profit_Loss'];
        setPFLossChartYaxisTxtName('Call Distribution (%)');
        setPFLossChartYaxisScndTxtName('Millions');
        //setPFLossChartYaxisPrcntTxt('%');
    }else{
        dtObj.trend = profitLossAnalyticsChartData['agenttrend'];
        dtObj.pfLoss = profitLossAnalyticsChartData['AgentCount'];
        setPFLossChartYaxisTxtName('Agent Count');
        setPFLossChartYaxisScndTxtName('Thousands');
        //setPFLossChartYaxisPrcntTxt('');
    }
    if(dtObj && dtObj.trend){
        dtObj.trend.map((itm, idx)=>{
            let obj = {'color': itm.background , 'label': itm.PLRegion};
            vrtclLgnds.push(obj);
        });
      }
    setPFLossChartYaxisPrcntTxt('%');
    setPrftLossVrtclLgnds(vrtclLgnds);
    setProfitLossRegionalChartData(dtObj);
};

    const handleModalLineChart = (data) => {
        let vals = [], lbls=[];
        setLineModalPopup(true);
        setKeyModalTitle(data.Metric);
        let compliance = data.Compliance;
        compliance?.map((itm, i)=>{
            vals.push(itm.Value1);
            lbls.push(itm.YearMonth)
        });
        setModalLineChartData({
            labels: lbls,
            datasets: [{
              label: 'Value',
              data: vals,
              fill: false,
              backgroundColor: 'rgba(75,192,192,1)',
              borderColor: 'rgb(75, 192, 192)',
              tension: 0.1
            }]
          });   
          if(data?.Metric !== 'Digital Trans (%)' && data?.Metric !== 'First Contact Resolution(%)' && data?.Metric !== 'Abondon Rate(%)' && data?.Metric !== 'Attrition(%)')   {
            setModalLineAreChartOptionsConfig(ModalLineAreChartOptions);  
          } else{
            setModalLineAreChartOptionsConfig(ModalLineAreChartOptionsTlptip);
          }
    };

    const getStackedBarChartTxt = (metric) => {
        let txtName;
        switch(metric){
            case 'SLA Compliance' : { txtName = 'SLA'; break;}
            case 'CSAT' : { txtName = 'CSAT'; break;}
            case 'Delivery Support' : { txtName = 'Delivery Support'; break;}
            default : { break; }
        }
        return txtName;
    }

    const handleViewRptModal = (data) => {
        let lgnds = [data?.Label2, data?.Label3, data?.Label1];
        let chart_data = { MetPrcnt : [], MetricMet : [], Missed : [], Labels: [] };
        if(data && data?.Compliance && data?.Compliance?.length > 0){
            data?.Compliance?.map((cmpl, i)=>{
                chart_data.MetPrcnt.push(parseFloat(cmpl.Value1));
                chart_data.MetricMet.push(cmpl.Value2);
                chart_data.Missed.push(cmpl.Value3);
                chart_data.Labels.push(cmpl.YearMonth);
            });
        }
        setDoughnutModalStackedChartLegendData(chart_data);
        setDoughnutModalChartLegendData(lgnds);
        setComplianceModalTitle(data?.Metric);
        setModalStackedChartTxtNameData(getStackedBarChartTxt(data?.Metric));
        setBarLineModalPopup(true);
    }

    const getLineAreaChartData = (key) => {
        const dt = kpiData[key][0];          
        return dt;
    };

    const pieChartClickedHandler = (data) => {
        let lbls = [];
        let datasets = [];
        let legends = [];
        setContactVolLineChartModalPopup(true);
        if(data && data.VolumebyChannels && data.VolumebyChannels.length > 0){
            data?.VolumebyChannels?.map((itm,i)=>{
                let datArr = [], dt;
                legends.push(itm.caption);                
                itm?.MonthVolumes?.map((obj, j)=>{
                    if(i===0)
                    lbls.push(obj.YearMonth);
                    datArr.push(obj.value);
                    dt = {
                        type: "line",
                        label: itm.caption,
                        backgroundColor: itm.Colour,
                        data: datArr,
                        borderColor: itm.Colour,
                        borderWidth: 2,
                        order: itm.sortorder 
                      };                   
                });  
                datasets.push(dt);                             
            });
            setcontactVolumeLineChartData({labels: lbls, datasets: datasets});
            setcontactVolumeLineChartLegendData(legends);
        }
    }

    const tcvValueClickHandler =(dt) => {
        let lbls = [];
        let datArr = [];   
        if(dt && dt.Compliance.length>0){
            dt.Compliance.map((itm,i)=>{
                lbls.push(itm.YearMonth);
                datArr.push(itm.Value1);
            });
        }        
        setContactVolBarChartModalPopup(true);
        setModalBarChartData({
            labels: lbls,
            datasets: [{
              backgroundColor: ['#00A3E1'],
              borderColor: ['#00A3E1'],
              borderWidth: 0,
              data: datArr
            }]
          });
    }

    const getCBVLgndIcons = (itm) => {
        switch(itm){
            case 'Calls': {
              return Calls_Contact_Volume_By_Channels;
              }
            case 'Emails' : {
              return Calls_Contact_Volume_By_Channels_3;
              }
            case 'Chats' : {
              return Calls_Contact_Volume_By_Channels_14;
              }
            case 'Web Handled': {
            return Calls_Contact_Volume_By_Channels_10;
              }
            case 'Voice Mails' : {
            return Calls_Contact_Volume_By_Channels_9;
             }
            case 'Swivel Tickets' : {
            return Calls_Contact_Volume_By_Channels_7;
             }
            default: {
              return(<div></div>);
            }
          }
    }
  
    
    const getDoughnutLgndIcons = (itm) => {
        switch(itm){
            case 'MET %': {
              return <img src={Calls_Contact_Volume_By_Channels_5} alt='legend icon' />;
              }
            case 'Metrics Met' : {
              return <div className="color-box" style={{backgroundColor: '#00A3E1'}}></div>;
              }
            case 'Missed' : {
              return <div className="color-box" style={{backgroundColor: '#ED9B33'}}></div>;
              }
            case 'CSAT %': {
                return <img src={Calls_Contact_Volume_By_Channels_5} alt='legend icon' />;
            }
            case 'CSATS' : {
                return <div className="color-box" style={{backgroundColor: '#00A3E1'}}></div>;
            }
            case 'DSAT' : {
                return <div className="color-box" style={{backgroundColor: '#ED9B33'}}></div>;
            }
            case 'Delivery Support %': {
                return <img src={Calls_Contact_Volume_By_Channels_5} alt='legend icon' />;
                }
            case 'L1FTE Count' : {
                return <div className="color-box" style={{backgroundColor: '#00A3E1'}}></div>;
                }
            case 'Support Count' : {
                return <div className="color-box" style={{backgroundColor: '#ED9B33'}}></div>;
                }
            default: {
              return(<div></div>);
            }
          }
    }

    const selectedDrpDwnRegionHandler = (e) => {
        setSelectedRegionDropdownData(e);
    }

    const selectedDrpDwnYrMnthHandler = (e) => {
        setSelectedYearMonthDropdownData(e);        
    }

    const selectedDrpDwnLocationHandler = (e) => {
        setSelectedLocationGroupDropdownData(e);        
    }

    const filterRefreshHandler = () => {
        setRegionDropdownData([...regionDropdownData]);
        setYearMonthDropdownData([...yearMonthDropdownData]);    
        setSelectedRegionDropdownData('');
        setSelectedYearMonthDropdownData('');       
    }

    useEffect(()=>{  
        FetchAPIData(selectedyearMonthDropdownData, selectedRegionDropdownData, selectedLocationGroupDropdownData);
    }, [selectedyearMonthDropdownData, selectedRegionDropdownData, selectedLocationGroupDropdownData]);
    

    return (
        <div className="row econtainer">
        <div className="edashboard-container" style={{margin: '0px 10px'}}>
            <div className="row">
                 <BredCrumb data={brdData}  />
            </div>
            <div className="row">
                <div className="col-md-3 report-lbl edashboard-screen-lbl-txt">Dashboard</div>
            </div>
            <div className="row notfcnt-text">
            <span><b>{eDashBrdNotfnTextMsg}</b></span>
            </div>
            <div className="row">
                <EDashboardFilter 
                yrMnthDt={yearMonthDropdownData} rgnDt={regionDropdownData} 
                lcntDt={locationGroupDropdownData}
                lcntGrpDt = {locationGroupDropdownData}
                selectedDrpDwnRegionHandler={selectedDrpDwnRegionHandler} 
                selectedDrpDwnYrMnthHandler={selectedDrpDwnYrMnthHandler} 
                selectedDrpDwnLocationHandler={selectedDrpDwnLocationHandler}
                refreshHandler={filterRefreshHandler} 
                />
            </div>           
            <div className="row" style={{marginTop: '30px'}}>
                <EDashboradCallType chartBkgrndData={chartBkgrndData} barChartData={barChartData} chartLabelData={chartLabelData} chartLegendData={chartLegendData}
                 tileData={tileData} analyticsChartValAddtnlData={analyticsChartValAddtnlData} analyticsChartValAddtnlTltpData={analyticsChartValAddtnlTltpData}/>
            </div> 
            <div className="row analytics-container" style={{marginTop: '10px', padding: '20px'}}> 
                    <div className="row analytics-title">
                        <div className="col-sm-12 col-md-6 col-lg-6"><span className="analytics-hdr-txt ms-3">Profit & Loss Region Analysis</span></div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="row text-right">
                                <div  className="col-sm-6 col-md-6 col-lg-6"></div>
                                 <div onClick={()=>{setcallDisbtnActiveStatus(!callDisbtnActiveStatus); loadPFLossData('call');}}  className={`col-sm-6 col-md-3 col-lg-3 text-center call-distb-btn ${callDisbtnActiveStatus ? 'actvBtn' : 'disbleBtn'}`}>
                                    <span>Call Distribution Trend</span>
                                </div>
                                <div onClick={()=>{setcallDisbtnActiveStatus(!callDisbtnActiveStatus); loadPFLossData('agent');}} className={`col-sm-6 col-md-3 col-lg-3 text-center call-distb-btn ${!callDisbtnActiveStatus ? 'actvBtn' : 'disbleBtn'}`}>
                                    <span>Agent Count Trend</span>
                                </div>
                            </div>                                
                        </div>
                    </div>
                    <div className="row ms-2" style={{marginTop: '20px'}}>
                            <ul id="prft-loss-bar-lgnd">   
                                        {prftLossVrtclLgnds.map((itm,i)=>{
                                            return <li key={i}><img  src={prftLossHrzntLgnds[i]} alt="legend icons" /><span className="bar-lgnd-text">{itm.label}</span></li>
                                        })}
                            </ul> 
                    </div>
                    <div className="row" style={{marginTop: '20px'}}>
                        <div className="col-sm-12 col-md-1 col-lg-1"></div>
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <Analytics data={profitLossRegionalChartData} yaxisfname={pfLossChartYaxisTxtName} yaxissname={pfLossChartYaxisScndTxtName} prcnttxt={pfLossChartYaxisPrcntTxt}/>
                        </div>                        
                        <div className="col-sm-12 col-md-2 col-lg-2">
                            <div className="row">
                                <ul id="vrtcl-lgnds">   
                                    {prftLossVrtclLgnds?.map((itm,i)=>{
                                        return <li key={i}><div className="color-box" style={{backgroundColor: itm.color}}></div>
                                        <span className="bar-lgnd-text">{itm.label}</span>
                                        </li>
                                    })}
                                </ul>
                            </div>   
                            <div className="row" style={{width: '75%',marginTop: '38%'}}>
                                <Col><div className="account"></div> <div><div className='btn_profile pfloss-btn'>VIEW DETAILS</div></div></Col>
                            </div>                        
                        </div>                               
                    </div>                                    
            </div>
            <div className="row ms-1" style={{marginTop: '30px'}}>
                <div className="col-sm-12 col-md-9 col-lg-9">
                   <div className="row gx-5">
                    <div className="col-sm-12 col-md-5 col-lg-5 hrzntl-bar-card">
                        <HorizontalBarChart data={accountsHrzntlBarChrtData} txt="Calls By Account (Top 10)" clr="#006975" />
                    </div>
                    <div className="col-sm-12 col-md-1 col-lg-1" ></div>
                    <div className="col-sm-12 col-md-5 col-lg-5 hrzntl-bar-card" >
                        <HorizontalBarChart data={languagesHrzntlBarChrtData} txt="Calls By Language (Top 10)" clr="#00A3E1"/>
                    </div>
                    <div className="col-sm-12 col-md-1 col-lg-1" ></div>
                    </div>
                </div>                
                <div className="col-sm-12 col-md-3 col-lg-3">
                    <div className="row total-incdnts-cnter">
                        <div className="col-sm-12 col-md-6 col-lg-6 total-incdnts-cnter-chld">
                            <div className="totl-incidnt-txt">{totalIncidntData?.label1}</div>
                            <div className="totl-incidnt-val">{totalIncidntData?.value1}</div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 total-incdnts-cnter-chld2">
                            <div className="row dwn-uptm-txt">
                                <span>{totalIncidntData?.label2}</span>
                            </div>
                            <div className="row dwn-uptm-txt-val">
                            <span><img src={BelowMinimalTargetIcon} className="margn-5 mrgn-top-arrw-1"  alt="AbvRqdTgt Icon" />{totalIncidntData?.value2}</span>
                            </div>
                            <div className="row dwn-uptm-txt">
                                <span>{totalIncidntData?.label3}</span>
                            </div>
                            <div className="row dwn-uptm-txt-val">
                            <span><img src={AboveRequiredTargetIcon} className="margn-5 mrgn-top-arrw-1"  alt="AbvRqdTgt Icon" />{totalIncidntData?.value3}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row total-reqst-cnter">
                            <div className="ttl-rq-txt text-center">
                                <span>{totalIncidntData?.label4}</span>
                            </div>
                            <div className="ttl-rq-txt-val text-center">
                                <span className="ttl-rq-txt-val-hover">{totalIncidntData?.value4}</span>
                            </div>
                            <div className="ttl-rq-txt-lval text-center">
                                <span><img src={AboveRequiredTargetIcon} className="margn-5 mrgn-top-arrw-1"  alt="AbvRqdTgt Icon" /><span className="grn-color">{totalIncidntData?.value6}</span> for {totalIncidntData?.yearmonth}</span>
                            </div>
                    </div>
                </div>                   
            </div>   
            <div className="row gx-5" style={{marginTop: '30px'}}>
                <div className="col-sm-12 col-md-6 col-lg-6 area-card-list-bgclr" >
                    {firstKPIData.map((key,i)=>{  
                          let dt = getLineAreaChartData(key)          
                        return <EDashboardAreaCard valClicked={handleModalLineChart} key={i} data={dt}/>;
                    })}
                </div>                                   
                <div className="col-sm-12 col-md-6 col-lg-6 area-card-list-bgclr">
                {secondKPIData.map((key,i)=>{
                        const dt = kpiData[key][0];
                        return <EDashboardAreaCard valClicked={handleModalLineChart} key={i} objName={key} data={dt}/>;
                    })}
                </div>
            </div>         
            <div className="row dghnt-align-cards">
                {gComplianceData?.map((key,j)=>{
                     const dt = complianceData[0][key][0];
                     return(
                        <div key={j} style={ ((j===1) ? { textAlign:'-webkit-center'} : ((j===2) ? { textAlign:'-webkit-right'} : {}))} className="col-sm-12 col-md-12 col-lg-4 dghnt-align-card">
                            <DoughnutCards data={dt} viewRptClicked={handleViewRptModal}/>                            
                        </div>
                     );
                })}                                
            </div>
            <div className="row mrgn-top-35">
                    <PieChart data={contactVolumeData} pieChartClicked={pieChartClickedHandler} tcvValueClickedHandler={tcvValueClickHandler} />
            </div>
            {showLineModalPopup && <ModalPopup isOpen={showLineModalPopup} setShowModal={setLineModalPopup}>
                   <div className="row mrgn-30">          
                   <div className="col-sm-2 col-md-1 col-lg-1 line-modal-yr text-center" style={{writingMode: 'vertical-rl', textOrientation: 'mixed', transform: 'rotate(180deg)'}}>
                    <span>{keyModalTitle}</span>
                    </div> 

                    <div className="col-sm-10 col-md-11 col-lg-11">
                        <div className="row">
                            <div className="col-sm-11 col-md-11 col-lg-11 ps-1 pb-2">
                                <span className="line-chart-title-modal">{keyModalTitle}</span>
                            </div>
                            <div className="col-sm-1 col-md-1 col-lg-1">
                                <img src={CloseWindow_Normal} className="close-modal" onClick={()=>setLineModalPopup(false)} alt="window close icon" />
                            </div>
                        </div>
                        <div className="row px-5 mt-3">
                            <Line options={modalLineAreChartOptionsConfig} data={modalLineChartData}  plugins={[{
                                id: 'horizontalDottedLine',
                                beforeDatasetsDraw: (chart, args, options) => {
                                const {ctx, chartArea: { top, right, bottom, left, width, height},                            
                                scales: {x,y}} = chart;
                                ctx.save();
                                ctx.strokeStyle = 'Orange';                           
                                ctx.setLineDash([10,10]);
                                ctx.strokeRect(left, y.getPixelForValue(y.end), width, 0);
                                ctx.restore();
                                }
                            }]} />
                        </div>
                        <div className="row line-modal-yr text-center p-2 mt-2">
                            <span>Year/Month</span>
                        </div>
                    </div>                


                </div>
            </ModalPopup>}
            {showBarLineModalPopup && <ModalPopup isOpen={showBarLineModalPopup} setShowModal={setBarLineModalPopup}>
            <div className="row">
                <div className="col-sm-2 col-md-1 col-lg-1">
                    <label>text</label>
                </div>
                <div className="col-sm-10 col-md-11 col-lg-11">
                        <div className="row mrgn-30">
                            <div className="row">
                                <div className="col-sm-11 col-md-11 col-lg-11 ps-1 pb-2">
                                    <span className="line-chart-title-modal">{complianceModalTitle}</span>
                                </div>
                                <div className="col-sm-1 col-md-1 col-lg-1">
                                <img src={CloseWindow_Normal} className="close-modal" onClick={()=>setBarLineModalPopup(false)} alt="window close icon" />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <ul id="bar-lgnd">   
                                    {DoughnutModalChartLegendData.map((itm,i)=>{
                                        return <li className="ms-4" key={i}><span>{getDoughnutLgndIcons(itm)}</span><span className="bar-lgnd-text">{itm}</span></li>
                                    })}
                                </ul>
                            </div>
                            <div className="row mt-2" style={{height: '25rem'}}>
                                <StackedBarLineChart data={DoughnutModalStackedChartLegendData} chrtTxtName={modalStackedChartTxtNameData} />
                            </div>                           
                        </div>
                </div>  
            </div>
            </ModalPopup>}
            {showContactVolBarChartModalPopup && <ModalPopup isOpen={showContactVolBarChartModalPopup} setShowModal={setContactVolBarChartModalPopup}>
            <div className="row mrgn-30">
                <div className="col-sm-2 col-md-1 col-lg-1 line-modal-yr text-center" style={{writingMode: 'vertical-rl', textOrientation: 'mixed', transform: 'rotate(180deg)'}}>
                    <span>Total Contact Volume(TCV)</span>
                </div>
                <div className="col-sm-10 col-md-11 col-lg-11">
                        <div className="row">
                            <div className="row">
                                <div className="col-sm-11 col-md-11 col-lg-11 ps-1 pb-2">
                                    <span className="line-chart-title-modal">Contact Volume</span>
                                </div>
                                <div className="col-sm-1 col-md-1 col-lg-1">
                                    <img src={CloseWindow_Normal} className="close-modal" onClick={()=>setContactVolBarChartModalPopup(false)} alt="window close icon" />
                                </div>
                            </div>
                            <div className="row mt-2" style={{height: '25rem'}}>
                                <Bar  options={ModalBarChartOptions} data={modalBarChartData}  />
                            </div>
                            <div className="row line-modal-yr text-center p-2 mt-4">
                                <span>Year/Month</span>
                            </div>
                        </div>
                </div>  
            </div>
            </ModalPopup>}
            {showContactVolLineChartModalPopup && <ModalPopup isOpen={showContactVolLineChartModalPopup} setShowModal={setContactVolLineChartModalPopup}>
            <div className="row" style={{marginLeft: '26%'}}>
                <div className="col-sm-2 col-md-1 col-lg-1 line-modal-yr text-center" style={{writingMode: 'vertical-rl', textOrientation: 'mixed', transform: 'rotate(180deg)'}}>
                    <span>Total Contact Volume(TCV)</span>
                </div>
                <div className="col-sm-10 col-md-11 col-lg-11">
                        <div className="row">
                            <div className="row bar-lgnd-contnr">
                                <div className="col-sm-11 col-md-11 col-lg-11 ps-1 pb-2">
                                    <span className="line-chart-title-modal">Contact Volume By Channels</span>
                                </div>
                                <div className="col-sm-1 col-md-1 col-lg-1">
                                    <img src={CloseWindow_Normal} className="close-modal" onClick={()=>setContactVolLineChartModalPopup(false)} alt="window close icon" />
                                </div>
                            </div>
                            <div className="row mt-2">                              
                            <ul id="bar-lgnd">   
                                {contactVolumeLineChartLegendData.map((itm,i)=>{
                                    return <li className="ms-4" key={i}><span><img  src={getCBVLgndIcons(itm)} alt="legend icons" /></span><span className="bar-lgnd-text">{itm}</span></li>
                                })}
                            </ul>
                            </div>
                            <div className="row mt-2" style={{height: '25rem'}}>                               
                                <Chart data={contactVolumeLineChartData} options={ModalLineAreChartOptions}/>
                            </div>
                            <div className="row line-modal-yr text-center p-2 mt-4">
                                <span>Year/Month</span>
                            </div>
                        </div>
                </div>  
            </div>
            </ModalPopup>}
        </div>
        </div>
    );
};

export default ExecutiveDashboard;