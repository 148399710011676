
const getRequestData = (items) => {
    const dispobj = (items.date !== '' ? `Date=${items.date}` : '') + '&' + (items.requesttype !== '' ? `Type[]=${items.requesttype}` : '') + '&' + (items.status !== '' ? `Status[]=${items.status}` : '') + '&'; 
    console.log(items)
    if (items.date === '' && items.requesttype === '' && items.status === '' )
        return '/api/usp/RequestIncidentGetAll?&hint=request'
     else
         return `/api/usp/RequestIncidentGetAll?${dispobj}&hint=request`;
}

const getRequestAdminData = (items) => {
    const dispobj = (items.date !== '' ? `Date=${items.date}` : '') + '&' + (items.requesttype !== '' ? `Type[]=${items.requesttype}` : '') + '&' + (items.status !== '' ? `Status[]=${items.status}` : '') + '&'; 
    console.log(items)
    if (items.date === '' && items.requesttype === '' && items.status === '' )
        return '/api/usp/RequestIncidentAdminGetAll?&hint=request'
     else
         return `/api/usp/RequestIncidentAdminGetAll?${dispobj}&hint=request`;
}


export const requestUrls = (name, items) => {
    console.log('items:::::::::',items)
    switch (name) {
        case 'GET_REQUEST_DATA': {
            return getRequestData(items)
        }
        case 'UPDATE_NEW_REQUEST': {
            return '/api/usp/RequestIncidentNew'
        }
        case 'UPDATE_EDIT_REQUEST': {
            return '/api/usp/RequestIncidentEdit'
        }
        case 'GET_ADMIN_REQUEST_DATA': {
            return getRequestAdminData(items)
           // return `/api/usp/RequestIncidentAdminGetAll?Date=${items.date}&Type=${items.requesttype}&Status=${items.status}`
        }
        case 'UPDATE_EDIT_ADMIN_REQUEST': {
            return '/api/usp/RequestIncidentAdminEdit'
        }
        case 'GET_REQUEST_DROPDOWN_DATA': {
            return '/api/usp/MasterGetAll'
        }
        case 'GET_UPLOADED_FILE_DATA': {
            return `/api/file/${items.uploadfileid}`
        }
        default: { }
    }

}   