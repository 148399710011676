

const SlaFailureURLs = (name,items) => {
    switch (name) {
        case 'GET_EXEMPTION_DATA': {
            return `/api/usp/SLAExemption?CustomerId=${items.custId}&ServiceDeskId=${items.servId}&Month=${items.month}`
        }
        case 'UPDATE_EXISTING_COMMENT': {
            return `/api/usp/SLAExemptionComment?CustomerId=${items.custId}&ServiceDeskId=${items.servId}&Month=${items.month}&SLAID=${items.sla}`
        }
        case 'GET_EXCLUSION_DATA': {
            return `/api/usp/SLAExclusion?CustomerId=${items.custId}&ServiceDeskId=${items.servId}&Month=${items.month}`
        }
        case 'UPDATE_EXCLUSION_COMMENT': {
            return `/api/usp/SLAExclusionWithComment?CustomerId=${items.custId}&ServiceDeskId=${items.servId}&Month=${items.month}&SLAID=${items.sla}`
        }  
        default: { }
    }
}
export default SlaFailureURLs;