import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { getOperationsData, updateOperationsDeleteStart } from '../../../../actions/Administration/adminView.action';
import Edit_Icon from './../../../../assets/onboard-images/Edit_Icon.png';
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png'
import TooltipHandler from '../../../Onboard/OnboardComponents/TooltipHandler';
import { OperationsAddModel, OperationsModalPopup } from './OperationsModels';
import { DeleteModalPopup } from '../../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { AdminViewDeleteModel } from '../AdminViewModels';
import { useHistory } from 'react-router-dom';

const OperationsMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let operationsMainData = useSelector((state) => state.adminViewReducer.operationsData);
  const [editOperationData, setEditOperationData] = useState()
  const [mode,setMode] = useState('')
  const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
  const [deleteRoleID, setDeleteRoleID] = useState(null);
  
  


  useEffect(() => {
    dispatch(getOperationsData());

  }, [dispatch]);

  const [showOperationAddModal, setShowOperationAddModal] = useState(false)
  const [filter, setFilter] = useState('');
  const [searchColumns, setSearchColumns] = useState(['OperationName']);
  function searchRows(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column]
            .toString()
            .toLowerCase()
            .indexOf(filter.toLowerCase()) > -1,
      ),
    );
  }

  const handleDeleteRole = () => {
    let delete_arr = [{"OperationID":deleteRoleID.ID}]
    dispatch(updateOperationsDeleteStart(delete_arr))
    dispatch(getOperationsData())
    setShowDeleteRoleModal(false)
    dispatch(getOperationsData());
}

console.log('delete',deleteRoleID)

  return (
    <div style={{ paddingBottom: '200px', marginRight: 20 }}>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={()=>history.push('/adminviewlanding')}
        isActive3={false} label3='Operations'
      />
      <header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='administration_text'>Operations: {operationsMainData.length}</div>
        <div style={{ display: 'flex', gap: '20px' }} >
          <div style={{ marginTop: 20 }} onClick={()=>{setShowOperationAddModal(true);setMode('Add')}}><span className='btn_addAdminFlow'>ADD OPERATION</span></div>
          <span className='search_bar_AdminFlow'>
            <input className='search_input_AdminFlow' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Roles' />
            <button className='search_icon_AdminFlow'><img src={searchIcon} alt='' /> </button>
          </span>
        </div>
      </header>
      
      <table style={{ width: '100%', marginTop: '1em' }} className='queue-table'>
                <thead>
                    <tr>
                          <th style={{ border: 'none' }}><span className='roles_header'>Operation Name</span></th>
                          <th style={{ border: 'none' }}><span className='roles_header'>Descriptions</span></th>
                       
                        <th style={{ border: 'none' }}>{'  '}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                      searchRows(operationsMainData).map((data, index) => (
                            <tr key={data.id}>
                                <td className='roles_td'>{data?.['OperationName']}</td>
                                <td className='roles_td'>{(data?.Description)}</td>
                                
                               <td className='table-button'>
                                    <span onClick={()=>{setShowOperationAddModal(true);setEditOperationData(data);setMode('Edit')}}>
                                        <TooltipHandler src={Edit_Icon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                                    </span>

                                    <span onClick={()=>{setShowDeleteRoleModal(true);setDeleteRoleID(data)}}>
                                        <TooltipHandler src={deleteIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Delete' />
                                    </span>
                                </td>
                                
                            </tr>
                     )) }
                </tbody>
            </table>

            {showOperationAddModal && <OperationsModalPopup isOpen={showOperationAddModal} setShowModal={setShowOperationAddModal}>
            <OperationsAddModel editOperationData={editOperationData} Mode={mode}
            handleCancelOperation={()=>{setShowOperationAddModal(false)}}
            handleSubmitOperation={()=>{setShowOperationAddModal(false);dispatch(getOperationsData())}}
            />
        </OperationsModalPopup>}

        {showDeleteRoleModal && <DeleteModalPopup isOpen={showDeleteRoleModal} setShowModal={setShowDeleteRoleModal}>
            <AdminViewDeleteModel modalName='Delete Operation' buttonOne='YES' buttonTwo='NO'
            InformationText = {deleteRoleID.OperationName} 
            AfterInfoText='operation?'
            handleDeleteYes={handleDeleteRole}
            handleDeleteNo={()=>setShowDeleteRoleModal(false)}
            />
        </DeleteModalPopup>}
    </div>
  )
}

export default OperationsMain