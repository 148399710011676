import React, { Fragment, useEffect, useState } from 'react'
import editIcon from './../../../../assets/onboard-images/Edit_Icon.png'
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png'
import supportIcon from './../../../../assets/images/Add_SupportHours_Icon.png'
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png'

import MOCK_DATA from './SupportData.json'
import { ModalAccountPopup } from '../Modals/ModalQueuePopup';
import AddSupportModal from './AddSupportModal';
import { AdminValidDateChange, DeleteFragment, DeleteModalPopup } from '../Modals/DeleteModalPopup'
import TooltipHandler from '../TooltipHandler'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import { getRoleBasedView, updateSupportRequestStart } from '../../../../actions/Administration/admin.action';
import ActivateDisabledIcon from './../../../../assets/images/Activate_Disabled_Icon.png';
import ActivateIcon from './../../../../assets/images/Activate_Icon.png';
import { setValidforAddingTabRows } from '../../../../actions/Onboard.action'


const initailState = {
    start_time: '',
    end_time: '',
    language: '',
    valid_from: '',
    valid_to: '',
    mondays: false,
    tuesdays: false,
    wednesdays: false,
    thursdays: false,
    fridays: false,
    saturdays: false,
    sundays: false

}

const SupportHours = (props) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('');
    const location = useLocation();
    const [Data, setData] = useState(MOCK_DATA);
    const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    const [searchColumns, setSearchColumns] = useState([
        'language'
    ]);
    const [mode, setMode] = useState(null);
    useEffect(() => {
        if (props.accountDetails) setMode(props.accountDetails.mode)
    }, [props.accountDetails])

    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch])

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    };

    const onClickSelect = (name) => {
        const newFormData = { ...editSupportInfo };
        newFormData[name] = '';
        setEditSupportInfo(newFormData)

    }
    const [validation, setValidation] = useState('');

    const weekDaysBooleanHandle = (dt) => {
        if (dt === false) return 'false';
        if (dt === true) return 'true';
        if (dt === null || dt === undefined) return 'false'
        else return dt;
    }

    const renderSaveSupportHpursVoice = (data) => {
        let temp_Arr = [...data];
        let temp_arr = temp_Arr?.map((data, i) => {
            return {
                language: data.language,
                start_time: data.startTime,
                end_time: data.endTime,
                valid_from: formatDate(data.validFrom),
                valid_to: formatDate(data.validTo),
                mondays: (weekDaysBooleanHandle(data?.mondays)),
                tuesdays: (weekDaysBooleanHandle(data?.tuesdays)),
                wednesdays: (weekDaysBooleanHandle(data?.wednesdays)),
                thursdays: (weekDaysBooleanHandle(data?.thursdays)),
                fridays: (weekDaysBooleanHandle(data?.fridays)),
                saturdays: (weekDaysBooleanHandle(data?.saturdays)),
                sundays: (weekDaysBooleanHandle(data?.sundays)),
                NonVoiceItem: data['non-Voice Item'],
                TabName: data['voice/Non-Voice'],
                supportID: data?.supporthoursId,
                status: data?.status,
                request: data?.request,
                tooltip: data?.toolTip

            }
        });
        return temp_arr;
    }

    // let data = onSaveSupportHoursVoice?.length > 0 ? renderSupportHpursVoice(onSaveSupportHoursVoice[0].Details) :
    let data = (props.supportHoursVoice?.length > 0 ? renderSaveSupportHpursVoice(props.supportHoursVoice) : []);
    const [supportData, setSupportData] = useState(data);
    useEffect(() => {
        setSupportData(data)
    }, [props.supportHoursVoice]);

    const [dispatchSupportObj, setDispatchSupportObj] = useState([])
    function searchRows(rows) {
        return rows?.filter((row) => searchColumns?.some((column) => row?.[column]?.toString()?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1,))
    }

    const configureSupportData = (support) => {
        const temp_arr = [...support];
        const temp_Arr = temp_arr.map(data => {
            let temp1 = {
                Language: data.language,
                StartTime: data.start_time || '',
                EndTime: data.end_time || '',
                ValidFrom: data.valid_from,
                ValidTo: data.valid_to,
                Mondays: data.mondays || '',
                Tuesdays: data.tuesdays || '',
                Wednesdays: data.wednesdays || '',
                Thursdays: data.thursdays || '',
                Fridays: data.fridays || '',
                Saturdays: data.saturdays || '',
                Sundays: data.sundays || '',
            }
            let temp2 = {
                Language: data.language,
                StartTime: data.start_time || '',
                EndTime: data.end_time || '',
                ValidFrom: data.valid_from,
                ValidTo: data.valid_to,
                Mondays: data.mondays || '',
                Tuesdays: data.tuesdays || '',
                Wednesdays: data.wednesdays || '',
                Thursdays: data.thursdays || '',
                Fridays: data.fridays || '',
                Saturdays: data.saturdays || '',
                Sundays: data.sundays || '',
                OpenHoursId: data.supportID || ''
            }
            return location.pathname === '/onboardAccount' ? temp1 : temp2
        })
        let tempArray3 = [{
            Servicedesk: accountSiteInfo.SiteName,
            Account: accountSiteInfo.AccountName,
            NonVoiceItem: props.NonVoiceItem,
            VoiceNonVoice: props.TabName,
            Details: temp_Arr
        }]
        setDispatchSupportObj(tempArray3);
    }
    const headers = Data.header;
    
    const [showAddSupportModal, setShowAddSupportModal] = useState(false);
    const [showEditSupportModal, setShowEditSupportModal] = useState(false);
    const [showDeleteSupportModal, setShowDeleteSupportModal] = useState(false);
    const [addFormSupport, setAddFormSupport] = useState(initailState)

    const [editSupportID, setEditSupportID] = useState(null);
    const [editSupportInfo, setEditSupportInfo] = useState(initailState);

    const [deleteRowID, setDeleteRowID] = useState(null);

    const addValueToSupport = (name, value) => {
        const newFormData = { ...addFormSupport };
        if (value !== 'Select') {
            newFormData[name] = value;
            setAddFormSupport(newFormData)
        }
    }

    const HandleAddSupport = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...addFormSupport };
            newFormData[fieldName] = fieldValue;
            setAddFormSupport(newFormData);
        }
    }
    const handleToggleSupport = (event, isActive) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = isActive
        const newFormData = { ...addFormSupport };
        newFormData[fieldName] = fieldValue;
        setAddFormSupport(newFormData)
    }
    const handleAddSupportSubmit = event => {
        event.preventDefault();
        const newSupport = {
            id: (Math.random() * 10000).toString(),
            start_time: addFormSupport.start_time,
            end_time: addFormSupport.end_time,
            language: addFormSupport.language,
            valid_from: addFormSupport.valid_from,
            valid_to: addFormSupport.valid_to,
            mondays: (weekDaysBooleanHandle(addFormSupport.mondays)),
            tuesdays: (weekDaysBooleanHandle(addFormSupport.tuesdays)),
            wednesdays: (weekDaysBooleanHandle(addFormSupport.wednesdays)),
            thursdays: (weekDaysBooleanHandle(addFormSupport.thursdays)),
            fridays: (weekDaysBooleanHandle(addFormSupport.fridays)),
            saturdays: (weekDaysBooleanHandle(addFormSupport.saturdays)),
            sundays: (weekDaysBooleanHandle(addFormSupport.sundays)),
            supportID: addFormSupport?.supportID,
            status: 'Active',
            request: 'Completed',
            tooltip: null
        }
        const newSupports = [newSupport, ...supportData]
        let temp_arr = {
            language: addFormSupport.language,
            valid_from: (addFormSupport.valid_from),
            valid_to: (addFormSupport.valid_to)
        };
        const isEmpty = Object.values(temp_arr).some(temp => (temp === null || temp === '' || temp === undefined));
        const isValidDate = moment((addFormSupport.valid_to)).isAfter((addFormSupport.valid_from));
        // const isValidTime = moment(addFormSupport.start_time, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A'], true).isValid() &&
        //     moment(addFormSupport.end_time, ['HH:mm:ss', 'HH:mm', 'h:mm', 'h:mm A', 'hh:mm A', 'HH:MM:SS A'], true).isValid();
        const validStartEndTime = () => {
            if (addFormSupport.start_time && addFormSupport.end_time)
                return moment(addFormSupport.start_time, 'hh:mm').isBefore(moment(addFormSupport.end_time, 'hh:mm'))
        }
        if (isEmpty) {
            setShowAddSupportModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }
        else if (!isValidDate) {
            setShowAddSupportModal(true);
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date');
        }
        else if (!validStartEndTime) {
            setShowAddSupportModal(true);
            setValidation('Please ensure "Start Time"  is placed before "End Time"');
        }
        else {
            setSupportData(newSupports)
            setShowAddSupportModal(false);
            configureSupportData(newSupports);
            setValidation('')
        }
    }

    const handleAddSupportCancel = event => {
        event.preventDefault();
        setShowAddSupportModal(false);
    }


    const onClickEditRow = (index, data) => {
        setEditSupportID(index)
        setShowEditSupportModal(true)
        const formValues = {
            start_time: data.start_time,
            end_time: data.end_time,
            language: data.language,
            valid_from: data.valid_from,
            valid_to: data.valid_to,
            mondays: ((data.mondays)),
            tuesdays: ((data.tuesdays)),
            wednesdays: ((data.wednesdays)),
            thursdays: ((data.thursdays)),
            fridays: ((data.fridays)),
            saturdays: ((data.saturdays)),
            sundays: ((data.sundays)),
            supportID: data.supportID
        };
        setEditSupportInfo(formValues);

    }

    const handleEditSupportSelect = (name, value) => {
        const newFormData = { ...editSupportInfo };
        if (value !== 'Select') {
            newFormData[name] = value;
            setEditSupportInfo(newFormData);
        }
    }

    const handleEditSupportUpdate = event => {
        event.preventDefault();

        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...editSupportInfo };
        newFormData[fieldName] = fieldValue;

        setEditSupportInfo(newFormData);
    }
    const handleEditToggleSupport = (event, isActive) => {
        const fieldName = event.target.getAttribute('name');
        const fieldValue = isActive
        const newFormData = { ...editSupportInfo };
        newFormData[fieldName] = fieldValue;
        setEditSupportInfo(newFormData)
    }

    const handleEditSupportSubmit = event => {
        event.preventDefault();
        const editedSupport = {
            id: editSupportID,
            start_time: editSupportInfo.start_time,
            end_time: editSupportInfo.end_time,
            language: editSupportInfo.language,
            valid_from: editSupportInfo.valid_from,
            valid_to: editSupportInfo.valid_to,
            mondays: (weekDaysBooleanHandle(editSupportInfo.mondays)),
            tuesdays: (weekDaysBooleanHandle(editSupportInfo.tuesdays)),
            wednesdays: (weekDaysBooleanHandle(editSupportInfo.wednesdays)),
            thursdays: (weekDaysBooleanHandle(editSupportInfo.thursdays)),
            fridays: (weekDaysBooleanHandle(editSupportInfo.fridays)),
            saturdays: (weekDaysBooleanHandle(editSupportInfo.saturdays)),
            sundays: (weekDaysBooleanHandle(editSupportInfo.sundays)),
            supportID: editSupportInfo?.supportID,
            status: 'Active',
            request: 'Completed',
            tooltip: null
        };
        const newSupports = [...supportData];
        let temp_arr = {
            language: editSupportInfo.language,
            valid_from: (editSupportInfo.valid_from),
            valid_to: (editSupportInfo.valid_to)
        };
        const isEmpty = Object.values(temp_arr).some(temp => (temp === null || temp === '' || temp === undefined));
        const isValidDate = moment((editSupportInfo.valid_to)).isAfter((editSupportInfo.valid_from));
        const validStartEndTime = () => {
            if (editSupportInfo.start_time && editSupportInfo.end_time)
                return moment(editSupportInfo.start_time, 'hh:mm').isBefore(moment(editSupportInfo.end_time, 'hh:mm'))
        }
        if (isEmpty) {
            setShowEditSupportModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ');
        }
        else if (!isValidDate) {
            setShowEditSupportModal(true);
            setValidation('Please ensure "Valid to" date is placed after "Valid from" date');
        }
        else if (!validStartEndTime) {
            setShowEditSupportModal(true);
            setValidation('Please ensure "Start Time"  is placed before "End Time"');
        }
        else {
            const index = supportData.findIndex((_, index) => index === editSupportID);
            newSupports[index] = editedSupport;
            setSupportData(newSupports);
            configureSupportData(newSupports);
            setEditSupportID(null);
            setShowEditSupportModal(false)
        }

    }

    const handleEditSupportCancel = event => {
        event.preventDefault();
        setEditSupportID(null);
        setShowEditSupportModal(false)
    }

    const handleDeleteSupport = () => {
        const newSupport = [...supportData];
        const index = supportData.findIndex((_, index) => index === deleteRowID);
        newSupport.splice(index, 1);
        setSupportData(newSupport);
        configureSupportData(newSupport);
        setShowDeleteSupportModal(false);
    }

    const handleDeleteSupportCancel = event => {
        event.preventDefault();
        setShowDeleteSupportModal(false)
    }
    useEffect(() => {
        props.dispatchSupportHandler(dispatchSupportObj);
    }, [dispatchSupportObj]);
    const [showReuqestModal, setShowReuqestModal] = useState(false);
    const [AccountSupportId, setAccountSupportId] = useState(null);
    const ActivationHandler = (id, data) => {
        if (data.request !== 'Sent') {
            setEditSupportID(id);
            setAccountSupportId(data?.supportID);
            setShowReuqestModal(true);
        }
    }

    const onSubmitRequest = (date) => {
        const temp_Arr = [{
            Account: accountSiteInfo.AccountName,
            Servicedesk: accountSiteInfo.SiteName,
            ValidUntil: date,
            SupportHoursId: AccountSupportId
        }]
        dispatch(updateSupportRequestStart(temp_Arr))
        console.log(temp_Arr)
        setShowReuqestModal(false);
        let temp_arr = [...supportData];
        temp_arr.map(temp => {
            if (temp.supportID === AccountSupportId) {
                temp.request = 'Sent';
                temp.tooltip = 'Activation Request already sent to Administrator'
                console.log(temp.supportID, AccountSupportId)
            }
        });
        setSupportData(temp_arr)

    }

    const ValidUser = () => {
         if(props.GeneralInfoToggle){
            setAddFormSupport([])
            setShowAddSupportModal(true)
            dispatch(setValidforAddingTabRows(false))
         }
         else{
            window.scrollTo(0, 0);
            dispatch(setValidforAddingTabRows(true))
         }
     }

    return (
        <div>
            <Fragment>
                <section className='search-container'>
                    <div className='search-bar' style={{ width: '265px', paddingLeft: 10, paddingRight: 10 }}>
                        <input className='search-input' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Support Hours' />
                        <button className='search-icon'><img src={searchIcon} alt='' /> </button>
                    </div>

                 {(RoleBasedView?.[0]?.SupportHours?.[0]?.ReadWrite === 0 && location.pathname === '/admin/account') ? '' : <> {(mode === 'create' || mode === 'edit') && <div className='add-queue' onClick={ValidUser}
                        style={{ cursor: 'pointer' }}>
                        <img src={supportIcon} alt='' />
                        <span className='add-queue-span'>Add Support Hours</span>
                    </div>}</>}


                </section>
            </Fragment>

            {searchRows(supportData).length === 0 ?

                <section>
                    <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                        <thead>
                            <tr>
                                {headers.map(header => (
                                    <th style={{ border: 'none' }} key={header.id}><span className='queue_header'>{header.caption}</span></th>
                                ))}
                                <th style={{ borderLeft: '0px' }}>{'  '}</th>
                            </tr>
                        </thead>
                    </table>
                    <section className='noData-table'>
                        <div className='noData-para' style={{ marginTop: 0 }}>
                            <div style={{ marginTop: 30 }}>
                                No records found. This data grid enables adding/editing the contractually defined support hours.<br /> To add a new Support Hours, select the “Add Support Hours” link. </div>
                        </div>
                    </section>


                </section> :
                <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                    <thead>
                        <tr>
                            {headers.map(header => (
                                <th style={{ border: 'none' }} key={header.id}><span className='queue_header'>{header.caption}</span></th>
                            ))}
                            <th style={{ borderLeft: '0px' }}>{'  '}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            searchRows(supportData).map((data, index) => {
                                return (
                                    <tr key={data.id}>
                                        <td className='supportTable_body'>{data.start_time}</td>
                                        <td className='supportTable_body'>{data.end_time}</td>
                                        <td className='supportTable_body'>{data.language}</td>
                                        <td className='supportTable_body'>{data.mondays}</td>
                                        <td className='supportTable_body'>{data.tuesdays}</td>
                                        <td className='supportTable_body'>{data.wednesdays}</td>
                                        <td className='supportTable_body'>{data.thursdays}</td>
                                        <td className='supportTable_body'>{data.fridays}</td>
                                        <td className='supportTable_body'>{data.saturdays}</td>
                                        <td className='supportTable_body'>{data.sundays}</td>
                                        <td className='supportTable_body'>{data.valid_from}</td>
                                        <td className='supportTable_body'>{data.valid_to}</td>
                                        {location.pathname === '/onboardAccount' ? <>
                                            {(mode === 'create' || mode === 'edit') && <td className='table-button' style={{ borderLeft: '0px' }}>
                                                <span onClick={() => onClickEditRow(index, data)}>
                                                    <TooltipHandler src={editIcon} width='14px' height='14px' textBoxWidth='100px' position='top center' arrowAlign='center' text='Edit' />
                                                </span>
                                                <span onClick={() => { setDeleteRowID(index); setShowDeleteSupportModal(true) }}>
                                                    <TooltipHandler src={deleteIcon} width='14px' height='14px' textBoxWidth='100px' position='top center' arrowAlign='center' text='Delete' /></span>
                                            </td>}</> :
                                           <>{RoleBasedView?.[0]?.SupportHours?.[0]?.ReadWrite === 1 ? <> {(mode === 'create' || mode === 'edit') && <td className='table-button'>  {data.status === 'Active' ?
                                                <span onClick={() => onClickEditRow(index, data)}>
                                                    <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' /></span>
                                                : <>
                                                    <span onClick={() => ActivationHandler(index, data)}>
                                                        <TooltipHandler src={data?.request === 'Sent' ? ActivateDisabledIcon : ActivateIcon} width='14px' height='14px' position='top center'
                                                            arrowAlign='center' textBoxWidth='300px'
                                                            text={data?.request === 'Sent' ? 'Request Already sent to Administrator' : ' Send Activation Request to Administrator'} />
                                                    </span>
                                                </>
                                            }</td>}</> : ''}</>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>}


            {showAddSupportModal && <ModalAccountPopup isOpen={showAddSupportModal} setShowModal={setShowAddSupportModal}>
                <AddSupportModal modelName='Add Support Hours' buttonOne='ADD' buttonTwo='CANCEL'
                    editMode={false} handleFormAddSupport={HandleAddSupport}
                    editSupportInfo={[initailState]}
                    handleAddSupportSubmit={handleAddSupportSubmit}
                    handleAddSupportCancel={handleAddSupportCancel}
                    addValueToSupport={addValueToSupport}
                    addDateToSupport={addValueToSupport}
                    handleToggleSupport={handleToggleSupport}
                    QueryHandler={() => { }}
                    validation={validation}
                    onClickSelect={() => { }}
                />
            </ModalAccountPopup>}

            {showEditSupportModal && <ModalAccountPopup isOpen={showEditSupportModal} setShowModal={setShowEditSupportModal}>
                <AddSupportModal modelName='Edit Support Hours' buttonOne='UPDATE' buttonTwo='CANCEL'
                    editMode={true} editSupportInfo={editSupportInfo}
                    handleFormAddSupport={handleEditSupportUpdate}
                    handleEditSupportSubmit={handleEditSupportSubmit}
                    handleAddSupportCancel={handleEditSupportCancel}
                    handleSupportEditDate={handleEditSupportSelect}
                    addValueToSupport={handleEditSupportSelect}
                    handleToggleSupport={handleEditToggleSupport}
                    QueryHandler={() => { }} validation={validation}
                    onClickSelect={onClickSelect}
                />
            </ModalAccountPopup>}
            {showDeleteSupportModal && <DeleteModalPopup isOpen={showDeleteSupportModal} setShowModal={setShowDeleteSupportModal}>
                <DeleteFragment modalName='Delete Support Hours ' buttonOne='YES' buttonTwo='NO' delete={true}
                    handleDeleteQueue={handleDeleteSupport}
                    handleDeleteQueueCancel={handleDeleteSupportCancel}
                    supportName={supportData[supportData.findIndex((_, index) => index === deleteRowID)]}
                />
            </DeleteModalPopup>}
            {
                showReuqestModal && <DeleteModalPopup isOpen={showReuqestModal} setShowModal={setShowReuqestModal}>
                    <AdminValidDateChange
                        requestHeading="Request for Support Hours Re-Activation" title1='Start Time - End Time' title2='Language'
                        solution1={`${supportData[supportData.findIndex((_, index) => index === editSupportID)]?.start_time} - ${supportData[supportData.findIndex((_, index) => index === editSupportID)]?.end_time}`}
                        solution2={supportData[supportData.findIndex((_, index) => index === editSupportID)]?.language}
                        onSubmit={onSubmitRequest} notValidDate = {() => { setShowReuqestModal(true) }}
                        onCancel={() => { setShowReuqestModal(false) }}
                    />
                </DeleteModalPopup>
            }
        </div>
    )
}
export default SupportHours;