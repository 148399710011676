import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getAuditRoleData, getRegionData } from '../../../../actions/Administration/adminView.action';
import { getGeneralInfoSites, getRolesTabRoles, getRolesTabUsers } from '../../../../actions/Onboard.action';
import { getStaffAccounts } from '../../../../actions/Administration/staffAdmin.action';
import './Audit.css';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import Filter_Icon from './../../../../assets/images/Filter_Icon.png';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';
import RefreshIcon from './../../../../assets/images/Reset_Icon.png';
import Button from '../../../ReportTables/ReportFilters/Button';
import { TimeOutSelect } from '../../../Onboard/OnboardComponents/Dropdown/TimeOutSelect';




const headers = [
  {
    caption: "Role",
    id: "1"
  }, {
    caption: "User",
    id: "2"
  },
  {
    caption: "Account",
    id: "3"
  },
  {
    caption: "Site",
    id: "4"
  }, {
    caption: "Region",
    id: "5"
  },
  {
    caption: "Valid From",
    id: "6"
  },
  {
    caption: "Valid To",
    id: "7"
  }
]


const AuditRole = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let Rolename = useSelector((state) => state.onboardReducer.roleTabRoles);
  let usernameData = useSelector((state) => state.onboardReducer.roleTabUsers);
  const SiteData = useSelector((state) => state.onboardReducer.generalSiteInfo);
  const AccountsInfo = useSelector((state) => state.staffAdminReducer.staffAccounts);
  const regionData = useSelector((state) => state.adminViewReducer.regionData);
  let auditRoleTableData = useSelector((state) => state.adminViewReducer.auditRoleData);
  useEffect(() => {
    dispatch(getRolesTabRoles());
    dispatch(getGeneralInfoSites());
    dispatch(getStaffAccounts());
    dispatch(getRegionData());
    dispatch(getRolesTabUsers(''));

  }, [dispatch]);

  const emailIDhandler = (username) => {
    if (username) {
        let emailObj = usernameInfo?.filter(info => info?.nameEmail === username);
        return emailObj?.[0]?.email || '';
    }
    else return '';
}

  useEffect(() => {
    setUsernameInfo(usernameData)
}, [usernameData])
const QueryHandler = query => {
    setTimeout(() => {
        dispatch(getRolesTabUsers(query));
        setUsernameInfo(usernameData)
    }, 2000)
    if (query === '') {
        dispatch(getRolesTabUsers(''));
    }
}

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [month, day, year].join('/');
}


  const [selectedRole, setSelectedRole] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [selectedSite, setSelectedSite] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [isRefreshClicked, setIsRefreshedClicked] = useState(false);
  const [usernameInfo, setUsernameInfo] = useState([]);
  const [auditRoleData, setAuditRoleData] = useState([]);

  console.log('setSelectedUser',selectedUser)

  useEffect(() => {
    if(selectedRole || selectedUser || selectedAccount || selectedSite || selectedRegion){
    dispatch(getAuditRoleData(selectedRole ? selectedRole : '',selectedAccount ? selectedAccount :'', selectedSite ? selectedSite : '', selectedUser ? (selectedUser) : '',selectedRegion ? selectedRegion : ''));
    }
  }, [dispatch,selectedRole,selectedUser,selectedAccount,selectedSite,selectedRegion]);


useEffect(()=>{
  if(selectedRole === undefined && selectedUser === undefined && selectedAccount === undefined  && selectedSite === undefined && selectedRegion === undefined){
    setAuditRoleData([]);
  }
  else{
    setAuditRoleData(auditRoleTableData)
  }
},[auditRoleTableData, selectedAccount, selectedRegion, selectedRole, selectedSite, selectedUser])


  return (
    <section>
      <header>
        <AdminBreadcrumb value1={() => history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={() => history.push('/adminviewlanding')}
          isActive3={false} label3='Audit Role'
        />
        <main className='administration_text'>Audit Role</main>
      </header>


      <section className='Filter_RBAC'>
        <main className='audit_filter_img'>
          <img src={Filter_Icon} alt='' style={{ padding: '20px', paddingTop: '70px', paddingLeft: '30px' }} />
        </main>
        <div className='auditrole_dropdown'>
          <main className='Audit_dropdown'>
            <label HtmlFor='country'>Role</label>
            <OnboardSelect data={Rolename} name='Role' value='RoleName' id='RoleId' marginTop='10px'
              editValue='' addValueToQueue={(name, value) => { setSelectedRole(value);setIsRefreshedClicked(false) }} refresh={isRefreshClicked}/>
          </main>
          <main className='Audit_dropdown'>
          <label HtmlFor='username'>User</label>
                                <TimeOutSelect data={usernameInfo} name='username' value='nameEmail' id='id'
                                    QueryHandler={QueryHandler}
                                    editValue=''
                                    addValueToQueue={(name, value) => { setSelectedUser(emailIDhandler(value));setIsRefreshedClicked(false) }} refresh={isRefreshClicked}/>
          </main>
          <main className='Audit_dropdown'>
            <label HtmlFor='country'>Account</label>
            <OnboardSelect data={AccountsInfo} name='Customer' value='customerName' id='Id' marginTop='10px'
              editValue='' addValueToQueue={(name, value) => { setSelectedAccount(value);setIsRefreshedClicked(false) }} refresh={isRefreshClicked}/>
          </main>
          <main className='Audit_dropdown'>
            <label HtmlFor='country'>Site</label>
            <OnboardSelect data={SiteData} name='Servicedesk' value='SiteName' id='SiteId' marginTop='10px'
              editValue='' addValueToQueue={(name, value) => { setSelectedSite(value);setIsRefreshedClicked(false) }} refresh={isRefreshClicked}/>
          </main>
          <main className='Audit_dropdown'>
            <label HtmlFor='country'>Region</label>
            <OnboardSelect data={regionData} name='Region' value='Region' id='Id' marginTop='10px'
              editValue='' addValueToQueue={(name, value) => { setSelectedRegion(value);setIsRefreshedClicked(false) }} refresh={isRefreshClicked}/>
          </main>
        </div>
        <main><div className='container_reset_request_admin' >
          <Button className={`reset-icon download-btn `} item='Refresh Table'
            onClick={(event) => {
              setSelectedRole(undefined);
              setSelectedUser(undefined);
              setSelectedAccount(undefined);
              setSelectedSite(undefined);
              setSelectedRegion(undefined);
              setAuditRoleData([]);
              setIsRefreshedClicked(true)
            }} icon={RefreshIcon} /></div>
        </main>

      </section>

      {auditRoleData.length === 0 ? <div className='Audit_nodata_card'>No records found.
           <br/> Use the filter options above to get relevant data.</div> : 
           
           <table style={{ width: '98%', marginTop: '40px' }} className='audit_table'>
            <thead>
            {headers.map(header => (
                            <th  key={header.id}><span >{header.caption}</span></th>
                        ))
                        }
            </thead>
            <tbody>
            {
               (auditRoleData).map((data, index) => (
                <tr>
                    <td >{data.role}</td>
                    <td >{data.user}</td>
                    <td>{data.account}</td>
                    <td >{data.site}</td>
                    <td >{data.region}</td>
                    <td>{data.validSince === null ? ''  : formatDate(data.validSince)}</td>
                    <td >{data.validUntil === null ? '' : formatDate(data.validUntil)}</td>
                </tr>
                ))
                    }
            </tbody>
        </table>}
    </section>
  )
}

export default AuditRole