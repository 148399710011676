import React from "react";
import "./analytics.css";
import { Chart } from "react-chartjs-2";

const Analytics = (props) => {
  let lbls = [], datasetsVal=[];
  if(props && props.data && props.data['trend']){
    props.data['trend'].map((itm, idx)=>{
        if(idx == 0)
        return itm.Data.map(itms=>lbls.push(itms['YearMonth']));
    });
  }

  if(props && props.data && props.data['pfLoss']){
    props.data['pfLoss'].map((itm, idx)=>{   
       let obj =  {
        type: "line",
        label: "",
        borderColor: itm.background,
        borderWidth: 2,
        borderDash: [10, 5],
        fill: false,
        data: [],
        order: idx,
        yAxisID: 'linemlns'
        };
        itm.Data.map(itms=>obj.data.push(itms['value']));
        datasetsVal.push(obj);
    });
  }

  if(props && props.data && props.data['trend']){
    props.data['trend'].map((itm, idx)=>{   
       let obj =  {
            type: "bar",
            label: "",
            backgroundColor: itm.background,
            data: [],
            borderColor: itm.background,
            borderWidth: 2,
            order: idx,
            yAxisID: 'y'
          };
        itm.Data.map(itms=>obj.data.push(parseFloat(itms['PLTrend'])));
        datasetsVal.push(obj);
    });
  }
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: {weight: '400'},
        displayColors: false,
        padding: 10,
        cornerRadius: 0,
        caretSize:5,
        bodyFont: {weight: 'bolder'},
        callbacks: {
          label: (context) => {
            if(context && context.dataset.type === 'bar'){ 
              let label = "";             
              if (context.parsed.y) {
                label = context.parsed.y + "%"
              }
              return label;
            }else{
              return context.parsed.y;
            } 
          }
        }
      }
    },
    scales: {    
      x: {
        stacked: false,
        title:{
          display: true,
          text: `Year/Month`
        }
    },  
      y: {
        beginAtZero: true,
        stacked: false,
        type: 'linear',
        position: 'left',
        title:{
          display: true,
          text: `${props?.yaxisfname}`
        },
        ticks: {
          callback: function(value, index, values){
            return `${value} ${props?.prcnttxt}`;
          }
        }      
    },
      linemlns:{
        beginAtZero: true,
              stacked: false,
              type: 'linear',
              position: 'right',
              title:{
                display: true,
                text: `${props?.yaxissname}`
              },
              grid: {
                drawOnChartArea: false
              }              
      }
    }
  };

  const data = {
    labels:lbls,
    datasets: datasetsVal        
  };

  return (
            <div className="graph-container">
              <Chart data={data} options={options} />;
            </div>
          );
};

export default Analytics;
