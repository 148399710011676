import React from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Database from './Database';
import MsTeams from './MsTeams';
import { Footer } from '../HeaderFooter';

const DesignCards = () => {
    const history = useHistory();
    const designCard = useSelector(state => state.githubReducer.designCard)
    const DesignCardDisplay = () => {
        switch (designCard.text) {
            case 'database': return <Database />
            case 'msteams': return <MsTeams />
            default: { }
        }
    }
  return (
    <>
        <section style={{ marginTop: 0, marginLeft: -100 }}>
                <div className='breadcrumb_dev'>
                    <section>
                        <span style={{ color: '#5F249F', cursor: 'pointer' }} onClick={() => history.push('/githublanding')}>Home</span>
                        <span style={{ color: '#5F249F', cursor: 'pointer' }} onClick={() => history.push('/design')} >{` / `} Design</span>
                        <span style={{ color: '#63666A' }}>{` / `} {designCard.title}</span>
                    </section>
                    {/* <section><DeveloperOffcanvas/></section> */}
                </div>
                {DesignCardDisplay()}
                <Footer />
            </section>
    </>
  )
}

export default DesignCards