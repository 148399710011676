
const actionTypes = {
  GET_USER_TOKEN: "GET_USER_TOKEN",
  USER_TOKEN: "USER_TOKEN",
  GET_USER_DATA: "GET_USER_DATA",
  USER_DATA: "USER_DATA",
  GET_MONTH_TAB_DATA: "GET_MONTH_TAB_DATA",
  MONTH_TAB_DATA: "MONTH_TAB_DATA",
  GET_SUMMARY_TAB_DATA: "GET_SUMMARY_TAB_DATA",
  SUMMARY_TAB_DATA: "SUMMARY_TAB_DATA",
  GET_WEEK_TAB_DATA: "GET_WEEK_TAB_DATA",
  WEEK_TAB_DATA: "WEEK_TAB_DATA",
  GET_DAY_TAB_DATA: "GET_DAY_TAB_DATA",
  DAY_TAB_DATA: "DAY_TAB_DATA",
  GET_ANNUAL_TAB_DATA: "GET_ANNUAL_TAB_DATA",
  ANNUAL_TAB_DATA: "ANNUAL_TAB_DATA",
  UPDATE_SELECTED_TAB_NAME: "UPDATE_SELECTED_TAB_NAME",
  UPDATE_SELECTED_KEY_NAME: "UPDATE_SELECTED_KEY_NAME",
  GET_REGIONAL_DATA: "GET_REGIONAL_DATA",
  REGIONAL_DATA: "REGIONAL_DATA",
  UPDATE_SELECTED_ACCOUNT_NAME: "UPDATE_SELECTED_ACCOUNT_NAME",
  GET_HOME_FEED_DATA: "GET_HOME_FEED_DATA",
  HOME_FEED_DATA: "HOME_FEED_DATA",
  SAVE_SELECTED_MENU_ITEM: "SAVE_SELECTED_MENU_ITEM",
  SAVE_ACCOUNT_LOCATION_NAME: "SAVE_ACCOUNT_LOCATION_NAME",
  GET_FINANCE_DATA: "GET_FINANCE_DATA",
  FINANCE_DATA:"FINANCE_DATA",
  GET_DROPDOWN_DATA:"GET_DROPDOWN_DATA",
  DROPDOWN_DATA:"DROPDOWN_DATA",
  GET_ACCOUNT_DATA:"GET_ACCOUNT_DATA",
  ACCOUNT_DATA : "ACCOUNT_DATA",
  GET_REGIONALSITE_DATA: "GET_REGIONALSITE_DATA",
  REGIONALSITE_DATA: "REGIONALSITE_DATA",
  GET_AVERAGE_HANDLE_TIME:"GET_AVERAGE_HANDLE_TIME",
  AVERAGE_HANDLE_TIME:"AVERAGE_HANDLE_TIME",
  GET_ABANDON_RATE:"GET_ABANDON_RATE",
  ABANDON_RATE:"ABANDON_RATE",
  GET_DIGITAL_TRANS:"GET_DIGITAL_TRANS",
  DIGITAL_TRANS:"DIGITAL_TRANS",
  GET_COST_PER_CONTACT:"GET_COST_PER_CONTACT",
  COST_PER_CONTACT:"COST_PER_CONTACT",
  GET_CONTACT_FTE_DATA: "GET_CONTACT_FTE_DATA",
  CONTACT_FTE_DATA:"CONTACT_FTE_DATA",
  UPDATE_CALENDAR_DATE_FORMAT: "UPDATE_CALENDAR_DATE_FORMAT",
  UPDATE_CALENDAR_ISOSTRING_FORMAT: "UPDATE_CALENDAR_ISOSTRING_FORMAT",
  UPDATE_CALENDAR_DATE: "UPDATE_CALENDAR_DATE",
  UPDATE_BREADCRUMB: "UPDATE_BREADCRUMB",
  GET_PENALTY_AT_RISK:"GET_PENALTY_AT_RISK",
  PENALTY_AT_RISK:"PENALTY_AT_RISK",
  GET_FIRST_CONTACT_RESOLUTION:"GET_FIRST_CONTACT_RESOLUTION",
  FIRST_CONTACT_RESOLUTION:"FIRST_CONTACT_RESOLUTION",
  GET_ATTRITION_DATA:"GET_ATTRITION_DATA",
  ATTRITION_DATA:"ATTRITION_DATA",
  GET_SLA_COMPLIANCE: "GET_SLA_COMPLIANCE",
  SLA_COMPLIANCE:"SLA_COMPLIANCE",
  GET_CSAT_DATA:"GET_CSAT_DATA",
  CSAT_DATA:"CSAT_DATA",
  GET_DELIVERY_SUPPORT:"GET_DELIVERY_SUPPORT",
  DELIVERY_SUPPORT:"DELIVERY_SUPPORT",
  GET_CONTACT_VOLUME_INFO: "GET_CONTACT_VOLUME_INFO",
  CONTACT_VOLUME_INFO:"CONTACT_VOLUME_INFO",
  GET_DROPDOWN_INFO:"GET_DROPDOWN_INFO",
 DROPDOWN_INFO:"DROPDOWN_INFO",
 GET_ACCOUNT_INFO:"GET_ACCOUNT_INFO",
 ACCOUNT_INFO:"ACCOUNT_INFO",
 TOGGLE_SETTINGS: "TOGGLE_SETTINGS",
 SAVE_ACCOUNT_DRPDWN_LIST_DATA: "SAVE_ACCOUNT_DRPDWN_LIST_DATA",
 SAVE_LOCATION_DRPDWN_LIST_DATA: "SAVE_LOCATION_DRPDWN_LIST_DATA",
 UPDATE_DTM_REPORT_DATE: "UPDATE_DTM_REPORT_DATE"
};

export default actionTypes;
