import React, { useEffect, useRef, useState } from 'react';
import './AccountSelect.css';
import downArrow from "../../../assets/images/downward_arrow.png";
import upArrow from "../../../assets/images/upward_arrow.png";
import '../../ReportTables/ReportFilters/Dropdown.css';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountDropdown, getMultiAccountWidgets, getStoredAccount, saveSelectedAccountObj, updateStoredAccountStart} from '../../../actions/welcomePage.action';

function AccountDropdown(props) {
    const dispatch = useDispatch();
    let AccountDropdown = useSelector((state) => state.welcomePageReducer.AccountDropdown);
    // let storedAccount = useSelector((state) => state.welcomePageReducer.storedAccount);
    // console.log('storedAccount',storedAccount)
    console.log('multiAccountsInfo',AccountDropdown)
    let DropdownOptions = AccountDropdown.filter((el) =>{
        return el.Widgets[1]?.Selected === true || el.Widgets[0]?.Selected === true
      }
      );

    let selectedAccountObj = useSelector((state) => state.welcomePageReducer.selectedAccountObj);
    const [openData, setOpenData] = useState(false);
    const [query, setQuery] = useState('');
    const [selectedOption, setSelectedOption] = useState();


    // let ffff = (Object.keys(selectedAccountObj).length === 0 ? ({CustomerID: 1, CustomerName: '3M China', ServiceDeskID: 22, ServiceDeskName: 'Dalian, China', Widgets: [
    //     {WidgetID: 10, Name: 'Quality', Description: 'provides contractual service level and/or key perf…/escalations/interactions/Net-Promoter-Score/etc]', Selected: true},
        
    //     {WidgetID: 1, Name: 'Telephony', Description: 'provides contractual service level and/or key perf…ators for telephony channel [volumes/ASA/ABN/etc]', Selected: true}]}) : selectedAccountObj)

    //     let jjjj = (Object.keys(selectedAccountObj).length === 0 ? (DropdownOptions?.[0]) : selectedAccountObj)
    

   
    useEffect(() => {
        dispatch(getAccountDropdown())
        dispatch(getStoredAccount())
    }, [dispatch]);
    const filterQuery = options => {
        return options.filter(option => {
            if (query === '') return option;
            else if (option.CustomerName.toLowerCase().includes(query.toLowerCase().trim()))
                return option;
        })
    }
    useEffect(() => {
        if (!openData) setQuery('')
    }, [openData]);

    const ref = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                if (!openData)
                    setOpenData(openData);
            }
        }

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [ref]);

    

    // useEffect(() => {
    //     dispatch(getAccountDropdown())
    //     dispatch(getStoredAccount())
    //     let storedAccountFilter = AccountDropdown.filter((el) =>{
    //         return el.CustomerName === selectedAccountObj.CustomerName && el.ServiceDeskName === selectedAccountObj.ServiceDeskName
    //       }
    //       );
    //       console.log('storedAccount2',storedAccountFilter)
    //     let ffff = (storedAccountFilter.length === 0 ? ({}) : storedAccountFilter?.[0])
    //   //  props.dropdownSelectHandle(ffff)
        
        
    // }, [selectedOption]);

    console.log('selectedOption',selectedOption)
 
 useEffect(()=>{
    if(selectedOption){
     dispatch(updateStoredAccountStart({account : selectedOption.CustomerName, site : selectedOption.ServiceDeskName}));
     dispatch(getStoredAccount())
    }
 },[dispatch,selectedOption])

 useEffect(()=>{
    dispatch(getStoredAccount())
 },[dispatch,selectedOption])
   
    const WidgetNoHandler=(account)=>{
      let temp_Arr=  account.Widgets.filter(widget=>widget.Selected===true)
      return temp_Arr.length>0? temp_Arr.length+' Widgets':''
    }

   
    
    
    return (

        <React.Fragment>
            <section className='account_dropdown'>
                <div className='select_account'>  Select Account</div>
                <section className='dropdown_container' >
                    <div className=' wrapper' >
                        <input className='input-field' type='text' placeholder='Search' value={query}
                            onChange={e => { setQuery(e.target.value); setOpenData(true) }}
                            onClick={() => setOpenData(!openData)}
                        />
                        <div className='button-arrow arrow-btn'
                            onClick={() => setOpenData(!openData)}
                            ref={ref}
                        >
                            {!openData ? <img src={downArrow} alt="arrow" /> :
                                <img src={upArrow} alt="arrow" />}
                        </div>
                    </div>
                    <div>
                        {openData && <ul className={`ul-wrapper`}>
                         {/* {filterQuery(multiAccountsInfo)?.map((account, i) =>
                             (<li value={account?.CustomerName} key={account?.CustomerID} */}
                         {filterQuery(DropdownOptions)?.map((account, i) =>
                            (<li value={account?.CustomerName} 
                                className='list-wrapper' id='id-hover'
                                 style={{ cursor:'pointer'}}
                                onClick={() => {
                                    setQuery('')
                                    dispatch(getMultiAccountWidgets())
                                    setSelectedOption(account)
                                    console.log('dddddddddddddddddd',account)
                                    dispatch(saveSelectedAccountObj(account))
                                    // props.dropdownSelectHandle(account)
                                }}  >
                                {/* <span className='label-region'>{account.CustomerName} </span> */}
                                <span className='label-region'>{account.CustomerName} ({account.ServiceDeskName}) </span>
                                <span className='label-region_two'> {WidgetNoHandler(account)} </span>
                            </li>)
                            )}
                        </ul>}
                    </div>
                </section>
            </section>
        </React.Fragment>
    )
}

export default AccountDropdown;