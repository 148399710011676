import React, { useEffect, useState } from 'react';
import "./Preview";
import InformationIcon from "./../../../../assets/images/Information_Icon.png";
import ToolTip from 'react-power-tooltip';
import TooltipHandler from '../TooltipHandler';

const Toggle = (props) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <>
            <span>
                <label class="toggle">
                    <input type="checkbox" checked={props.value} />
                    <div class="slider_on"></div>
                    <span class="labels" data-on="YES" data-off="NO"></span>
                </label>
            </span>
            <span style={{ position: "relative" }}><img src={InformationIcon} alt='none' className='i_img'
                onMouseOver={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} ></img>
                <ToolTip show={showTooltip} textAlign="left" arrowAlign="start" moveRight='20px'
                    position="bottom right" textBoxWidth="600px" borderRadius='0px' fontSize='14/19' >
                    <span >The toggle button automatically goes to "YES" status, once you have made the changes in this section.
                      Assuming you have fully completed all your desired changes; hereby, you are granting us the approval to proceed with the onboarding.
                      Once submitted only the administrator can reopen the onboarding.</span>
                </ToolTip>
            </span>
        </>
    )
};
export default Toggle