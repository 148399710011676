import React, { useEffect, useState } from 'react';
import rolesImage from './../../../assets/onboard-images/Roles_Normal_Icon.png'
import rolesHoverImage from './../../../assets/onboard-images/Roles_Hover_and_Selected_Icon.png'
import voiceImage from './../../../assets/onboard-images/Voice_Normal_Icon.png'
import voiceHoverImage from './../../../assets/onboard-images/Voice_Hover_and_Selected_Icon.png'
import nonVoiceImage from './../../../assets/onboard-images/NonVoice_Normal_Icon.png'
import nonVoiceHoverImage from './../../../assets/onboard-images/NonVoice_Hover_and_Selected_Icon.png'
import previewImage from './../../../assets/onboard-images/Preview_Normal_Icon.png'
import previewHoverImage from './../../../assets/onboard-images/Preview_Hover_and_Selected_Icon.png'
import GeneralInfoImage from './../../../assets/admin-images/GeneralInfo_Normal.png'
import GeneralInfoHoverImage from './../../../assets/admin-images/GeneralInfo_Selected.png'
import VoiceHandler from '../VoiceOnboard/VoiceHandler';
import RoleComponent from '../OnboardComponents/RolesComponent/Rolescomponent';
import GeneralInfo from '../GeneralInfo/GeneralInfo';
import NonVoiceHandler from '../NonVoice/NonVoiceHandler';
import { useHistory, useLocation } from 'react-router-dom';
import closeIcon from './../../../assets/onboard-images/CloseWindow_Hover_State.png'
import './Navigationbar.css'
import PreviewHandler from '../OnboardComponents/Preview/Preview';
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import dataSaveClose_icon from "./../../../assets/onboard-images/dataSaveClose_icon.png";
import dataSave_icon from "./../../../assets/onboard-images/dataSave_icon.png";
import { SuccessFragment } from '../OnboardComponents/Modals/DeleteModalPopup';
import { useDispatch, useSelector } from 'react-redux';

import {
  getGeneralNonVoice,
  getNonVoiceSLAData, getNonVoiceSupportHours, getOnboardValidUser, getPreviewOnboard, getQueueTableData, getRoleTabData, getSLAKPIData, getSupportHoursVoice,
  setWidgetCardNonVoice,
  updateOnboardSubmitStart, updateOnboardToggleStart, updateRoleDataStart, updateSLADataStart,
  updateSupportHoursStart
} from '../../../actions/Onboard.action';
import { updateQueueTableStart } from './../../../actions/Onboard.action';
import { CancelFragment, CancelModalPopup, NextButtonFragment, SuccessModalPopup } from '../OnboardComponents/Modals/OnboardButtonsPopup';
import { getAdminQueueTableData, getAdminSlaTableData, getAdminSupportTableData, getAdminVoiceSlaTableData, getAdminVoiceSupportTableData, getPreviewAdmin, updateAdminQueueTableDataStart, updateAdminSlaTableDataStart, updateAdminSupportTableDataStart, updateAdminUpdateStart } from '../../../actions/Administration/admin.action';



function Image({ onClick, src, children, onMouseEnter, onMouseLeave }) {
  return <>
    <button className={'nav-btn'} onClick={onClick} onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} >
      <img className='nav-image' src={src} alt='' />
      {children}
    </button>
  </>
}

const BUTTONS = [
  { id: 1, label: 'PREVIOUS' },
  { id: 2, label: 'SAVE' },
  { id: 3, label: 'CANCEL' },
  { id: 4, label: 'NEXT' },
  { id: 5, label: 'SUBMIT' },
  { id: 6, label: 'UPDATE' },
]


function Navigationbar() {
  const dispatch = useDispatch();
  const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
  let roletabdata = useSelector((state) => state.onboardReducer.roleTabData);
  const QueueTableData = useSelector((state) => state.onboardReducer.QueueTableData);
  const SLAKPIData = useSelector((state) => state.onboardReducer.SLAKPIData);
  const supportHoursVoice = useSelector((state) => state.onboardReducer.supportHoursVoice);
  const NonVoiceSLAdata = useSelector((state) => state.onboardReducer.NonVoiceSLAdata);
  const NonVoiceSupportHours = useSelector((state) => state.onboardReducer.NonVoiceSupportHours);
  const GeneralNonvoicedata = useSelector((state) => state.onboardReducer.GeneralNonVoice);
  const accountDetails = useSelector((state) => state.onboardReducer.viewAccounts);
  const rolesUpdateResponse = useSelector((state) => state.onboardReducer.rolesUpdateResponse);
  const queuesUpdateResponse = useSelector((state) => state.onboardReducer.queuesUpdateResponse);
  const slaUpdateResponse = useSelector((state) => state.onboardReducer.slaUpdateResponse);
  const supportUpdateResponse = useSelector((state) => state.onboardReducer.supportUpdateResponse);
  const widgetCard = useSelector((state) => state.onboardReducer.widgetCardNonVoice);
  const validforaddingtabrows = useSelector((state) => state.onboardReducer.validforaddingtabrows);
  console.log('validforaddingtabrows',validforaddingtabrows)
  

  const AdminQueueTableData = useSelector((state) => state.adminAccountReducer.AdminQueueTableData);
  const AdminSlaTableData = useSelector((state) => state.adminAccountReducer.AdminSlaTableData);
  const AdminSupportTableData = useSelector((state) => state.adminAccountReducer.AdminSupportTableData);
  const AdminVoiceSlaTableData = useSelector((state) => state.adminAccountReducer.AdminVoiceSlaTableData);
  const AdminVoiceSupportTableData = useSelector((state) => state.adminAccountReducer.AdminVoiceSupportTableData);
  const onboardValidUser = useSelector((state) => state.onboardReducer.onboardValidUser);
  const previewOnboardData = useSelector((state) => state.onboardReducer.previewOnboardData);
  const [generalTab, setGeneralTab] = useState('roles');

  const [rolesImgSrc, setRolesImgSrc] = useState();
  const [voiceImgSrc, setVoiceImgSrc] = useState();
  const [nonVoiceImgSrc, setNonvoiceImgSrc] = useState();
  const [previewImgSrc, setPreviewImgSrc] = useState();
  const [showButtons, setShowButtons] = useState([0, 1, 1, 1, 0, 0]);
  const [dataSaveInfo, setDataSaveInfo] = useState(false);
  const [accountExists, setAccountExists] = useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [updatedRoleData, setUpdatedRoleData] = useState([]);
  const [updatedQueueData, setUpdatedQueueData] = useState([]);

  const [updatedAccountData, setUpdatedAccountData] = useState([]);
  const [updatedSupportData, setUpdatedSupportData] = useState([]);
  const [updatedGeneralData, setUpdatedGeneralData] = useState([]);
  const [updatedFileData, setUpdatedFileData] = useState()
  const [previewContract, setPreviewContract] = useState(false);
  const [GeneralInfoToggle, setGeneralInfoToggle] = useState(false);
  const [previewSubmitBar, setPreviewSubmitBar] = useState(false);
  const [previewContractBar, setPreviewContractBar] = useState(false);
  const [accountDetailsInfo, setAccountDetailsInfo] = useState({
    AccountName: null,
    SiteName: null,
    ValidFrom: new Date(''),
    ValidTo: new Date(''),
    mode: 'create',
  });

  const history = useHistory();
  const location = useLocation();
  const dispatchNavigationHandle = () => {
    if (location.pathname === '/onboardAccount') {
      dispatch(getSLAKPIData(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getRoleTabData(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getQueueTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getSupportHoursVoice(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getNonVoiceSupportHours(accountSiteInfo.AccountName, accountSiteInfo.SiteName, widgetCard.card));
      dispatch(getGeneralNonVoice(accountSiteInfo.AccountName, accountSiteInfo.SiteName, widgetCard.card))
      dispatch(getNonVoiceSLAData(accountSiteInfo.AccountName, accountSiteInfo.SiteName, widgetCard.card));
    }
    else {
      dispatch(getAdminVoiceSlaTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getRoleTabData(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getAdminQueueTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getAdminVoiceSlaTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
      dispatch(getAdminVoiceSupportTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName, generalTab, widgetCard?.card));
      dispatch(getGeneralNonVoice(accountSiteInfo.AccountName, accountSiteInfo.SiteName, widgetCard?.card))
      dispatch(getAdminSlaTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName, generalTab, widgetCard?.card));
    }
  }
  useEffect(() => {
    dispatchNavigationHandle()
    dispatch(getPreviewOnboard(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
  }, [dispatch])

  const ObjectEquateHandle = (data, updatedData) => {
    if (data?.length === updatedData?.length) {
      const eq = JSON.stringify(data) === JSON.stringify(updatedData)
      return eq;
    }
    else {
      return false;
    }
  }

  useEffect(() => {
    switch (generalTab) {
      case 'roles': {
        dispatch(updateRoleDataStart())
        setUpdatedQueueData([])
        setUpdatedAccountData([]);
        setUpdatedSupportData([]);
        setRolesImgSrc(rolesHoverImage);
        setPreviewImgSrc(location.pathname === '/onboardAccount' ? previewImage : GeneralInfoImage);
        setNonvoiceImgSrc(nonVoiceImage);
        setVoiceImgSrc(voiceImage);
        setShowButtons([0, 1, 1, 1, 0, 0]);
        dispatchNavigationHandle();
        break;
      }
      case 'voice': {
        dispatch(updateRoleDataStart())
        setUpdatedRoleData([])
        setUpdatedQueueData([])
        setUpdatedAccountData([]);
        setUpdatedSupportData([]);
        setRolesImgSrc(rolesImage);
        setPreviewImgSrc(location.pathname === '/onboardAccount' ? previewImage : GeneralInfoImage);
        setNonvoiceImgSrc(nonVoiceImage);
        setVoiceImgSrc(voiceHoverImage);
        setShowButtons([1, 1, 1, 1, 0, 0]);
        dispatchNavigationHandle();
        break;
      }
      case 'nonvoice': {
        dispatch(updateRoleDataStart())
        setUpdatedRoleData([])
        setUpdatedQueueData([])
        setUpdatedAccountData([]);
        setUpdatedSupportData([]);
        setRolesImgSrc(rolesImage);
        setPreviewImgSrc(location.pathname === '/onboardAccount' ? previewImage : GeneralInfoImage);
        setNonvoiceImgSrc(nonVoiceHoverImage);
        setVoiceImgSrc(voiceImage);
        widgetCardHandler()
        dispatchNavigationHandle()
    dispatch(getPreviewAdmin(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
        break;
      }
      case 'preview': {
        dispatch(updateRoleDataStart())
        setUpdatedRoleData([])
        setUpdatedQueueData([])
        setUpdatedAccountData([]);
        setUpdatedSupportData([]);
        setRolesImgSrc(rolesImage);
        setPreviewImgSrc(location.pathname === '/onboardAccount' ? previewHoverImage : GeneralInfoHoverImage);
        setNonvoiceImgSrc(nonVoiceImage);
        setVoiceImgSrc(voiceImage);
        location.pathname === '/onboardAccount' ? setShowButtons([1, 0, 0, 0, 1, 0]) : setShowButtons([1, 0, 0, 0, 0, 1]);
        dispatchNavigationHandle()
        break;
      }
      default: { }
    }
  }, [generalTab]);
  const [showInfoBar, setShowInfoBar] = useState(false);

const GeneralInfoToggleWithPath = location.pathname === '/onboardAccount' ? GeneralInfoToggle : true

  useEffect(() => {
    if (accountDetails) {
      (accountDetails.mode === 'create') ? setShowInfoBar(false) : setShowInfoBar(true);
      if (accountDetails.mode === 'edit' || accountDetails.mode === 'view') {
        setGeneralTab('roles')
      }
      else {
        setUpdatedRoleData([])
        setGeneralTab('preview')
      }
    }

    setTimeout(() => {
      setShowInfoBar(false)
    }, 10000)
  }, [accountDetails])
  const widgetCardHandler = (widget) => {
    switch (widget) {
      case 'nonvoice': setShowButtons([1, 0, 1, 1, 0, 0]); break;
      case 'widget': setShowButtons([1, 1, 1, 1, 0, 0]); break;
      default: setShowButtons([1, 0, 1, 1, 0, 0]);
    }
  }
  const onboardTabHandler = () => {
    // if (updatedRoleData?..length > 0 || updatedQueueData.length > 0 || updatedAccountData.length > 0 || updatedSupportData.length > 0) {
    //   dispatch(updateRoleDataStart(updatedRoleData));
    //   location.pathname === '/onboardAccount' ? dispatch(updateQueueTableStart(updatedQueueData)) : dispatch(updateAdminQueueTableDataStart(updatedQueueData));
    //   location.pathname === '/onboardAccount' ? dispatch(updateSLADataStart(updatedAccountData)) : dispatch(updateAdminSlaTableDataStart(updatedAccountData));
    //   location.pathname === '/onboardAccount' ? dispatch(updateSupportHoursStart(updatedSupportData)) : dispatch(updateAdminSupportTableDataStart(updatedSupportData));
    // }
    switch (generalTab) {
      case 'roles':
        return <RoleComponent DispatchRolehandler={(data) => {
          setUpdatedRoleData(data)
        }} roletabdata={roletabdata} GeneralInfoToggle={GeneralInfoToggleWithPath}
          accountDetails={Object.keys(accountDetails).length > 0 ? accountDetails : accountDetailsInfo}
        />

      case 'voice':
        return <VoiceHandler 
        GeneralInfoToggle={GeneralInfoToggleWithPath}
        dispatchAccountHandler={(data) => {
          setUpdatedAccountData(data)
        }}
          dispatchSupportHandler={(data) => {
            setUpdatedSupportData(data)
          }}
          DispatchQueuehandler={(data) => {
            setUpdatedQueueData(data);
          }}
          accountDetails={Object.keys(accountDetails).length > 0 ? accountDetails : accountDetailsInfo}
          QueueTableData={location.pathname === '/onboardAccount' ? QueueTableData : AdminQueueTableData}
          supportHoursVoice={location.pathname === '/onboardAccount' ? supportHoursVoice : AdminVoiceSupportTableData}
          voiceSLAData={location.pathname === '/onboardAccount' ? SLAKPIData : AdminVoiceSlaTableData}
        />


      case 'nonvoice':
        return <NonVoiceHandler
        GeneralInfoToggle={GeneralInfoToggleWithPath}
          dispatchGeneralHandler={data =>
            setUpdatedGeneralData(data)
          }
          dispatchAccountHandler={(data) => {
            setUpdatedAccountData(data)
          }}
          dispatchSupportHandler={(data) => {
            setUpdatedSupportData(data)
          }}
          accountDetails={Object.keys(accountDetails).length > 0 ? accountDetails : accountDetailsInfo}
          widgetCardHandler={widgetCardHandler} GeneralNonvoicedata={GeneralNonvoicedata}
          supportHoursVoice={location.pathname === '/onboardAccount' ? NonVoiceSupportHours : AdminSupportTableData}
          voiceSLAData={location.pathname === '/onboardAccount' ? NonVoiceSLAdata : AdminSlaTableData}
        />
      case 'preview':
        return <PreviewHandler onClickViewDetails={accountDetails.mode === 'create' ? [] : onClickViewDetails} onCheckContractPreview={(checked) => setPreviewContract(checked)} 
          dispatchFileUpload={data =>
            setUpdatedFileData(data)
          }
        />;
      default: { }
    }
  }


  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  };

  const onClickViewDetails = title => {
    switch (title) {
      case 'Roles': setGeneralTab('roles'); break;
      case 'Voice': setGeneralTab('voice'); break;
      default: setGeneralTab('nonvoice');
    }
  }

  const generaltabRows = ['roles', 'voice', 'nonvoice', 'preview'];
  const saveButtonHandler = () => {
    dispatch(getOnboardValidUser(accountSiteInfo.AccountName, accountSiteInfo.SiteName))
    let isAccountExist = onboardValidUser?.[0]?.column1 === 'Invalid User'
    if (generalTab === 'roles' && isAccountExist) {
      setDataSaveInfo(false)
      setAccountExists(true)
    }
    else {
      setAccountExists(false)
      setDataSaveInfo(true)
    }

    // window.scroll({
    //   top: 0,
    //   left: 0,
    //   behavior: 'smooth'
    // });
    switch (generalTab) {
      case 'roles':
        console.log('post data:::::::::',updatedRoleData)
        dispatch(updateRoleDataStart(updatedRoleData))
        break;
      case 'nonvoice':
      case 'voice':
        if (updatedQueueData.length > 0) {
          location.pathname === '/onboardAccount' ? dispatch(updateQueueTableStart(updatedQueueData)) : dispatch(updateAdminQueueTableDataStart(updatedQueueData));
        }
        if (updatedAccountData.length > 0) {
          location.pathname === '/onboardAccount' ? dispatch(updateSLADataStart(updatedAccountData)) : dispatch(updateAdminSlaTableDataStart(updatedAccountData));
        }
        if (updatedSupportData.length > 0) {
          location.pathname === '/onboardAccount' ? dispatch(updateSupportHoursStart(updatedSupportData)) : dispatch(updateAdminSupportTableDataStart(updatedSupportData));
        }
        break;
      default: { }
    }
    dispatch(getPreviewOnboard(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
    dispatch(getPreviewAdmin(accountSiteInfo.AccountName, accountSiteInfo.SiteName))
    let index = generaltabRows.indexOf(generalTab);
      let currentTab = generaltabRows[index + 1];
      setGeneralTab(currentTab);
  }
 
  const [noUnsavedData, setNoUnsavedData] = useState(null);
  const [unsavedDataExist, setUnsavedDataExist] = useState(null);
  const [showNextModal, setShowNextModal] = useState(null);
  const [showTabModal, setShowTabModal] = useState(false);
  const [buttonMode, setButtonMode] = useState(null);
  const [tabForYesButton, setTabForYesButton] = useState(null);

  const rolesValidation_1 = ObjectEquateHandle(rolesUpdateResponse?.[0]?.Details, updatedRoleData?.[0]?.Details);

  const voiceValidation_1 = updatedQueueData.length === 0 && updatedAccountData.length === 0 && updatedSupportData.length === 0;
  const voiceValidation_2 = updatedQueueData.length !== 0 || updatedAccountData.length !== 0 || updatedSupportData.length !== 0;
  const voiceValidation_3 = ObjectEquateHandle(queuesUpdateResponse?.[0]?.Details, updatedQueueData?.[0]?.Details) && ObjectEquateHandle(slaUpdateResponse?.[0]?.Details, updatedAccountData?.[0]?.Details) && ObjectEquateHandle(supportUpdateResponse?.[0]?.Details, updatedSupportData?.[0]?.Details)
  const voiceValidation_4 = ObjectEquateHandle(queuesUpdateResponse?.[0]?.Details, updatedQueueData?.[0]?.Details) || ObjectEquateHandle(slaUpdateResponse?.[0]?.Details, updatedAccountData?.[0]?.Details) || ObjectEquateHandle(supportUpdateResponse?.[0]?.Details, updatedSupportData?.[0]?.Details)

 

  const nonvoiceValidation_1 = updatedAccountData.length === 0 && updatedSupportData.length === 0;
  const nonvoiceValidation_2 = updatedAccountData.length !== 0 || updatedSupportData.length !== 0;
  const nonvoiceValidation_3 = ObjectEquateHandle(slaUpdateResponse?.[0]?.Details, updatedAccountData?.[0]?.Details) && ObjectEquateHandle(supportUpdateResponse?.[0]?.Details, updatedSupportData?.[0]?.Details)
  const nonvoiceValidation_4 = ObjectEquateHandle(slaUpdateResponse?.[0]?.Details, updatedAccountData?.[0]?.Details) || ObjectEquateHandle(supportUpdateResponse?.[0]?.Details, updatedSupportData?.[0]?.Details)


  const OnboardCancelButtonHandle = () => {
    switch (generalTab) {
      case 'roles': {
        setShowCancelModal(true);
        if (rolesValidation_1) {
          setNoUnsavedData(true)
          setUnsavedDataExist(false);
        }
        else {
          setUnsavedDataExist(true);
          setNoUnsavedData(false);
        }
        break
      }
      case 'voice': {
        setShowCancelModal(true);
        if (voiceValidation_1) {
          setNoUnsavedData(true)
          setUnsavedDataExist(false);
        }
        if (voiceValidation_4) {
          setUnsavedDataExist(true);
          setNoUnsavedData(false);
        }
        if (voiceValidation_3) {
          setNoUnsavedData(true)
          setUnsavedDataExist(false);
        }
        break
      }
      case 'nonvoice': {
        setShowCancelModal(true);
        if (nonvoiceValidation_1) {
          setNoUnsavedData(true)
          setUnsavedDataExist(false);
        }
        if (nonvoiceValidation_4) {
          setUnsavedDataExist(true);
          setNoUnsavedData(false);
        }
        if (nonvoiceValidation_3) {
          setNoUnsavedData(true)
          setUnsavedDataExist(false);
        }
        break
      }
      default: { }
    }

  }



  const cancelButtonHandler = () => {
    dispatchNavigationHandle();
    location.pathname === '/onboardAccount' ? history.push('/onboard') : history.push('/admin');
    setShowCancelModal(false);
  }
  const tabChangeHandle = (button) => {
    if (button === 'next') {
      let index = generaltabRows.indexOf(generalTab);
      let currentTab = generaltabRows[index + 1];
      setGeneralTab(currentTab);
    }
    else {
      let index = generaltabRows.indexOf(generalTab);
      let currentTab = generaltabRows[index - 1];
      setGeneralTab(currentTab);
    }
  }

  const ValidationManager = (valid1, valid2, valid3, valid4, btn) => {
    if (valid1) {
      tabChangeHandle(btn)
      setUnsavedDataExist(false);
    }
    if (valid2) {
      setUnsavedDataExist(true);
      setShowNextModal(true);
    }

    if (valid3) {
      tabChangeHandle(btn);
      setShowNextModal(false);
      setUnsavedDataExist(false);
    }
    if (valid4) {
      setUnsavedDataExist(true);
    }

  }
  const onboardNextButtonHandle = () => {
    switch (generalTab) {
      case 'roles': {
        if (rolesValidation_1) {
          setUnsavedDataExist(false);
          tabChangeHandle('next')
        }
        if (!rolesValidation_1) {
          setShowNextModal(true);
          setUnsavedDataExist(true);
        }
        break
      }
      case 'voice': {
        ValidationManager(voiceValidation_1, voiceValidation_2, voiceValidation_3, voiceValidation_4, 'next')
        break
      }
      case 'nonvoice': {
        ValidationManager(nonvoiceValidation_1, nonvoiceValidation_2, nonvoiceValidation_3, nonvoiceValidation_4, 'next')
        break
      }
      default: { }
    }
  }
// const TabValidate = rolesValidation_1 && voiceValidation_3 && nonvoiceValidation_3
const tabValidate = updatedRoleData?.[0]?.Details === undefined && updatedQueueData?.[0]?.Details === undefined && updatedAccountData?.[0]?.Details === undefined && updatedSupportData?.[0]?.Details === undefined
//console.log('............',TabValidate,tabValidate)
  const onboarTabButtonHandle = (tab) => {
    if(!tabValidate){
      setShowTabModal(true);
      setTabForYesButton(tab)
    }
    else{
      setGeneralTab(tab)
    }
   
   
  }
  console.log('mmmmmmmmmmmm',rolesValidation_1,voiceValidation_3,nonvoiceValidation_3,  rolesValidation_1 && voiceValidation_3 && nonvoiceValidation_3,slaUpdateResponse?.[0]?.Details, updatedAccountData?.[0]?.Details)
 
  const tabYesButtonHandle = () => {
    saveButtonHandler();
    setShowTabModal(false);
    dispatch(updateRoleDataStart())
    setUpdatedRoleData([]);
    setUpdatedQueueData([]);
    setUpdatedAccountData([]);
    setUpdatedSupportData([]);
    setGeneralTab(tabForYesButton)
  }

  const tabNoButtonHandle = () => {
    setShowTabModal(false)
    setUpdatedRoleData([]);
    setUpdatedQueueData([]);
    setUpdatedAccountData([]);
    setUpdatedSupportData([]);
    setGeneralTab(tabForYesButton)
  }

  const onboardPreviousButtonHandle = () => {
    switch (generalTab) {
      case 'voice': {
        ValidationManager(voiceValidation_1, voiceValidation_2, voiceValidation_3, voiceValidation_4, 'previous')
        break
      }
      case 'nonvoice': {
        ValidationManager(nonvoiceValidation_1, nonvoiceValidation_2, nonvoiceValidation_3, nonvoiceValidation_4, 'previous')
        break;
      }
      case 'preview': {
        tabChangeHandle('previous');
        break;
      }
      default: { }
    }
  }


  const nextSaveButtonHandle = () => {
    saveButtonHandler();
    setUnsavedDataExist(false);
    setShowNextModal(false);
    buttonMode === 'next' ? tabChangeHandle('next') : tabChangeHandle('previous')
  }
  const nextNoButtonHandle = () => {
    setShowNextModal(false);
    buttonMode === 'next' ? tabChangeHandle('next') : tabChangeHandle('previous')

  }
  const nextButtonHandler = () => {
    setShowNextModal(false);
    tabChangeHandle('next')
  }
  const submitPreviewHandle = () => {
    if (location.pathname === '/onboardAccount') {

      if (previewContract && GeneralInfoToggle) setShowSubmitModal(true)
      if (!GeneralInfoToggle) {
        setPreviewSubmitBar(true);
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
      if (!previewContract) {
        setPreviewContractBar(true);
      }
    }
    if (location.pathname === '/admin/account') {
      const temp_Arr = [{
        Account: accountSiteInfo.AccountName,
        Servicedesk: accountSiteInfo.SiteName,
        ValidFrom: accountSiteInfo.ValidFrom,
        ValidTo: accountSiteInfo.ValidTo,
      }];
        dispatch(updateOnboardToggleStart(temp_Arr));
        setShowSubmitModal(true)
    }
  }

  const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', updatedFileData);

		fetch(
			`${
        process.env.REACT_APP_BASE_URL
      }/api/org/customer/${previewOnboardData?.[0]?.CustomerID}/photo`,
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};
  const submitButtonHandler = () => {
    handleSubmission()
    let temp_arr = [{
      Account: accountSiteInfo.AccountName,
      Servicedesk: accountSiteInfo.SiteName,
      Status: "Submitted",
    }]
    let temp_arr_admin = [{
      Account: accountSiteInfo.AccountName,
      Servicedesk: accountSiteInfo.SiteName,
      Status: "Completed",
    }]
    location.pathname === '/onboardAccount' ? dispatch(updateOnboardSubmitStart(temp_arr)) : dispatch(updateAdminUpdateStart(temp_arr_admin)); 
    setShowSubmitModal(false);
    location.pathname === '/onboardAccount' ? history.push('/onboard') : history.push('/admin');
  }

  const ButtonClickHandler = (button) => {
    switch (button) {
      case 'next': {
        setButtonMode('next')
        onboardNextButtonHandle()
        break
      }
      case 'previous': {
        setButtonMode('previous')
        onboardPreviousButtonHandle()
        break
      }
      case 'submit': submitPreviewHandle(); break;
      case 'save': saveButtonHandler(); break;
      case 'cancel': OnboardCancelButtonHandle(); break;
      case 'update' : submitPreviewHandle(); break;
      default: { }
    }
  }
  // var msg = "Any thing which u want";
  // var customUrl = '';
  // window.history.pushState({ "html": msg, "pageTitle": "My Title" }, "", customUrl);
  // window.onpopstate = function (event) {
  //   event.preventDefault();
  //   OnboardCancelButtonHandle()
  // }
  // window.onbeforeunload = function (event) {
  //   event.preventDefault();
  //   dispatch(updateRoleDataStart(updatedRoleData))
  //}

  const onboardLabelHandle = () => {
    if (accountDetails) {
      if (accountDetails.mode === 'edit') return `${accountSiteInfo.AccountName} - EDIT ACCOUNT `
      if (accountDetails.mode === 'view') return `${accountSiteInfo.AccountName} - VIEW ACCOUNT `
    }
    if (Object.keys(accountDetails).length === 0) {
      return accountSiteInfo?.AccountName + ' - NEW ACCOUNT'
    }
  }

  
  return (
    <React.Fragment>
      <main>
        {location.pathname === '/onboardAccount'
          ? <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Onboard' value2={()=>history.push('/onboard')}
            isActive3={false} label3={onboardLabelHandle()} /> :
          <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} value2={()=>history.push('/admin')} label2='Administration'
            isActive3={false} label3={accountSiteInfo.AccountName} />
        }
        <section className='main-container'>
          {location.pathname === '/onboardAccount' ? <>
            <div>
              <div className='onboard_account' >Onboard - New Account</div>
              {dataSaveInfo && <div className='dataSave_div'>
                <div> <img src={dataSave_icon} alt='' /> &nbsp;&nbsp;Data has been saved successfully </div>
                <div><img src={dataSaveClose_icon} alt='' onClick={() => setDataSaveInfo(false)} /></div>
              </div>}
              {validforaddingtabrows === true ? <div className='dataSave_div' style={{backgroundColor: '#F0AD4E' }}>
                <div> &nbsp;&nbsp;Without marking ‘Initial Save’ with YES, the account record will be lost. </div>
                <div></div>
              </div> : ''}
              {accountExists && <div className='dataSave_div' style={{ backgroundColor: '#F0AD4E' }}>
                <div> <img src={dataSave_icon} alt='' /> &nbsp;&nbsp;{`${accountSiteInfo.AccountName} account already exists with ${accountSiteInfo.SiteName} location`} </div>
                <div><img src={dataSaveClose_icon} alt='' onClick={() => setAccountExists(false)} /></div>
              </div>}
              {showInfoBar && <div className='info_bar' style={{ width: '98%' }} >
                <span>  Please Select Account and Site names with respective Validation dates to <b> {accountDetails?.mode?.toUpperCase() || 'CREATE'} </b> an Account </span>
                <span><img onClick={() => setShowInfoBar(false)} src={closeIcon} alt='' /></span>
              </div>}
            </div>
            <div style={{ marginTop: 40, marginBottom: 20 }}>
              <GeneralInfo accountDetails={Object.keys(accountDetails).length > 0 ? accountDetails : accountDetailsInfo}
                generalToggleChange={(checked) => setGeneralInfoToggle(checked)} /></div>
            {previewSubmitBar && <div className='info_bar'>
              <div> &nbsp;&nbsp;Please ensure that the toggle is selected in General Informations tab  </div>
              <div><img src={dataSaveClose_icon} alt='' onClick={() => setPreviewSubmitBar(false)} /></div>
            </div>}</>
            : <section className='onboard_account_admin' >
              <div> <span><img
                    style={{maxHeight:'40px',maxWidth:'60px'}}
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/org/customer/${accountSiteInfo?.CustomerID}/photo`}
                  alt="Client Logo"
                /></span>   {accountSiteInfo?.AccountName} - {accountSiteInfo.SiteName} </div>
                <div className='admin_title_border'>
              <div className='date_pickers'>
                <label>
                    <span> Valid From* : <span className='valid_from_admin'>{accountSiteInfo?.ValidFrom}</span> </span>
                </label>
                <label>
                    <span> Valid To* : <span className='valid_from_admin'>{accountSiteInfo?.ValidTo}</span></span>
                </label>
           
              </div></div>
            </section>
          }
          
          <br />
          <section className='navbar'>
            <div className='nav-container'>
              <ul className='nav-menu'>
                <Image src={rolesImgSrc} onClick={() => onboarTabButtonHandle('roles')}
                  onMouseEnter={() => generalTab !== 'roles' && setRolesImgSrc(rolesHoverImage)} onMouseLeave={() => generalTab !== 'roles' && setRolesImgSrc(rolesImage)}
                > <div className={`nav-span`}>
                    <span className='nav-text' style={{ color: generalTab === 'roles' && 'black' }}>Roles</span></div>
                  {generalTab === 'roles' && <div className='nav_btn_active'></div>}</Image>
                <Image src={voiceImgSrc} onClick={() => onboarTabButtonHandle('voice')}
                  onMouseEnter={() => generalTab !== 'voice' && setVoiceImgSrc(voiceHoverImage)} onMouseLeave={() => generalTab !== 'voice' && setVoiceImgSrc(voiceImage)}>
                  <div className={`nav-span`}>
                    <span className='nav-text' style={{ color: generalTab === 'voice' && 'black' }}>Voice</span></div>{generalTab === 'voice' && <div className='nav_btn_active'></div>} </Image>
                <Image src={nonVoiceImgSrc} onClick={() => onboarTabButtonHandle('nonvoice')}
                  onMouseEnter={() => generalTab !== 'nonvoice' && setNonvoiceImgSrc(nonVoiceHoverImage)} onMouseLeave={() => generalTab !== 'nonvoice' && setNonvoiceImgSrc(nonVoiceImage)}>
                  <div className={`nav-span`}>
                    <span className='nav-text' style={{ color: generalTab === 'nonvoice' && 'black' }}>Non voice</span></div>
                  {generalTab === 'nonvoice' && <div className='nav_btn_active'></div>} </Image>
                <Image src={previewImgSrc} onClick={() =>onboarTabButtonHandle('preview')}
                  onMouseEnter={() => generalTab !== 'preview' && setPreviewImgSrc(location.pathname === '/onboardAccount' ? previewHoverImage : GeneralInfoHoverImage)} onMouseLeave={() => generalTab !== 'preview' && setPreviewImgSrc(location.pathname === '/onboardAccount' ? previewImage : GeneralInfoImage)}>
                  <div className={`nav-span`}>
                    <span className='nav-text' style={{ color: generalTab === 'preview' && 'black' }}>{location.pathname === '/onboardAccount' ? 'Preview' : 'Genaral Informations'}</span></div>
                  {generalTab === 'preview' && <div className='nav_btn_active'></div>} </Image>
              </ul>
            </div>

          </section>
          {onboardTabHandler()}
        </section>
        {previewContractBar && <div className='info_bar' style={{ width: '96%' }}>
          <div> &nbsp;&nbsp;Please ensure you are in above agreement with data owner policies. </div>
          <div><img src={dataSaveClose_icon} alt='' onClick={() => setPreviewContractBar(false)} /></div>
        </div>}
        {accountDetails.mode === 'view' ? '' :<main className='button-wrapper'>{
          BUTTONS.map((button, i) => (
            <>  {showButtons[i] === 1 && <button className='btn'
              onClick={() => ButtonClickHandler(button.label.toLowerCase())} ><span id='span'>{button.label}</span></button>} </>
          ))}</main>}
      </main>
      <br /><br /><br /><br />
      {showCancelModal && <CancelModalPopup isOpen={showCancelModal} setShowModal={setShowCancelModal}>
        <CancelFragment modalName='Unsaved Changes' buttonOne='YES' buttonTwo='NO' buttonThree='SAVE'
          accountName={accountSiteInfo.AccountName}
          onboard={true} handleSaveQueue={() => {
            saveButtonHandler();
            setShowCancelModal(false)
          }}
          handleDeleteQueue={cancelButtonHandler} handleDeleteQueueCancel={() => setShowCancelModal(false)}
          noUnsavedData={noUnsavedData} unsavedDataExist={unsavedDataExist}
        />
      </CancelModalPopup>}
      {showNextModal && <CancelModalPopup isOpen={showNextModal} setShowModal={setShowNextModal}>
        <NextButtonFragment
          handleSaveQueue={nextSaveButtonHandle}
          accountName={accountSiteInfo.AccountName}
          queueName={`${accountSiteInfo.AccountName} ${accountSiteInfo.SiteName}`}
          handleDeleteQueueCancel={nextNoButtonHandle}
          handleDeleteQueue={nextButtonHandler} tab={generalTab}
        />
      </CancelModalPopup>}
      {showTabModal && <CancelModalPopup isOpen={showTabModal} setShowModal={setShowTabModal}>
        <NextButtonFragment
          handleSaveQueue={tabYesButtonHandle}
          accountName={accountSiteInfo.AccountName}
          queueName={`${accountSiteInfo.AccountName} ${accountSiteInfo.SiteName}`}
          handleDeleteQueueCancel={tabNoButtonHandle}
          handleDeleteQueue={nextButtonHandler} tab={generalTab}
        />
      </CancelModalPopup>}
      {showSubmitModal && <SuccessModalPopup isOpen={showSubmitModal} setShowModal={setShowSubmitModal}>
        <SuccessFragment modalName={location.pathname === '/onboardAccount' ? `Onboard - ${accountSiteInfo.AccountName} Account` : `${accountSiteInfo.AccountName} Account`} buttonOne='CLOSE'
          handleSuccessAccount={submitButtonHandler} ReopenText={location.pathname === '/onboardAccount' ? `Please note your request will be processed within 24 hours, within this time frame, you may ask for re-open and make updates to your account onboard.` : ''}
          queueName={`${accountSiteInfo.AccountName} ${accountSiteInfo.SiteName}`}
        />
      </SuccessModalPopup>}
    </React.Fragment>
  );

}



export default Navigationbar;
