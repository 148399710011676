import React from 'react';
import { Accordion } from 'react-bootstrap';
import './Accordion.css';


function AccordionHandler({ACCORDION_INFO}) {
  return (<>

  <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
    {
      ACCORDION_INFO.map((accordion,i)=>(
        <Accordion.Item eventKey={i} style={{marginTop:'0.3em'}} key={i} >
        <Accordion.Header style={{
            width: 'inherit',
            height: '58px',
            background: '#FFFFFF 0% 0% no-repeat paddingBox',
            boxShadow: '0px 3px 3px #9D9D9D80',
            opacity: '1'
        }}><span className='accordion-header'>{accordion.label}</span></Accordion.Header>
        <Accordion.Body style={{
            marginTop:'0', border:'transparent', backgroundcolor:'white'
        }}>
          {accordion.Info}
            </Accordion.Body>
      </Accordion.Item>
      ))
    }
</Accordion>
  </>);
}

export default AccordionHandler;
