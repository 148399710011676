import React from 'react'
// import './Table.css'
import  '../../ReportTables/ReportFilters/ReportTable.css';

function FinanceTable(props) {
const Data = props.tblData?.table?.data;
const headerData = props.tblData?.table?.header;
const footerData = props.tblData?.table?.footer;
  return (
    <React.Fragment>
     {Data ===null ? (
        <section className='noData-table' style={{ width: '95%' }}>
          {/* <div className='noData-headers extra-noData-header'>
            {headerData.map((info, i) => (
              <div className='headers-span'
                key={i}><span>{info.label}</span></div>
            ))
            }
          </div> */}
          <div className='noData-para' >
            <div className='noData-para-span' style={{fontSize:'17px', marginLeft:'22em'}}>No records are available. Please select other filters.</div>
          </div>
        </section>
     ) :
        <section className='table-section'>
      <table className='report-table'>
        <thead className='thead-wrapper'>
          <tr>
            { headerData?.map((info, i) => (
                <th className='header-child'  key={i}>{info.label}</th>
              )) }
          </tr>
        </thead>

        <tbody className='tbody-container' >
              {Data?.map((info, i) =>
              (<tr key={i}>
                {
                  
                  Object.values(info).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))
                }
              </tr>))}
            </tbody>
        <tfoot className='thead-wrapper tfoot-wrapper '>
        {footerData?.map((info, i) =>
              (<tr key={i}>
                {
                  Object.values(info).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))
                }
              </tr>))}
        </tfoot>
      </table>
      </section>}
      <br/>
      <br/>
      <br/>
    </React.Fragment>
  )
}

export default FinanceTable












