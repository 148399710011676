import React, { useState } from 'react'
import './Training.css';
import AdminBreadcrumb from './../../Administration/General/Admin-breadcrumb';
import Training_HeroBanner from './../../../assets/github-images/Training_HeroBanner.png';
import Training_Materials from './../../../assets/github-images/Training_Materials.png';
import VerticalLine from './../../../assets/images/VerticalLine_Headings.png';
import { Footer } from '../HeaderFooter';
import { useHistory } from 'react-router-dom';
import { VideoModelPopup } from '../GitHub_LandingPage/Models';
import PlatformVideo from '../GitHub_LandingPage/PlatformVideo';

const RECORDED_CARDS = [
  {
      id: 1,
      title: 'GSD Insight Wave 1 Closure Meeting',
      text: 'GSD Insight introduction meeting after completion of Wave 1 (telephony data onboarding) as the going forward GSD solution containing the global standard repository of reporting and analytics.',
      href:'  https://dxcportal.sharepoint.com/sites/GSDInsight/Shared%20Documents/Forms/AllItems.aspx?FolderCTID=0x012000E6B3722E8FCB1A4BB398EDB8F620E4AC&id=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FTraining%20Materials%2FWave%201%20Closing%20Video%2FGSD%20Insight%20%2D%20Wave%201%20Closure%20%E2%80%93%20First%20Session%2D20211013%5F153308%2DMeeting%20Recording%2Emp4&parent=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FTraining%20Materials%2FWave%201%20Closing%20Video'
  },
  {
      id: 2,
      title: 'GSD Insight Wave 1 Production Release Demo',
      text:'Training guide for GSD Insight Wave 1 production, introduction of current Landing Page, Menus, Regional/Site Selection Page, DTM Report and Site Level SLA Dashboard.',
      href:'https://dxcportal.sharepoint.com/sites/GSDInsight/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=sDyVrf&cid=22a54140%2De5a0%2D4b9b%2Dbfdd%2Ddc4d12e175fa&FolderCTID=0x012000E6B3722E8FCB1A4BB398EDB8F620E4AC&id=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FTraining%20Materials%2FWave%201%20Release%20Recordings%2FDEMO%20Recording%20%2D%20GSD%20Insight%5F%20Wave%201%20Production%20Release%2Emp4&parent=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FTraining%20Materials%2FWave%201%20Release%20Recordings'
  },
  {
      id: 3,
      title: "GSD Insight Wave 1 Next Steps Demo",
      text:'FMO Design concept introduction for Landing Page and Widget Setup, Regional/Site Selection Page, DTM Report, DTM Report detailed analytics and Executive Dashboard.',
      href:'https://dxcportal.sharepoint.com/sites/GSDInsight/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=sDyVrf&cid=22a54140%2De5a0%2D4b9b%2Dbfdd%2Ddc4d12e175fa&FolderCTID=0x012000E6B3722E8FCB1A4BB398EDB8F620E4AC&id=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FTraining%20Materials%2FWave%201%20Release%20Recordings%2FDEMO%20Recording%20%2D%20GSD%20Insight%5F%20Next%20Steps%2Emp4&parent=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FTraining%20Materials%2FWave%201%20Release%20Recordings'
  }
]

const  TRAINING_MATERIAL= [
  {
      id: 1,
      title:'GSD Insight Introduction',
      href:'/notinproduction'
  },
  {
      id: 2,
      title:'Navigation',
      href:'https://dxcportal.sharepoint.com/:p:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7BDC8D92B3-5160-46EA-B326-8656F22930C8%7D&file=Training_Navigation.pptx&action=edit&mobileredirect=true'
  },
  {
      id: 3,
      title:'Day-To-Month Report',
      href:'https://dxcportal.sharepoint.com/:p:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7BFA6CA7CF-3436-48FD-9C3A-E75BCF824FD6%7D&file=Training_DTM.pptx&wdLOR=c43A27992-9331-4FAB-8F5E-CFF4B06175C4&action=edit&mobileredirect=true'
  },
  {
    id: 4,
    title:'Executive Dashboard',
    href:'https://dxcportal.sharepoint.com/:p:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7B9263C8CF-0E02-434E-8723-1A93D08C98A1%7D&file=Training_Guide_Executive_Dashboard.pptx&action=edit&mobileredirect=true'
},
{
    id: 5,
    title:'Landing Page',
    href:'https://dxcportal.sharepoint.com/:p:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7BF487ACAF-6E97-407B-B4E2-70C9DD5222B3%7D&file=Training_Guide_Landing_Page.pptx&action=edit&mobileredirect=true'
},
{
    id: 6,
    title:'Onboard',
    href:'https://dxcportal.sharepoint.com/:p:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7BFD69EC0A-516A-41B5-A425-98373BFA45FA%7D&file=Training_Guide_Onboard.pptx&wdLOR=cC1724915-0F72-4236-BCF4-E9372221E08A&action=edit&mobileredirect=true'
},
{
  id: 7,
  title:'Administration',
  href:'https://dxcportal.sharepoint.com/:p:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7BAE2D11B7-0112-4694-9B7B-11EB6BA08D0C%7D&file=Training_Guide_Administration_StaffAdministration.pptx&action=edit&mobileredirect=true'
},
{
  id: 8,
  title:'Request/Incident',
  href:'https://dxcportal.sharepoint.com/:p:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7BB007558A-B43F-4379-A2A9-1B28FE5080B0%7D&file=Training_Guide_Request_Incident.pptx&wdLOR=cE55853D7-C2F6-4C10-A7D0-9BC015A7C79C&action=edit&mobileredirect=true'
}
]

const Training = () => {
  const history = useHistory();
  const [showVideoModel, setShowVideoModel] = useState(false);
  return (
    <>
       <section style={{ marginLeft: -100 }}>
        <main className='breadcrumb_solution'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Training' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>Training</div>The training section refers to learning activities which GSD Insight users can undertake with the aim to acquire the overview and necessary skills for the easy navigation on the portal. The training content on this page offers recorded sessions and decks with presentation materials.</div>
          <div style={{paddingBottom:20,paddingLeft:80}}><img src={Training_HeroBanner} alt='' /></div></div>
        </main>
        <main className='conceptual_arch_user'>
        <div style={{width:1200,margin:'auto',display:'flex',gap:'40px'}}>
         <section className='card_background_training'>
         <main className='recorded'>
           <div><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp;  Recorded Sessions</div>
           <main id='card_flex'>{RECORDED_CARDS?.map((grid, id) =><div id='card_style'>
            <div id='card_header'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={grid.href}
                      title="" >  <span id='link_font'>{grid.title}</span> </a></div>{grid.text}
           </div>)}</main>
         </main>
        </section>
        </div>
        </main>
        <section style={{width:1200,margin:'auto',display:'flex',height:'397px'}}>
          <main><img src={Training_Materials} alt=''/></main>
          <main className='training_grid'>
          <div className='training_header'>Training Materials</div>
          <div className='grid_training'>
              {TRAINING_MATERIAL.map((link, index) => (<div style={{position:'relative'}}><div class="grid_item_training" >
              {(link.title === 'GSD Insight Introduction') ? <span onClick={()=>setShowVideoModel(true)} style={{cursor:'pointer'}}> <span style={{color:'black'}}> {link.title}</span></span> : 
              <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={link.href}
                      title="" >  <span style={{color:'black'}}>{link.title}</span> </a>}
                      </div>
             </div>))}
        </div>
          </main>
        </section>
        <Footer/>
        </section> 

        {showVideoModel && <VideoModelPopup isOpen={showVideoModel} setShowModal={setShowVideoModel}>
        <PlatformVideo closeWindow={()=>setShowVideoModel(false)}
        />
      </VideoModelPopup>}
    </>
  )
}

export default Training