import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import './WidgetModal.css'
import herobanner from '../../../assets/images/HeroBanner_WithContent.png'
import { useDispatch, useSelector } from 'react-redux';
import {getWidgetPreference, updateWidgetPreferenceShown } from '../../../actions/welcomePage.action';
import Widget from './widget';

const REMEMBER_LABEL = 'Don’t ask me again';
export function Banner() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [preference, setPreference] = useState();
    let widgetPreferences = useSelector((state) => state.welcomePageReducer.widgetPreferences);
    useEffect(() => {
        dispatch(getWidgetPreference());
        if (widgetPreferences?.WidgetSelectorShown === 'true') setPreference(true);
        else setPreference(false);
        widgetPreferences?.WidgetSelectorShown === 'true' && history.push('/welcome');
    }, []);
    const WidgetPreferenceHandle = () => {
        setPreference(!preference)
        dispatch(updateWidgetPreferenceShown(!preference))
    }
    return (

        <>
            <div className='image_holder' >
                <div className='image_banner'> <img src={herobanner} alt='hero' /></div>
                <div className='remainder' style={{ cursor: 'pointer' }}>
                    <input type='checkbox' checked={preference}
                        name='remainder' id='remainder' onChange={WidgetPreferenceHandle} style={{ cursor: 'pointer' }} />
                    <label htmlFor='remainder' style={{ cursor: 'pointer' }}>
                        <span className='text-remainder' style={{ cursor: 'pointer' }}>{REMEMBER_LABEL}</span>
                    </label >
                </div>
            </div>
        </>
    )
}
function WidgetModal(props) {
    return (<>
        <section className='widget_section'>
            <div className='header_span' >   {props.WIDGET_HEADING}    </div>
            <div style={{ marginLeft: '20px', marginTop: '-20px' }}>
                <b>Account Selected:</b> <strong style={{ color: '#5F249F' }}>{props.accountName}</strong>
            </div>
            <div className='widget_list_holder'>
                {props.accountWidgets?.map((widget, id) =>
                    <Widget widgetvalue={widget.Name} key={widget.WidgetID}
                        widgetinfo=''
                        togglehandler={(bool) => props.toggleHandler(bool, widget.Name, widget.WidgetID)}
                        isselected={widget.Selected}
                        widgettooltip={widget.Description}
                    />)
                }
            </div>

            <footer className='widget_button'> <button className='skip-button'>    <span className='skip-span'
                onClick={props.skip_ButtonHandler}>{props.BUTTON_ONE}</span>
            </button>
                <button className='widget-button'>  <span className='widget-button-span'
                    onClick={props.widget_ButtonHandler}  >{props.BUTTON_TWO}</span>
                </button>
            </footer>
        </section>

    </>)
}



export default WidgetModal

