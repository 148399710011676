import { slaFailure_actionTypes as actionTypes} from './../../constants/Administration/SLAFailure-actionTypes';

const initialState = {
    ExemptionData:[],
    ExclusionData:[],
}

const slaFailureReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EXEMPTION_DATA_START:
        case actionTypes.GET_EXCLUSION_DATA_START:
            return {
                ...state,
            };
        case actionTypes.GET_EXEMPTION_DATA_SUCCESS:
            return { ...state, ExemptionData: action.payload };
        case actionTypes.GET_EXCLUSION_DATA_SUCCESS:
                return { ...state, ExclusionData: action.payload };

        default: return state;
    }
}


export default slaFailureReducer