import { adminViewActionTypes as actionTypes } from "../../constants/Administration/adminView-actionTypes"

export const getAdminRoles = (data) => (
  {
    type: actionTypes.GET_ADMIN_ROLES,
    payload: data,
  }
)

export const getAccessGroup = (data) => (
  {
    type: actionTypes.GET_ACCESS_GROUP,
    payload: data,
  }
)

export const updateAccessGroupDataStart = (data) => (
  {
    type: actionTypes.UPDATE_ACCESSGROUP_DATA_START,
    payload: data,
  }
)


export const updateAccessGroupDataSuccess = () => (
  {
    type: actionTypes.UPDATE_ACCESSGROUP_DATA_SUCCESS,
  }
)

export const updateRoleDeleteStart = (data) => (
  {
    type: actionTypes.UPDATE_ROLE_DELETE_START,
    payload: data,
  }
)


export const updateRoleDeleteSuccess = () => (
  {
    type: actionTypes.UPDATE_ROLE_DELETE_SUCCESS,
  }
)


// OPERATIONS FLOW

export const getOperationsData = (data) => (
  {
    type: actionTypes.GET_OPERATIONS_DATA,
    payload: data,
  }
)

export const updateOperationsDataStart = (data) => (
  {
    type: actionTypes.UPDATE_OPERATIONS_DATA_START,
    payload: data,
  }
)


export const updateOperationsDataSuccess = () => (
  {
    type: actionTypes.UPDATE_OPERATIONS_DATA_SUCCESS,
  }
)

export const updateOperationsDeleteStart = (data) => (
  {
    type: actionTypes.UPDATE_OPERATIONS_DELETE_START,
    payload: data,
  }
)


export const updateOperationsDeleteSuccess = () => (
  {
    type: actionTypes.UPDATE_OPERATIONS_DELETE_SUCCESS,
  }
)


// ACCESS GROUP FLOW

export const getAccessGroupData = (data) => (
  {
    type: actionTypes.GET_ACCESSGROUP_DATA,
    payload: data,
  }
)

export const getAccessGroupEditData = (accessgroupid) => (
  {
    type: actionTypes.GET_ACCESSGROUP_EDIT_DATA,
    accessgroupid: accessgroupid
  }
)

export const getAccessGroupAddData = (data) => (
  {
    type: actionTypes.GET_ACCESSGROUP_ADD_DATA,
    payload: data,
  }
)

export const updateAccessGroupAddEditStart = (data) => (
  {
    type: actionTypes.UPDATE_ACCESSGROUP_ADDEDIT_START,
    payload: data,
  }
)


export const updateAccessGroupAddEditSuccess = () => (
  {
    type: actionTypes.UPDATE_ACCESSGROUP_ADDEDIT_SUCCESS,
  }
)

export const updateAccessGroupDeleteStart = (data) => (
  {
    type: actionTypes.UPDATE_ACCESSGROUP_DELETE_START,
    payload: data,
  }
)


export const updateAccessGroupDeleteSuccess = () => (
  {
    type: actionTypes.UPDATE_ACCESSGROUP_DELETE_SUCCESS,
  }
)

// USER FLOW

export const getTimeZoneData = (data) => (
  {
    type: actionTypes.GET_TIMEZONE_DATA,
    payload: data,
  }
)

export const updateAddUserStart = (data) => (
  {
    type: actionTypes.UPDATE_ADD_USER_START,
    payload: data,
  }
)


export const updateAddUserSuccess = () => (
  {
    type: actionTypes.UPDATE_ADD_USER_SUCCESS,
  }
)

export const updateDeleteUserStart = (data) => (
  {
    type: actionTypes.UPDATE_DELETE_USER_START,
    payload: data,
  }
)


export const updateDeleteUserSuccess = () => (
  {
    type: actionTypes.UPDATE_DELETE_USER_SUCCESS,
  }
)

export const getEditUserData = (userId) => (
  {
    type: actionTypes.GET_EDIT_USER_DATA,
    userId: userId
  }
)

export const updateEditUserStart = (data) => (
  {
    type: actionTypes.UPDATE_EDIT_USER_START,
    payload: data,
  }
)


export const updateEditUserSuccess = () => (
  {
    type: actionTypes.UPDATE_EDIT_USER_SUCCESS,
  }
)

export const getRegionData = (data) => (
  {
    type: actionTypes.GET_REGION_DATA,
    payload: data,
  }
)

export const getUPNValidation = (upn) => (
  {
    type: actionTypes.GET_UPN_VALIDATION,
    upn: upn
  }
)

export const getUserflowUsers = (query,status) => (
  {
    type: actionTypes.GET_USERFLOW_USERS,
    query: query,
    status:status
  }
)

export const getUserflowCount = (status) => (
  {
    type: actionTypes.GET_USERFLOW_COUNT,
    status:status
  }
)

export const saveUserStoredFilter = (data) => (
  {
    type: actionTypes.SAVE_USER_STORED_FILTER,
    payload: data,
  }
)

export const saveUserStoredStatus = (data) => (
  {
    type: actionTypes.SAVE_USER_STORED_STATUS,
    payload: data,
  }
)

// AUDIT RBAC 

export const getAuditRBACData = (role, accessGroup, operation) => (
  {
    type: actionTypes.GET_AUDITRBAC_DATA,
    role: role,
    accessGroup: accessGroup,
    operation: operation
  }
)

// AUDIT RBAC 

export const getAuditRoleData = (role, account, site, user, region) => (
  {
    type: actionTypes.GET_AUDITROLE_DATA,
    role: role,
    account: account,
    site: site,
    user:user,
    region:region
  }
)