import actionTypes from "../constants/actionTypes";

export const getUserData = (data) => ({
  type: actionTypes.GET_USER_DATA,
  payload: data,
});

export const getUserToken = (data) => ({ 
  type: actionTypes.GET_USER_TOKEN,
  payload: data,
});

export const getRegionalData = (data) => ({
  type: actionTypes.GET_REGIONAL_DATA,
  payload: data,
});

export const updateCalendarDateFormat = (dt) => ({
  type: actionTypes.UPDATE_CALENDAR_DATE_FORMAT,
  cal_dt_frmt: dt,
});

export const updateCalendarISOStringFormat = (dt) => ({
  type: actionTypes.UPDATE_CALENDAR_ISOSTRING_FORMAT,
  cal_isostring_frmt: dt,
});

export const updateCalendarDate = (dt) => ({
  type: actionTypes.UPDATE_CALENDAR_DATE,
  cal_dt: dt,
});

export const updateBreadCrumb = (bArr) => ({
  type: actionTypes.UPDATE_BREADCRUMB,
  breadCrumbArr: bArr,
});