import EDashboardActionTypes from "../constants/eDashboard-actionTypes";

export const getKPIData = (dt, rgn, lctn) => ({
    type: EDashboardActionTypes.GET_KPI_DATA,
    dt: dt,
    region: rgn,
    location: lctn
  });

export const updateKPIData = () => ({
type: EDashboardActionTypes.UPDATE_KPI_DATA
});
  
export const getComplianceData = (dt, rgn, lctn) => ({
  type: EDashboardActionTypes.GET_COMPLIANCE_DATA,
  dt: dt,
  region: rgn,
  location: lctn
}); 

export const getContactVolumeData = (dt, rgn, lctn) => ({
  type: EDashboardActionTypes.GET_CONTACT_VOLUME_DATA,
  dt: dt,
  region: rgn,
  location: lctn
});

export const getCallTypeData = (dt, rgn, lctn) => ({
  type: EDashboardActionTypes.GET_CALLTYPE_DATA,
  dt: dt,
  region: rgn,
  location: lctn
});

export const getHorizontalBarChartData = (dt, rgn, lctn) => ({
  type: EDashboardActionTypes.GET_HORIZONTAL_BAR_CHART_DATA,
  dt: dt,
  region: rgn,
  location: lctn
});

export const getProfitLossAnalyticData = (dt, rgn, lctn) => ({
 type: EDashboardActionTypes.GET_PROFIT_LOSS_ANALYTICS_DATA,
 dt: dt,
 region: rgn,
 location: lctn
});

export const getYearMonthRegionData = () => ({
  type: EDashboardActionTypes.GET_YEARMONTH_REGION_DATA
});

export const getIncidentsCardsData = (dt) => ({
  type: EDashboardActionTypes.GET_INCIDENTS_CARDS_DATA,
  dt: dt
})

export const getEDashBrdNotfnText = () => ({
  type: EDashboardActionTypes.GET_EDASHBOARD_NOTIFICATION_TEXT
});