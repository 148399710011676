import API from "../../api";
let token = localStorage.getItem("token");
export const getKPIDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getComplianceDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getContactVolumeDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
}

export const getCallTypeDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
}

export const getHrzntBarChrtDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getProfitLossAnalyticsDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
}

export const getYearMonthRegionDrpdwnDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
}

export const getTotalIncidentCardDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
}

export const getEDashBrdNotfnTextMsgDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
}