import React from 'react'
import AdminBreadcrumb from '../Administration/General/Admin-breadcrumb';
import Security_HeroBanner_Image from './../../assets/github-images/Security_HeroBanner_Image.png';
import VerticalLine from './../../assets/images/VerticalLine_Headings.png';
import { Footer } from './HeaderFooter';
import './../GitHub/user/User.css';
import { useHistory } from 'react-router-dom';

const  RBAC_LIST= [
    {
        id: 1,
        title:'Role Assignment',
        text:'A user can exercise a permission only if has been selected or been assigned a role.'
    },
    {
        id: 2,
        title:'Role Authorisation ',
        text:'A user’s active role must be authorized. Can’t just assign itself to a role. Authorization is required.'
    },
    {
        id: 3,
        title:'Permission Authorisation',
        text:'A user can exercise a permission only if the permission is authorized for the user’s active role. With rules 1 and 2, this rule ensures that users can only exercise permissions for which they are authorized.'
    }]

    const  Benefits= [
        { id: 1, title:'Enhances security & compliance'},
        { id: 2, title:'Reduces IT and administration effort'},
        { id: 3, title:'Maximizes the operational efficiency'},
        { id: 4, title:'Decreases the risk of breaches and data leakage'},
        { id: 5, title:'Reduces cost'},
        ]
    const  Implementing= [
            { id: 1, title:'Define the services which will be provided to end users'},
            { id: 2, title:'Create the mapping of the roles to specific resources'},
            { id: 3, title:'Create security groups which represent each role'},
            { id: 4, title:'Assign the users to the created roles'},
            { id: 5, title:'Apply the groups to access the required services / resources'},
            ]
    const  Documents= [
                { id: 1, title:'ROLE BASED ACCESS REPOSITORY is located ',href:'https://dxcportal.sharepoint.com/sites/GSDInsight/Shared%20Documents/Forms/AllItems.aspx?RootFolder=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FRBAC'},
                { id: 2, title:'Main overview is captured ',href:'https://dxcportal.sharepoint.com/:w:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7B000EF271-60FC-4424-BD92-8589F678F509%7D&file=GSD%20Insight_RBAC_1.0.docx&action=default&mobileredirect=true&cid=fbe28c33-0698-459e-871f-f4578483ddf7'},
                { id: 3, title:'Implementation control details are captured ',href:'https://dxcportal.sharepoint.com/:w:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7B57279015-93EE-4CC9-8A72-F8D6B3B7E06C%7D&file=RBAC-Implementation_Control.docx&action=default&mobileredirect=true&wdOrigin=TEAMS-ELECTRON.teams.files&wdExp=TEAMS-CONTROL&wdhostclicktime=1637860520099&cid=70f7c771-1e04-4c0a-893f-dc7183d4fb64'}
                ]
 
  const UID='https://my.dxc.com/departments/got/global-cyber-security/security-enhancement/universal-id'
  const OKTA ='https://csc.service-now.com/sp?sys_kb_id=fa9d78711b003090bb25eca0604bcbae&id=kb_article_view&sysparm_rank=2&sysparm_tsqueryId=2c4cc5131b397c945c6eedb2604bcb00';

  const Security = () => {
    const history = useHistory();
  return (
    <>
   <section style={{ marginLeft: -100 }}>
        <main className='breadcrumb_solution'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Security' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>Security</div>Overview of global DXC security prerequisites and the correlating access authorisations and role-based-access-control mechanisms. Account restrictions, storage and security requirements aligned with DXC standards are managed through the GSD Insight platform.</div>
          <div style={{paddingBottom:20,paddingLeft:80}}><img src={Security_HeroBanner_Image} alt='' /></div></div>
        </main>
        <main className='conceptual_arch_user'>
        <div style={{width:1200,margin:'auto',display:'flex',gap:'40px'}}>
        <section>
         <section className='card_background_user'>
          <main className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>Security and Access Model</span> <hr/> 
          GSD Insight application authenticates a user using the corporate OKTA UID - and then internally manages what data the user is able to view based on the internal RBAC model.<br/><br/>
          <b>Reporting and Auditing of GSD Insight Access</b><br/>A “GSD Access Report” is available within the solution.
           </main>
          <main className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>UID Integration</span> <hr/>
          The new company standard to authenticate users when accessing specific applications is the <span><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={UID}
                      title="" >  <span id='link_font'>UID</span> </a></span> solution - replacing the Global Pass solution.<br/><br/>
          GSD Insight is integrated with UID to authenticate the user’s login and thereby providing access to all DXC Technology employees.<br/><br/>
          You can check <span><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={OKTA}
                      title="" >  <span id='link_font'>here</span> </a></span> how to setup your OKTA UID authentication.
                    </main>
         </section>
        
         <main className='security_model'>
            <div id='text_security'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; What is RBAC</div>
            <section className='rbac_card'>
            <div>
            Role-Based Access Control (RBAC) is a security solution whereby users are granted access to specific resources based on their role in the company or department.<br/><br/>
            RBAC, if implemented correctly, can be an effective way of enforcing the principle of least privilege access.<br/><br/>
            Usually RBAC solution is based on the application of three basic principles. How these are applied in individual organizations can vary, but these principles remain invariable:<br/><br/>
            </div>
            <div>
              {RBAC_LIST.map((link, index) => (<li><span><b>{link.title}</b> - {link.text}</span></li>))}
              </div>
              <div className='grid_security'>
              <main class="grid_item_security" ><b>The Benefits of RBAC</b><div id='grid_text' style={{paddingTop:20,paddingBottom:20}}>
                {Benefits.map((link, index) => (<li>{link.title}</li>))}
              </div></main>
              <main class="grid_item_security" ><b>Implementing RBAC</b><div id='grid_text' style={{paddingTop:20,paddingBottom:20}}>
             { Implementing.map((link, index) => (<li>{link.title}</li>))}
              </div></main>
              <main class="grid_item_security" ><b>Role Based Access Documents</b><div id='grid_text' style={{paddingTop:20,paddingBottom:20}}>
              {Documents.map((link, index) => (<li>{link.title}<a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={link.href}
                      title="" >  <span className='link_here'>here</span> </a></li>))}
              </div></main>
              </div>
             </section>
         </main>
        </section>
        </div>
        </main>
        <Footer/>
        </section>
     </>
  )
}

export default Security