export const slaFailure_actionTypes = {

    // GET
    GET_EXEMPTION_DATA_START: "GET_EXEMPTION_DATA_START",
    GET_EXEMPTION_DATA_SUCCESS: "GET_EXEMPTION_DATA_SUCCESS",

    // EDIT
    UPDATE_EXISTING_COMMENT_START: "UPDATE_EXISTING_COMMENT_START",
    UPDATE_EXISTING_COMMENT_SUCCESS: "UPDATE_EXISTING_COMMENT_SUCCESS",

    GET_EXCLUSION_DATA_START: "GET_EXCLUSION_DATA_START",
    GET_EXCLUSION_DATA_SUCCESS: "GET_EXCLUSION_DATA_SUCCESS",

    UPDATE_EXCLUSION_COMMENT_START: "UPDATE_EXCLUSION_COMMENT_START",
    UPDATE_EXCLUSION_COMMENT_SUCCESS: "UPDATE_EXCLUSION_COMMENT_SUCCESS",
}