import React, { useEffect, useState } from 'react';
import PerformanceScoreCup from "../../assets/images/PerformanceScoreCup.png";
import DownloadIcon from "../../assets/images/Download.png"
import AccountAssignmentIcon from "../../assets/images/AccountAssignmentIcon.png"
import AboveMinimalTarget_Status from "../../assets/images/AboveMinimalTarget_Status.png"
import AboveRequiredTarget_Status from "../../assets/images/AboveRequiredTarget_Status.png"
import BelowMinimalTarget_Status from "../../assets/images/BelowMinimalTarget_Status.png"
import './AgentScorecard.css'
import Box from '../Box/box';
import { useDispatch, useSelector } from 'react-redux';
import { getScoreCardData, getScoreCardOffCanvasData } from './../../actions/ScoreCard&TeamKPI.action';
import DatePickerHandle from '../Onboard/OnboardComponents/KeyboardDatePicker';
import { AccountsOffcanvas } from './AccountsOffcanvas';
import { KeyboardDatePicker } from '@material-ui/pickers';



function AgentScoreCard() {
    const dispatch = useDispatch();
    let ScoreCardData = useSelector((state) => state.ScoreCardReducer.ScorCardData);
    let ScorCardOffCanvasData = useSelector((state) => state.ScoreCardReducer.ScorCardOffCanvasData);
    let selectedAgentScoreCardDate = useSelector((state) => state.ScoreCardReducer.selectedAgentScoreCardDate);

   const ScorCardData = ScoreCardData ? ScoreCardData : {}

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, '01', year].join('/');
    }

    const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
   

    const ValidFromDateHandler = (date) => {
        setSelectedDate((date === null || date === undefined) ? null : formatDate(date));
    }
    console.log('selectedDate',selectedDate)

    useEffect(() => {
        dispatch(getScoreCardData(selectedDate,selectedAgentScoreCardDate));
        dispatch(getScoreCardOffCanvasData(selectedAgentScoreCardDate))
    }, [dispatch,selectedDate,selectedAgentScoreCardDate]);

    const getArrowMark = (color) => {
        switch(color){
          case 'Green': {
            return (<img src={AboveRequiredTarget_Status} className="margn-5 mrgn-top-arrw-1"  alt="AbvRqdTgt Icon" />);
            }
          case 'Yellow' : {
            return (<img src={AboveMinimalTarget_Status}  className="margn-5 mrgn-top-arrw-1"  alt="AbvMnmlTgt Icon" />);
            }
          case 'Red' : {
            return(<img src={BelowMinimalTarget_Status}  className="margn-5 mrgn-top-arrw-1"  alt="BlwMnmlTgt Icon" />);
            }
          default: {
            return(<div></div>);
          }
        }
      }
      const [showOffCanvas,setShowOffCanvas] = useState(false)
    return (
        <>
            <section className='agent_score_card'>
                <header className='agent_header_continer'>
                    <div className='agent_header'>Agent Scorecard</div>
                    <div>
                    <div className='container_one_user'>
                    {/* <DatePickerHandle className='container-three' label='Select Date' id='label' width='260px'
                        value={selectedDate}
                        onChange={(date) => ValidFromDateHandler(date)}
                    /> */}
                    <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  label="Select Month"
                  value={selectedDate}
                  onChange={(date) => ValidFromDateHandler(date)}
                />
                </div>
                    </div>
                    
                </header>
                <div className="legend_container">
                        <span id='legend'>Legend: </span>
                        <img src={AboveRequiredTarget_Status} alt='' />Above Required Target
                        <img src={AboveMinimalTarget_Status} alt='' />Above Minimal Target
                        <img src={BelowMinimalTarget_Status} alt='' />Below Minimal Target
                    </div>
                
                <main className='main_dashboard'>
                    <div className='john_doe'>{ScorCardData.agentname}</div>
                    <section className='dashboard_agent'>
                            {/* <img src={AgentPhoto} alt='agent' width='120px' height='120px' /> */}
                            <img
                    style={{maxHeight:'130px',maxWidth:'130px',marginTop:'20px'}}
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/users/${selectedAgentScoreCardDate}/photo`}
                  alt="Client Logo"
                />
                            <div className='employee_div'>
                                <span className='employee_id'> Employee ID </span><br/><b>{ScorCardData.employeeid}</b><br />
                                <span className='employee_id'>Alias</span><br /><b>{ScorCardData.alias}</b><br />
                                <span className='employee_id'>FTE</span> <br /><b>{ScorCardData.FTE}</b>
                            </div>
                            <div className='vertical_line'></div>
                       
                        <div className='performance_score'>
                            <span id='overall'>Overall Performance Score</span>
                            <div  style={{ display: 'flex' }}>  <div id='cup'><img src={PerformanceScoreCup} alt='' width='70px' height='80px' /> </div> <div id='number'>{ScorCardData.score}</div></div>
                            <span>{getArrowMark(ScorCardData?.['Last Month'])}</span><span id='increase'>{ScorCardData.ScorePM}</span> <span id='last_month'>for last month</span>
                        </div>
                        <div className='vertical_line'></div>
                        <div className='account_Assing'>
                            <span id='overall'> Account Assignment</span>    <br />
                          <div style={{ display: 'flex', marginTop:'20px' }}> <div id='img'><img src={AccountAssignmentIcon} alt='' width='100px' height='100px' />
                          </div> <div id='number' onClick={()=>{setShowOffCanvas(true)}} style={{cursor:'pointer'}}>{ScorCardData.accounts} </div>  </div>
                        </div>
                        <AccountsOffcanvas title='Roles Hints' style={{ textAlign: 'right' }} ScorCardOffCanvasData={ScorCardOffCanvasData} handleClose={()=>setShowOffCanvas(false)} show={showOffCanvas} Title='Account Assignment'/>
                        <div className='vertical_line'></div>
                        <div className='download_csv'>
                           <div style={{marginLeft:20}}> <img src={DownloadIcon} alt='' width='50px'  height='50px' /></div><br/>
                          <span className='csv'><b>  Download CSV </b></span>
                        </div>

                    </section>
                </main>
                <br/><br/>

            {ScorCardData?.blocks?.map(box=><Box data={box} SLAReportHandleClick={()=>{}} click={false}/>)}
            <br/><br/>
            </section>

        </>
    )
}

export default AgentScoreCard