import React, { useEffect, useState } from 'react'
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import DatePickerHandle from '../../../Onboard/OnboardComponents/KeyboardDatePicker';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getOriginatingCountry, getRolesTabUsers } from '../../../../actions/Onboard.action';
import deleteIcon from './../../../../assets/images/Delete_Icon.png';
import editIcon from './../../../../assets/onboard-images/Edit_Icon.png'
import { getTimeZoneData, getUPNValidation, updateAddUserStart, updateEditUserStart } from '../../../../actions/Administration/adminView.action';
import TooltipHandler from '../../../Onboard/OnboardComponents/TooltipHandler';
import { AddRoleAccountModel, AddRoleAccountPopup } from './UserModels';
import { DeleteModalPopup } from '../../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { AdminViewDeleteModel } from '../AdminViewModels';
import { name } from 'file-loader';


const headers = [
  {
      caption: "Role",
      id: "1"
  }, {
      caption: "Account",
      id: "2"
  },
  {
      caption: "Site",
      id: "3"
  },
  {
      caption: "Region",
      id: "4"
  },
  {
      caption: "Valid From",
      id: "5"
  },
  {
      caption: "Valid To",
      id: "6"
  }
]

const AddUsers = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  console.log('location::::::', location.state)
  const EditData = location.state=== undefined ? '' :  location.state.data;
  const Mode = location.state=== undefined ? '' : location.state.Mode;
  const editDataID = location.state=== undefined ? '' : location.state.editDataID
  let countryInfo = useSelector((state) => state.onboardReducer.originatingCountry);
  let timezoneData = useSelector((state) => state.adminViewReducer.timezoneData);
  let UPNValidation = useSelector((state) => state.adminViewReducer.UPNValidation);

  useEffect(() => {
    dispatch(getOriginatingCountry());
    dispatch(getTimeZoneData());
}, [dispatch])


  const [addUser, setAddUser] = useState();
  const [addUserTableData, setAddUserTableData] = useState([])


  
  useEffect(() => {
    if (Mode === 'Edit') {
      setAddUser(EditData?.[0])
      setAddUserTableData(EditData?.[0]?.RoleAssignment)
    }
    else {
        setAddUser()
    }
  }, [dispatch, Mode,EditData ]);

 

  const HandleAddUser = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    if (fieldValue !== 'Select') {
      const newFormData = { ...addUser };
      newFormData[fieldName] = fieldValue;
      setAddUser(newFormData)
    }
    if(fieldName === 'UPN'){
dispatch(getUPNValidation(fieldValue))
    }
  }
  const addValueToUser = (name, value) => {
    const newFormData = { ...addUser };
    if (name !== 'Select') {
        newFormData[name] = value;
        setAddUser(newFormData)
    }
}


const [file, setFile] = useState("");
  const [error, setError] = useState();
  const [datacard, setDatacard] = useState(false);

function handleUpload(event) {
  if (event.target.files[0].size > 1000000) {
    setError('please upload the file lessthan 1MB');
    setFile('');
    setDatacard(false);
  } else {
    console.log(event.target.files[0])
    setFile(event.target.files[0]);
    setError('')
    setDatacard(true);
  }
}
function deleteHandler() {
  setFile('');
  setDatacard(false);
}

const onChangeValue = (event) =>  {
  const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    if (fieldValue !== 'Select') {
      const newFormData = { ...addUser };
      newFormData[fieldName] = JSON.parse(fieldValue);
      setAddUser(newFormData)
    }
}

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

   

    const ValidUntilDateHandler = (date) => {
      setAddUser({...addUser, ValidUntil: (date === null || date === undefined) ? null : formatDate(date)});
      
  }








  const [showAddRoleAccountModel, setShowAddRoleAccountModel] = useState(false);
  const [editRoleAccountData, setEditRoleAccountData] = useState([]);
  const [mode, setMode] = useState();
  const [tableRow, setTableRow] = useState({});

  const addValueToTable = (value) => {
    if(mode === 'Add'){
    if(addUserTableData.length === 0){
      setAddUserTableData([value])
    }
    else{
      setAddUserTableData([...addUserTableData,value])
    }
  }
  else{
    const editrowdata = [...addUserTableData];
         editrowdata[tableRow['index']] = { ...value }
         setAddUserTableData([...editrowdata]);
  }
}

const [validation, setValidation] = useState('');
const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
const [deleteRowid, setDeleteRowid] = useState();
const [deleteRowData, setDeleteRowData] = useState();

const deleteTableRowHandler = () => {
  const deletedata = addUserTableData.filter((row, _id) => (_id !== deleteRowid));
  setAddUserTableData([...deletedata]);
  setShowDeleteRoleModal(false)
}

console.log('deleteRowData',deleteRowData)

const handleSubmission = () => {
  const formData = new FormData();

  formData.append('File', file);

  fetch(
    `${
      process.env.REACT_APP_BASE_URL
    }/api/users/${addUser.Email}/photo`,
    {
      method: 'POST',
      body: formData,
    }
  )
    .then((response) => response.json())
    .then((result) => {
      console.log('Success:', result);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

console.log('UPNValidation',UPNValidation.length)

const dispobj = () =>{
 
  let temparr = {
    ID:"",
    Name:addUser.Name,
    UPN:addUser.UPN,
    Email:addUser.Email,
    ValidUntil:addUser.ValidUntil,
    Blocked:addUser.Blocked === 'true' ? 1 : 0,
    City:addUser.City,
    Country:addUser.Country,
    TimeZone:addUser.TimeZone,
  }
  console.log('dispOBJ',[temparr])
  const temp_Arr = {
    Name:addUser.Name,
    UPN:addUser.UPN,
    Email:addUser.Email
}
function ValidateEmail() 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(addUser.Email))
  {
    return (true)
  }
    return (false)
}

const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
if (isEmptyInput) {
    setValidation('Please Ensure you filled all the inputs with  " * " mark ')
    window.scrollTo(0, 0);
}
else if(UPNValidation.length !== 0){
  setValidation('UPN already Exists, Please Enter the different UPN')
  window.scrollTo(0, 0);
}
else if(!ValidateEmail()){
  setValidation('Invalid Email, Please Enter the valid Email Address')
  window.scrollTo(0, 0);
}
else{
  dispatch(updateAddUserStart([temparr]))
  history.push('/admin/users');
  setValidation('')
}
}



const dispEditobj = () =>{
  let temp_arr = [...addUserTableData];
  let tempArraydetails = temp_arr.map(data => {
      return {
        ID: data?.ID ? data?.ID : '', 
        Role:data?.Role,
        Accouunt:data?.Customer,
        Site:data?.Servicedesk,
        Region:data?.Region,
        ValidFrom:data?.['Valid From'],
        ValidTo:data?.['Valid To']
      }
  });

  let temparr = {
    UserID:editDataID,
    Name:addUser.Name,
    UPN:addUser.UPN,
    Email:addUser.Email,
    ValidUntil:addUser.ValidUntil,
    Blocked:addUser.Blocked === true ? 1 : 0,
    City:addUser.City,
    Country:addUser.Country,
    TimeZone:addUser.TimeZone,
    Details:tempArraydetails,
  }
  console.log('dispEDITOBJ',temparr);
  const temp_Arr = {
    Name:addUser.Name,
    UPN:addUser.UPN,
    Email:addUser.Email
}

function ValidateEmail() 
{
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(addUser.Email))
  {
    return (true)
  }
    return (false)
}
const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
const upnValidation = () =>{
  if(UPNValidation.length !== 0){
    const bbbbb = EditData?.[0]?.UPN === temparr.UPN
    return bbbbb 
  }
  return true
}
console.log('upnValidation:::',upnValidation(),UPNValidation.length !== 0, EditData?.[0]?.UPN !== temparr.UPN , EditData?.[0]?.UPN , temparr.UPN)
if (isEmptyInput) {
    setValidation('Please Ensure you filled all the inputs with  " * " mark ')
    window.scrollTo(0, 0);
}
else if(!upnValidation()){
  console.log('UPNValidation',UPNValidation?.[0]?.upn, temparr.UPN )
  setValidation('UPN already Exists, Please Enter the different UPN')
  window.scrollTo(0, 0);
}
else if(!ValidateEmail()){
  setValidation('Invalid Email, Please Enter the valid Email Address')
  window.scrollTo(0, 0);
}
else{
  dispatch(updateEditUserStart(temparr))
  history.push('/admin/users');
  setValidation('')
 handleSubmission()
}
}


  return (
    <div style={{ paddingBottom: '200px', marginRight: 20 }}>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={()=>history.push('/adminviewlanding')}
        isActive3={true} label3='Users' value3={() => history.push('/admin/users')} isActive4={false} label4={Mode === 'Edit' ? 'Edit Role' : 'Add Role'}
      />

      <div className='administration_text'>{Mode === 'Edit' ? 'Edit User' : 'Add User' }</div>
     <div style={{ color: '#ED4F32',marginLeft:10 }}>{validation}</div>

      <main className='input_container_user'>
        <div className='container-one'>
          <label>Name *</label>

          <input type='text'
            name='Name'
            value={addUser?.Name}
            placeholder='Example: Balaban, Cosmin'
            onChange={(e) => HandleAddUser(e)}
          />
        </div>
        <div className='container-one'>
          <label>UPN *</label>

          <input type='text'
            name='UPN'
            value={addUser?.UPN}
            placeholder='Example: cosmin.balaban@dxc.com'
            onChange={(e) => HandleAddUser(e)}
          />
        </div>
      </main>

      <main className='input_container_user'>
        <div className='container-one'>
          <label>Email ID *</label>

          <input type='text'
            name='Email'
            value={addUser?.Email}
            placeholder='Example: cosmin.balaban@dxc.com'
            onChange={(e) => HandleAddUser(e)}
          />
        </div>
        <div className='container-one'>
          <DatePickerHandle className='container-three' label='Valid Until' id='label' width='380px'
            value={(addUser?.ValidUntil === undefined || addUser?.ValidUntil === null) ? null : addUser?.ValidUntil}
            onChange={(date) => ValidUntilDateHandler(date)}
          />
        </div>
      </main>

      <main className='input_container_user'>
        <div className='container-one'>
          <label HtmlFor='country'>Country</label>
          <OnboardSelect data={countryInfo} name='Country' value='Country' id='Id' marginTop='10px'
            editValue={addUser?.Country} addValueToQueue={addValueToUser}/>
        </div>
        <div className='container-one'>
          <label>City</label>

          <input type='text'
            name='City'
            value={addUser?.City}
            placeholder='Example: Bucharest'
            onChange={(e) => HandleAddUser(e)}
          />
        </div>
      </main>

      <main className='input_container_user'>
        <div className='container-one'>
          <label HtmlFor='country'>Time Zone</label>
          <OnboardSelect data={timezoneData} name='TimeZone' value='Timezone' id='Id' marginTop='10px'
            editValue={addUser?.TimeZone} addValueToQueue={addValueToUser} />
        </div>
        <div className="radio_buttons" onChange={onChangeValue} style={{marginTop:0}}>Blocked<br />
                    <input type="radio" id="true" name="Blocked" value={true} required
                        className="radio_input_user" checked={addUser?.Blocked === true}/>
                    <label for="valid">Yes</label>
                    <input type="radio" id="false" name="Blocked" value={false} 
                        className="radio_input_user" checked={addUser?.Blocked === false}/>
                    <label for="Exemption">No</label>
                </div>

               
      </main>

      {Mode !== 'Edit' ? <div></div>  :   <main style={{margin:10}}>
        
            <div className='complete_preview'>Photo Upload</div>
            <div className='role_preview'>You can upload only PNG, GIF and JPEG files. Maximum upload file size: 1MB.</div>
            <div id="upload-box">
              <input type="file" name="myImage" id='file' accept="image/png, image/gif, image/jpeg" onChange={handleUpload} />
              <label for="file" style={{ cursor: 'pointer'}}> <span className='browse_link'>BROWSE </span>&nbsp; your files.</label>
            </div>
            <div style={{ color: 'red' }}>{error}</div>
            {datacard && <div className='card__upload'> <span>{file.name}</span>
              <span >{(file.size) / 1024} KB <img src={deleteIcon} alt='del' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} /></span>
            </div>}
      </main>}

    {Mode !== 'Edit' ? <div></div>  : <> <main className='account_role_flex'>
      <div>Role and Account Assignment</div>
      <div><button className='btn_addAccessGroup' onClick={()=>{setShowAddRoleAccountModel(true);setMode('Add');setEditRoleAccountData([])}}>ADD ROLE AND ACCOUNT</button></div>
      </main>


      <main>
      {(addUserTableData).length === 0 ?

<section>    <table style={{ width: '100%', marginTop: '1em',marginLeft:10 }} className='queue-table'>
    <thead>
        <tr>
            {headers.map(header => (
                <th style={{ border: 'none' }} key={header.id}><span className='roles_header'>{header.caption}</span></th>
            ))
            }
            <th style={{ border: 'none' }}>{'  '}</th>
        </tr>
    </thead>
</table>
    <section className='noData-table'>
        <div className='noData-para' style={{ marginTop: 0 }}>
            <div style={{ marginTop: 30 }}>
                No records found.<br /> To add new Role entries, click on "ADD ROLE AND ACCOUNT"  link.
            </div>
        </div>
    </section>
</section>
: <table style={{ width: '100%', marginTop: '1em',marginLeft:10 }} className='queue-table'>
    <thead>
        <tr>
            {headers.map(header => (
                <th style={{ border: 'none' }} key={header.id}><span className='roles_header'>{header.caption}</span></th>
            ))
            }
            <th style={{ border: 'none' }}>{'  '}</th>
        </tr>
    </thead>
    <tbody>
        {
            (addUserTableData).map((data, index) => (
                <tr key={data.id}>
                    <td className='roles_td'>{data?.Role}</td>
                    <td className='roles_td'>{(data?.Customer)}</td>
                    <td className='roles_td'>{data?.Servicedesk}</td>
                    <td className='roles_td'>{data?.Region}</td>
                    <td className='roles_td'>{data?.['Valid From']}</td>
                    <td className='roles_td'>{data?.['Valid To']}</td>
                    <td className='table-button'>
                        <span onClick={()=>{setShowAddRoleAccountModel(true);setEditRoleAccountData(data);setMode('Edit');setTableRow({ ...data, index })}}>
                            <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                        </span>

                        <span onClick={()=>{setDeleteRowid(index);setDeleteRowData(data);setShowDeleteRoleModal(true)}}>
                            <TooltipHandler src={deleteIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Delete' />
                        </span>
                    </td>
                    
                </tr>
            ))
        }
    </tbody>
</table>}
      </main></>}
      
      <main className='Button_container_accessGroup' style={{marginLeft:'500px'}}>
                        <button className='btn_adduser' onClick={()=>{Mode === 'Edit' ? dispEditobj() : dispobj()}}>{Mode === 'Edit' ? 'UPDATE' : 'ADD' }</button>
                        <button className='btn_canceluser' onClick={()=>history.push('/admin/users')}>CANCEL</button>
                    </main>

{showAddRoleAccountModel && <AddRoleAccountPopup isOpen={showAddRoleAccountModel} setShowModal={setShowAddRoleAccountModel}>
<AddRoleAccountModel cancelHandler={()=>setShowAddRoleAccountModel(false)}
saveHandler={()=>setShowAddRoleAccountModel(false)} addValueToTable={addValueToTable} editRoleAccountData={editRoleAccountData}
mode={mode}
/>
</AddRoleAccountPopup>}

{showDeleteRoleModal && <DeleteModalPopup isOpen={showDeleteRoleModal} setShowModal={setShowDeleteRoleModal}>
            <AdminViewDeleteModel modalName='Delete User' buttonOne='YES' buttonTwo='NO'
            InformationText = {deleteRowData.Role}
            AfterInfoText='role?'
            handleDeleteYes={deleteTableRowHandler}
            handleDeleteNo={()=>setShowDeleteRoleModal(false)}
            />
        </DeleteModalPopup>}
      
    </div>


  )
}

export default AddUsers