import React from "react";
import "./stacked_bar_line.css";
import { Chart } from "react-chartjs-2";

const StackedBarLineChart = (props) => {
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
            mode: 'x-axis'
          },
        scales:{
            x: {
                stacked: true,
                title:{
                  display: true,
                  text: `Year/Month`
                }
            },
            y: {
                beginAtZero: true,
                stacked: true,
                type: 'linear',
                position: 'left',
                title:{
                  display: true,
                  text: `${props?.chrtTxtName} Metrics Met and Missed`
                }
            },
            metprcnt: {
              beginAtZero: true,
              stacked: true,
              type: 'linear',
              position: 'right',
              title:{
                display: true,
                text: `${props?.chrtTxtName} Met %`
              },
              grid: {
                drawOnChartArea: false
              },
              ticks: {
                callback: function(value, index, values){
                  return `${value} %`;
                }
              }
            }
        },
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Jai",
          },
          tooltip: {
            displayColors: false,
            backgroundColor: 'white',
            bodyColor: 'black',
            titleMarginBottom: 0,
            borderColor: '#969696',
            borderWidth: 1,
            titleColor: '#63666A',
            titleAlign: 'center',
            titleFont: {weight: '400'},
            displayColors: false,
            padding: 10,
            cornerRadius: 0,
            caretSize:5,
            bodyFont: {weight: 'bolder'},
              callbacks: {
                label: (context) => {
                  if(context.dataset && context.dataset.type === 'line'){
                    let label = "";             
                    if (context.parsed.y) {
                      label = context.parsed.y + "%"
                    }
                    return label; 
                  }else{
                    return context.parsed.y;
                  }                                      
                }
              }
            }        
        }
      };

    const data = {
        labels: props?.data?.Labels,
        datasets: [ 
          {
            type: "bar",
            label: "Metrics Met",
            backgroundColor: "#00A3E1",
            data: props?.data?.MetricMet,
            borderColor: "white",
            borderWidth: 0,
            order: 2,
            yAxisID: 'y'
          },
          {
            type: "bar",
            label: "Missed",
            backgroundColor: "#ED9B33",
            data: props?.data?.Missed,
            borderColor: "white",
            borderWidth: 0,
            order: 3
          },
          {
            type: "line",
            label: "Met %",
            borderColor: "#6CC24A",
            tension: 0.1,         
            fill: false,
            data: props?.data?.MetPrcnt,
            order: 1,
            yAxisID: 'metprcnt'
          }
        ]       
      };
    return (
        <div>
            <Chart data={data} options={options}/>
        </div>
    );
};

export default StackedBarLineChart;