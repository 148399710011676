import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory} from 'react-router-dom'
import { getRolesTitleInfo, getWidgetPreference } from '../../../actions/welcomePage.action';
import FirstVisitor from './FirstVisitor';

function UserLandingPage() {
    const history = useHistory();
    let dispatch = useDispatch();
    let RolesInfo = useSelector((state) => state.welcomePageReducer.rolesTitle);
    let widgetPreferences = useSelector((state) => state.welcomePageReducer.widgetPreferences);

    useEffect(() => {
        dispatch(getRolesTitleInfo());
        dispatch(getWidgetPreference());
    }, [dispatch]);
    const UserLandingHandler = () => {
        switch (RolesInfo.length) {
            case 0: return <FirstVisitor />
            case 1: {
                localStorage.setItem('Role_id', RolesInfo?.[0]?.id);
                localStorage.setItem('SingleRolesUser', true);
                widgetPreferences?.WidgetSelectorShown === 'true' ? history.push('/welcome') : history.push('/widget');
                break;
            }
            default: history.push('/role');
                localStorage.setItem('SingleRolesUser', false);

        }

    }
    return (<>
        {UserLandingHandler()}
    </>)
}

export default UserLandingPage