import React from "react";
import { Button } from "react-bootstrap";
import "./legend.css";
import DownloadIcon from "../../assets/images/Download_Normal.png";
import AboveRequiredTargetIcon from "../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../assets/images/BelowMinimalTarget_Status.png";
import ContractualSLAIcon from "../../assets/images/Contractual_SLAs.png";
import ConstructURLs from "../../constants/urls";

const LegendComponent = (props) => {   
   const getURL = () => { 
    let urll = ConstructURLs('DOWNLOAD_EXCEL_DATA');
    window.location.href = urll;
   }

const globalAccountsHandler = () => {
  props.glblSmmryBtnClicked();
}

const globalSummaryHandler = () => {
  props.glblSummaryBtnClicked();
}

const regionalSummaryHandler = () => {
  props.regnlSummaryBtnClicked();
}
    return(
      <div className="flex-container-legend">
      <div className="items-text-center">Legend:</div>
      <div className="items-text-center"><span><img src={AboveRequiredTargetIcon} className="margn-5"  alt="AbvRqdTgt Icon" /></span><span className="margn-5">Above Required Target</span></div>
      <div className="items-text-center"><span><img src={AboveMinimalTargetIcon}  className="margn-5"  alt="AbvMnmlTgt Icon" /></span><span className="margn-5">Above Minimal Target</span></div>  
      <div className="items-text-center"><span><img src={BelowMinimalTargetIcon}  className="margn-5"  alt="BlwMnmlTgt Icon" /></span><span className="margn-5">Below Minimal Target</span></div>
      { props.shwSLA ? (<div className="items-text-center"><span><img src={ContractualSLAIcon} className="margn-5"  alt="ContractualSLA Icon" /></span><span className="margn-5">Contractual SLAs</span></div>) : null}
      { props.shwDwnBtn ? (<div className="items-text-center"><Button onClick={getURL}  variant="outline-secondary"><img src={DownloadIcon} height="20" width="20" className="margn-5" alt="Download Icon" />Download</Button></div>) : null }
      { props.shwGlbBtn ? (<div className="items-text-center"><Button onClick={globalAccountsHandler} className='glb-btn'  variant="outline-secondary">Global Summary</Button></div>) : null }
      { props.shwGlbSmmryBtn ? (<div className="items-text-center"><Button onClick={globalSummaryHandler}  variant="outline-secondary"><img src={DownloadIcon} height="20" width="20" className="margn-5" alt="Download Icon" />Download</Button></div>) : null }
      { props.shwRgnlSmmryBtn ? (<div className="items-text-center"><Button onClick={regionalSummaryHandler}  variant="outline-secondary"><img src={DownloadIcon} height="20" width="20" className="margn-5" alt="Download Icon" />Download</Button></div>) : null }
    </div>
    );
}

export default LegendComponent;