export const onboard_actionTypes = {
    // Save redux Items
    SAVE_ACCOUNT_SITE: 'SAVE_ACCOUNT_SITE',
    SAVE_CANCEL_CLICK: "SAVE_CANCEL_CLICK",



    // General Info

    GET_ACCOUNT_SITE: "GET_ACCOUNT_SITE",
    ACCOUNT_SITE: "ACCOUNT_SITE",

    GET_GENERAL_INFO_SITE: "GET_GENERAL_INFO_SITE",
    GENERAL_INFO_SITE: "GENERAL_INFO_SITE",

    // ROLES TAB DATA

    GET_ROLES_TAB_ROLES: "GET_ROLES_TAB_ROLES",
    ROLES_TAB_ROLES: "ROLES_TAB_ROLES",

    GET_ROLES_TAB_USERS: "GET_ROLES_TAB_USERS",
    ROLES_TAB_USERS: "ROLES_TAB_USERS",

    UPDATE_ROLE_DATA_START: "UPDATE_ROLE_DATA_START",
    UPDATE_ROLE_DATA_SUCCESS: "UPDATE_ROLE_DATA_SUCCESS",



    UPDATE_QUEUE_TABLE_START: "UPDATE_QUEUE_TABLE_START",
    UPDATE_QUEUE_TABLE_SUCCESS: "UPDATE_QUEUE_TABLE_SUCCESS",

    UPDATE_SLA_TABLE_START: "UPDATE_SLA_TABLE_START",
    UPDATE_SLA_TABLE_SUCCESS: "UPDATE_SLA_TABLE_SUCCESS",


    UPDATE_SUPPORT_TABLE_START: "UPDATE_SUPPORT_TABLE_START",
    UPDATE_SUPPORT_TABLE_SUCCESS: "UPDATE_SUPPORT_TABLE_SUCCESS",

    UPDATE_NEW_TEMPLATE_START: "UPDATE_NEW_TEMPLATE_START",
    UPDATE_NEW_TEMPLATE_SUCCESS: "UPDATE_NEW_TEMPLATE_SUCCESS",

    GET_QUEUE_LANGUAGES: "GET_QUEUE_LANGUAGES",
    QUEUE_LANGUAGES: "QUEUE_LANGUAGES",

    GET_ORIGINATING_COUNTRIES: "GET_ORIGINATING_COUNTRIES",
    ORIGINATING_COUNTRIES: "ORIGINATING_COUNTRIES",

    GET_QUEUE_TABLE_DATA: "GET_QUEUE_TABLE_DATA",
    QUEUE_TABLE_DATA: "QUEUE_TABLE_DATA",

    GET_SLA_TEMPLATES: "GET_SLA_TEMPLATES",
    SLA_TEMPLATES: "SLA_TEMPLATES",

    GET_SLA_KPI_DATA: "GET_SLA_KPI_DATA",
    SLA_KPI_DATA: "SLA_KPI_DATA",



    GET_NON_VOICE_SLA_DATA: "GET_NON_VOICE_SLA_DATA",
    NON_VOICE_SLA_DATA: "NON_VOICE_SLA_DATA",

    GET_NON_VOICE_SUPPORT_HOURS: "GET_NON_VOICE_SUPPORT_HOURS",
    NON_VOICE_SUPPORT_HOURS: "NON_VOICE_SUPPORT_HOURS",








    UPDATE_GENERAL_NON_VOICE_START: "UPDATE_GENERAL_NON_VOICE_START",
    UPDATE_GENERAL_NON_VOICE_SUCCESS: "UPDATE_GENERAL_NON_VOICE_SUCCESS",

    GET_GENERAL_NON_VOICE: "GET_GENERAL_NON_VOICE",
    GENERAL_NON_VOICE: "GENERAL_NON_VOICE",


    GET_ROLES_TAB_DATA: "GET_ROLES_TAB_DATA",
    ROLES_TAB_DATA: "ROLES_TAB_DATA",

    GET_SUPPORT_HOURS_VOICE: "GET_SUPPORT_HOURS_VOICE",
    SUPPORT_HOURS_VOICE: "SUPPORT_HOURS_VOICE",

    GET_PREVIEW_ONBOARD: "GET_PREVIEW_ONBOARD",
    PREVIEW_ONBOARD: "PREVIEW_ONBOARD",


    GET_ONBOARD_ACCOUNTS: "GET_ONBOARD_ACCOUNTS",
    ONBOARD_ACCOUNTS: "ONBOARD_ACCOUNTS",

    VIEW_ACCOUNT_DETAILS: "VIEW_ACCOUNT_DETAILS",
    EDIT_ACCOUNT_DETAILS: "EDIT_ACCOUNT_DETAILS",

    UPDATE_ONBOARD_TOGGLE_START: "UPDATE_ONBOARD_TOGGLE_START",
    UPDATE_ONBOARD_TOGGLE_SUCCESS: "UPDATE_ONBOARD_TOGGLE_SUCCESS",

    UPDATE_ONBOARD_SUBMIT_START: "UPDATE_ONBOARD_SUBMIT_START",
    UPDATE_ONBOARD_SUBMIT_SUCCESS: "UPDATE_ONBOARD_SUBMIT_SUCCESS",


    SET_WIDGET_CARD_NON_VOICE: "SET_WIDGET_CARD_NON_VOICE",
    SET_WIDGET_PREVIEW_TAB: "SET_WIDGET_PREVIEW_TAB",
    SET_VALIDFOR_ADDING_TABROWS: "SET_VALIDFOR_ADDING_TABROWS",

    GET_ONBOARD_VALID_USER: "GET_ONBOARD_VALID_USER",
    ONBOARD_VALID_USER: "ONBOARD_VALID_USER",

    UPDATE_REOPEN_REQUEST_START: "UPDATE_REOPEN_REQUEST_START",
    UPDATE_REOPEN_REQUEST_SUCCESS: "UPDATE_REOPEN_REQUEST_SUCCESS",

    UPDATE_DELETE_ACCOUNT_START: "UPDATE_DELETE_ACCOUNT_START",
    UPDATE_DELETE_ACCOUNT_SUCCESS: "UPDATE_DELETE_ACCOUNT_SUCCESS",
}