import { all, call, put, takeEvery } from "redux-saga/effects";
import {ScoreCardUrls} from '../constants/ScoreCard&TeamKPI-urls';
import { getAgentsGraphDataService, getAgentsKPIAccountsDataService, getAgentsKPISummaryDataService, getScoreCardDataService, getScoreCardOffCanvasDataService, getScoreCardPerformanceDataService, getTeamKPISummaryDataService, getTeamleaderAccountsDataService, getTeamleaderAccountsViewDataService, getTeamleaderDatesDataService } from "../services/ScoreCard&TeamKPI.service";
import {ScoreCardActionTypes as actionTypes} from "../constants/ScoreCard&TeamKPI-actionTypes";


function* fetchScoreCardData({selecteddate,agent}) {
    let items={
        selecteddate:selecteddate,
        agent:agent
    }
    try {
        const response = yield call(getScoreCardDataService, ScoreCardUrls('GET_SCORECARD_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.SCORECARD_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading ScoreCard data", error);
    }
}

function* fetchScoreCardPerformanceData(action) {
    try {
        const response = yield call(getScoreCardPerformanceDataService, ScoreCardUrls('GET_SCORECARD_PERFORMANCE_DATA'));
        if (response.data) {
            yield put({
                type: actionTypes.SCORECARD_PERFORMANCE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading ScoreCard Performance data", error);
    }
}

function* fetchScoreCardOffCanvasData({agent}) {
    let items={
        agent:agent,
    }
    try {
        const response = yield call(getScoreCardOffCanvasDataService, ScoreCardUrls('GET_SCORECARD_OFFCANVAS_DATA',items));
        console.log('offcanvasdata',response)
        if (response.data) {
            yield put({
                type: actionTypes.SCORECARD_OFFCANVAS_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading ScoreCard offcanvas data", error);
    }
}

function* fetchAgentsKPISummaryData({name,account,date,selectedtab}) {
    let items = {
        name: name,
    account: account,
    date:date,
    selectedtab:selectedtab
    }
    try {
        const response = yield call(getAgentsKPISummaryDataService, ScoreCardUrls('GET_AGENTS_KPI_SUMMARY_DATA',items));
        console.log('response:::::::',response.data,items.selectedtab)
        if (response.data) {
            yield put({
                type: actionTypes.AGENTS_KPI_SUMMARY_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Agents KPI Summary data", error);
    }
}

function* fetchTeamleaderAgentsData({accountID}) {
    let items = {
        accountID: accountID,
    }
    try {
        const response = yield call(getTeamKPISummaryDataService, ScoreCardUrls('GET_TEAMLEADER_AGENTS',items));
        if (response.data) {
            yield put({
                type: actionTypes.TEAMLEADER_AGENTS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Teamleader Agents data", error);
    }
}

function* fetchTeamleaderAccountsData({agents}) {
    let items = {
        agents: agents,
    }
    try {
        const response = yield call(getTeamleaderAccountsDataService, ScoreCardUrls('GET_TEAMLEADER_ACCOUNTS',items));
        if (response.data) {
            yield put({
                type: actionTypes.TEAMLEADER_ACCOUNTS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Teamleader Accounts data", error);
    }
}

function* fetchTeamleaderAccountsViewData(action) {
    try {
        const response = yield call(getTeamleaderAccountsViewDataService, ScoreCardUrls('GET_TEAMLEADER_ACCOUNTS_VIEW'));
        if (response.data) {
            yield put({
                type: actionTypes.TEAMLEADER_ACCOUNTS_VIEW,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Teamleader Accounts View data", error);
    }
}

function* fetchTeamleaderDatesData(action) {
    try {
        const response = yield call(getTeamleaderDatesDataService, ScoreCardUrls('GET_TEAMLEADER_DATES'));
        if (response.data) {
            yield put({
                type: actionTypes.TEAMLEADER_DATES,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Teamleader Dates data", error);
    }
}

function* fetchAgentsKPIAccountsData({email}) {
    let items = {
        email: email,
    }
    try {
        const response = yield call(getAgentsKPIAccountsDataService, ScoreCardUrls('GET_AGENTS_KPI_ACCOUNTS_DATA',items));
        console.log('response:::::::',response.data,items.selectedtab)
        if (response.data) {
            yield put({
                type: actionTypes.AGENTS_KPI_ACCOUNTS_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Agents KPI Accounts data", error);
    }
}

function* fetchTeamKPISummaryData({date}) {
    let items = {
    date:date,
    }
    try {
        const response = yield call(getTeamKPISummaryDataService, ScoreCardUrls('GET_TEAM_KPI_SUMMARY_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.TEAM_KPI_SUMMARY_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Team KPI Summary data", error);
    }
}

function* fetchAgentsGraphData({agent,teamKPI,metric,date}) {
    let items = {
        agent: agent,
    teamKPI: teamKPI,
    metric: metric,
    date:date,
        }
    try {
        const response = yield call(getAgentsGraphDataService, ScoreCardUrls('GET_AGENTS_GRAPH_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.AGENTS_GRAPH_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Loading Agents Graph data", error);
    }
}

function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_SCORECARD_DATA, fetchScoreCardData);
    yield takeEvery(actionTypes.GET_SCORECARD_PERFORMANCE_DATA, fetchScoreCardPerformanceData);
    yield takeEvery(actionTypes.GET_SCORECARD_OFFCANVAS_DATA, fetchScoreCardOffCanvasData);
    yield takeEvery(actionTypes.GET_AGENTS_KPI_SUMMARY_DATA, fetchAgentsKPISummaryData);
    yield takeEvery(actionTypes.GET_TEAMLEADER_AGENTS, fetchTeamleaderAgentsData);
    yield takeEvery(actionTypes.GET_TEAMLEADER_ACCOUNTS, fetchTeamleaderAccountsData);
    yield takeEvery(actionTypes.GET_TEAMLEADER_ACCOUNTS_VIEW, fetchTeamleaderAccountsViewData);
    yield takeEvery(actionTypes.GET_TEAMLEADER_DATES, fetchTeamleaderDatesData);
    yield takeEvery(actionTypes.GET_AGENTS_KPI_ACCOUNTS_DATA, fetchAgentsKPIAccountsData);
    yield takeEvery(actionTypes.GET_TEAM_KPI_SUMMARY_DATA, fetchTeamKPISummaryData);
    yield takeEvery(actionTypes.GET_AGENTS_GRAPH_DATA, fetchAgentsGraphData);

}
export default function* ScoreCardSagas() {
    yield all([watchGetRequest()]);
}