import React, { Fragment, useEffect } from 'react'
import { useState } from 'react';
import { ModalAccountPopup } from '../Onboard/OnboardComponents/Modals/ModalQueuePopup';
import searchIcon from './../../assets/onboard-images/Search_Icon.png';
import './Request.css';
import UserReuqestModal from './RequestModals';
import AdminBreadcrumb from '../Administration/General/Admin-breadcrumb';
import filterIcon from '../../assets/images/Filter_Icon.png';
import RefreshIcon from '../../assets/images/Reset_Icon.png';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Button from './../ReportTables/ReportFilters/Button';
import DATA from './DATA.json';
import RequestTableHandle from './RequestTableHandle';
import '../Administration/SLA-Administration/SLA-Admin.css';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../actions/user.action';
import RequestDropdown from '../Onboard/OnboardComponents/Dropdown/RequestDropdown';
import { getRequestData, getRequestDropdownData, updateEditRequestStart, updateNewRequestStart } from './../../actions/request.action';
import { useHistory } from 'react-router-dom';
import axios from 'axios';


const RequestIncidentMain = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    let RequestData = useSelector((state) => state.requestReducer.RequestData);
    let RequestDropdownData = useSelector((state) => state.requestReducer.RequestDropdownData);
    useEffect(() => {
        dispatch(getUserData());
        dispatch(getRequestData('', '', ''));
        dispatch(getRequestDropdownData());
    }, [dispatch]);
    const [RequestIncidentData, setRequestIncidentData] = useState([])
    const [showAddRequestModel, setShowAddRequestModel] = useState(false);
    const [showEditRequestModal, setShowEditRequestModal] = useState(false);
    const [filter, setFilter] = useState('');
  const [updatedFileData, setUpdatedFileData] = useState()
    const [searchColumns, setSearchColumns] = useState(['Request / Incident Title']);
    const [reuqestDate, setRequestDate] = useState(new Date(''));
    function RequestDateFormat(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    useEffect(() => {
        setRequestIncidentData((RequestData.statusCode) ? [] : RequestData)
    }, [RequestData])


    function searchRows(rows) {
        return rows.filter((row) =>
            searchColumns.some(
                (column) =>
                    row[column]
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.toLowerCase()) > -1,
            ),
        );
    }

    const [AddRequestForm, setAddRequestForm] = useState({});
    const [editRequestID, setEditRequestID] = useState(null);
    const [EditRequestForm, setEditrequestForm] = useState();


    const [validation, setValidation] = useState('');
    const addValueToRequest = (name, value) => {
        const newFormData = { ...AddRequestForm };
        if (name !== 'Select') {
            newFormData[name] = value;
            setAddRequestForm(newFormData)
        }
    }

    const handleUserAddRequest = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...AddRequestForm };
            newFormData[fieldName] = fieldValue;
            setAddRequestForm(newFormData)
        }
    }

    function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    };
    function formatTime() {
        return new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds();
    }

const[uploadedFileId,setUploadedFileId] = useState();
const[uploadedFileName,setUploadedFileName] = useState()
useEffect(()=>{
    if(updatedFileData){
        const formData = new FormData();

    formData.append('File', updatedFileData);

    fetch(
        `${
    process.env.REACT_APP_BASE_URL
  }/api/file`,
        {
            method: 'POST',
            body: formData,
        }
    )
        .then((response) => response.json())
        .then((result) => {
            console.log('Success:', result);
            setUploadedFileId(result.id);
        })
        .catch((error) => {
            console.error('Error:', error);
        });

    }
},[updatedFileData]) 

const deleteFile = async (fileId) => {
    try {
      const response = await axios.delete(`/api/file/${fileId}`);
      if (response.status === 200) {
        setUploadedFileId(null);
        setUploadedFileName(null)
      } else {
        alert('file not deleted')
      }
    } catch (error) {
      // Handle error.
    }
  };
    
const DeleteUploadedFile = () =>{
    deleteFile(uploadedFileId);
    }
    
    console.log('uploadedFileId',uploadedFileId,uploadedFileName)

    const handleRequestFormSubmit = event => {
        event.preventDefault();
        console.log(AddRequestForm);
        const temp_details = {

            id: RequestIncidentData?.length + 1,
            'Request / Incident Title': AddRequestForm.title,
            'Created Date': formatDate(),
            Type: AddRequestForm.type,
            Status: 'New',

        }
        let temp_updates = {
            UserComments: [{
                'Last Updated Date': `${formatDate()}, ${formatTime()}`,
                Comments: AddRequestForm.comments,
                FileStore: uploadedFileId,
                FileName: uploadedFileName
            }]
        }


        const newRequest = Object.assign(temp_details, temp_updates)
        const newRequests = [newRequest, ...RequestIncidentData]
        const temp_Arr = {
            type: AddRequestForm.type,
            title: AddRequestForm.title,
            comments: AddRequestForm.comments
        }
        let dispatchObj = {
            Title: AddRequestForm.title,
            Type: AddRequestForm.type,
            Status: 'New',
            Comments: AddRequestForm.comments,
            FileStore: uploadedFileId,
            FileName: uploadedFileName
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        if (isEmptyInput) {
            setShowAddRequestModel(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else {
            setRequestIncidentData(newRequests)
            setShowAddRequestModel(false);
            dispatch(updateNewRequestStart(dispatchObj))
            setValidation('')
        }
    }
    const handleRequestFormCancel = event => {
        event.preventDefault();
        setShowAddRequestModel(false);
        setValidation('')
    }
const [CompleteTask,setCompleteTask] = useState('')
    const HandleEditRequestClick = (info) => {
        setEditRequestID(info.IncidentID)
        setShowEditRequestModal(true)
        const formValues = {
            type: info?.Type,
            status: info?.Status,
            title: info?.['Request / Incident Title'],
            createdDate: info?.['Created Date'],
            comments: '',
            file: info?.FileStore,
            UserDetails: info?.TaskDetails?.[0]?.UserDetails,
            ValidUntil: info?.TaskDetails?.[0]?.ValidUntil,
        };
        console.log('formValues', formValues)
        setEditrequestForm(formValues);
        setCompleteTask(info?.TaskDetails?.[0]?.ValidUntil)
    }

  //  console.log('CompleteTask2',CompleteTask,EditRequestForm?.ValidUntil)

    const onClickSelect = (name) => {
        const newFormData = { ...EditRequestForm };
        newFormData[name] = '';
        setEditrequestForm(newFormData)

    }

    const editValueToRequest = (name, value) => {
        const newFormData = { ...EditRequestForm };
        if (value !== 'Select') {
            newFormData[name] = value;
            setEditrequestForm(newFormData);
        }
    }
    const handleUserEditRequest = event => {
        event.preventDefault();
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...EditRequestForm };
        newFormData[fieldName] = fieldValue;
        setEditrequestForm(newFormData);
    }
    console.log('CompleteTask',formatDate(CompleteTask),(EditRequestForm?.ValidUntil))
    console.log('EditRequestForm:::', EditRequestForm)
    // submit
    const handleRequesEditSubmit = event => {
        event.preventDefault();
        const temp_details = {
            IncidentID: editRequestID,
            'Request / Incident Title': EditRequestForm.title,
            'Created Date': formatDate(EditRequestForm.createdDate),
            Type: EditRequestForm.type,
            Status: EditRequestForm.status,
            TaskDetails: [
                {
                    UserDetails: EditRequestForm.UserDetails,
                    ValidUntil: EditRequestForm.ValidUntil
                }
            ]
        }
        let temp_updates = {
            'Last Updated Date': `${formatDate()}, ${formatTime()}`,
            Comments: EditRequestForm.comments,
            FileStore: uploadedFileId,
            FileName: uploadedFileName
        }
        const dispatchEditObj = {
            Title: EditRequestForm.title,
            Type: EditRequestForm.type,
            Status: EditRequestForm.status,
            Comments: EditRequestForm.comments,
            IncidentID: editRequestID,
            ValidUntil: EditRequestForm.ValidUntil,
            FileStore: uploadedFileId,
            FileName: uploadedFileName
        }
        const newRequests = [...RequestIncidentData];
        const temp_Arr = {
            status: EditRequestForm.status,
            comments: EditRequestForm.comments
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        console.log('CompleteTask',formatDate(CompleteTask),formatDate(EditRequestForm.ValidUntil))
        if (isEmptyInput) {
            setShowEditRequestModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else if(EditRequestForm.type === 'Task' && EditRequestForm.status !== 'Completed'){
            setShowEditRequestModal(true);
            setValidation('Please Ensure that Status Selected is set to Completed to Update the Role Validity')
        }
        else {
            const index = RequestIncidentData.findIndex((data, index) => data.IncidentID === editRequestID);
            let editedRequestUpdates = newRequests[index]?.UserComments;
            editedRequestUpdates.unshift(temp_updates)
            let obj2 = { UserComments: editedRequestUpdates }
            let adminRequestUpdates = newRequests[index]?.AdminComments;
            let admindata = { AdminComments: adminRequestUpdates }
            const newRequest = Object.assign(temp_details, obj2, admindata)
            newRequests[index] = newRequest;
            setRequestIncidentData(newRequests);
            dispatch(updateEditRequestStart(dispatchEditObj))
            setEditRequestID(null);
            setShowEditRequestModal(false)
            setValidation('');                     
        }
    }

    console.log('RequestIncidentData', RequestIncidentData)
    const handleRequestEditCancel = event => {
        event.preventDefault();
        setEditRequestID(null);
        setShowEditRequestModal(false);
        setValidation('')
    }



    const [filterSelected, setFilterSelected] = useState('');
    const [statusSelected, setStatusSelected] = useState('');
    const [selectedType, setSelectedType] = useState('')
    const [isRefreshClicked, setIsRefreshedClicked] = useState(false);


    useEffect(() => {
        if (filterSelected || statusSelected || selectedType) {
            dispatch(getRequestData(filterSelected ? filterSelected : '', selectedType ? selectedType : '', statusSelected ? statusSelected : ''));
        }
    }, [dispatch, filterSelected, selectedType, statusSelected]);

    return (
        <>
            <Fragment>
                <section style={{ width: '98%' }}>
                    <main>
                        <AdminBreadcrumb value1={() => history.push('/welcome')} label1='Home' isActive1={false} label2='Requests' />
                        <header className='request_header'>Requests</header>
                        <header className='sla_date_filter_tab' style={{ marginLeft: '15px', width: '98%' }}>
                        <main className='exclude_refresh'>
                            <span className='filter_icon'><img src={filterIcon} alt='' /></span>
                            <span style={{ marginTop: '15px' }}><label id='label'>Date</label>&nbsp;<br />
                                <KeyboardDatePicker
                                    autoOk
                                    variant="inline"
                                    name='request_date'
                                    clearable
                                    value={reuqestDate}
                                    placeholder="MM/DD/YYYY"
                                    onChange={(date) => {
                                        setRequestDate(RequestDateFormat(date));
                                        setFilterSelected(RequestDateFormat(date));
                                    }}
                                    format="MM/DD/YYYY"
                                    style={{ width: '250px' }}
                                    error={false}
                                    helperText={null}
                                />
                            </span>
                            <span className='dropdown_sla'> Status
                                <RequestDropdown data={DATA.Status} name='yearMonth' value='value' id='id'
                                    addValueToQueue={(name, value) => { setStatusSelected(value); setIsRefreshedClicked(false) }} refresh={isRefreshClicked}
                                />
                            </span>
                            <span className='dropdown_sla'> Type
                                <RequestDropdown data={RequestDropdownData?.[0]?.filter?.Type} name='Type' value='Type' id='id'
                                    addValueToQueue={(name, value) => { setSelectedType(value); setIsRefreshedClicked(false) }} refresh={isRefreshClicked}

                                />
                            </span>
</main>
<main>
                            <div className='container-reset-request' >
                                <Button className={`reset-icon download-btn `} item='Refresh Table'
                                    onClick={() => {
                                        setRequestDate(null)
                                        setStatusSelected('')
                                        setFilterSelected('')
                                        setIsRefreshedClicked(true)
                                        setSelectedType('')
                                        dispatch(getRequestData('', '', ''));
                                    }} icon={RefreshIcon} /></div>
                                    </main>
                        </header>
                    </main>
                    <main className='hints_row' style={{ marginTop: 30, marginLeft: 15, width: '98%' }}>
                        <header className='search_adduser'>

                            <div> <span className='search-bar-role'>
                                <input className='search-input-role' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Request/Incident Title' />
                                <button className='search-icon'><img src={searchIcon} alt='' /> </button>
                            </span></div>
                            <div>
                                <button type='add' className='add_request_btn' onClick={() => { setShowAddRequestModel(true) }}>
                                    <span className='add_request_span' >Add Request / Incident</span>
                                </button>
                            </div>
                        </header>

                        <section className='sla_exemption_table' style={{ width: '100%' }}>
                            <table  >
                                <thead style={{ width: '100%' }} >
                                    <tr style={{ position: 'sticky', top: -1, backgroundColor: '#F6F8FA' }}>{DATA.UserHeader.map(data => (<th style={{ border: 'none' }} key={data.id}>{data.value}</th>))}</tr>   </thead>
                                <tbody style={{ minHeight: '0px' }} >
                                    {searchRows(RequestIncidentData)?.map((info, i) =>

                                        <RequestTableHandle key={i} info={info} HandleEditRequestClick={HandleEditRequestClick} />)}
                                </tbody>
                            </table>
                        </section>
                    </main>
                </section>

            </Fragment>

            {showAddRequestModel && <ModalAccountPopup isOpen={showAddRequestModel} setShowModal={setShowAddRequestModel}>
                <UserReuqestModal
                    editMode={false} buttonOne='ADD' buttonTwo='CANCEL' addValueToQueue={addValueToRequest}
                    handleUserAddRequest={handleUserAddRequest} handleRequestFormCancel={handleRequestFormCancel}
                    handleRequestFormSubmit={handleRequestFormSubmit} onClickSelect={() => { }}
                    EditRequestForm={EditRequestForm} validation={validation} dispatchFileUpload={data =>
            setUpdatedFileData(data)} dispatchFileName={data => setUploadedFileName(data)} DeleteUploadedFile={DeleteUploadedFile}
                />
            </ModalAccountPopup>
            }
            {showEditRequestModal && <ModalAccountPopup isOpen={showEditRequestModal} setShowModal={setShowEditRequestModal}>
                <UserReuqestModal
                    editMode={true} buttonOne='UPDATE' buttonTwo='CANCEL' addValueToQueue={editValueToRequest}
                    handleUserAddRequest={handleUserEditRequest} handleRequestFormCancel={handleRequestEditCancel}
                    handleRequestFormSubmit={handleRequesEditSubmit} onClickSelect={onClickSelect}
                    EditRequestForm={EditRequestForm} validation={validation}  dispatchFileUpload={data =>
            setUpdatedFileData(data)} dispatchFileName={data => setUploadedFileName(data)} DeleteUploadedFile={DeleteUploadedFile}

                />
            </ModalAccountPopup>
            }


        </>
    )
}

export default RequestIncidentMain