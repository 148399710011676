import React from 'react'
import './ClearFilter.css'


function ClearFilter(props) {
   return (
        <React.Fragment >
            <section className='clearfilter-tab'  >
                <span className='para-filter'>
                    Filtered by:
                </span>

                <div >
                    {props.filteredPeriod.map(data => (
                        <div className='selected-filters' key={data} >
                            <span className='filters'>{data}</span>
                        </div>
                    ))}
                </div>
                <button className='clear-filters' onClick={props.onClearingFilters}>
                    <span className='para-clear'>Clear filters </span>
                </button>
            </section>
        </React.Fragment>
    )
}

export default ClearFilter
