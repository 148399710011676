import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { OnboardSelect } from '../../../Onboard/OnboardComponents/Dropdown/Select';
import DatePickerHandle from '../../../Onboard/OnboardComponents/KeyboardDatePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralInfoSites, getRolesTabRoles } from '../../../../actions/Onboard.action';
import { getStaffAccounts } from '../../../../actions/Administration/staffAdmin.action';
import { getRegionData, saveUserStoredStatus } from '../../../../actions/Administration/adminView.action';
import downward_icon from './../../../../assets/images/downward_arrow.png';
import upward_icon from './../../../../assets/images/upward_arrow.png';
import contractIcon from './../../../../assets/onboard-images/Contractual_SLA_Icon.png'

export function AddRoleAccountModel(props) {
    const dispatch = useDispatch();
    
    let Rolename = useSelector((state) => state.onboardReducer.roleTabRoles);
    const SiteData = useSelector((state) => state.onboardReducer.generalSiteInfo);
    const AccountsInfo = useSelector((state) => state.staffAdminReducer.staffAccounts);
    const regionData = useSelector((state) => state.adminViewReducer.regionData);

    useEffect(() => {
        dispatch(getRolesTabRoles());
        dispatch(getGeneralInfoSites());
        dispatch(getStaffAccounts());
        dispatch(getRegionData())
    }, [dispatch])

    const [addRoleAccount, setAddRoleAccount] = useState(props.mode === 'Edit' ? props.editRoleAccountData  : [])
    const addValueToRoleAccount = (name, value) => {
        const newFormData = { ...addRoleAccount };
        if (name !== 'Select') {
            newFormData[name] = value;
            setAddRoleAccount(newFormData)
        }
    }

    useEffect(() => {
        if (props.mode === 'Edit'){
        setValidFrom(props.editRoleAccountData?.['Valid From']);
        setValidTo(props.editRoleAccountData?.['Valid To'])
        }
    }, [dispatch,props.editRoleAccountData,props.mode])

    const [ValidFrom, setValidFrom] = useState(null);
    const [ValidTo, setValidTo] = useState(null);
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    const ValidFromDateHandler = (date) => {
        setAddRoleAccount({ ...addRoleAccount, 'Valid From': (date === null || date === undefined) ? null : formatDate(date) });
        setValidFrom((date === null || date === undefined) ? null : formatDate(date));
    }

    const ValidToDateHandler = (date) => {
        setAddRoleAccount({ ...addRoleAccount, 'Valid To': (date === null || date === undefined) ? null : formatDate(date) });
        setValidTo((date === null || date === undefined) ? null : formatDate(date));

    }
    
const [validation, setValidation] = useState(false);

    const SubmitHandler = () => {
        if(addRoleAccount.Role === undefined){
            setValidation(true)
        }
        else{
        props.addValueToTable(addRoleAccount)
        props.saveHandler();
        setValidation(false)
        }
    }

    console.log('addRoleAccount',props.editRoleAccountData)
    return <>
        <section className="UserModel_container">
            <div className='user_model_title' style={{marginLeft:10}}>
              {props.mode === 'Edit' ? 'Edit'  :  'Add' } Role and Account
            </div>
            {validation && <div style={{ color: '#ED4F32',marginLeft:10 }}>Please Ensure you filled all the inputs with  " * " mark </div>}
            <main className='input_container_user_model'>
                <div className='container_one_user'>
                    <label HtmlFor='country'>Role *</label>
                    <OnboardSelect data={Rolename} name='Role' value='RoleName' id='RoleId' marginTop='10px'
                        editValue={props.editRoleAccountData.Role} addValueToQueue={addValueToRoleAccount} />
                </div>
                <div className='container_one_user'>
                    <label HtmlFor='country'>Site</label>
                    <OnboardSelect data={SiteData} name='Servicedesk' value='SiteName' id='SiteId' marginTop='10px'
                        editValue={props.editRoleAccountData.Servicedesk} addValueToQueue={addValueToRoleAccount} />
                </div>
            </main>

            <main className='input_container_user_model'>
                <div className='container_one_user'>
                    <label HtmlFor='country'>Region</label>
                    <OnboardSelect data={regionData} name='Region' value='Region' id='Id' marginTop='10px'
                        editValue={props.editRoleAccountData.Region} addValueToQueue={addValueToRoleAccount} />
                </div>
                <div className='container_one_user'>
                    <label HtmlFor='country'>Account</label>
                    <OnboardSelect data={AccountsInfo} name='Customer' value='customerName' id='Id' marginTop='10px'
                        editValue={props.editRoleAccountData.Customer} addValueToQueue={addValueToRoleAccount} />
                </div>
            </main>

            <main className='input_container_user_model'>
                <div className='container_one_user'>
                    <DatePickerHandle className='container-three' label='Valid From' id='label' width='315px'
                        value={ValidFrom}
                        onChange={(date) => ValidFromDateHandler(date)}
                    />
                </div>
                <div className='container_one_user'>
                    <DatePickerHandle className='container-three' label='Valid To' id='label' width='315px'
                        value={ValidTo}
                        onChange={(date) => ValidToDateHandler(date)}
                    />
                </div>
            </main>

            <main className='Button_container_accessGroup' style={{ marginTop: '15px', marginLeft: '5em' }}>
                <button className='btn_adduser' onClick={SubmitHandler}>{props.mode === 'Edit' ? 'UPDATE' : 'ADD' }</button>
                <button className='btn_canceluser' onClick={props.cancelHandler}>CANCEL</button>
            </main>
        </section>
    </>
}

export function AddRoleAccountPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '24%',
                    left: '25%',
                    width: '700px',
                    height: '430px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}


export function UserDropdown(props) {
    const dispatch = useDispatch();
    // let saveUserStoredStatus = useSelector((state) => state.adminViewReducer.saveUserStoredStatus);
    // console.log('saveUserStoredFilter',saveUserStoredStatus)
    const { data, value, id, className, name, onChange, editValue } = props;
    const [openData, setOpenData] = useState(false);
    const [Inputvalue, setInputValue] = useState(props.inputvalue);
    const [query, setQuery] = useState('');
    const filterQuery = options => {
        return options?.filter(option => {
            if (query === '') return option;
            else if (option[value]?.toLowerCase().includes(query.toLowerCase().trim()))
                return option;
        })
    }


    useEffect(() => {
        if (!openData) setQuery('')
    }, [openData])

    useEffect(() => {
        setInputValue(editValue);
    }, [editValue, name, setInputValue]);

    const dropdownHandler = (event, option) => {
        setInputValue(option[value]);
        dispatch(saveUserStoredStatus(option[value]))
        setOpenData(!openData);
        props.addValueToQueue(name, option[value], option[id]);
    }
    const dropDownref = useRef();
    const inputValueRef = useRef();
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownref.current && !dropDownref.current.contains(event.target)) {
                if (!openData)
                    setOpenData(openData);
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [dropDownref]);

    return (
        <React.Fragment>
            <section className={className}>
                <div className=' wrapper'>
                    <input className='input-field' type='text' name={name} style={{ marginTop: props.marginTop }}
                        value={Inputvalue || query}
                        autoComplete="off"
                        onChange={e => {
                            setQuery(e.target.value);
                            setOpenData(true);
                            setInputValue(null)
                        }}
                        onClick={() => {
                            setOpenData(!openData);
                        }}
                        placeholder='Select'
                    />
                    <div className='button-arrow arrow-btn'
                        onClick={() => {
                            setOpenData(!openData);
                        }}
                        ref={dropDownref}
                    >
                        {!openData ? <img src={downward_icon} alt="arrow" /> :
                            <img src={upward_icon} alt="arrow" />}
                    </div>
                </div>
                <div>
                    {openData && <ul className={`ul-wrapper`}>
                        {filterQuery(data)?.map((option,i) =>
                        (<li value={option?.[value]} key={option?.[id]} className='list-wrapper' ref={inputValueRef}
                            onClick={(event) =>{
                             dropdownHandler(event, option)} 
                             }>
                            <label className='label-region' htmlFor={option?.[id]}>  {option?.[value]} </label>
                            {option?.Contractual === 'Yes' && <span style={{ marginTop: 10, marginRight: 10 }}><img src={contractIcon} alt='' /></span>}
                        </li>)
                        )}
                    </ul>}
                </div>
            </section>
        </React.Fragment>
    )
}
