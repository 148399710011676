import React from "react";
import { Breadcrumb } from "react-bootstrap";
import "./bredcrumb.css";
import { useHistory } from "react-router-dom";
export const BredCrumb = (props) => {
  const history = useHistory();

  const navigateToRegionalSite = (reDirectTo) => {
    history.push(reDirectTo);
  };
 
  return (
    <div className="bredcrumb-container">
      <Breadcrumb>
        {/* <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item href="#" onClick={navigateToRegionalSite}>
          Reports
        </Breadcrumb.Item> */}
        {props?.data?.map((itm, i)=>{
          return <Breadcrumb.Item active={i===(props?.data?.length-1)} onClick={()=>navigateToRegionalSite(itm.reDirect)} key={i}>{itm.name}</Breadcrumb.Item>
        })}
      </Breadcrumb>
    </div>
  );
};

export default BredCrumb;
