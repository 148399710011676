import React from 'react';
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import { Footer } from '../HeaderFooter';
import './Developer.css';
import Developer_HeroImage from './../../../assets/github-images/Developer_HeroImage.png';
import RightArrow_Hover from './../../../assets/github-images/RightArrow_Hover.png';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setDeveloperCard } from '../../../actions/github.action';


const Developer_Cards = [
  { id: 1, title: 'Infrastructure', text: 'infra' },
  {
    id: 2,
    title: 'Developer Access',
    text: 'access'
  },
  {
    id: 3,
    title: 'Developer Guidelines',
    text: 'guidelines'
  },
  {
    id: 4,
    title: 'Development Environment',
    text: 'environment'
  },
  {
    id: 5,
    title: 'Documentation Standards',
    text: 'standard'
  },
  {
    id: 6,
    title: 'Release and Build Guides',
    text: 'release'
  },
  {
    id: 7,
    title: 'Product Maintenance',
    text: 'product'
  },
  {
    id: 8,
    title: 'Developer Support',
    text: 'support'
  },
  {
    id: 9,
    title: 'Design Principles',
    text: 'principle'
  }
]

const DeveloperLanding = () => {
  const dispatch = useDispatch();
  const history= useHistory();
  const onClickDeveloperCards=(card)=>{
    dispatch(setDeveloperCard(card));
    history.push('/developer/card')
  }
  return (
    <>
      <section style={{ marginLeft: -100 }}>
        <main className='breadcrumb_solution'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Developer' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>Developer</div>This repository contains information, guidance and processes for developers, including coding, design, application management, maintenance and technical support of the GSD Insight application.</div>
          <div style={{paddingBottom:20,paddingLeft:80}}><img src={Developer_HeroImage} alt=''/></div></div>
        </main>
        <main className='conceptual_arch_devp'>
        <div style={{width:1200,margin:'auto'}}>
          <div className='grid_dev'>
            {Developer_Cards.map((link, index) => (<div class="grid-item-dev" onClick={()=>onClickDeveloperCards(link)}><div>{link.title}</div><div><img src={RightArrow_Hover} alt='' /></div></div>))}
          </div></div>
        </main>
        <Footer />
      </section>
    </>
  )
}

export default DeveloperLanding