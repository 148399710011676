import API from "../api";

let token = localStorage.getItem("token");
export const getUserDataService = async (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getUserTokenService = (requestUrl) => {
  return API.get(requestUrl)
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getRegionalDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }})
    .then((resp) => resp)
    .catch((error) => error.response);
};

