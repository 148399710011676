import API from "../api";
let token = localStorage.getItem("token");
export const getAccountSiteService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};



export const getGeneralInfoSiteService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};



export const getRolesTabRolesService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getRolesTabUsersService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};



export const updateRolesTabDataService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getQueueTabLanguagesService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getOriginatingCountryService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateQueueTableService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getQueueTableDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getSLATemplateDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const updateSLATableService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateNewTemplateService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateSupportHoursService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getSLAKPIDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getRoleTabDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getSupportHoursVoiceService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getNonVoiceSLADataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};
export const getNonVoiceSupportHoursService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getGeneralNonVoiceService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const updateGeneralNonVoiceService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getPreviewOnboardService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getOnboardAccountsService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const updateOnboardToggleService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const updateOnboardSubmitService = (requestUrl, id) => {

  return API.post(requestUrl,id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getOnboardValidUserService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateReOpenRequestService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateDeleteAccountService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};