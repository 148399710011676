import React from 'react';
import './OverView.css';
import { Accordion } from 'react-bootstrap';
import VerticalLine from './../../../assets/images/VerticalLine_Headings.png';
import Overview_Product_Selection_Image from './../../../assets/github-images/Overview_Product_Selection_Image.png';
import { Footer } from '../HeaderFooter';

const  BUSINESS_REQUIREMENTS= [
    {
        id: 1,
        title:'Comprehensive Reporting Platform',
        text:'Integrated data, reporting and analytics for GSD capability.'
    },
    {
        id: 2,
        title:'SLA/KPI Reporting and Dashboarding',
        text:'Applied standard definitions and metric calculations for Global Service Desk Service Level Agreements & Key Performance Indicators. Detailed reporting views developed with download option.'
    },
    {
        id: 3,
        title:'Multiple Data Sources',
        text:'Handle multiple data sources and integrate into a Central Reporting & Analytics system.​'
    },
    {
      id: 4,
      title:'Data Ingestion and Processing',
      text:'Data ingestion standardization and normalization should happen automatically (non-human). Ability to develop seamless data ingestion mechanism.'
  },
  {
      id: 5,
      title:'RBAC Access Model',
      text:'Role based access to data and reports.'
  }]

  const  DATA_SOURCE= [
    {
        id: 1,
        title:'Ticketing System',
        text:'Tools for managing customer incident, requests related tickets.'
    },
    {
        id: 2,
        title:'Customer Satisfaction Survey Tool',
        text:'Tools of Surveying customer’s End User’s satisfaction related to the service and handled incident, request ticket.'
    },
    {
        id: 3,
        title:'Chat Tool',
        text:'Tools for managing chat based inbound channel for End Users.​'
    },
    {
      id: 4,
      title:'Transaction Monitoring Tool',
      text:'Tools for managing Transaction (voice, ticket) quality evaluations.'
  },
  {
      id: 5,
      title:'Web Portal Tool',
      text:'Tools for managing Portal based inbound channel for End Users.'
  },
  {
      id: 6,
      title:'E-mail Tool',
      text:'Tools for managing E-mail based inbound channel for End Users as well as managing outbound e-mail interactions.'
  },
  {
      id: 7,
      title:'Workforce Management Tool (Scheduling)',
      text:'Tools for managing Work Force scheduling (time, attendance).'
  },
  {
      id: 8,
      title:'Financial Tools (P/L mapping, Cost Center)',
      text:'Tools for managing and allocating Work Force to financial regions, Cost Centers, Labor rates.'
  },
  {
      id: 9,
      title:'Time tracking tool (DXC Time)',
      text:'Tool for managing Work Force time tracking.'
  },
  {
      id: 10,
      title:'Work Force / HR tool (RMT)',
      text:'Tool for managing Work Force related data (FTE, Job level, Job code, Organization.'
  }
]

  const AWS_Instance = [
    { id: '1', caption: "" },
    { id: '2', caption: "GSD Insight" },
    { id: '3', caption: "Aceyus" }
]
const AWS_Instance_DATA = [
    { id: '1',data:'Back-end data modelling',GSDInsight:'Same effort in both options',Aceyus:'Same effort in both options'},
    { id: '2',data:'Infrastructure',GSDInsight:'Same effort in both options',Aceyus:'Same effort in both options'},
    { id: '3',data:'Integration with data sources not in AWS Connect',GSDInsight:'Effort needs to be done for this, but probability of usage is low',Aceyus:'Some ready-made plug-ins for SFDC/ Qfiniti / Verint available'},
    { id: '4',data:'Data Visualization',GSDInsight:'Limited to our imagination and usage of Power BI. Enhance and invest in WEB based UI / UX solution.',Aceyus:'Many ready-made options available. Far superior. Easy to customize and configure.'},
    { id: '5',data:'Long-Term Support',GSDInsight:'Needs planning to build solid documentation, put it up in GitHub and provide for support teams.',Aceyus:'Dependence on Aceyus and with additional cost'},
    { id: '5',data:'Usability',GSDInsight:'Needs push from Site Leadership to drive common standards and not resort to many local variations.',Aceyus:'Attractiveness of UI to pull more users of the platform'},
    { id: '5',data:'Cost',GSDInsight:'US$ ~240K for 3 years, with slightly higher investment/implementation costs in the first 1.5 years. Need to ring-fence a support/ enhancement team to maintain and enhance',Aceyus:'US$ 330K in FY22 and US$ 300K for 2 more years (US$1M over 3 years)'},
]
const OverView = () => {
  return (
    <>
     <section style={{ marginTop: 0, marginLeft: -100 }}>
    <main className='accordion_handle' >
    <div style={{width:1200,margin:'auto'}}>
        <div className='Infrastructure'>Overview</div>
        <div style={{ width: '98%' }}>
            <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                    <Accordion.Header >
                        <span className='accordion-header'>Product Selection</span></Accordion.Header>
                    <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                        <>
                        <main className='security_model'>
            <div id='text_security'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GSD Insight Security Model</div>
            <div className='grid_security'>
              {BUSINESS_REQUIREMENTS.map((link, index) => (<main class="grid_item_security" ><div id='grid_title'>{link.title}</div><div id='grid_text'>{link.text}</div></main>))}
              </div>
         </main>   
         <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Decision Driving Factors</main>
                 <div className='list_overview'>
                    <ul>
                        <li className='list_overview_ul'>Value for money / Cost (consumption approach)​</li>
                        <li className='list_overview_ul'>Meet business needs and DXC long term strategy​</li>
                        <li className='list_overview_ul'>Conformity of Functional / Non-functional requirements​</li>
                    </ul>
                 </div>
                 <img src={Overview_Product_Selection_Image} alt='' style={{marginTop:20,marginBottom:20}}/>
                 <main className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Build Vs Buy Comparison</main>
                 <main>  <table className='infra_table' style={{width:'100%'}}>
                                            <thead><tr>
                                                {AWS_Instance.map(header => (<th style={{ border: 'none' }} key={header.id}><span className='infra_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {AWS_Instance_DATA.map((data, index) => (<tr key={data.id}>
                                                    <td className='infra_td'>{data?.data}</td>
                                                    <td className='infra_td'>{data?.GSDInsight}</td>
                                                    <td className='infra_td'>{data?.Aceyus}</td>
                                                </tr>))} </tbody>
                                        </table></main> 

                        </>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                    <Accordion.Header style={{
                        width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                        boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                    }}>
                        <span className='accordion-header'>Data Source</span></Accordion.Header>
                    <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                        <>
                          <div className='data_source'>Below is the list of data source used in GSD Insight for getting the data and using the data out of these data source as input to report creation and display.</div>  
                          <main className='security_model'>
            <div id='text_security'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GSD Insight Security Model</div>
            <div className='grid_security'>
              {DATA_SOURCE.map((link, index) => (<main class="grid_item_security" ><div id='grid_title'>{link.title}</div><div id='grid_text'>{link.text}</div></main>))}
              </div>
         </main>   
                        </>
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
        </div></div>
    </main>
    <Footer/>
    </section>
</>
  )
}

export default OverView