import React, { useEffect, useState } from 'react';
import SLADataExclusion_Icon from '../../../../assets/admin-images/SLADataExclusion_Icon.png';
import Expand_DownArrow from '../../../../assets/admin-images/Expand_DownArrow.png';
import Collapse_UpArrow from '../../../../assets/admin-images/Collapse_UpArrow.png';
import AboveMinimalTargetIcon from "../../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../../assets/images/BelowMinimalTarget_Status.png";
import Edit_Icon from "../../../../assets/images/Edit_Icon.png";
import DATA from './../DATA.json';
import { GenericModal } from '../../General/SLA-modal';
import {  RequestModalHandler } from '../ModalHandler';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../actions/user.action';
import { ActivateModelPopup } from '../../Administration_firstpage.js/ActivationModel';
import TooltipHandler from '../../../Onboard/OnboardComponents/TooltipHandler';
import { ExclusionModalHandler } from './ExclusionModalHandler';
import { getExclusionDataStart, updateExclusionCommentStart } from './../../../../actions/Administration/SLAFailure.action';


export default function TableBodyHandler({ info,selectedDate }) {
    const dispatch = useDispatch();
    let userInfo = useSelector((state) => state.userReducer.userInfo);
    let AccountDetails = useSelector((state) => state.adminAccountReducer.AccountDetails);
    useEffect(() => {
        dispatch(getUserData());
    }, [dispatch]);

    const [showAccordion, setShowAccordion] = useState(false);
    const [showExclusionModal, setShowExclusionModal] = useState(false);
    const [addExclusionID, setAddExclusionID] = useState(null);
    const [ExclusionComments, setExclusionComments] = useState([]);
    const [showRequestModal, setShowRequestModal] = useState(false);
    const [exclusionModalInfo, setExclusionModalInfo] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [validation, setValidation] = useState('')
    const [emptyRowData,setEmptyRowData] = useState(false)
   console.log(info)
    const handleExclusionFormSubmit = data => {
            console.log(data);
            let temp_arr = [...data.data];
            console.log(temp_arr)
            let tempArray2 = temp_arr.map(role => {
                return {
                    Date: role.Date ,
                    EndTime: role.EndTime ,
                    StartTime: role.StartTime,
                   
                }   
            });
            const newExclusion = {
                info:info.ID,
                Comments :data.comments.comments,
                Data : tempArray2
            }
            console.log(newExclusion);
            let isEmptyInput = false;
            const filterd = tempArray2
            .forEach(row => {
                const values = Object.values(row);
                console.log("values::: 57 :::::::::", values);
                if(isEmptyInput) {
                    return
                } 
                if(values.includes(undefined) || values.includes('')) {
                    isEmptyInput = true;
                    return;
                }
               // return  !values.includes(undefined) // values.every((v) =>  v !== undefined || v !== null)
            });
            console.log("values::: 60 :::::::", filterd)
            // const isEmptyInput = tempArray2
            // // .filter(row => {
            // //     return  Object.values(row).every(x => (x || x !== null || x !== '' || x !== undefined))
            // // })
            // .every(row => {
                
            //     return Object.values(row).length >= 3 ;
            // });
            console.log("row values ::::::::::",isEmptyInput );
            // const tempArr = tempArray2.every(row => {
            //     const yes = Object.values(row).some(x => (x === null || x === '' || x === undefined))
            //     console.log("row values ::::::::::",Object.values(row), Object.values(row).length, yes );
            //     setEmptyRowData(yes)
            //     return Object.values(row);
            // });
        //     const tempArr = tempArray2.every(row => Object.values(row).some(x => (x === undefined)))
        //    console.log(tempArr)

          if(isEmptyInput){
            setShowExclusionModal(true)
            setValidation('Please Ensure you filled all the inputs with  " * " mark')
          }
        //   else if(!tempArr){
        //     setShowExclusionModal(true)
        //     setValidation('Please Ensure you filled all the inputs with  " * " mark')
        //   }
        // else if(tempArray2.length === 0){
        //     setShowExclusionModal(true)
        //     setValidation('Please Ensure ') 
        // }
          else{
             if(ExclusionComments !== null)  {
                const newRoles = [newExclusion,...ExclusionComments];
                setExclusionComments(newRoles)
            }
            else{
                const newRoles = [newExclusion];
                setExclusionComments(newRoles)
            }
            setShowExclusionModal(false);
            setValidation('')
          }

            let dataArr = temp_arr.map(role => {
             return   {
                            "Date": role.Date,
                            "StartTime": role.StartTime,
                            "EndTime": role.EndTime
                }
            });
            const dispObj ={
                "Comment": data.comments.comments,
                "Data": dataArr
            }
           console.log(newExclusion.info) //props.selectedDate
            dispatch(updateExclusionCommentStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID, selectedDate,newExclusion.info, dispObj))
            dispatch(getExclusionDataStart(AccountDetails.CustomerID, AccountDetails.ServiceDeskID, selectedDate))

                console.log('dispatchdata::::::::::',AccountDetails.CustomerID, AccountDetails.ServiceDeskID, selectedDate,newExclusion.info)


    //         dispatch(updateExclusionCommentStart('20', '15', '2022-07-01',newExclusion.info, dispObj))
    //         dispatch(getExclusionDataStart('20', '15', '2022-07-01'))
      }
   
    useEffect(() => {
        let temp_arr=[...DATA.ExclusionData[0].comments];
        let tempArr = info.Comments
      tempArr &&  tempArr.reverse()
        console.log(tempArr, temp_arr)
        setExclusionComments(tempArr)
    }, [])

    // useEffect(() => {
    //     console.log(info)
    //     if (info?.Comments?.length > 0) {
    //         let arr1 = [...info?.Comments];
    //         console.log(arr1)
    //         const arr3 = [...info?.Comments[0].Data]
    //         const tempArray2 = arr3.map((date)=>{
    //             return{
    //                 Date: date.Date,
    //                 EndTime: date.EndTime,
    //                 StartTime: date.StartTime
    //             }
    //         })
    //         let arr2 = arr1.map((a, i, arr) => {
    //                 return {
    //                     Data : tempArray2,
    //                     Comments :a?.Comments
    //                 }
    //         })
    //         setExclusionComments(arr2)
    //     }
    //     else setExclusionComments([]);
    // }, [])
    const formatDatefromString = (seldate) =>{
        var date = new Date(seldate); // M-D-YYYY
		var d = date.getDate();
		var m = date.getMonth() + 1;
		var y = date.getFullYear();

		return (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
    }
    console.log('..............',info.SLA)
    return (<>
        <tr key={info.id}>
            <td id='table_tr' style={{ border: 'none' }}>{info.Month} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info.SLA} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info.Type} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.['Minimum Target']} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.['Expected Target']} </td>
            <td id='table_tr' style={{ border: 'none' }}><span>{info.Performance.Value}
                <img src={info.Performance.Color === 'green' ? AboveMinimalTargetIcon : BelowMinimalTargetIcon} alt='' /></span></td>
            <td id='table_tr' style={{ border: 'none' }}>  <span>{info.WDE.Value}
                <img src={info.WDE.Color === 'green' ? AboveMinimalTargetIcon : BelowMinimalTargetIcon} alt='' /></span> </td>
            <td id='table_tr' style={{ border: 'none' }}>{info.Performance.Met} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info.WDE.Met} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info.Performance.All} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info.WDE.All} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info.Status} </td>
            <td id='table_tr' style={{ border: 'none' }}><span
             onClick={() => {
                setAddExclusionID(info.id)
                ExclusionComments !== null ? setShowAccordion(!showAccordion) : setShowExclusionModal(true)
                setEditMode(false)
                setExclusionModalInfo('')
            }}>
                {ExclusionComments !== null ? (showAccordion ? <img src={Collapse_UpArrow} alt='' style={{ cursor: 'pointer' }} /> :
                    <img src={Expand_DownArrow} alt='' style={{ cursor: 'pointer' }} />) :
                    <span > <TooltipHandler src={SLADataExclusion_Icon} position='bottom right'
                        arrowAlign='end' textBoxWidth='330px' text='Add Request for SLA Data Exclusion' /></span>}
            </span>
            </td>
        </tr>

        {(showAccordion) &&
            <tr > <th colSpan='15'>
                <section className='table_insider' >
                    <div style={{ display: 'flex' }}>
                        {DATA.header2WDE.map(data => (<span style={{ width: data.id === 3 ? '40%' : (data.id === 4 || data.id === 5) ? '20%' : '32%' }} className='table_insider_box'
                            key={data.id}>{data.label}</span>))}</div>
                    <div style={{padding:10,paddingRight:20}}>
                        {ExclusionComments?.map(comment => (
                            <section style={{display:'flex',borderBottom:'1px solid #63666A4D'}}>
                            <main style={{width:'65%' }}>
                                {comment?.Data?.map((value, i) => (
                                   <div>
                                    <span className='table_insider_body_exclusion' key={i}>{formatDatefromString(value.Date)}</span>
                                    <span className='table_insider_body_exclusion' key={i}>{value.StartTime}</span>
                                    <span className='table_insider_body_exclusion' key={i}>{value.EndTime}</span><br/>
                                </div>))}</main>
                                  <main className='table_insider_body_exclusion'>{comment.Comments}</main>                                
                               <main> {(ExclusionComments.indexOf(comment) === 0 && info.status !== 'Completed' && ExclusionComments !== null) ? 
                                <span style={{ width: '12%', marginTop: '10px' }}><span onClick={() => {
                                    const element = ExclusionComments.find(details => details === info.Comments)
                                    const index = ExclusionComments.indexOf(element) + 1;
                                    setAddExclusionID(index);
                                    setEditMode(true)
                                    setShowExclusionModal(true);
                                    setExclusionModalInfo(ExclusionComments?.[0])
                                }}>
                                    <TooltipHandler src={Edit_Icon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                                </span></span> : <span style={{ width: '12%' }}><img src='' alt='' style={{ paddingLeft: '40px' }} /></span>}</main>
                                </section>
                              
                        )
                        )}
                    </div>
                </section>
            </th></tr>}
        {showExclusionModal && <GenericModal isOpen={showExclusionModal} setShowModal={setShowExclusionModal} height='450px' width='600px'>
            <ExclusionModalHandler editMode={editMode}
                handleAddRoleCancel={() => setShowExclusionModal(false)} validation={validation}
                handleExclusionFormSubmit={handleExclusionFormSubmit} ExclusionData={exclusionModalInfo}
                ExclusionName={info.SLA}
            />
        </GenericModal>
        }
        {(!showExclusionModal && showRequestModal) && <ActivateModelPopup isOpen={showRequestModal} setShowModal={setShowRequestModal} >
            <RequestModalHandler RequestClosehandler={() => setShowRequestModal(false)}
                userEmail={userInfo?.email}
              //  ExclusionName={ExclusionComments[ExclusionComments.findIndex((data,i) => i === addExclusionID)].SLA}
            />
        </ActivateModelPopup>
        }
    </>)

}