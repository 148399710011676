import React, { useEffect, useState } from 'react';
import AdminBreadcrumb from '../../General/Admin-breadcrumb';
import { Link } from 'react-router-dom';
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessGroupAddData, getAccessGroupData } from '../../../../actions/Administration/adminView.action';
import AccessGroupTableView from './AccessGroupTableView';
import { useHistory } from 'react-router-dom';

const AccessGroupMain = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let accessGroupMainData = useSelector((state) => state.adminViewReducer.accessGroupMainData);
  let accessGroupAddData = useSelector((state) => state.adminViewReducer.accessGroupAddData);
console.log('accessGroupMainData',accessGroupMainData)

useEffect(() => {
  dispatch(getAccessGroupData());
  dispatch(getAccessGroupAddData());
}, [dispatch]);

    const [filter, setFilter] = useState('');
    const [searchColumns, setSearchColumns] = useState(['Access Group']);
    function searchRows(rows) {
        return rows.filter((row) =>
          searchColumns.some(
            (column) =>
              row[column]
                .toString()
                .toLowerCase()
                .indexOf(filter.toLowerCase()) > -1,
          ),
        );
      }
      const mode = {
        Mode : 'Add',
        addData : accessGroupAddData?.[0]?.OperationList
        };

      
  return (
    <div style={{paddingBottom:'200px',marginRight:20}}>
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Administration' value2={()=>history.push('/adminviewlanding')}
        isActive3={false} label3='Access Groups'
      />
    <header style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='administration_text'>Access Groups: {accessGroupMainData.length} </div>
        <div style={{ display: 'flex', gap: '20px' }}>
       <div style={{marginTop:20}}> <Link to={{ pathname: '/admin/accessgroup/addaccessgroup', state: mode}}
  ><span className='btn_addAdminFlow'>ADD ACCESS GROUP</span></Link></div>
          <span className='search_bar_AdminFlow'>
            <input className='search_input_AdminFlow' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search Access Group' />
            <button className='search_icon_AdminFlow'><img src={searchIcon} alt='' /> </button>
          </span>
        </div>
      </header>
      <section className='adminView_table' style={{ width: '100%'}}>
      {searchRows(accessGroupMainData).map((info, i) => <AccessGroupTableView info={info} key={i}/>)}
      </section>
    </div>
  )
}

export default AccessGroupMain