import React, { useEffect, useState } from 'react'
import Expand_DownArrow from './../../../../assets/onboard-images/Expand_DownArrow.png';
import Collapse_UpArrow from './../../../../assets/onboard-images/Collapse_UpArrow.png';
import Edit_Icon from './../../../../assets/onboard-images/Edit_Icon.png';
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png'
import { DeleteModalPopup } from '../../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { AdminViewDeleteModel } from '../AdminViewModels';
import { getAccessGroupData, getAccessGroupEditData, updateAccessGroupDeleteStart } from '../../../../actions/Administration/adminView.action';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AccessGroupTableView = ({info,key}) => {
  const dispatch = useDispatch();
  
    
  const [showAccordion, setShowAccordion] = useState(false);
  const [showDeleteAccessGroupModal, setShowDeleteAccessGroupModal] = useState(false);

  const handleDeleteAccessGroup = () =>{
    let deleteObj = [{"AccessGroupID": info.AccessGroupID}]
    dispatch(updateAccessGroupDeleteStart(deleteObj))
    dispatch(getAccessGroupData());
    setShowDeleteAccessGroupModal(false)
    dispatch(getAccessGroupData());
  }

  const editData = {
    Mode: 'Edit',
    editInfo: info
    };
  return (
    <>
    <main className='Table_border_AdminFlow' key={key}>
              <span style={{ border: 'none'}}>{info?.['Access Group']}</span>
              <span className='Icon_holder_AdminFlow'>
              <div>
              <Link to={{ pathname: '/admin/accessgroup/addaccessgroup', state: editData}} >
   <img src={Edit_Icon} alt='' style={{ cursor: 'pointer' }} />
   </Link>
   </div>
                <div > <img src={deleteIcon} alt='' style={{ cursor: 'pointer' }} onClick={()=>setShowDeleteAccessGroupModal(true)}/></div>
                <div> <img src={showAccordion ? Collapse_UpArrow : Expand_DownArrow} alt='' style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setShowAccordion(!showAccordion)
                  }} /></div>
              </span>
          </main>
      
            {(showAccordion) &&
       <th colSpan='15' style={{backgroundColor: '#F6F8FA',width:'10%'}}>
      <div className='AdminFlow_Description'>
      Access Group Descriptions: {info.Description}
        </div>
        <section className='table_insider_AdminFlow' >
          <main style={{ display: 'flex',backgroundColor:'#F6F8FA' }}>
            <span className='table_insider_box' style={{ width: '100%' }}> Operations</span>
          </main>
          
            <main style ={{backgroundColor:'white'}}>
          <div style={{ display: 'flex',borderTop:'1px solid #63666A4D',padding:'20px' }}>
            <span className='table_insider_body' style={{ width: '100%' }} >
            {(info?.OperationList)?.map((data,i,row)=>(<span>{data.Operation} {i+1 === row.length ? '' : '|' } &nbsp;</span>))}
            </span>
          </div>
      </main>
        </section>
      </th>}
     
     
      {showDeleteAccessGroupModal && <DeleteModalPopup isOpen={showDeleteAccessGroupModal} setShowModal={setShowDeleteAccessGroupModal}>
            <AdminViewDeleteModel modalName='Delete Access Group and Operations' buttonOne='YES' buttonTwo='NO'
            InformationText = {info?.['Access Group']} 
            AfterInfoText='access group?'
            handleDeleteYes={handleDeleteAccessGroup}
            handleDeleteNo={()=>setShowDeleteAccessGroupModal(false)}
            />
        </DeleteModalPopup>}
    </>
  )
}

export default AccessGroupTableView