import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from 'react-power-tooltip'
import "./global-accounts.css";
import viewIcon from "../../../assets/images/ViewDetails_Icon.png";
import AboveRequiredTargetIcon from "../../../assets/images/AboveRequiredTarget_Status.png";
import AboveMinimalTargetIcon from "../../../assets/images/AboveMinimalTarget_Status.png";
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";
import { updateRegionalSiteCustomerName, updateRegionalSiteCustomerID, getRegionalSiteCustIDData } from "../../../actions/regionalSite.action";
import ReactTooltip from 'react-tooltip';
const GlobalAccounts = (props) => {
  let sites=[];
  const history = useHistory();
  const dispatch = useDispatch();
  const getArrowIcon = (color) => {
    let colr;
    switch(color){
        case 'yellow' : { colr = AboveMinimalTargetIcon; break;}
        case 'green' : { colr = AboveRequiredTargetIcon; break;}
        case 'red' : { colr = BelowMinimalTargetIcon; break;}
        default : {}
    }
    return colr;
  };

  

  const navigateToAccountGlobal = (c_name, c_id) => {
    dispatch(updateRegionalSiteCustomerName(c_name));
    dispatch(updateRegionalSiteCustomerID(c_id));
    dispatch(getRegionalSiteCustIDData(c_id, new Date().toISOString().split('T')[0]));
    history.push('/regional_global_accounts');
  }

  return (
    <div>
      <br />      
      <div className="global-acc-cntr">
        <div className="global-acc-label">Global Accounts</div>
        <div className="row table-header-row">
          <div className="col-md-1 redu-width"> Account Logo</div>
          <div className="col-md-1 redu-width">Account Name</div>
          <div className="col-md-1 redu-width">Regions</div>
          <div className="col-md-1 redu-width">Sites</div>
          <div className="col-md-1 redu-width">SLA's Met</div>
          <div className="col-md-1 redu-width">SLA over Delivery </div>
          <div className="col-md-1 redu-width"></div>
        </div>
        <div style={{height: '450px', overflowY : 'auto', overflowX: 'hidden'}}>
        { props.data.map((itm,idx)=>{
          sites = [];
          itm?.servicedesks?.map((itm)=>{
            return sites.push(itm.servicedesk);                       
           });
           return(
             
           <div className="row table-row" key={idx}>
            <div className="col-md-1 redu-width" style={{height: '65px'}}>
              <img className="card-image" src={`${process.env.REACT_APP_BASE_URL}/api/org/customer/${itm?.customerid}/photo`} alt="Account Logo" />              
            </div>
            <div style={{height: '65px'}} className="col-md-1 redu-width">{itm?.customer}</div>
            <div style={{height: '65px'}} className="col-md-1 redu-width mrgn-5" >
              <span data-tip={itm?.count_regions} data-for={('regions'+idx.toString())}>{itm?.count_regions}</span>
              <ReactTooltip className='customeTooltip' aria-haspopup='true' id={('regions'+idx.toString())} place="bottom" effect="solid" arrowColor="#00A3E1">
              <div className="hdr-tlptip">{itm?.customer} Regions</div>        
              <div className="body-tlptip">
                  <ul>
                     {  itm?.regions?.map((itm, j)=>{
                            return <li key={j}>{itm?.region}</li>;                       
                     })  }                
                  </ul>
              </div>     
             
              </ReactTooltip>
            </div>
            <div style={{height: '65px'}} className="col-md-1 redu-width">
            <span data-tip={itm?.count_servicedesks} data-for={('sites'+idx.toString())}>{itm?.count_servicedesks}</span> 
            <ReactTooltip className='customeTooltip' aria-haspopup='true' id={('sites'+idx.toString())} place="bottom" effect="solid" arrowColor="#00A3E1">
              <div className="hdr-tlptip">{itm?.customer} Sites</div>        
              <div className="body-tlptip">                  
                     {  sites.join(' | ')  } 
              </div>                 
              </ReactTooltip>             
            </div>
            <div style={{height: '65px'}} className="col-md-1 redu-width mrgn-5">
             {itm?.items?.slamet?.value}
              <span>
                <img
                  src={getArrowIcon(itm?.items?.slamet?.color)}
                  className="legend-space"
                  alt=""
                />
              </span>
            </div>
            <div style={{height: '65px'}} className="col-md-1 redu-width">
            {itm?.items?.slaovd?.value}
              <span>
                <img
                  className="legend-space"
                  src={getArrowIcon(itm?.items?.slaovd?.color)}
                  alt=""
                />
              </span>
            </div>
            <div style={{height: '65px'}} className="col-md-1 redu-width">
              <img src={viewIcon} onClick={()=>navigateToAccountGlobal(itm?.customer, itm?.customerid)} className="view-img" alt="viewImg" />
            </div>
            </div>
            );
        }) }
        </div>
      </div>
    </div>
  );
};

export default GlobalAccounts;
