import React, { useEffect, useState } from 'react';
import voiceImage from './../../../assets/onboard-images/Voice_Normal_Icon.png'
import voiceHoverImage from './../../../assets/onboard-images/Voice_Hover_and_Selected_Icon.png'
import nonVoiceImage from './../../../assets/onboard-images/NonVoice_Normal_Icon.png'
import nonVoiceHoverImage from './../../../assets/onboard-images/NonVoice_Hover_and_Selected_Icon.png'
import previewImage from './../../../assets/onboard-images/Preview_Normal_Icon.png'
import previewHoverImage from './../../../assets/onboard-images/Preview_Hover_and_Selected_Icon.png'
import UploadAdministration_Icon from './../../../assets/images/UploadAdministration_Icon.png'


import UploadPreview from './PreviewUpload';
import VoiceTabDetails from './VoiceUpload';
import NonVoicetabDetails from './NonVoiceUpload';
import { useHistory} from 'react-router-dom';
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedVoiceNonVoiceItem, setSelectedVoiceNonVoiceTab } from './../../../actions/uploads.action';




function Image({ onClick, src, children, onMouseEnter, onMouseLeave }) {
    return <>
        <button className={'nav-btn'} onClick={onClick} onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} >
            <img className='nav-image' src={src} alt='' />
            {children}
        </button>
    </>
}

function UploadNavigation() {
    const history = useHistory();
    const dispatch = useDispatch();
    const SelectedVolumeFields = useSelector((state) => state.uploadReducer.SelectedVolumeFields);
    const SelectedSLAKPIFields = useSelector((state) => state.uploadReducer.SelectedSLAKPIFields);
    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);

    const [generalTab, setGeneralTab] = useState('Voice');
    const [voiceImgSrc, setVoiceImgSrc] = useState();
    const [nonVoiceImgSrc, setNonvoiceImgSrc] = useState();
    const [previewImgSrc, setPreviewImgSrc] = useState();

    useEffect(() => {
        switch (generalTab) {
            case 'Voice': {
                setPreviewImgSrc(previewImage);
                setNonvoiceImgSrc(nonVoiceImage);
                setVoiceImgSrc(voiceHoverImage);
                break;
            }
            case 'Non-Voice': {
                setPreviewImgSrc(previewImage);
                setNonvoiceImgSrc(nonVoiceHoverImage);
                setVoiceImgSrc(voiceImage);
                break;
            }
            case 'Preview': {
                setPreviewImgSrc(previewHoverImage);
                setNonvoiceImgSrc(nonVoiceImage);
                setVoiceImgSrc(voiceImage);
                break;
            }
            default: { }
        }
    }, [generalTab]);

    const onClickViewDetails = title => {
        switch (title) {
          case 'Voice': setGeneralTab('Voice'); break;
          default: setGeneralTab('Non-Voice');
        }
      }

    const uploadTabHandler = () => {
        switch (generalTab) {
            case 'Voice':
                return <VoiceTabDetails SelectedVolumeFields={SelectedVolumeFields} SelectedSLAKPIFields={SelectedSLAKPIFields} />
            case 'Non-Voice':
                return <NonVoicetabDetails SelectedVolumeFields={SelectedVolumeFields} SelectedSLAKPIFields={SelectedSLAKPIFields} />
            case 'Preview':
                return <UploadPreview onClickViewDetails={onClickViewDetails}/>
            default: { }
        }
    }

    useEffect(()=>{
        dispatch(setSelectedVoiceNonVoiceTab(generalTab))
        if(generalTab === 'Voice'){
            dispatch(setSelectedVoiceNonVoiceItem('AWS (ASD) Telephony'))
        }
    },[dispatch,generalTab])

    return (
        <React.Fragment>
            <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Upload' value2={()=>history.push('/upload')}
                isActive3={false} label3={SelectedAccountDetails?.['Account Name'] + '-' + SelectedAccountDetails?.['Site Name']}  />
            <main style={{ display: 'flex', justifyContent: 'space-between', width: '98%' }}>
                <div className='onboard_account' ><div><img
                    style={{maxHeight:'40px',maxWidth:'60px'}}
                    // height='20px'
                    // width='60px'
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/org/customer/${SelectedAccountDetails?.CustomerID}/photo`}
                  alt="Client Logo"
                /></div> {SelectedAccountDetails?.['Account Name']} - {SelectedAccountDetails?.['Site Name']} </div>
                <div style={{ marginTop: '30px' }}>
                    <button className='cancel-button extra_Cancel'
                        onClick={() => { history.push('/upload/dataAdmin') }}
                        style={{ width: '290px'}}
                    >
                        <img src={UploadAdministration_Icon} alt='' style={{ marginLeft: '10px' }} />
                        <span className='cancel-span' style={{ marginTop: '2px' }}>Upload Data Administration </span>
                    </button>
                </div>
            </main>
            <section className='main-container'>
                <section className='navbar'>
                    <div className='nav-container'>
                        <ul className='nav-menu'>
                            <Image src={voiceImgSrc} onClick={() => {setGeneralTab('Voice')}}
                                onMouseEnter={() => generalTab !== 'Voice' && setVoiceImgSrc(voiceHoverImage)} onMouseLeave={() => generalTab !== 'Voice' && setVoiceImgSrc(voiceImage)}>
                                <div className={`nav-span`}>
                                    <span className='nav-text' style={{ color: generalTab === 'Voice' && 'black' }}>Voice</span></div>{generalTab === 'Voice' && <div className='nav_btn_active'></div>} </Image>
                            <Image src={nonVoiceImgSrc} onClick={() => setGeneralTab('Non-Voice')}
                                onMouseEnter={() => generalTab !== 'Non-Voice' && setNonvoiceImgSrc(nonVoiceHoverImage)} onMouseLeave={() => generalTab !== 'Non-Voice' && setNonvoiceImgSrc(nonVoiceImage)}>
                                <div className={`nav-span`}>
                                    <span className='nav-text' style={{ color: generalTab === 'Non-Voice' && 'black' }}>Non Voice</span></div>
                                {generalTab === 'Non-Voice' && <div className='nav_btn_active'></div>} </Image>
                            <Image src={previewImgSrc} onClick={() => setGeneralTab('Preview')}
                                onMouseEnter={() => generalTab !== 'Preview' && setPreviewImgSrc(previewHoverImage)} onMouseLeave={() => generalTab !== 'Preview' && setPreviewImgSrc(previewImage)}>
                                <div className={`nav-span`}>
                                    <span className='nav-text' style={{ color: generalTab === 'Preview' && 'black' }}>Preview</span></div>
                                {generalTab === 'Preview' && <div className='nav_btn_active'></div>} </Image>
                        </ul>
                    </div>
                </section>
                {uploadTabHandler()}
            </section>
        </React.Fragment>
    )
}

export default UploadNavigation