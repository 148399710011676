import API from "../api";
let token = localStorage.getItem("token");
export const getRoleSTitleService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getWidgetInfoService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};




export const updateWidgetInfoService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};
export const getRecentLinksService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const updateWidgetPreferenceShownService = (requestUrl, data) => {
  console.log(data);
  return API.post(requestUrl,
    {
      "WidgetSelectorShown": data
    }
    , {
      headers: {
        Authorization: token,
      },
    })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getWidgetPreferenceService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getcarouselTitleService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getMultiAccountWidgetService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getMultiWidgetInfoService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const setMultiWidgetInfoService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};




export const getAccountSummaryService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAccountDropdownService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getLatestUpdatesService = (requestUrl) => {
  return API.get(`/api/feed`, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const createLatestUpdatesService = (channel, data) => {
  return API.post(`/api/feed/${channel}/`,data, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const updateLatestUpdatesService = (channel, serial, data) => {
  return API.put(`/api/feed/${channel}/${serial}/`,data, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const deleteLatestUpdatesService = ( channel, serial) => {
  return API.delete(`/api/feed/${channel}/${serial}/`, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getStoredAccountService = (requestUrl) => {
  return API.get(requestUrl, {

    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateStoredAccountService = (requestUrl, id) => {

  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getTaskLinksService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};