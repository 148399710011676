export const adminViewActionTypes = {

    // ROLES FLOW

    GET_ADMIN_ROLES: "GET_ADMIN_ROLES",
    ADMIN_ROLES: "ADMIN_ROLES",

    GET_ACCESS_GROUP: "GET_ACCESS_GROUP",
    ACCESS_GROUP: "ACCESS_GROUP",

    UPDATE_ACCESSGROUP_DATA_START: "UPDATE_ACCESSGROUP_DATA_START",
    UPDATE_ACCESSGROUP_DATA_SUCCESS: "UPDATE_ACCESSGROUP_DATA_SUCCESS",

    UPDATE_ROLE_DELETE_START: "UPDATE_ROLE_DELETE_START",
    UPDATE_ROLE_DELETE_SUCCESS: "UPDATE_ROLE_DELETE_SUCCESS",



    // OPERATIONS FLOW 

    GET_OPERATIONS_DATA: "GET_OPERATIONS_DATA",
    OPERATIONS_DATA: "OPERATIONS_DATA",

    UPDATE_OPERATIONS_DATA_START: "UPDATE_OPERATIONS_DATA_START",
    UPDATE_OPERATIONS_DATA_SUCCESS: "UPDATE_OPERATIONS_DATA_SUCCESS",

    UPDATE_OPERATIONS_DELETE_START: "UPDATE_OPERATIONS_DELETE_START",
    UPDATE_OPERATIONS_DELETE_SUCCESS: "UPDATE_OPERATIONS_DELETE_SUCCESS",


    // ACCESS GROUP FLOW 

    GET_ACCESSGROUP_DATA: "GET_ACCESSGROUP_DATA",
    ACCESSGROUP_DATA: "ACCESSGROUP_DATA",

    GET_ACCESSGROUP_EDIT_DATA: "GET_ACCESSGROUP_EDIT_DATA",
    ACCESSGROUP_EDIT_DATA: "ACCESSGROUP_EDIT_DATA",

    GET_ACCESSGROUP_ADD_DATA: "GET_ACCESSGROUP_ADD_DATA",
    ACCESSGROUP_ADD_DATA: "ACCESSGROUP_ADD_DATA",

    UPDATE_ACCESSGROUP_ADDEDIT_START: "UPDATE_ACCESSGROUP_ADDEDIT_START",
    UPDATE_ACCESSGROUP_ADDEDIT_SUCCESS: "UPDATE_ACCESSGROUP_ADDEDIT_SUCCESS",

    UPDATE_ACCESSGROUP_DELETE_START: "UPDATE_ACCESSGROUP_DELETE_START",
    UPDATE_ACCESSGROUP_DELETE_SUCCESS: "UPDATE_ACCESSGROUP_DELETE_SUCCESS",


    // USERS FLOW

    GET_TIMEZONE_DATA: "GET_TIMEZONE_DATA",
    TIMEZONE_DATA: "TIMEZONE_DATA",

    UPDATE_ADD_USER_START: "UPDATE_ADD_USER_START",
    UPDATE_ADD_USER_SUCCESS: "UPDATE_ADD_USER_SUCCESS",

    UPDATE_DELETE_USER_START: "UPDATE_DELETE_USER_START",
    UPDATE_DELETE_USER_SUCCESS: "UPDATE_DELETE_USER_SUCCESS",

    GET_EDIT_USER_DATA: "GET_EDIT_USER_DATA",
    EDIT_USER_DATA: "EDIT_USER_DATA",

    UPDATE_EDIT_USER_START: "UPDATE_EDIT_USER_START",
    UPDATE_EDIT_USER_SUCCESS: "UPDATE_EDIT_USER_SUCCESS",

    GET_REGION_DATA: "GET_REGION_DATA",
    REGION_DATA: "REGION_DATA",

    GET_UPN_VALIDATION: "GET_UPN_VALIDATION",
    UPN_VALIDATION: "UPN_VALIDATION",

    GET_USERFLOW_USERS: "GET_USERFLOW_USERS",
    USERFLOW_USERS: "USERFLOW_USERS",

    GET_USERFLOW_COUNT: "GET_USERFLOW_COUNT",
    USERFLOW_COUNT: "USERFLOW_COUNT",

    SAVE_USER_STORED_FILTER: "SAVE_USER_STORED_FILTER",
    SAVE_USER_STORED_STATUS: "SAVE_USER_STORED_STATUS",

    // AUDIT RBAC

    GET_AUDITRBAC_DATA:'GET_AUDITRBAC_DATA',
    AUDITRBAC_DATA:'AUDITRBAC_DATA',

    // AUDIT ROLE

    GET_AUDITROLE_DATA:'GET_AUDITROLE_DATA',
    AUDITROLE_DATA:'AUDITROLE_DATA'

}


