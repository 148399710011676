import { onboard_actionTypes as actionTypes } from "../constants/Onboard-actionTypes";
const initialState = {
    accountSites: [],
    generalSiteInfo: [],
    roleTabRoles: [],
    roleTabUsers: [],
    roleTabData: [],
    queueTabLanguages: [],
    originatingCountry: [],
    QueueTableData: [],
    accountSiteInfo: {},
    file: null,
    cancelBtnClick: null,
    SLATemplate: [],
    SLAKPIData: [],
    updatedSLAKPIData: [],
    supportHoursVoice: [],
    NonVoiceSLAdata: [],
    NonVoiceSupportHours: [],
    GeneralNonVoice: [],
    previewOnboardData: [],
    onboardAccounts: [],
    viewAccounts: {},
    editAccounts: {},
    rolesUpdateResponse: [],
    queuesUpdateResponse: [],
    slaUpdateResponse: [],
    supportUpdateResponse: [],
    generalUpdateResponse: [],
    widgetCardNonVoice: {},
    widgetPreviewTab: '',
    validforaddingtabrows:'',
    onboardValidUser:[],
    reopenRequest:[]
}

export const onboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ACCOUNT_SITE:
        case actionTypes.GET_GENERAL_INFO_SITE:
        case actionTypes.GET_ROLES_TAB_ROLES:
        case actionTypes.GET_ROLES_TAB_USERS:
        case actionTypes.GET_ROLES_TAB_DATA:
        case actionTypes.GET_QUEUE_LANGUAGES:
        case actionTypes.GET_ORIGINATING_COUNTRIES:
        case actionTypes.GET_QUEUE_TABLE_DATA:
        case actionTypes.GET_SLA_TEMPLATES:
        case actionTypes.GET_SLA_KPI_DATA:
        case actionTypes.GET_SUPPORT_HOURS_VOICE:
        case actionTypes.GET_NON_VOICE_SLA_DATA:
        case actionTypes.GET_NON_VOICE_SUPPORT_HOURS:
        case actionTypes.GET_GENERAL_NON_VOICE:
        case actionTypes.GET_PREVIEW_ONBOARD:
        case actionTypes.GET_ONBOARD_ACCOUNTS:
        case actionTypes.GET_ONBOARD_VALID_USER:

            return { ...state };
        case actionTypes.ACCOUNT_SITE:
            return { ...state, accountSites: action.payload };
        case actionTypes.GENERAL_INFO_SITE:
            return { ...state, generalSiteInfo: action.payload };
        case actionTypes.ROLES_TAB_ROLES:
            return { ...state, roleTabRoles: action.payload };
        case actionTypes.ROLES_TAB_USERS:
            return { ...state, roleTabUsers: action.payload };
        case actionTypes.ROLES_TAB_DATA:
            return { ...state, roleTabData: action.payload };
        case actionTypes.QUEUE_LANGUAGES:
            return { ...state, queueTabLanguages: action.payload };
        case actionTypes.ORIGINATING_COUNTRIES:
            return { ...state, originatingCountry: action.payload };
        case actionTypes.QUEUE_TABLE_DATA:
            return { ...state, QueueTableData: action.payload };
        case actionTypes.SLA_TEMPLATES:
            return { ...state, SLATemplate: action.payload };
        case actionTypes.GENERAL_NON_VOICE:
            return { ...state, GeneralNonVoice: action.payload };
        case actionTypes.SLA_KPI_DATA:
            return { ...state, SLAKPIData: action.payload };
        case 'SETIMG':
            return { ...state, file: action.payload }
        case actionTypes.SAVE_ACCOUNT_SITE: {
            return { ...state, accountSiteInfo: action.payload }
        }
        case actionTypes.SUPPORT_HOURS_VOICE: {
            return { ...state, supportHoursVoice: action.payload }
        }
        case actionTypes.NON_VOICE_SLA_DATA: {
            return { ...state, NonVoiceSLAdata: action.payload }
        }
        case actionTypes.NON_VOICE_SUPPORT_HOURS: {
            return { ...state, NonVoiceSupportHours: action.payload }
        }


        case actionTypes.UPDATE_ROLE_DATA_START: {
            return { ...state, rolesUpdateResponse: action.payload }
        }

        case actionTypes.UPDATE_QUEUE_TABLE_START: {
            return { ...state, queuesUpdateResponse: action.payload }
        }
        case actionTypes.UPDATE_SLA_TABLE_START: {
            return { ...state, slaUpdateResponse: action.payload }
        }

        case actionTypes.UPDATE_SUPPORT_TABLE_START: {
            return { ...state, supportUpdateResponse: action.payload }
        }


        case actionTypes.UPDATE_GENERAL_NON_VOICE_START: {
            return { ...state, generalUpdateResponse: action.payload }
        }

        case actionTypes.VIEW_ACCOUNT_DETAILS: {
            return { ...state, viewAccounts: action.payload }
        }
        case actionTypes.EDIT_ACCOUNT_DETAILS: {
            return { ...state, editAccounts: action.payload }
        }
        case actionTypes.PREVIEW_ONBOARD: {
            return { ...state, previewOnboardData: action.payload }
        }
        case actionTypes.ONBOARD_ACCOUNTS: {
            return { ...state, onboardAccounts: action.payload }
        }
        case actionTypes.SET_WIDGET_CARD_NON_VOICE: {
            return { ...state, widgetCardNonVoice: action.payload }
        }
        case actionTypes.SET_WIDGET_PREVIEW_TAB: {
            return { ...state, widgetPreviewTab: action.payload }
        }
        case actionTypes.SET_VALIDFOR_ADDING_TABROWS: {
            return { ...state, validforaddingtabrows: action.payload }
        }
        case actionTypes.ONBOARD_VALID_USER: {
            return { ...state, onboardValidUser: action.payload }
        }
        case actionTypes.UPDATE_REOPEN_REQUEST_START: {
            return { ...state, reopenRequest: action.payload }
        }
        default:
            return state;
    }
}

