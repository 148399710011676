import React from 'react'
import { Footer } from '../HeaderFooter';
import './NotDesigned.css';
const NotDesigned = () => {
  return (
  <> 
   <div style={{marginLeft: -100}}>
  <section style={{width:1200,margin:'auto',display:'flex',height:'397px'}}>
          <div className='notDesigned'> 
          <div className='text_notinproduction'> This Page is not in production yet, links will be available later. </div></div>
        </section>
        <Footer/>
        </div>
        </> 
  )
}

export default NotDesigned