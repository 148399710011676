import React, { useState } from 'react';
import './TableTemplate.css'
import TooltipHandler from '../TooltipHandler';
import Information_Icon from './../../../../assets/onboard-images/Information_Icon.png'
import { OnboardSelect } from '../Dropdown/Select';
import DatePickerHandle from '../KeyboardDatePicker';



export function AddQueueModal(props) {
    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    const ValidFromDateHandler = (date) => {
        props.addDateToQueue('valid_from', formatDate(date))
        setValidFrom(formatDate(date));

    }
    const ValidToDateHandler = (date) => {
        props.addDateToQueue('valid_to', formatDate(date))
        setValidTo(formatDate(date));

    }
    const editMode = props.editMode;
    return (
        <>
            <section className='add-row-container-queue'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'>
                        {props.modalName}
                    </div>
                    <div style={{ color: '#ED4F32' }}>{props.validation}</div>
                    <form onSubmit={editMode ? props.handleEditQueueSubmit : props.handleAddQueueSubmit} >
                        <main className='input-container-one-queue'>
                            <div className='container-one'>
                                <label>Queue Name *</label>
                                {editMode ? <input type='text'
                                    name='queue_name'
                                    placeholder='Example: AUDI_HU_VIP_BH'
                                    value={props.editQueueInfo.queue_name}
                                    onChange={props.handleFormAddQueue}
                                /> :
                                    <input type='text'
                                        name='queue_name'
                                        placeholder='Example: AUDI_HU_VIP_BH'
                                        onChange={props.handleFormAddQueue}
                                    />}
                            </div>
                            <div className='container-one'>
                                <label>Business Unit </label>
                                {editMode ? <input type='text'
                                    name='business_unit'
                                    placeholder='Example:Store_VIP_Slovakia_VIP'
                                    value={props.editQueueInfo.business_unit}
                                    onChange={props.handleFormAddQueue}
                                /> : <input type='text'
                                    name='business_unit'
                                    placeholder='Example:Store_VIP_Slovakia_VIP'
                                    onChange={props.handleFormAddQueue}
                                />}
                            </div>
                        </main>
                        <main className='input-container-one-queue'>
                            <div className='container-one'>
                                <label HtmlFor="lang" >Language *</label>
                                <OnboardSelect data={props.languageInfo} name='language' value='Language' id='LangID'
                                    editValue={editMode ? props.editQueueInfo.language : ''} addValueToQueue={props.addValueToQueue}
                                />
                            </div>
                            <div className='container-one'>
                                <label HtmlFor='country'> Orginating Country</label>
                                <OnboardSelect data={props.countryInfo} name='country' value='Country' id='Id'
                                    editValue={editMode ? props.editQueueInfo.country : ''} addValueToQueue={props.addValueToQueue} />
                            </div>
                        </main>
                        <main className='input-container-three-queue'>
                            <div className='container-three'>
                                <label style={{ display: 'flex' }}><span>Claim Number</span> &nbsp; &nbsp;<span style={{ position: 'relative' }} >
                                    <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right'
                                        arrowAlign='start'
                                        textBoxWidth='300px' info={true} text='“Claim number: A phone number. Applicable, if only part of the queue belongs to the business unit.” ' />
                                </span>

                                </label>
                                {editMode ? <input type='text'
                                    name='claim_no'
                                    pattern='[+][0-9].*$'
                                    placeholder='Example:+318000900057'
                                    value={props.editQueueInfo.claim_no}
                                    onChange={props.handleFormAddQueue}
                                /> : <input type='text'
                                    name='claim_no'
                                    pattern='[+][0-9].*$'
                                    placeholder='Example:+318000900057'
                                    onChange={props.handleFormAddQueue}
                                />}
                            </div>

                            <DatePickerHandle className='container-three' label='Valid From *' id='label'
                                value={editMode ? props.editQueueInfo.valid_from : ValidFrom}
                                onChange={(date) => editMode ? props.handleQueueEditDate('valid_from', formatDate(date)) : ValidFromDateHandler(date)}
                            />
                            <DatePickerHandle className='container-three' label='Valid To *' id='label'
                                value={editMode ? props.editQueueInfo.valid_to : ValidTo}
                                onChange={(date) => editMode ? props.handleQueueEditDate('valid_to', formatDate(date)) : ValidToDateHandler(date)}
                            />

                        </main>
                        <main className='input-container-four-queue' style={{ marginTop: '10px' }}>
                            <button type='submit' className='btn_adduser'> {props.buttonOne} </button>
                            <button className='btn_canceluser' onClick={props.handleAddQueueCancel}> {props.buttonTwo}  </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}
