import React, { Fragment, useEffect, useState } from 'react';
import './../QueueTable/TableTemplate.css'
import searchIcon from './../../../../assets/onboard-images/Search_Icon.png'
import AdduserImg from "./../../../../assets/images/Add_User_Icon.png";
import { AddRoleModal } from './AddRoleModel'
import "./Roles.css";
import { DeleteFragment, DeleteModalPopup } from '../Modals/DeleteModalPopup';
import { RoleModalPopup } from '../Modals/ModalQueuePopup';
import TooltipHandler from '../TooltipHandler';
import editIcon from './../../../../assets/onboard-images/Edit_Icon.png'
import deleteIcon from './../../../../assets/onboard-images/Delete_Icon.png'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment'
import { getOnboardValidUser, getRolesTabRoles, getRolesTabUsers, setValidforAddingTabRows } from '../../../../actions/Onboard.action';
import { HintsRoles } from '../OffCanvas/HintsRoles';
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const headers = [
    {
        caption: "Role",
        id: "1"
    }, {
        caption: "UserName",
        id: "2"
    },
    {
        caption: "EmailID",
        id: "3"
    },
    {
        caption: " Valid From",
        id: "4"
    },
    {
        caption: "Valid To",
        id: "5"
    }
]

function RoleComponent(props) {
    const location = useLocation()
    const dispatch = useDispatch();
    let usernameData = useSelector((state) => state.onboardReducer.roleTabUsers);
    let rolenameInfo = useSelector((state) => state.onboardReducer.roleTabRoles);
    const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
    const onboardValidUser = useSelector((state) => state.onboardReducer.onboardValidUser);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    const [validation, setValidation] = useState('');
    const [usernameInfo, setUsernameInfo] = useState([]);

    console.log('RoleBasedView',RoleBasedView?.[0]?.Roles?.[0]?.ReadWrite)
    useEffect(() => {
        setUsernameInfo(usernameData)
    }, [usernameData])
    const QueryHandler = query => {
        setTimeout(() => {
            dispatch(getRolesTabUsers(query));
            setUsernameInfo(usernameData)
        }, 2000)
        if (query === '') {
            dispatch(getRolesTabUsers(''));
        }
    }

    const [validUser, setValidUser] = useState();
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    };
    useEffect(() => {
        dispatch(getRolesTabUsers(''));
        dispatch(getRolesTabRoles());
        dispatch(getOnboardValidUser(accountSiteInfo.AccountName, accountSiteInfo.SiteName))
        dispatch(getRoleBasedView())
    }, [dispatch])

    const renderRolesTabData = (data) => {
        console.log('renderRolesTabData',data)
        let temp_Arr = [...data];
        let temp_arr = temp_Arr?.map((temp, i) => {
            return {
                emailID: temp.EmailID,
                role: temp.Role,
                name: temp.Name,
                valid_from:temp['Valid From'] === null ? null : formatDate(temp['Valid From']),
                valid_to: temp['Valid To'] === null ? null :formatDate(temp['Valid To']),
                username: temp.User
            }
        });
        return temp_arr;
    }
    let data = (props.roletabdata?.length > 0 ? renderRolesTabData(props.roletabdata) : []);
    const [roleData, setRoleData] = useState(data);
    const [mode, setMode] = useState(null);
    useEffect(() => {
        if (props.accountDetails) setMode(props.accountDetails.mode)
    }, [props.accountDetails])

    useEffect(() => {
        (props.accountDetails.mode === 'create') ? setRoleData(data) : setRoleData(data)
    }, [props.roletabdata])
    const [addRoleSubmit, setRoleAddSubmit] = useState(false)
    const [filter, setFilter] = useState('');
    const [showAddRoleModal, setShowAddRoleModal] = useState(false);
    const [showEditRoleModal, setShowEditRoleModal] = useState(false);
    const [showDeleteRoleModal, setShowDeleteRoleModal] = useState(false);
    const [showFromInfo, setShowFromInfo] = useState(false)
    const [searchColumns, setSearchColumns] = useState([
        'role', 'username', 'emailID'

    ]);
    const [addFormRole, setAddFormRole] = useState({
        role: '',
        username: '',
        emailID: '',
        valid_from: '',
        valid_to: ''
    })

    const [editRoleID, setEditRoleID] = useState(null);
    const [editRoleInfo, setEditRoleInfo] = useState({
        role: '',
        username: '',
        emailID: '',
        valid_from: '',
        valid_to: ''
    });

    const [deleteRoleID, setDeleteRoleID] = useState(null);
    const emailIDhandler = (username) => {
        if (username) {
            let emailObj = usernameInfo?.filter(info => info?.nameEmail === username);
            return emailObj?.[0]?.email || '';
        }
        else return '';
    }
    const userNamehandler = (username) => {
        if (username) {
            let userNameObj = usernameInfo?.filter(info => info?.nameEmail === username);
            return userNameObj?.[0]?.name || '';
        }
        else return '';
    }

    const [dispatchRoleObj, setDispatchRoleObj] = useState([])
    const addValueToRole = (name, value) => {
        if (value !== 'Select') {
            const newFormData = { ...addFormRole };
            newFormData[name] = value;
            setAddFormRole(newFormData)
        }
    }
    const HandleAddRole = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...addFormRole };
            newFormData[fieldName] = fieldValue;
            setAddFormRole(newFormData)
            setRoleAddSubmit(false);
        }
    }
    const configureRoleData = roleData => {
        let temp_arr = [...roleData];
        let tempArray2 = temp_arr.map(role => {
            return {
                email: role.emailID,
                Role: role.role,
                ValidSince: role.valid_from,
                ValidUntil: role.valid_to
            }
        });
        let tempArray3 = [{
            Account: accountSiteInfo.AccountName,
            Servicedesk: accountSiteInfo.SiteName,
            ValidFrom: accountSiteInfo.ValidFrom,
            ValidTo: accountSiteInfo.ValidTo,
            Details: tempArray2
        }]

        setDispatchRoleObj(tempArray3);

    }


    const handleAddRoleSubmit = event => {
        event.preventDefault();
        const newRole = {
            id: (Math.random() * 10000).toString(),
            role: addFormRole.role,
            name:userNamehandler(addFormRole.username),
            username: addFormRole.username,
            emailID: emailIDhandler(addFormRole.username),
            valid_from: (addFormRole.valid_from),
            valid_to: (addFormRole.valid_to)
        }
        setAddFormRole(newRole)
        const newRoles = [newRole, ...roleData];
        const temp_Arr = {
            role: addFormRole.role,
            username: addFormRole.username,
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        // const isEmptyInput = Object.values(newRole).some(x => (x === null || x === '' || x === undefined));
        // const isValidDate = moment(addFormRole.valid_to).isAfter(addFormRole.valid_from)
        if (isEmptyInput) {
            // setRoleAddSubmit(true);
            setShowAddRoleModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        // else if (!isValidDate) {
        //     setShowAddRoleModal(true);
        //     setValidation('Please ensure "Valid to" date is placed after "Valid from" date ')
        // }
         else {
            setRoleData(newRoles)
            setShowAddRoleModal(false);
            configureRoleData(newRoles);
            setValidation('')
        }
    }

    const handleAddRoleCancel = event => {
        event.preventDefault();
        setShowAddRoleModal(false);
        setRoleAddSubmit(false);
    }
    const handleRoleEditDate = (name, value) => {
        const newFormData = { ...editRoleInfo };
        newFormData[name] = value;
        setEditRoleInfo(newFormData);

    }
    const onClickSelect = (name) => {
        const newFormData = { ...editRoleInfo };
        newFormData[name] = '';
        setEditRoleInfo(newFormData)

    }
    const handleEditRoleSelect = (name, value) => {
        const newFormData = { ...editRoleInfo };
        if (value !== 'Select') {
            newFormData[name] = value;
            setEditRoleInfo(newFormData);
        }
    }
    const handleEditRoleUpdate = event => {
        event.preventDefault();
        const fieldName = event.target.getAttribute("name");
        const fieldValue = event.target.value;
        const newFormData = { ...editRoleInfo };
        newFormData[fieldName] = fieldValue;
        setEditRoleInfo(newFormData);
    }

    const handleEditRoleSubmit = event => {
        event.preventDefault(); // editRoleInfo
        const editedRole = {
            id: editRoleID,
            role: editRoleInfo.role,
            name: userNamehandler(editRoleInfo.username) ? userNamehandler(editRoleInfo.username) : editRoleInfo.name,
            username:editRoleInfo.username,
            emailID: emailIDhandler(editRoleInfo.username) ? emailIDhandler(editRoleInfo.username) : editRoleInfo.emailID, 
            valid_from: editRoleInfo.valid_from,
            valid_to: editRoleInfo.valid_to
        };
         console.log('roledata::::::::::::',...roleData)
        const newRoles = [...roleData];
        console.log('newRoles:::::::::::::  2',newRoles)
        const temp_Arr = {
            role: editRoleInfo.role,
            username:editRoleInfo.username,
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        // const isEmptyInput = Object.values(editRoleInfo).some(x => (x === null || x === '' || x === undefined));
        // const isValidDate = moment(editRoleInfo.valid_to).isAfter(editRoleInfo.valid_from)
        if (isEmptyInput) {
            setShowEditRoleModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        // else if (!isValidDate) {
        //     setShowEditRoleModal(true);
        //     setValidation('Please ensure "Valid to" date is placed after "Valid from" date ')
        // }
         else {
            const index = roleData.findIndex((_, index) => index === editRoleID);
            newRoles[index] = editedRole;
            console.log('newRoles:::::::::::::',newRoles)
            setRoleData(newRoles);
            configureRoleData(newRoles);
            setEditRoleID(null);
            setShowEditRoleModal(false);
            setValidation('')
        }

    }
    const handleEditRoleCancel = event => {
        event.preventDefault();
        setEditRoleID(null);
        setShowEditRoleModal(false)
    }


    const handleDeleteRole = () => {
        const newRoles = [...roleData];
        const index = roleData.findIndex((_, index) => index === deleteRoleID);
        newRoles.splice(index, 1);
        setRoleData(newRoles);
        configureRoleData(newRoles);
        setShowDeleteRoleModal(false)
    }
    const handleDeleteRoleCancel = event => {
        event.preventDefault();
        setShowDeleteRoleModal(false)
    }

    function searchRows(rows) {
        return rows.filter((row) =>
            searchColumns.some(
                (column) =>
                    row[column]
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.toLowerCase()) > -1,
            ),
        );
    }
    const ValidUser = () => {
        if(props.GeneralInfoToggle){
            setAddFormRole([])
        setShowAddRoleModal(true);
        dispatch(setValidforAddingTabRows(false))
        }
        else{
            window.scrollTo(0, 0);
            dispatch(setValidforAddingTabRows(true))
        }
    }

    useEffect(() => {
        console.log(dispatchRoleObj)
        props.DispatchRolehandler(dispatchRoleObj)
    }, [dispatchRoleObj])

    return (<>

        <Fragment>

            <div className='hints_row'>
                <div className='search_adduser'>
                    {validUser && <div>invalid</div>}
                    <div> <span className='search-bar-role'>
                        <input className='search-input-role' value={filter || ''} onChange={e => setFilter(e.target.value)} placeholder='Search User Roles' />
                        <button className='search-icon'><img src={searchIcon} alt='' /> </button>
                    </span></div>
                    <div style={{ display: "flex" }}>  <span className="offcanves_role">
                        <HintsRoles title='Roles Hints' style={{ textAlign: 'right' }} /></span>
                      {(RoleBasedView?.[0]?.Roles?.[0]?.ReadWrite === 0 && location.pathname === '/admin/account') ? '' : <> {(mode === 'create' || mode === 'edit') && <span className='add-role__flex' style={{ textAlign: 'right', cursor: 'pointer' }}> <span className='add-Role' onClick={ValidUser}>
                            <img src={AdduserImg} alt='' />
                            <span className='add_role_text'>Add Role</span>
                        </span></span>}</>}
                    </div>

                </div>
            </div>
        </Fragment>

        {searchRows(roleData).length === 0 ?

            <section>    <table style={{ width: '100%', marginTop: '1em' }} className='queue-table'>
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th style={{ border: 'none' }} key={header.id}><span className='roles_header'>{header.caption}</span></th>
                        ))
                        }
                        <th style={{ border: 'none' }}>{'  '}</th>
                    </tr>
                </thead>
            </table>
                <section className='noData-table'>
                    <div className='noData-para' style={{ marginTop: 0 }}>
                        <div style={{ marginTop: 30 }}>
                            No records found.<br /> To add new Role entries, click on "Add Role"  link.
                        </div>
                    </div>
                </section>
            </section>
            : <table style={{ width: '100%', marginTop: '1em' }} className='queue-table'>
                <thead>
                    <tr>
                        {headers.map(header => (
                            <th style={{ border: 'none' }} key={header.id}><span className='roles_header'>{header.caption}</span></th>
                        ))
                        }
                        <th style={{ border: 'none' }}>{'  '}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        searchRows(roleData).map((data, index) => (
                            <tr key={data.id}>
                                <td className='roles_td'>{data?.role}</td>
                                <td className='roles_td'>{(data?.name)}</td>
                                <td className='roles_td'>{data?.emailID}</td>
                                <td className='roles_td'>{(data?.valid_from === null || data?.valid_from === undefined) ? <div style ={{color:'black'}}>not defined</div> : data?.valid_from}</td>
                                <td className='roles_td'>{(data?.valid_to === null || data?.valid_to === undefined) ? <div style ={{color:'black'}}>not defined</div> : data?.valid_to}</td>
                                {(mode === 'create' || mode === 'edit') && <td className='table-button'>
                                    {(RoleBasedView?.[0]?.Roles?.[0]?.ReadWrite === 0 && location.pathname === '/admin/account') ? '' :<span onMouseOver={() => setShowFromInfo(true)} onMouseLeave={() => setShowFromInfo(false)} onClick={(event) => {
                                        event.preventDefault()
                                        setEditRoleID(index)
                                        setShowEditRoleModal(true)
                                        const formValues = {
                                            role: data?.role,
                                            name: data?.name,
                                            username: data?.username,
                                            emailID: data?.emailID,
                                            valid_from: data?.valid_from,
                                            valid_to: data?.valid_to
                                        };
                                        setEditRoleInfo(formValues);
                                    }} >
                                        <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                                    </span> }

                                   {location.pathname === '/admin/account' ? <> {(RoleBasedView?.[0]?.Roles?.[0]?.Delete === 1 ) ?  <span onClick={() => { setShowDeleteRoleModal(true); setDeleteRoleID(index) }}>
                                        <TooltipHandler src={deleteIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Delete' />
                                    </span> : '' }</> : <span onClick={() => { setShowDeleteRoleModal(true); setDeleteRoleID(index) }}>
                                        <TooltipHandler src={deleteIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Delete' />
                                    </span>}
                                </td>
                                }
                            </tr>
                        ))
                    }
                </tbody>
            </table>}
        {showAddRoleModal && <RoleModalPopup isOpen={showAddRoleModal} setShowModal={setShowAddRoleModal}>
            <AddRoleModal modalName='Add Role' buttonOne='ADD' buttonTwo='CANCEL'
                editMode={false} handleFormAddRole={HandleAddRole} editRoleInfo={editRoleInfo}
                handleAddRoleSubmit={handleAddRoleSubmit} usernameInfo={usernameInfo} rolenameInfo={rolenameInfo}
                handleAddRoleCancel={handleAddRoleCancel}
                addValueToRole={addValueToRole}
                addDateToRole={addValueToRole}
                QueryHandler={QueryHandler}
                validation={validation}
                OnClickSelect={() => { }}
            />
        </RoleModalPopup>}
        {showEditRoleModal && <RoleModalPopup isOpen={showEditRoleModal} setShowModal={setShowEditRoleModal}>
            <AddRoleModal modalName='Edit Role' buttonOne='UPDATE' buttonTwo='CANCEL' usernameInfo={usernameInfo}
                editMode={true} editRoleInfo={editRoleInfo} rolenameInfo={rolenameInfo}
                handleFormAddRole={handleEditRoleUpdate}
                handleAddRoleSubmit={handleEditRoleSubmit}
                handleAddRoleCancel={handleEditRoleCancel}
                handleRoleEditDate={handleRoleEditDate}
                QueryHandler={QueryHandler}
                addValueToRole={handleEditRoleSelect}
                validation={validation}
                OnClickSelect={onClickSelect}
            />
        </RoleModalPopup>}
        {showDeleteRoleModal && <DeleteModalPopup isOpen={showDeleteRoleModal} setShowModal={setShowDeleteRoleModal}>
            <DeleteFragment modalName='Delete Role' buttonOne='YES' buttonTwo='NO'
                delete={true}
                handleDeleteQueue={handleDeleteRole}
                handleDeleteQueueCancel={handleDeleteRoleCancel}
                queueName={roleData[roleData.findIndex((_, index) => index === deleteRoleID)].role}
            />
        </DeleteModalPopup>}

    </>);
}

export default RoleComponent