import actionTypes from "../constants/actionTypes";

const initialState = {
    DropdownInfo: {},
    AccountInfo: {},
    AverageHandleTime: {},
    AbandonRate: {},
    DigitalTrans: {},
    CostPerContact: {},
    contactFTEData: {},
    penaltyAtRisk: {},
    firstContactResolution: {},
    attritionData: {},
    SLAcompliance: {},
    CSATData: {},
    deliverySupport: {},
    contactVolumeData: {},
    accountDrpdwnListData: [],
    locationDrpdwnListData: []
}




const reportTableReducer = (state = initialState, action) => {
    switch (action.type) {


        case actionTypes.GET_ACCOUNT_INFO:
        case actionTypes.GET_DROPDOWN_INFO:
        case actionTypes.GET_AVERAGE_HANDLE_TIME:
        case actionTypes.GET_ABANDON_RATE:
        case actionTypes.GET_DIGITAL_TRANS:
        case actionTypes.GET_COST_PER_CONTACT:
        case actionTypes.GET_CONTACT_FTE_DATA:
        case actionTypes.GET_PENALTY_AT_RISK:
        case actionTypes.GET_FIRST_CONTACT_RESOLUTION:
        case actionTypes.GET_ATTRITION_DATA:
        case actionTypes.GET_SLA_COMPLIANCE:
        case actionTypes.GET_CSAT_DATA:
        case actionTypes.GET_DELIVERY_SUPPORT:
        case actionTypes.GET_CONTACT_VOLUME_INFO:
            return { ...state };

        case actionTypes.DROPDOWN_INFO:
            return { ...state, DropdownInfo: action.payload };
        case actionTypes.ACCOUNT_INFO:
            return { ...state, AccountInfo: action.payload };
        case actionTypes.AVERAGE_HANDLE_TIME:
            return { ...state, AverageHandleTime: action.payload };
        case actionTypes.ABANDON_RATE:
            return { ...state, AbandonRate: action.payload };
        case actionTypes.DIGITAL_TRANS:
            return { ...state, DigitalTrans: action.payload };
        case actionTypes.COST_PER_CONTACT:
            return { ...state, CostPerContact: action.payload };
        case actionTypes.CONTACT_FTE_DATA:
            return { ...state, contactFTEData: action.payload };
        case actionTypes.PENALTY_AT_RISK:
            return { ...state, penaltyAtRisk: action.payload };
        case actionTypes.FIRST_CONTACT_RESOLUTION:
            return { ...state, firstContactResolution: action.payload };
        case actionTypes.ATTRITION_DATA:
            return { ...state, attritionData: action.payload };
        case actionTypes.SLA_COMPLIANCE:
            return { ...state, SLAcompliance: action.payload };
        case actionTypes.CSAT_DATA:
            return { ...state, CSATData: action.payload };
        case actionTypes.DELIVERY_SUPPORT:
            return { ...state, deliverySupport: action.payload };
        case actionTypes.CONTACT_VOLUME_INFO:
            return { ...state, contactVolumeData: action.payload };
        case actionTypes.SAVE_ACCOUNT_DRPDWN_LIST_DATA:
            return { ...state, accountDrpdwnListData: action.payload };
        case actionTypes.SAVE_LOCATION_DRPDWN_LIST_DATA:
            return { ...state, locationDrpdwnListData: action.payload };
        default:
            return state;
    }
}
export default reportTableReducer