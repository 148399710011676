import React, { useEffect, useState } from 'react';
import './DataAdmin.css'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import FileUpload_Icon from './../../../assets/images/FileUpload_Icon.png';
import { Tab, Tabs } from 'react-bootstrap';
import { FormFieldHandle } from './UploadDataAdmin';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSLAKPIVolumeTemplates, setSelectedSLAKPIFields, setSelectedSLAKPIVolumeTab, setSelectedVolumeFields } from '../../../actions/uploads.action';



function DataAdministration() {
    const history = useHistory();
    const dispatch = useDispatch();
    const SlaKpiVolumeTemplates = useSelector((state) => state.uploadReducer.SlaKpiVolumeTemplates);
    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
    const dataAdministrationValidation = useSelector((state) => state.uploadReducer.dataAdministrationValidation);

    console.log('dataAdministrationValidation', dataAdministrationValidation)



    const [validation, setValidation] = useState(false);
    const [error, setError] = useState(false);
    const [fieldKey, setfieldKey] = useState('Volume')
    console.log('validation',validation)
    const handleTabSelect = (key) => {
        if (validation === true) {
            setError(true)
        }
        else {
            setError(false)
            setfieldKey(key)
        }

    }

    useEffect(()=>{
        if(validation === false){
            setError(validation)
        }
    },[validation])

    const handler = (data) => {
        console.log('data', data)
        setValidation(data)
    }

    console.log('validation', validation)


    const shiftToSLAKPITab = () => {
        console.log('clicked:::::::::::::::::::::::::::::')
        setfieldKey('sla/kpi')
    }

    useEffect(() => {
        dispatch(getSLAKPIVolumeTemplates(SelectedAccountDetails.CustomerID ? SelectedAccountDetails.CustomerID : '', fieldKey))
        dispatch(setSelectedSLAKPIVolumeTab(fieldKey))
    }, [dispatch, fieldKey, SelectedAccountDetails])


    console.log('field', fieldKey)

    return (
        <>
            <AdminBreadcrumb value1={() => { validation ? setError(true) : history.push('/welcome') }} label1='Home' isActive2={true} label2='Uploads' value2={() => { validation ? setError(true) : history.push('/upload') }}
                isActive3={false} label3={SelectedAccountDetails?.['Account Name'] + '-' + SelectedAccountDetails?.['Site Name']} />
            <section className='data_Admin_container'>
                <main className='display_flex'>
                    <div className='onboard_account' ><div><img
                        style={{ maxHeight: '40px', maxWidth: '60px' }}
                        // height='20px'
                        // width='60px'
                        type="image/jpeg"
                        src={`${process.env.REACT_APP_BASE_URL
                            }/api/org/customer/${SelectedAccountDetails?.CustomerID}/photo`}
                        alt="Client Logo"
                    /></div> {SelectedAccountDetails?.['Account Name']} - {SelectedAccountDetails?.['Site Name']} - Upload Data Administration</div>
                    <div style={{ marginTop: '30px' }}>
                        <button className='cancel-button extra_Cancel' style={{ width: '300px' }}
                            onClick={() => { validation ? setError(true) : history.push('/upload/account') }}>
                            <img src={FileUpload_Icon} alt='' style={{ marginLeft: '10px' }} />
                            <span className='cancel-span' style={{ marginTop: '4px' }}>File Upload - Voice & Non Voice </span>
                        </button>
                    </div>
                </main>
                {error && <div className='dataSave_div' style={{backgroundColor:'red'}}><div>Data Not Saved.</div></div>}
                <main>
                    <Tabs activeKey={fieldKey} onSelect={handleTabSelect}>
                        <Tab eventKey="Volume" title="Volume Data" key="Volume">
                            <FormFieldHandle data={SlaKpiVolumeTemplates} tabName='Volume' onSaveButtonClick={fields => {
                                console.log(fields) 
                                dispatch(setSelectedVolumeFields(fields))
                            }} onTemplateChange={(templateChanges) => handler(templateChanges)} shiftToSLAKPITab={shiftToSLAKPITab}/>
                        </Tab>
                        <Tab eventKey="sla/kpi" title='SLAs/KPIs Data' key="sla/kpi">
                            <FormFieldHandle data={SlaKpiVolumeTemplates} tabName='SLA/KPI'
                                onTemplateChange={(templateChanges) => handler(templateChanges)}
                                onSaveButtonClick={fields => dispatch(setSelectedSLAKPIFields(fields))} />
                        </Tab>
                    </Tabs>
                </main>

                <br />
                <br />
                <br />
            </section>
        </>
    )
}

export default DataAdministration