import { all, call, put, takeEvery} from "redux-saga/effects";
import { UploadActionTypes as actionTypes} from "../constants/uploads-actionTypes";
import { getFileUploadDataService, getNonvoiceCardsDataService, getSLAKPIVolumeTemplatesService, getUploadAccountsService, getUploadPreviewDataService, getVolumeSlakpiSelectedTemplatesDataService, updateCustomTemplateService, updateFileUploadDataService, UpdateSelectedSLAKPIVolumeService, updateVolumeSLAKPIDataService } from "../services/Upload.service";
import { UploadURLS } from "../constants/Upload-urls";
import { updateCustomTemplateSuccess, updateFileUploadDataSuccess, updateSelectedSLAKPIVolumeSuccess, updateVolumeSLAKPIDataSuccess } from './../actions/uploads.action';


function* fetchUploadAccountsData() {
    try {
        const response = yield call(getUploadAccountsService, UploadURLS('GET_UPLOAD_ACCOUNTS'));
        if (response.data) {
            yield put({
                type: actionTypes.UPLOAD_ACCOUNTS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchSLAKPIVolumeTemplates({customerID,category}) {
    let items = {
        customerID:customerID,
        category:category
    }
    try {
        const response = yield call(getSLAKPIVolumeTemplatesService, UploadURLS('GET_SLAKPI_VOLUME_TEMPLATES',items));
        if (response.data) {
            yield put({
                type: actionTypes.SLAKPI_VOLUME_TEMPLATES,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateSelectedSLAKPIVolume({ payload: id }) {
    try {
        const response = yield call(UpdateSelectedSLAKPIVolumeService, UploadURLS('UPDATE_SELECTED_SLAKPI_VOLUME'), id);
        if (response.data) {
            yield put(
                updateSelectedSLAKPIVolumeSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating selected SLAKPI Volume data", error);
    }
}

function* updateCustomTemplate({ payload: id }) {
    try {
        const response = yield call(updateCustomTemplateService, UploadURLS('UPDATE_CUSTOM_TEMPLATE'), id);
        if (response.data) {
            yield put(
                updateCustomTemplateSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating new Custom template data", error);
    }
}

function* fetchFileUploadData({customerID,servicedeskID,selectedVoiceNonVoiceTab}) {
    let items = {
        customerID:customerID,
      servicedeskID:servicedeskID,
      selectedVoiceNonVoiceTab:selectedVoiceNonVoiceTab
    }
    try {
        const response = yield call(getFileUploadDataService, UploadURLS('GET_FILE_UPLOAD_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.FILE_UPLOAD_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateFileUploadData({ payload: id }) {
    try {
        const response = yield call(updateFileUploadDataService, UploadURLS('UPDATE_FILE_UPLOAD_DATA'), id);
        if (response.data) {
            yield put(
                updateFileUploadDataSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating file upload data", error);
    }
}

function* fetchNonvoiceCardsData({customerID,servicedeskID,selectedVoiceNonVoiceTab,month}) {
    let items = {
        customerID:customerID,
      servicedeskID:servicedeskID,
      selectedVoiceNonVoiceTab:selectedVoiceNonVoiceTab,
      month:month
    }
    try {
        const response = yield call(getNonvoiceCardsDataService, UploadURLS('GET_NONVOICE_CARDS_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.NONVOICE_CARDS_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchUploadPreviewData({customerID,servicedeskID,month}) {
    let items = {
        customerID:customerID,
      servicedeskID:servicedeskID,
      month:month
    }
    try {
        const response = yield call(getUploadPreviewDataService, UploadURLS('GET_UPLOAD_PRIVIEW_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.UPLOAD_PRIVIEW_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchVolumeSlakpiSelectedTemplatesData({customerID,servicedeskID,category,month,itemname}) {
    let items = {
        customerID:customerID,
        servicedeskID:servicedeskID,
        category:category,
        month:month,
        itemname:itemname
    }
    try {
        const response = yield call(getVolumeSlakpiSelectedTemplatesDataService, UploadURLS('GET_VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA',items));
        if (response.data) {
            yield put({
                type: actionTypes.VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateVolumeSLAKPIData({ payload: id }) {
    try {
        const response = yield call(updateVolumeSLAKPIDataService, UploadURLS('UPDATE_VOLUME_SLAKPI_DATA'), id);
        if (response.data) {
            yield put(
                updateVolumeSLAKPIDataSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Volume and SLA KPI data", error);
    }
}

function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_UPLOAD_ACCOUNTS, fetchUploadAccountsData);
    yield takeEvery(actionTypes.GET_SLAKPI_VOLUME_TEMPLATES,fetchSLAKPIVolumeTemplates);
    yield takeEvery(actionTypes.UPDATE_SELECTED_SLAKPI_VOLUME_START,updateSelectedSLAKPIVolume);
    yield takeEvery(actionTypes.UPDATE_CUSTOM_TEMPLATE_START,updateCustomTemplate);
    yield takeEvery(actionTypes.GET_FILE_UPLOAD_DATA,fetchFileUploadData);
    yield takeEvery(actionTypes.UPDATE_FILE_UPLOAD_DATA_START,updateFileUploadData);
    yield takeEvery(actionTypes.GET_NONVOICE_CARDS_DATA,fetchNonvoiceCardsData);
    yield takeEvery(actionTypes.GET_UPLOAD_PRIVIEW_DATA,fetchUploadPreviewData);
    yield takeEvery(actionTypes.GET_VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA,fetchVolumeSlakpiSelectedTemplatesData);
    yield takeEvery(actionTypes.UPDATE_VOLUME_SLAKPI_DATA_START,updateVolumeSLAKPIData);

}
export default function* UploadSagas() {
    yield all([watchGetRequest()]);
}