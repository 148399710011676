import React, { useState } from 'react'
import './ReportFilters.css'
import filterIcon from '../../../assets/images/Filter_Icon.png'
import downloadIcon from '../../../assets/images/Download.png'
import RefreshIcon from '../../../assets/images/Reset_Icon.png'
import Button from './Button'
import Dropdown from './Dropdown'
import reportConstructURLs from '../../../constants/reportURL'
import { useLocation } from 'react-router-dom'
import ConstructURLs from '../../../constants/urls'
function Filter(props) {
  const [openDownload, setOpenDownload] = useState(false);
  const location = useLocation();
  const refreshIconHandler = () => {
    props.refreshHandler();
    if (openDownload) setOpenDownload(!openDownload);
  };
  return (
    <React.Fragment>
      <div className='filter-tab'>
        <span className='filter-icon'>
          <img className='filter-image' src={filterIcon} alt="filter" />
        </span>
        <section className='filter-container'>
          <div className='dropdown-period'>
            <p className='year-month'>Year & Month</p>
            <Dropdown data={props.period_data} id='periodDrpdwn'
              onSelectingDropdown={props.onSelectingDropdown}
              clearFilters={props.clearFilters} latestUpdates={props.latestUpdates} />
          </div>
          {(props.delivery_data || props.region_data) && <div className='dropdown-regions extra-location'>
            <p className='pl-region'>Delivery Region</p>
            <Dropdown data={props.region_data} id='regionDrpdwn'
              onSelectingDropdown={props.onSelectingDropdown}
              clearFilters={props.clearFilters} />
          </div>}
          {props.category_data && <div className='dropdown-regions  extra-location'>
            <p className='pl-region'>Cateogory</p>
            <Dropdown data={props.category_data} id='categoryDrpdwn'
              onSelectingDropdown={props.onSelectingDropdown}
              clearFilters={props.clearFilters} latestUpdates={props.latestUpdates} />
          </div>}
          {props.account_data && <div className='dropdown-regions  extra-location'>
            <p className='pl-region'>Account</p>
            <Dropdown data={props.account_data} id='accountDrpdwn'
              onSelectingDropdown={props.onSelectingDropdown}
              clearFilters={props.clearFilters} />
          </div>}
          {props.location_data ? <div className='dropdown-regions'>
            <p className='pl-region'>Location Group</p>
            <Dropdown data={props.location_data} id='locationDrpdwn'
              onSelectingDropdown={props.onSelectingDropdown}
              clearFilters={props.clearFilters} />
          </div> :
            <div className='extra_gap'></div>}
        </section>
        <div className='container-reset' >
          <Button className={`reset-icon download-btn `} item='Refresh Table'
            onClick={refreshIconHandler} icon={RefreshIcon} />
          {props.downloadButton && <div className='download-icon'>
            <div
              // onClick={() => setOpenDownload(!openDownload)}  
              onClick={() => {
                let downloadReportUrl;
                location.pathname === '/finance' ? downloadReportUrl = reportConstructURLs(props.downloadURL, props.ID?.financeID, props.ID?.filterID)
                  : downloadReportUrl = reportConstructURLs(props.downloadURL, props.ID?.ContactVolumeID, props.ID?.filterID);
                window.location.href = downloadReportUrl;
                setOpenDownload(false)
              }}
              ><img src={downloadIcon} alt='download'/></div>
            {/* {openDownload && (
              <ul className='download-report' >
                {props.downloadArray.map(data => (
                  <li className='li-report' key={data.id} value={data.value}  >
                    <button
                     onClick={()=>{
                       window.localStorage.setItem('downloadID',data.value)
                       let downloadReportUrl = reportConstructURLs(props.downloadURL);
                       window.location.href = downloadReportUrl;
                       setOpenDownload(false)
                      }}
                        >{data.id}</button>
                     </li>)
                )}
              </ul>
            )} */}
          </div>}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Filter












