import React, { useState } from 'react';
import './AgentsTableComponent.css';
import TableData from './FilterTable.json';
import { useHistory } from 'react-router-dom';
import { AssignedAccountsTblModel, AssignedAccountsTblPopUp } from '../AgentsKPIModels/AgentsKPIModels';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { AgentsKPIAccountsData, getAgentsGraphData, saveSelectedAgentScoreCardDate} from '../../../actions/ScoreCard&TeamKPI.action';
import AboveRequiredTarget_Status from "../../../assets/images/AboveRequiredTarget_Status.png"
import BelowMinimalTarget_Status from "../../../assets/images/BelowMinimalTarget_Status.png"
import BelowMinimalTargetIcon from "../../../assets/images/BelowMinimalTarget_Status.png";
import CloseWindow_Normal from '../../../assets/images/CloseWindow_Normal.png';

const AgentsTableComponent = (props) => {
const history = useHistory();
const dispatch = useDispatch();
let agentsKPISummaryData = useSelector((state) => state.ScoreCardReducer.agentsKPISummaryData);
let selectedTab = useSelector((state) => state.ScoreCardReducer.selectedTab);
let selectedDate = useSelector((state) => state.ScoreCardReducer.selectedDate);
let agentsGraphData = useSelector((state) => state.ScoreCardReducer.agentsGraphData);
console.log('agentsKPISummaryData',agentsGraphData,agentsGraphData?.[0]?.YAxis)



const [showAccAsgnMdl,SetShowAccAsgnMdl] = useState(false);
const [showStaffedTimeMdl,SetShowStaffedTimeMdl] = useState(false);

  const TableHeaderHandler = () => {
    switch (props.SelectedTab) {
        case 'Schedule / Attendance': return TableData.ScheduleHeader
        case 'Customer Experience':  return TableData.CustomerHeader
        case 'Quantitative': return TableData.QuantitativeHeader
        case 'Qualitative': return TableData.QualitativeHeader
        case 'Account Specific KPIs': return TableData.AccountHeader
        case 'AWS Telephony': return TableData.AWSHeader
        default: { }
    }
  }

  const ArrowHandler = (color) => {
    switch (color) {
      case 'Red': return BelowMinimalTarget_Status;
      case 'Green': return AboveRequiredTarget_Status;
      case 'Yellow': return BelowMinimalTargetIcon;
      default: return;
    }
  }





const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' 
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      //yAlign: 'bottom',
      displayColors: false,
      backgroundColor: 'white',
      bodyColor: 'black',
      titleMarginBottom: 0,
      borderColor: '#969696',
      borderWidth: 1,
      titleColor: '#63666A',
      titleAlign: 'center',
      titleFont: {weight: '400'},
      displayColors: false,
      padding: 10,
      cornerRadius: 0,
      caretSize:5,
      bodyFont: {weight: 'bolder'},
      callbacks: {
          label: (context) => {  
              let label = context.parsed.y;             
              return label;                   
          }
        }

    } 
  },
  scales:{
    x: {
        stacked: true,
        title:{
          display: true,
        }
    },
    y: {
      beginAtZero: true,
      grid: {
          display: false,
          borderColor: 'white'
      },
      ticks: {
          display: false
     }
  }
},   
         
};

 const setLabels = agentsGraphData?.[0]?.details.map((data)=>{return data.YearMonth})
 const setData = agentsGraphData?.[0]?.details.map((data)=>{return data.Value2})


const data = {
  labels: setLabels,
  datasets: [ 
    {
      type: "line",
      label: "Met %",
      borderColor: "#6CC24A",
      tension: 0.1,         
      fill: false,
      data: setData,
      order: 1,
      yAxisID: 'metprcnt'
    }
  ]
};

const DisplayGraphHandler = (Agent,Metric) =>{
  SetShowStaffedTimeMdl(true);
  dispatch(getAgentsGraphData(Agent,selectedTab,Metric,selectedDate))
}



// // working solution.................................................................................................

//   const durations = ['01:30:00', '02:15:45', '00:45:30', '03:00:00', '01:00:15'];

//   // Function to convert hh:mm:ss to seconds
//   const timeToSeconds = (time) => {
//     const [hours, minutes, seconds] = time.split(':').map(Number);
//     return hours * 3600 + minutes * 60 + seconds;
//   };

//   // Function to convert seconds to hh:mm:ss
//   const secondsToTime = (seconds) => {
//     const hrs = Math.floor(seconds / 3600);
//     const mins = Math.floor((seconds % 3600) / 60);
//     const secs = seconds % 60;
//     return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
//   };

//   // Convert durations to seconds for Chart.js
//   const durationsInSeconds = durations.map(time => timeToSeconds(time));

//   // Prepare data for Chart.js format
//   const data = {
//     labels: ['Data Point 1', 'Data Point 2', 'Data Point 3', 'Data Point 4', 'Data Point 5'],
//     datasets: [
//       {
//         label: 'Time Durations',
//         data: durationsInSeconds,
//         borderColor: 'green',
//         fill: false
//       }
//     ]
//   };

//   // Chart options
//   const options = {
//     plugins: {
//     legend: {
//       position: 'top' 
//     },
//     title: {
//       display: true,
//       text: 'Chart.js Line Chart',
//     },
//     tooltip: {
//       //yAlign: 'bottom',
//       displayColors: false,
//       backgroundColor: 'white',
//       bodyColor: 'black',
//       titleMarginBottom: 0,
//       borderColor: '#969696',
//       borderWidth: 1,
//       titleColor: '#63666A',
//       titleAlign: 'center',
//       titleFont: {weight: '400'},
//       displayColors: false,
//       padding: 10,
//       cornerRadius: 0,
//       caretSize:5,
//       bodyFont: {weight: 'bolder'},
//       callbacks: {
//           label: (context) => {  
//               let label = context.parsed.y;             
//               return secondsToTime(label);                   
//           }
//         }

//     } 
//   },
//     scales: {
//       y: {
//         title: {
//           display: true,
//           text: 'Time (hh:mm:ss)'
//         },
//         ticks: {
//           // Custom callback to format y-axis labels as hh:mm:ss
//           callback: function(value) {
//             return secondsToTime(value);
//           }
//         }
//       }
//     }
//   };



  return (
    <div className='AgentTable' style={{ marginTop: 50,marginBottom:-150 }}>
      <table className='TL_Table'>
        <thead>
          <tr style={{ position: 'sticky', top: -1, backgroundColor: '#F6F8FA' }}>
            <th style={{ border: 'none', width: '30%' }}>{''}</th>
            {TableHeaderHandler().map(header => (
              <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>
            ))
            }
          </tr>
        </thead>
        
        <> {agentsKPISummaryData?.[0]?.Agent && agentsKPISummaryData?.[0]?.Agent.map((info, i) =>
          <tbody><tr key={i}>
        <td><section style={{display:'flex',gap:'20px',minWidth:'400px'}}>
        <img
                    style={{maxHeight:'64px',maxWidth:'64px',marginTop:'10px',marginLeft:'10px'}}
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/users/${info?.Agent}/photo`}
                  alt="Client Logo"
                />
        <main className='Text_style_table'>
        <div className='Title_css' onClick={()=>{history.push('/agent');dispatch(saveSelectedAgentScoreCardDate(info?.Agent))}}>{info?.Agent}</div>
        <div style={{cursor:'pointer'}} onClick={()=>{SetShowAccAsgnMdl(true);dispatch(AgentsKPIAccountsData(info?.Agent))}}>Assigned Accounts: {info?.['Assigned Accounts']}</div>
        <div>Score: {info?.Score}</div>
        <div className='progress_style_table'><img src={ArrowHandler(info?.Color1)} alt=''/> {info?.['for Last Month']} <span style={{color: '#969696'}}> for Last Month </span></div>
        </main>
        </section></td>
        { info?.Details?.map((value, index) => {
               return <td key={index} onClick={()=>DisplayGraphHandler(value['Agent'],value['Metric'])} style={{cursor:'pointer'}}>
                 { (value['Value1']) } {value['Color2'] !== null ? <img src={ArrowHandler(value['Color2'])} alt="arrow" /> : ''}
                 </td>
            })}
        </tr></tbody>
        )}
        </>
      </table>


      {showAccAsgnMdl && <AssignedAccountsTblPopUp isOpen={showAccAsgnMdl} setShowModal={SetShowAccAsgnMdl}>
        <AssignedAccountsTblModel  closeModel={()=>{SetShowAccAsgnMdl(false)}}
        />
      </AssignedAccountsTblPopUp>}

      {showStaffedTimeMdl && <AssignedAccountsTblPopUp isOpen={showStaffedTimeMdl} setShowModal={SetShowStaffedTimeMdl}>
       <div style={{maxWidth:700,paddingBottom:'20px'}}> 
       <div className='mdl_ttl_name'><div>{agentsGraphData?.[0]?.Agent}</div><div onClick={()=>SetShowStaffedTimeMdl(false)} style={{cursor:'pointer'}}><img src={CloseWindow_Normal} alt=''/></div></div>
       <div className='mdl_staffTime'>{agentsGraphData?.[0]?.widget} - {agentsGraphData?.[0]?.metric}</div>
     <main style={{display:'flex'}}><div className='y_axis_title'>{agentsGraphData?.[0]?.YAxis}</div> <Line data={data} options={options} /></main>
     <div className='x_axis_title'>Year/Month</div>
        </div>
      </AssignedAccountsTblPopUp>}
      
    </div>
  )
}

export default AgentsTableComponent