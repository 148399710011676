import React, { useState } from 'react'
import { Accordion } from 'react-bootstrap';
import './DeveloperSupport.css';
import VerticalLine from './../../../../assets/images/VerticalLine_Headings.png';
import DeveloperSupportIcon from './../../../../assets/github-images/DeveloperSupport_general_cloud_support_process_incident.png';
import Developercreatenew from './../../../../assets/github-images/DeveloperSupport_general_cloud_support_process_create_new_inc.png';
import CloudSupport from './../../../../assets/github-images/DeveloperSupport_general_cloud_support_process_ticket.png';
import CopyToClipboard from './../../../../assets/github-images/CopyToClipboard.png';
import { useHistory } from 'react-router-dom';
import { RequestTab, RequestTabPopup } from '../../GitHub_LandingPage/Models';

const APP_SUPPORT_TEAM = [
    { id: '1', caption: "IF the problem is application related a check and fix it." },
    { id: '2', caption: "IF the problem is platform related a check the platform availability for possible issues and a inform the respective Development team & Attila Szabo to check and fix the issue." },
    { id: '3', caption: "When platform problem is fixed check the application and confirm that all is back to normal operation." },
    { id:'4',caption:"IF the platform can’t be fixed by you and Attila is NOT available a follow this guide to open a ticket to the Cloud IT support team."}
]
const REQUIREMENTS =[
    {id:'1',main:"Network Requirements:",description:'All users accessing the application need to be connected to the corporate network from the office or from home via Secure Pulse VPN. Browsers network settings to be set for the right settings (corporate standard).'},
    {id:'2',main:"Browser Requirements:",description:'Although Microsoft Internet Explorer is the preferred web browser for DXC, Google Chrome is also supported by IT and Cyber Security. If your account or organization allows the use of Chrome, you can install it from Google.'},
    {id:'3',main:"Step by step instructions for Support teams, Developers, Technical & Business owners",description:'Please go step by step to identify the root cause and the respective actions:'},
]
const CLOUD_V2 =[
    {id:'1',description:'Always mention the affected **SERVERNAME** - Configuration Item'},
    {id:'2',description:'Always mention the related **EPR** **ID:** **206921** - Correlation ID'},
    {id:'3',description:'Always mention who is the contact person for the issue – **EMAIL** **ADDRESS** + attilas@dxc.com'},
]

const DeveloperSupport = () => {
    const history = useHistory();
    const [showRequestModel, setShowRequestModel] = useState(false);
  return (
    <>
            <main className='accordion_handle' >
            <div style={{width:1200,margin:'auto'}}>
            <div className='Infrastructure'>Developer Support</div>
               <div style={{width:'98%'}}>
                <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                    <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                        <Accordion.Header style={{ width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                            boxShadow: '0px 3px 3px #9D9D9D80',opacity: '1'}}> 
                            <span className='accordion-header'>Cloud Servers Support Process</span></Accordion.Header>
                        <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                            <>
                            <section className='cloud_server'>
                            <div className='vertical_bar'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; How to open a ticket to DXC SSZ - Cloud support?</div>
                            <div className='target_audience'>Target Audience</div>
                            <div className='ul_ticket_to_ssz'> 
                            <ul><li className='list_disc_style'>Application Owners</li><li className='list_disc_style'>Application Developers</li>
                                <li className='list_disc_style'>Business Owners</li></ul></div>
                            <main className='header_text_prerequisite'>Prerequisite checks before opening the ticket</main>
                            <div>The following checks to be executed before a ticket is opened:<br/> <b>Note:</b> Applications are running on servers located in the DXC SSZ Cloud infrastructure – located physically in USA (Houston). The users are authenticated based UID policy and requirements.</div>
                            {REQUIREMENTS.map((data, index) => (<><main className='header_text_prerequisite'>{data.main}</main>
                            <div>{data.description}</div></>))}
                            <main className='app_support_team'>Application Support team:</main>
                            <div className='list_style_ol'><ol> {APP_SUPPORT_TEAM.map((data, index) => (<li id='list'>{data.caption}</li>))}</ol></div>
                            <main className='app_support_team'>Generic Situation:</main>
                            <div>IF the application development team members are not available à application owners should open the ticket based on the description to have the platform checked by IT. </div>
                            <main className='app_support_team'>Communication:</main>
                            <div>All communications to end users to be handled by the Application owners.</div>
                            </section>
                            <section className='cloud_server'>
                            <main className='Standard_bar'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp; How to open ticket to IT support?</main>
                           <div className='ol_li_list'> <ol><li>Open the DXC ServiceNow portal - <span id='link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href='https://csc.service-now.com/sp' title=''>https://csc.service-now.com/</a></span></li><li>​Search for the word incident</li></ol></div>
                            <img src={DeveloperSupportIcon} alt=''/>
                            <div className='create_new'>1. Select <b>Create New</b> or the <b>New</b> push button on the top</div>
                            <img src={Developercreatenew} alt=''/>
                            <div className='create_new'>1. A new incident form appears. Fill out the form as required</div>
                           <img src={CloudSupport} alt=''/>
                           <div style={{marginTop:'20px'}}>Business Service is always to be <b>Cloud V2 Services | Cloud V2 </b><br/>
                           Please add these details to the description of your problem <b>AND</b></div>
                           <main className='copy_list' style={{display:'flex'}}>
                           <div className='list_style_ol'><ol> {CLOUD_V2.map((data, index) => (<li id='list'>{data.description}</li>))}</ol></div>
                            <div><img src={CopyToClipboard} alt='' width='20px'/></div>
                           </main>
                           <ol><li><b>Submit</b> the ticket</li>
                           <li>If you need to elevate the ticket (or if a major outage – create an MI) you can call the <b>OMC</b> along with the ticket number with business impact justification and they can elevate the ticket (an eNote alert will be sent to the proper support team). </li>
                           </ol>
                           <div style={{marginTop:'-20px',marginLeft:15}}>Their number is <b>+1.800.396.3798</b> <span className='link_itio'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href="mailto:itio-sso-omc-all@dxc.com" title='' >(itio-sso-omc-all@dxc.com) </a> </span></div>
                           <div style={{marginTop:'20px',marginLeft:15}}>!!! Elevate only in case there is a major impact on the end users !!!</div>
                            </section>
                            </>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                        <Accordion.Header style={{ width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                            boxShadow: '0px 3px 3px #9D9D9D80',opacity: '1'}}> 
                            <span className='accordion-header'>GSD Insight Application Support</span></Accordion.Header>
                        <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                            <>
                              <span className='relevent_content'>Please see the relevant content under </span>
                              <span className='general_content' onClick={()=>history.push('/user')}>User</span> section or you can raise&nbsp; 
                              <span className='general_content' onClick={()=>setShowRequestModel(true)}>Request/Incident</span>. 
                            </>
                        </Accordion.Body>
                    </Accordion.Item>

                </Accordion>
                </div></div>
            </main>

            {showRequestModel && <RequestTabPopup isOpen={showRequestModel} setShowModal={setShowRequestModel}>
        <RequestTab modalName='request' closeWindow={()=>setShowRequestModel(false)}
        />
      </RequestTabPopup>}

        </>

        
  )
}


export default DeveloperSupport