
import { STAFF_ADMIN_ACTIONTYPES as staffActionTypes } from "./../../constants/Administration/staffAdmin-actionTypes";


const initialState = {
    availableEmployees: [],
    supervisorList:[],
    newUserRole:[],
    supervisorEmployeeMapping:[],
    accountEmployee:[],
    roleCategory:{},
    staffAccounts:[],
    supervisorInfo:{},
    accountAssignmentStaff:[],
    queueLists:[],
    idAssignment:[],
    slaAssignment:[],
    volumeAssignment:[],
    updateQueueAssignment:[],
    updateIdAssignment:[],
    updateSlaAssignment:[],
    updateVolumeAssignment:[],
    staffPreview:[],
    mappedEmployees:[],
    queueData:[],
    accountSLA:[],
    volumeTemplates:[],
    idAccounts:[],
    slaAccounts:[],
    validSupervisor:[],
    accountSLAForEdit:[],
    volumeTemplatesForEdit:[],

    updatedAccountResponse :[],
    updatedQueueResponse :[],
    updatedIDResponse :[],
    updatedSLAResponse :[],
    updatedVolumeResponse :[],

}


const staffAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case staffActionTypes.GET_AVAILABLE_EMPLOYEES:
            return { ...state };
            case staffActionTypes.GET_SUPERVISOR_LIST:
            return { ...state };
            case staffActionTypes.GET_ROLE_CATEGORY:
            return { ...state };
            case staffActionTypes.GET_STAFF_ACCOUNTS:
            return { ...state };
            case staffActionTypes.GET_ACCOUNT_ASSIGNMENT:
            return { ...state };
            case staffActionTypes.GET_QUEUE_ASSIGNMENT:
            return { ...state };
            case staffActionTypes.GET_ID_ASSIGNMENT:
            return { ...state };
            case staffActionTypes.GET_SLA_ASSIGNMENT:
            return { ...state };
            case staffActionTypes.GET_VOLUME_ASSIGNMENT:
            return { ...state };
            case staffActionTypes.GET_STAFF_PREVIEW:
            return { ...state };
            case staffActionTypes.GET_MAPPED_EMPLOYEES:
            return { ...state };
            case staffActionTypes.GET_QUEUE_DATA:
            return { ...state };
            case staffActionTypes.GET_ACCOUNT_SLA:
            return { ...state };
            case staffActionTypes.GET_VOLUME_TEMPLATES:
            return { ...state };
            case staffActionTypes.GET_ID_ACCOUNTS:
                return { ...state };
            case staffActionTypes.GET_SLA_ACCOUNTS:
                return { ...state };
            case staffActionTypes.GET_VALID_SUPERVISOR:
                return { ...state };
            case staffActionTypes.GET_ACCOUNT_SLA_FOREDIT:
                return { ...state };
            case staffActionTypes.GET_VOLUME_TEMPLATES_FOREDIT:
                    return { ...state };
        case staffActionTypes.AVAILABLE_EMPLOYEES: {
            return { ...state, availableEmployees: action.payload }
        }
        case staffActionTypes.SUPERVISOR_LIST: {
            return { ...state, supervisorList: action.payload }
        }
        case staffActionTypes.UPDATE_NEW_USER_ROLE_START: {
            return { ...state, newUserRole: action.payload }
        }
        case staffActionTypes.UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_START: {
            return { ...state, supervisorEmployeeMapping: action.payload }
        }
        case staffActionTypes.ROLE_CATEGORY: {
            return { ...state, roleCategory: action.payload }
        }
        case staffActionTypes.STAFF_ACCOUNTS: {
            return { ...state, staffAccounts: action.payload }
        }
        case staffActionTypes.SAVE_SUPERVISOR_MAPPING: {
            return { ...state, supervisorInfo: action.payload }
        }
        case staffActionTypes.ACCOUNT_ASSIGNMENT: {
            return { ...state, accountAssignmentStaff: action.payload }
        }
        case staffActionTypes.QUEUE_ASSIGNMENT: {
            return { ...state, queueLists: action.payload }
        }
        case staffActionTypes.ID_ASSIGNMENT: {
            return { ...state, idAssignment: action.payload }
        }
        case staffActionTypes.SLA_ASSIGNMENT: {
            return { ...state, slaAssignment: action.payload }
        }
        case staffActionTypes.VOLUME_ASSIGNMENT: {
            return { ...state, volumeAssignment: action.payload }
        }
        case staffActionTypes.STAFF_PREVIEW: {
            return { ...state, staffPreview: action.payload }
        }
        case staffActionTypes.MAPPED_EMPLOYEES: {
            return { ...state, mappedEmployees: action.payload }
        }
        case staffActionTypes.QUEUE_DATA: {
            return { ...state, queueData: action.payload }
        }
        case staffActionTypes.ACCOUNT_SLA: {
            return { ...state, accountSLA: action.payload }
        }
        case staffActionTypes.VOLUME_TEMPLATES: {
            return { ...state, volumeTemplates: action.payload }
        }
        case staffActionTypes.ID_ACCOUNTS: {
            return { ...state, idAccounts: action.payload }
        }
        case staffActionTypes.SLA_ACCOUNTS: {
            return { ...state, slaAccounts: action.payload }
        }
        case staffActionTypes.ACCOUNT_SLA_FOREDIT: {
            return { ...state, accountSLAForEdit: action.payload }
        }
        case staffActionTypes.VOLUME_TEMPLATES_FOREDIT: {
            return { ...state, volumeTemplatesForEdit: action.payload }
        }
        case staffActionTypes.UPDATE_ACCOUNT_EMPLOYEE_START: {
            return { ...state, updatedAccountResponse: action.payload }
        }
        case staffActionTypes.UPDATE_QUEUE_ASSIGNMENT_START: {
            return { ...state, updatedQueueResponse: action.payload }
        }
        case staffActionTypes.UPDATE_ID_ASSIGNMENT_START: {
            return { ...state, updatedIDResponse: action.payload }
        }
        case staffActionTypes.UPDATE_SLA_ASSIGNMENT_START: {
            return { ...state, updatedSLAResponse: action.payload }
        }
        case staffActionTypes.UPDATE_VOLUME_ASSIGNMENT_START: {
            return { ...state, updatedVolumeResponse: action.payload }
        }
        case staffActionTypes.VALID_SUPERVISOR: {
            return { ...state, validSupervisor: action.payload }
        }
        default:
            return state;
    }


}


export default staffAdminReducer