import React from 'react';
import { Accordion } from 'react-bootstrap';
import './../../Onboard/OnboardComponents/Accordion/Accordion.css';

function UploadAccordion({ ACCORDION_INFO, openAccordion, onAccordionOpen }) {
  return (
    <Accordion activeKey={openAccordion} flush> {/* Use activeKey for controlled component */}
      {
        ACCORDION_INFO.map((accordion, i) => (
          <Accordion.Item
            eventKey={i.toString()}
            style={{ marginTop: '0.3em' }}
            key={i}
          >
            <Accordion.Header
              style={{
                width: 'inherit',
                height: '58px',
                background: '#FFFFFF 0% 0% no-repeat paddingBox',
                boxShadow: '0px 3px 3px #9D9D9D80',
                opacity: '1'
              }}
              onClick={() => onAccordionOpen(i.toString())} // Call the handler on header click
            >
              <span className='accordion-header'>{accordion.label}</span>
            </Accordion.Header>
            <Accordion.Body
              style={{
                marginTop: '0',
                border: 'transparent',
                backgroundColor: 'white'
              }}
            >
              {accordion.Info}
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  );
}

export default UploadAccordion;
