import React from 'react'
import './ProductMaintenance.css';
import VerticalLine from './../../../../assets/images/VerticalLine_Headings.png';


const ProductMaintenance = () => {

  const DATA_RETENTION='https://dxcportal.sharepoint.com/sites/GSDInsight/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation%2FGSD%5FInsight%5FData%5FRetention%5FPolicy%5FProcess%5Fv20220314%2Epdf&parent=%2Fsites%2FGSDInsight%2FShared%20Documents%2FGeneral%2FDocumentation';
  const SIRCC = 'https://my.dxc.com/departments/got/global-cyber-security/incident-reporting';
  return (
    <> 
    <section className='prod_main_div'>
    <div style={{width:1200,margin:'auto'}}>
          <main className='main_title'>Product Maintenance</main>
          
          <main>
            <div className='policy'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp; Data Retention Policy and Process</div>
            <span className='policyheader'> Data Retention Policy and Process document is described and located </span>
           <span className='policylink'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={DATA_RETENTION} title=''>here</a> 
            </span>.
          </main>
          <main>
            <div className='policy'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp; Security Patching</div>
            <div className='patch_text'> For each server, the Windows OS upgrades are installed automatically by DXC IT with BigFix. The maintenance window is sent out via email for each server in scope by 
           <span id='link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href="mailto:DXCIT_Server_Patching@dxc.com" title='' >
                                <span>  DXCIT Server Patching Team  </span>
                            </a></span>
            in the form of a change request. The patching always takes place during out of office business hours, usually on the first Sunday of the month. After the patching process is completed, the servers are rebooted.</div>
          </main>
          <main>
            <div className='policy'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp; Security Properties</div>
            <div className='patch_text'> Specific security properties related to ITAR countries/accounts will be defined later</div>
          </main>
          <main>
            <div className='policy'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp; Security Vulnerability Incidents</div>
            <div className='patch_text'> Please refer to DXC standard process ( <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }}
                                href={SIRCC} title=''><span className='policylink'>SIRCC</span></a> ).</div>
          </main></div>
</section>

        </>
  )
}

export default ProductMaintenance