import { slaFailure_actionTypes as actionTypes } from './../../constants/Administration/SLAFailure-actionTypes';

export const getExemptionDataStart = (custId, servId, month) => ({
  type: actionTypes.GET_EXEMPTION_DATA_START,
  custId, servId, month
});


export const updateExistingCommentStart = (custId, servId, month, sla, id) => ({
  type: actionTypes.UPDATE_EXISTING_COMMENT_START,
  custId, servId, month, sla, id
});

export const updateExistingCommentSuccess = () => ({
  type: actionTypes.UPDATE_EXISTING_COMMENT_SUCCESS
});

export const getExclusionDataStart = (custId, servId, month) => ({
  type: actionTypes.GET_EXCLUSION_DATA_START,
  custId, servId, month
});


export const updateExclusionCommentStart = (custId, servId, month, sla, id) => ({
  type: actionTypes.UPDATE_EXCLUSION_COMMENT_START,
  custId, servId, month, sla, id
});

export const updateExclusionCommentSuccess = () => ({
  type: actionTypes.UPDATE_EXCLUSION_COMMENT_SUCCESS
});




