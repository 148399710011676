import ReactModal from "react-modal";
import Incidents_Image from './../../../assets/github-images/Incidents_Image.png';
import WindowClose_Icon from './../../../assets/github-images/WindowClose_Icon.png';
import Email_HeroImage from './../../../assets/github-images/Email_HeroImage.png';

import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

export function RequestTab(props) {
    const history =useHistory();
    return <>
        <section className="requesttab_container">
            <div >
              <main style={{display:'flex',gap:'20px'}}>
               <div><img src={Incidents_Image} alt='' width='370px' height='450px'/></div>
               <div className="incident_text">
               <div  onClick={props.closeWindow} style={{marginLeft:'300px',cursor:'pointer'}}><img src={WindowClose_Icon} alt='' /></div>
               <div className="incident_header">Incidents / Requests </div> Create an incident record to 
              document a deviation from an expected standard of operation or alternatively submit a request to flag 
              other types of problems or proposals related to GSD Insight Platform. Click on the create button 
              to open Incident / Request section.This formal process enables dialogue between the submitter and 
              the assigned engineer, provides an audit trail, and a mechanism for prioritization and tracking.
              <Link to ='/request' target='_blank'><button className='btn_createuser' > CREATE NOW  </button></Link>
              </div>
              
                       
              </main>
            </div>
        </section>
       
    </>
}

export function EmailTab(props) {
    const history =useHistory();
    return <>
        <section className="requesttab_container">
            <div >
              <main style={{display:'flex',gap:'20px'}}>
               <div><img src={Email_HeroImage} alt='' width='370px' height='450px'/></div>
               <div className="incident_text">
               <div  onClick={props.closeWindow} style={{marginLeft:'300px',cursor:'pointer'}}><img src={WindowClose_Icon} alt='' /></div>
               <div className="incident_header">Email </div><div> For Incidents / Requests please click on the following link.</div>
               <Link to ='/request' target='_blank'><button className='btn_addAccessGroup' > CREATE NOW  </button></Link>
               <div style={{marginTop:'20px'}}>For any other Queries / Suggestions feel free to contact us via email.</div>
              <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href='mailto:GSD_Insight_Support@dxc.com'
                      title="" >  <button className='btn_createuser' >EMAIL US </button></a>
              </div>
              
                       
              </main>
            </div>
        </section>
       
    </>
}


export function RequestTabPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '25%',
                    left: '25%',
                    width: '760px',
                    height: '450px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}

export function VideoModelPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                   
                    background: ' #63666A66 0% 0% no-repeat padding-box',
                    opacity: '1',
                    
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-30%',
                    transform: 'translate(-50%, -50%)',
                }
            }}>
            {children}
        </ReactModal>

    </>)
}