import React, { useState, useRef, useMemo } from "react";
import "./styles.css";
import settingsImage from "../../assets/images/Settings_Normal.png";
import logoutImage from "../../assets/images/Logout_Normal.png";
import gsdLogoImg from "../../assets/images/GSDInsight_Logo.png";
import dataSaveClose_icon from "./../../assets/onboard-images/dataSaveClose_icon.png";
import ConstructURLs from "../../constants/urls";
import HamburgerMenuComponent from "../hamburger-menu/hamburger-menu";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserData, getUserToken } from "../../actions/user.action";
import { useHistory, withRouter } from "react-router-dom";
import Settinglinks from "./settinglinks";
import { toggleSettingsModal } from "../../actions/settings.action";
import { useLocation } from 'react-router';

const Header = (props) => {
  const dispatch = useDispatch();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let showSettingsModal = useSelector((state) => state.settingsReducer.showSettingsModal);
  
  const errorVisibility = useSelector(state => state.githubReducer.errorVisibility)

  console.log('developerCard',errorVisibility)
  let url = window.location.href;
  const history = useHistory();
  const location =useLocation();
  useEffect(() => {
    console.log("Window URL  " + url);
    let isLocalhost = url.includes("localhost");
    console.log("isLocalhost  " + isLocalhost);

    if (isLocalhost) {
      dispatch(getUserToken());
    }
   if(location.pathname !== '/githublanding' && location.pathname !== '/whygsd' &&
  
 
   location.pathname !== '/getstarted' &&
   location.pathname !== '/architecture' &&
   location.pathname !== '/architecture/card' &&
   location.pathname !== '/developer/card' &&
   location.pathname !== '/product' &&
   location.pathname !== '/developer' &&
   location.pathname !== '/user' &&
   location.pathname !== '/security' &&
   location.pathname !== '/overview' &&
   location.pathname !== '/training' &&
   location.pathname !== '/design' &&
   location.pathname !== '/design/card' &&
   location.pathname !== '/database' &&
   location.pathname !== '/features' &&
   location.pathname !== '/faq' &&
   location.pathname !== '/notinproduction'
   )
   {
    dispatch(getUserData());
   }
   
  }, []);


  const logout = () => {
    window.localStorage.clear();
    window.location.href = ConstructURLs("LOG_OUT_URL");
  };


  const [showModal, setShowModal] = useState(false);

  // const closeSettings=() => setOpenSettings(false);
  const ref = useRef();

  useEffect(() => {

    /**
     * Alert if clicked on outside of element
     */

    const handleClickOutside = (event) => {
      // console.log(ref?.current?.className, 'testtttttttttttt', event.target, event.currentTarget);


      if ((ref.current && event.target.className !== "settings-icon")) {
        // if (showSettingsModal) {
        dispatch(toggleSettingsModal(false))
        // }
      }

    }

    document.addEventListener("click", handleClickOutside, false);

    return () => {

      document.removeEventListener("click", handleClickOutside, false);

    };

  }, [ref, dispatch, showSettingsModal]); //  (openSettings===true)

  useMemo(() => {
    const navigationHandler = () => {

      if (showSettingsModal) {
        dispatch(toggleSettingsModal(false))
        setShowModal(false);
      }
    }


    window.addEventListener("popstate", navigationHandler)
    return () => {

      // Unbind the event listener on clean up

      window.removeEventListener("popstate", navigationHandler);

    }
  }, [showSettingsModal, dispatch]);

  var d = new Date().toString();
  var index = d.lastIndexOf(':') +13
  console.log(d.substring(25, index))

  
  console.log('location::::::', location.state)
  return (
    <>
      {props.show === true ? <div className="fixedElement">
      {/* {Error ? <div className="ErrorComponent">{Error}</div> : ''} */}
        <header>
          {props.isMenu ? (
            <div className="header-bl">
              <div className="row hd">
                <div className="col-md-11 header-text">
                  <div className="user-name">
                    Hi, <b>{userInfo.name}</b> 
                    &nbsp; &nbsp;&nbsp; &nbsp; Time zone: <b>{userInfo.timezone} ({d.substring(25, index)})</b>
                  </div>
                </div>
                <div className="col-md-1 header-text">
                  <div className="row">
                    {/* <div className="col-md-8 user">
                  </div> */}
                    <div className="col-md-6">
                      <div className="settings-icon" ref={ref}>
                        <img onClick={(event) => {
                          //  setOpenSettings(!openSettings);
                          dispatch(toggleSettingsModal(!showSettingsModal))
                          //event.preventDefault()
                        }} src={settingsImage} alt="set"
                          className="settings-icon" />
                        {showSettingsModal && (
                          <Settinglinks
                            closeSettings={() => dispatch(toggleSettingsModal(false))}
                            openModal={() => {
                              setShowModal(true);
                              dispatch(toggleSettingsModal(false));
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="logout-icon">
                        <img onClick={logout} src={logoutImage} alt="log" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="header-wh">
            <div className="row hd">
              {props.isMenu ? (
                <div className="col-md-1 menu-icon">
                  <HamburgerMenuComponent></HamburgerMenuComponent>
                </div>
              ) : null}
              <div className="col-md-1 gsd-logo">
                <div className="txt-rgt">
                  <img onClick={() => history.push('/welcome')} src={gsdLogoImg} alt="GSD logo" />
                </div>
              </div>
              <div className="col-md-3 gsd-txt">
                <div className="gsd-head-txt" onClick={() => history.push('/welcome')}>{"GSD Insight"}</div>
              </div>
              <div className="col-md-5"></div>
              {props.isInfo ? (
                <div className="col-md-2 search-box">

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"

                  />
                </div>

              ) : null}
            </div>
          </div>
        </header>
      </div> :
        <div>
          {props.logo === true ? <> <div className="starter_line">
          </div>
            <div className="header_section">
              <div className="GSD_LOGO">
                <img src={gsdLogoImg} alt='GSD_LOGO' width='60px' height='60px' />
                <span> GSD Insight </span>
              </div>
              <div className="welcome_user">
                Welcome,{userInfo.name}
              </div>
            </div> </> :
            <> {
              props.introPage === false && <div className="starter_line">
              </div>}</>
          }
        </div>
      }
    </>

  );
};

export default withRouter(Header);
