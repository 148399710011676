import actionTypes from "../constants/actionTypes";

const initialState = { month: [], summary: {}, week: null, day: null, annual: null, selectedTabName: '',
 selectedKeyName: 'Overall', selectedAccountName: null, homeFeedData: [],
selectedMenuName: '', selectedAccLocationName: '', DTMReportPageDate: '' };

const fetchMonthTabData = (state, action) => {
  console.log("check the data in reducer fetchMonthTabData", action);

  return {
    ...state,
  };
};

const updateMonthTabData = (state, action) => {
  console.log("check the data in reducer updateMonthTabData", action);
  return { ...state, month: action.payload };
};

const fetchSummaryTabData = (state, action) => {
  console.log("check the data in reducer fetchSummaryTabData", action);

  return {
    ...state,
  };
};

const updateSummaryTabData = (state, action) => {
  console.log("check the data in reducer updateSummaryTabData", action);
  return { ...state, summary: action.payload };
};

const fetchWeekTabData = (state, action) => {
  console.log("check the data in reducer fetchWeekTabData", action);

  return {
    ...state,
  };
};

const updateWeekTabData = (state, action) => {
  console.log("check the data in reducer updateWeekTabData", action);
  return { ...state, week: action.payload };
};

const fetchDayTabData = (state, action) => {
  console.log("check the data in reducer fetchDayTabData", action);

  return {
    ...state,
  };
};

const updateDayTabData = (state, action) => {
  console.log("check the data in reducer updateDayTabData", action);
  return { ...state, day: action.payload };
};

const fetchAnnualTabData = (state, action) => {
  console.log("check the data in reducer fetchAnnualTabData", action);

  return {
    ...state,
  };
};

const updateAnnualTabData = (state, action) => {
  console.log("check the data in reducer updateAnnualTabData", action);
  return { ...state, annual: action.payload };
};

const updateSelectedTabName = (state, action) => {
  console.log("check the data in reducer updateSelectedTabName", action);
  return { ...state, selectedTabName: action.payload };
};

const updateSelectedKeyName = (state, action) => {
  console.log("check the data in reducer updateSelectedKeyName", action);
  return { ...state, selectedKeyName: action.payload };
};

const updateSelectedAccountName = (state, action) => {
  console.log("check the data in reducer updateSelectedAccountName", action);
  return { ...state, selectedAccountName: action.payload };
};

const fetchHomeFeedData = (state, action) => {
  console.log("check the data in reducer fetchAnnualTabData", action);
  return {
    ...state,
  };
};

const updateHomeFeedData = (state, action) => {
  console.log("check the data in reducer updateHomeFeedData", action);
  return { ...state, homeFeedData: action.payload };
};

const saveSelectedMenuItem = (state, action) => {
  return {
    ...state, selectedMenuName: action.payload
  };
}

const saveAccountLocationName = (state, action) => {
  return {
    ...state, selectedAccLocationName: action.payload
  }
}

const updateDTMReportDate = (state, action) => {
  return {
    ...state, DTMReportPageDate: action.payload
  }
}

const tabReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_MONTH_TAB_DATA:
      return fetchMonthTabData(state, action);
    case actionTypes.MONTH_TAB_DATA:
      return updateMonthTabData(state, action);
    case actionTypes.GET_SUMMARY_TAB_DATA:
      return fetchSummaryTabData(state, action);
    case actionTypes.SUMMARY_TAB_DATA:
      return updateSummaryTabData(state, action);
    case actionTypes.GET_WEEK_TAB_DATA:
      return fetchWeekTabData(state, action);
    case actionTypes.WEEK_TAB_DATA:
      return updateWeekTabData(state, action);
    case actionTypes.GET_DAY_TAB_DATA:
      return fetchDayTabData(state, action);
    case actionTypes.DAY_TAB_DATA:
      return updateDayTabData(state, action);
    case actionTypes.GET_ANNUAL_TAB_DATA:
      return fetchAnnualTabData(state, action);
    case actionTypes.ANNUAL_TAB_DATA:
      return updateAnnualTabData(state, action);
    case actionTypes.UPDATE_SELECTED_TAB_NAME:
    return updateSelectedTabName(state, action);
    case actionTypes.UPDATE_SELECTED_KEY_NAME:
    return updateSelectedKeyName(state, action);
    case actionTypes.GET_HOME_FEED_DATA:
      return fetchHomeFeedData(state, action)
    case actionTypes.HOME_FEED_DATA:
      return updateHomeFeedData(state, action);
    case actionTypes.UPDATE_SELECTED_ACCOUNT_NAME:
      return updateSelectedAccountName(state, action);
    case actionTypes.SAVE_SELECTED_MENU_ITEM :
      return saveSelectedMenuItem(state, action);
    case actionTypes.SAVE_ACCOUNT_LOCATION_NAME:
      return saveAccountLocationName(state, action);
    case actionTypes.UPDATE_DTM_REPORT_DATE:
      return updateDTMReportDate(state, action);
    default:
      return state;
  }
};

export default tabReducer;
