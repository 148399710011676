import actionTypes from "../constants/actionTypes";

export const getMonthData = () => ({
  type: actionTypes.GET_MONTH_TAB_DATA,
});

export const getSummaryData = () => ({
  type: actionTypes.GET_SUMMARY_TAB_DATA,
});

export const getWeeklyData = () => ({
  type: actionTypes.GET_WEEK_TAB_DATA,
});

export const getDailyData = () => ({
  type: actionTypes.GET_DAY_TAB_DATA,
});

export const getAnnualData = () => ({
  type: actionTypes.GET_ANNUAL_TAB_DATA,
});

export const GET_WEEK_TAB_DATA = (tabName) => ({
  type: actionTypes.UPDATE_SELECTED_TAB_NAME,
  payload: tabName
});

export const updateSelectedKeyName = (keyName) => ({
  type: actionTypes.UPDATE_SELECTED_KEY_NAME,
  payload: keyName
});

export const updateSelectedAccountName = (AccName) => ({
  type: actionTypes.UPDATE_SELECTED_ACCOUNT_NAME,
  payload: AccName
});

export const getHomeFeedData = () => ({
  type: actionTypes.GET_HOME_FEED_DATA
});

export const saveSelectedMenuItem = (menuName) => ({
  type: actionTypes.SAVE_SELECTED_MENU_ITEM,
  payload: menuName
});

export const saveAccountLocationName = (accLocationName) => ({
  type: actionTypes.SAVE_ACCOUNT_LOCATION_NAME,
  payload: accLocationName
});

export const updateDTMReportDate = (dt) => ({
  type: actionTypes.UPDATE_DTM_REPORT_DATE,
  payload: dt
});