import React, { useEffect, useState } from 'react'
import { StackedBarCharts, StackedLineBarCharts, VerticalBarChart } from './Bar-charts';
import './Analytics.css';
import moment from 'moment';
import { CalendarModelpopup, CalendarvaluesModel, PastMonthTrendsModel } from './DTM-ModalPopup';
import AboveRequiredTarget_Status from "../../assets/images/AboveRequiredTarget_Status.png"
import BelowMinimalTarget_Status from "../../assets/images/BelowMinimalTarget_Status.png"
import BelowMinimalTargetIcon from "../../assets/images/BelowMinimalTarget_Status.png";
import Download_Icon from "../../assets/images/Download_Normal.png"
import { useDispatch, useSelector } from 'react-redux';
import { getDTMAnalyticsData, getDTMAnalyticsHalfHour, getDTMAnalyticsMonth, updateRegionalSiteCustomerID, updateRegionalSiteCustomerName } from '../../actions/regionalSite.action';
import { useHistory, useLocation } from 'react-router-dom';
import AdminBreadcrumb from '../Administration/General/Admin-breadcrumb';
import * as htmlToImage from 'html-to-image';
import { saveAccountLocationName, saveSelectedMenuItem, updateDTMReportDate, updateSelectedAccountName } from '../../actions/tabMenu.action';

function daysInMonth(d) {
  let date = new Date(d)
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}


function DTMAnalyticsSLA() {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  // const customerID = useSelector((state) => state.regionalSiteReducer.rgnCustId);
  // const serviceID = useSelector((state) => state.regionalSiteReducer.rgnServiceID);
  const reportcustomerID = useSelector((state) => state.regionalSiteReducer.rgnCustId);
const reportserviceID = useSelector((state) => state.regionalSiteReducer.rgnServiceID);
const selectedSLA = useSelector((state) => state.regionalSiteReducer.selectedSLA);
const reportcustomerName = useSelector((state) => state.regionalSiteReducer.rgnCustName);
let selectedCustIDServID = useSelector((state) => state.welcomePageReducer.selectedCustIDServID);
  console.log('selectedCustIDServID',selectedCustIDServID)
  const customerID = reportcustomerID === null ? selectedCustIDServID.CustomerID  : reportcustomerID;
  const serviceID = reportserviceID === '' ? selectedCustIDServID.ServiceDeskID : reportserviceID;
  const customerName = reportcustomerName === null ? selectedCustIDServID.CustomerName : reportcustomerName

  const selectedDate = useSelector((state) => state.tabReducer.DTMReportPageDate);
  const DTMAnalytics = useSelector((state) => state.regionalSiteReducer.DTMAnalytics);
  const DTMHalfHour = useSelector((state) => state.regionalSiteReducer.DTMHalfHour);
  const DTMMonthlyData = useSelector((state) => state.regionalSiteReducer.DTMMonthlyData);
  function formatDatehandle(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const formatTime = (value) => {
    let totalSeconds = value;
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600; let minutes = Math.floor(totalSeconds / 60);
    let seconds = parseInt(totalSeconds % 60);
    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    if (seconds.length < 2)
      seconds = '0' + seconds;
    return (hours + ":" + minutes + ":" + seconds);


  }

  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    return [year, month, '01'].join('-');
  }

  const [currentDate, setCurrentDate] = useState(moment())
  useEffect(() => {
    let newDate = new Date(selectedDate)
    let year = !isNaN(newDate?.getFullYear()) ? newDate?.getFullYear() : new Date().getFullYear();
    let month = !isNaN(newDate?.getMonth()) ? newDate?.getMonth() : new Date().getMonth();
    setCurrentDate(moment().set({ 'year': year, 'month': month }))
    const myDate = moment().set({ 'year': year, 'month': month })
    let obj1 = {
      customerID: customerID,
      serviceID: serviceID,
      view: '',
      date: formatDate(myDate),
      item: selectedSLA.item
    }
    let obj3 = {
      customerID: customerID,
      serviceID: serviceID,
      view: '12+month',
      date: formatDate(myDate),
      item: selectedSLA.item
    }
    dispatch(getDTMAnalyticsData(obj1))
    dispatch(getDTMAnalyticsMonth(obj3))
  }, [])

  const [DTMAnalyticsData, setDTMAnalyticsData] = useState([]);
  const [DTMHalfHourData, setDTMHalfHourData] = useState([]);
  const [DTMMonthlyInfo, setDTMMonthlyInfo] = useState([]);

  useEffect(() => {
    setDTMAnalyticsData(DTMAnalytics);
    setDTMHalfHourData(DTMHalfHour);
    setDTMMonthlyInfo(DTMMonthlyData)
  }, [DTMAnalytics, DTMHalfHour, DTMMonthlyData])


  const [showCalendarvalueModel, setShowCalendarvalueModel] = useState(false);
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const [weekIndex, setWeekIndex] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showPastMonthTrends, setShowPastMonthTrends] = useState(false);
  const [showPerformanceVolume, setShowPerformanceVolume] = useState(false);




  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    scales: {
      xAxes: {
        display: true,
        stacked: true
      },
      yAxes: {
        display: true,
        stacked: true,
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },

    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        displayColors: false,
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: context => {
            return context[0].label.includes(':') ? context[0].label : `${currentDate.format('MM')}/${context[0].label}/${currentDate.format('YYYY')}`
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString()
          }
        }
      }
    },
  }

  const DTMoptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    scales: {
      xAxes: {
        display: true,
        stacked: true
      },
      yAxes: {
        display: true,
        stacked: true,
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },

    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        displayColors: false,
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: context => {
            return context[0].label.includes(':') ? context[0].label : `${currentDate.format('MM')}/${context[0].label}/${currentDate.format('YYYY')}`
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString() + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`
          }
        }
      }
    },
  }

  const monthlyoptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    scales: {
      xAxes: {
        display: true,
        stacked: true
      },
      yAxes: {
        display: true,
        stacked: true,
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },
    onClick: (context) => {
      setShowPerformanceVolume(false);
      setShowPastMonthTrends(false)
      let newDate = new Date(context?.chart?.tooltip?.title?.[0]);
      let year = !isNaN(newDate?.getFullYear()) ? newDate?.getFullYear() : new Date().getFullYear();
      let month = !isNaN(newDate?.getMonth()) ? newDate?.getMonth() : new Date().getMonth();
      setCurrentDate(moment().set({ 'year': year, 'month': month }))
      const myDate = moment().set({ 'year': year, 'month': month })
      let obj1 = {
        customerID: customerID,
        serviceID: serviceID,
        view: '',
        date: formatDate(myDate),
        item: selectedSLA.item
      }
    
      let obj3 = {
        customerID: customerID,
        serviceID: serviceID,
        view: '12+month',
        date: formatDate(myDate),
        item: selectedSLA.item
      }
      dispatch(getDTMAnalyticsData(obj1))
      dispatch(getDTMAnalyticsMonth(obj3))
    },
    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        displayColors: false,
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: context => {
            return context[0].label.includes(':') ? context[0].label : `${context[0].label}`
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString() + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`
          }
        }
      }
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target;
      target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  }

  const performanceoptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    scales: {
      xAxes: {
        display: true,
        stacked: true
      },
      yAxes: {
        display: true,
        stacked: true,
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },
    onClick: (context) => {
      setShowPerformanceVolume(false);
      setShowPastMonthTrends(false)
      let newDate = new Date(context?.chart?.tooltip?.title?.[0]);
      let year = !isNaN(newDate?.getFullYear()) ? newDate?.getFullYear() : new Date().getFullYear();
      let month = !isNaN(newDate?.getMonth()) ? newDate?.getMonth() : new Date().getMonth();
      setCurrentDate(moment().set({ 'year': year, 'month': month }))
      const myDate = moment().set({ 'year': year, 'month': month })
      let obj1 = {
        customerID: customerID,
        serviceID: serviceID,
        view: '',
        date: formatDate(myDate),
        item: selectedSLA.item
      }
    
      let obj3 = {
        customerID: customerID,
        serviceID: serviceID,
        view: '12+month',
        date: formatDate(myDate),
        item: selectedSLA.item
      }
      dispatch(getDTMAnalyticsData(obj1))
      dispatch(getDTMAnalyticsMonth(obj3))
    },
    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        displayColors: false,
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: context => {
            return context[0].label.includes(':') ? context[0].label : `${context[0].label}`
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString() 
          }
        }
      }
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target;
      target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  }


  const DTMLabelsHandler = (label) => {
    switch (label) {
      case 'dailyPerformance':
      case 'DTM': {
        const temp_arr = [];
        let n = 1;
        while (n <= daysInMonth(currentDate)) {
          temp_arr.push(n);
          n++;
        }
        return temp_arr;
      }
      case 'CalendarPopup':
      case 'HalfHour': {
        const temp_arr = [];
        let a = 0
        while (a <= 23) {
          temp_arr.push(`${a}:00`);
          temp_arr.push(`${a}:30`);
          a++;
        }
        return temp_arr;
      }
      case 'performanceVolume':
      case 'pastMonthTrends': {
        var monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var d = new Date(currentDate);
        d.setDate(1);
        let temp_arr = [];
        for (let i = 12; i >= 0; i--) {
          temp_arr[i] = monthName[d.getMonth()] + ' ' + d.getFullYear()
          d.setMonth(d.getMonth() - 1);
        }
        // let temp_arr = Array.apply(0, Array(12)).map(function (_, i) { return `${moment().month(i).format('MMM')} ${moment().month(i).format('YYYY')}` });
        return temp_arr
      }
      default: { }
    }
  }

  const DTMChartsDataHandler = (label) => {
    switch (label) {
      case 'DTM': {
        let temp_dtm = [];
        DTMAnalyticsData?.daytomonth?.map((data, i) => {
          temp_dtm[i] = selectedSLA.format === 'Percentage' ? parseFloat(((data?.numerator / data?.denominator) * 100).toFixed(2)) : parseInt(data?.numerator)
        })
        return temp_dtm;
      }
      case 'dailyPerformance': {
        let temp_daily = [];
        DTMAnalyticsData?.dailyperformance?.map((data, i) => {
          temp_daily[i] = selectedSLA.format === 'Percentage' ? data?.numerator : data?.numerator - data?.denominator
        })
        return temp_daily;
      }
      case 'HalfHour': {
        let temp_half = [];
        DTMAnalyticsData?.halfhourdata?.map((data, i) => {
          temp_half[i] = selectedSLA.format === 'Percentage' ? data?.numerator : data?.numerator - data?.denominator
        })
        return temp_half;
      }
      case 'CalendarPopup': {
        let temp_half = [];
        DTMHalfHourData?.halfhourdata?.map((data, i) => {
          temp_half[i] = selectedSLA.format === 'Percentage' ? data?.numerator : data?.numerator - data?.denominator
        })
        return temp_half;
      }
      case 'pastMonthTrends': {
        let temp_pastMonth = [];
        DTMMonthlyInfo?.monthdata?.map((data, i) => {
          temp_pastMonth[i] = selectedSLA.format === 'Percentage' ? parseFloat(((data?.numerator / data?.denominator) * 100).toFixed(2)) : data?.numerator - data?.denominator
        })
        return temp_pastMonth;
      }
      case 'performanceVolume': {
        let temp_perf = [];
        DTMMonthlyInfo?.monthdata?.map((data, i) => {
          temp_perf[i] = selectedSLA.format === 'Percentage' ? data?.numerator : data?.numerator - data?.denominator
        })
        return temp_perf;
      }
      default: { }
    }
  }

  const DTMStackedChartsDataHandler = (label) => {

    let temp_arr = [...DTMLabelsHandler(label)];
    switch (label) {
      case 'DTM': {
        let temp_dtm = [];
        DTMAnalyticsData?.daytomonth?.map((data, i) => {
          temp_dtm[i] = parseInt(data?.difference)
        })
        return temp_dtm;
      }
      case 'dailyPerformance': {
        let temp_daily = [];
        DTMAnalyticsData?.dailyperformance?.map((data, i) => {
          temp_daily[i] = selectedSLA.format === 'Percentage' ? data?.difference : data?.denominator
        })
        return temp_daily;
      }
      case 'HalfHour': {
        let temp_half = [];
        DTMAnalyticsData?.halfhourdata?.map((data, i) => {
          temp_half[i] = selectedSLA.format === 'Percentage' ? data?.difference : data?.denominator
        })
        return temp_half;
      }
      case 'CalendarPopup': {
        let temp_half = [];
        DTMHalfHourData?.halfhourdata?.map((data, i) => {
          temp_half[i] = selectedSLA.format === 'Percentage' ? data?.difference : data?.denominator
        })
        return temp_half;
      }
      case 'pastMonthTrends': {
        let temp_perf = [];
        return temp_perf;
      }
      case 'performanceVolume': {
        let temp_perf = [];
        DTMMonthlyInfo?.monthdata?.map((data, i) => {
          temp_perf[i] = selectedSLA.format === 'Percentage' ? data?.difference : parseInt(data?.denominator)
        })
        return temp_perf;
      }
      default: { }

    }
  };


  const DTMLineBarBgAdjustHandle = (color) => {
    switch (color) {
      case 'red': return BelowMinimalTarget_Status;
      case 'green': return AboveRequiredTarget_Status;
      case 'yellow': return BelowMinimalTargetIcon;
      default: return;
    }
  }

  const [day, setDay] = useState('');
  const [targetValue, setTargetValue] = useState(null)

  const calendarClickHandle = (day) => {
    setDay(day);
    const dt = new Date(day).getDate();
    let obj2 = {
      customerID: customerID,
      serviceID: serviceID,
      view: 'halfhour',
      date: day.format('MM/DD/YYYY'),
      item: selectedSLA.item
    }
    dispatch(getDTMAnalyticsHalfHour(obj2))
    setTargetValue(DTMAnalyticsData?.dailyperformance?.[dt - 1]);
    setShowCalendarvalueModel(true);
  }
  const CalendarDailyValues = (day) => {
    const dt = new Date(day).getDate();
    return DTMAnalyticsData?.dailyperformance?.[dt - 1]
  }


  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [calendar, setCalendar] = useState([])
  const [weekOfTheYear, setWeekOfTheYear] = useState([]);
  const startingDate = currentDate.clone().startOf('month').format('MM/DD/YYYY');
  const endingDate = currentDate.clone().endOf('month').format('MM/DD/YYYY');
  function beforeToday(day) {
    if (day.isBefore(new Date(startingDate), "day")) return "Yes its working"
  }
  function afterToday(day) {
    if (day.isAfter(new Date(endingDate), "day")) return "Yes its working"
  }

  useEffect(() => {
    const startDay = currentDate.clone().startOf("month").startOf("week");
    const endDay = currentDate.clone().endOf("month").endOf("week");
    const day = startDay.clone().subtract(1, "day");
    const temp_calendar = [];
    while (day.isBefore(endDay, "day")) {
      temp_calendar.push(Array(7).fill(0).map(() => day.add(1, "day").clone()))
    }
    const temp_arr_one = temp_calendar[0].map(day => {
      if (Number(day.format('DD')) > 20) return '';
      else return day
    })
    const temp_arr_two = temp_calendar[temp_calendar.length - 1].map(day => {
      if (Number(day.format('DD')) < 10) return '';
      else return day
    })
    temp_calendar[0] = temp_arr_one;
    temp_calendar[temp_calendar.length - 1] = temp_arr_two;
    setCalendar(temp_calendar);
    let temp_week = temp_calendar.map((week) => {
      if (temp_calendar?.indexOf(week) === 0) return week?.[week?.length - 1].format('WW');
      else return week?.[1] ? week?.[1]?.format('WW') : week?.[0]?.format('WW');
    });
    setWeekOfTheYear(temp_week);
  }, [currentDate]);

  const exportAsPicture = (id, image) => {
    htmlToImage.toPng(document.getElementById(id), {
      quality: 0.90,
    })
      .then(function (dataUrl) {
        let link = document.createElement('a');
        link.download = image;
        link.href = dataUrl;
        link.click()
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }
  const ReportRedirect = () => {
    if (DTMAnalytics?.servicedesk?.id) {
      window.localStorage.setItem('ServiceDeskID', DTMAnalytics?.servicedesk?.id);
    }
    if (DTMAnalytics?.customer?.id) {
      window.localStorage.setItem('CustomerID', DTMAnalytics?.customer?.id);
    }
    dispatch(updateRegionalSiteCustomerID(DTMAnalytics?.customer?.id));
    dispatch(updateRegionalSiteCustomerName(DTMAnalytics?.customer?.name));
    dispatch(updateSelectedAccountName(DTMAnalytics?.customer?.name));
    dispatch(saveAccountLocationName(DTMAnalytics?.servicedesk?.name));
    dispatch(saveSelectedMenuItem("reports"));
    dispatch(updateDTMReportDate(new Date(formatDate(currentDate))));
    history.push("/reports");
  }

  return (
    <>
      {/* <AdminBreadcrumb value1='/welcome' label1='Home' isActive2={true} label2='Reports' value2='/regional'
        isActive3={true} label3={customerName + ' - DTM Analytics - ' + currentDate.format('MMMM') + ' - ' + currentDate.format('YYYY')} onClick={ReportRedirect} isActive4={false} label4={selectedSLA.slaItem} /> */}
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Reports' value2={()=>history.push('/regional')}
        isActive3={true} label3={customerName} value3={ReportRedirect} isActive4={true}
        label4='DTM' value4={ReportRedirect} isActive5={true} label5={currentDate.format('MMMM') + ' - ' + currentDate.format('YYYY')} value5={ReportRedirect}
        label6={"Analytics - " + selectedSLA.slaItem} isActive6={false}
      />
      <section id='whatever'>
        <section className='dtm_analytics_container' id='whatever'>
          <main className='calls_handled_text'><div>{customerName} - {selectedSLA.slaItem} - {`${currentDate.format('MMMM')} ${currentDate.format('YYYY')}`}</div>
            <div onClick={() => { exportAsPicture('whatever', selectedSLA.slaItem) }} style={{ cursor: 'pointer' }}>
              <button className='btnn'> <img src={Download_Icon} alt='' /> &nbsp; DOWNLOAD</button>
            </div>
          </main>
          <section className='SLA_DTM_Container'>
            <div id='first'>
              <div className='second_one'>  {selectedSLA.slaItem}   </div>
              <div className='first_one'>{selectedSLA.format === 'Time' ? formatTime(DTMAnalyticsData?.currentmonth?.[0]?.fvalue) : DTMAnalyticsData?.currentmonth?.[0]?.value}</div>
            </div>
            {DTMAnalyticsData?.slasetting?.[0]?.expectedtarget && <div id='first'>
              <div className='second_one'>  Expected Target   </div>
              <div className='first_one'> {selectedSLA.format === 'Time' ? formatTime(DTMAnalyticsData?.slasetting?.[0]?.expectedtarget) : DTMAnalyticsData?.slasetting?.[0]?.expectedtarget + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}</div>
            </div>}
            {DTMAnalyticsData?.slasetting?.[0]?.minimumtarget && <div id='first'>
              <div className='second_one'>  Minimum Target   </div>
              <div className='first_one'> {selectedSLA.format === 'Time' ? formatTime(DTMAnalyticsData?.slasetting?.[0]?.minimumtarget) : DTMAnalyticsData?.slasetting?.[0]?.minimumtarget + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}</div>
            </div>}
            <div className='trend_open_text' onClick={() => setShowPastMonthTrends(true)}> Past 12 Months Trend</div>
            <div className='trend_open_text' onClick={() => setShowPerformanceVolume(true)}>Performance Vs Volume</div>
          </section>
        </section>

        <StackedLineBarCharts heading='Day To Month (DTM)'
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
          xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`}
          labels={DTMLabelsHandler('DTM')} data={DTMChartsDataHandler('DTM')}
          lineData={DTMStackedChartsDataHandler('DTM')} color='#6CC24A'
          numerator={DTMAnalyticsData?.slasetting?.[0]?.numerator}
          options={DTMoptions} />

        <StackedBarCharts heading='Daily Performance'
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '%' : ''}`}
          xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`}
          numerator={DTMAnalyticsData?.slasetting?.[0]?.numerator}
          difference={DTMAnalyticsData?.slasetting?.[0]?.difference}
          labels={DTMLabelsHandler('dailyPerformance')} data={DTMChartsDataHandler('dailyPerformance')}
          stackedData={DTMStackedChartsDataHandler('dailyPerformance')} color='#00A3E1' stackedColor='#FFCD00'
          options={options} />

        <section className='calander_container'>
          <header className='header_values'>
            <div id='div1'>Daily Values</div>
            <div id='div2'>
              <><img src={AboveRequiredTarget_Status} alt='' />{DTMAnalyticsData?.slasetting?.[0]?.numerator}  <img src={BelowMinimalTarget_Status} alt='' /> {DTMAnalyticsData?.slasetting?.[0]?.difference} </>
            </div>
          </header>
          <table className='table_calendar'>
            <thead>
              <tr>
                <th>{currentDate.format('YYYY')}</th> {weekDays.map((week, i) => (<th key={week}>{week}</th>))}
              </tr>
            </thead>
            <tbody>
              {calendar.map((week, i) => (<tr>
                <td id='week_id'> <div id='week_no'>{weekOfTheYear[i]} </div> Week</td>
                {week.map((day, idx) => (<td>
                  <div id='date_month'>{day && day.format("DD MMM").toString()}   </div>

                  <div id='percent' style={{ position: 'relative', cursor: 'pointer' }}
                    onClick={() => CalendarDailyValues(day) && calendarClickHandle(day)}
                    onMouseOver={() => {
                      setDropdownIndex(i);
                      setWeekIndex(idx);
                      CalendarDailyValues(day) && setShowTooltip(true)
                    }}
                    onMouseLeave={() => {
                      setShowTooltip(false);
                      setWeekIndex(null);
                      setDropdownIndex(null);
                    }}>
                    {CalendarDailyValues(day) && (selectedSLA.format === 'Time' ? formatTime(CalendarDailyValues(day)?.numerator) : CalendarDailyValues(day)?.value)}
                    &nbsp; &nbsp;
                    {(showTooltip && dropdownIndex === i && weekIndex === idx) && (
                      <ul className='tooltip_content'>
                        <li className='tooltip_head_text'>{day.format('MM/DD/YYYY')}</li>
                        <li className='tooltip_body_text'><div>{DTMAnalyticsData?.slasetting?.[0]?.numerator} : <span id='number'>{CalendarDailyValues(day)?.numerator}</span></div>
                          <div>{DTMAnalyticsData?.slasetting?.[0]?.denominator} : <span id='number'>{CalendarDailyValues(day)?.denominator}</span></div>
                          <div> {DTMAnalyticsData?.slasetting?.[0]?.difference} : <span id='number'>{CalendarDailyValues(day)?.difference}</span></div></li>
                      </ul>
                    )}
                    {(CalendarDailyValues(day)?.color !== null && CalendarDailyValues(day)) && <img src={DTMLineBarBgAdjustHandle(CalendarDailyValues(day)?.color)} alt='' />}
                  </div>
                </td >))
                }</tr >))}
            </tbody >
          </table >
        </section >
        <StackedBarCharts
          heading={`${selectedSLA.slaItem} (By Half Hour Average: 01/${currentDate.format('MM')}/${currentDate.format('YYYY')} to ${daysInMonth(currentDate)}/${currentDate.format('MM')}/${currentDate.format('YYYY')})`}
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
          numerator={DTMAnalyticsData?.slasetting?.[0]?.numerator}
          difference={DTMAnalyticsData?.slasetting?.[0]?.difference}
          xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`}
          labels={DTMLabelsHandler('HalfHour')} data={DTMChartsDataHandler('HalfHour')}
          stackedData={DTMStackedChartsDataHandler('HalfHour')} color='#006975' stackedColor='#FFCD00'
          options={options} />

      </section>
      {
        showCalendarvalueModel && <CalendarModelpopup isOpen={setShowCalendarvalueModel} setShowModal={setShowCalendarvalueModel}  >
          <CalendarvaluesModel closewindow={() => setShowCalendarvalueModel(false)}
            heading={`(${formatDatehandle(targetValue?.date)}) - Standard Telephony By Half Hour `} title={true}
            yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}` }
            target={targetValue?.value}  Arrcolor={targetValue?.color}
            numerator={DTMAnalyticsData?.slasetting?.[0]?.numerator}
            difference={DTMAnalyticsData?.slasetting?.[0]?.difference}
            xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`}
            labels={DTMLabelsHandler('CalendarPopup')} data={DTMChartsDataHandler('CalendarPopup')}
            stackedData={DTMStackedChartsDataHandler('CalendarPopup')} color='#006975' stackedColor='#FFCD00'
            options={options} />
        </CalendarModelpopup>
      }

      {
        showPerformanceVolume && <CalendarModelpopup isOpen={setShowPerformanceVolume} setShowModal={setShowPerformanceVolume}  >
          <CalendarvaluesModel closewindow={() => setShowPerformanceVolume(false)}
            heading='' title={false} target='' 
            yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
            numerator={DTMAnalyticsData?.slasetting?.[0]?.numerator}
            difference={DTMAnalyticsData?.slasetting?.[0]?.difference}
            xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`}
            labels={DTMLabelsHandler('performanceVolume')} data={DTMChartsDataHandler('performanceVolume')}
            stackedData={DTMStackedChartsDataHandler('performanceVolume')} color='#006975' stackedColor='#FFCD00'
            options={performanceoptions} />

        </CalendarModelpopup>
      }
      {
        showPastMonthTrends && <CalendarModelpopup isOpen={setShowPastMonthTrends} setShowModal={setShowPastMonthTrends}  >
          <PastMonthTrendsModel closewindow={() => setShowPastMonthTrends(false)}
            heading=''
            yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
            xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`}
            labels={DTMLabelsHandler('pastMonthTrends')} data={DTMChartsDataHandler('pastMonthTrends')}
            color='#6CC24A'
            options={monthlyoptions} />
        </CalendarModelpopup>
      }
    </>
  )
}

export default DTMAnalyticsSLA