import React, { useEffect, useState } from 'react';
import './AccountSelect.css';
import searchIcon from '../../../assets/images/Search_Icon.png';
import closeIcon from '../../../assets/images/Close_Window_Icon.png';
import editIcon from '../../../assets/images/Edit_Icon.png';
import deleteIcon from '../../../assets/images/Delete_Icon.png';
import CustomWidgetsIcon from '../../../assets/images/CustomWidgets_Icon.png';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getMultiAccountWidgets, saveDropdownAccounts, saveSelectedAccounts, updateMultiWidgetStart } from '../../../actions/welcomePage.action';
import { WidgetModalPopup } from './WidgetModalPopup';
import WidgetModal from '../CustomWidget/WidgetModal';
import HomeBreadcrumb from './Home-Breadcrumb';
import { DeleteFragment, DeleteModalPopup } from '../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import TooltipHandler from '../../Onboard/OnboardComponents/TooltipHandler';




const CANCEL_BUTTON = "CANCEL";
const CUSTOM_WIDGET_BUTTON = "SEE MY CUSTOM WIDGET";
const UPDATE_BUTTON = "UPDATE";
const SKIP_BUTTON = 'SKIP';


function AccountSelect() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let multiAccountsInfo = useSelector((state) => state.welcomePageReducer.multiAccounts);
    let savedAccountsInfo = useSelector((state) => state.welcomePageReducer.selectedAccounts);

    

    const [accountInfo, setAccountInfo] = useState([])
    const [showWidgetModal, setShowWidgetModal] = useState(false);
    const [multiAccountSelected, setMultiAccountSelected] = useState(false);
    const [widgetAccountSelected, setWidgetAccountSelected] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [query, setQuery] = useState('');
    const [noOfWidgetSelected, setNoOfWidgetSelected] = useState(0);
    const [accountName, setAccountName] = useState('');
    const [showDeleteWidgetModal, setShowDeleteWidgetModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const WIDGET_HEADING = editModal === true ? 'Edit Widgets' : 'Add Widgets';

    useEffect(() => {
        dispatch(getMultiAccountWidgets());
    }, [dispatch]);

    useEffect(() => {
        if (multiAccountsInfo?.length > 0) {
            multiAccountsInfo?.map(account => account.isChecked = false);
            setAccountInfo(multiAccountsInfo);
        };
    }, [dispatch,multiAccountsInfo]);
    const filterQuery = options => {
        return options.filter(option => {
            if (query === '') return option;
            else if (option.CustomerName.toLowerCase().includes(query.toLowerCase().trim()))
                return option;
        })
    }
    const [widgetSelectedArr, setWidgetSelectedArr] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const AccountSelectHandle = (account, i) => {
        let temp_Arr = [...accountInfo];
        temp_Arr.map((itm, i) => {
            if (account?.CustomerName === itm?.CustomerName) {
                itm.isChecked = !account.isChecked;
                if (account?.isChecked) {
                    let element = temp_Arr.slice(i, i + 1);
                    temp_Arr.splice(i, 1);
                    temp_Arr.unshift(element[0]);
                    widgetSelectedArr.unshift(account.CustomerName);
                }
                if (!account?.isChecked) {
                    if (widgetSelectedArr.includes(account.CustomerName)) {
                        let index_acc = widgetSelectedArr.indexOf(account.CustomerName);
                        widgetSelectedArr.splice(index_acc, 1);
                    }
                }
            }
            if (account?.isChecked === false) {
                if (account?.CustomerName === itm?.CustomerName) {
                    setNoOfWidgetSelected(prevState => prevState - 1);
                }
            }
            if (account?.isChecked === true) {
                account?.CustomerName === itm?.CustomerName &&
                    setNoOfWidgetSelected(prevState => prevState + 1);
            }
            return ' ';
        })
        let uniqueWidgets = [];
        widgetSelectedArr.forEach((c) => {
            if (!uniqueWidgets.includes(c)) {
                uniqueWidgets.push(c);
            }
        });
        let resultWidgets = accountInfo.filter(el => {
            return uniqueWidgets.filter(element => {
                if (element === el.CustomerID) return element;
            });
        });

        let filteredResultWidgets = resultWidgets.filter(res => {
            if (res.isChecked === true) return res;
        });
        dispatch(saveSelectedAccounts(filteredResultWidgets));
        setSelectAllChecked(!accountInfo.some((acc) => acc?.isChecked !== true));
        setAccountInfo(temp_Arr);
        if (editMode === true) {
            setWidgetAccountSelected(true);
            setMultiAccountSelected(false);
        }
        else {
            setWidgetAccountSelected(false);
            setMultiAccountSelected(true);
        }

    }

    const AccountSelectAllHandle = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            let tempUser = accountInfo.map((item => {
                setSelectAllChecked(!item.isChecked);
                return { ...item, isChecked: true }
            }));
            setAccountInfo(tempUser);
            setNoOfWidgetSelected(multiAccountsInfo?.length);
            accountInfo?.map(account => widgetSelectedArr.unshift(account.CustomerName))
        }
        if (!checked) {
            let tempUser = accountInfo.map((item => {
                setSelectAllChecked(!item.isChecked);
                return { ...item, isChecked: false }
            }));
            setNoOfWidgetSelected(0);
            setAccountInfo(tempUser);
            setWidgetSelectedArr([]);
        }
        let uniqueWidgets = [];
        widgetSelectedArr.forEach((c) => {
            if (!uniqueWidgets.includes(c)) {
                uniqueWidgets.push(c);
            }
        });
        let resultWidgets = [];
        resultWidgets = accountInfo.filter(el => {
            return uniqueWidgets.filter(element => {
                if (element === el.CustomerID) return element;
            });
        });

        let filteredResultWidgets = resultWidgets.filter(res => {
            if (res.isChecked === true) return res;
        });
        if (selectAllChecked === false)
            dispatch(saveSelectedAccounts(filteredResultWidgets));

        if (editMode === true) {
            setWidgetAccountSelected(true);
            setMultiAccountSelected(false);
        }
        else {
            setWidgetAccountSelected(false);
            setMultiAccountSelected(true);
        }
    }
    if (selectAllChecked === true) dispatch(saveSelectedAccounts(accountInfo));

    function removeDuplicates(originalArray, prop) {
        let account_arr = [];
        let accountObj = {};
        for (var i in originalArray) {
            accountObj[originalArray[i][prop]] = originalArray[i];
        }
        for (i in accountObj) {
            account_arr.push(accountObj[i]);
        }
        return account_arr;
    }
    const [savedAccounts, setSavedAccounts] = useState([]);
    useEffect(() => {
        if (multiAccountsInfo?.length > 0 && savedAccountsInfo?.length > 0) {
            let temp_arr = [...multiAccountsInfo, ...savedAccountsInfo];
            var uniqueArray = removeDuplicates(temp_arr, "CustomerName");
            setAccountInfo(uniqueArray)
            let AccountHandleData = (temp_arr)?.map(widgets => widgets.Widgets);
            let accountWidgets = AccountHandleData.map(account => {
                let noOfWidgets = 0;
                if (account[0].Selected === true) noOfWidgets += 1;
                if (account[1].Selected === true) noOfWidgets += 1;
                return noOfWidgets;
            });
            uniqueArray.map((array, i) => array.noOfWidgets = accountWidgets[i]);
            setSavedAccounts(uniqueArray);
        }
    }, [multiAccountsInfo, dispatch]);
    dispatch(saveDropdownAccounts(savedAccounts));


    const [accountWidgets, setAccountWidgets] = useState([]);
    const [customerID_unique, setCustomerID_unique] = useState(null);
    let AccountHandleData = multiAccountsInfo?.map(widgets => widgets.Widgets);
    let AccountHandlerData = multiAccountsInfo?.map(widgets => ({
        "customerId": widgets.CustomerID ?? null,
        "Widgets": widgets.Widgets ?? []
    }));
    let customerIds = multiAccountsInfo?.map(id => id.CustomerID);
    const CustomWidgetHandler = (account) => {
        dispatch(getMultiAccountWidgets());
        setShowWidgetModal(true);
        let index = customerIds.indexOf(account.CustomerID);
        setAccountWidgets(AccountHandleData[index]);
        setCustomerID_unique(account.CustomerID);
        setAccountName(account.CustomerName);
    }
    let selectedWidgets = accountWidgets?.filter(acc => {
        if (acc.Selected === true) return acc.WidgetID;
    }).map(dt => dt.WidgetID);


    const toggleHandler = (bool, name, id) => {
        if (bool === true) {
            selectedWidgets.unshift(id);
        }
        if (bool === false) {
            if (selectedWidgets.includes(id)) {
                let index = selectedWidgets.indexOf(id);
                if (index > -1) {
                    selectedWidgets.splice(index, 1);
                }
            }
        }
        dispatch(updateMultiWidgetStart(
            [
                {
                    "CustomerID": customerID_unique,
                    "WidgetIds": selectedWidgets
                }
            ]
        ));
    }


    const widget_ButtonHandler = () => {
        setShowWidgetModal(false);
        dispatch(getMultiAccountWidgets());
        setEditModal(false);
    }

    useEffect(() => {
        let filteredEditMode = AccountHandleData.filter(account => {
            if (account[0].Selected || account[1].Selected) {
                return account;
            }
        })
        if (filteredEditMode.length > 0) setEditMode(true)
        else setEditMode(false);
    }, [AccountHandleData]);

    const [deleteWidgetID, setDeleteWidgetID] = useState(null);
    const deleteUniqueHandler = account => {
        setShowDeleteWidgetModal(true);
        setAccountName(account.CustomerName);
        setDeleteWidgetID(account.CustomerID);
    }

    const handleDeleteWidget = () => {
        setShowDeleteWidgetModal(false);
        dispatch(updateMultiWidgetStart(
            [
                {
                    "CustomerID": deleteWidgetID,
                    "WidgetIds": []
                }
            ]
        ));
        dispatch(getMultiAccountWidgets());
    }
    const [multiAccountWidgetModal, showMultiAccountWidgetModal] = useState(false);
    const [multiWidgetsOverwrite, setMultiWidgetsOverwrite] = useState(null);
    let filteredAccounts = accountInfo.filter(accounts => accounts.isChecked === true);
    let filteredCustomerIds = filteredAccounts.map(ids => ids.CustomerID);
    const MultiAccountAddWidget = () => {
        setSelectedWidgetMulti([])
        setMultiWidgetsOverwrite(false);
        dispatch(getMultiAccountWidgets());
        showMultiAccountWidgetModal(true);
        let index = customerIds.indexOf(filteredAccounts[0].CustomerID);
        if (multiWidgetsOverwrite) setAccountWidgets(AccountHandleData[index]);
        else {
            let temp_arr = [...AccountHandleData[index]]
            temp_arr.map(info => info.Selected = false);
            setAccountWidgets(temp_arr)
        }
    }

    const handleMutliAddClick = () => {
        setMultiWidgetsOverwrite(true);
        handleDeleteMultiWidget();
        dispatch(getMultiAccountWidgets());
        MultiAccountAddWidget();
    }

    const dispatchedItem = (customerId, widgetID) => ({
        "CustomerID": customerId,
        "WidgetIds": widgetID
    })
    const [selectedWidgetMulti, setSelectedWidgetMulti] = useState([])
    const toggleHandleMulti = (bool, name, id) => {
        let temp_Arr = [...selectedWidgetMulti];
        if (bool === true) {
            temp_Arr.unshift(id)
        }

        if (bool === false) {
            if (temp_Arr.includes(id)) {
                let index = temp_Arr.indexOf(id);
                if (index > -1) {
                    temp_Arr.splice(index, 1);
                }
            }
        }
        setSelectedWidgetMulti(temp_Arr)
        let filteredData = filteredCustomerIds.map(i => dispatchedItem(i, temp_Arr))
        dispatch(updateMultiWidgetStart(filteredData));
        dispatch(getMultiAccountWidgets());
    }

    const widget_ButtonHandlerMulti = () => {
        dispatch(getMultiAccountWidgets());
        showMultiAccountWidgetModal(false)
        setMultiAccountSelected(false)
    }

    const [multiAccountDeleteModal, showMultiAccountDeleteModal] = useState(false)
    const handleDeleteMultiWidget = () => {
       
        let filteredData = filteredCustomerIds.map(i => dispatchedItem(i, []))
        dispatch(updateMultiWidgetStart(filteredData));
        dispatch(getMultiAccountWidgets())
        showMultiAccountDeleteModal(false);
        dispatch(getMultiAccountWidgets())

    }

    const [showCancelWidgetModal, setShowCancelWidgetModal] = useState(false);
    const [sureProceedModal, setSureProceedModal] = useState(false);
    const handleCancelWidgets = () => {
        let temp_Arr = [...accountInfo];
        temp_Arr.map(accunt => accunt.isChecked = false);
        setAccountInfo(temp_Arr);
        setMultiAccountSelected(false);
        setWidgetAccountSelected(false);
        handleDeleteMultiWidget()
        setShowCancelWidgetModal(false);
        history.push('/welcome');
    }

    return (
        <>
            <section className='account_filter_container'>
                {location.pathname !== '/widget' && <HomeBreadcrumb pageId='Custom Widgets' style={{ marginLeft: '0px' }} />}
                <div style={{ height: '40px' }}></div>
                {location.pathname === '/welcomePage/filter' && <header className='header_sign'>Custom Widgets</header>}
                <div className='sub_header_sign' >Select a custom widget for your accounts</div>
                {widgetAccountSelected && <main className={`add_widgets ${location.pathname === '/widget' && 'extra_widgets_bar'}`} >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div id='widget_added'>
                            <span className='account_span'> {noOfWidgetSelected} accounts selected</span>
                            <br />
                            <span className='account_note'>(Note: ADD WIDGETS action will overwrite your existing selected widgets.)</span>
                        </div>
                        <div style={{ display: 'flex', marginTop: '7px' }} >
                            <button className='skip-button-acc' onClick={handleMutliAddClick}>
                                <span className='skip-span-acc' >ADD WIDGET</span>
                            </button>
                            <button className='skip-button-acc' style={{ marginLeft: 20, marginRight: 20 }} onClick={() => showMultiAccountDeleteModal(true)}>
                                <span className='skip-span-acc'  >DELETE WIDGET</span>
                            </button>

                            <div className='add_widget_img' onClick={() => {
                                setShowDeleteWidgetModal(false);
                                setShowWidgetModal(false)
                                setWidgetAccountSelected(false)
                            }}><img src={closeIcon} alt='' /></div>
                        </div>
                    </div>
                </main>}
                {multiAccountSelected && <main className={`on_visit_add ${location.pathname === '/widget' && 'extra_widgets_bar'}`}>
                    <div id='widget_added'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                            <div >
                                <span className='account_span'> {noOfWidgetSelected} accounts selected</span>
                                <br />
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <button className='skip-button-acc' style={{ marginBottom: 10, marginRight: '20px' }} onClick={MultiAccountAddWidget}>
                                        <span className='skip-span-acc' >ADD WIDGET</span>
                                    </button>
                                </div>
                                <div className='add_widget_img' onClick={() => {
                                    setShowDeleteWidgetModal(false);
                                    setShowWidgetModal(false)
                                    setMultiAccountSelected(false)
                                }}>
                                    <img src={closeIcon} alt='' /></div>
                            </div>
                        </div>
                    </div>
                </main>
                }
                <main className='selection_container' >

                    <div className='account_list select_all' style={{ width: '95%', marginTop: '0px' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div> <span style={{ cursor: 'pointer' }}>
                                <input type='checkbox' id='select' name="selectAll" style={{ cursor: 'pointer' }}
                                    onChange={AccountSelectAllHandle}
                                    checked={!accountInfo.some((acc) => acc?.isChecked !== true)}
                                />
                                <label className='label-region' htmlFor='select' style={{ cursor: 'pointer' }}>  Select All </label></span></div>
                            <div style={{ marginRight: '20px', marginTop: '-5px' }}><span className='search-bar-widget'>
                                <input type='input' className='search_account'
                                    onChange={(e) => setQuery(e.target.value)}
                                    placeholder='Search Account' />
                                <button className='search-icon-widget'><img src={searchIcon} alt='' /> </button></span></div>
                        </div>
                    </div>

                     <section className='scroll_bar'>
                     {filterQuery(accountInfo)?.map((account, i) => {
    let widgets = AccountHandlerData.find(acc => acc.customerId === account.CustomerID)?.Widgets ?? [];
    return (
        <div className='account_list select_list' key={i}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <span className='account_name'>
                        <input type='checkbox' id={account.CustomerName} style={{ cursor: 'pointer' }}
                            name={account.CustomerName}
                            onChange={() => AccountSelectHandle(account, i)}
                            checked={account?.isChecked}
                        />
                        <label style={{ color: 'black', cursor: 'pointer' }} htmlFor={account.CustomerName}>
                            {account.CustomerName}
                        </label>
                    </span>
                </div>
                {widgets.filter(wid => wid.Selected === true).length > 0 ?
                    <div>
                        {widgets.filter(wid => wid.Selected === true).length} Widgets
                        <img style={{ marginRight: '25px', marginLeft: '25px', cursor: 'pointer' }}
                            src={editIcon} alt='' onClick={() => {
                                setEditModal(true);
                                CustomWidgetHandler(account);
                            }} />
                        <img style={{ marginRight: '25px', cursor: 'pointer' }}
                            src={deleteIcon} alt='' onClick={() => deleteUniqueHandler(account)} />
                    </div>
                    :
                    <span style={{ marginRight: '20px' }} onClick={() => CustomWidgetHandler(account)}>
                        <TooltipHandler src={CustomWidgetsIcon} width='22px' height='22px' position='left center'
                            textBoxWidth='150px' text='Add Widgets' info={true} />
                    </span>
                }
            </div>
        </div>
    );
})}
                    </section>
                </main>
                {location.pathname === '/widget' ?
                    <footer className='button_placeholder'>

                        <button className='skip-button fit_content_skip ' onClick={() => (filteredAccounts.length === 0) ? setSureProceedModal(true) : history.push('/welcome')}>
                            <span className='skip-span '

                            >{SKIP_BUTTON}</span>
                        </button>
                        <button className='widget-button fit_content_see' onClick={() => history.push('/welcome')}>
                            <span className='widget-button-span' style={{ marginTop: '4px' }}
                            >{CUSTOM_WIDGET_BUTTON}</span>
                        </button>
                    </footer> :
                    <footer className='button-wrapper'>
                        <button className='widget-button fit_content_update' onClick={() => {
                            setMultiAccountSelected(false);
                            setWidgetAccountSelected(false);
                            (filteredAccounts.length === 0) ? setSureProceedModal(true) : history.push('/welcome')
                        }}>
                            <span className='widget-button-span' style={{ marginTop: '1px' }}
                            >{UPDATE_BUTTON}</span>
                        </button>
                        <button className='skip-button fit_content_skip' onClick={() => setShowCancelWidgetModal(true)}>
                            <span className='skip-span ' >{CANCEL_BUTTON}</span>
                        </button>
                    </footer>
                }
                <br />
                <br />
                {
                    (showWidgetModal && widgetAccountSelected === false && multiAccountSelected === false)
                    && <WidgetModalPopup isOpen={showWidgetModal} setShowModal={setShowWidgetModal}>
                        <WidgetModal BUTTON_ONE={CANCEL_BUTTON} BUTTON_TWO={CUSTOM_WIDGET_BUTTON} WIDGET_HEADING={WIDGET_HEADING}
                            accountWidgets={accountWidgets} skip_ButtonHandler={() => {
                                setEditModal(false);
                                setShowWidgetModal(false)
                            }}
                            toggleHandler={toggleHandler} accountName={accountName}
                            widget_ButtonHandler={widget_ButtonHandler} />
                    </WidgetModalPopup>}

                {(multiAccountWidgetModal) && <WidgetModalPopup isOpen={multiAccountWidgetModal} setShowModal={showMultiAccountWidgetModal}>
                    <WidgetModal BUTTON_ONE={CANCEL_BUTTON} BUTTON_TWO={CUSTOM_WIDGET_BUTTON} WIDGET_HEADING={WIDGET_HEADING}
                        accountWidgets={accountWidgets} skip_ButtonHandler={() => showMultiAccountWidgetModal(false)}
                        toggleHandler={toggleHandleMulti} accountName='Selected Accounts'
                        widget_ButtonHandler={widget_ButtonHandlerMulti} />
                </WidgetModalPopup>}

                {(showDeleteWidgetModal && widgetAccountSelected === false && multiAccountSelected === false)
                    && <DeleteModalPopup isOpen={showDeleteWidgetModal} setShowModal={setShowDeleteWidgetModal}>
                        <DeleteFragment modalName='Delete Widgets' buttonOne='YES' buttonTwo='NO' delete={true}
                            handleDeleteQueue={handleDeleteWidget}
                            handleDeleteQueueCancel={() => setShowDeleteWidgetModal(false)}
                            queueName={`selected widgets for  ${accountName} account`}
                        />
                    </DeleteModalPopup>}

                {(multiAccountDeleteModal)
                    && <DeleteModalPopup isOpen={multiAccountDeleteModal} setShowModal={showMultiAccountDeleteModal}>
                        <DeleteFragment modalName='Delete Widgets' buttonOne='YES' buttonTwo='NO' delete={true}
                            handleDeleteQueue={handleDeleteMultiWidget}
                            handleDeleteQueueCancel={() => showMultiAccountDeleteModal(false)}
                            queueName='all the widgets for selected accounts ?'
                        />
                    </DeleteModalPopup>}

                {(showCancelWidgetModal)
                    && <DeleteModalPopup isOpen={showCancelWidgetModal} setShowModal={setShowCancelWidgetModal}>
                        <DeleteFragment modalName='Cancel Custom Widgets' buttonOne='YES' buttonTwo='NO' delete={true}
                            handleDeleteQueue={handleCancelWidgets}
                            handleDeleteQueueCancel={() => setShowCancelWidgetModal(false)}
                            queueName='all the saved data ?'
                        />
                    </DeleteModalPopup>}
                {sureProceedModal &&
                    <DeleteModalPopup isOpen={sureProceedModal} setShowModal={setSureProceedModal}>
                        <DeleteFragment modalName='Proceed to Home Page' buttonOne='YES' buttonTwo='NO'
                            handleDeleteQueue={() => history.push('/welcome')}
                            handleDeleteQueueCancel={() => setSureProceedModal(false)}
                            delete={false}
                        />
                    </DeleteModalPopup>
                }
            </section>
        </>
    )
}
export default AccountSelect

