import React, { useState, useEffect } from "react";
import {Bar} from 'react-chartjs-2';
import "./bar-chart.css";


const EDashboardBarChart = (props) => {
    const { legends, analyticsChartValAddtnlData, analyticsChartValAddtnlTltpData } = props ? props : null; 

    const data={
        labels: props?.labels,
        datasets: [{
          backgroundColor: props?.bkgrndClr,
          borderColor: props?.bkgrndClr,
          borderWidth: 0,
          data: props?.chartData
        },{
          backgroundColor: 'rgb(246,248,250)',
          borderColor: 'rgba(246,248,250, 0.5)',
          borderWidth: 0,
          data: [100, 100, 100, 100, 100, 100]
        }],
    
    
      };

const tooltip = {
  displayColors: false,
  backgroundColor: 'white',
  bodyColor: 'black',
  titleMarginBottom: 0,
  borderColor: '#969696',
  borderWidth: 1,
  titleColor: '#63666A',
  titleAlign: 'center',
  titleFont: {weight: '400'},
  displayColors: false,
  padding: 10,
  cornerRadius: 0,
  caretSize:5,
  bodyFont: {weight: 'bolder'},
  callbacks:{
    title: function(context) {
      return (analyticsChartValAddtnlTltpData?.length> 0 ? analyticsChartValAddtnlTltpData[context[0].dataIndex] : null);
    },
    label: function(context){
      return (analyticsChartValAddtnlData?.length> 0 ? analyticsChartValAddtnlData[context.dataIndex] : null);;
    }
  }
};

const options={
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    plugins: {
      tooltip,
    },
    scales: {
        x: { 
        stacked: true,
        ticks: {
          beginAtZero: true,
          display: false
        },
        display: false
      },
      x1: {
        position: 'top',
      },
      y: {        
        display: true
      },
    }
};

    return (
        <div className="row">
            <div className="row bar-lgnd-contnr"  style={{height: '130px', maxWidth: '100%'}}>
            <ul id="bar-lgnd">   
                {legends.map((itm,i)=>{
                    return <li key={i}><div className="color-box" style={{backgroundColor: itm.color}}></div><span className="bar-lgnd-text">{itm.lbl}</span></li>
                })}
            </ul>
            </div>
            <div className="row" style={{height: '220px', maxWidth: '100%'}}>
            <Bar  options={options} data={data} 
            plugins={{
                beforeDraw: (chartInstance, easing) => {
                    var ctx = chartInstance.chart.ctx;
                    ctx.fillStyle = 'rgb(53, 53, 53)';
                    var chartArea = chartInstance.chartArea;
                    ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
                }
            }} />
            </div>
        </div>
    );
};

export default EDashboardBarChart;