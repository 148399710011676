import React, { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import hintsIcon from './../../../../assets/onboard-images/Hints_Icon.png'
import HINTS from './Hints.json';

export const HintsRoles = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className='icon_wrapper' onClick={handleShow} style={{ cursor: 'pointer' }}> <img src={hintsIcon} alt='hints' /> <span>Hints</span></div>
      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ marginTop: '1.5em' }}><span className='title'>{HINTS.ROLE_INFO.Title}</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: '#FFFFFF', border: ' 1px solid #ECEEF1' }}>
          <div className='queue-span'>  Usage  </div>
          <div className='queue-para'>
            {HINTS.ROLE_INFO.Usage.map((para, i) => (
              <div id='p1' key={i}> {para}</div>
            ))}
            <div className='queue-span'>  Legend  </div>
            {HINTS.ROLE_INFO.Legend.map((legend, i) => (
              <> <div className='queue-span-two'> {legend.title}</div>
                <div id='p1'>  {legend.info}</div>
              </>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}



export const HintsGeneralInfo= (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className='icon_wrapper' onClick={handleShow} style={{ cursor: 'pointer' }}> <img src={hintsIcon} alt='hints' /> <span>Hints</span></div>
      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ marginTop: '1.5em' }}><span className='title'>{HINTS.GENERAL_INFO.Title}</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: '#FFFFFF', border: ' 1px solid #ECEEF1' }}>
          <div className='queue-span'>  Usage  </div>
          <div className='queue-para'>
            {HINTS.GENERAL_INFO.Usage.map((para, i) => (
              <div id='p1' key={i}> {para}</div>
            ))}
            <div className='queue-span'>  Legend  </div>
            {HINTS.GENERAL_INFO.Legend.map((legend, i) => (
              <> <div className='queue-span-two'> {legend.title}</div>
                <div id='p1'>  {legend.info}</div>
              </>
            ))}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
