import React from 'react';

export function AdminViewDeleteModel(props) {
    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    {props.modalName}
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                <div className='delete-para-span'>
                Are you sure you want to delete the <b>{props.InformationText}</b> {props.AfterInfoText}
                </div>
            </div>
            <main className='input-container-five' style={{ marginLeft: '5em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleDeleteYes}> {props.buttonOne}
                </button>
                <button className='btn_canceluser' onClick={props.handleDeleteNo}> {props.buttonTwo} 
                </button>
            </main>
        </section>
    </>
}

export function AdminViewCannotDeleteModel(props) {
    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    {props.modalName}
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                <div className='delete-para-span'>
                {props.InformationText}
                </div>
            </div>
            <main className='input-container-five' style={{ paddingLeft:'10em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleClose}> CLOSE
                </button>
            </main>
        </section>
    </>
}