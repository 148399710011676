import API from "../../api";
let token = localStorage.getItem("token");
export const getAvailableEmployeesService = (requestUrl) => {
    return API.get(requestUrl, {
        headers: {
            Authorization: token,
        },
    })
        .then((resp) => resp)
        .catch((error) => error.response);
};

export const getSupervisorListService = (requestUrl) => {
    return API.get(requestUrl, {
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };
  
  export const updateNewUserRoleService = (requestUrl, id) => {
  
      return API.post(requestUrl, id, {
        headers: {
          Authorization: token,
        },
      })
        .then((resp) => resp)
        .catch((error) => error.response);
    };
  
    export const updateSupervisorEmployeeMappingService = (requestUrl, id) => {
  
      return API.post(requestUrl, id, {
        headers: {
          Authorization: token,
        },
      })
        .then((resp) => resp)
        .catch((error) => error.response);
    };
  
    export const updateAccountEmployeeService = (requestUrl, id) => {
  
      return API.post(requestUrl, id, {
        headers: {
          Authorization: token,
        },
      })
        .then((resp) => resp)
        .catch((error) => error.response);
    };

    export const getRoleCategoryService = (requestUrl) => {
      return API.get(requestUrl, {
          headers: {
              Authorization: token,
          },
      })
          .then((resp) => resp)
          .catch((error) => error.response);
  };

  export const getStaffAccountsService = (requestUrl) => {
    return API.get(requestUrl, {
        headers: {
            Authorization: token,
        },
    })
        .then((resp) => resp)
        .catch((error) => error.response);
};

export const getAccountAssignmentService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getQueueAssignmentService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getIdAssignmentService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getSlaAssignmentService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getVolumeAssignmentService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const updateQueueAssignmentService = (requestUrl, id) => {
  
  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateIdAssignmentService = (requestUrl, id) => {
  
  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateSlaAssignmentService = (requestUrl, id) => {
  
  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const updateVolumeAssignmentService = (requestUrl, id) => {
  
  return API.post(requestUrl, id, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getStaffPreviewService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getMappedEmployeesService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getQueueDataService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getAccountSLAService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getVolumeTemplatesService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getIDAccountsService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getSLAAccountsService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getValidSupervisorService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAccountSLAForEditService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};

export const getVolumeTemplatesForEditService = (requestUrl) => {
  return API.get(requestUrl, {
      headers: {
          Authorization: token,
      },
  })
      .then((resp) => resp)
      .catch((error) => error.response);
};