import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import "./Preview.css";
import deleteIcon from './../../../../assets/images/Delete_Icon.png'
import success from './../../../../assets/images/Success_Icon.png'
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { PreviewCard, PreviewNonvoiceCard } from './PreviewCard';
import { getPreviewOnboard } from '../../../../actions/Onboard.action';
import { useLocation } from 'react-router';
import DatePickerHandle from './../KeyboardDatePicker';
import { saveAccountSite } from './../../../../actions/Onboard.action';
import { KeyboardDatePicker } from '@material-ui/pickers';



const PreviewHandler = (props) => {
  const [file, setFile] = useState("");
  const [error, setError] = useState();
  const [datacard, setDatacard] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
  const previewOnboardData = useSelector((state) => state.onboardReducer.previewOnboardData);
  useEffect(() => {
    dispatch(getPreviewOnboard(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
  }, [dispatch])
  function handleUpload(event) {

    if (event.target.files[0].size > 1000000) {
      setError('please upload the file lessthan 1MB');
      setFile('');
      setDatacard(false);
    } else {
      dispatch({ type: "SETIMG", payload: event.target.files[0] })
      console.log(event.target.files[0])
      setFile(event.target.files[0]);
      setError('')
      setDatacard(true);
    }
  }
  function deleteHandler() {
    setFile('');
    setDatacard(false);
  }

  const previewToggleHandler = (value, tab) => {
    return value === 'Yes' ? true : false
  }
  const OnCheckPreviewContract = (event) => {
    const checked = event.target.checked;
    props.onCheckContractPreview(checked)
  }
  
  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  };
  const [ValidTo, setValidTo] = useState(new Date(accountSiteInfo?.ValidTo));

  const ValidationDatehandler = (valid, date) => {
    let temp_Arr = { ...accountSiteInfo };
    temp_Arr[valid] = formatDate(date);
   setValidTo(formatDate(date));
    dispatch(saveAccountSite(temp_Arr));
  }
  useEffect(() => {
    props.dispatchFileUpload(file)
}, [file,props])


  return (
    <div className='preview__width'>
      {location.pathname === '/onboardAccount' ? <><div className='Preview_head'>This page enables to view the previous steps filled status/update.<br />
        Don’t forget to save you work.</div><hr />
      <PreviewCard title='Roles' value={previewToggleHandler(previewOnboardData?.[0]?.Roles?.[0]?.Completed)} isDone={true} category={null} onClick={props.onClickViewDetails} table={false} />  <hr />
      <PreviewCard title='Voice' value={previewToggleHandler(previewOnboardData?.[0]?.Voice?.[0]?.Completed)} isDone={false} category='AWS (ASD) Telephony' onClick={props.onClickViewDetails} table={false} />  <hr />
      <div>
        <Row>
          <Col style={{ flex: 1 }}><div className='role__main'>Non Voice</div></Col>
          <Col style={{ flex: 3.5 }}>
            <PreviewNonvoiceCard value={previewToggleHandler(previewOnboardData?.[0]?.['Non Voice']?.[0]?.Completed)} title='Chat Tool' isDone={true} onClick={props.onClickViewDetails} table={false} />
            <PreviewNonvoiceCard value={previewToggleHandler(previewOnboardData?.[0]?.['Non Voice']?.[2]?.Completed)} title='Web Portal' isDone={true} onClick={props.onClickViewDetails} table={false} />
            <PreviewNonvoiceCard value={previewToggleHandler(previewOnboardData?.[0]?.['Non Voice']?.[4]?.Completed)} title='Customer Survey' isDone={true} onClick={props.onClickViewDetails} table={false} />
            <PreviewNonvoiceCard value={previewToggleHandler(previewOnboardData?.[0]?.['Non Voice']?.[6]?.Completed)} title='Workforce Management (Scheduling)' isDone={false} onClick={props.onClickViewDetails} table={false} />
          </Col>
          <Col style={{ flex: 3.5 }}>
            <PreviewNonvoiceCard value={previewToggleHandler(previewOnboardData?.[0]?.['Non Voice']?.[1]?.Completed)} title='Ticketing Tool' isDone={true} onClick={props.onClickViewDetails} table={false} />
            <PreviewNonvoiceCard value={previewToggleHandler(previewOnboardData?.[0]?.['Non Voice']?.[3]?.Completed)} title='Email' isDone={true} onClick={props.onClickViewDetails} table={false} />
            <PreviewNonvoiceCard value={previewToggleHandler(previewOnboardData?.[0]?.['Non Voice']?.[5]?.Completed)} title='Transaction Monitoring NON AQUAA' isDone={false} onClick={props.onClickViewDetails} table={false} />
          </Col>
        </Row>
      </div></> : 
      <>
      <div style={{marginTop:'30px',marginBottom:'30px'}}>
      <Row>
          <Col style={{ flex: 1.2 }}><div className='role__main'>Valid To</div></Col>
          <Col style={{ flex: 8 }}>
          <div className='role_preview'><b>NOTE</b>: For changes in valid to date to became effective, make sure you press “UPDATE” Button on this page.</div>
         <br/>
          <div >
          {/* <DatePickerHandle className='container-three'
                  label=''
                  value={ValidTo}
                  onChange={(date) => ValidationDatehandler('ValidTo', date)}
                /> */}
                <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    name='valid_from'
                    clearable
                    value={ValidTo}
                    placeholder="MM/DD/YYYY"
                    onChange={(date) => ValidationDatehandler('ValidTo', date)}
                    // minDate={new Date('01/01/2020')}
                    format="MM/DD/YYYY"
                />
          </div>
          </Col>
          </Row></div>
      </>
      }
      <hr />
      <div>
        <Row>
          <Col style={{ flex: 1.2 }}><div className='role__main'>Account Logo</div></Col>
          <Col style={{ flex: 8 }}>
            <div className='complete_preview'>Select a file to upload</div>
            <div className='role_preview'>You can upload only PNG, GIF and JPEG files. Maximum upload file size: 1MB.</div>
            <div id="upload-box">
              <input type="file" name="myImage" id='file' accept="image/png, image/gif, image/jpeg" onChange={handleUpload} style={{ display: 'none'}}/>
              <label for="file" style={{ cursor: 'pointer'}}> <span className='browse_link'>BROWSE </span>&nbsp; your files.</label>
            </div>
            <div style={{ color: 'red' }}>{error}</div>
            {datacard && <div className='card__upload'> <span>{file.name}</span>
              <span >{(file.size) / 1024} KB <img src={deleteIcon} alt='del' style={{ marginLeft: 20, cursor: 'pointer' }} onClick={deleteHandler} /></span>
            </div>}
          </Col>
        </Row>
      </div>
      <hr />
     {location.pathname === '/onboardAccount' ? <div>
        <input type='checkbox' name='final' id='final' onChange={OnCheckPreviewContract} />
        <label for='final' className='contracts__preview'>Contracts must include requirements to protect information in accordance with
          all applicable laws and data owner policies.</label>
      </div> : ''}

      <br />
      <br />
    </div>
  )
}
export default PreviewHandler