const ConstructRegionalSiteURLs = (type, val, dt) => {
  console.log('val',val)
  switch (type) {
    case 'GET_REGIONALSITE_CUSTID_DATA': {
      return `/api/org/hierarchy/me?date=${dt}&customerid=${val}`;
    }
    case 'GET_REGIONALSITE_GLOBAL_SUMMARY_DATA': {
      return `/api/metrics/legacydtm/${val.cust_id}/${val.serv_id}/${val.regn_id}?month=${dt}&tabname=Summary&hint=${val.regn_id ? 'regional' : 'global'}`
    }
    case 'DOWNLOAD_REGIONALSITE_GLOBAL_SUMMARY_DATA': {
      return `${process.env.REACT_APP_BASE_URL}/api/metrics/legacydtm/${val.cust_id}/${val.serv_id}/${val.regn_id}?month=${dt}&tabname=Summary&format=xlsx`
    }
    case 'GET_DTM_ANALYTICS':
    case 'GET_DTM_ANALYTICS_HALF_HOUR':
    case 'GET_DTM_ANALYTICS_MONTH': {
      console.log(val.date, val.view)
      return `/api/usp/DTMData?CustomerID=${val.customerID}&ServiceDeskID=${val.serviceID}&SLA=${val.item}&Month=${val.date}&View=${val?.view}`
    }
    default: { }
  }
}

export default ConstructRegionalSiteURLs;