import React, { useEffect } from 'react';
import Accounts from '../../../assets/admin-images/Accounts.png';
import Roles from '../../../assets/admin-images/Roles.png';
import AccessGroups from '../../../assets/admin-images/AccessGroups.png';
import Operations from '../../../assets/admin-images/Operations.png';
import Users from '../../../assets/admin-images/Users.png';
import Admin_Reports from '../../../assets/admin-images/Admin_Reports.png';
import Arrow_Normal from '../../../assets/admin-images/Arrow_Normal.png';
import Audit_RBAC from '../../../assets/admin-images/Audit_RBAC.png';
import AuditRole from '../../../assets/admin-images/AuditRole.png';
import { useHistory } from 'react-router-dom';
import AdminBreadcrumb from '../General/Admin-breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessGroupData, getAdminRoles, getOperationsData, getUserflowCount } from '../../../actions/Administration/adminView.action';
import { getRolesTabUsers } from '../../../actions/Onboard.action';
import { getAdminAccounts } from '../../../actions/Administration/admin.action';




const AdminViewFirstPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let AdminAccounts = useSelector((state) => state.adminAccountReducer.AdminAccounts);
  let adminViewRoleData = useSelector((state) => state.adminViewReducer.adminViewRoleData);
  let accessGroupMainData = useSelector((state) => state.adminViewReducer.accessGroupMainData);
  let operationsMainData = useSelector((state) => state.adminViewReducer.operationsData);
  let userflowCount = useSelector((state) => state.adminViewReducer.userflowCount);

useEffect(() => {
  dispatch(getAdminAccounts());
  dispatch(getAdminRoles());
  dispatch(getAccessGroupData());
  dispatch(getOperationsData());
  dispatch(getUserflowCount(''));
}, [dispatch]);

const Developer_Cards = [
  { id: 1,
    title: 'Accounts',
    value: AdminAccounts.length,
    imgsrc: Accounts,
    path:'/admin'
  },
  {
    id: 2,
    title: 'Roles',
    value: adminViewRoleData.length,
    imgsrc: Roles,
    path:'/admin/roles'
  },
  {
    id: 3,
    title: 'Access Groups',
    value: accessGroupMainData.length,
    imgsrc: AccessGroups,
    path:'/admin/accessgroup'
  },
  {
    id: 4,
    title: 'Operations',
    value: operationsMainData.length,
    imgsrc: Operations,
    path:'/admin/operations'
  },
  {
    id: 5,
    title: 'Users',
    value: userflowCount?.[0]?.count,
    imgsrc: Users,
    path:'/admin/users'
  },
  {
    id: 6,
    title: 'Reports',
    imgsrc: Admin_Reports,
    path:'/regional'
  },
  {
    id: 7,
    title: 'Audit Role',
    imgsrc: AuditRole,
    path:'/auditrole'
  },
  {
    id: 8,
    title: 'Audit RBAC',
    imgsrc: Audit_RBAC,
    path:'/auditrbac'
  }
]
    
  return (
    <section>
    <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={false} label2='Administration' />
    <header className='administration_text'>Administration</header>
    <section>
    <div className='grid_admin'>
          {Developer_Cards.map((link, index) => (<div class="grid_item_admin" onClick={()=>history.push(link.path)}>
          <main style={{flex:2}}><img src={link.imgsrc} alt=''/></main>
          <main style={{flex:4}}><div className='grid_admin_text'>{link.title}</div><div className='grid_admin_value'>{link.value}</div></main>
          <main style={{flex:0.75, marginTop:'10px',paddingLeft:'50px'}}><img src={Arrow_Normal} alt=''/></main>
          </div>))}
        </div>
    </section>
    
    </section>
    
  )
}

export default AdminViewFirstPage