import API from "../api";

let token = localStorage.getItem("token");
export const getRegionalSiteDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getRegionalSiteCustIdDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getDTMAnalyticsDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    }
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};




