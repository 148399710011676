import React from 'react'
import TotalCalls from "../../../assets/images/TotalCalls_Icon.png"
import Duration from "../../../assets/images/Duration_Icon.png"
import Cost from "../../../assets/images/Cost_Icon.png"
import './Financeinfo.css'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
const Financeinfo = (props) => {
  const totalCalls = props.tabData?.tiles?.[0];
const duration_minutes = props.tabData?.tiles?.[1];
const costPerCall = props.tabData?.tiles?.[2];
const costPerMinute=props.tabData?.tiles?.[3];
 return (
    <div className='info-container'>
      <div className='style'>
        <Row className="d-flex">
          <Col className='border1'>
            <div className="d-flex">
              <Col><img className='img1' src={TotalCalls} alt="none"></img></Col>
              <Col><span><span className='text1'>{totalCalls?.caption}</span> <br />
               <span className='value1'>{totalCalls?.value}</span></span></Col>
            </div>
          </Col>
          <Col className='border2'>
            <div className='d-flex'>
              <Col className='flex1'><img className='img2' src={Duration} alt="none"></img></Col>
              <Col className='flex2'><span><span className="text2">
                {duration_minutes?.caption}
                </span>
               <br /> <span className='value2'>{duration_minutes?.value}</span></span></Col>
            </div>
          </Col>
          <Col className='border3'>
            <div className="d-flex">
              <Col className='flex3'>
                <span><img className='img3' src={Cost} alt="none" ></img></span></Col>
              <Col className='flex4' >
                <span className='text3'>{costPerCall?.caption}</span> <br />
                <span className='value3'> {costPerCall?.value}</span>
              </Col>
              <Col className='flex5'>
                <span className='text4'>{costPerMinute?.caption}</span><br />
                <span className='value4'>{costPerMinute?.value}</span>
              </Col>
            </div>
          </Col>
        </Row>
      </div>

    </div>
  );
}
export default Financeinfo