import React, {useState } from 'react';
import './../QueueTable/TableTemplate.css'
import { OnboardSelect } from '../Dropdown/Select';
import DatePickerHandle from '../KeyboardDatePicker';
import { TimeOutSelect } from '../Dropdown/TimeOutSelect';

export function AddRoleModal(props) {
    const [ValidFrom, setValidFrom] = useState(null);
    const [ValidTo, setValidTo] = useState(null);
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    const editMode = props.editMode;

    const ValidFromDateHandler = (date) => {
        if (editMode){
            props.handleRoleEditDate('valid_from', ((date === null || date === undefined) ? null : formatDate(date)))
        }
        else{
        props.addDateToRole('valid_from', ((date === null || date === undefined) ? null : formatDate(date)))
        setValidFrom((date === null || date === undefined) ? null : formatDate(date));
        }

    }
    const ValidToDateHandler = (date) => {
        if (editMode){
            console.log('valid_to',date)
              props.handleRoleEditDate('valid_to', ((date === null || date === undefined) ? null : formatDate(date)))
             }
              else{
              props.addDateToRole('valid_to', ((date === null || date === undefined) ? null : formatDate(date)))
        setValidTo((date === null || date === undefined) ? null : formatDate(date));
    }
    }
    // const [usernameData, setUsernameData] = useState([]);
    // useEffect(() => {
    //     setUsernameData(props.usernameInfo)
    // }, [props.usernameInfo])
    // console.log(usernameData)
    return (
        <>
            <section className='add-role-container'>
                <div style={{ marginLeft: '3em' }}>
                    <div className='add-row-span'>
                        {props.modalName}
                    </div>
                    <div style={{ color: '#ED4F32' }}>{props.validation}</div>
                    <form onSubmit={props.handleAddRoleSubmit} >
                        <main className='input-container-three'>
                            <div className='container-one'>
                                <label HtmlFor="role">Role *</label>
                                <OnboardSelect data={props.rolenameInfo} name='role' value='RoleName' id='RoleId'
                                    editValue={editMode ? props.editRoleInfo.role : ""} addValueToQueue={props.addValueToRole} />
                            </div>
                            <div className='container-one'>
                                <label HtmlFor='username'>User *</label>
                                <TimeOutSelect data={props?.usernameInfo} name='username' value='nameEmail' id='id'
                                    QueryHandler={props.QueryHandler}
                                    editValue={editMode ? props.editRoleInfo.username : ""}
                                    addValueToQueue={props.addValueToRole} />
                            </div>
                        </main>
                        <main className='input-container-three'>
                            <DatePickerHandle className='container-one' label='Valid From'
                                value={editMode ? ((props.editRoleInfo.valid_from === null || props.editRoleInfo.valid_from === undefined) ? null : props.editRoleInfo.valid_from) : ValidFrom} width='380px'
                                onChange={(date) => ValidFromDateHandler(date)}
                            />
                            <DatePickerHandle className='container-one' label='Valid To'
                                value={editMode ? ((props.editRoleInfo.valid_to === null || props.editRoleInfo.valid_to === undefined) ? null : props.editRoleInfo.valid_to) : ValidTo} width='380px'
                                onChange={(date) => ValidToDateHandler(date)}
                            />
                        </main>

                        <main className='input-container-four'>
                            <button type='submit' className='btn_adduser'> {props.buttonOne} </button>
                            <button className='btn_canceluser' onClick={props.handleAddRoleCancel}> {props.buttonTwo}  </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}