const EDashboardURLs = (name, dt, rgn, lctn) => {
    switch (name) {
      case 'GET_KPI_DATA': {
        return constructURL("/api/usp/EDashboardPart", dt, rgn, lctn);
      };
      case 'GET_COMPLIANCE_DATA': {
        return constructURL("/api/usp/EDashboardStart", dt, rgn, lctn);
      };
      case 'GET_CONTACT_VOLUME_DATA': {
        return constructURL("/api/usp/EDContactVolume", dt, rgn, lctn);
      }    
      case 'GET_CALLTYPE_DATA': {
        return hintConstructUrls("/api/usp/EDashboardCallType", dt, rgn, lctn);
      }  
      case 'GET_HRZNTLBARCHRT_DATA': {
        return constructURL(`/api/usp/EDCallsAccountLanguage`, dt, rgn, lctn);
      }
      case 'GET_PROFITLOSSANALYTICS_DATA': {
        return constructURL(`/api/usp/EDProfitLossAnalysis`, dt, rgn, lctn);
      }
      case 'GET_YEARMONTHREGION_DATA': {
        return `/api/usp/EDMasters`;
      }
      case 'GET_TOTALINCIDENT_CARDS_DATA': {
        return constructURL(`/api/usp/EDIncidents`, dt, null);
      }
      case 'GET_EDASHBRD_NOTFN_TEXT':  {
        return `/api/usp/EDashboard_Notice`;
      }
      default: {}
    }
  }

  const constructURL = (url, dt, rgn, lctn) => {
    let temp_URL = url;
    if(dt && rgn && lctn)
    return `${url}?Month[]=${dt}&REGION[]=${rgn}&locationgroup[]=${lctn}`;
    else{
        if(dt) temp_URL = `${url}?Month[]=${dt}`;
        if(temp_URL?.indexOf('?')>-1){
          if(rgn) temp_URL += `&REGION[]=${rgn}`
        }else{
          if(rgn) temp_URL = `${url}?REGION[]=${rgn}`;
        }
        if(temp_URL?.indexOf('?')>-1){
          if(lctn) temp_URL += `&locationgroup[]=${lctn}`
        }else{
          if(lctn) temp_URL = `${url}?locationgroup[]=${lctn}`;
        }
        return temp_URL;
    } 
  };
  
  export default EDashboardURLs;


  const hintConstructUrls=(url,dt, rgn)=>{
    if(dt && rgn)
    return `${url}?Month[]=${dt}&REGION[]=${rgn}&hint=eDashboard`;
    else if(dt && !rgn)
      return `${url}?Month[]=${dt}&hint=eDashboard`;
    else if(!dt && rgn)
    return `${url}?REGION[]=${rgn}&hint=eDashboard`;
    else
      return `${url}?hint=eDashboard`;
  }
//   https://gsd-insight-dev.houston.dxccorp.net/api/usp/EDashboardStart
// https://gsd-insight-dev.houston.dxccorp.net/api/usp/EDashboardPart
//https://gsd-insight-dev.houston.dxccorp.net/api/usp/EDContactVolume