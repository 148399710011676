import ReactModal from "react-modal";
import "../QueueTable/TableTemplate.css";
import Success_Icon from '../../../../assets/onboard-images/Success_Icon.png'
import DatePickerHandle from "../KeyboardDatePicker";
import { useState } from "react";
import { useLocation } from 'react-router';

ReactModal.setAppElement('#root')
export function DeleteFragment(props) {
    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    {props.modalName}
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                {props.delete === true ? <div className='delete-para-span'>
                    Are you sure you want to delete <span className="queue-span">{props.queueName} - {props.sitename} ?</span>
                </div> :
                    <div className='delete-para-span'> You have not selected any accounts.
                        Are you sure you want to procced to <span className="queue-span"> HOME PAGE</span>
                    </div>}
            </div>
            <main className='input-container-five' style={{ marginLeft: '5em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleDeleteQueue}> {props.buttonOne}
                </button>
                <button className='btn_canceluser' onClick={props.handleDeleteQueueCancel}> {props.buttonTwo} 
                </button>
            </main>
        </section>
    </>
}

export function AccountDeleted(props) {
    return <>
        <section className="delete-success-container">
            <div style={{ marginLeft: '3em', marginTop: '2em' }}>
                <div className='delete-row-span' style={{ marginTop: '3em' }}>
                    {props.accountName} - Account
                </div>
            </div>
            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                <div className='delete-para-span'>
                    <img src={Success_Icon} alt='' />  &nbsp; &nbsp;   <span className="queue-span " style={{ font: 'normal normal bold 24px/29px Open Sans', marginTop: 10 }}> Success</span>
                </div>
                <div className='delete-para-span' style={{ marginTop: '10px',width:'450px' }}>
                    <span className="queue-span"> {props.accountName} - {props.sitename}</span> account deleted successfully.
                   
                </div>
            </div>
            <main className='input-container-five' style={{ marginLeft: '10em',marginTop:'80px' }}>
                <button className='btn_canceluser' onClick={props.handleClose}> CLOSE 
                </button>
            </main>
        </section>
    </>
}

export function DeletedModalPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '40%',
                    left: '35%',
                    width: '500px',
                    height: '240px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}





export function DeleteModalPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '40%',
                    left: '35%',
                    width: '500px',
                    height: '240px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}





export function SuccessFragment(props) {
    const location = useLocation();
    return <>
        <section className="success_model_container">
            <div style={{ marginLeft: '3em', marginTop: '4em' }}>
                <div className='delete-row-span' style={{ marginTop: '1em' }}>
                    {props.modalName}
                </div>
            </div>

            <div className='delete-para' style={{ marginLeft: '3em', marginBottom: '1em' }}>
                <div className='delete-para-span'>
                    <img src={Success_Icon} alt='' />  &nbsp; &nbsp;   <span className="queue-span " style={{ font: 'normal normal bold 24px/29px Open Sans', marginTop: 10 }}> Success</span>
                </div>
                <div className='delete-para-span' style={{ marginTop: '10px',width:'520px' }}>
                    <span className="queue-span"> {props.queueName}</span> account submitted successfully.
                   <div> {props.ReopenText}</div>
                </div>
            </div>
            <br /><br />
            <main className='input-container-five' style={location.pathname === '/onboardAccount' ? { marginLeft: '12em',marginTop:'5em' } : { marginLeft: '12em',marginTop:'2em' }}>
                <button type='submit' className='btn_adduser' onClick={props.handleSuccessAccount}> {props.buttonOne}
                </button>
            </main>
        </section>
    </>
}

export function OnboardRequestAdmin(props) {
    return <>
        <section className="delete-row-container">
            <div style={{ marginLeft: '3em', marginTop: '4em' }}>
                <div className='delete-row-span' style={{ marginTop: '1em' }}>
                    {props.requestHeading}
                </div>
            </div>
            <div className='delete-para extra-delete-para' style={{ marginLeft: '3em', marginBottom: '1em', letterSpacing: '-0.2px' }} >
                The <b>{props.accountName}</b> account re-opening request has been received successfully. You will receive an Email
                confirmation from the GSD Insight Administrator to your registered mail ID <strong>({props.email})</strong>
            </div>
            <br /><br />
            <main className='input-container-five' style={{ marginLeft: '12em', marginTop: '40px' }}>
                <button type='submit' className='add-button' onClick={props.onClick} >
                    <span className='add-span' >CLOSE</span>
                </button>
            </main>
        </section>
    </>
}



export function AdminValidDateChange(props) {
    const [validUntil, setValidUntil] = useState('');
    const [validation, setValidation] = useState('');
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }

    const ValidToDateHandler = (date) => {
        let d = formatDate(date)
        setValidUntil(d);
    }
    const onsubmit = () =>{
        if(validUntil){
            setValidation('')
            props.onSubmit(validUntil)
        }
        else{
            setValidation('Please Ensure you filled the inputs with  " * " mark ')
            props.notValidDate()
        }
    }
    return <>
        <section className="delete_row_container_activation">
            <div style={{ marginLeft: '3em', marginTop: '4em' }}>
                <div className='delete-row-span' style={{ marginTop: '0.2em' }}>
                    {props.requestHeading}
                </div>
                <div style={{ color: '#ED4F32'}}> {validation} </div>
                <div className="queue_name">{props.title1}: <span id='name'>{props.solution1}</span></div>
                <div className="queue_name">{props.title2}<span id='name'>{props.solution2}</span></div>
                <div className="date_picker">
                    <DatePickerHandle className='container-three' label='Valid To *'
                        value={validUntil}
                        onChange={(date) => ValidToDateHandler(date)}
                    />
                </div>
            </div>

            <main className='input-container-five' style={{ marginLeft: '5em' }}>
                <button type='submit' className='btn_adduser' onClick={onsubmit 
              //  props.onSubmit(validUntil)
                }>
                    SUBMIT
                </button>
                <button className='btn_canceluser' onClick={props.onCancel}>
                    CANCEL 
                </button>
            </main>
        </section>
    </>
}