import React, { useState } from 'react';
import "./eDashboardfilter.css";
import '../../ReportTables/ReportFilters/ReportFilters.css';
import filterIcon from '../../../assets/images/Filter_Icon.png';
import downloadIcon from '../../../assets/images/Download.png';
import RefreshIcon from '../../../assets/images/Reset_Icon.png';
import Button from '../../ReportTables/ReportFilters/Button';
import Dropdown from '../../ReportTables/ReportFilters/Dropdown';
import CustomSelect from "../../custom-select/custom-select";
import reportConstructURLs from '../../../constants/reportURL';
import { useLocation } from 'react-router-dom';
import ConstructURLs from '../../../constants/urls';
function EDashboardFilter(props) { 
  const [slctVal, setSlctVal] = useState('');
  const [slctValRgn, setSlctValRgn] = useState('');
  const [slctValLcnt, setSlctValLcnt] = useState('');
  const [yrMnSlctVal, setYrMnthSlctVal] = useState();
  const [rgnSlctVal, setRgnSlctVal] = useState(); 

  const refreshIconHandler =()=>{   
    setSlctVal('SELECT');
    setSlctValRgn('SELECT');
    setSlctValLcnt('SELECT');
    props.refreshHandler();   
  };

  const selectedDrpDwnYrMnthHandler = (e) => {
    setYrMnthSlctVal(e);
    setSlctVal(e);
    props?.selectedDrpDwnYrMnthHandler(e);
  }

  const selectedDrpDwnRegionHandler = (e) => {
    setRgnSlctVal(e);
    setSlctValRgn(e);
    props?.selectedDrpDwnRegionHandler(e);
  }

  const selectedDrpDwnLocationGroupHandler = (e) => {
    setSlctValLcnt(e);
    props?.selectedDrpDwnLocationHandler(e);
  }

  return (
    <React.Fragment>
      <div className='filter-tab-eDash'>
        <span className='filter-icon-eDash'>
          <img className='filter-image' src={filterIcon} alt="filter" />
        </span>
        <section className='edashboard-filter-container'>
        <div className='dropdown-period'>
          <p className='year-month'>Year & Month</p>         
            <CustomSelect options={props?.yrMnthDt} selectedVal={slctVal} isSearchable={true} selectedRegionHandler={selectedDrpDwnYrMnthHandler}/>
       </div> 
        <div className='dropdown-regions extra-location'>
          <p className='pl-region'>P & L Region</p>          
            <CustomSelect options={props?.rgnDt} selectedVal={slctValRgn} isSearchable={false} selectedRegionHandler={selectedDrpDwnRegionHandler}/>
        </div>
        <div className='dropdown-regions extra-location'>
          <p className='pl-region'>Location Group</p>          
            <CustomSelect options={props?.lcntDt} selectedVal={slctValLcnt} isSearchable={false} 
            selectedRegionHandler={selectedDrpDwnLocationGroupHandler} />
        </div>
        </section>
          <div className='container-reset' >
          <Button className={`reset-icon download-btn `} item='Refresh Table'
           onClick={refreshIconHandler}  icon={RefreshIcon} />
          <div className='download-icon'>
            <Button className='download-btn' item='Download Report'               
              onClick={()=>{ 
                if(yrMnSlctVal && rgnSlctVal)
                window.location.href = `${process.env.REACT_APP_BASE_URL}/api/usp/EDashboardCallType?month[]=${yrMnSlctVal}&region[]=${rgnSlctVal}&format=excel&filename=EDashboard_excel_report.xlsx`;
                else if(yrMnSlctVal && !rgnSlctVal)
                window.location.href = `${process.env.REACT_APP_BASE_URL}/api/usp/EDashboardCallType?month[]=${yrMnSlctVal}&format=excel&filename=EDashboard_excel_report.xlsx`;
                else if(!yrMnSlctVal && rgnSlctVal)
                window.location.href = `${process.env.REACT_APP_BASE_URL}/api/usp/EDashboardCallType?region[]=${rgnSlctVal}&format=excel&filename=EDashboard_excel_report.xlsx`;
                else
                window.location.href = `${process.env.REACT_APP_BASE_URL}/api/usp/EDashboardCallType?format=excel&filename=EDashboard_excel_report.xlsx`;
                }}  icon={downloadIcon} />            
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EDashboardFilter;












