import React, { useEffect, useState } from 'react'
import './SupportHours.css';
import Information_Icon from './../../../../assets/onboard-images/Information_Icon.png'
import TooltipHandler from '../TooltipHandler';
import { OnboardSelect } from '../Dropdown/Select';
import { useDispatch, useSelector } from 'react-redux';
import { getQueueTabLanguages } from '../../../../actions/Onboard.action';
import DatePickerHandle from '../KeyboardDatePicker';

function SupportToggle(props) {

    const [isActive, setIsActive] = useState(props.isSelected)
    const { editValue } = props;
    useEffect(() => {
        setIsActive(editValue === true || editValue === "true")
    }, [editValue]);
    const toggleClickHandle = (event) => {
        setIsActive(!isActive)
        props.toggleHandler(event, !isActive);
    }
    let className = isActive === true ? 'switch active' : 'switch ';
    return (
        <React.Fragment>
            <section className={props.className} >
                <label className={className}>
                    <input
                        value={props.widgetName}
                        type='checkbox'
                        checked={isActive}
                        onChange={toggleClickHandle}
                        {...props}
                    />
                    <span className='slider' />
                </label>
            </section>
        </React.Fragment>
    )
}
const AddSupportModal = (props) => {
    const dispatch = useDispatch()
    let languageInfo = useSelector((state) => state.onboardReducer.queueTabLanguages);
    useEffect(() => {
        dispatch(getQueueTabLanguages());
    }, [dispatch])

    const [ValidFrom, setValidFrom] = useState(new Date(''));
    const [ValidTo, setValidTo] = useState(new Date(''));
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    const ValidFromDateHandler = (date) => {
        setValidFrom(formatDate(date));
        props.addDateToSupport('valid_from', formatDate(date))
    }
    const ValidToDateHandler = (date) => {
        setValidTo(formatDate(date));
        props.addDateToSupport('valid_to', formatDate(date))
    }
    const editMode = props.editMode;

    return (
        <div>
            <section className='new-template-container-SLA'>
                <div style={{ marginLeft: '3em' }} >
                    <div className='add-row-span'>
                        <div id='modal-name'> {props.modelName}</div>
                    </div>
                    {props.validation && <div style={{ color: '#ED4F32' }}>{props.validation}</div>}
                    <form onSubmit={editMode ? props.handleEditSupportSubmit : props.handleAddSupportSubmit}>
                        <section className='scrollable_Section' style={{ height: '300px' }}>
                            <main className='input-container-three'>
                                <div className='container-three'>
                                    <label style={{ display: 'flex' }}>Start time &nbsp; &nbsp;
                                        <span style={{ position: 'relative' }} >
                                            <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right' arrowAlign='start'
                                                textBoxWidth='300px' info={true} text='Please define Time in accordance with "HH:MM" format.' />
                                        </span>
                                    </label>


                                    {editMode ? <input type='text'
                                        name='start_time'
                                        value={props.editSupportInfo.start_time}
                                        placeholder='01:23'
                                        onChange={props.handleFormAddSupport}
                                    /> :
                                        <input type='text'
                                            name='start_time'
                                            placeholder='01:23'
                                            onChange={props.handleFormAddSupport}
                                        />}
                                </div>
                                <div className='container-three'>
                                    <label style={{ display: 'flex' }}>End time &nbsp; &nbsp;
                                        <span style={{ position: 'relative' }} >
                                            <TooltipHandler src={Information_Icon} width='14px' height='14px' position='bottom right' arrowAlign='start'
                                                textBoxWidth='300px' info={true} text='Please define Time in accordance with "HH:MM" format.' />
                                        </span>
                                    </label>                                {editMode ? <input type='text'
                                        name='end_time'
                                        value={props.editSupportInfo.end_time}
                                        placeholder='22:59'
                                        onChange={props.handleFormAddSupport}
                                    /> :
                                        <input type='text'
                                            name='end_time'
                                            placeholder='22:59'
                                            onChange={props.handleFormAddSupport}
                                        />}
                                </div>
                                <div className='container-three'>
                                    <label className='extra-lang'>Language *</label>
                                    <OnboardSelect data={languageInfo} name='language' value='Language' id='LangID'
                                        editValue={editMode ? props.editSupportInfo.language : ''} addValueToQueue={props.addValueToSupport}
                                    />
                                </div>
                            </main>
                            <main className='input-container-three'>
                                <DatePickerHandle className='container-three' label='Valid From *' id='label'
                                    value={editMode ? props.editSupportInfo.valid_from : ValidFrom}
                                    onChange={(date) => editMode ? props.handleSupportEditDate('valid_from', formatDate(date)) : ValidFromDateHandler(date)}
                                />
                                <DatePickerHandle className='container-three' label='Valid To *' id='label'
                                    value={editMode ? props.editSupportInfo.valid_to : ValidTo}
                                    onChange={(date) => editMode ? props.handleSupportEditDate('valid_to', formatDate(date)) : ValidToDateHandler(date)}
                                />
                            </main>

                            <main>
                                <section className='week-days'>
                                    <div className='week-div'>
                                        <div className='week-span'>Monday</div>
                                        <SupportToggle className='toggle' toggleHandler={props.handleToggleSupport} name="mondays" editValue={props.editSupportInfo.mondays} />
                                    </div>
                                    <div className='week-div'>
                                        <div className='week-span'>Tuesdays</div>
                                        <SupportToggle className='toggle' toggleHandler={props.handleToggleSupport} name="tuesdays" editValue={props.editSupportInfo.tuesdays} addValue={false} />
                                    </div>
                                    <div className='week-div'>
                                        <div className='week-span'>Wednesdays</div>
                                        <SupportToggle className='toggle' toggleHandler={props.handleToggleSupport} name="wednesdays" editValue={props.editSupportInfo.wednesdays} />
                                    </div>
                                    <div className='week-div'>
                                        <div className='week-span'>Thursdays</div>
                                        <SupportToggle className='toggle' toggleHandler={props.handleToggleSupport} name="thursdays" editValue={props.editSupportInfo.thursdays} />
                                    </div>
                                    <div className='week-div'>
                                        <div className='week-span'>Fridays</div>
                                        <SupportToggle className='toggle' toggleHandler={props.handleToggleSupport} name="fridays" editValue={props.editSupportInfo.fridays} />
                                    </div>
                                    <div className='week-div'>
                                        <div className='week-span'>Saturdays</div>
                                        <SupportToggle className='toggle' toggleHandler={props.handleToggleSupport} name="saturdays" editValue={props.editSupportInfo.saturdays} />
                                    </div>
                                    <div className='week-div'>
                                        <div className='week-span'>Sundays</div>
                                        <SupportToggle className='toggle' toggleHandler={props.handleToggleSupport} name="sundays" editValue={props.editSupportInfo.sundays} />
                                    </div>
                                </section>
                            </main>
                        </section>
                        <main className='input-container-four extra-four ' style={{ marginTop: '20px', height: '65px' }}>
                            <button type='submit' className='btn_adduser'> {props.buttonOne}  </button>
                            <button className='btn_canceluser' onClick={props.handleAddSupportCancel}> {props.buttonTwo}  </button>
                        </main>

                        <div>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    )
}
export default AddSupportModal;


