import React, { Fragment, useEffect, useState } from 'react'
import searchIcon from './../../../assets/onboard-images/Search_Icon.png'
import editIcon from './../../../assets/onboard-images/Edit_Icon.png'
// import deleteIcon from './../../../assets/onboard-images/Delete_Icon.png'
import TooltipHandler from './../../../components/Onboard/OnboardComponents/TooltipHandler'
import { useDispatch, useSelector } from 'react-redux'
import { FileUploadFragment, FileUploadModalPopup } from './UploadModal'
import { getFileUploadData } from '../../../actions/uploads.action'
import { getRoleBasedView } from '../../../actions/Administration/admin.action'

// const DATA = [
//     {
//         fileName: 'AbandonRateExcelReport',
//         fileType: 'CSV',
//         fileSize: '145KB',
//         uploadedDate: "11/12/2021",
//         period: 'Day - 09/05/2022',
//         uploader: "GHOSH, AMITH (amith.ghosh@dxc.com)",
//     },

//     {
//         fileName: 'GlobalSummaryReport_AUDI',
//         fileType: 'CSV',
//         fileSize: '455KB',
//         uploadedDate: "09/05/2022",
//         period: 'Week - 01/05/2022 to 07/05/2022',
//         uploader: "GHOSH, AMITH (amith.ghosh@dxc.com)",
//     },

//     {
//         fileName: 'OnboardAccountRetail',
//         fileType: 'CSV',
//         fileSize: '768KB',
//         uploadedDate: "24/02/2022",
//         period: 'Month - April 2022',
//         uploader: "GHOSH, AMITH (amith.ghosh@dxc.com)",
//     },
//     {
//         fileName: 'AdminRequestGSD_Insight',
//         fileType: 'XLSX',
//         fileSize: '346KB',
//         uploadedDate: "05/04/2022",
//         period: 'Custom Period - 01/05/2022 to 11/05/2022',
//         uploader: "GHOSH, AMITH (amith.ghosh@dxc.com)",
//     },]

const HEADERS = [
    { id: 1, caption: "File Name" },
    { id: 2, caption: "Format" },
    { id: 3, caption: "Size" },
    { id: 4, caption: "Uploaded Date" },
    { id: 5, caption: "Period" },
    { id: 6, caption: "Created By" },
]


function FileUploadhandler() {
    const dispatch = useDispatch();
    //  let userInfo = useSelector((state) => state.userReducer.userInfo);
    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
    const FileUploadData = useSelector((state) => state.uploadReducer.FileUploadData);
    const SelectedVoiceNonVoiceItem = useSelector((state) => state.uploadReducer.SelectedVoiceNonVoiceItem)
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch])

    const [fileUploadData, setFileUploadData] = useState([]);

    useEffect(() => {
        if (SelectedVoiceNonVoiceItem) {
            dispatch(getFileUploadData(SelectedAccountDetails.CustomerID ? SelectedAccountDetails.CustomerID : '', SelectedAccountDetails.ServiceDeskID ? SelectedAccountDetails.ServiceDeskID : '', SelectedVoiceNonVoiceItem))
        }
    }, [dispatch, SelectedAccountDetails, SelectedVoiceNonVoiceItem]);

    useEffect(() => {
        setFileUploadData(FileUploadData)
    }, [FileUploadData])
    console.log('FileUploadData',FileUploadData)

    const [filter, setFilter] = useState('');
   // const [searchColumns, setSearchColumns] = useState(['File Name', 'Format', 'Period']);
    let searchColumns = ['File Name', 'Format', 'Period']
    function searchRows(rows) {
        return rows.filter((row) =>
            searchColumns.some(
                (column) =>
                    row[column]
                        .toString()
                        .toLowerCase()
                        .indexOf(filter.toLowerCase()) > -1,
            ),
        );
    };

  //  const [FileOriginData, setFileOriginData] = useState(DATA)
    const [showAddFileModal, setShowAddFileModal] = useState(false);
    const [addFileUpload, setAddFileUpload] = useState({});
   

  //  const [editFileModal, showEditFileModal] = useState(false);

    // const selectedPeriodHandle = (value) => {
    //     let perAr = value.split(' - ');
    //     switch (perAr[0]) {
    //         case 'Day':
    //         case 'Month': return perAr
    //         default: return [perAr[0], ...perAr[1].split(' to ')]
    //     }
    // }

    const [EditMode,setEditMode] = useState(false)
    const onClickEditRow = (index, data) => {
        setEditMode(true)
       // setEditFileID(index);
        setAddFileUpload(data);
        setShowAddFileModal(true);
    }



    // const handleFileEditSubmit = () => {
    //     const newFile = {
    //         fileName: addFileUpload.fileName,
    //         fileType: addFileUpload.fileType,
    //         fileSize: addFileUpload.fileSize,
    //         uploadedDate: addFileUpload.uploadedDate,
    //         period: addFileUpload.period,
    //         uploader: addFileUpload.uploader,
    //     }
    //     const newFiles = [...FileOriginData];
    //     const index = FileOriginData.findIndex((_, index) => index === editFileID);
    //     newFiles[index] = newFile;
    //     setFileOriginData(newFiles);
    //     console.log(newFiles)
    //    // setEditFileID(null);
    //     setShowAddFileModal(false);
    // }
    // const [deleteModal, showDeleteModal] = useState(false);
    // const [deleteFileID, setDeleteFileID] = useState(null);

    // const handleDeleteFile = () => {
    //     const newFiles = [...FileOriginData];
    //     const index = FileOriginData.findIndex((_, index) => index === deleteFileID);
    //     newFiles.splice(index, 1);
    //     setFileOriginData(newFiles);
    //     setDeleteFileID(null)
    //     showDeleteModal(false);
    // }


    return (
        <>
            <Fragment>
                <section className='search-container'>
                    <div className='search-bar'>
                        <input className='search-input' value={filter || ''} onChange={e => setFilter(e.target.value)} />
                        <button className='search-icon'><img src={searchIcon} alt='' /> </button>
                    </div>
                    {RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 && <div className='add-queue'><button className='btn_canceluser' onClick={() =>{ setShowAddFileModal(true);setEditMode(false)}}>
                        UPLOAD
                    </button>
                    </div>}
                </section>
            </Fragment>
            {searchRows(fileUploadData)?.length === 0 ?
                <section>
                    <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                        <thead>
                            <tr>
                                {HEADERS.map(header => (
                                    <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>
                                ))
                                }
                                <th>{'  '}</th>
                            </tr>
                        </thead>
                    </table>
                    <section className='noData-table'>
                        <div className='noData-para' style={{ marginTop: 0 }}>
                            <div style={{ marginTop: 30 }}>
                                No records found. This data grid enables adding/editing the corresponding sub-categories. <br /> To add new queue entries, click on "Add Queue"  link.
                            </div>
                        </div>
                    </section>
                </section> :
                <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                    <thead>
                        <tr>
                            {HEADERS.map(header => (
                                <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>
                            ))
                            }
                            <th>{'  '}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fileUploadData && searchRows(fileUploadData)?.map((data, index) => (
                                <tr key={index}>
                                    <td className='queue_tablebody'>{data?.['File Name']}</td>
                                    <td className='queue_tablebody'>{data.Format}</td>
                                    <td className='queue_tablebody'>{data.Size}</td>
                                    <td className='queue_tablebody'>{data?.['Uploaded Date']}</td>
                                    <td className='queue_tablebody'>{data.Period}</td>
                                    <td className='queue_tablebody'>{data?.['Name of the Uploader']}</td>
                                    {RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 && <td className='table-button'>
                                        <span onClick={() => { onClickEditRow(index, data) }}>
                                            <TooltipHandler src={editIcon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' /></span>
                                    </td>}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
            {showAddFileModal && <FileUploadModalPopup isOpen={showAddFileModal} setShowModal={setShowAddFileModal}>
                <FileUploadFragment editFileUpload={addFileUpload}
                    handleFileUploadCancel={() => setShowAddFileModal(false)} editMode={false}
                     EditMode={EditMode}
                />
            </FileUploadModalPopup>}
            {/* {editFileModal && <FileUploadModalPopup isOpen={editFileModal} setShowModal={showEditFileModal}>
                <FileUploadFragment buttonOne='UPDATE' buttonTwo='CANCEL' modalName='Edit Uploaded File'
                    editFileUpload={addFileUpload} editMode={true}
                    handleFileUploadCancel={() => showEditFileModal(false)}
                   
                />
            </FileUploadModalPopup>} */}
            {/* {deleteModal && <DeleteModalPopup isOpen={deleteModal} setShowModal={showDeleteModal}>
                <DeleteFragment modalName='Delete File' buttonOne='YES' buttonTwo='NO' delete={true}
                    handleDeleteQueue={handleDeleteFile}
                    handleDeleteQueueCancel={() => {
                        showDeleteModal(false);
                        setDeleteFileID(null);
                    }}
                    queueName={FileOriginData[FileOriginData.findIndex((_, index) => index === deleteFileID)].fileName + '.'
                        + FileOriginData[FileOriginData.findIndex((_, index) => index === deleteFileID)].fileType}
                />
            </DeleteModalPopup>} */}
        </>
    )
}

export default FileUploadhandler