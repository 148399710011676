import React from 'react'
import HeroBannerImg from './../../../assets/images/HeroBanner_Image.png';
import RolespecificImg from './../../../assets/images/RoleSpecificAccess_Image.png';
import HelpcenterImg from './../../../assets/images/HelpCenter_Image.png';
import UserGuidesIcon from "../../../assets/images/UserGuides_Icon.png";
import TrainingVideosIcon from "../../../assets/images/TrainingVideos_Icon.png";
import EmailToReportingTeamIcon from "../../../assets/images/EmailToReportingTeam_Icon.png";
import MSChatIcon from "../../../assets/images/MSChat_Icon.png";
import ITSMPortalIcon from "../../../assets/images/ITSMPortal.png";
import DXCLogoImg from '../../../assets/images/DXC_Logo_Footer_Normal.png';
import LogoutImg from '../../../assets/images/Logout_Icon.png';
import GSDLogo from '../../../assets/images/GSDInsight_Logo.png'
import './FirstVisitor.css';
import { Col, ListGroup } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import ConstructURLs from '../../../constants/urls';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserToken } from '../../../actions/user.action';
import { useHistory } from 'react-router-dom';
 const FirstVisitor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let userInfo = useSelector((state) => state.userReducer.userInfo);
  let url = window.location.href;
  const logoutHandler = () => {
    window.localStorage.clear();
    window.location.href = ConstructURLs("LOG_OUT_URL");
  };
  useEffect(() => {
    console.log("Window URL  " + url);
    let isLocalhost = url.includes("localhost");
    console.log("isLocalhost  " + isLocalhost);
    if (isLocalhost) {
      dispatch(getUserToken());
    }
  }, [dispatch]);
  return (
    <>
    <div style={{width:'100%',marginTop:'-125px',marginLeft:'-50px',minWidth:'600px'}}>
        <div className='first_container'>
            <img src={HeroBannerImg} alt='none' style={{width:'100%',height:"400px"}}/>
           
            <div className="top-left">
            <span><img src={GSDLogo} alt='gsd' style={{marginRight:'20px'}}/> </span>
            <span className='gsd_text'>GSD Insight</span>
            </div>
            <div class="top-left-2">
            <div className='welcome_gsd'>Welcome to GSD Insight!</div></div>
            <div className="text-block">
            <div>We appreciate your interest to GSD Insight. You have <br/>
            been identified as a First Time User and would need to <br/>
            obtain specific permission to be able to access further <br/>
            details (reports, administrations and other features) of <br/>
            the GSD Insight portal. Explore the GitHub portal to <br/>
            learn more about specific user role privileges.</div>
          </div> 
          <div className="bottom-left" onClick = {()=>history.push('/githublanding')}>
          
                      <div className='btn_exploreuser' style={{marginLeft:'0px',marginTop:20}}>EXPLORE GITHUB</div>
                   
          
            </div>
            <div className="top-right"><span className='name_text'>Hi, {userInfo.name}</span><span style={{cursor:'pointer'}}><img onClick={logoutHandler} src={LogoutImg} alt='none'/> </span></div>
           
        </div>
        <div className='second_container' style={{display:'flex'}}>
          <Col style={{paddingRight:'30px'}} >
             <div className='role_access'>For your role specific access</div>
             <div className='send_email'>Send an email to GSD Insight support team with following informations:</div>
             <div className='email_flow'>Name → Email → Role → Account </div>
             <div className='approval_note'>(Note: Approval from the GSD Modern Workplace Site Manager attached)</div>
             <div>
             <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href="mailto:GSD_Insight_Support@dxc.com"
                      title="GSD_Insight_Support@dxc.com" >
                    <div className='btn_adduser' style={{marginLeft:'0px',marginTop:20}}> EMAIL US</div>
                    </a>
             </div>
          </Col>
          <Col >
              <img src={RolespecificImg} alt='none'/>
          </Col>
         
        </div>
        <div className='third_container'>
        <Row>
             <Col>
             <img src={HelpcenterImg} alt='none' style={{marginBottom:30}}/>
             </Col>
             <Col>
             <ListGroup variant="flush">
                  <ListGroup.Item style={{background:'transparent' }}><span className='linkhead_visitor'>Help Center</span></ListGroup.Item>
                 <Row>
                 <Col>
                  <ListGroup.Item className='list_items' ><span onClick ={()=>history.push('/githublanding')}><img src={UserGuidesIcon} className="margin_1" alt="none" />
                      <span className='guide'>User Guides</span></span>
                    </ListGroup.Item>
                  <ListGroup.Item className='list_items'><span onClick ={()=>history.push('/training')}><img src={TrainingVideosIcon} className="margin_1" alt="none" />
                      <span className='guide'>Trainings</span></span>
                  </ListGroup.Item>
                  <ListGroup.Item className='list_items'><span><img src={EmailToReportingTeamIcon} className="margin_1" alt="none" /></span>
                    <a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href="mailto:GSD_Insight_Support@dxc.com"
                    >
                      <span className='guide'>Email to Reporting Team</span>
                    </a>
                  </ListGroup.Item>
                  </Col>
                  <Col>
                  <ListGroup.Item className='list_items'><span><img src={MSChatIcon} className="margin_1" alt="none" /></span>
                    <a target="_blank" rel="noreferrer"
                      title="https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&amp;tenantId=93f33571-550f-43cf-b09f-cd331338d086"
                      href="https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&amp;tenantId=93f33571-550f-43cf-b09f-cd331338d086"                         >
                      <span className='guide'>Microsoft Teams Chat</span>
                    </a>
                  </ListGroup.Item>
                  <ListGroup.Item className='list_items'><span onClick ={()=>history.push('/request')}><img src={ITSMPortalIcon} className="margin_1" alt="none" />
                      <span className='guide'>ITSM Service Portal</span></span>
                  </ListGroup.Item>
                  </Col>
                  </Row>
                </ListGroup>
             </Col>
             </Row>
        
        <hr/>
        <div style={{display:'flex',justifyContent:'space-between'}}>
         <span> <img src={DXCLogoImg} alt='dxc'/></span>
          <span className='footer_dxc'>© DXC Technology Company</span>
        </div>
        </div>
    </div>
    </>
  )
}
export default FirstVisitor;