import React from 'react'
import { useHistory } from 'react-router-dom'

function RefreshHandle() {
    const history = useHistory()
    window.onunload = history.push('/')
    console.log('its now refreshed')
    return(<>
    </>)
}

export default RefreshHandle