import React, { useState } from 'react'
import filterIcon from '../../../assets/images/Filter_Icon.png'
import downloadIcon from '../../../assets/images/Download_Icon.png'
import './SLA-Admin.css';
import Button from '../../ReportTables/ReportFilters/Button';
import { useHistory } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
function FilterTab(props) {
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(new Date());

    return (
        <>
            <section>
                <div className='sla_header'>
                    <div> <span><img
                    style={{maxHeight:'40px',maxWidth:'60px'}}
                    // height='20px'
                    // width='60px'
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL
                    }/api/org/customer/${props.displayimage}/photo`}
                  alt="Client Logo"
                /></span>  {props.selectedItem} </div>
                    {/* <div className='last-of-type'>
                        <button className='cancel-button extra_Cancel'
                            style={{ width: '220px', borderTopRightRadius: '20px' }}
                            onClick={() => history.push(`/admin/${props.pathname}`)}>
                            <img src={props.src} alt='' style={{ marginLeft: '10px' }} />
                            <span className='cancel-span' style={{ marginTop: '-2px' }}> {props.buttonValue} </span>
                        </button>
                        </div> */}
                </div>
                <header className='sla_date_filter_tab'>
                    <span className='filter_icon'><img src={filterIcon} alt='' /></span>
                    <span className='dropdown_sla'> 
                   <label>Select Month</label> <br/>
                        <KeyboardDatePicker
                            views={['month', 'year']}
                            autoOk
                            variant="inline"
                            name='valid_from'
                            clearable
                            value={selectedDate}
                            placeholder="MMMM YYYY"
                            onChange={(date) => {
                                setSelectedDate(date)
                                props.HandleSLAMonth(date)
                            }}
                            format="MMMM YYYY"
                            style={{ width: '300px' }}
                            error={false}
                            helperText={null}
                        />
                    </span>
                    <div className='exemption_info'>   <span id='vertical_line' ></span>
                        <span id='all'> All Exemption <div id='numericals'>{props.counters?.Total?.toString()}</div></span><span id='vertical_line' ></span>
                        <span id='voice'> Voice <div id='numericals'>{props.counters?.Voice?.toString()}</div></span>  <span id='vertical_line' ></span>
                        <span id='non_voice'> Non voice <div id='numericals'>{props.counters?.['Non-Voice']?.toString()}</div></span>
                    </div>
                    <Button className='download_btn' item='Download Report'
                        onClick={() => { }} icon={downloadIcon} />
                </header>


            </section>
        </>)
}
export default FilterTab;
