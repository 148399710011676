import API from "../api";
let token = localStorage.getItem("token");
export const getContactAccountsService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getDropdownInfoServive = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAverageHandleTimeService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

export const getAbandonTimeService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getDigitalTransService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getCostPerContactService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getContactFTEService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getPenaltyAtRiskService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getFirstContactResolutionService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getAttritionDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getSLAconplainceService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getCSATdataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};


export const getDeliverySupportService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};



export const getContactVolumeDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};

