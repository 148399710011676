import React from 'react'
import { Accordion } from 'react-bootstrap'
import './ReleaseandBuild.css'
import VerticalLine from './../../../../assets/images/VerticalLine_Headings.png';
import ReleaseandBuild from './../../../../assets/github-images/ReleaseandBuildGuides_release_process.png';
const List =[
    {  id:'1', text:'The developer completes a change on its local PC development environment.'},
    {  id:'2', text:'After initial testing - compiles the build'},
     {  id:'3', text:'Both the code & the build is synchronized to GitHub code repository via GitHub Desktop'},
     {  id:'4', text:'The build files are manually copied to the respective WEB server location (dev, uat, prod)'},
     {  id:'5', text:'In case of need restart of the IIS can be required to pickup the changes… '}
]
const process_list =[
    {  id:'1', text:'Receive of the change / request in GitHub'},
    {  id:'2', text:'Evaluation & decision & prioritization + adding to the JIRA backlog'},
     {  id:'3', text:'Development and release to DEV for internal validation'},
     {  id:'4', text:'Release to UAT for end user validation & testing '},
     {  id:'5', text:'Release to PROD environment + adding to release notes description'}
]
const Enhancement =[
    {  id:'1', text:'Check your code into github'},
    {  id:'2', text:'TAG your code with the JIRA number associated with your release'},
     {  id:'3', text:'Update your JIRA task with INSTALLATION INSTRUCTIONS'},
     {  id:'4', text:'Update your JIRA task with RELEASE NOTES'},
     {  id:'5', text:'Move your JIRA task into Release to UAT Status'}
]
const Enhancement_UAT =[
    {  id:'1', text:'JIRA must be updated by COB each Tuesday'},
    {  id:'2', text:'A team member will assemble the FULL release instructions from ALL JIRA requests in Release to UAT status on following day (Wednesday)'},
     {  id:'3', text:'Consolidated instructions will be sent and reviewed in daily SCRUM meeting'},
     {  id:'4', text:'If instructions are approved - release to UAT will occur the following day (Thursday)'}
]
const ReleaseandBuildGuides = () => {
    return (
        <>
            <main className='accordion_handle' >
            <div style={{width:1200,margin:'auto'}}>
                <div className='Infrastructure'>Release and Build Guides</div>
                <div style={{ width: '98%'}}>
                    <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
                        <Accordion.Item eventKey={0} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Deployment to Servers</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundColor: 'white' }}>
                                <>
                                    <main>
                                        <div className='build'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Security Properties</div>
                                       <div className='build_list'>
                                        <ol>
                                           {List.map((list, index) => ( <li>{list.text}</li>))}
                                        </ol>
                                        <div>&nbsp;&nbsp;&nbsp;&nbsp;In the future to have the CI/CD pipeline implemented which will automatically push the changes from the GitHub repository to the respective WEB server(s).</div>
                                    </div>
                                    </main>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={1} style={{ marginTop: '0.3em' }}  >
                            <Accordion.Header style={{
                                width: 'inherit', height: '58px', background: '#FFFFFF 0% 0% no-repeat paddingBox',
                                boxShadow: '0px 3px 3px #9D9D9D80', opacity: '1'
                            }}>
                                <span className='accordion-header'>Release Process</span></Accordion.Header>
                            <Accordion.Body style={{ marginTop: '0', border: 'transparent', backgroundcolor: 'white' }}>
                                <>
                                <main className='build'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Overview</main>
                                       <main className='build_list'>
                                       <div id='our_team'>Our team implements a structured process for releasing enhancements and fixes to production</div>
                                       <div id='process'><b>This process involves:</b></div>
                                        <ol>
                                           {process_list.map((list, index) => ( <li>{list.text}</li>))}
                                        </ol>
                                    </main>
                                    <main className='img_release'><img src={ReleaseandBuild} alt=''/></main>
                                    <main className='img_release'>There are typically two types of release:</main>
                                    <main className='img_release'><b>Enhancement:</b> this is the normal situation where product development leads to a point where some NEW feature or functionality is added and released.<br/>
                                    <b> Hotfix:</b> this situation occurs if during a previous releases - we have introduced an issue (deemed urgent) that was NOT present prior. This process allows for Production to be patched immediately.
                                    </main>
                                   <section> <main className='build'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Enhancement</main>
                                   <main className='enhancement_style'><div id='header'>You should:</div><div><ol>
                                           {Enhancement.map((list, index) => ( <li>{list.text}</li>))}
                                        </ol></div></main>
                                        <main className='enhancement_style'><div id='header'>Release to UAT is co-ordinated as follows:</div><div><ol>
                                           {Enhancement_UAT.map((list, index) => ( <li>{list.text}</li>))}
                                        </ol></div></main>
                                   </section>
                                   <main className='build'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; HotFix</main>
                                   <div className='img_release'>You should seek approval from your technical lead for application of hotfixes. Hotfix needs to be tested on UAT first before to be release to production.</div>
                                   <main className='build'><img src={VerticalLine} alt='' /> &nbsp;&nbsp;&nbsp; Release Notes</main>
                                   <div className='img_release'>After successful release, release notes needs to be published under GitHub documentation - related section.</div>
                                </>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div></div>
            </main>

        </>
    )
}

export default ReleaseandBuildGuides