import API from "./../../api";
let token = localStorage.getItem("token");

export const getExemptionDataService = (requestUrl) => {
    return API.get(requestUrl, {
  
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };
  
  
  
  
  export const updateExistingCommentService = (requestUrl, id) => {
    return API.post(requestUrl, id, {
  
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };

  export const getExclusionDataService = (requestUrl) => {
    return API.get(requestUrl, {
  
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };
  
  
  export const updateExclusionCommentService = (requestUrl, id) => {
    return API.post(requestUrl, id, {
  
      headers: {
        Authorization: token,
      },
    })
      .then((resp) => resp)
      .catch((error) => error.response);
  };
  
