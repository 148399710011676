import React from 'react'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb'
import { Footer } from '../HeaderFooter';
import User_HeroBanner from './../../../assets/github-images/User_HeroBanner.png';
import VerticalLine from './../../../assets/images/VerticalLine_Headings.png';
import './User.css';
import { useHistory } from 'react-router-dom';

const  security_Cards= [
    {
        id: 1,
        title:'Where are our toolsets hosted?',
        text:'The servers are physically hosted in the USA within the DXC SSZ infrastructure in the Houston Data Centre.'
    },
    {
        id: 2,
        title:'What Security policies govern this framework?',
        text:'The servers databases, mailboxes and supporting CODE is hosted in accordance with DXC security policies, requiring an annual Security review to remain within this hosted environment.'
    },
    {
        id: 3,
        title:'Who supports and has accesss to the data residing on the servers?',
        text:'Database and back-end access is restricted solely to tool administrators and developers.'
    },
    {
      id: 4,
      title:'Who can access the data through the user interface?',
      text:'GSD Insight is available only for Internal DXC staff. Connection to the DXC network is required (physically within an DXC office or via a Secure Pulse VPN).The role based access model is described in a separate document.'
  },
  {
      id: 5,
      title:'What data is collected?',
      text:'A multitude of GSD related data sources are required to make a truly useful governance platform.'
  }]

  const GSD_URL = [
    { id: '1', caption: "Environment" },
    { id: '2', caption: "GSD UI URL" }
]
const GSD_URL_LIST = [
    { id: '1', 'Environment': 'Production Instance', 'GSD_UI_URL':'https://gsd-insight.houston.dxccorp.net',href:'https://gsd-insight.houston.dxccorp.net'}
]

const GLOBAL_SLA_KPI = [
  { id: '1', caption: "Global Standard SLAs",href:'https://dxcportal.sharepoint.com/:x:/r/sites/GSDInsight/_layouts/15/Doc.aspx?action=edit&sourcedoc=%7B66f9ae2d-1a43-4fad-9ad3-054a0cd9d811%7D&wdOrigin=TEAMS-ELECTRON.teamsSdk.openFilePreview&wdExp=TEAMS-CONTROL&cid=e2623342-1ebe-4bde-8cf2-53566bafba2a' },
  { id: '2', caption: "Global KPI Standards and Metrics",href:'https://dxcportal.sharepoint.com/:w:/r/sites/GSDInsight/_layouts/15/Doc.aspx?sourcedoc=%7B4B4C0E9A-ECF7-45AB-80BB-32CF46D67105%7D&file=MWS_GSD_WFM_KPIs-Standards_and_Metric.docx&action=default&mobileredirect=true' }
]

const MS_TEAMS ='https://teams.microsoft.com/l/team/19%3aI-wF3DALEKei_jMiwPbiE4IDvpni3HCHbbZajBRyNk81%40thread.tacv2/conversations?groupId=b47fd5cb-2b61-4939-9254-7be4518dd717&tenantId=93f33571-550f-43cf-b09f-cd331338d086';
const User = () => {
  const history = useHistory();
  return (
    <>
   <section style={{ marginLeft: -100 }}>
        <main className='breadcrumb_solution'>
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='User' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>User</div>This section provides high-level information to all users about access to GSD Insight Portal, standard definition of metrics, security model and other MS Teams links, which will connect the users to all relevant details.</div>
          <div style={{paddingBottom:20,paddingLeft:80}}><img src={User_HeroBanner} alt='' /></div></div>
        </main>
        <main className='conceptual_arch_user'>
        <div style={{width:1200,margin:'auto',display:'flex',gap:'40px'}}>
        <section>
         <main className='card_background_user'>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>GSD Insight URL list</span> <hr/> GSD Insight solution is available within DXC internal network running from the DXC IT centralized instance in Houston. Please refer the below tables for details:
          <main>  <table className='user_table' style={{width:'95%'}}>
                                            <thead><tr>
                                                {GSD_URL.map(header => (<th style={{ border: 'none',backgroundColor:'white' }} key={header.id}><span className='user_header'>{header.caption}</span></th>))}
                                            </tr></thead>
                                            <tbody>
                                                {GSD_URL_LIST.map((data, index) => (<tr key={data.id}>
                                                    <td className='user_td'>{data?.Environment}</td>
                                                    <td className='user_td_link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={data.href}
                      title="" >  <span id='link_font'>{data?.GSD_UI_URL}</span> </a></td>
                                                </tr>))} </tbody>
                                        </table></main>
                                        </div>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>Access to GSD Insight</span> <hr/> In order to successfully access GSD Insight the following requirements need to be met: 
                   <div className='list_div'> <ul>
                      <li className='list_user_ul'>User needs to possess Universal ID</li>
                      <li className='list_user_ul'>User needs to log into Appgate or Pulse VPN and select the Houston Server option</li>
                    </ul></div>
                    <div>For further details we would like to redirect you to</div>
                   <div id='link_font' onClick={()=>history.push('/getstarted')} >How to Get Started.</div>
                    </div>
         </main>
         <main className='card_background_user' style={{marginTop:'-40px'}}>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>GSD Standards</span> <hr/>Standard Definition and Metric Calculation for Global Service Desk is detailed in the following official documents:
          <div className='list_div'><ul>
          {GLOBAL_SLA_KPI.map((data, index) => (<li className='list_user_ul_link'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={data.href}
                      title="" >  <span id='link_font'>{data.caption}</span>  </a></li>))}
           
          </ul></div>
           </div>
          <div className='card_header'><span id='img_vertical'><img src={VerticalLine} alt=''/></span><span id='header_font'>Useful Links</span> <hr/> Under our <span id='link_font'><a target="_blank" rel="noreferrer" style={{ listStyleType: 'none' }} href={MS_TEAMS}
                      title="" >  <span id='link_font'>MS TEAMs</span>  </a></span> channel you will find all relevant informations and documentation.</div>
         </main>
         <main className='security_model'>
            <div id='text_security'><img src={VerticalLine} alt=''/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; GSD Insight Security Model</div>
            <div className='grid_security'>
              {security_Cards.map((link, index) => (<main class="grid_item_security" ><div id='grid_title'>{link.title}</div><div id='grid_text'>{link.text}</div></main>))}
              </div>
             
         </main>
        </section>
        </div>
        </main>
        <Footer/>
        </section>
     </>
  )
}

export default User