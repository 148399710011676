import React, { useEffect, useState } from 'react';
// import ReportFilters from './ReportFilters/ReportFilters';
import './KPIReportHandler.css'
import { useDispatch, useSelector } from 'react-redux';
import { getDropdownInfo, getContactVolumeData, getAccountInfo, getAbandonRate, getAverageHandleTime, getDigitalTrans, getCostPerContact, getContactFTEData, getPenaltyAtRisk, getFirstContactResolution, getAttritionData, getSLAcompliance, getCSATdata, getDeliverySupport } from '../../../actions/reports.action';
import Filter from '../ReportFilters/ReportFilters';
import ReportTable from '../ReportFilters/ReportTable';
import { saveAccountDropdownList, saveLocationDropdownList } from "../../../actions/reports.action";
// import ReportTabs from '../ReportFilters/ReportTabs';
import { Tabs, Tab } from "react-bootstrap";
import './../../TabMenu/tabmenu.css';
import moversIcon from '../../../assets/images/top_10_movers_image.png';
import shakersIcon from '../../../assets/images/top_10_shakers_image.png';
import { useHistory, useLocation } from 'react-router-dom';
import ClearFilter from '../ClearFilters/ClearFilter';


function KPIReportHandler() {

  const dispatch = useDispatch();
  const location = useLocation();
  const [KPI_pathname, setKPI_pathname] = useState('');
  const [header_info, setHeader_info] = useState('')
  const [downloadURL, setDownloadURL] = useState('');
  const [moversShakersHandle, setMoversShakersHandle] = useState();
  useEffect(() => {
    clearFilterHandler();
  }, [])

  const TableInfoHandler = (ID) => {
    switch (location.pathname) {
      case '/contact': {
        dispatch(getContactVolumeData(ID));
        setKPI_pathname('contact');
        setHeader_info('Contact Volume');
        setDownloadURL('DOWNLOAD_CONTACT_VOLUME_DATA');
        setMoversShakersHandle(false);
        break;
      }
      case '/abrate': {
        dispatch(getAbandonRate(ID));
        setKPI_pathname('abrate');
        setHeader_info('Abandon Rate');
        setDownloadURL('DOWNLOAD_ABANDON_RATE');
        setMoversShakersHandle(true);
        break;
      }
      case '/aht': {
        dispatch(getAverageHandleTime(ID));
        setKPI_pathname('aht');
        setHeader_info('Average Handle Time');
        setDownloadURL('DOWNLOAD_AVERAGE_HANDLE');
        setMoversShakersHandle(true);
        break;
      }
      case '/digitalTrans': {
        dispatch(getDigitalTrans(ID));
        setKPI_pathname('digital');
        setHeader_info('Digital Trans');
        setDownloadURL('DOWNLOAD_DIGITAL_TRANS');
        setMoversShakersHandle(false);
        break;
      }
      case '/cpc': {
        dispatch(getCostPerContact(ID));
        setKPI_pathname('cpc');
        setHeader_info('Cost per Contact');
        setDownloadURL('DOWNLOAD_COST_PER_CONTACT');
        setMoversShakersHandle(true);
        break;
      }
      case '/fte': {
        dispatch(getContactFTEData(ID))
        setKPI_pathname('fte');
        setHeader_info('Contact per FTE');
        setDownloadURL('DOWNLOAD_CONTACT_FTE_DATA');
        setMoversShakersHandle(false);
        break;
      }
      case '/penalty': {
        dispatch(getPenaltyAtRisk(ID));
        setKPI_pathname('penalty');
        setHeader_info('Penalty at Risk');
        setDownloadURL('DOWNLOAD_PENALTY_AT_RISK');
        setMoversShakersHandle(true);
        break;
      }
      case '/fcr': {
        dispatch(getFirstContactResolution(ID));
        setKPI_pathname('fcr');
        setHeader_info('First Contact Resolution');
        setDownloadURL('DOWNLOAD_FIRST_CONTACT_RESOLUTION');
        setMoversShakersHandle(false);
        break;
      }
      case '/attrition': {
        dispatch(getAttritionData(ID));
        setKPI_pathname('attrition');
        setHeader_info('Attrition');
        setDownloadURL('DOWNLOAD_ATTRITION_DATA');
        setMoversShakersHandle(true);
        break;
      }
      case '/sla': {
        dispatch(getSLAcompliance(ID));
        setKPI_pathname('sla');
        setHeader_info('SLA Compliance');
        setDownloadURL('DOWNLOAD_SLA_COMPLIANCE');
        setMoversShakersHandle(false);
        break;
      }
      case '/csat': {
        dispatch(getCSATdata(ID));
        setKPI_pathname('csat');
        setHeader_info('CSAT');
        setDownloadURL('DOWNLOAD_CSAT_DATA');
        setMoversShakersHandle(false);
        break;
      }
      case '/delivery': {
        dispatch(getDeliverySupport(ID));
        setKPI_pathname('delivery');
        setHeader_info('Delivery Support');
        setDownloadURL('DOWNLOAD_DELIVERY_SUPPORT');
        setMoversShakersHandle(true);
        break;
      }

      default: { };
    }
  }

  let contact_tableInfo = useSelector((state) => state.reportTableReducer.contactVolumeData);
  let abrate_tableInfo = useSelector((state) => state.reportTableReducer.AbandonRate);
  let aht_TableInfo = useSelector((state) => state.reportTableReducer.AverageHandleTime);
  let attrition_TableInfo = useSelector((state) => state.reportTableReducer.attritionData);
  let fte_TableInfo = useSelector((state) => state.reportTableReducer.contactFTEData);
  let cpc_TableInfo = useSelector((state) => state.reportTableReducer.CostPerContact);
  let csat_TableInfo = useSelector((state) => state.reportTableReducer.CSATData);
  let delivery_TableInfo = useSelector((state) => state.reportTableReducer.deliverySupport);
  let digital_TableInfo = useSelector((state) => state.reportTableReducer.DigitalTrans);
  let fcr_TableInfo = useSelector((state) => state.reportTableReducer.firstContactResolution);
  let penalty_TableInfo = useSelector((state) => state.reportTableReducer.penaltyAtRisk);
  let sla_TableInfo = useSelector((state) => state.reportTableReducer.SLAcompliance);



  let DropdownInfo = useSelector((state) => state.reportTableReducer.DropdownInfo);
  let AccountFilters = useSelector((state) => state.reportTableReducer.AccountInfo);
  let prevAccountdrpdwnList = useSelector((state) => state.reportTableReducer.accountDrpdwnListData);
  let prevLocationdrpdwnList = useSelector((state) => state.reportTableReducer.locationDrpdwnListData);
  const DOWNLOAD_ARRAY = [
    { value: 'excel', id: 'Excel' },
    { value: 'svg', id: '.SVG' },
    { value: 'csv', id: '.CSV' },
    { value: 'pdf', id: 'PDF' }
  ]
  useEffect(() => {
    if (prevAccountdrpdwnList?.length > 0 || prevLocationdrpdwnList?.length > 0) {
      prevAccountdrpdwnList.splice(0, prevAccountdrpdwnList.length);
      prevLocationdrpdwnList.splice(0, prevLocationdrpdwnList.length);
    }
  }, []);
  useEffect(() => {
    TableInfoHandler(ContactTableTrails([]));
    dispatch(getDropdownInfo());
    dispatch(getAccountInfo(ContactTableTrails([])))
  }, [dispatch]);

  const reportTableHandler = () => {
    switch (KPI_pathname) {
      case 'abrate': return abrate_tableInfo;
      case 'contact': return contact_tableInfo;
      case 'aht': return aht_TableInfo;
      case 'digital': return digital_TableInfo;
      case 'cpc': return cpc_TableInfo;
      case 'fte': return fte_TableInfo;
      case 'penalty': return penalty_TableInfo;
      case 'fcr': return fcr_TableInfo;
      case 'attrition': return attrition_TableInfo;
      case 'sla': return sla_TableInfo;
      case 'csat': return csat_TableInfo;
      case 'delivery': return delivery_TableInfo;

      default: { };
    }
  }


  const [filters, setFilters] = useState([]);
  const [clearFilters, setClearFilters] = useState(false);
  const [period_data, setPeriod_data] = useState([]);
  const [region_data, setRegion_data] = useState([]);
  const [account_data, setAccount_data] = useState([]);
  const [location_data, setLocation_data] = useState([]);
  const RegionInfo = DropdownInfo?.DeliverRegion;
  const PeriodInfo = DropdownInfo?.YearMonth;
  const AccountInfo = AccountFilters?.Account;
  const LocationInfo = AccountFilters?.LocationGroup;


  useEffect(() => {
    if (RegionInfo?.length > 0) {
      RegionInfo.map(info => info.isChecked = false);
      setRegion_data(RegionInfo);
    }
    if (PeriodInfo?.length > 0) {
      PeriodInfo.map(info => info.isChecked = false);
      setPeriod_data(PeriodInfo)
    }

  }, [RegionInfo, PeriodInfo])
  useEffect(() => {
    if (AccountInfo?.length > 0) {
      AccountInfo.map(info => info.isChecked = false);
      setAccount_data(AccountInfo);
    }

    if (LocationInfo?.length > 0) {
      LocationInfo.map(info => info.isChecked = false);
      setLocation_data(LocationInfo)
    }
  }, [AccountInfo, LocationInfo])

  const dropDownLocationFilter = (filters) => {
    region_data.map(info =>
      (info.isChecked === true) && dispatch(getAccountInfo(ContactTableTrails(filters)))
    )
    period_data.map(info =>
      (info.isChecked === true) && dispatch(getAccountInfo(ContactTableTrails(filters)))
    )
  }

  function removeDuplicates(originalArray, prop) {
    let account_arr = [];
    let accountObj = {};
    for (var i in originalArray) {
      accountObj[originalArray[i][prop]] = originalArray[i];
    }
    for (i in accountObj) {
      account_arr.push(accountObj[i]);
    }
    return account_arr;
  }
  useEffect(() => {
    if (AccountFilters?.Account?.length > 0 && prevAccountdrpdwnList?.length > 0 && clearFilters === false) {
      let temp_arr = [...AccountFilters?.Account, ...prevAccountdrpdwnList];
      let uniqueArray = removeDuplicates(temp_arr, "label");
      setAccount_data(uniqueArray);
    }
    if (AccountFilters?.LocationGroup?.length > 0 && prevLocationdrpdwnList?.length > 0 && clearFilters === false) {
      let temp_arr = [...AccountFilters?.LocationGroup, ...prevLocationdrpdwnList];
      let uniqueArray = removeDuplicates(temp_arr, "label");
      setLocation_data(uniqueArray);
    }
  }, [AccountFilters, clearFilters, prevAccountdrpdwnList, prevLocationdrpdwnList]);

  // useEffect(() => {
  //   if (AccountFilters?.Account?.length > 0 && prevAccountdrpdwnList?.length > 0 && clearFilters === false) {
  //     let updatedAccountFilers = uniqueArray([...prevAccountdrpdwnList, ...AccountFilters?.Account]);
  //     setAccount_data(updatedAccountFilers);
  //   }
  //   if (AccountFilters?.LocationGroup?.length > 0 && prevLocationdrpdwnList?.length > 0 && clearFilters === false) {
  //     let updatedLocationFilters = uniqueArray([...prevLocationdrpdwnList, ...AccountFilters?.LocationGroup]);
  //     setLocation_data(updatedLocationFilters);
  //   }

  // }, [AccountFilters, clearFilters, prevAccountdrpdwnList, prevLocationdrpdwnList])


  // const uniqueArray = a => [...new Set(a.map(o => JSON.stringify(o)))].map(s => JSON.parse(s));


  const onAddingPeriodFilters = (option, drpdwnID) => {
    let temp_Arr = [...account_data];
    temp_Arr.map((itm, i) => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return ' ';
    })
    setAccount_data(temp_Arr);

    let temp_arr = [...region_data];
    temp_arr.map((itm, i) => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return ' ';
    });
    setRegion_data(temp_arr);


    let temp_ARR = [...period_data]
    setPeriod_data(temp_ARR);
    temp_ARR.map(itm => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return ' ';
    })
    setPeriod_data(temp_ARR);

    let temp_arr1 = [...location_data];
    temp_arr1.map((itm, i) => {
      if (option.value === itm.value) {
        itm.isChecked = !option.isChecked;
      }
      return ' ';
    });
    setLocation_data(temp_arr1);



    switch (drpdwnID) {
      case 'accountDrpdwn': {
        dispatch(saveAccountDropdownList(account_data)); break;
      }
      case 'locationDrpdwn': {
        dispatch(saveLocationDropdownList(location_data)); break;
      }
      default: {

      }
    }

    if (filters.includes(option.value)) {
      return ' ';
    }

    if (filters.length > 0) {
      setClearFilters(false);
    }
    let fltrs = [...filters, option.value];
    setFilters(fltrs);
    ContactTableTrails(fltrs);
    // dispatch(getContactVolumeData());
    TableInfoHandler(ContactTableTrails(fltrs));
    dropDownLocationFilter(fltrs);

  }

  const clearFilterHandler = () => {
    setFilters([]);
    setClearFilters(true);
    ContactTableTrails([]);
    TableInfoHandler(ContactTableTrails([]));
    dispatch(getDropdownInfo());
    dispatch(getAccountInfo(ContactTableTrails([])));
  }

  const filterByReference = (arr1, arr2) => {
    let res = [];
    res = arr1.filter(el => {
      return arr2.find(element => {
        return element === el.value;
      });
    });
    return res;
  }


  const ContactTableTrails = filteredRegions => {
    console.log(filteredRegions)
    let periodFilters = filterByReference(period_data, filteredRegions).map(fil => fil.value);
    let regionFilters = filterByReference(region_data, filteredRegions).map(fil => fil.value);
    let AccountFilters = filterByReference(account_data, filteredRegions).map(fil => {
      if (fil.value.includes('&')) return fil.value.replace('&', '%26');
      else return fil.value;
      return;
    }
    );
    let LocationFilters = filterByReference(location_data, filteredRegions).map(fil => {
      if (fil.value.includes('&')) return fil.value.replace('&', '%26');
      else return fil.value;
      return;
    });
    let ContactVolumeID = periodFilters.map(val => `yearmonth[]=${val}`).join('&') + '&' + regionFilters.map(val => `deliveryregion[]=${val}`).join('&') + '&' + AccountFilters.map(val => `Account[]=${val}`).join('&') + '&' + LocationFilters.map(val => `LocationGroup[]=${val}`).join('&') + '&';
    let filterID = periodFilters.map(val => `yearmonth[]=${val}`).join('&') + '&' + regionFilters.map(val => `deliveryregion[]=${val}`).join('&');
    let ID = { ContactVolumeID: ContactVolumeID, filterID: filterID }
    return ID;
  }

  const [tabKey, setTabKey] = useState('defaultTab');
  const handleTabSelect = key => setTabKey(key);
  const TblDataHandler = (tabKey) => {
    switch (tabKey) {
      case 'defaultTab':
        return reportTableHandler()?.table?.data;
      case 'movers':
        return (moversShakersHandle === true) ?
          reportTableHandler()?.table?.data?.sort((a, b) => parseFloat(b?.Value4) - parseFloat(a?.Value4)) :
          reportTableHandler()?.table?.data?.sort((a, b) => parseFloat(a?.Value4) - parseFloat(b?.Value4));
      case 'shakers':
        return (moversShakersHandle === false) ?
          reportTableHandler()?.table?.data?.sort((a, b) => parseFloat(b?.Value4) - parseFloat(a?.Value4)) :
          reportTableHandler()?.table?.data?.sort((a, b) => parseFloat(a?.Value4) - parseFloat(b?.Value4));
      default: { }
      // return reportTableHandler()?.table?.data;
    }
  }
  const headerData = reportTableHandler()?.table?.header;
  const history = useHistory();
  const redirectTo = (path) => { }
  return <React.Fragment>
    <section className='contact-volume-container' >
      <section className='view_report_breadcrumb'>
        <span onClick={() => history.push('/welcome')}>Home / </span>
        <span onClick={() => history.push('/regional')}>Report / </span>
        <span onClick={() => history.push('/eDashboard')}>EDashboard / </span>
        <span style={{ color: '#63666A' }}>{header_info}</span>

      </section>
      <br />
      <span className='contact-volume'>{header_info}</span>
      <Filter downloadArray={DOWNLOAD_ARRAY}
        region_data={region_data}
        period_data={period_data} account_data={account_data} location_data={location_data}
        onSelectingDropdown={onAddingPeriodFilters}
        clearFilters={clearFilters}
        refreshHandler={clearFilterHandler}
        downloadURL={downloadURL}
        latestUpdates={false}
        downloadButton={true}
        ID={ContactTableTrails(filters)}
      />
      {filters?.length > 0 ? (<ClearFilter
        onClearingFilters={clearFilterHandler}
        filteredPeriod={filters} />) : null}
      <section className='tab_container'>
        <Tabs
          onSelect={handleTabSelect}
          className='nav-tabs-new'
        >
          <Tab eventKey="defaultTab" title="ALL" key="defaultTab">
            <ReportTable tblData={TblDataHandler(tabKey)} headerData={headerData} />
          </Tab>
          <Tab eventKey="movers" title={<span className='movers_title'>
            <img id='img_Src' src={moversIcon} alt='icon' height='26px' width='26px' /> MOVERS</span>} key="movers">
            <ReportTable tblData={TblDataHandler(tabKey)} headerData={headerData} />
          </Tab>
          <Tab eventKey="shakers" title={<span className='movers_title'>
            <img id='img_Src' src={shakersIcon} alt='icon' height='26px' width='26px' /> SHAKERS</span>} key="shakers">
            <ReportTable tblData={TblDataHandler(tabKey)} headerData={headerData} />
          </Tab>
        </Tabs>
      </section>
    </section>
  </React.Fragment>;
}
export default KPIReportHandler;
