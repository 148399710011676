import React, { useState } from 'react'
import ReactModal from "react-modal";
import { getOperationsData, updateOperationsDataStart } from '../../../../actions/Administration/adminView.action';
import { useDispatch } from 'react-redux';





export function OperationsAddModel(props) {
   const dispatch = useDispatch();
   const {Mode,editOperationData} = props
    const [addOperation, setAddOperation] =useState(Mode === 'Edit' ? editOperationData : {});
    const HandleAddOperations = (event) => {
        event.preventDefault();
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (fieldValue !== 'Select') {
            const newFormData = { ...addOperation };
            newFormData[fieldName] = fieldValue;
            setAddOperation(newFormData)
        }
    }

    const [validation, setValidation] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        let temparr = {  
             ID : Mode === 'Edit' ? editOperationData.ID :'',
             OperationName : addOperation?.OperationName,
             Description : addOperation.Description,
        }
        const temp_Arr = {
            OperationName : addOperation?.OperationName,
             Description : addOperation.Description,
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        if(isEmptyInput){
            setValidation(true);
        }
        else{
        dispatch(updateOperationsDataStart([temparr]))
        props.handleSubmitOperation()
        dispatch(getOperationsData());
        setValidation(false)
        }
    }

    return (
        <>
            <section className='AccessGroup_container' style={{width:'550px'}}>
                <form>
                    <div className='add_access_text'>{Mode === 'Edit' ? 'Edit' : 'Add'} Operation</div>
                    {validation && <div style={{ color: '#ED4F32'}}>Please Ensure you filled all the inputs with  " * " mark </div>}
                    <main>
                    <div style={{width:'480px',height:'75px',marginTop:'10px'}}>
                                <label>Name *</label>
                                    <input type='text'
                                        name='OperationName'
                                        value={addOperation.OperationName}
                                        placeholder='Example: Metric.ReadSensitive'
                                        onChange={(e)=>{HandleAddOperations(e)}}
                                    />
                            </div>
                            <div style={{width:'480px',height:'75px',marginTop:'10px'}}>
                                <label>Descriptions *</label>
                               <input type='text'
                                    name='Description'
                                        value={addOperation.Description}
                                    placeholder='Example:Can read the sensitive subset of all in scope metrics'
                                    onChange={(e)=>{HandleAddOperations(e)}}
                                />
                            </div>
                    </main>
                    
                    <main className='Button_container_accessGroup' style={{marginTop:'2em',marginLeft:'4em'}}>
                        <button className='btn_adduser' onClick={(e)=>{handleSubmit(e)}}>{Mode === 'Edit' ? 'UPDATE' : 'ADD'} </button>
                        <button className='btn_canceluser' onClick={props.handleCancelOperation}>CANCEL</button>
                    </main>
                </form>
            </section>
        </>
    )
}

export function OperationsModalPopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '25%',
                    left: '33%',
                    width: '550px',
                    height: '350px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}

