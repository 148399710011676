import React from 'react'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb';
import { Footer } from './../HeaderFooter';
import ProductRequirement_HeroBanner from './../../../assets/images/ProductRequirement_HeroBanner.png';
import UserExperienceEnsurance from './../../../assets/images/UserExperienceEnsurance_Image.png';
import ToolTip from 'react-power-tooltip';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const  Functional_Cards= [
  {
      id: 1,
      title:'Bottom to Top - Top to Bottom',
      text:'Users can drill down in more depth levels within the data structure.'
  },
  {
      id: 2,
      title:'Export capability',
      text:'Export data in multiple formats (e.g., xls, pdf, csv).'
  },
  {
      id: 3,
      title:'Graphical Visualization',
      text:'The dashboard need to graphically display the reports.'
  },
  {
    id: 4,
    title:'Connectivity',
    text:'Connection to the Reporting platform by various Report display solutions should be supported (eg. PowerBI).'
},
{
    id: 5,
    title:'Access',
    text:'The platform must have the ability to assure Role Based Access Control (RBAC).'
},
{
    id: 6,
    title:'Access audit',
    text:'Real time access to audit all kind of access.'
},
{
  id: 7,
  title:'Normalisation & Standardisation',
  text:'Standardize and normalize the data (fields) and have a common taxonomy defined.'
},
{
  id: 8,
  title:'Data Anonymization',
  text:'Anonymize data wherever required to be compliant with legal and other regulations (eg. GDPR).'
},
{
  id: 9,
  title:'Data Ingestion On Schedule',
  text:'The platform need to be able to run scheduled data fetch/ingestion.'
},
{
  id: 10,
  title:'Data Access Management',
  text:'Data owners should have the ability to flexibly manage Accesses for Users with regards of their own data.'
},
{
  id: 11,
  title:'Data Validation',
  text:'Validation of data before injection into the database (logic for the data source and checks to be developed).'
},
{
  id: 12,
  title:'Report Parametrisation',
  text:'Data owners should have the ability to change certain parameters of reports or create custom ones.'
},
{
  id: 13,
  title:'Time Zone Coverage',
  text:'The platform need to be able to manage data in different time zones.'
},
{
  id: 14,
  title:'Language Localisation',
  text:'The platform need to be able to handle language localization (if required).'
},
{
  id: 15,
  title:'Support & Enhancement Interface',
  text:'The platform should have an interface for Users being able to apply for support (incident management, change management) or enter enhancement requests.'
}
]
const  NonFunctional_Cards= [
  {
      id: 1,
      title:'Audit',text1:'Audit – User Activity​',text2:'Audit – Database (backup, retention)​',text3:'Source code (vulnerability, patching)', text4:'',text5:'',text6:'',text7:''
  },
  {
      id: 2,
      title:'Documentation',text1:'Architecture guide',text2:'Data & Security design​',text3:'Functional design​', text4:'End User manual​',text5:'Cost Model inputs (deploy, run)',text6:'API guide',text7:''
  },
  {
      id: 3,
      title:'Design Principles',text1:'Privacy​',text2:'Centralization', text3:'Language',text4:'Data processing model​',text5:'Accessibility (API)',text6:''
  },
  {
    id: 4,
    title:'Integrity',text1:'Application integrity​',text2:'Data integrity',text3:'', text4:'',text5:'',text6:'',text7:''
},
{
    id: 5,
    title:'Usability',text1:'Accessibility (User access)​',text2:'User standards (Look / Feel)​',text3:'Localisation (Language, spellings, keyboard – EN only)', text4:'',text5:'',text6:'',text7:''
},
{
    id: 6,
    title:'Security',text1:'Access permissions (Admin, User)​',text2:'Data classification / System Accreditation (Data protection)​',text3:'PW requirements​', text4:'System network Access (Int/Ext)​',text5:'Risks – Mitigating factors​',text6:'Security Testing (regular)​',text7:'System - Data backup'
},
{
  id: 7,
  title:'Reporting',text1:'Client consumption​',text2:'Utilization (Servic and Cost)',text3:'', text4:'',text5:'',text6:'',text7:''
},
{
  id: 8,
  title:'Compatibility',text1:'Compatibility (UI with common browsers)',text2:'',text3:'', text4:'',text5:'',text6:'',text7:''
},
{
  id: 9,
  title:'Continuous Delivery',text1:'Rolling updates and changes',text2:'',text3:'', text4:'',text5:'',text6:'',text7:''
},
{
  id: 10,
  title:'Delivery Kit',text1:'Control and Manage (Centrally)',text2:'',text3:'', text4:'',text5:'',text6:'',text7:''
},
{
  id: 11,
  title:'Automation',text1:'Accept, digest data (fully, semi, minimal manual)​',text2:'Output reports to e-mail (scheduled)​',text3:'System Access review notifications', text4:'',text5:'',text6:'',text7:''
}
]

const  user_exp= [
  {
      id: 1,
      title:'Availability',
      text1:'Globally' ,
      text2:'​7x24x365',
      text3:'​Aim 95% minimum, 98% expected',
      text4:''
  },
  {
      id: 2,
      title:'Recoverability',
      text1:'Backup frequencies',
      text2:'Recovery process',
      text3:'RPO (Recovery Point Objectives)',
      text4:'RTO (Recovery Time Objectives)'
  },
  {
      id: 3,
      title:'Reliability',
      text1:'Functionality under stated conditions',
      text2:'Mean Time between failures (Downtime)​',
      text3:'Mean time to recovery',
      text4:''
  },
  {
    id: 4,
    title:'Capacity',
    text1:'Forecasting (Growth/Reduction Audit, Forecast)​',
    text2:'Monitoring (Storage, Throughput, Memory)​',
    text3:'Optimisation (Cascaded)',
    text4:''
},
{
    id: 5,
    title:'Usability',
    text1:'Performance',
    text2:'Processing times (Response, Processing, Calculations)​',
    text3:'​Query and Reporting times (Initial load)',
    text4:''
}
]
const ProductRequirements = () => {
  const history = useHistory();
  const [nonFunctionalTooltip, setNonFunctionalTooltip] = useState(false)
  const [nonFuctionalIndex, setNonFunctionalIndex] = useState(null);
  const [functionalTooltip, setFunctionalTooltip] = useState(false)
  const [dropdownIndex, setDropdownIndex] = useState(null);
  const onClickFunctional =(link,index) =>{
    setDropdownIndex(index);
    setFunctionalTooltip(true)
  }
  const onClickNonFunctional =(link,index) =>{
    setNonFunctionalIndex(index);
    setNonFunctionalTooltip(true)
  }
  return (
    <>
      <section style={{ marginLeft: -100 }}>
      <main className='breadcrumb_solution'>
     
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Solution - Product Requirements' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>Product Requirements</div>A systematic preparation and thorough decision making process assured to develop our GSD Insight solution conforming all the layers of requirements.</div>
          <div style={{paddingBottom:20,paddingLeft:80}}><img src={ProductRequirement_HeroBanner} alt='' /></div></div>
        </main>
        <main className='conceptual_prod'>
        <div style={{width:1200,margin:'auto'}}>
              <div id='functional'>Functional Requirements</div>  
              <div className='grid_functional'>
              {Functional_Cards.map((link, index) => (<div style={{position:'relative'}}><div class="grid-item" onMouseOver={() => onClickFunctional(link, index)} onMouseLeave={() =>setFunctionalTooltip(false) }>{link.title}</div>
              {(functionalTooltip && dropdownIndex === index) &&  <ToolTip show={functionalTooltip}
         position='bottom left' arrowAlign='start' borderRadius='10px' fontSize='14/19' color='#1D2833' fontWeight='bold' textBoxWidth='400px' moveRight='50px' > 
            <span >{link.text}</span>
        </ToolTip>}</div>))}
              </div></div>
        </main>
        <main className='non_functional'>
        <div style={{width:1200,margin:'auto'}}>
        <div id='functional_title'>Non Functional Requirements</div> 
        <div className='grid_non_func'>
              {NonFunctional_Cards.map((link, index) => (<div style={{position:'relative'}}><div class="grid_item_func" onMouseOver={() => onClickNonFunctional(link, index)} onMouseLeave={() =>setNonFunctionalTooltip(false) }>{link.title}</div>
              {(nonFunctionalTooltip && nonFuctionalIndex === index) &&  <ToolTip show={nonFunctionalTooltip}
         position='bottom left' arrowAlign='start' borderRadius='10px' fontSize='14/19' color='#1D2833' fontWeight='bold' textBoxWidth='400px'  moveRight='50px' > 
            <span ><div>{link.text1}</div><div> {link.text2}</div><div> {link.text3}</div><div> {link.text4}</div><div> {link.text5}</div>
            <div> {link.text6}</div><div> {link.text7}</div>
          </span> 
        </ToolTip>}</div>))}
              </div></div>
        </main>
        <main className='user_exp'>
        <div style={{width:1200,margin:'auto'}}>
        <div id='user_title'>User Experience Ensurance</div>  
        <div id='img_flex'>
          <main id='image_exp'><img src={UserExperienceEnsurance} alt='' width='600px' height='500px'/></main>
          <main id='content'>
          {user_exp.map((data, index) => ( <section id='content_row'><div id='title_css'>{data.title}</div><div>{data.text1}<br/>{data.text2}<br/>{data.text3}<br/>{data.text4}</div></section>))}
                 
          </main>
        </div></div>
        </main>
        <Footer/>
           </section>
    </>
  )
}

export default ProductRequirements