import React from "react";
import ReactModal from "react-modal";
import './AgentsKPIModels.css';
import { useSelector } from "react-redux";
import CloseWindow_Normal from '../../../assets/images/CloseWindow_Normal.png';

const TableHeader= [
    {
      title: "Account Name"
    },
    {
      title: "Month-Year"
    },
    {
      title: "Valid From"
    },
    {
      title: "Valid To"
    }
  ]

export function AssignedAccountsTblModel(props) {
    let agentsKPIAccountsData = useSelector((state) => state.ScoreCardReducer.agentsKPIAccountsData);
    console.log('agentsKPIAccountsData',agentsKPIAccountsData,props.Email)

    return <>
        <section>
            <div className="Model_Title_Container"><div>{agentsKPIAccountsData?.[0]?.Agent}</div><div onClick={props.closeModel} style={{cursor:'pointer'}}><img src={CloseWindow_Normal} alt=''/></div> </div>
            <table className="AssignedAccountsTbl">
                <thead>
                    <tr style={{ position: 'sticky', top: -1}}>
                    {TableHeader.map(header => (
              <th key={header.id} >{header.title}</th>
            ))
            }
                    </tr>
                </thead>
                <tbody>
               
                    {agentsKPIAccountsData?.[0]?.Accounts.map(data => (
                        <tr key={data.id}> <td>{data.AccountName}</td>
                        <td>{data.MonthYear}</td>
                        <td>{data?.['Valid From']}</td>
                        <td>{data?.['Valid To']}</td>
          </tr>   ))
             }
                   
                </tbody>
            </table>
        </section>

    </>
}


export function AssignedAccountsTblPopUp({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={true} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    background: ' #63666A66 0% 0% no-repeat padding-box',
                    opacity: '1',
                },
                content: {
                    top: '50%',
                    left: '50%',
                    right: 'auto',
                    bottom: 'auto',
                    marginRight: '-30%',
                    transform: 'translate(-50%, -50%)',
                    margin:'20px',
                    padding:'30px',
                   
                }
            }}>
            {children}
        </ReactModal>

    </>)
}
