import { all, call, put, takeEvery } from "redux-saga/effects";
import actionTypes from "../constants/actionTypes";
import RegionalSiteActionTypes from "../constants/regional-site-actionTypes";
import ConstructURLs from "../constants/urls";
import ConstructRegionalSiteURLs from "../constants/regional-site-url";
import { getRegionalSiteDataService, getRegionalSiteCustIdDataService, getDTMAnalyticsDataService } from "../services/regionalSite.service";

function* fetchRegionalSiteData(action) {
  try {
    const response = yield call(getRegionalSiteDataService, ConstructURLs('GET_REGIONALSITE_DATA'));
    console.log("Fetching Regional Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.REGIONALSITE_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching Regional data");
  }
}

function* fetchRegionalSiteCustIdData({ custID, dt }) {
  try {
    const response = yield call(getRegionalSiteCustIdDataService, ConstructRegionalSiteURLs('GET_REGIONALSITE_CUSTID_DATA', custID, dt));
    console.log("Fetching Regional Site Customer ID Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: RegionalSiteActionTypes.UPDATE_REGIONALSITE_CUSTID_DATA,
        payload: response.data.regions,
      });
    }
  } catch (error) {
    console.log("Error while fetching Regional Site Customer ID Data");
  }
}

function* fetchRegionalSiteGlobalSummaryData({ custID, servID, regnID, dt }) {
  let Obj = {
    cust_id: custID,
    serv_id: servID,
    regn_id: regnID
  };
  try {
    const response = yield call(getRegionalSiteCustIdDataService, ConstructRegionalSiteURLs('GET_REGIONALSITE_GLOBAL_SUMMARY_DATA', Obj, dt));
    console.log("Fetching Regional Site Global Summary Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: RegionalSiteActionTypes.UPDATE_REGIONALSITE_GLOBAL_SUMMARY_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching Regional Site Global Summary Data");
  }
}

function* fetchDTMAnalyticsData({ data }) {
  // console.log(custID, servID, dt)
  // let obj = {
  //   cust_id: custID,
  //   serv_id: servID,
  //   sla: sla,
  //   view: view,
  // }
  console.log(data)
  try {
    const response = yield call(getDTMAnalyticsDataService, ConstructRegionalSiteURLs('GET_DTM_ANALYTICS', data));
    if (response.data) {
      yield put({
        type: RegionalSiteActionTypes.UPDATE_DTM_ANALYTICS,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching DTM Analytics Data");
  }
}

function* fetchDTMAnalyticsHalfHour({ data }) {
  // console.log(custID, servID, dt)
  // let obj = {
  //   cust_id: custID,
  //   serv_id: servID,
  //   sla: sla,
  //   view: view,
  // }
  console.log(data)
  try {
    const response = yield call(getDTMAnalyticsDataService, ConstructRegionalSiteURLs('GET_DTM_ANALYTICS_HALF_HOUR', data));
    if (response.data) {
      yield put({
        type: RegionalSiteActionTypes.UPDATE_DTM_ANALYTICS_HALF_HOUR,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching DTM Analytics Data");
  }
}

function* fetchDTMAnalyticsMonth({ data }) {
  // console.log(custID, servID, dt)
  // let obj = {
  //   cust_id: custID,
  //   serv_id: servID,
  //   sla: sla,
  //   view: view,
  // }
  console.log(data)
  try {
    const response = yield call(getDTMAnalyticsDataService, ConstructRegionalSiteURLs('GET_DTM_ANALYTICS_MONTH', data));
    if (response.data) {
      yield put({
        type: RegionalSiteActionTypes.UPDATE_DTM_ANALYTICS_MONTH,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching DTM Analytics Data");
  }
}

function* watchGetRequest() {
  yield takeEvery(actionTypes.GET_REGIONALSITE_DATA, fetchRegionalSiteData);
  yield takeEvery(RegionalSiteActionTypes.GET_REGIONALSITE_CUSTID_DATA, fetchRegionalSiteCustIdData);
  yield takeEvery(RegionalSiteActionTypes.GET_REGIONALSITE_GLOBAL_SUMMARY_DATA, fetchRegionalSiteGlobalSummaryData);
  yield takeEvery(RegionalSiteActionTypes.GET_DTM_ANALYTICS, fetchDTMAnalyticsData);
  yield takeEvery(RegionalSiteActionTypes.GET_DTM_ANALYTICS_HALF_HOUR, fetchDTMAnalyticsHalfHour);
  yield takeEvery(RegionalSiteActionTypes.GET_DTM_ANALYTICS_MONTH, fetchDTMAnalyticsMonth);


}

export default function* regionalSiteSagas() {
  yield all([watchGetRequest()]);
}
