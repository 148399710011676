import React, { useState } from 'react'
import './GitHubPage.css';
import HeroBanner from './../../../assets/images/HeroBanner.png';
import Achievements_Image from './../../../assets/images/Achievements_Image.png';
import OnboardSteps_Image from './../../../assets/images/OnboardSteps_Image.png';
import WhatisGSDInsight_Image from './../../../assets/images/WhatisGSDInsight_Image.png';
import Platform_VideoLink_Image from './../../../assets/images/Platform_VideoLink_Image.png';
import Onboard_Icon from './../../../assets/images/Onboard_Icon.png';
import Account_Icon from './../../../assets/images/Account_Icon.png';
import User_Icon from './../../../assets/images/User_Icon.png';
import VoiceData_Icon from './../../../assets/images/VoiceData_Icon.png';
import NonVoiceData_Icon from './../../../assets/images/NonVoiceData_Icon.png';
import Vertical_Strip_Icon from './../../../assets/images/Vertical_Strip_Icon.png'
import { Footer} from './../HeaderFooter';
import githubReducer from './../../../reducers/github.reducer';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTilesData } from './../../../actions/github.action';
import { useHistory } from 'react-router-dom';
import { VideoModelPopup } from './Models';
import PlatformVideo from './PlatformVideo';



const INFO_CARDS = [
    {
        id: 1,
        name: 'Built to reduce manual work, utilize automated or semi-automated solutions to get your data and visualize in standard views.',
        imgsrc: ''
    },
    {
        id: 2,
        name: 'One stop shop leveraging all channels, both for Voice and Non-voice offering so comprehensive overview of your services.',
        imgsrc: ''
    },
    {
        id: 3,
        imgsrc: "",
        name: 'Empower all the Users at all organizational levels through Operational Reports, Dashboards or Agent Scorecard.',
    }
]
const GitHubPage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showVideoModel, setShowVideoModel] = useState(false);
    let TilesData = useSelector((state) => state.githubReducer.TilesData);
    console.log('TilesData:::::::::::::',TilesData?.[0]?.['accounts Onboarded'])
    useEffect(() => {
        dispatch(getTilesData())
      }, [dispatch]);

      const GRID_TEXT = [
        {
            id: 1,
            name: 'Sites Utilizing the Platform',
            title: TilesData?.[0]?.['sites Utilizing the Platform']
        },
        {
            id: 2,
            name: 'Accounts Onboarded',
            title: TilesData?.[0]?.['accounts Onboarded']
        },
        {
            id: 3,
            name: 'Agents Telephony Data Integrated',
            title: TilesData?.[0]?.['agents Telephony Data Integrated']
        },
        {
            id: 4,
            name: 'GB Data Consumed (Voice & Non-Voice)',
            title: TilesData?.[0]?.['gb Data Consumed (Voice & Non-Voice)']
        }
    ]

    return (
        <section style={{ marginTop: 125, marginLeft: -100 }}>
            <main className='hero_image_container'>
                <img src={HeroBanner} alt='HeroBanner' width='100%' height='480px' style={{objectFit:'cover'}}/>
                <div class="text_on_herobanner">
                    <div className='value_propagation_text'>Value Proposition of GSD Insight </div>
                    <div className='unified_text'>Unified Reporting and Analytics Platform for you to make better business decisions.</div>
                </div>
            </main>
            <main className='Git_cards_container'>
            <div style={{width:1260,display:'flex',gap:40,margin:'auto',textAlign:'center'}}>
                {INFO_CARDS.map((cards, i) =>
                    <div className='Git_text_cards'><div><img src={Vertical_Strip_Icon} alt='' style={{ paddingRight: '20px' }} /></div><div style={{ paddingTop: '20px' }}>{cards.name}</div></div>
                )}
                <div className='Git_text_cards_2'><div><img src={Vertical_Strip_Icon} alt='' style={{ paddingRight: '20px' }} /></div><div>The Platform  <img src={Platform_VideoLink_Image} alt='' style={{ marginTop: '10px',cursor:'pointer' }} onClick={()=>setShowVideoModel(true)}/></div></div>
            </div></main>
            <section style={{width:1260,margin:'auto'}}>
            <main style={{ display: 'flex', height: '474px' }}><img src={Achievements_Image} alt='' width='50%' />
                <div className='grid_box'>
                    {GRID_TEXT?.map((grid, id) =>
                        <div className='border_grid' > <div className='grid_number'>{grid.title === null ? '0' : grid.title}</div><div className='grid_name'>{grid.name}</div></div>)}
                </div>
            </main></section>
            <main className='onboard_container'><img src={OnboardSteps_Image} alt='' width='100%' height="651px" style={{objectFit:'cover'}}/>
           
                <div className='head_text_onboard'>A few simple steps to get you onboarded</div>
                <div className='img_arrow_text'><div className='dash_arrow'></div><div className='arrow-right'></div><div className='arrow-right' style={{ marginLeft: '40%' }}></div><div className='arrow-right' style={{ marginLeft: '65%' }}></div>
                    <div className='image_arrow_container'><img src={Onboard_Icon} alt='' /><div className='head_text_flow'>Onboard</div><div className='info_text_flow'>A standard onboarding template will guide you through the process.</div></div>
                    <div className='image_arrow_container'><img src={Account_Icon} alt='' /><div className='head_text_flow'>1. Account and Site Location</div><div className='info_text_flow'>Define the account name and site location (this defines time zone).</div></div>
                    <div className='image_arrow_container'><img src={User_Icon} alt='' /><div className='head_text_flow'>2. User Role Access</div><div className='info_text_flow'>Define user roles to which you would like to get access.</div></div>
                    <div className='image_arrow_container'><img src={VoiceData_Icon} alt='' /><img src={NonVoiceData_Icon} alt='' /><div className='head_text_flow'>3. Data (Voice & Non Voice)</div><div className='info_text_flow'>For various channels you have the ability to define data source, type, datapoints and measurement criteria. You provide us the data and SLA or KPI formulas and we will calculate and visualise them in your reports.</div></div>
                </div>
            </main>
            <section style={{width:1260,margin:'auto'}}>
            <main className='what_is_gsd_container'>
            <img src={WhatisGSDInsight_Image} alt='' width='100%' height="711px" style={{objectFit:'cover'}} />
            <div class="text_on_herobanner_gsd" >
            <div className='gsd_text_image'>What is GSD Insight</div>
                <div className='know_more_text'> Get to know more on the Concept, Design, Features and Functionalities of the platform.</div>
          </div>
            </main></section>
            <Footer />

            {showVideoModel && <VideoModelPopup isOpen={showVideoModel} setShowModal={setShowVideoModel}>
        <PlatformVideo closeWindow={()=>setShowVideoModel(false)}
        />
      </VideoModelPopup>}
        </section>

        
    )
}

export default GitHubPage