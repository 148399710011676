import React, { useEffect, useState } from 'react';
import AddComments_Icon from '../../../../assets/admin-images/AddComments_Icon.png';
import Expand_DownArrow from '../../../../assets/admin-images/Expand_DownArrow.png';
import Collapse_UpArrow from '../../../../assets/admin-images/Collapse_UpArrow.png';
import Edit_Icon from "../../../../assets/images/Edit_Icon.png";
import DATA from './../DATA.json';
import { GenericModal } from '../../General/SLA-modal';
import { ExclusionModalHandler, ExemptionModalHandler } from '../ModalHandler';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../../../../actions/user.action';
import TooltipHandler from '../../../Onboard/OnboardComponents/TooltipHandler';
import { updateExistingCommentStart } from '../../../../actions/Administration/SLAFailure.action';
import { getExemptionDataStart } from './../../../../actions/Administration/SLAFailure.action';





export default function TableBodyHandler({ key, info, updateExistingComment,RoleBasedView}) {
    const dispatch = useDispatch();
    let userInfo = useSelector((state) => state.userReducer.userInfo);
    useEffect(() => {
        dispatch(getUserData());
    }, [dispatch]);
    const [ExemptionData, setExemptionData] = useState([]);
    const [showAccordion, setShowAccordion] = useState(false);
    const [showExemptionModal, setShowExemptionModal] = useState(false);
    const [exemptionModalInfo, setExemptionModalInfo] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [validation, setValidation] = useState('');



    const currentDateHandler = () => {
        let today = new Date();
        let month = today.getUTCMonth() + 1;
        let day = today.getUTCDate();
        let year = today.getUTCFullYear();
        let hours = today.getHours();
        let minutes = today.getMinutes();
        let seconds = today.getSeconds();
        let newdate = (month < 10 ? '0' + month : month) + "/" + (day < 10 ? '0' + day : day) + "/" + year;
        let newTime = (hours < 10 ? '0' + hours : hours) + ":" + (minutes < 10 ? '0' + minutes : minutes) + ":" + (seconds < 10 ? '0' + seconds : seconds) + ' hours';
        let current_date = [];
        current_date.push(newdate);
        current_date.push(newTime);
        return current_date.join(' ');
    }
    const createdUserDetails = () => {
        let temp_arr = [];
        temp_arr.push(userInfo?.name);
        temp_arr.push(userInfo?.email);
        return temp_arr.join(' ');
    }


    const penaltyExemptionHandler = (data) => {
        if (data.type === 'Valid') return data.penalty ;
        else return '0'
    }

    const handleExemptionFormSubmit = (data) => {
        console.log(data)
        const newExemption = {
            Commenter: createdUserDetails(),
            CommentDate: currentDateHandler(),
            Comments: data.comments,
            Type: data.type,
            Penalty: penaltyExemptionHandler(data),
            sla:info.ID
        }
        const newExemptions = [newExemption, ...ExemptionData]
        const temp_Arr = {
            Comments: data.comments,
            Type: data.type,
          //  Penalty: penaltyExemptionHandler(data),
        }
        const isEmptyInput = Object.values(temp_Arr).some(x => (x === null || x === '' || x === undefined));
        if (isEmptyInput) {
            setShowExemptionModal(true);
            setValidation('Please Ensure you filled all the inputs with  " * " mark ')
        }
        else{
            setExemptionData(newExemptions);
            setShowExemptionModal(false);
            updateExistingComment(newExemption)
        }
           
        
    }
    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day, year].join('/');
    }
    function DateHandle(value) {
        let str = value.split('T');
        str[0] = formatDate(str[0]);
        str[1] = str[1] + ' hours';
        return str.join('\t')
    }
    useEffect(() => {
        if (info?.Comments?.length > 0) {
            let arr1 = [...info?.Comments];
            arr1.reverse()
            let arr2 = arr1.map((a, i, arr) => {
                return {
                    Commenter: a?.Commenter,
                    CommentDate: DateHandle(a?.CommentDate),
                    Comments: a?.Comments,
                    Type: a?.Type,
                    Penalty: a?.Penalty,
                    ID: a?.ID
                }
            })
            setExemptionData(arr2)
        }
        else setExemptionData([]);
    }, [])

    console.log('RoleBasedView',RoleBasedView)
    return (<>
        <tr key={key}>
            <td id='table_tr' style={{ border: 'none' }}>{info?.Month} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.SLA} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.Type} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.['Minimum Target']} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.['Expected Target']} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.Performance?.Value} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.Performance?.Met} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.Performance?.All} </td>
            <td id='table_tr' style={{ border: 'none' }}>{info?.Status} </td>
            {RoleBasedView === 0 ? 
                <td id='table_tr' style={{ border: 'none' }}>
                <span onClick={() => {
                    setEditMode(false)
                    ExemptionData.length !== 0 && setShowAccordion(!showAccordion);
                    setExemptionModalInfo({})
                }}>
                
                 {ExemptionData.length !== 0 && (showAccordion ? <img src={Collapse_UpArrow} alt='' style={{ cursor: 'pointer' }} /> : <img src={Expand_DownArrow} alt='' style={{ cursor: 'pointer' }} />) 
                 }
                </span>
            </td>
             : <td id='table_tr' style={{ border: 'none' }}>
                <span onClick={() => {
                    setEditMode(false)
                    ExemptionData.length !== 0 ? setShowAccordion(!showAccordion) : setShowExemptionModal(true);
                    setExemptionModalInfo({})
                }}>
                
                 {ExemptionData.length !== 0 ? (showAccordion ? <img src={Collapse_UpArrow} alt='' style={{ cursor: 'pointer' }} /> : <img src={Expand_DownArrow} alt='' style={{ cursor: 'pointer' }} />) 
                 : 
                 <span > <TooltipHandler src={AddComments_Icon} position='bottom right'
                    arrowAlign='end' textBoxWidth='300px' text='Add SLA Exemption Comments' /></span>}
                </span>
            </td>}

        </tr>

        {(showAccordion) &&
            <tr > <th colSpan='15'>
                <section className='table_insider' >
                    <div style={{ display: 'flex' }}>
                        {DATA.header2.map(data => (<span style={{ width: data.id === 3 ? '70%' : (data.id === 6) ? '15%' : '50%' }} className='table_insider_box'
                            key={data.id}>{data.label}</span>))}</div>
                    <div>
                        {ExemptionData?.map(data => (
                            <div style={{ display: 'flex', marginLeft: '20px' }}>

                                {Object.values(data).slice(0, 5).map((value, i) => (<span style={{ width: i === 2 ? '70%' : '50%' }}
                                    className='table_insider_body' key={i}>{value}</span>))}
                                {ExemptionData?.indexOf(data) === 0 ? (RoleBasedView === 1 && <span style={{ width: '15%', marginTop: '10px' }}>
                                    <span onClick={() => {
                                        setEditMode(true)
                                        setShowExemptionModal(true);
                                        console.log(ExemptionData?.[0])
                                        setExemptionModalInfo(ExemptionData?.[0])
                                    }}>
                                        <TooltipHandler src={Edit_Icon} width='14px' height='14px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                                    </span></span>) : <span style={{ width: '12%' }}><img src='' alt='' /></span>}</div>
                        ))}
                    </div>
                </section>
            </th></tr>}

        {showExemptionModal && <GenericModal isOpen={showExemptionModal} setShowModal={setShowExemptionModal} height='450px' width='600px'>
            <ExemptionModalHandler
                CancelBtnHandler={() => setShowExemptionModal(false)}
                handleExemptionFormSubmit={handleExemptionFormSubmit}
                SLA_name={info?.SLA} ExemptionData={exemptionModalInfo}
                editMode={editMode}
            />
        </GenericModal>}

    </>)
}