import React from "react";
import "./accordion.css";
import Accordion from 'react-bootstrap/Accordion';
import ClientCards from '../Client-Cards/clientCards';

const AccordionPanels = (props) => {
    return (
        <div>
        {/*  initially it   (activeKey={props.isActive})     was placed inside the <Accordion   to close the accordion on the click am removing it */}
           <Accordion  defaultActiveKey={ (props?.selectedPanel || (props?.selectedPanel === 0)) ? props?.selectedPanel : ''} flush>
            { props?.data?.servicedesks?.map((itm,idx)=>{
                return (<Accordion.Item  style={{marginTop: '3px'}} eventKey={idx} key={idx} onClick={()=>props.slctdAccrdPnlID(idx)}>
                    <Accordion.Header>                   
                        <div className="row" style={{width: '100%', marginRight: '2%'}}>
                            <div className="col-sm-6 col-md-6 col-lg-6 accrd-hdr-name">{itm?.servicedesk}</div>
                            <div className="col-sm-6 col-md-6 col-lg-6 accrd-hdr2-name" style={{textAlign: 'right'}}>{itm?.count_customers}{(itm.count_customers > 1) ? ' Accounts' : ' Account'}</div>
                        </div>     
                    </Accordion.Header>
                    <Accordion.Body>
                     <ClientCards siteName={itm?.servicedesk} data={itm?.customers} serviceDeskID={itm?.servicedeskid}/>
                    </Accordion.Body>
                </Accordion.Item>);   
            }) }         
        </Accordion>
        </div>
        );
};


export default AccordionPanels;