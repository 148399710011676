import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getScoreCardPerformanceData, saveSelectedAgentScoreCardDate } from '../../actions/ScoreCard&TeamKPI.action';
import { getUserData } from '../../actions/user.action';

const AgentCardinReports = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let userInfo = useSelector((state) => state.userReducer.userInfo);
    let ScoreCardPerformanceData = useSelector((state) => state.ScoreCardReducer.ScoreCardPerformanceData);
    useEffect(()=>{
      dispatch(getUserData());
      dispatch(getScoreCardPerformanceData());
    },[dispatch])

    const ColorHandler = (color) => {
      switch (color) {
        case 'Red': return '#EA333D';
        case 'Green': return '#6CC24A';
        case 'Yellow': return '#FFBF00';
        default: return;
      }
    }
  return (
    <section className="Agent_Card_inreports">
    <main className='Left_Txt'>
   <div className='Myscorecard'> My Scorecard  </div>
   <div> Information related to Agent Score Card Metrics are displayed in this section.</div>
    </main>
    <main className='Right_Txt'>
   <div> {ScoreCardPerformanceData?.[0]?.Metric} </div> 
   <div><span className='Score_style'> {ScoreCardPerformanceData?.[0]?.Details?.[0]?.Score} </span>
   <span style={{color:ColorHandler(ScoreCardPerformanceData?.[0]?.Details?.[0]?.Color)}}> {ScoreCardPerformanceData?.[0]?.Details?.[0]?.['for Last Month']} </span> <span style={{color:'#969696'}}> for Last Month </span></div>
     <div style={{color:'#582B99',cursor:'pointer'}} onClick={()=>{history.push('/agent');dispatch(saveSelectedAgentScoreCardDate(userInfo.email))}}>VIEW SCORECARD</div>
    </main>
              </section>
  )
}

export default AgentCardinReports