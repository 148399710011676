import React, {useState } from 'react'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import clock_normal from '../../../../assets/images/Clock_Normal.png';
import Add_Dates from '../../../../assets/admin-images/Add_Dates.png';
import Remove_Dates from '../../../../assets/admin-images/Remove_Dates.png';
import { useEffect } from 'react';

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}
function formatTime(time) {
    let today = new Date(time),
        hours =  today.getHours(),
        minutes = today.getMinutes(),
        seconds = '00'
    if (hours < 10)
        hours = '0' + hours;
        
    if (minutes < 2)
        minutes = '0' + minutes;
    return [hours, minutes, seconds].join(':');
}

const formatTimeIST=(date) =>{
    let hms = date;
var target = new Date("1970-01-01T" + hms);
return target
}

function AddDateTimeFormat(props) {
    const [selectedDate, setSelectedDate] = useState(new Date('01/01/2000'));
    const [startTime, setStartTime] = useState(new Date(''));
    const [endTime, setEndTime] = useState(new Date(''));
    useEffect(() => {
        const {EndTime, StartTime, Date:date = ''} = props.rows;
        //  const { Date:date = '', EndTimeDateObj:endTime = '', StartTimeDateObj:startTime = '', } = props.rows;
       // console.log("rows in Add date time format :::::::::::", props.rows);
        // if(date) {
        //     
        // } 
        setSelectedDate(new Date(date));
        setEndTime(formatTimeIST(EndTime));
        // setStartTime(new Date(startTime));
        setStartTime(formatTimeIST(StartTime))
        // if(endTime) {
        //     setEndTime(new Date(endTime));
        //     console.log(endTime)
        // }
        // else{
        //     setEndTime(formatTimeIST(EndTime))
        // }
        // if(startTime) {
        //     setStartTime(new Date(startTime));
        // }
        // else{
        //     setStartTime(formatTimeIST(StartTime))
        // }
      
    }, [props.rows]);
    
    return (<>
        <main className='input-container-three' style={{ width: "100%" }}>

            <div className='container-three'>
                <label id='label'> Date *</label>&nbsp;

                <KeyboardDatePicker
                    autoOk
                    variant="inline"
                    name='valid_from'
                    clearable
                    value={selectedDate}
                    placeholder="MM/DD/YYYY"
                    onChange={(date) => {
                        setSelectedDate(formatDate(date));
                        props.onChangeExclusionDate('Date', formatDate(date))
                    }}
                    minDate={new Date('01/01/1990')}
                    format="MM/DD/YYYY"
                    error={false}
                    helperText={null}

                /></div>
            <div className='container-three'>
                <label id='label'>Start Time *</label>&nbsp;
                <KeyboardTimePicker
                    autoOk
                    name='valid_from_st'
                    clearable
                    ampm={false}
                    variant="inline"
                    value={startTime}
                    onChange={(date) => {
                        setStartTime(new Date(date));
                        props.onChangeExclusionDate('StartTime', formatTime(date))
                    }}
                    keyboardIcon={<img src={clock_normal} alt='' />}
                    error={false}
                    helperText={null}

                />

            </div>
            <div className='container-three'>
                <label id='label'>End Time *</label> &nbsp;
                <KeyboardTimePicker
                    autoOk
                    clearable
                    name='valid_from_et'
                    ampm={false}
                    variant="inline"
                    value={endTime}
                    onChange={(date) => {
                        // here date is actual date object, b4 conversion
                        setEndTime(new Date(date));
                        props.onChangeExclusionDate('EndTime', formatTime(date))
                    }}
                    keyboardIcon={<img src={clock_normal} alt='' />}
                    error={false}
                    helperText={null}

                />
            </div>
            <div  onClick={props.onClickDeleteButton}>
               <img className='remove_dates' src={Remove_Dates} alt=''/>
            </div>
        </main>
    </>)
}




export function ExclusionModalHandler(props) {
    console.log(props.ExclusionData)
    // const Exclusion_initialState = {
    //     Comments: '',
       
    // }
    const [displayRows, setDisplayRows] = useState([])
    const [exclusionData, setExclusionData] = useState([])
    const [commentAttribute, setCommentAttribute] = useState('');
    const [validation, setValidation] = useState('');
    const [editFormInfo, setEditFormInfo] = useState({});
   // const [addExemptionForm, setAddExemptionForm] = useState(Exclusion_initialState);
    let editMode = props.editMode;

    const onChangeExclusionDate = (type, date, index) => {
        const allDisplayRows = [...displayRows];
        console.log(allDisplayRows)
        const updateDisplayRows = allDisplayRows.map((row, i) => {
            if( i === index ) {
                return {
                    ...row,
                    // [type+'DateObj']: new Date(), //  actualTimeObj ||
                    [type]: date
                }
            } else {
                return row;
            }
        });
        setDisplayRows(updateDisplayRows);
       
    }
    const onClickAddNewDate = () => {
        let arr1 = [...displayRows];
        let temp = { id: displayRows.length + 1 }
        arr1.push(temp);
        setDisplayRows(arr1);
        console.log(displayRows)
    }

    const onClickDeleteButton = (id) => {
        let getExistingRows = [...displayRows];
        // console.log("before delete :::::", arr1)
       // arr1.splice(i,1)
        // console.log("after delete :::::::", arr1)
        const getDeletedRows = getExistingRows.filter((obj, i) => i !== id);
        console.log("after delete :::::::", getDeletedRows)
        setDisplayRows(getDeletedRows)
    }
    const onChangeExclusionInput = (event) => {
        event.preventDefault();
       // setCommentAttribute(event.target.value);
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        if (editMode) {
            const newFormData = { ...editFormInfo};
            newFormData[fieldName] = fieldValue;
            setCommentAttribute(newFormData)
        }
        else {
            const newFormData = { ...displayRows };
            newFormData[fieldName] = fieldValue;
            setCommentAttribute(newFormData)
        }
    }
    const onClickSubmit=(event)=>{
        event.preventDefault();
        let temp = {
            comments:commentAttribute,
            data:displayRows
        }
        console.log(temp)
    //    const temp_arr = Object.values(displayRows).every(row => Object.values(row).length >= 3)
    //    console.log('temp_arr',temp_arr)
    //    if(!temp_arr){
    //   setValidation('Please Ensure you filled all the inputs with  " * " mark')
    //    }
    //    else{
        props.handleExclusionFormSubmit(temp);
    //   }
    }
    console.log(displayRows)
   console.log(commentAttribute)
    useEffect(() => {
        let temp = [
            {
                Date:'',
                EndTime:'',
                StartTime:''
            }
        ]
        console.log(props.ExclusionData?.Data)
        setDisplayRows(props.ExclusionData ? (props.ExclusionData?.Data !== null ? props.ExclusionData?.Data : temp) : temp);
    }, [])
    
        return (
        <>
            <form onSubmit={onClickSubmit} style={{ marginRight: -30 }}>
                <header className="modal_heading">Request for SLA Data Exclusion</header>

                <div className='sla_name'>SLA Name: <span style={{ color: "#000000" }}>{props.ExclusionName}</span></div>
                <div style={{ color: '#ED4F32' }}>{props.validation ? props.validation : validation}</div>
                <section className='scrollable_Section' style={{ height: '250px', overflowY: 'scroll', overflowX: 'hidden' }}>
                    {displayRows.map((rows, i) => {
                        console.log("rows :::::::::::::::::::", rows);
                        return (<AddDateTimeFormat
                        rows={rows}
                        onChangeExclusionDate={(type, date) => onChangeExclusionDate(type, date, i)}
                        // dateObj
                        onClickDeleteButton={() => onClickDeleteButton(i) } key={i}/>)}
                        )}
                    <main className='input-container-two'>
                        <div onClick={onClickAddNewDate}>
                            <img src={Add_Dates} alt=''/>
                        </div>
                    </main>

                    <main className='input-container-two'>
                        <div className='container-one'>
                            <label >Comments *</label>
                            {/* <input type='text'
                                name='comments'
                                placeholder='Example: exclude 30 minutes'
                                onChange={onChangeExclusionInput}
                            /> */}
                            {editMode ? <input type='text'
                        name='comments'
                        required='required'
                        placeholder='Example: Audi account has undergone changes in Brazil.....'
                        value={editMode && editFormInfo?.comments}
                        onChange={onChangeExclusionInput}
                    /> :
                        <input type='text'
                            name='comments'
                            required='required'
                            placeholder='Example: Audi account has undergone changes in india.....'
                            onChange={onChangeExclusionInput} />
                    }
                        </div>
                    </main>
                </section>
                <main style={{ display: 'flex', marginLeft: '100px', gap: '30px' }}>
                    <button type='submit-button' className='add-button'  >
                        <span className='add-span' >SUBMIT</span>
                    </button>
                    <button className='cancel-button' onClick={props.handleAddRoleCancel}>
                        <span className='cancel-span'>CANCEL </span>
                    </button>
                </main>
            </form>
        </>
    )
}


