import React, { useState, useEffect } from "react";
import { useSelector, useDispatch} from "react-redux";
import { useHistory } from "react-router-dom";
import BredCrumb from "../../Breadcrumb/bredcrumb";
import { KeyboardDatePicker } from "@material-ui/pickers";
import LegendComponent from "../../Legend_CSV/legend";
import AccountDetails  from "../Account-Details/account-details";
import {getRegionalSiteGlobalSummaryData} from "../../../actions/regionalSite.action";
import { updateCalendarDateFormat, updateCalendarISOStringFormat, updateCalendarDate } from "../../../actions/user.action";
import { updateRegionalSiteCustomerName, updateRegionalSiteCustomerID, getRegionalSiteCustIDData } from "../../../actions/regionalSite.action";
const AccountGlobal = (props) => {
  
    const dispatch = useDispatch();
    const history = useHistory();

    const dateToYMD = (date) => {
        var strArray = [
          "JAN",
          "FEB",
          "MAR",
          "APR",
          "MAY",
          "JUN",
          "JUL",
          "AUG",
          "SEP",
          "OCT",
          "NOV",
          "DEC",
        ];
        var m = strArray[date.getMonth()];
        var y = date.getFullYear();
        return m + " " + y;
      };
        
    const [accName, setAccName] = useState('');
    const selectedAccLocationName = 'Global Accounts';
    const selectedAccCustName = useSelector((state) => state.regionalSiteReducer.rgnCustName);
    const selectedAccCustID = useSelector((state) => state.regionalSiteReducer.rgnCustId);
    const regionalCustomerIDData = useSelector((state) => state.regionalSiteReducer.rgnCustIdData);
    const isoDateString = useSelector((state) => state.userReducer.cal_isostring_frmt);
    const selectedDate = useSelector((state) => state.userReducer.cal_dt);
    const formattedDate = useSelector((state) => state.userReducer.cal_dt_frmt);
    const breadCrumbData = useSelector((state) => state.userReducer.breadCrumbArr);
    const [brdData, setBrdData] = useState([]);
    const getClientCustID = () => { 
        return selectedAccCustID;
    };

  const handleDateChange = (e) => {    
    dispatch(updateCalendarDate(new Date(e)));
    dispatch(updateCalendarDateFormat(dateToYMD(new Date(e))));
    dispatch(updateCalendarISOStringFormat(new Date(e).toISOString().split('T')[0]));
    updateBreadCrumb(new Date(e));    
    dispatch(getRegionalSiteCustIDData(selectedAccCustID, new Date(e).toISOString().split('T')[0]));
  };  

  const glblSmmryBtnClickHandler = () => {
    dispatch(getRegionalSiteGlobalSummaryData(selectedAccCustID,0,0,isoDateString));
    history.push('/regional_global_summary');
  };

 
  const updateBreadCrumb = (dt) => {
    let tmpArr = [];
    tmpArr.push(breadCrumbData[0]);
    let rptObj = {name : 'Reports', reDirect: '/regional' };
    tmpArr.push(rptObj);     
    let brdData = selectedAccCustName + ' - ' + selectedAccLocationName + ' - ' + dateToYMD(dt);
    let obj = {name : brdData, reDirect: '' };
    tmpArr.push(obj);   
    setBrdData(tmpArr);
  };

  useEffect(()=>{  
    if(selectedAccCustName && selectedAccLocationName){    
      updateBreadCrumb(selectedDate);
    }    
    
  }, [breadCrumbData]);

    return (
      <div className="gsd-main-container">                 
          <BredCrumb  data={brdData}/>
          <div className="client-row">
            <div className="row hd">
              <div className="client-icon">
                <img
                  className="client-icon-img"
                  type="image/jpeg"
                  src={`${process.env.REACT_APP_BASE_URL}/api/org/customer/${getClientCustID()}/photo`}
                  alt="Client Logo"
                />
              </div>
              <div className="client-name">
              {selectedAccCustName}<span className="border-right"></span>
              </div>
              <div className="client-date">
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  label="Select Month"
                  value={selectedDate}
                  onChange={handleDateChange}
                />
              </div>
            </div>
          </div>
          <LegendComponent shwGlbBtn={true} glblSmmryBtnClicked={glblSmmryBtnClickHandler} />
          <div style={{ width: "99.5%" }}>
            {
                regionalCustomerIDData?.map((itm, i)=>{
                    return <AccountDetails data={itm} key={i} />;
                })
            }           
          </div>      
      </div>
    );
};

export default AccountGlobal;
