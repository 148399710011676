import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteModalPopup } from '../../Onboard/OnboardComponents/Modals/DeleteModalPopup';
import { CancelModalPopup } from '../../Onboard/OnboardComponents/Modals/OnboardButtonsPopup';
import Contractual_SLA_Icon from './../../../assets/images/Contractual_SLA_Icon.png';
import { AddFormModal, AddVolumePopup, CancelFieldFragment } from './AddFormModal';
import { FormField } from './FormField';
import { useSelector, useDispatch } from 'react-redux';
import { updateSelectedSLAKPIVolumeStart } from '../../../actions/uploads.action';
import { getRoleBasedView } from '../../../actions/Administration/admin.action';

export function FormFieldHandle(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const SelectedAccountDetails = useSelector(state => state.uploadReducer.SelectedAccountDetails);
    const SelectedSLAKPIVolumeTab = useSelector(state => state.uploadReducer.SelectedSLAKPIVolumeTab);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch])

    const [editMode, setEditMode] = useState(false);
    const [showAddVolume, setShowAddVolume] = useState(false);
    const [editFormField, setEditFormField] = useState();
    const [editFieldID, setEditFieldID] = useState(null);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
const[showCancelModal,setShowCancelModal] = useState(false)
    useEffect(() => {
        setSelectedTemplates(props.data || []);
    }, [props.data]);

    const toggleTemplate = (templateID) => {
        setSelectedTemplates(prevTemplates => {
            return prevTemplates.map(template => {
                if (template.templateID === templateID) {
                    return { ...template, toggle: !template.toggle };
                }
                return template;
            });
        });
        // Call onTemplateChange with true to indicate changes
        props.onTemplateChange(true);
    };

    const handleSelectAll = (event) => {
        const isChecked = event.target.checked;
        const updatedTemplates = selectedTemplates.map(template => ({
            ...template,
            toggle: isChecked,
        }));
        setSelectedTemplates(updatedTemplates);
        setCheckedAll(isChecked);
    };

    const dispatchSelectedTemplatesHandler = () => {
        return selectedTemplates
            .filter(template => template.toggle)
            .map(data => ({
                AccountID: SelectedAccountDetails.CustomerID,
                SLATemplateID: data.templateID,
                ServicedeskID: SelectedAccountDetails.ServiceDeskID,
                Contractual: data.contractual ? 1 : 0,
                toggle: data.toggle ? 1 : 0,
                Category: SelectedSLAKPIVolumeTab,
            }));
    };

    const onClickSaveFormField = () => {
        props.onTemplateChange(false);
        const templatesToDispatch = dispatchSelectedTemplatesHandler();
        dispatch(updateSelectedSLAKPIVolumeStart(templatesToDispatch));
        if(SelectedSLAKPIVolumeTab === 'Volume'){
          props.shiftToSLAKPITab()
        }
        else{
       history.push('/upload/account');
       }
       window.scrollTo(0, 0);
        
    };

    const onClickEditForm = (field, index) => {
        setEditMode(true);
        setEditFieldID(index);
        setShowAddVolume(true);
        setEditFormField(field);
    };

    const handleEditFormField = (editedRow) => {
        const updatedTemplates = selectedTemplates.map((template, index) => 
            index === editFieldID ? { ...editedRow, id: editFieldID } : template
        );
        setSelectedTemplates(updatedTemplates);
        setEditFieldID(null);
        setShowAddVolume(false);
    };

    const onClickCancelButton = () => {
        const templatesAreEqual = JSON.stringify(props.data) === JSON.stringify(selectedTemplates);
        if (templatesAreEqual) {
            history.push('/upload');
        } else {
            setShowCancelModal(true);
        }
    };

    return (
        <>
            <br />
            <main className='display_flex'>
                <div id='data'>Select a custom data field for your {props.tabName} data</div>
               {RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 && <div className='add_arrow' onClick={() => { setShowAddVolume(true); setEditMode(false); }}> 
                    Add {props.tabName} Data 
                </div>}
            </main>
            <br />
            <main className='display_flex' style={{ marginLeft: 50 }}>
               {RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 && <div id='toggle'>
                    <span className='toggle-container'>
                        <label className={checkedAll ? 'switch active' : 'switch'}>
                            <input
                                type='checkbox'
                                checked={checkedAll}
                                onChange={handleSelectAll}
                            />
                            <span className='slider' />
                        </label>
                    </span> &nbsp; Select All
                </div>}
                <div id='legend'>
                    Legend: <img src={Contractual_SLA_Icon} alt='' /> 
                    <span id='span'>Contractual SLAs/KPIs</span>
                </div>
            </main>
            <main className='display_grid'>
                {selectedTemplates.map((field, i) => (
                    <FormField 
                        key={field.templateID}
                        contractual={field.contractual} 
                        fieldName={field.name}
                        description={field.description} 
                        onClickEdit={() => onClickEditForm(field, i)}
                        isSelected={field.toggle}
                        togglehandler={() => toggleTemplate(field.templateID)}
                        toggleView={RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 ? true : false}
                    />
                ))}
            </main>
          {RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 && <footer className='button-wrapper'>
                <button className='btn_adduser' onClick={onClickSaveFormField}>
                    SAVE
                </button>
                <button className='btn_canceluser' onClick={onClickCancelButton}>
                    CANCEL
                </button>
            </footer>}

            {showAddVolume && 
                <AddVolumePopup 
                    isOpen={showAddVolume} 
                    setShowModal={setShowAddVolume} 
                    style={{ height: '320px' }}
                >
                    <AddFormModal  
                        editMode={editMode} 
                        title={SelectedSLAKPIVolumeTab === 'Volume' ? 'Volume Data' : 'SLAs/KPIs Data'}
                        handleAddTemplateModelClose={() => setShowAddVolume(false)} 
                        editFormField={editFormField} 
                        handleEditFormField={handleEditFormField}
                    />
                </AddVolumePopup>
            }

            {showCancelModal && 
                <CancelModalPopup 
                    isOpen={showCancelModal} 
                    setShowModal={setShowCancelModal}
                >
                    <CancelFieldFragment
                        handleSaveQueue={onClickSaveFormField}
                        handleDeleteQueue={onClickCancelButton} 
                        tab={props.tabName}
                        handleDeleteQueueCancel={() => setShowCancelModal(false)}
                    />
                </CancelModalPopup>
            }
        </>
    );
}
