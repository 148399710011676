import React, { useEffect, useState } from 'react'
import { VerticalBarChart } from './Bar-charts';
import './Analytics.css';
import moment from 'moment';
import './Analytics.css'
import { CalendarModel, CalendarModelpopup } from './DTM-ModalPopup';
import Download_Icon from "../../assets/images/Download_Normal.png"
import { useDispatch, useSelector } from 'react-redux';
import { getDTMAnalyticsData, getDTMAnalyticsHalfHour, getDTMAnalyticsMonth, updateRegionalSiteCustomerID, updateRegionalSiteCustomerName } from '../../actions/regionalSite.action';
import AdminBreadcrumb from '../Administration/General/Admin-breadcrumb';
import * as htmlToImage from 'html-to-image';
import { saveAccountLocationName, saveSelectedMenuItem, updateDTMReportDate, updateSelectedAccountName } from '../../actions/tabMenu.action';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';

function DTMAnalyticsHandler() {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  // const customerID = useSelector((state) => state.regionalSiteReducer.rgnCustId);
  // const serviceID = useSelector((state) => state.regionalSiteReducer.rgnServiceID);

const reportcustomerID = useSelector((state) => state.regionalSiteReducer.rgnCustId);
const reportserviceID = useSelector((state) => state.regionalSiteReducer.rgnServiceID);
const selectedSLA = useSelector((state) => state.regionalSiteReducer.selectedSLA);
const reportcustomerName = useSelector((state) => state.regionalSiteReducer.rgnCustName);
let selectedCustIDServID = useSelector((state) => state.welcomePageReducer.selectedCustIDServID);
  console.log('selectedCustIDServID',selectedCustIDServID)
  const customerID = reportcustomerID === null ? selectedCustIDServID.CustomerID  : reportcustomerID;
  const serviceID = reportserviceID === '' ? selectedCustIDServID.ServiceDeskID : reportserviceID;
  const customerName = reportcustomerName === null ? selectedCustIDServID.CustomerName : reportcustomerName
  const selectedDate = useSelector((state) => state.tabReducer.DTMReportPageDate);
  const DTMAnalytics = useSelector((state) => state.regionalSiteReducer.DTMAnalytics);
  const DTMHalfHour = useSelector((state) => state.regionalSiteReducer.DTMHalfHour);
  const DTMMonthlyData = useSelector((state) => state.regionalSiteReducer.DTMMonthlyData);
  
  // const initialDate = useSelector((state) => state.userReducer.selectedDate);

  const formatDate = (date) => {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    return [year, month, '01'].join('-');

  }


  const formatTime = (value) => {
    let totalSeconds = value;
    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600; let minutes = Math.floor(totalSeconds / 60);
    let seconds = parseInt(totalSeconds % 60);
    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");
    if (seconds.length < 2)
      seconds = '0' + seconds;
    return (hours + ":" + minutes + ":" + seconds);
  }

  const [currentDate, setCurrentDate] = useState(moment())
  useEffect(() => {
    let newDate = new Date(selectedDate)
    let year = !isNaN(newDate?.getFullYear()) ? newDate?.getFullYear() : new Date().getFullYear();
    let month = !isNaN(newDate?.getMonth()) ? newDate?.getMonth() : new Date().getMonth();
    setCurrentDate(moment().set({ 'year': year, 'month': month }))
    const myDate = moment().set({ 'year': year, 'month': month })
    let obj1 = {
     // customerID:location.pathname=== 'reports' ? customerID : '60',
      customerID: customerID,
      serviceID: serviceID,
      view: '',
      date: formatDate(myDate),
      item: selectedSLA.slaItem
    }
    let obj3 = {
      customerID: customerID,
      serviceID: serviceID,
      view: '12+month',
      date: formatDate(myDate),
      item: selectedSLA.slaItem
    }
    dispatch(getDTMAnalyticsData(obj1))
    dispatch(getDTMAnalyticsMonth(obj3))
  }, [])



  const [DTMAnalyticsData, setDTMAnalyticsData] = useState([]);
  const [DTMHalfHourData, setDTMHalfHourData] = useState([]);
  const [DTMMonthlyInfo, setDTMMonthlyInfo] = useState([]);

  useEffect(() => {
    setDTMAnalyticsData(DTMAnalytics);
    setDTMHalfHourData(DTMHalfHour);
    setDTMMonthlyInfo(DTMMonthlyData)
  }, [DTMAnalytics, DTMHalfHour, DTMMonthlyData])


  function OnClickBarHandle(label) {
    let newDate = new Date(label?.chart?.tooltip?.title?.[0]);
    let year = newDate.getFullYear();
    let month = newDate.getMonth();
    // dispatch(updateDTMReportDate(formatDate(newDate)));
    let obj1 = {
      customerID: customerID,
      serviceID: serviceID,
      view: '',
      date: formatDate(newDate || new Date()),
      item: selectedSLA.slaItem
    }
    let obj3 = {
      customerID: customerID,
      serviceID: serviceID,
      view: '12+month',
      date: formatDate(newDate || new Date()),
      item: selectedSLA.slaItem
    }
    setShowOffCanvasModel(false)
    dispatch(getDTMAnalyticsData(obj1))
    dispatch(getDTMAnalyticsMonth(obj3))
    setCurrentDate(moment().set({ 'year': year, 'month': month }));

  }

  const monthlyoptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    scales: {
      x1: {
        display: true,
        title: {
          display: false,
          text: 'October 21'
        }
      },
      y: {
        display: true,
        title: {
          display: false,
          text: 'Calls Handled'
        },
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },
    onClick: (label => OnClickBarHandle(label)),
    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        displayColors: false,
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: function (context) {
            return context[0].label
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString() + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`
          }
        }
      }
    },
    onHover: (event, chartElement) => {
      const target = event.native ? event.native.target : event.target;
      target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    }
  };
  const timeoptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    scales: {
      xAxes: {
        ticks: {
          maxRotation: 90,
          minRotation: 90
        },
      },
      yAxes: {
        display: true,
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },
    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: function (context) {
            return context[0].label
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString() 
          }
        }
      }
    },
  }
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    scales: {
      x1: {
        display: true,
      },
      y: {
        display: true,
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },
    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: context => {
            return context[0].label.includes(':') ? context[0].label : `${currentDate.format('MM')}/${context[0].label}/${currentDate.format('YYYY')}`
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString()
          }
        }
      }
    },
  }
  const DTMoptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: false,

    },
    title: {
      display: false,
    },
    scales: {
      x1: {
        display: true,
      },
      y: {
        display: true,
        ticks: {
          callback: function (value) {
            return selectedSLA.format === 'Time' ? formatTime(value) : value
          }
        },
      },
    },
    plugins: {
      legend: { display: false, position: 'right' },
      tooltip: {
        displayColors: false,
        backgroundColor: 'white',
        bodyColor: 'black',
        titleMarginBottom: 0,
        borderColor: '#969696',
        borderWidth: 1,
        titleColor: '#63666A',
        titleAlign: 'center',
        titleFont: { weight: '400' },
        padding: 10,
        cornerRadius: 0,
        caretSize: 5,
        bodyFont: { weight: 'bolder' },
        callbacks: {
          title: context => {
            return context[0].label.includes(':') ? context[0].label : `${currentDate.format('MM')}/${context[0].label}/${currentDate.format('YYYY')}`
          },
          label: function (context) {
            return selectedSLA.format === 'Time' ? formatTime(context.raw) : context.raw.toString() + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`
          }
        }
      }
    },
  }
  const [showOffCanvasModel, setShowOffCanvasModel] = useState(false)
  const [showCalendarModel, setShowCalendarModel] = useState(false);
  function daysInMonth(d) {
    let date = new Date(d)
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  }
  const DTMLabelsHandler = (label) => {
    switch (label) {
      case 'dailyPerformance':
      case 'DTM': {
        const temp_arr = [];
        let n = 1;
        while (n <= daysInMonth(currentDate)) {
          temp_arr.push(n);
          n++;
        }
        return temp_arr;
      }
      case 'CalendarPopup':
      case 'HandledDetails': {
        const temp_arr = [];
        let a = 0
        while (a <= 23) {
          temp_arr.push(`${a}:00`);
          temp_arr.push(`${a}:30`);
          a++;
        }
        return temp_arr;
      }
      case 'pastMonthTrends': {
        var monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var d = new Date(currentDate);
        d.setDate(1);
        let temp_arr = [];
        for (let i = 12; i >= 0; i--) {
          temp_arr[i] = monthName[d.getMonth()] + ' ' + d.getFullYear()
          d.setMonth(d.getMonth() - 1);
        }
        // let temp_arr = Array.apply(0, Array(12)).map(function (_, i) { return `${moment().month(i).format('MMM')} ${moment().month(i).format('YYYY')}` });
        return temp_arr
      }

      default: { }
    }
  }

  const dataFixedvValues = (data) => {
    switch (selectedSLA.format) {
      case 'Percentage': return (data?.fvalue * 100)?.toFixed(2)
      case 'Number': return (data?.fvalue);
      case 'Time': return (data?.fvalue)?.toFixed(2)
      default: { }
    }
  }

  const DTMChartsDataHandler = (data, label) => {
    let temp_dtm = [];
    data?.[label]?.map((data, i) => {
      temp_dtm[i] = dataFixedvValues(data)
    })
    return temp_dtm;
  }



  const [day, setDay] = useState('')
  const [targetValue, setTargetValue] = useState(null)
  const calendarClickHandle = (day) => {
    debugger
    setDay(day);
    let temp = DTMChartsDataHandler(DTMAnalyticsData, 'dailyperformance');
    const dt = new Date(day).getDate();
    setTargetValue(temp[dt - 1]);
    let obj2 = {
      customerID: customerID,
      serviceID: serviceID,
      view: 'halfhour',
      date: day.format('MM/DD/YYYY'),
      item: selectedSLA.item
    }
    dispatch(getDTMAnalyticsHalfHour(obj2))
    setShowCalendarModel(true);
  }
  const CalendarDailyValues = (day) => {
    let temp_dtm = [];
    DTMAnalyticsData?.dailyperformance?.map((data, i) => {
      temp_dtm[i] = data?.fvalue
    })
    const dt = new Date(day).getDate();
    let val = temp_dtm[dt - 1]
    switch (selectedSLA.format) {
      case 'Percentage': {
        if (!isNaN(val) && val !== null) return parseFloat(val * 100)?.toFixed(2) + ' %';
        return ''
      }
      case 'Time': {
        if (!isNaN(val) && val !== null) return formatTime(val);
        return ''
      }
      case 'Number': {
        if (!isNaN(val) && val !== null) return (val);
        return
      }
      default: { }
    }
  }

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const [calendar, setCalendar] = useState([])
  const [weekOfTheYear, setWeekOfTheYear] = useState([]);
  const startingDate = currentDate.clone().startOf('month').format('MM/DD/YYYY');
  const endingDate = currentDate.clone().endOf('month').format('MM/DD/YYYY');
  function beforeToday(day) {
    if (day.isBefore(new Date(startingDate), "day")) return "Yes its working"
  }
  function afterToday(day) {
    if (day.isAfter(new Date(endingDate), "day")) return "Yes its working"
  }

  useEffect(() => {
    const startDay = currentDate.clone().startOf("month").startOf("week");
    const endDay = currentDate.clone().endOf("month").endOf("week");
    const day = startDay.clone().subtract(1, "day");
    const temp_calendar = [];
    while (day.isBefore(endDay, "day")) {
      temp_calendar.push(Array(7).fill(0).map(() => day.add(1, "day").clone()))
    }
    const temp_arr_one = temp_calendar[0]?.map(day => {
      if (Number(day.format('DD')) > 20) return '';
      else return day
    })
    const temp_arr_two = temp_calendar[temp_calendar.length - 1]?.map(day => {
      if (Number(day.format('DD')) < 10) return '';
      else return day
    })
    temp_calendar[0] = temp_arr_one;
    temp_calendar[temp_calendar.length - 1] = temp_arr_two;
    setCalendar(temp_calendar);
    let temp_week = temp_calendar?.map((week) => {
      if (temp_calendar?.indexOf(week) === 0) return week?.[week?.length - 1].format('WW');
      else return week[1] ? week[1].format('WW') : week[0].format('WW');
    });
    setWeekOfTheYear(temp_week);
  }, [currentDate]);

  const exportAsPicture = (id, image) => {
    htmlToImage.toPng(document.getElementById(id), {
      quality: 0.90,
    })
      .then(function (dataUrl) {
        let link = document.createElement('a');
        link.download = image;
        link.href = dataUrl;
        link.click()
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  const ReportRedirect = () => {
    if (DTMAnalytics?.servicedesk?.id) {
      window.localStorage.setItem('ServiceDeskID', DTMAnalytics?.servicedesk?.id);
    }
    if (DTMAnalytics?.customer?.id) {
      window.localStorage.setItem('CustomerID', DTMAnalytics?.customer?.id);
    }
    dispatch(updateRegionalSiteCustomerID(DTMAnalytics?.customer?.id));
    dispatch(updateRegionalSiteCustomerName(DTMAnalytics?.customer?.name));
    dispatch(updateSelectedAccountName(DTMAnalytics?.customer?.name));
    dispatch(saveAccountLocationName(DTMAnalytics?.servicedesk?.name));
    dispatch(saveSelectedMenuItem("reports"));
    dispatch(updateDTMReportDate(new Date(formatDate(currentDate))));
    history.push("/reports");
  }
  return (
    <>
      {/* <AdminBreadcrumb value1='/welcome' label1='Home' isActive2={true} label2='Reports' value2='/regional'
        isActive3={true} label3={customerName + ' - DTM Analytics - ' + currentDate.format('MMMM') + ' - ' + currentDate.format('YYYY')} onClick={ReportRedirect} isActive4={true} label4={selectedSLA.slaItem} /> */}
      <AdminBreadcrumb value1={()=>history.push('/welcome')} label1='Home' isActive2={true} label2='Reports' value2={()=>history.push('/regional')}
        isActive3={true} label3={customerName} value3={ReportRedirect} isActive4={true}
        label4='DTM' value4={ReportRedirect} isActive5={true} label5={currentDate.format('MMMM') + ' - ' + currentDate.format('YYYY')} value5={ReportRedirect}
        label6={"Analytics - " + selectedSLA.slaItem} isActive6={false}
      />
      <section className='dtm_analytics_container' id='whatever'>
        <main className='calls_handled_text'><div>{customerName} - {selectedSLA.slaItem} - {`${currentDate.format('MMMM')} ${currentDate.format('YYYY')}`}</div>
          <div style={{ cursor: 'pointer' }} onClick={() => { exportAsPicture('whatever', selectedSLA.slaItem) }}>
            <button className='btnn'> <img src={Download_Icon} alt='' /> &nbsp; DOWNLOAD</button>
          </div>
        </main>
        <section className='header_container'>
          <div id='first'>
            <div className='second_one'>  {selectedSLA.slaItem}   </div>
            <div className='first_one'>{selectedSLA.format === 'Time' ? formatTime(DTMAnalyticsData?.currentmonth?.[0]?.fvalue) : DTMAnalyticsData?.currentmonth?.[0]?.value}</div>
          </div>
          <div id='second' onClick={() => setShowOffCanvasModel(true)} ><div>Past 12 Months Trend</div>
          </div>
        </section>
        <VerticalBarChart heading='Day To Month (DTM)'
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
          xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`} id='dtm'
          image={selectedSLA.slaItem + `_${selectedSLA.format === 'Percentage' ? '(%)' : ''}_DTM_report`}
          labels={DTMLabelsHandler('DTM')} data={DTMChartsDataHandler(DTMAnalyticsData, 'daytomonth')} color='#00A3E1'
          options={DTMoptions}
        />
        <VerticalBarChart heading='Daily Performance'
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
          xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`} id='daily'
          image={selectedSLA.slaItem + `_${selectedSLA.format === 'Percentage' ? '(%)' : ''}_Daily_report`}
          labels={DTMLabelsHandler('dailyPerformance')} data={DTMChartsDataHandler(DTMAnalyticsData, 'dailyperformance')} color='#00968F'
          options={options} />
        <section className='calander_container'>
          <header className='header_values'>
            <div id='div1'>Daily Values</div>
          </header>
          <table className='table_calendar'>
            <thead>
              <tr>
                <th>{currentDate.format('YYYY')}</th> {weekDays.map((week, i) => (<th key={week}>{week}</th>))}
              </tr>
            </thead>
            <tbody>
              {calendar?.map((week, i) => (<tr>
                <td id='week_id'> <div id='week_no'>{weekOfTheYear?.[i]} </div> Week</td>
                {week?.map((day) => (<td>
                  <div id='date_month'>{day && day.format("DD MMM").toString()} </div>
                  <div id='percent' onClick={() => calendarClickHandle(day)}>{CalendarDailyValues(day)}  </div>
                </td>))}</tr>))}
            </tbody>
          </table>
        </section>
        <VerticalBarChart heading={`Handled Details (Average: 01/${currentDate.format('MM')}/2021 to ${daysInMonth(currentDate)}/${currentDate.format('MM')}/2021)`}
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
          xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`} id='details'
          image={selectedSLA.slaItem + `_${selectedSLA.format === 'Percentage' ? '(%)' : ''}_handleed_report`}
          labels={DTMLabelsHandler('HandledDetails')} data={DTMChartsDataHandler(DTMAnalyticsData, 'halfhourdata')} color='#00968F'
          options={ timeoptions} />
      </section>
      <br /><br />

      {showCalendarModel && <CalendarModelpopup isOpen={setShowCalendarModel} setShowModal={setShowCalendarModel}  >
        <CalendarModel day={day} target={selectedSLA.format === 'Time' ? formatTime(targetValue) : targetValue} heading={true}
          closewindow={() => setShowCalendarModel(false)}
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
          xAxesTitle={`${currentDate.format('MMMM')}, ${currentDate.format('YYYY')}`} id='calendar'
          image={selectedSLA.slaItem + `_${selectedSLA.format === 'Percentage' ? '(%)' : ''}_halfHour_report`}
          labels={DTMLabelsHandler('CalendarPopup')} data={DTMChartsDataHandler(DTMHalfHourData, 'halfhourdata')} color='#00968F'
          options={ timeoptions}
        />
      </CalendarModelpopup>
      }
      {showOffCanvasModel && <CalendarModelpopup isOpen={setShowOffCanvasModel} setShowModal={setShowOffCanvasModel}  >
        <CalendarModel heading={false}
          closewindow={() => setShowOffCanvasModel(false)}
          yAxesTitle={selectedSLA.slaItem + ` ${selectedSLA.format === 'Percentage' ? '(%)' : ''}`}
          xAxesTitle='Month & Year' id='yearly'
          image={selectedSLA.slaItem + `_${selectedSLA.format === 'Percentage' ? '(%)' : ''}_yearly_report`}
          labels={DTMLabelsHandler('pastMonthTrends')} data={DTMChartsDataHandler(DTMMonthlyInfo, 'monthdata')} color='#00A3E1'
          options={monthlyoptions}
        />
      </CalendarModelpopup>
      }
    </>
  )
}

export default DTMAnalyticsHandler;