import React, { useEffect, useState } from 'react'
import Expand_DownArrow from './../../assets/onboard-images/Expand_DownArrow.png';
import Collapse_UpArrow from './../../assets/onboard-images/Collapse_UpArrow.png';
import Edit_Icon from './../../assets/onboard-images/Edit_Icon.png';


function RequestTableHandle({ info, HandleEditRequestClick }) {

    const [ID,SetID] = useState();
    const urlfile = `${process.env.REACT_APP_BASE_URL}/api/file/${ID}`

    useEffect(()=>{
      if(ID){
        window.open(urlfile);
      }
    },[ID,urlfile])
  

  const [showAccordion, setShowAccordion] = useState(false);
  function RequestDateFormat(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [month, day, year].join('/');
}
  return (<>
    <tr key={info.IncidentID} >
      <td id='table_tr' style={{ border: 'none' }}>{info?.['Request / Incident Title']} </td>
      <td id='table_tr' style={{ border: 'none' }}>{RequestDateFormat(info?.['Created Date'])} </td>
      <td id='table_tr' style={{ border: 'none' }}>{info?.Type} </td>
      <td id='table_tr' style={{ border: 'none',color:info?.Status === 'Pending' ? '#ff4444' :'#000000' }}>{info?.Status} </td>
      <td id='table_tr' style={{ border: 'none'}}>
       {info?.Status === 'Completed' || info?.View === 0 ? '' : <img src={Edit_Icon} alt='' style={{cursor: 'pointer' }} onClick={() => HandleEditRequestClick(info)} />}
      </td>
      <td id='table_tr' style={{ border: 'none'}}>
        {info?.View === 0 ? '' :<img src={showAccordion ? Collapse_UpArrow : Expand_DownArrow} alt='' style={{cursor: 'pointer' }}
          onClick={() => {
            setShowAccordion(!showAccordion)
          }} />}
      </td>
    </tr>

    {(showAccordion) &&
      <tr > <th colSpan='15'>
        <section className='table_insider' >
          <div style={{ display: 'flex' }}>
            <span className='table_insider_box' style={{ width: '25%' }}> Last Updated Date</span>
            <span className='table_insider_box' style={{ width: '45%' }}> Comments</span>
            <span className='table_insider_box' style={{ width: '30%' }}> File Attachments</span>
          </div>
          <div>
            {info?.UserComments?.map((update, i) =>  (
              
              <div style={{ display: 'flex',borderTop:'1px solid #63666A4D',padding:'20px' }}>
                <span className='table_insider_body' style={{ width: '25%' }} >{RequestDateFormat(update?.['Last Updated Date'])}</span>
                <span className='table_insider_body' style={{ width: '45%' }} >{update.Comments}</span>
                <span className='table_insider_body' onClick={()=>{SetID(update.FileStore)}} style={{ width: '30%', color: '#5F249F', textDecoration: 'underline',cursor: 'pointer' }}>{update.FileName}</span>
              </div>
            ))}
          </div>
          
          {info?.AdminComments?.map((update, i) => (
            <div className='admin_response'>
            <div id='header'>{update?.UpdatedTS}</div>
            <div id='para'><span id='comments'>Comments: </span> {update.Comments} </div>
            <div> <span id='comments'>File Attachments: <span id='file' onClick={()=>{SetID(update.FileStore)}}>{update.FileName}</span></span></div>
            </div>
          ))}
          
        </section>
      </th></tr>}
  </>
  )
}

export default RequestTableHandle