import actionTypes from "../constants/actionTypes";
const dateToYMD = (date) => {
  var strArray = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  var m = strArray[date.getMonth()];
  var y = date.getFullYear();
  return m + " " + y;
};

const initialState = { userInfo: [], selectedDate: "", token: "", regionalData: [], cal_dt_frmt: dateToYMD(new Date()),
 cal_isostring_frmt: new Date().toISOString().split('T')[0], cal_dt: new Date(), breadCrumbArr: [{name: 'Home', reDirect: '/welcome'}] };

const getUsersData = (state, action) => {
  console.log("Reducer - get user data" + action);
  return {
    ...state,
  };
};

const updateUsersData = (state, action) => {
  console.log("Reducer - get user data" + action);
  return {
    ...state,
    userInfo: action.payload,
  };
};

const getUserToken = (state, action) => {
  console.log("Reducer - get user Token" + action);
  return {
    ...state,
  };
};

const updateUserToken = (state, action) => {
  console.log("Reducer -  user Token" + action);
  return {
    ...state,
    token: action.payload,
  };
};

const getRegionalData = (state, action) => {
  return {
    ...state
  };
}

const updateRegionalData = (state, action) => {
  return{
    ...state,
    regionalData: action.payload
  };
}

const updateCalendarDateFormat = (state, action) => {
  return{
    ...state,
    cal_dt_frmt: action.cal_dt_frmt
  };
}

const updateCalendarISOStringFormat = (state, action) => {
  console.log(action.cal_isostring_frmt)
  return{
    ...state,
    cal_isostring_frmt: action.cal_isostring_frmt
  };

}

const updateCalendarDate = (state, action) => {
  return{
    ...state,
    cal_dt: action.cal_dt
  };
}

const updateBreadCrumb = (state, action) => {
  return{
    ...state,
    breadCrumbArr: action.breadCrumbArr
  };
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_TOKEN:
      return getUserToken(state, action);
    case actionTypes.USER_TOKEN:
      return updateUserToken(state, action);
    case actionTypes.GET_USER_DATA:
      return getUsersData(state, action);
    case actionTypes.USER_DATA:
      return updateUsersData(state, action);
    case actionTypes.GET_REGIONAL_DATA:
      return getRegionalData(state, action);
    case actionTypes.REGIONAL_DATA:
      return updateRegionalData(state, action);
    case actionTypes.UPDATE_CALENDAR_DATE_FORMAT:
      return updateCalendarDateFormat(state, action);
    case actionTypes.UPDATE_CALENDAR_ISOSTRING_FORMAT:
      return updateCalendarISOStringFormat(state, action);
    case actionTypes.UPDATE_CALENDAR_DATE:
      return updateCalendarDate(state, action);
    case actionTypes.UPDATE_BREADCRUMB:
      return updateBreadCrumb(state, action);
    default:
      return state;
  }
};

export default userReducer;
