import React, { useEffect, useState } from 'react';
import './ReportTable.css';


function ReportTable({tblData,headerData}) {
  const Data = tblData
  return (
    <React.Fragment>
       {Data === null ? (
        <section className='noData-table'>
          <div className='noData-para' >
            <div className='noData-para-span'
              style={{ fontSize: '17px', marginLeft: '22em' }}>No records are available. Please select other filters.</div>
          </div>
        </section>
      ) :
        <section className='table-section' style={{ style: '98%' , position: 'relative' }}>
          <table className='report-table'>
            <thead style={{ backgroundColor: '#969696' }}>
              <tr >
                {headerData?.map((info, i) => (
                  <th style={{ border: 'none' }} key={i} >{info.label}</th>
                ))}
              </tr>
            </thead>
            <tbody >
              {Data?.map((info, i) =>
              (<tr key={i}>
                { Object.values(info).map((value, idx) => (
                    <td key={idx}>{value}</td>
                  ))  }
              </tr>))}
            </tbody>
          </table>
        </section>}
      <br />
      <br />
      <br />
    </React.Fragment>
  )
}

export default ReportTable












