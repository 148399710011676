
import { github_actionTypes as actionTypes} from './../constants/github-actionTypes';

export const setDeveloperCard = (data) => (
    {
      type: 'SET_DEVELOPER_CARD_DATA',
      payload: data,
    }
  )

  export const setErrorData = (data) => (
    {
      type: 'SET_AXIOS_ERROR_DATA',
      payload: data,
    }
  )

  export const setDesignCard = (data) => (
    {
      type: 'SET_DESIGN_CARD_DATA',
      payload: data,
    }
  )
  export const getTilesData = (data) => (
    {
      type: actionTypes.GET_TILES_DATA,
      payload: data,
    }
  )