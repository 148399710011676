import React, { useEffect, useState } from 'react'
import './../Upload-Navigation/UploadNavigation.css'
import './FileUpload.css'
import { KeyboardDatePicker } from '@material-ui/pickers';
import ContractualSLAIcon from "./../../../assets/images/Contractual_SLAs.png";
import Download_Normal from './../../../assets/images/Download_Normal.png'
import closeIcon from './../../../assets/images/Close_Window_Icon.png'
import yesIcon from './../../../assets/onboard-images/Complete_Yes_Icon.png'
import { FormInputFormat, FormInputText, isEditableForDate } from './FormInput';
import UploadAdministration_Icon from './../../../assets/images/UploadAdministration_Icon.png'
//import DATA from './DATA.json'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDataAdministrationValidation, setSelectedVolumeSlaDate, updateVolumeSLAKPIDataStart } from '../../../actions/uploads.action';
import { getRoleBasedView } from '../../../actions/Administration/admin.action';
 
function FormFieldHandler({ error, setError }) {
    const dispatch = useDispatch();
    const history = useHistory();    
    const volumeSlakpiSelectedTemplates = useSelector((state) => state.uploadReducer.volumeSlakpiSelectedTemplates);
    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
    const SelectedVoiceNonVoiceItem = useSelector((state) => state.uploadReducer.SelectedVoiceNonVoiceItem);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getRoleBasedView())
    }, [dispatch])
   
   
 
const[Data,setData] = useState()
 
useEffect(()=>{
    setData(volumeSlakpiSelectedTemplates)
},[volumeSlakpiSelectedTemplates])
 

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year,month, '01'].join('/');
}
 
    const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));

    const ValidFromDateHandler = (date) => {
        setSelectedDate((date === null || date === undefined) ? null : formatDate(date));
    }

useEffect(()=>{
    if(selectedDate){
       dispatch(setSelectedVolumeSlaDate(selectedDate))
    }
},[dispatch,selectedDate])



    const FormFieldManager = (field) => {
        switch (field?.noCols) {
            case 1:
                return <FormInputText field={field} AddValueToUploadForm={AddValueToUploadForm} RBACEditable={RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 ? true : false}/>
            case 3:
                return <FormInputFormat field={field} AddValueToUploadForm={AddValueToUploadForm} RBACEditable={RoleBasedView?.[0]?.['File Upload']?.[0]?.ReadWrite === 1 ? true : false}/>
            default:
                return '' ;
        }
    }
    const [addUploadForm, setAddUploadForm] = useState([]);
 
    const AddValueToUploadForm = (data) => {
        // Append new data to the existing array
        const filterData = addUploadForm.filter((obj) => {
            console.log("obj ::::", obj);
            return obj.AccountSLAID !== data.AccountSLAID
        });
        console.log('filterData',[...filterData, data])
        setAddUploadForm([...filterData, data]);

        // setAddUploadForm(prevData => [...prevData, data]);
        // console.log('Updated Form Data:', [...addUploadForm, data]);
    };
 
    // const AddValueToUploadForm = (name, value) => {
    //     const newFormData = { ...addUploadForm };
    //     newFormData[name] = value;
    //     setAddUploadForm(newFormData);
    //     console.log('newFormData',newFormData)
    // }

const [dataSaved,setDataSaved] = useState(false)
const onClickSaveHandler = () =>{

  let dispObj =  [
    {
        "CustomerID": SelectedAccountDetails.CustomerID || '',
        "ServicedeskID":SelectedAccountDetails.ServiceDeskID || '',
        "StartTime":selectedDate,
        "NonVoiceItem":SelectedVoiceNonVoiceItem,
        "Details": addUploadForm
    }]


    console.log('dispObj',dispObj)
    dispatch(updateVolumeSLAKPIDataStart(dispObj))
    setAddUploadForm([])
    setDataSaved(true)
    setError(false);
}

useEffect(()=>{
    dispatch(setDataAdministrationValidation(addUploadForm.length === 0 ? true : false))
},[dispatch,addUploadForm])

console.log('addUploadForm',addUploadForm)
const isEditable = isEditableForDate(selectedDate);
    return (
        <>
        {error && <div className='dataSave_div' style={{backgroundColor:'red'}}><div>{error}</div></div>}
{(dataSaved && addUploadForm.length === 0) && <div className='dataSave_div'>
                    <div> <img src={yesIcon} alt='' /> &nbsp;&nbsp;Data has been saved successfully </div>
                    <div><img src={closeIcon} alt='' style ={{cursor:'pointer'}} onClick={() => setDataSaved(false)} /></div>
                </div>}
{!isEditable  && <div className='Sla_Volume_Note'>Note: You can not edit data for the past months.
 If you need to change historical data, please raise a request through the GSD Insight 
 <span className='request_incident_link' onClick={()=>history.push('/request')}> &nbsp;Request/Incident module</span>.</div>
}
            {Data?.length === 0 ?
                <>
                    <section className="no_records_found">
                        No Fields are selected to upload Volume Data  <br /> To add/edit  Volume Data , click the “Upload Data Administration” link below.<br /><br />
                        <div>
                            <span style={{ marginRight: 10 }}><img src={UploadAdministration_Icon} alt='' /></span>
                            <span style={{ color: '#582B99',cursor:'pointer' }} onClick={() => history.push('/upload/dataAdmin')}>Upload Data Administration</span>
                        </div>
                    </section>
                </>
                : <section className='form_field_container'>
                    <header className='main_header'>
                        <div>
                            <label>Select Month</label><br />
                            <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  value={selectedDate}
                  onChange={(date) => ValidFromDateHandler(date)}
                /> </div>
                        <div style={{ marginTop: 20 }} >
                            <span id='legend'>Legend:</span> &nbsp;
                            <img src={ContractualSLAIcon} alt='' />  &nbsp;  Contractual SLAs/KPIs
                            &nbsp; &nbsp;
                            <button type='submit' className='download_button' 
                            onClick={()=>{window.location.href = `${process.env.REACT_APP_BASE_URL}/api/usp/FileUploadDownload?CustomerID=${SelectedAccountDetails.CustomerID}&ServicedeskID=${SelectedAccountDetails.ServiceDeskID}&Mnth=${selectedDate}&format=excel&filename=VolumeSLAKPI_excel_report.xlsx`}}>
                                <span className='download-span'><img src={Download_Normal} alt='' style={{ marginRight: '10px' }} />Download</span>
                            </button>
                        </div>
                    </header>
                    <section className='form_wall'>
                        <div className='form_container'>
                            {Data?.map(field => (FormFieldManager(field)))}
                        </div>
                      <main className='input-container-four fixed_footer_span' style={{ marginLeft: '20%' }}>
                            <button type='submit' className='btn_adduser' onClick={onClickSaveHandler}>
                                SAVE
                            </button>
                        </main>
                    </section>
                </section>}
        </>
    )
}
 
export default FormFieldHandler