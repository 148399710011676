
import { STAFF_ADMIN_ACTIONTYPES as actionTypes } from '../../constants/Administration/staffAdmin-actionTypes'



export const getAvailableEmployees = (items) => ({
    type: actionTypes.GET_AVAILABLE_EMPLOYEES, items
});

export const getSupervisorList = (data) => (
    {
        type: actionTypes.GET_SUPERVISOR_LIST,
        payload: data,
    }
)

export const updateNewUserRoleStart = (data) => (
    {
        type: actionTypes.UPDATE_NEW_USER_ROLE_START,
        payload: data,
    }
)


export const updateNewUserRoleSuccess = () => (
    {
        type: actionTypes.UPDATE_NEW_USER_ROLE_SUCCESS,
    }
)

export const updateSupervisorEmployeeMappingStart = (data) => (
    {
        type: actionTypes.UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_START,
        payload: data,
    }
)


export const updateSupervisorEmployeeMappingSuccess = () => (
    {
        type: actionTypes.UPDATE_SUPERVISOR_EMPLOYEE_MAPPING_SUCCESS,
    }
)

export const updateAccountEmployeeStart = (data) => (
    {
        type: actionTypes.UPDATE_ACCOUNT_EMPLOYEE_START,
        payload: data,
    }
)


export const updateAccountEmployeeSuccess = () => (
    {
        type: actionTypes.UPDATE_ACCOUNT_EMPLOYEE_SUCCESS,
    }
)

export const getRoleCategory = (data) => ({
    type: actionTypes.GET_ROLE_CATEGORY,
    payload: data
});

export const getStaffAccounts = (empId, supId) => ({
    type: actionTypes.GET_STAFF_ACCOUNTS,
    empId, supId
});

export const saveSupervisorMapping = (data) => ({
    type: actionTypes.SAVE_SUPERVISOR_MAPPING,
    payload: data
});

export const getAccountAssignment = (emp_id, sup_id) => ({
    type: actionTypes.GET_ACCOUNT_ASSIGNMENT,
    empId: emp_id,
    supId: sup_id
});

export const getQueueAssignment = (empId, supId) => ({
    type: actionTypes.GET_QUEUE_ASSIGNMENT,
    empId: empId,
    supId: supId
});

export const getIdAssignment = (empId, supId) => ({
    type: actionTypes.GET_ID_ASSIGNMENT,
    empId: empId,
    supId: supId
});

export const getSlaAssignment = (empId, supId) => ({
    type: actionTypes.GET_SLA_ASSIGNMENT,
    empId: empId,
    supId: supId
});

export const getVolumeAssignment = (empId, supId) => ({
    type: actionTypes.GET_VOLUME_ASSIGNMENT,
    empId: empId,
    supId: supId
});

export const updateQueueAssignmentStart = (data) => (
    {
        type: actionTypes.UPDATE_QUEUE_ASSIGNMENT_START,
        payload: data,
    }
)

export const updateQueueAssignmentSuccess = () => (
    {
        type: actionTypes.UPDATE_QUEUE_ASSIGNMENT_SUCCESS,
    }
)

export const updateIdAssignmentStart = (data) => (
    {
        type: actionTypes.UPDATE_ID_ASSIGNMENT_START,
        payload: data,
    }
)

export const updateIdAssignmentSuccess = () => (
    {
        type: actionTypes.UPDATE_ID_ASSIGNMENT_SUCCESS,
    }
)

export const updateSlaAssignmentStart = (data) => (
    {
        type: actionTypes.UPDATE_SLA_ASSIGNMENT_START,
        payload: data,
    }
)


export const updateSlaAssignmentSuccess = () => (
    {
        type: actionTypes.UPDATE_SLA_ASSIGNMENT_SUCCESS,
    }
)

export const updateVolumeAssignmentStart = (data) => (
    {
        type: actionTypes.UPDATE_VOLUME_ASSIGNMENT_START,
        payload: data,
    }
)


export const updateVolumeAssignmentSuccess = () => (
    {
        type: actionTypes.UPDATE_VOLUME_ASSIGNMENT_SUCCESS,
    }
)

export const getStaffPreview = (empId, supId) => ({
    type: actionTypes.GET_STAFF_PREVIEW,
    empId, supId
});

export const getMappedEmployees = (data) => ({
    type: actionTypes.GET_MAPPED_EMPLOYEES,
    payload: data
});

export const getQueueData = (empId, supId) => ({
    type: actionTypes.GET_QUEUE_DATA,
    empId, supId
});

export const getAccountSLA = (empId, supId) => ({
    type: actionTypes.GET_ACCOUNT_SLA,
    empId, supId
});

export const getVolumeTemplates = (empId, supId) => ({
    type: actionTypes.GET_VOLUME_TEMPLATES,
    empId, supId
});

export const getIDAccounts = (empId, supId) => ({
    type: actionTypes.GET_ID_ACCOUNTS,
    empId, supId
});

export const getSLAAccounts = (empId, supId) => ({
    type: actionTypes.GET_SLA_ACCOUNTS,
    empId, supId
});

export const getValidSupervisor = (data) => (
    {
        type: actionTypes.GET_VALID_SUPERVISOR,
        payload: data,
    }
)

export const getAccountSLAForEdit = (empId, supId) => ({
    type: actionTypes.GET_ACCOUNT_SLA_FOREDIT,
    empId, supId
});

export const getVolumeTemplatesForEdit = (empId, supId) => ({
    type: actionTypes.GET_VOLUME_TEMPLATES_FOREDIT,
    empId, supId
});