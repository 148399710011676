import React, { useEffect, useState } from 'react';
import './nonVoice.css';
import disabledTick from './../../../assets/onboard-images/NonVoice_Items_Disabled_TickMark.png';
import backArrow from './../../../assets/onboard-images/Back_Arrow.png';
import activeTick from './../../../assets/onboard-images/NonVoice_Items_Active_TickMark.png';

import SLAComponent from './../OnboardComponents/SLAComponents/SLAComponent'
import SupportHoursMain from './../OnboardComponents/SupportHoursComponent/SupportHoursMain'
import General from './GeneralTab/General';
import AccordionHandler from '../OnboardComponents/Accordion/Accordion';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralNonVoice, getNonVoiceSLAData, getNonVoiceSupportHours, getPreviewOnboard, setWidgetCardNonVoice } from '../../../actions/Onboard.action';
import { useLocation } from 'react-router-dom';
import { getAdminSlaTableData, getAdminSupportTableData, getPreviewAdmin } from '../../../actions/Administration/admin.action'




function NonVoiceHandler(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const accountSiteInfo = useSelector((state) => state.onboardReducer.accountSiteInfo);
    const previewOnboardData = useSelector((state) => state.onboardReducer.previewOnboardData);
    const widgetPreviewTab = useSelector((state) => state.onboardReducer.widgetPreviewTab);
    let previewAdminData = useSelector((state) => state.adminAccountReducer.previewAdminData);
    const [widget, setWidget] = useState();
    const widgetHandler = card => {
        if (card.isDisabled === false) {
            setWidget(card)
            props.widgetCardHandler('widget');
        }
    }
    const ACCORDION_INFO = [
        {
            id: 1,
            label: 'General',
            Info: <General
                dispatchGeneralHandler={props.dispatchGeneralHandler}
                NonVoiceItem={widget && widget.card}
                GeneralNonvoicedata={props.GeneralNonvoicedata}
                accountDetails={props.accountDetails}
            />
        },
        {
            id: 2,
            label: 'SLA/KPIs',
            Info: <SLAComponent GeneralInfoToggle={props.GeneralInfoToggle}
                dispatchAccountHandler={props.dispatchAccountHandler}
                voiceSLAData={props.voiceSLAData}
                TabName='Non-Voice'
                NonVoiceItem={widget && widget.card}
                accountDetails={props.accountDetails}
            />
        },
        {
            id: 3,
            label: 'Support Hours',
            Info: <SupportHoursMain GeneralInfoToggle={props.GeneralInfoToggle}
                dispatchSupportHandler={props.dispatchSupportHandler}
                supportHoursVoice={props.supportHoursVoice}
                TabName='Non-Voice'
                accountDetails={props.accountDetails}
                NonVoiceItem={widget && widget.card}
            />
        },
    ]
    const nonVoiceTickHandler = (value, tab) => {
        return value === 'Yes' ? true : false
    }


    const NON_VOICE_CARDS = [
        {
            id: 1,
            card: 'Chat Tool',
            isDisabled: false,
            isSaved: nonVoiceTickHandler(location.pathname === '/onboardAccount' ? previewOnboardData?.[0]?.['Non Voice']?.[0]?.Completed : previewAdminData?.[0]?.['Non Voice']?.[0]?.Completed),
            label: 'chat'
        },
        {
            id: 2,
            card: 'Ticketing Tool',
            isDisabled: false,
            isSaved: nonVoiceTickHandler(location.pathname === '/onboardAccount' ? previewOnboardData?.[0]?.['Non Voice']?.[1]?.Completed : previewAdminData?.[0]?.['Non Voice']?.[1]?.Completed),
            label: 'ticket'
        },

        {
            id: 3,
            card: 'Web Portal',
            isDisabled: false,
            isSaved: nonVoiceTickHandler(location.pathname === '/onboardAccount' ? previewOnboardData?.[0]?.['Non Voice']?.[2]?.Completed : previewAdminData?.[0]?.['Non Voice']?.[2]?.Completed),
            label: 'web'
        },
        {
            id: 4,
            card: 'Email',
            isDisabled: false,
            isSaved: nonVoiceTickHandler(location.pathname === '/onboardAccount' ? previewOnboardData?.[0]?.['Non Voice']?.[3]?.Completed : previewAdminData?.[0]?.['Non Voice']?.[3]?.Completed),
            label: 'email'

        },
        {
            id: 5,
            card: 'Customer Survey',
            isDisabled: false,
            isSaved: nonVoiceTickHandler(location.pathname === '/onboardAccount' ? previewOnboardData?.[0]?.['Non Voice']?.[4]?.Completed : previewAdminData?.[0]?.['Non Voice']?.[4]?.Completed),
            label: 'customer'
        },

        {
            id: 6,
            card: 'Transaction Monitoring NON AQUAA',
            isDisabled: false,
            isSaved: nonVoiceTickHandler(location.pathname === '/onboardAccount' ? previewOnboardData?.[0]?.['Non Voice']?.[5]?.Completed : previewAdminData?.[0]?.['Non Voice']?.[5]?.Completed),
            label: 'non_aquaa'
        },
        {
            id: 7,
            card: 'Workforce Management (Scheduling)',
            isDisabled: false,
            isSaved: nonVoiceTickHandler(location.pathname === '/onboardAccount' ? previewOnboardData?.[0]?.['Non Voice']?.[6]?.Completed : previewAdminData?.[0]?.['Non Voice']?.[6]?.Completed),
            label: 'workforce'
        },

        {
            id: 8,
            card: 'Workforce / HR Data',
            isDisabled: true,
            isSaved: false,
            label: 'agent'
        },
        {
            id: 9,
            card: 'Finance Data',
            isDisabled: true,
            isSaved: false,
            label: 'finance'
        },
        {
            id: 10,
            card: 'Agent / Staff Administration',
            isDisabled: true,
            isSaved: false,
            label: 'AQUAA'
        },
        {
            id: 11,
            card: 'Transaction Monitoring AQUAA',
            isDisabled: true,
            isSaved: false,
            label: 'monitor'
        },
    ]

    useEffect(() => {
        NON_VOICE_CARDS.map(card => {
            if (widgetPreviewTab === card.card)
                setWidget(card)
            else setWidget()
        })
    }, [])

    const OnClickWidgetHandler = (card) => {
        if (location.pathname === '/onboardAccount') {
            dispatch(getNonVoiceSupportHours(accountSiteInfo.AccountName, accountSiteInfo.SiteName, card.card));
            dispatch(getNonVoiceSLAData(accountSiteInfo.AccountName, accountSiteInfo.SiteName, card.card))
        }
        if(location.pathname === '/admin/account'){
            dispatch(getAdminSlaTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName, 'Non-Voice', card?.card));
            dispatch(getAdminSupportTableData(accountSiteInfo.AccountName, accountSiteInfo.SiteName, 'Non-Voice', card?.card));
        }
        dispatch(getGeneralNonVoice(accountSiteInfo.AccountName, accountSiteInfo.SiteName, card.card))
        dispatch(getPreviewOnboard(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
        dispatch(getPreviewAdmin(accountSiteInfo.AccountName, accountSiteInfo.SiteName))
        dispatch(setWidgetCardNonVoice(card))
        widgetHandler(card)
    }
    return (<>
    <div className='dataSave_div_voice'>During the Account Onboarding and Administration process populate only Voice and Non-Voice channels that are applicable for selected account and site. Non-applicable channels can be left blank.</div>
        {!widget ? <div className='nonvoice_cards'> <div className="nonvoice_heading">Non Voice Item</div> <ul className='card-section' style={{ cursor: 'pointer' }}>
            {NON_VOICE_CARDS.map(card => (
                <li className={card.isDisabled ? 'nonvoice-cards-disabled' : 'nonvoice-cards'}
                    onClick={() => { OnClickWidgetHandler(card) }}>{card.card}  
                    <div className='img-src'>  <img src={card.isSaved ? activeTick : disabledTick} alt='' /></div>
                </li>
            ))}
        </ul>
            <div className='nonvoice_note'><b>Note:</b> Greyed out items related data feed is managed globally</div>
        </div> :
            <section className='non-voice-container' >
                {widget && <div >
                    <span style={{ cursor: 'pointer' }} onClick={() => {
                        setWidget('')
                        props.widgetCardHandler('nonvoice');
            dispatch(getPreviewOnboard(accountSiteInfo.AccountName, accountSiteInfo.SiteName));
    dispatch(getPreviewAdmin(accountSiteInfo.AccountName, accountSiteInfo.SiteName))
                    }}>
                        <img src={backArrow} alt='' style={{ marginRight: 15, marginBottom: 10 }} />
                        <span className='back-button' >{widget.card}</span></span>
                    {widget.label === 'chat' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                    {widget.label === 'workforce' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                    {widget.label === 'ticket' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                    {widget.label === 'email' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                    {widget.label === 'customer' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                    {widget.label === 'non_aquaa' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}
                    {widget.label === 'web' && <AccordionHandler ACCORDION_INFO={ACCORDION_INFO} />}

                </div>}
            </section>
        }
    </>)
}

export default NonVoiceHandler;