import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import GSD from '../../../assets/images/GSDInsight_Logo.png';
import './rolesSelect.css';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserData } from "../../../actions/user.action";
import { getRolesTitleInfo, getWidgetPreference, saveSelectedRole } from '../../../actions/welcomePage.action';

const Roleselectionpage = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    let userInfo = useSelector((state) => state.userReducer.userInfo);
    let RolesInfo = useSelector((state) => state.welcomePageReducer.rolesTitle);
    useEffect(() => {
        dispatch(getUserData());
        dispatch(getRolesTitleInfo());
        dispatch(getWidgetPreference());
    }, []);

    const [selectedRole, setSelectedRole] = useState('');
    const [role_id, setRoleId] = useState('');
    const buttonHandler = () => {
        console.log(role_id)
        if (selectedRole) {
            localStorage.setItem('RoleSelected', selectedRole);
            localStorage.setItem('Role_id', role_id);
            dispatch(saveSelectedRole(selectedRole));
            history.push('/widget');
        }
        else
            alert(`Hello ${userInfo.name}, please proceed by selecting any role :)`);
    };

    return (
        <React.Fragment>
      <> <div className='gsd_logo'><img id='logo' src={GSD} alt='none' width='60px' height='60px' />
                <span>GSD Insight</span>
            </div>
            <section className='roles_selection' style={{ marginTop: '5%', marginLeft: '13%' }}>

                <div className='username_info'>  Hi, {userInfo.name} </div>
                <div className='select_role'>Select your Role  </div>
                <main className='roles_container'>
                    {RolesInfo?.map((role,id) =>
                        <div className='role_widget '  key={role.name} >
                            <label htmlFor={id}style={{ cursor: 'pointer' }}>
                                <input className='radio_type' type="radio" id={id} name='rolesSelection' value={role.name} onChange={() =>{ setSelectedRole(role.name);
                                setRoleId(role.id)}} style={{ cursor: 'pointer' }} />
                                <span className='label_role' >{role.name}</span></label>
                        </div>)}
                </main>
                <button className='continue_button' onClick={buttonHandler}>
                    <span className='continue_button-span'>CONTINUE</span>
                </button>
            </section>
            
            </>
            </React.Fragment>

        
        
    )
}

export default Roleselectionpage