
import React from "react"
import { Bar } from "react-chartjs-2"

export function VerticalBarChart(props) {
    return (<>
        <section className='common_container' id={props.id} >
            <section >
                <main style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                    <div className='dtm_title_text'>{props.heading}</div>
                </main>
                <main style={{ display: 'flex' }}>
                    <div className='Y_Label_text'>{props.yAxesTitle}</div>
                    <div className='chart_one_container' >
                        <Bar data={{
                            labels: props.labels,
                            datasets: [
                                {
                                    data: props.data,
                                    backgroundColor: props.color,
                                }
                            ]
                        }} width={20} height={10} options={props.options} />
                    </div>
                </main>
                <main className='X_label_text'>{props.xAxesTitle}</main>
            </section>
        </section>
    </>)
}



export function StackedBarCharts(props) {
    return (<>
        <section className='common_container'>
            <section >
                <main style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                    <section> <div className='dtm_title_text'>{props.heading}</div></section>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                        <div style={{ display: 'flex', gap: '10px' }}> <div className='abovestacked' > </div>{props.numerator}</div>
                        <div style={{ display: 'flex', gap: '10px' }}> <div className='belowstacked'> </div>{props.difference}</div>
                    </div>  </main>
                <main style={{ display: 'flex' }}>
                    <div className='Y_Label_text'>{props.yAxesTitle}</div>
                    <div className='chart_one_container' >
                        <Bar data={{
                            labels: props.labels,
                            datasets: [
                                {
                                    label: 'Answered',
                                    data: props.data,
                                    backgroundColor: props.color,
                                },
                                {
                                    label: 'Not Answered',
                                    data: props.stackedData,
                                    backgroundColor: props.stackedColor,
                                }
                            ]
                        }} width={20} height={10} options={props.options} />
                    </div>
                </main>
                <main className='X_label_text'>{props.xAxesTitle}</main>
            </section>
        </section>
    </>)
}


export function StackedLineBarCharts(props) {
    return (<>
        <section className='common_container'>
            <section >
                <main style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30 }}>
                    <div className='dtm_title_text'>{props.heading}</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px' }}>
                        <div style={{ display: 'flex', gap: '10px' }}> <div className='abovestacked_s' > </div>{props.numerator}</div>
                    </div>
                </main>
                <main style={{ display: 'flex' }}>
                    <div className='Y_Label_text'>{props.yAxesTitle}</div>
                    <div className='chart_one_container' >
                        <Bar data={{
                            labels: props.labels,
                            datasets: [
                                {
                                    label: 'Answered',
                                    data: props.data,
                                    backgroundColor: props.color,
                                    order: 2,
                                },
                                // {
                                //     label: 'Required Target',
                                //     data: props.lineData,
                                //     backgroundColor: 'black',
                                //     tension: 0,
                                //     borderColor: 'black',
                                //     type: 'line',
                                //     order: 1,
                                //     pointRadius: 0,
                                // }
                            ]
                        }} width={20} height={10} options={props.options} />
                    </div>
                </main>
                <main className='X_label_text'>{props.xAxesTitle}</main>
            </section>
        </section>
    </>)
}