import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap'
import hintsIcon from './../../../../assets/onboard-images/Hints_Icon.png'
import './OffCanvas.css'
import AccordionHandler from './AccordianHints';

function OffCanvasHandler() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className='icon-wrapper' onClick={handleShow}> <img src={hintsIcon} alt='hints' /> <span>Hints</span></div>
      <Offcanvas show={show} onHide={handleClose} placement='end'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ marginTop: '1.5em' }}><span className='title'>AWS (ASD) Telephony Hints</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: '#FFFFFF', border: ' 1px solid #ECEEF1' }}>
          <AccordionHandler />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasHandler;

