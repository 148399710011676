const RoleId = localStorage.getItem('Role_id');


export const UploadURLS = (name,items) =>{
    console.log('items',items)
    switch(name){
        case 'GET_UPLOAD_ACCOUNTS' : {
            return `/api/usp/FileUploadGetAccounts?RoleID=${RoleId}`;
        }
        case 'GET_SLAKPI_VOLUME_TEMPLATES' : {
            return `/api/usp/FileUploadGetSLAKPIVolumeTemplates?CustomerID=${items.customerID}&Category=${items.category}`;
        }
        case 'UPDATE_SELECTED_SLAKPI_VOLUME' : {
            return `/api/usp/FileUploadTemplateSelection`;
        }
        case 'UPDATE_CUSTOM_TEMPLATE' : {
            return `/api/usp/FileUploadAddCustomSLAKPIVolumeTemplate`;
        }
        case 'GET_FILE_UPLOAD_DATA' : {
            return `/api/usp/FileUploadGetDetails?CustomerID=${items.customerID}&ServicedeskID=${items.servicedeskID}&VoiceNonVoice=${items.selectedVoiceNonVoiceTab}`;
        }
        case 'UPDATE_FILE_UPLOAD_DATA' : {
            return `/api/usp/FileUpload`;
        }
        case 'GET_NONVOICE_CARDS_DATA' : {
            return `/api/usp/FileUploadGetVoiceNonVoiceItems?CustomerID=${items.customerID}&ServicedeskID=${items.servicedeskID}&VoiceNonVoice=${items.selectedVoiceNonVoiceTab}&Month=${items.month}`;
        }
        case 'GET_UPLOAD_PRIVIEW_DATA' : {
            return `/api/usp/FileUploadPreview?CustomerID=${items.customerID}&ServicedeskID=${items.servicedeskID}&Mnth=${items.month}`;
        }
        case 'GET_VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA' : {
            return `/api/usp/FileUploadGetSelectedTemplates?CustomerID=${items.customerID}&ServicedeskID=${items.servicedeskID}&Category=${items.category}&Month=${items.month}&Itemname=${items.itemname}`;
        }
        case 'UPDATE_VOLUME_SLAKPI_DATA' : {
            return `/api/usp/FileUploadTemplateValues`;
        }
        default : {}
    }
}