import { UploadActionTypes as actionTypes } from "../constants/uploads-actionTypes";



const initialState = {
    SelectedVolumeFields: [],
    SelectedSLAKPIFields: [],
    uploadAccounts:[],
    SlaKpiVolumeTemplates:[],
    SelectedAccountDetails:[],
    SelectedSLAKPIVolumeTab:[],
            dataAdministrationValidation:'',
    selectedVolumeSlaDate:'',
    FileUploadData:[],
    SelectedVoiceNonVoiceTab:'',
    SelectedVoiceNonVoiceItem:'',
    NonvoiceCardsData:[],
    UploadpreviewData:[],
    volumeSlakpiSelectedTemplates:[]
}




export const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_UPLOAD_ACCOUNTS:
        case actionTypes.GET_SLAKPI_VOLUME_TEMPLATES:
        case actionTypes.GET_FILE_UPLOAD_DATA:
        case actionTypes.GET_VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA:

            return { ...state };

        case actionTypes.SET_SELECTED_VOLUME_FIELDS:
            return { ...state, SelectedVolumeFields: action.payload };
        case actionTypes.SET_SELECTED_SLAKPI_FIELDS:
            return { ...state, SelectedSLAKPIFields: action.payload };
        case actionTypes.SET_SELECTED_ACCOUNT_DETAILS:
            return { ...state, SelectedAccountDetails: action.payload };
        case actionTypes.SET_SELECTED_SLAKLP_VOLUME_TAB:
            return { ...state, SelectedSLAKPIVolumeTab: action.payload };
        case actionTypes.SET_SELECTED_VOICE_NONVOICE_TAB:
            return { ...state, SelectedVoiceNonVoiceTab: action.payload };
        case actionTypes.SET_SELECTED_VOICE_NONVOICE_ITEM:
            return { ...state, SelectedVoiceNonVoiceItem: action.payload };
                              case actionTypes.SET_DATA_ADMINISTRATION_VALIDATION:
                                 return { ...state, dataAdministrationValidation: action.payload };
        case actionTypes.SET_SELECTED_VOLUME_SLA_DATE:
            return { ...state, selectedVolumeSlaDate: action.payload };
        case actionTypes.UPLOAD_ACCOUNTS: 
            return { ...state, uploadAccounts: action.payload };
        case actionTypes.SLAKPI_VOLUME_TEMPLATES: 
            return { ...state, SlaKpiVolumeTemplates: action.payload };
        case actionTypes.FILE_UPLOAD_DATA: 
            return { ...state, FileUploadData: action.payload };
        case actionTypes.NONVOICE_CARDS_DATA: 
            return { ...state, NonvoiceCardsData: action.payload };
        case actionTypes.UPLOAD_PRIVIEW_DATA: 
            return { ...state, UploadpreviewData: action.payload };
        case actionTypes.VOLUME_SLAKPI_SELECTEDTEMPLATES_DATA: 
            return { ...state, volumeSlakpiSelectedTemplates: action.payload };
        default: return state
    }
}
