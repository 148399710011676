import React, { useState, useEffect }  from "react";
//import Header from "../Header/header";
import ButtonList  from "./buttonList";
import { useSelector, useDispatch } from "react-redux";
import { getRegionalData } from "../../actions/user.action";
import { updateSelectedAccountName, saveSelectedMenuItem } from "../../actions/tabMenu.action";
import { useHistory } from "react-router-dom";

const SiteLanding = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [regionalIdx, setRegionalIdx] = useState(0);
    const [serviceDskIdx, setServiceDskIdx] = useState(0);
    const [regionalDt, setRegionalDt] = useState([]);
    const [serviceDskDt, setServiceDskDt] = useState([]);
    const [customerDt, setCustomerDt] = useState([]);
    const slaDt = [{'fieldName': 'SLA Report', 'idx': 0, 'isActv': false}];
    const extractRegionalData = (data, type) => {
        const tempArr = [];
        if(data && data.length > 0){
            data.map((rData, indx)=>{
                let obj;
                if(type==='region')
                obj = {'fieldName': rData.region, 'idx': indx, 'isActv': false};
                else if(type==='service'){
                obj = {'fieldName': rData.servicedesk, 'idx': indx, 'isActv': false};
                }else{
                obj = {'fieldName': rData.customer, 'idx': indx, 'isActv': false};
                }                
               return tempArr.push(obj);                
            });
            return tempArr;
        }else{
            return [];
        }
    }

    const getCustomerName = (custID) => {
        if(regionalAllData[regionalIdx] && regionalAllData[regionalIdx]?.servicedesks[serviceDskIdx]){
            let dt = regionalAllData[regionalIdx]?.servicedesks[serviceDskIdx];
            dt = dt.customers.filter((item, idx)=>item.customerid === custID);
            return dt[0]['customer'];
        }      

    }

    const regionalAllData = useSelector((state) => state.userReducer.regionalData); 
    useEffect(() => {        
        dispatch(getRegionalData());        
    }, [dispatch]);

    useEffect(() => {  
        if(regionalAllData && regionalAllData.length > 0){
            let rgDt = extractRegionalData(regionalAllData, 'region');
            let servDt = extractRegionalData(regionalAllData[0]['servicedesks'], 'service');
            let cusDt = extractRegionalData(regionalAllData[0]['servicedesks'][0]['customers'], 'customer');
            rgDt[0].isActv = true;
            servDt[0].isActv = true;
            setRegionalDt(rgDt);
            setServiceDskDt(servDt);
            setCustomerDt(cusDt);
        }
        else{
            setRegionalDt([]);
            setServiceDskDt([]);
            setCustomerDt([]);
        }        
    }, [regionalAllData]);

    const handleRegionalSelection = (indx) => {
        let dt = [], cusDt = [];
        setCustomerDt([]);
        setServiceDskDt([]);
        if(regionalAllData && regionalAllData.length > 0){
            if(regionalAllData[indx]['servicedesks'] && regionalAllData[indx]['servicedesks'].length > 0){
                cusDt = extractRegionalData(regionalAllData[indx]['servicedesks'][0]['customers'], 'customer');
                dt = extractRegionalData(regionalAllData[indx]['servicedesks'], 'service');
                dt[0].isActv = true;
            }  
            else
            cusDt = [];
            setRegionalIdx(indx);           
            setServiceDskDt(dt);
            setCustomerDt(cusDt);
        }
    }

    const handleServiceDskSelection = (idx) => {
        if(serviceDskDt && serviceDskDt.length > 0 && regionalAllData[regionalIdx]['servicedesks'][idx]['customers']){
            let dt = extractRegionalData(regionalAllData[regionalIdx]['servicedesks'][idx]['customers'], 'customer');
            setCustomerDt(dt);
        }else{
            setCustomerDt([]);
        }
        setServiceDskIdx(idx);
    }

    const handleCustomerDskSelection = (idx) => {
       let servObj = regionalAllData[regionalIdx].servicedesks[serviceDskIdx];       
       if(servObj && servObj.servicedeskid){
           window.localStorage.setItem('ServiceDeskID', servObj.servicedeskid);           
       }
       if(servObj && servObj.customers && servObj.customers.length > 0){
           window.localStorage.setItem('CustomerID', servObj.customers[idx].customerid);
       }
       const custName = getCustomerName(servObj.customers[idx].customerid);
       dispatch(updateSelectedAccountName(custName));
       dispatch(saveSelectedMenuItem("reports"));
       history.push("/reports");
    }

    const handleSLASelection = (idx) => {
        let srvID;
        if(regionalAllData[regionalIdx] && regionalAllData[regionalIdx].servicedesks[serviceDskIdx] && regionalAllData[regionalIdx].servicedesks[serviceDskIdx].servicedeskid)
        srvID = regionalAllData[regionalIdx].servicedesks[serviceDskIdx].servicedeskid;
        window.open(`${process.env.REACT_APP_BASE_URL}/api/metrics/redirecttositereport/${srvID}`);
    }

    return(
        <div>           
            <ButtonList handleDataSelection={handleRegionalSelection} data={regionalDt} bannerName="Select Delivery Region"/>
            <ButtonList handleDataSelection={handleServiceDskSelection} data={serviceDskDt} bannerName="Select Site"/>
            <ButtonList handleDataSelection={handleCustomerDskSelection} data={customerDt} bannerName="Account level report(s)"/>
            <ButtonList handleDataSelection={handleSLASelection} data={slaDt} bannerName="Site level SLA report(s)"/>
        </div>
    );
}

export default SiteLanding;