import ReactModal from "react-modal";
import Download_Icon from "../../assets/images/Download_Icon.png"
import CloseWindow_Normal from '../../assets/images/CloseWindow_Normal.png';
import { Bar } from 'react-chartjs-2';
import './Analytics.css';
import * as htmlToImage from 'html-to-image';


const exportAsPicture = (id, image) => {
    htmlToImage.toPng(document.getElementById(id), {
        quality: 0.90,
    })
        .then(function (dataUrl) {
            let link = document.createElement('a');
            link.download = image;
            link.href = dataUrl;
            link.click()
        })
        .catch(function (error) {
            console.error('oops, something went wrong!', error);
        });
}

export function CalendarModel(props) {

    return <>
        <section className="calender_model_container" id={props.id}>
            <main style={{ display: 'flex', justifyContent: 'space-between' }}>
                {props.heading === true ? <div className='model_header_dtm' >
                    {`${props.day.format('DD')}/${props.day.format('MM')}/${props.day.format('YYYY')}`} - Standard Telephony By Half Hour
                </div> : <div></div>}
                <div onClick={props.closewindow} style={{ cursor: 'pointer' }}><img src={CloseWindow_Normal} alt='none' /></div>
            </main>
            <section className='header_container'>
                {props.heading === true ? <div id='first'>
                    <div className='second_one'>  {props.yAxesTitle}   </div>
                    <div className='first_one'>{props.target}</div>
                </div> : <div></div>}
            </section>
            <main style={{ display: 'flex', marginTop: 30 }}>
                <div className='Y_Label_text'>{props.yAxesTitle}</div>
                <div className='chart_one_container' style={{ height: '320px', marginLeft: '10px' }} >
                    <Bar data={{
                        labels: props.labels,
                        datasets: [
                            {
                                data: props.data,
                                backgroundColor: props.color,
                            }
                        ]
                    }} width={20} height={10} options={props.options} />
                </div>
            </main>
            <main className='X_label_text'>{props.xAxesTitle}</main>     </section>
    </>
}

export function CalendarvaluesModel(props) {

    return <>
        <section className="calender_model_container">
            <main style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='model_header_dtm' >
                    {props.heading}
                </div>
                <div onClick={props.closewindow} style={{ cursor: 'pointer' }}><img src={CloseWindow_Normal} alt='none' /></div>
            </main>
            <main style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 30, marginTop: 20 }}>
                {props.title === true ? <div className='calls_value' style={{ width: '30%' }}>
                    <div className='calls_handled_box'>{props.yAxesTitle}</div><div className='calls_handled_number' style={{ color: props.Arrcolor }}>{props.target}</div>
                </div> : <div></div>}
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '20px', marginTop: 20 }}>
                    <div style={{ display: 'flex', gap: '10px' }}> <div className='abovestacked' > </div>{props.numerator}</div>
                    <div style={{ display: 'flex', gap: '10px' }}> <div className='belowstacked'> </div>{props.difference}</div>
                </div> </main>
            <main style={{ display: 'flex' }}>
                <div className='Y_Label_text'>{props.yAxesTitle}</div>
                <div className='chart_one_container' style={{ height: '320px', marginLeft: '10px' }} >
                    <Bar data={{
                        labels: props.labels,
                        datasets: [
                            {
                                label: 'Answered',
                                data: props.data,
                                backgroundColor: props.color,
                            },
                            {
                                label: 'Not Answered',
                                data: props.stackedData,
                                backgroundColor: props.stackedColor,
                            }
                        ]
                    }} width={20} height={10} options={props.options} />
                </div>
            </main>
            <main className='X_label_text'>{props.xAxesTitle}</main>     </section>
    </>
}

export function PastMonthTrendsModel(props) {

    return <>
        <section className="calender_model_container">
            <main style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className='model_header_dtm' >
                    {props.heading}
                </div>
                <div onClick={props.closewindow} style={{ cursor: 'pointer' }}><img src={CloseWindow_Normal} alt='none' /></div>
            </main>

            <main style={{ display: 'flex', marginTop: 60 }}>
                <div className='Y_Label_text'>{props.yAxesTitle}</div>
                <div className='chart_one_container' style={{ height: '320px', marginLeft: '10px' }} >
                    <Bar data={{
                        labels: props.labels,
                        datasets: [
                            {
                                label: 'Answered',
                                data: props.data,
                                backgroundColor: props.color,
                                order: 2,
                            },
                            // {
                            //     label: 'Required Target',
                            //     data: props.lineData,
                            //     backgroundColor: 'black',
                            //     tension: 0,
                            //     borderColor: 'black',
                            //     type: 'line',
                            //     order: 1,
                            //     pointRadius: 0,
                            // }
                        ]
                    }} width={20} height={10} options={props.options} />
                </div>
            </main>
            <main className='X_label_text'>{props.xAxesTitle}</main>     </section>
    </>
}


export function CalendarModelpopup({ isOpen, setShowModal, children }) {
    return (<>
        <ReactModal isOpen={isOpen} shouldCloseOnOverlayClick={false} preventScroll={true}
            onRequestClose={() => setShowModal(false)}
            portalClassName='backdrop'
            style={{
                overlay: {
                    position: 'fixed',
                    top: '10em',
                    left: '15em',
                    width: '1100px',
                    height: '550px',
                    background: ' #FFFFFF 0% 0% no-repeat padding-box',
                    boxShadow: ' 6px 6px 45px #00000066',
                    border: ' 1px solid #ECEEF1',
                    opacity: '1',
                },
                content: {
                    position: 'absolute',
                    top: '0',
                    left: '-22em',
                    right: '40px',
                    bottom: '40px',
                    border: 'none',
                    background: 'none',
                    overflow: 'none',
                    WebkitOverflowScrolling: 'touch',
                    borderRadius: '4px',
                    outline: 'none',
                    padding: '2em'
                }
            }}>
            {children}
        </ReactModal>

    </>)
}
