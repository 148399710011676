import React, { Fragment, useEffect, useState } from 'react';
import './EmployeeTab.css';
import AddAccount_Icon from '../../../../assets/images/AddAccount_Icon.png';
import Add_Queue_Icon from '../../../../assets/images/Add_Queue_Icon.png';
import Add_SLA_KPI_Icon from '../../../../assets/images/Add_SLA_KPI_Icon.png';
import AddID_Icon from '../../../../assets/images/AddID_Icon.png';
import Inactive_Img from "../../../../assets/admin-images/Inactive_Indication_Icon.png";
import Add_Volumetric_Icon from "../../../../assets/admin-images/Add_Volumetric_Icon.png";
import Search_Icon from "../../../../assets/images/Search_Icon.png"
import Edit_Icon from "../../../../assets/images/Edit_Icon.png"
import MOCKDATA from './Tab.json'
import { AccountAssignmentModal, IDAssignmentModal, QueueAssignmentAddModal, QueueAssignmentModal } from './QueueAssignmentModal';
import { SLAAssignmentModal, VolumeAssignmentModal } from './SLAAssignmentModel';
import moment from 'moment';
import { QueueassignAddPopup, QueueassignPopup, SLAModalPopup } from './ModelPopUps';
import TooltipHandler from '../../../Onboard/OnboardComponents/TooltipHandler';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getAccountSLA, getAccountSLAForEdit, getIDAccounts, getQueueData, getSLAAccounts, getStaffAccounts } from '../../../../actions/Administration/staffAdmin.action';
import { getVolumeTemplates, getVolumeTemplatesForEdit } from './../../../../actions/Administration/staffAdmin.action';
import { getRoleBasedView } from '../../../../actions/Administration/admin.action';


function EmployeeTabHandler(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const AccountsInfo = useSelector((state) => state.staffAdminReducer.staffAccounts);
    const queueLists = useSelector((state) => state.staffAdminReducer.queueData);
    const SLATemplates = useSelector((state) => state.staffAdminReducer.accountSLA);
    const VolumeTemplates = useSelector((state) => state.staffAdminReducer.volumeTemplates);
    const SLATemplatesForEdit = useSelector((state) => state.staffAdminReducer.accountSLAForEdit);
    const VolumeTemplatesForEdit = useSelector((state) => state.staffAdminReducer.volumeTemplatesForEdit);
    console.log('create', VolumeTemplates, 'edit', VolumeTemplatesForEdit)
    const supervisorInfo = useSelector((state) => state.staffAdminReducer.supervisorInfo);
    const idAccounts = useSelector((state) => state.staffAdminReducer.idAccounts);
    const slaAccounts = useSelector((state) => state.staffAdminReducer.slaAccounts);
    const RoleBasedView = useSelector((state) => state.adminAccountReducer.RoleBasedView);
    useEffect(() => {
        dispatch(getStaffAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID));
        dispatch(getRoleBasedView())
    }, [dispatch])

    const [AddTab, setAddTab] = useState('Add Account');
    const [imgSrc, setImgSrc] = useState(AddAccount_Icon);
    const [tab, setTab] = useState('account');
    const [editRowID, setEditRowID] = useState(null);
    const [editFormInfo, setEditFormInfo] = useState({});
    const [searchColumns, setSearchColumns] = useState([
        'value1', 'value2'
    ]);
    const [accountData, setAccountData] = useState([]);
    const [queueData, setQueueData] = useState([]);
    const [IDdata, setIDData] = useState([]);
    const [SLAData, setSLAData] = useState([]);
    const [VolumeData, setVolumeData] = useState([]);
    const [headerData, setHeaderData] = useState(MOCKDATA.ACCOUNT_ASSIGNMENTS.headers)
    const [VolumeID, setVolumeID] = useState(null)
    const [endDateKey, setEndDateKey] = useState('');

    const [slashow, setslaShow] = useState(false)

    useEffect(() => {
        switch (props.tab) {
            case 'account': {
                setImgSrc(AddAccount_Icon)
                setAddTab('Add Account')
                setTab('account');
                setSearchColumns(['value1', 'value2'])
                setHeaderData(MOCKDATA.ACCOUNT_ASSIGNMENTS.headers);
                dispatch(getStaffAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                setAccountData(props.StaffAdminData)
                setEndDateKey('value4');
                setslaShow(false);
                break;
            }
            case 'queue': {
                setImgSrc(Add_Queue_Icon)
                setAddTab('Add Queue')
                setTab('queue');
                setHeaderData(MOCKDATA.QUEUE_ASSIGNMENTS.headers);
                dispatch(getQueueData(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                setQueueData(props.StaffAdminData)
                setEndDateKey('value5');
                setslaShow(false);
                break;
            }

            case 'assign': {
                setImgSrc(AddID_Icon);
                setAddTab('Add ID');
                setTab('assign');
                setHeaderData(MOCKDATA.ID_ASSIGNMENTS.headers);
                setSearchColumns(['value1', 'value2', 'value3']);
                dispatch(getIDAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                setIDData(props.StaffAdminData)
                setEndDateKey('value5');
                setslaShow(false);
                break;
            }

            case 'slakpi': {
                setImgSrc(Add_SLA_KPI_Icon)
                setAddTab('Add SLA/KPI')
                setTab('slakpi');
                setHeaderData(MOCKDATA.SLA_ASSIGNMENTS.headers);
                setSearchColumns(['value1', 'value2', 'value3', 'value4'])
                dispatch(getSLAAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getAccountSLA(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getAccountSLAForEdit(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                setSLAData(props.StaffAdminData)
                setEndDateKey('value7');
                setslaShow(true);
                break;
            }

            case 'volume': {
                setImgSrc(Add_Volumetric_Icon)
                setAddTab('Add Volumetric')
                setTab('volume');
                setHeaderData(MOCKDATA.VOLUME_ASSIGNMENTS.headers);
                setSearchColumns(['value1', 'value2', 'value3', 'value4'])
                dispatch(getSLAAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getVolumeTemplates(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getVolumeTemplatesForEdit(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                setVolumeData(props.StaffAdminData)
                setEndDateKey('value7');
                setslaShow(true);
                break;
            }
            default: { }
        }
    }, [props.tab]);




    const dispatchObjHandle = () => {
        switch (props.tab) {
            case 'account': {
                let temp = [...accountData];
                let arr2 = temp.map(arr => (
                    {
                        Customer: arr.value1,
                        CustomerType: arr.value2,
                        ValidFrom: arr.value3,
                        ValidTo: arr.value4,
                        ID: typeof arr.ID === 'string' ? '' : arr.ID
                    }
                ))
                let arr1 = [{
                    EmployeeID: supervisorInfo.EmployeeID,
                    SupEmployeeID: supervisorInfo.SupervisorID,
                    Details: arr2
                }]
                return arr1;
            }
            case 'queue': {
                let temp = [...queueData];
                let arr2 = temp.map(arr => (
                    {
                        Customer: arr.value1,
                        QueueName: arr.value2,
                        Language: arr.value3,
                        ValidFrom: arr.value4,
                        ValidTo: arr.value5,
                        ID: typeof arr.ID === 'string' ? '' : arr.ID
                    }
                ))
                let arr1 = [{
                    EmployeeID: supervisorInfo.EmployeeID,
                    SupEmployeeID: supervisorInfo.SupervisorID,
                    Details: arr2
                }]
                return arr1;
            }
            case 'assign': {
                let temp = [...IDdata];
                let arr2 = temp.map(arr => (
                    {
                        Customer: arr.value1,
                        IDAssigned: arr.value2,
                        SystemName: arr.value3,
                        ValidFrom: arr.value4,
                        ValidTo: arr.value5,
                        ID: typeof arr.ID === 'string' ? '' : arr.ID
                    }
                ))
                let arr1 = [{
                    EmployeeID: supervisorInfo.EmployeeID,
                    SupEmployeeID: supervisorInfo.SupervisorID,
                    Details: arr2
                }]
                return arr1;
            }
            case 'slakpi': {
                let temp = [...SLAData];
                let arr2 = temp.map(arr => (
                    {
                        Customer: arr.value1,
                        Target: arr.value4,
                        Weighting: arr.value5,
                        ValidFrom: arr.value6,
                        ValidTo: arr.value7,
                        AccountSLAID: arr.value8,
                        ID: typeof arr.ID === 'string' ? '' : arr.ID
                    }
                ))
                let arr1 = [{
                    EmployeeID: supervisorInfo.EmployeeID,
                    SupEmployeeID: supervisorInfo.SupervisorID,
                    Details: arr2
                }]
                console.log('posting data sla', arr1)
                return arr1;
            }
            case 'volume': {
                console.log('VolumeData', VolumeData)
                let temp = [...VolumeData];
                let arr2 = temp.map(arr => (
                    {
                        Customer: arr.value1,
                        Target: arr.value4,
                        Weighting: arr.value5,
                        ValidFrom: arr.value6,
                        ValidTo: arr.value7,
                        AccountVolumetricID: arr.value8,
                        ID: typeof arr.ID === 'string' ? '' : arr.ID
                    }
                ))
                let arr1 = [{
                    EmployeeID: supervisorInfo.EmployeeID,
                    SupEmployeeID: supervisorInfo.SupervisorID,
                    Details: arr2
                }]
                console.log('posting data', arr1)
                return arr1;
            }
            default: { }
        }
    }

    const onAccountSubmitHandle = (formData, slaId) => {
        switch (props.tab) {
            case 'account': {
                const newAccount = {
                    ID: (Math.random() * 10000).toString(),
                    value1: formData.value1,
                    value2: formData.value2,
                    value3: (formData.value3),
                    value4: (formData.value4),
                }
                const newAccounts = [newAccount, ...accountData]
                setAccountData(newAccounts);
                setShowaddAccountModal(false);
                dispatch(getStaffAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID));
                break;
            };
            case 'queue': {
                console.log('formData', formData)
                const newQueue = formData.map(queue => {
                    return {
                        ID: (Math.random() * 10000).toString(),
                        value1: queue.value1,
                        value2: queue.value2,
                        value3: queue.value3,
                        value4: queue.value4,
                        value5: queue.value5,
                    }
                });
                console.log('formData', newQueue, queueData)
                // const newQueue = {
                //     ID: (Math.random() * 10000).toString(),
                //     value1: formData.value1,
                //     value2: formData.value2,
                //     value3: (formData.value3),
                //     value4: (formData.value4),
                //     value5: (formData.value5),
                // }
                const newQueues = newQueue.concat(queueData)
                setQueueData(newQueues)
                setShowAddQueueModal(false);
                dispatch(getQueueData(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            }
            case 'assign': {
                const newid = {
                    ID: (Math.random() * 10000).toString(),
                    value1: formData.value1,
                    value2: formData.value2,
                    value3: (formData.value3),
                    value4: (formData.value4),
                    value5: (formData.value5),
                }
                const newids = [newid, ...IDdata]
                setIDData(newids)
                setShowAddIDModal(false);
                break;
            }
            case 'slakpi': {
                const newsla = {
                    ID: (Math.random() * 10000).toString(),
                    value1: formData.value1,
                    value2: formData.value2,
                    value3: (formData.value3),
                    value4: (formData.value4),
                    value5: (formData.value5),
                    value6: (formData.value6),
                    value7: (formData.value7),
                    value8: slaId
                }
                const newslas = [newsla, ...SLAData];
                setSLAData(newslas);
                setShowAddSLAModal(false);
                break;
            }
            case 'volume': {
                const newvolume = {
                    ID: (Math.random() * 10000).toString(),
                    value1: formData.value1,
                    value2: formData.value2,
                    value3: (formData.value3),
                    value4: (formData.value4),
                    value5: (formData.value5),
                    value6: (formData.value6),
                    value7: (formData.value7),
                    value8: slaId
                }
                const newvolumes = [newvolume, ...VolumeData];
                setVolumeData(newvolumes);
                setShowAddVolumeModal(false);
                break;
            }
            default: { }
        }
    }


    useEffect(() => {
        props.DispatchObjectManager(dispatchObjHandle());
    }, [accountData, queueData, IDdata, SLAData, VolumeData])


    const [filter, setFilter] = useState('');
    function searchRows(rows) {
        return rows?.filter((row) =>
            searchColumns?.some(
                (column) =>
                    row?.[column]?.toString()?.toLowerCase()?.indexOf(filter?.toLowerCase()) > -1,
            ),
        );
    }

    const [showAddAccountModal, setShowaddAccountModal] = useState(false);
    const [showAddQueueModal, setShowAddQueueModal] = useState(false);
    const [showAddIDModal, setShowAddIDModal] = useState(false);
    const [showAddSLAModal, setShowAddSLAModal] = useState(false);
    const [showAddVolumeModal, setShowAddVolumeModal] = useState(false);
    const [showEditAccountModal, setShowEditAccountModal] = useState(false);
    const [showEditQueueModal, setShowEditQueueModal] = useState(false);
    const [showEditIDModal, setShowEditIDModal] = useState(false);
    const [showEditSLAModal, setShowEditSLAModal] = useState(false);
    const [showEditVolumeModal, setShowEditVolumeModal] = useState(false);

    const AddModalHandleClick = () => {
        switch (tab) {
            case 'account': setShowaddAccountModal(true); break;
            case 'queue': setShowAddQueueModal(true)
                dispatch(getQueueData(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            case 'assign': setShowAddIDModal(true);
                dispatch(getIDAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            case 'slakpi': setShowAddSLAModal(true);
                dispatch(getSLAAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getAccountSLA(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getAccountSLAForEdit(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            case 'volume': setShowAddVolumeModal(true);
                dispatch(getSLAAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getVolumeTemplates(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getVolumeTemplatesForEdit(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            default: { }
        }
    }

    const EditModalHandleClick = () => {
        switch (tab) {
            case 'account': setShowEditAccountModal(true); break;
            case 'queue': setShowEditQueueModal(true);
                dispatch(getQueueData(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            case 'assign': setShowEditIDModal(true);
                dispatch(getIDAccounts(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            case 'slakpi': setShowEditSLAModal(true);
                dispatch(getAccountSLA(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getAccountSLAForEdit(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            case 'volume': setShowEditVolumeModal(true);
                dispatch(getVolumeTemplates(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                dispatch(getVolumeTemplatesForEdit(supervisorInfo.EmployeeID, supervisorInfo.SupervisorID))
                break;
            default: { }
        }
    }

    const EditModalHandler = (event, data) => {
        event.preventDefault();
        switch (props.tab) {
            case 'account':
                setEditRowID(data.ID);
                EditModalHandleClick();
                const formValues = {
                    value1: data.value1,
                    value2: data.value2,
                    value3: data.value3,
                    value4: data.value4
                };
                setEditFormInfo(formValues);
                break;
            case 'queue':
            case 'assign': {
                setEditRowID(data.ID);
                EditModalHandleClick();
                const formValues = {
                    value1: data.value1,
                    value2: data.value2,
                    value3: data.value3,
                    value4: data.value4,
                    value5: data.value5

                };
                setEditFormInfo(formValues);
                break;
            }
            case 'slakpi': {
                setEditRowID(data.ID);
                EditModalHandleClick();
                const formValues = {
                    value1: data.value1,
                    value2: data.value2,
                    value3: data.value3,
                    value4: data.value4,
                    value5: data.value5,
                    value6: data.value6,
                    value7: data.value7,
                    value8: data.value8
                };
                setEditFormInfo(formValues);
                break;
            }
            case 'volume': {
                console.log('value8', data)
                setEditRowID(data.ID);
                EditModalHandleClick();
                const formValues = {
                    value1: data.value1,
                    value2: data.value2,
                    value3: data.value3,
                    value4: data.value4,
                    value5: data.value5,
                    value6: data.value6,
                    value7: data.value7,
                    value8: data.value8
                };
                setEditFormInfo(formValues);
                break;
            }

            default: { }
        }

    }


    const handleEditQueueSubmit = (editedData, slaId) => {
        switch (props.tab) {
            case 'account': {
                const editedAccount = {
                    ID: editRowID,
                    value1: editedData.value1,
                    value2: editedData.value2,
                    value3: editedData.value3,
                    value4: editedData.value4
                };

                const newAccounts = [...accountData];
                const index = accountData.findIndex((account) => account.ID === editRowID);
                newAccounts[index] = editedAccount;
                setAccountData(newAccounts);
                setEditRowID(null);
                setShowEditAccountModal(false);
                break;
            }
            case 'queue': {
                const editedQueue = {
                    ID: editRowID,
                    value1: editedData.value1,
                    value2: editedData.value2,
                    value3: editedData.value3,
                    value4: editedData.value4,
                    value5: editedData.value5,
                };

                const newQueues = [...queueData];
                const index = queueData.findIndex((queue) => queue.ID === editRowID);
                newQueues[index] = editedQueue;
                setQueueData(newQueues);
                setEditRowID(null);
                setShowEditQueueModal(false)
                break;
            }
            case 'assign': {
                const editedId = {
                    ID: editRowID,
                    value1: editedData.value1,
                    value2: editedData.value2,
                    value3: editedData.value3,
                    value4: editedData.value4,
                    value5: editedData.value5
                };

                const newids = [...IDdata];
                const index = IDdata.findIndex((info) => info.ID === editRowID);
                newids[index] = editedId;
                setIDData(newids);
                setEditRowID(null);
                setShowEditIDModal(false)
                break;
            }
            case 'slakpi': {
                const editedSLA = {
                    ID: editRowID,
                    value1: editedData.value1,
                    value2: editedData.value2,
                    value3: editedData.value3,
                    value4: editedData.value4,
                    value5: editedData.value5,
                    value6: editedData.value6,
                    value7: editedData.value7,
                    value8: editedData.value8
                };
                const newslas = [...SLAData];
                const index = SLAData.findIndex((sla) => sla.ID === editRowID);
                newslas[index] = editedSLA;
                setSLAData(newslas);
                setEditRowID(null);
                setShowEditSLAModal(false)
                break;
            }
            case 'volume': {
                console.log('editedData', editedData)
                const editedVolume = {
                    ID: editRowID,
                    value1: editedData.value1,
                    value2: editedData.value2,
                    value3: editedData.value3,
                    value4: editedData.value4,
                    value5: editedData.value5,
                    value6: editedData.value6,
                    value7: editedData.value7,
                    value8: editedData.value8
                };
                console.log('................', VolumeData)
                const newvolumes = [...VolumeData];
                const index = VolumeData.findIndex((volume) => volume.ID === editRowID);
                newvolumes[index] = editedVolume;
                setVolumeData(newvolumes);
                setEditRowID(null);
                setShowEditVolumeModal(false)
                break;
            }
            default: { }
        }
    }
    const handleEditQueueCancel = event => {
        event.preventDefault();
        setEditRowID(null);
        setShowEditAccountModal(false);
        setShowEditQueueModal(false);
        setShowEditIDModal(false);
        setShowEditSLAModal(false);
        setShowEditVolumeModal(false);
    }



    const TableDataHandler = () => {
        switch (props.tab) {
            case 'account': return searchRows(accountData)
            case 'queue': return searchRows(queueData)
            case 'assign': return searchRows(IDdata)
            case 'slakpi': return searchRows(SLAData)
            case 'volume': return searchRows(VolumeData)
            default: { }
        }
    }

    console.log('queueLists', queueLists, queueData)
    let result = queueLists.filter(o1 => !queueData.some(o2 => o1.QueueName === o2.value2));
    // const repeatqueues = queueLists.filter((el) =>{
    //     return el?.QueueName === queueLists?.QueueName});

    console.log('queueLists', result)
    return (
        <>
            <div style={{ width: '98%' }}>
                <Fragment>
                    <section className='search-container' >
                        <div className='search-bar'>
                            <input className='search-input' value={filter || ''} onChange={e => setFilter(e.target.value)} />
                            <button className='search-icon'><img src={Search_Icon} alt='' /> </button>
                        </div>

                        {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1 ? <div className='add-queue' style={{ cursor: 'pointer' }} onClick={AddModalHandleClick}>
                            <img src={imgSrc} alt='' />
                            <span className='add-queue-span'>{AddTab}</span>
                        </div> : ''}
                    </section>
                </Fragment>
                {TableDataHandler().length === 0 ? <section className='noData-table'>
                    <div className='noData-headers'>
                        {headerData.map(header => (
                            <span style={{ border: 'none', marginLeft: '2%', marginRight: '7%' }} key={header.id}>
                                <span className='queue_header'>{header.caption}</span></span>
                        ))
                        }
                    </div>
                    <div className='noData-para' style={{ marginTop: 0 }}>
                        <div className='noData-para-span'>
                            No records found. This data grid enables adding/editing the corresponding sub-categories.
                            To add new queue entries, click on {`"${AddTab}"`}  link.
                        </div>
                    </div>
                </section> :
                    <section>
                        <table style={{ width: '100%', marginTop: '2em' }} className='queue-table'>
                            <thead>
                                <tr>
                                    {headerData.filter(header => header.caption !== "value").map(header => (
                                        <th style={{ border: 'none' }} key={header.id} ><span className='queue_header'>{header.caption}</span></th>
                                    ))
                                    }
                                    <th>{'  '}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {slashow === false ? <> {TableDataHandler().map((info, i) =>
                                (<tr key={i}>
                                    {Object.values(info).slice(1, info.length).map((value, idx) => (
                                        <td key={idx}>{value}</td>
                                    ))}
                                    {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1 ? <>
                                        <td className='Edit_Column'>
                                            <div>
                                                <span onClick={(event) => EditModalHandler(event, info)}>
                                                    <TooltipHandler src={Edit_Icon} width='16px' height='16px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                                                </span>
                                            </div>
                                            {endDateKey && moment(info[endDateKey]).isAfter(new Date()) ? '' :
                                                <main style={{ display: 'flex', gap: '5px' }}>
                                                    <div><img src={Inactive_Img} alt='' width='10px' height='10px' />&nbsp;</div>
                                                    <div> Inactive</div>
                                                </main>}

                                        </td>
                                    </> : ''}

                                </tr>))}
                                </> :
                                    <>
                                        {TableDataHandler().map((info, i) =>
                                        (<tr key={i}>
                                            <td>{info?.value1}</td>
                                            <td>{info?.value2}</td>
                                            <td>{info.value3}</td>
                                            <td>{info.value4}</td>
                                            <td>{info.value5}</td>
                                            <td>{info.value6}</td>
                                            <td>{info.value7}</td>

                                            {RoleBasedView?.[0]?.StaffAdmin?.[0]?.ReadWrite === 1 ? <>
                                                <td className='Edit_Column'>
                                                    <div>
                                                        <span onClick={(event) => EditModalHandler(event, info)}>
                                                            <TooltipHandler src={Edit_Icon} width='16px' height='16px' position='top center' arrowAlign='center' textBoxWidth='100px' text='Edit' />
                                                        </span>
                                                    </div>
                                                    {endDateKey && moment(info[endDateKey]).isAfter(new Date()) ? '' :
                                                        <main style={{ display: 'flex', gap: '5px' }}>
                                                            <div><img src={Inactive_Img} alt='' width='10px' height='10px' />&nbsp;</div>
                                                            <div> Inactive</div>
                                                        </main>
                                                    }
                                                </td>
                                            </> : ''}

                                        </tr>))}
                                    </>
                                }
                            </tbody>
                        </table>
                    </section>
                }
            </div>


            {(showAddAccountModal) && <QueueassignPopup isOpen={showAddAccountModal} setShowModal={setShowaddAccountModal}>
                <AccountAssignmentModal editedInfo={editFormInfo}
                    handleAddRoleCancel={() => setShowaddAccountModal(false)} editMode={false}
                    onAccountSubmitHandle={onAccountSubmitHandle}
                    AccountsInfo={AccountsInfo} accountData={accountData}
                />
            </QueueassignPopup>
            }

            {showEditAccountModal && <QueueassignPopup isOpen={showEditAccountModal} setShowModal={setShowEditAccountModal}>
                <AccountAssignmentModal
                    editMode={true} editedInfo={editFormInfo}
                    onAccountSubmitHandle={handleEditQueueSubmit}
                    handleAddRoleCancel={handleEditQueueCancel}
                    AccountsInfo={AccountsInfo} accountData={accountData}
                />
            </QueueassignPopup>
            }

            {showAddQueueModal && <QueueassignAddPopup isOpen={showAddQueueModal} setShowModal={setShowAddQueueModal}>
                <QueueAssignmentAddModal editedInfo={editFormInfo}
                    handleAddRoleCancel={() => setShowAddQueueModal(false)} editMode={false}
                    onAccountSubmitHandle={onAccountSubmitHandle}
                    data={result} queueData={queueData}
                />
            </QueueassignAddPopup>
            }


            {showEditQueueModal && <QueueassignPopup isOpen={showEditQueueModal} setShowModal={setShowEditQueueModal}>
                <QueueAssignmentModal editMode={true} editedInfo={editFormInfo}
                    onAccountSubmitHandle={handleEditQueueSubmit} queueData={queueData}
                    handleAddRoleCancel={handleEditQueueCancel} data={queueLists} />
            </QueueassignPopup>
            }


            {(showAddIDModal) && <QueueassignPopup isOpen={showAddIDModal} setShowModal={setShowAddIDModal}>
                <IDAssignmentModal editedInfo={editFormInfo} data={idAccounts}
                    handleAddRoleCancel={() => setShowAddIDModal(false)} editMode={false}
                    onAccountSubmitHandle={onAccountSubmitHandle} IDdata={IDdata}
                />
            </QueueassignPopup>
            }

            {showEditIDModal && <QueueassignPopup isOpen={showEditIDModal} setShowModal={setShowEditIDModal}>
                <IDAssignmentModal editMode={true} editedInfo={editFormInfo} data={idAccounts}
                    onAccountSubmitHandle={handleEditQueueSubmit}
                    handleAddRoleCancel={handleEditQueueCancel} IDdata={IDdata}
                />
            </QueueassignPopup>
            }

            {(showAddSLAModal) && <SLAModalPopup isOpen={showAddSLAModal} setShowModal={setShowAddSLAModal}>
                <SLAAssignmentModal editedInfo={editFormInfo} data={SLATemplates} slaAccounts={slaAccounts}
                    handleAddRoleCancel={() => setShowAddSLAModal(false)} editMode={false} SLAData={SLAData}
                    onAccountSubmitHandle={onAccountSubmitHandle} SLATemplatesForEdit={SLATemplatesForEdit}
                />
            </SLAModalPopup>
            }

            {showEditSLAModal && <SLAModalPopup isOpen={showEditSLAModal} setShowModal={setShowEditSLAModal}>
                <SLAAssignmentModal
                    editMode={true} editedInfo={editFormInfo} data={SLATemplates} SLAData={SLAData}
                    onAccountSubmitHandle={handleEditQueueSubmit} slaAccounts={slaAccounts}
                    handleAddRoleCancel={handleEditQueueCancel} SLATemplatesForEdit={SLATemplatesForEdit}
                />
            </SLAModalPopup>
            }

            {(showAddVolumeModal) && <SLAModalPopup isOpen={showAddVolumeModal} setShowModal={setShowAddVolumeModal}>
                <VolumeAssignmentModal editedInfo={editFormInfo} VolumeTemplates={VolumeTemplates} slaAccounts={slaAccounts}
                    handleAddRoleCancel={() => setShowAddVolumeModal(false)} editMode={false} VolumeData={VolumeData}
                    onAccountSubmitHandle={onAccountSubmitHandle} VolumeTemplatesForEdit={VolumeTemplatesForEdit}
                />
            </SLAModalPopup>
            }

            {showEditVolumeModal && <SLAModalPopup isOpen={showEditVolumeModal} setShowModal={setShowEditVolumeModal}>
                <VolumeAssignmentModal
                    editMode={true} editedInfo={editFormInfo} VolumeTemplates={VolumeTemplates}
                    onAccountSubmitHandle={handleEditQueueSubmit} slaAccounts={slaAccounts} VolumeData={VolumeData}
                    handleAddRoleCancel={handleEditQueueCancel} VolumeTemplatesForEdit={VolumeTemplatesForEdit}
                />
            </SLAModalPopup>
            }
        </>
    )
}

export default EmployeeTabHandler