import React from 'react'
import AdminBreadcrumb from '../../Administration/General/Admin-breadcrumb'
import { Footer } from '../HeaderFooter'
import Architecture_HeroBanner from './../../../assets/images/Architecture_HeroBanner.png';
import Conceptual from './../../../assets/images/Conceptual_Architecture_Icon.png';
import Conceptual_Architecture from './../../../assets/images/Conceptual_Architecture_Flow_Thumnail_Image.png';
import Functional from './../../../assets/images/Functional_Architecture_Icon.png';
import Functional_Architecture from './../../../assets/images/Functional_Architecture_Flow_Thumnail_Image.png';
import Technical from './../../../assets/images/Technical_Architecture_Icon.png';
import Technical_Architecture from './../../../assets/images/Technical_Architecture_Flow_Thumnail_Image.png';
import Information from './../../../assets/images/Information_Architecture_Icon.png';
import AdminRole_InformationArchitecture from './../../../assets/images/AdminRole_InformationArchitecture_Thumbnail.png';
import ExecutiveRole_InformationArchitecture from './../../../assets/images/ExecutiveRole_InformationArchitecture_Thumbnail.png';
import AccountDeliveryRole_InformationArchitecture from './../../../assets/images/AccountDeliveryRole_InformationArchitecture_Thumbnail.png';
import SiteLeaderRole_InformationArchitecture from './../../../assets/images/SiteLeaderRole_InformationArchitecture_Thumbnail.png';
import ServicetDeliveryManagerRole_InformationArchitecture from './../../../assets/images/ServicetDeliveryManagerRole_InformationArchitecture_Thumbnaile.png';
import OperationsDeliveryManagerRole from './../../../assets/images/OperationsDeliveryManagerRole_InformationArchitecture_Thumbnail.png';
import OperationsManagerRole from './../../../assets/images/OperationsManagerRole_InformationArchitecture_Thumbnail.png';
import TeamLeaderRole from './../../../assets/images/TeamLeaderRole_InformationArchitecture_Thumbnail.png';
import ServiceDeliverySpecialistRole from './../../../assets/images/ServiceDeliverySpecialistRole_InformationArchitecture_Thumbnail.png';
import SubjectMatterExpertRole from './../../../assets/images/SubjectMatterExpertRole_InformationArchitecture_Thumbnail.png';
import CoachTrainerRole from './../../../assets/images/CoachTrainerRole_InformationArchitecture_Thumbnail.png';
import AgentRole from './../../../assets/images/AgentRole_InformationArchitecture_Thumbnail.png';
import SiteViewerRole from './../../../assets/images/SiteViewerRole_InformationArchitecture_Thumbnail.png';
import { Carousel, CarouselItem } from 'react-bootstrap';
import FunctionalArchitecture from './../../../assets/images/Functional_Architecture.png';
import TechnicalArchitecture from './../../../assets/images/Technical_Architecture.png';
import ConceptualArchitecture from './../../../assets/images/Conceptual_Architecture.png';
import Account_Delivery_Role from './../../../assets/github-images/Account_Delivery_Role.svg';
import Administrator_Role from './../../../assets/github-images/Administrator_Role.svg';
import Agent_Role from './../../../assets/github-images/Agent_Role.svg';
import Coach_Trainer_Role from './../../../assets/github-images/Coach_Trainer_Role.svg';
import Executive_Role from './../../../assets/github-images/Executive_Role.svg';
import Operations_Delivery_Manager_Role from './../../../assets/github-images/Operations_Delivery_Manager_Role.svg';
import Operations_Manager_Role from './../../../assets/github-images/Operations_Manager_Role.svg';
import Service_Delivery_Manager_Role from './../../../assets/github-images/Service_Delivery_Manager_Role.svg';
import Serviced_Delivery_Specialist_Role from './../../../assets/github-images/Serviced_Delivery_Specialist_Role.svg';
import Site_Leader_Role from './../../../assets/github-images/Site_Leader_Role.svg';
import Site_Viewer_Role from './../../../assets/github-images/Site_Viewer_Role.svg';
import Subject_Matter_Expert_Role from './../../../assets/github-images/Subject_Matter_Expert_Role.svg';
import Team_Leader_Role from './../../../assets/github-images/Team_Leader_Role.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setGithubSolutionCarouselData } from '../../../actions/welcomePage.action';
import { Zoom } from '@material-ui/core';




const Architecture = () => {
  const Architecture_Cards = [
    {
      id: 1,
      imgsrc1: Conceptual,
      title: 'Conceptual Architecture',
      imgsrc2: Conceptual_Architecture,
      display: ConceptualArchitecture,
      imgWidth:1500,
      imgHeight:'',
    },
    {
      id: 2,
      imgsrc1: Functional,
      title: 'Functional Architecture',
      imgsrc2: Functional_Architecture,
      display: FunctionalArchitecture,
      width:1160,
      height:600,
      imgWidth:2000,
      imgHeight:710,
      },
    {
      id: 3,
      imgsrc1: Technical,
      title: 'Technical Architecture',
      imgsrc2: Technical_Architecture,
      display: TechnicalArchitecture,
      width:1160,
      height:600,
    }
  ]
  const Carousal_Cards = [
    [
      {
        id: 1,
        title: 'Administrator',
        thumbnail: AdminRole_InformationArchitecture,
        display: Administrator_Role,
        width:1160,
      height:600,
      imgWidth:4000,
      imgHeight:2000,
      },
      {
        id: 2,
        title: 'Executive',
        thumbnail: ExecutiveRole_InformationArchitecture,
        display: Executive_Role,
        width:1160,
      height:600,
      imgWidth:1500,
      imgHeight:1500,
      },
      {
        id: 3,
        title: 'Account Delivery',
        thumbnail: AccountDeliveryRole_InformationArchitecture,
        display: Account_Delivery_Role,
        width:1160,
        height:600,
        imgWidth:1500,
        imgHeight:1500,
      }
    ],
    [{
      id: 4,
      title: 'Site Leader',
      thumbnail: SiteLeaderRole_InformationArchitecture,
      display: Site_Leader_Role,
      width:1160,
      height:600,
      imgWidth:3000,
      imgHeight:2000,
    },
    {
      id: 5,
      title: 'Service Delivery Manager',
      thumbnail: ServicetDeliveryManagerRole_InformationArchitecture,
      display: Service_Delivery_Manager_Role,
      width:1160,
      height:600,
      imgWidth:2500,
      imgHeight:1500,
    },
    {
      id: 6,
      title: 'Operations Delivery Manager',
      thumbnail: OperationsDeliveryManagerRole,
      display: Operations_Delivery_Manager_Role,
      width:1160,
      height:600,
      imgWidth:1500,
      imgHeight:1500,
    }],
    [{
      id: 7,
      title: 'Operations Manager',
      thumbnail: OperationsManagerRole,
      display: Operations_Manager_Role,
      width:1160,
      height:600,
      imgWidth:1500,
      imgHeight:1500,
    },
    {
      id: 8,
      title: 'Team Leader',
      thumbnail: TeamLeaderRole,
      display: Team_Leader_Role,
      width:1160,
      height:600,
      imgWidth:1500,
      imgHeight:1500,
    },
    {
      id: 9,
      title: 'Serviced Delivery Specialist',
      thumbnail: ServiceDeliverySpecialistRole,
      display: Serviced_Delivery_Specialist_Role,
      width:1160,
      height:600,
      imgWidth:3500,
      imgHeight:2000,
    }],

    [{
      id: 10,
      title: 'Subject Matter Expert',
      thumbnail: SubjectMatterExpertRole,
      display: Subject_Matter_Expert_Role,
      width:1160,
      height:600,
      imgWidth:1500,
      imgHeight:1500,
    },
    {
      id: 11,
      title: 'Coach & Trainer',
      thumbnail: CoachTrainerRole,
      display: Coach_Trainer_Role,
      width:1160,
      height:600,
      imgWidth:1500,
      imgHeight:1500,
    },

    {
      id: 12,
      title: 'Agent',
      thumbnail: AgentRole,
      display: Agent_Role,
      width:1160,
      height:600,
      imgWidth:1500,
      imgHeight:1500,
    }],

    [{
      id: 13,
      title: 'Site Viewer',
      thumbnail: SiteViewerRole,
      display: Site_Viewer_Role,
      width:1160,
      height:300,
      imgWidth:1400,
      imgHeight:600,
    }]
  ]
  const history = useHistory();
  const dispatch = useDispatch();
  const onClickCarouselCard = card => {
    history.push('/architecture/card')
    let temp_obj = {
      title: card.title,
      display: card.display,
      width:card.width,
      height:card.height,
      imgWidth:card.imgWidth,
      imgHeight:card.imgHeight,
    }
    dispatch(setGithubSolutionCarouselData(temp_obj));
  }
  return (
    <>
      <section style={{ marginLeft: -100 }}>
        <main className='breadcrumb_solution'>
        
          <AdminBreadcrumb value1={()=>history.push('/githublanding')} label1='Home' isActive2={false} label2='Solution - Architecture' />
          <div style={{width:1200,margin:'auto'}}>
          <div id='arch_text'><div id='arch_head'>Architecture</div>A comprehensive architectural design and development has been executed to envision and build the GSD Insight solution to address our Capability’s reporting needs.</div>
         <div style={{paddingBottom:20,paddingLeft:80}}> <img src={Architecture_HeroBanner} alt='' /></div></div>
        </main>
        <main className='conceptual_arch'>
        <div style={{width:1200,margin:'auto',display:'flex',gap:40,paddingTop:'10px'}}>
        {Architecture_Cards.map((link, index) => (<div id='card_arch' onClick={()=>onClickCarouselCard(link)}>
            <img src={link.imgsrc1} alt='' /><div id='conceptual_text'>{link.title}</div><hr /><img src={link.imgsrc2} alt='' style={{cursor:'pointer'}}/>
          </div>))}</div>
        </main>
        <main className='information_arch'>
          <section id='info_arch_text' style={{width:1200,margin:'auto',paddingBottom:20}}>
            <div><img src={Information} alt='' style={{ marginRight: '40px' }} /></div>
            <main><div id='info_arch_head'>Information Architecture</div><div id='info_arch_body'>Based on the requirements the following user profiles were architected.</div></main>
          </section>
          <section className='carousel_div'>
          <div style={{width:1200,margin:'auto'}}>
            <Carousel>
              {Carousal_Cards.map((carousel, index) => (
                <CarouselItem>
                  <section style={{ display: 'flex', gap: '40px', paddingBottom: '20px' }} key={index} >
                    {carousel.map(card => (
                      <div id='block' onClick={() => onClickCarouselCard(card)}> {card.title} <div ><img src={card.thumbnail} alt='' width='300px' height='150px' /></div></div>))}
                  </section>
                </CarouselItem>))}
            </Carousel>
            </div>
          </section>
        </main>
        <section>
        </section>
        <Footer />
      </section>
    </>
  )
}

export default Architecture