import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import { PreviewCard, PreviewNonvoiceCard } from '../../Onboard/OnboardComponents/Preview/PreviewCard';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUploadPreviewData } from '../../../actions/uploads.action';
import Toggle from '../../Onboard/OnboardComponents/Preview/toggle';
import { setWidgetPreviewTab } from '../../../actions/Onboard.action';

const UploadPreview = (props) => {
  const dispatch = useDispatch();
  const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
  const UploadpreviewData = useSelector((state) => state.uploadReducer.UploadpreviewData);
  console.log('UploadpreviewData',UploadpreviewData)

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year,month, '01'].join('/');
}

const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));


const ValidFromDateHandler = (date) => {
    setSelectedDate((date === null || date === undefined) ? null : formatDate(date));
}


useEffect(()=>{
  dispatch(getUploadPreviewData(SelectedAccountDetails.CustomerID ? SelectedAccountDetails.CustomerID : '', SelectedAccountDetails.ServiceDeskID ? SelectedAccountDetails.ServiceDeskID : '',selectedDate))
},[dispatch,SelectedAccountDetails,selectedDate])


  return (
    <div><div className='preview__width'>
      <div className='Preview_head'>This page enables to view the previous steps filled status/update.</div><hr />
      <Row>
          <Col style={{ flex: 1.2 }}><div className='role__main'>Select Month</div></Col>
          <Col style={{ flex: 8 }}>
          <div >
          <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  openTo="month"
                  views={["year", "month"]}
                  value={selectedDate}
                  onChange={(date) => ValidFromDateHandler(date)}
                />
          </div>
          </Col>
          </Row>
        <br/>  <hr/>
          <br/>
      <PreviewCard title='Voice' value='' isDone={false} category={UploadpreviewData?.[0]?.VoiceHeader?.[0]?.ItemName} table={true} tableData={UploadpreviewData?.[0]?.VoiceHeader} onClick={props.onClickViewDetails}/>  <hr />
      <div>
        {/* <Row>
          <Col style={{ flex: 1 }}><div className='role__main'>Non Voice</div></Col>
          <Col style={{ flex: 3.5 }}>
            <PreviewNonvoiceCard value='' title={UploadpreviewData?.[0]?.NonVoiceHeader?.[0]?.ItemName} isDone={true} table={true} 
            tableData={UploadpreviewData?.[0]?.NonVoiceHeader?.[0]?.NonVoiceDetails} onClick={props.onClickViewDetails}/>
            <PreviewNonvoiceCard value='' title={UploadpreviewData?.[2]?.NonVoiceHeader?.[2]?.ItemName} isDone={true} table={true} 
            tableData={UploadpreviewData?.[2]?.NonVoiceHeader?.[2]?.NonVoiceDetails} onClick={props.onClickViewDetails}/>
            <PreviewNonvoiceCard value='' title={UploadpreviewData?.[4]?.NonVoiceHeader?.[4]?.ItemName} isDone={true} table={true} 
            tableData={UploadpreviewData?.[4]?.NonVoiceHeader?.[4]?.NonVoiceDetails} onClick={props.onClickViewDetails}/>
            <PreviewNonvoiceCard value='' title={UploadpreviewData?.[6]?.NonVoiceHeader?.[6]?.ItemName} isDone={false} table={true} 
            tableData={UploadpreviewData?.[6]?.NonVoiceHeader?.[6]?.NonVoiceDetails} onClick={props.onClickViewDetails}/>
          </Col>
          <Col style={{ flex: 3.5 }}>
            <PreviewNonvoiceCard value='' title={UploadpreviewData?.[1]?.NonVoiceHeader?.[1]?.ItemName} isDone={true} table={true} 
            tableData={UploadpreviewData?.[1]?.NonVoiceHeader?.[1]?.NonVoiceDetails} onClick={props.onClickViewDetails}/>
            <PreviewNonvoiceCard value='' title={UploadpreviewData?.[3]?.NonVoiceHeader?.[3]?.ItemName} isDone={true} table={true} 
            tableData={UploadpreviewData?.[3]?.NonVoiceHeader?.[3]?.NonVoiceDetails} onClick={props.onClickViewDetails}/>
            <PreviewNonvoiceCard value='' title={UploadpreviewData?.[5]?.NonVoiceHeader?.[5]?.ItemName} isDone={false} table={true} 
            tableData={UploadpreviewData?.[5]?.NonVoiceHeader?.[5]?.NonVoiceDetails} onClick={props.onClickViewDetails}/>
          </Col>
        </Row> */}
<div style={{display:'flex'}}>
        <section><div className='nonvoice_title_flex'>Non Voice</div></section>
<section className='Nonvoice_Grid'>
{UploadpreviewData?.[0]?.NonVoiceHeader.map((data,id) => (<div>
  <div className='role_preview'>{data.ItemName}</div>
            
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <table className='upload_table_body'>
                    <thead>
                        <tr className='upload_table_head'>
                            <th>File Upload</th>
                            <th>Volume Data</th>
                            <th>SLAs/KPIs Data</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{data?.['File Upload']}</td>
                            <td>{data.Volume}</td>
                            <td>{data?.['SLA/KPI']}</td>
                        </tr></tbody>
                    </table>
                </div>
            
            <Row>
                <Col style={{ flex: 10 }}><div><Toggle value={props.value} /></div></Col>
                <Col style={{ flex: 5 }}><div className='view__details' onClick={() => {
                    dispatch(setWidgetPreviewTab(data.ItemName))
                    props.onClickViewDetails(data.ItemName);
                }}>View Details</div></Col>
            </Row>
            <hr /></div>
))    
}</section></div>
      </div>
      <hr />
      <div></div></div></div>
  )
}

export default UploadPreview