import React, { useEffect, useRef, useState } from 'react';
import './Dropdown.css'
import downArrow from '../../../assets/images/downward_arrow.png'
import upArrow from '../../../assets/images/upward_arrow.png'
function Dropdown(props) {
    const [openData, setOpenData] = useState(false);
    const [query, setQuery] = useState('');
    const filterQuery = options => {
        return options.filter(option => {
            if (query === '') return option;
            else if(option.value.toLowerCase().includes(query.toLowerCase().trim()))
                return option;
        })
    }


    useEffect(()=>{
     if(!openData)   setQuery('')
    },[openData])

   
    const ref = useRef();
    useEffect(() => {
  
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
           if(!openData)
            setOpenData(openData);
        }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {       
        document.removeEventListener("click", handleClickOutside);
    };
}, [ref]);
    const DropdownSelectHandler = (option, drpdwnID) => {
        props.onSelectingDropdown(option, drpdwnID);
    }

    useEffect(()=>{
        if(props.clearFilters===true && props.latestUpdates===true){
            props.data.map(data=>data.isChecked=false);
        }
    },[props.clearFilters])
    return <React.Fragment>
        <section className={props.className} >
            <div className=' wrapper' >
                <input className='input-field' type='text' placeholder='Search' value={query}
                    onChange={e => { setQuery(e.target.value); setOpenData(true) }}
                    onClick={() => setOpenData(!openData)}
                    // ref={ref} 
                    />
                <div className='button-arrow arrow-btn'
                onClick={() => setOpenData(!openData)} 
                ref={ref} 
               >
                    {!openData ? <img src={downArrow} alt="arrow" /> :
                        <img src={upArrow} alt="arrow" />}
                </div>
            </div>
            <div>
                {openData && <ul className={`ul-wrapper`}>
                    {filterQuery(props.data)?.map((option) =>
                    (<li value={option?.value} key={option?.label} className='list-wrapper' id='id-hover'
                        onClick={() => {
                            setQuery('')
                            DropdownSelectHandler(option, props?.id)
                        }}
                    
                    >
                        <input type='checkbox'
                            className='checkbox-input'
                            name={option?.value}
                            value={option?.value}
                            checked={
                            //   (props.clearFilters===true && props.latestUpdates===true) ? false  : 
                               option?.isChecked
                             }
                        />
                        <label className='label-region'>  {option.value} </label>
                    </li>)
                    )}
                </ul>}
            </div>
        </section>

    </React.Fragment>;
}

export default Dropdown;
