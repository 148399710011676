
const getRoleId = () => {
    return localStorage.getItem('Role_id');
}

const AdminAccountURLS = (name, items) => {
    switch (name) {
        case 'GET_ADMIN_ACCOUNTS': {
            return `/api/usp/AdmOBGetAccounts?&hint=admin&RoleID=${getRoleId()}`;
        }
        case 'UPDATE_VALIDTO_DATE': {
            return `/api/usp/AdmOnBoardAccount`
        }
        case 'GET_ADMIN_QUEUE_TABLE_DATA': {
            return `/api/usp/AdmOBGetQueuesByAccount?Customer=${items.account}&Servicedesk=${items.site}`;
        }
        case 'UPDATE_ADMIN_QUEUE_TABLE_DATA': {
            return `/api/usp/AdmOnBoardQueues`
        }
        case 'GET_ADMIN_SLA_TABLE_DATA': {
            return `/api/usp/AdmOBGetSLAKPIByAccount?Customer=${items.account}&Servicedesk=${items.site}&VoiceNonVoice=${items.tab}&NonVoiceItem=${items.card}`
        }
        case 'UPDATE_ADMIN_SLA_TABLE_DATA': {
            return `api/usp/AdmOnBoardSLAKPIAccount`
        }
        case 'GET_ADMIN_SUPPORT_TABLE_DATA': {
            return `/api/usp/AdmOBGetSupportHoursByAccount?Customer=${items.account}&Servicedesk=${items.site}&VoiceNonVoice=${items.tab}&NonVoiceItem=${items.card}`
        }
        case 'UPDATE_ADMIN_SUPPORT_TABLE_DATA': {
            return `api/usp/AdmOnBoardSupportHours`
        }
        case 'GET_ADMIN_VOICE_SLA_TABLE_DATA': {
            return `/api/usp/AdmOBGetSLAKPIByAccount?Customer=${items.account}&Servicedesk=${items.site}&VoiceNonVoice=Voice&NonVoiceItem=AWS (ASD) Telephony`
        }
        case 'GET_ADMIN_VOICE_SUPPORT_TABLE_DATA': {
            return `/api/usp/AdmOBGetSupportHoursByAccount?Customer=${items.account}&Servicedesk=${items.site}&VoiceNonVoice=Voice&NonVoiceItem=AWS (ASD) Telephony`
        }

        case 'QUEUE_ADMIN_REQUEST_START': {
            return `api/usp/AdmQueueActivation`
        }
        case 'SLA_ADMIN_REQUEST_START': {
            return `api/usp/AdmAccountSLAKPIActivation`
        }
        case 'SUPPORT_ADMIN_REQUEST_START': {
            return `api/usp/AdmSupportHoursActivation`
        }
        case 'UPDATE_ADMIN_UPDATE': {
            return `/api/usp/AdminAccountUpdate`;
        }
        case 'GET_PREVIEW_ADMIN': {
            return `/api/usp/AdmnPreview?Customer=${items.account}&Servicedesk=${items.site}`;
        }
        case 'GET_ROLE_BASED_VIEW': {
            return `/api/usp/OperationsByRole?RoleId=${getRoleId()}`;
        }
        default: { }
    }
}


export default AdminAccountURLS



