import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setWidgetPreviewTab } from '../../../../actions/Onboard.action';
import "./Preview.css";
import Toggle from './toggle';

export function PreviewCard(props) {
    const TableData = props.tableData?.[0]
    console.log('TableData',TableData)
    return (
        <>
            <Row className={props.className}>
                <Col style={{ flex: 1.2 }}><div className='role__main'>{props.title}</div></Col>
                <Col style={{ flex: 8 }}>
                    {props.category && <div className='role_preview'>{props.category}</div>}
                    {props.table === false ?
                        <div className='complete_preview'>Complete</div> :
                        <div style={{ marginTop: '20px', marginBottom: '20px' }} >
                            <table>
                                <tr className='upload_table_head'>
                                    <th>File Upload</th>
                                    <th>Volume Data</th>
                                    <th>SLAs/KPIs Data</th>
                                </tr>
                                <tr>
                                <td>{TableData?.['File Upload']}</td>
                            <td>{TableData?.Volume}</td>
                            <td>{TableData?.['SLA/KPI']}</td>
                                </tr></table>
                        </div>
                    }
                    <Row>
                        <Col style={{ flex: 4.2 }}><div><Toggle value={props.value} /></div></Col>
                        <Col style={{ flex: 8 }}><div className='view__details' onClick={() => props.onClick(props.title)}>View Details</div></Col>
                    </Row>
                </Col>
            </Row>
        </>)
}


export function PreviewNonvoiceCard(props) {
    const dispatch = useDispatch()
    const TableData = props.tableData
    console.log('TableData',TableData)
    return (
        <>
            <div className='role_preview'>{props.title}</div>
            {props.table === false ?
                <div className='complete_preview'>Complete</div> :
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <table className='upload_table_body'>
                        <tr className='upload_table_head'>
                            <th>File Upload</th>
                            <th>Volume Data</th>
                            <th>SLAs/KPIs Data</th>
                        </tr>
                        <tr>
                            <td>{TableData?.[0]?.Value}</td>
                            <td>{TableData?.[1]?.Value}</td>
                            <td>{TableData?.[2]?.Value}</td>
                        </tr>
                    </table>
                </div>
            }
            <Row>
                <Col style={{ flex: 10 }}><div><Toggle value={props.value} /></div></Col>
                <Col style={{ flex: 5 }}><div className='view__details' onClick={() => {
                    dispatch(setWidgetPreviewTab(props.title))
                    props.onClick(props.title);
                }}>View Details</div></Col>
            </Row>
            <hr />
        </>
    )
}
