import { faTruckMonster } from "@fortawesome/free-solid-svg-icons";

class Datasets {   
    constructor(){
        this.label = '';
        this.data = [];
        this.fill = true;
        this.backgroundColor = [];
        this.borderColor = [];
        this.tension = 0.1;
        this.isPrcntSymbl = [];
    }
}

export class LineAreaChartModel {
    labels = [];
    datasets;    
    constructor(){
        this.labels = [];
        this.datasets = [new Datasets()]; 
    }
}
export const LineAreChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                    borderColor: 'white'
                },
                ticks: {
                    display: false
               }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                    borderColor: 'white'
                },
                ticks: {
                    display: false
               }
            }
        }, 
        plugins:{
            tooltip: {
                //yAlign: 'bottom',
                displayColors: false,
                backgroundColor: 'white',
                bodyColor: 'black',
                titleMarginBottom: 0,
                borderColor: '#969696',
                borderWidth: 1,
                titleColor: '#63666A',
                titleAlign: 'center',
                titleFont: {weight: '400'},
                displayColors: false,
                padding: 10,
                cornerRadius: 0,
                caretSize:5,
                bodyFont: {weight: 'bolder'},
                callbacks: {
                    label: (context) => {  
                        let label = "";             
                        if (context.dataset.isPrcntSymbl[0]) {
                          label = context.parsed.y + "%";
                        }else{
                          label = context.parsed.y;
                        }
                        return label;                   
                    }
                  }

              } 
        }             
    };

    

    export const ModalLineAreChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                displayColors: false,
                backgroundColor: 'white',
                bodyColor: 'black',
                titleMarginBottom: 0,
                borderColor: '#969696',
                borderWidth: 1,
                titleColor: '#63666A',
                titleAlign: 'center',
                titleFont: {weight: '400'},
                displayColors: false,
                padding: 10,
                cornerRadius: 0,
                caretSize:5,
                bodyFont: {weight: 'bolder'}
            }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    borderColor: 'white'
                },
                ticks: {
                    display: true
               }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: true,
                    borderColor: 'white'
                },
                ticks: {
                    display: faTruckMonster
               }
            }
        }
    };

    export const ModalLineAreChartOptionsTlptip = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                displayColors: false,
                backgroundColor: 'white',
                bodyColor: 'black',
                titleMarginBottom: 0,
                borderColor: '#969696',
                borderWidth: 1,
                titleColor: '#63666A',
                titleAlign: 'center',
                titleFont: {weight: '400'},
                displayColors: false,
                padding: 10,
                cornerRadius: 0,
                caretSize:5,
                bodyFont: {weight: 'bolder'},
                callbacks: {
                  label: (context) => {    
                      let label = "";             
                      if (context.parsed.y) {
                        label = context.parsed.y + "%"
                      }
                      return label;                   
                  }
                }
              }
        },
        scales: {
            x: {
                grid: {
                    display: false,
                    borderColor: 'white'
                },
                ticks: {
                    display: true
               }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: true,
                    borderColor: 'white'
                },               
               ticks: {
                display: faTruckMonster,
                callback: function(value, index, values){
                  return `${value} %`;
                }
              }  
            }
        }
    };

    export const ModalBarChartOptions={
        responsive: true,
        maintainAspectRatio: false,
        plugins:{
            tooltip: {
                displayColors: false,
                backgroundColor: 'white',
                bodyColor: 'black',
                titleMarginBottom: 0,
                borderColor: '#969696',
                borderWidth: 1,
                titleColor: '#63666A',
                titleAlign: 'center',
                titleFont: {weight: '400'},
                displayColors: false,
                padding: 10,
                cornerRadius: 0,
                caretSize:5,
                bodyFont: {weight: 'bolder'}
            }
        },
        legend: {
          display: false,
    
        },
        title: {
          display: false,
        },
        scales: {
            x: { 
            stacked: false,
            ticks: {
              beginAtZero: true,
              display: true
            },
            display: true
          },          
          y: {        
            display: true
          },
        }
    };


   export const ModalBarChartData={
        labels: [],
        datasets: [{
          backgroundColor: ['#00A3E1'],
          borderColor: ['#00A3E1'],
          borderWidth: 0,
          data: []
        }]
      };