
import { onboard_actionTypes as actionTypes } from "../constants/Onboard-actionTypes"

export const getAccountSite = (data) => (
  {
    type: actionTypes.GET_ACCOUNT_SITE,
    payload: data,
  }
)


export const getGeneralInfoSites = (data) => (
  {
    type: actionTypes.GET_GENERAL_INFO_SITE,
    payload: data,
  }
)

export const getRolesTabRoles = (data) => (
  {
    type: actionTypes.GET_ROLES_TAB_ROLES,
    payload: data,
  }
)

export const getRolesTabUsers = (data) => (
  {
    type: actionTypes.GET_ROLES_TAB_USERS,
    query: data,
  }
)


export const saveAccountSite = (data) => (
  {
    type: actionTypes.SAVE_ACCOUNT_SITE,
    payload: data,
  }
)


export const saveCancelBtnClick = (data) => (
  {
    type: actionTypes.SAVE_CANCEL_CLICK,
    payload: data,
  }
)

export const updateRoleDataStart = (data) => (
  {
    type: actionTypes.UPDATE_ROLE_DATA_START,
    payload: data,
  }
)


export const updateRoleDataSuccess = () => (
  {
    type: actionTypes.UPDATE_ROLE_DATA_SUCCESS,
  }
)

export const updateQueueTableStart = (data) => (
  {
    type: actionTypes.UPDATE_QUEUE_TABLE_START,
    payload: data,
  }
)


export const updateQueueTableSuccess = () => (
  {
    type: actionTypes.UPDATE_QUEUE_TABLE_SUCCESS,
  }
)

export const getQueueTabLanguages = (data) => (
  {
    type: actionTypes.GET_QUEUE_LANGUAGES,
    payload: data,
  }
)

export const getOriginatingCountry = (data) => (
  {
    type: actionTypes.GET_ORIGINATING_COUNTRIES,
    payload: data,
  }
)

export const getQueueTableData = (account, site) => (
  {
    type: actionTypes.GET_QUEUE_TABLE_DATA,
    account: account,
    site: site
  }
)

export const updateSLADataStart = (data) => (
  {
    type: actionTypes.UPDATE_SLA_TABLE_START,
    payload: data,
  }
)


export const updateSLADataSuccess = () => (
  {
    type: actionTypes.UPDATE_SLA_TABLE_SUCCESS,
  }
)

export const getSLATemplates = (account, tab) => (
  {
    type: actionTypes.GET_SLA_TEMPLATES,
    account: account,
    tab: tab
  }
)


export const updateNewTemplateStart = (data) => (
  {
    type: actionTypes.UPDATE_NEW_TEMPLATE_START,
    payload: data,
  }
)


export const updateNewTemplateSuccess = () => (
  {
    type: actionTypes.UPDATE_NEW_TEMPLATE_SUCCESS,
  }
)



export const getSLAKPIData = (account, site) => (
  {
    type: actionTypes.GET_SLA_KPI_DATA,
    account: account,
    site: site
  }
)


export const updateSupportHoursStart = (data) => (
  {
    type: actionTypes.UPDATE_SUPPORT_TABLE_START,
    payload: data,
  }
)


export const updateSupportHoursSuccess = () => (
  {
    type: actionTypes.UPDATE_SUPPORT_TABLE_SUCCESS,
  }
)


export const getRoleTabData = (account, site) => (
  {
    type: actionTypes.GET_ROLES_TAB_DATA,
    account: account,
    site: site
  }
)

export const getSupportHoursVoice = (account, site) => (
  {
    type: actionTypes.GET_SUPPORT_HOURS_VOICE,
    account: account,
    site: site
  }
)
export const onSaveSupportHoursVoice = (data) => (
  {
    type: actionTypes.ON_SAVE_SUPPORT_HOURS_VOICE,
    payload: data,
  }
)


export const getNonVoiceSLAData = (account, site, widget) => (
  {
    type: actionTypes.GET_NON_VOICE_SLA_DATA,
    account: account,
    site: site,
    widget: widget
  }
)
export const getNonVoiceSupportHours = (account, site, widget) => (
  {
    type: actionTypes.GET_NON_VOICE_SUPPORT_HOURS,
    account: account,
    site: site,
    widget: widget
  }
)


export const onSaveSLAKPIData = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_DATA,
    payload: data,
  }
)


export const updateOnboardToggleStart = (data) => (
  {
    type: actionTypes.UPDATE_ONBOARD_TOGGLE_START,
    payload: data,
  }
)


export const updateOnboardToggleSuccess = () => (
  {
    type: actionTypes.UPDATE_ONBOARD_TOGGLE_SUCCESS,
  }
)


export const updateOnboardSubmitStart = (data) => (
  {
    type: actionTypes.UPDATE_ONBOARD_SUBMIT_START,
    payload: data,
  }
)


export const updateOnboardSubmitSuccess = () => (
  {
    type: actionTypes.UPDATE_ONBOARD_SUBMIT_SUCCESS,
  }
)






export const setWidgetCardNonVoice = (data) => (
  {
    type: actionTypes.SET_WIDGET_CARD_NON_VOICE,
    payload: data,
  }
)

export const setWidgetPreviewTab = (data) => (
  {
    type: actionTypes.SET_WIDGET_PREVIEW_TAB,
    payload: data,
  }
)

export const setValidforAddingTabRows = (data) => (
  {
    type: actionTypes.SET_VALIDFOR_ADDING_TABROWS,
    payload: data,
  }
)

//Redux for saving table Info


export const onSaveSLAChatTool = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_CHAT_TOOL,
    payload: data,
  }
)

export const onSaveSLATicketTool = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_TICKET_TOOL,
    payload: data,
  }
)

export const onSaveSLAWebPortal = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_WEB_PORTAL,
    payload: data,
  }
)
export const onSaveSLAEmail = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_EMAIL,
    payload: data,
  }
)
export const onSaveSLACustSurvey = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_CUST_SURVEY,
    payload: data,
  }
)
export const onSaveSLATransAquaa = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_TRANS_AQUAA,
    payload: data,
  }
)
export const onSaveSLAWorkForce = (data) => (
  {
    type: actionTypes.ON_SAVE_SLA_WORK_FORCE,
    payload: data,
  }
)


export const updateGeneralNonvoiceStart = (data) => (
  {
    type: actionTypes.UPDATE_GENERAL_NON_VOICE_START,
    payload: data,
  }
)


export const updateGeneralNonvoiceSuccess = () => (
  {
    type: actionTypes.UPDATE_GENERAL_NON_VOICE_SUCCESS,
  }
)


export const getGeneralNonVoice = (account, site, widget) => (
  {
    type: actionTypes.GET_GENERAL_NON_VOICE,
    account: account,
    site: site,
    widget: widget
  }
)

export const getPreviewOnboard = (account, site) => (
  {
    type: actionTypes.GET_PREVIEW_ONBOARD,
    account: account, site: site
  }

)


export const getOnboardAccounts = (data) => (
  {
    type: actionTypes.GET_ONBOARD_ACCOUNTS,
    payload: data,
  }
)

export const viewAccountDetails = (data) => (
  {
    type: actionTypes.VIEW_ACCOUNT_DETAILS,
    payload: data,
  }
)

export const editAccountDetails = (data) => (
  {
    type: actionTypes.EDIT_ACCOUNT_DETAILS,
    payload: data,
  }
)

export const getOnboardValidUser = (account, site) => (
  {
    type: actionTypes.GET_ONBOARD_VALID_USER,
    account: account,
    site: site
  }
)

export const updateReOpenRequestStart = (data) => (
  {
    type: actionTypes.UPDATE_REOPEN_REQUEST_START,
    payload: data,
  }
)

export const updateReOpenRequestSuccess = () => (
  {
    type: actionTypes.UPDATE_REOPEN_REQUEST_SUCCESS,
  }
)

export const updateDeleteAccountStart = (data) => (
  {
    type: actionTypes.UPDATE_DELETE_ACCOUNT_START,
    payload: data,
  }
)

export const updateDeleteAccountSuccess = () => (
  {
    type: actionTypes.UPDATE_DELETE_ACCOUNT_SUCCESS,
  }
)