import React from 'react';
import './WidgetModal.css'
import  { Banner } from './WidgetModal';
import AccountSelect from '../Welcome-LandingPage/AccountSelect';

function LandingPage() {
    return (

        <React.Fragment>
            <section className='welcome_page_container' >
                <Banner />
               <div style={{marginTop:'-120px',width:'100%',height:''}}> 
               <AccountSelect/>
               </div>
               
            </section>
        </React.Fragment>
    )
}

export default LandingPage
    