import React, { Component } from "react";
import BredCrumb from "../Breadcrumb/bredcrumb";
import TabMenuBar from "../TabMenu/tabmenu";
import "./insights.css";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { connect } from "react-redux";
import moment from 'moment';
import {
  getMonthData,
  getSummaryData,
  getWeeklyData,
  getDailyData,
  getAnnualData,
  updateDTMReportDate
} from "../../actions/tabMenu.action";
export class Insights extends Component {

  constructor(props) {
    super(props);
    window.localStorage.setItem(
      "selectedMonth",
      new Date().toISOString().split("T")[0]
    );

    this.state = {
      selectedDate: new Date(),
      accName: '',
      breadCrumbData: [this.props.userReducer.breadCrumbArr[0]]
    };
  }
  formatDatehandle(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }
  componentDidMount() {
    let selectedDT = this.props.userReducer.cal_dt;
    this.updateBreadCrumb(selectedDT);
    if (this.props?.tabReducer?.DTMReportPageDate) {
      let dt = this.props?.tabReducer?.DTMReportPageDate;
      this.setState((prevState) => {
        return { ...prevState, selectedDate: dt }
      });
      this.handleDateChange(moment().set({ 'year': dt.getFullYear(), 'month': dt.getMonth() }))
      this.updateBreadCrumb(dt)
    }

  }

  updateBreadCrumb = (dt) => {
    let tmpArr = [];
    tmpArr.push(this.props.userReducer.breadCrumbArr[0]);
    let rptObj = { name: 'Reports', reDirect: '/regional' };
    tmpArr.push(rptObj);
    if (this.props.tabReducer.selectedAccLocationName) {
      let rgnName = this.props.tabReducer.selectedAccLocationName.split(',');
      let cntryName = rgnName[1];
      let siteName = rgnName[0];
      let cntryObj = { name: cntryName, reDirect: '/regional' };
      let siteObj = { name: siteName, reDirect: '/regional' };
      tmpArr.push(cntryObj);
      tmpArr.push(siteObj);
    }
    if (this.props.tabReducer.selectedAccountName != '' && this.props.tabReducer.selectedAccLocationName != '') {
      let brdData = this.props.tabReducer.selectedAccountName + '-' + this.dateToYMD(dt);
      let obj = { name: brdData, reDirect: '' };
      tmpArr.push(obj);
      this.setState({ breadCrumbData: tmpArr, selectedDate: new Date(dt) });
    }
  }


  getClientCustID = () => {
    const custID = window.localStorage.getItem("CustomerID");
    return custID;
  };

  handleDateChange = (e) => {
    this.props.updateDTMReportDate(new Date(e));
    this.updateBreadCrumb(new Date(e));
    let dt = new Date(e).toISOString().split("T")[0];
    window.localStorage.setItem("selectedMonth", dt);
    let tabName = window.localStorage.getItem("slctdTabName");
    switch (tabName) {
      case "Summary": {
        this.props.fetchSummaryTabData();
        break;
      }
      case "Monthly": {
        this.props.fetchMonthTabData();
        break;
      }
      case "Weekly": {
        this.props.fetchWeeklyTabData();
        break;
      }
      case "Daily": {
        this.props.fetchDailyTabData();
        break;
      }
      case "Annual": {
        this.props.fetchAnnualTabData();
        break;
      }
      default: {
      }
    }
    this.setState({ selectedDate: new Date(e) });
  };

  dateToYMD = (date) => {
    var strArray = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    var m = strArray[date.getMonth()];
    var y = date.getFullYear();
    return m + " " + y;
  };

  render() {
    return (
      <div className="gsd-main-container">
        <BredCrumb data={this.state.breadCrumbData} />
        <div className="client-row">
          <div className="row hd">
            <div className="client-icon">
              <img
                className="client-icon-img"
                type="image/jpeg"
                src={`${process.env.REACT_APP_BASE_URL}/api/org/customer/${this.getClientCustID()}/photo`}
                alt="Client Logo"
              />
            </div>
            <div className="client-name">
              {this.props.tabReducer.selectedAccountName}<span className="border-right"></span>
            </div>
            <div className="client-date">
              <KeyboardDatePicker
                autoOk
                variant="inline"
                openTo="month"
                views={["year", "month"]}
                label="Select Month"
                value={this.state.selectedDate}
                onChange={this.handleDateChange}
              />
            </div>
          </div>
        </div>
        <div style={{ width: "95%" }}>
          <TabMenuBar></TabMenuBar>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => { return { ...state } };


const mapDispatchToProps = (dispatch) => {
  return {
    fetchMonthTabData: () => dispatch(getMonthData()),
    fetchSummaryTabData: () => dispatch(getSummaryData()),
    fetchWeeklyTabData: () => dispatch(getWeeklyData()),
    fetchDailyTabData: () => dispatch(getDailyData()),
    fetchAnnualTabData: () => dispatch(getAnnualData()),
    updateDTMReportDate: (e) => dispatch(updateDTMReportDate(e))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
