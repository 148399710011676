import React, { useState, useEffect, useRef } from "react";
import "./select.css";
import downward_icon from '../../assets/images/downward_arrow.png'
import upward_icon from '../../assets/images/upward_arrow.png'
const CustomSelect = (props) => { 
    const [options, setOptions] = useState([]);
    const [openData, setOpenData] = useState(false);
    const [selectedVal, setSelectedVal] = useState(props.selectedVal ? props.selectedVal : '');
    const [queryVal, setQueryVal] = useState('');
    const ref = useRef();
    let isSearchable = props.isSearchable ? props.isSearchable : false;
    useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
           // alert('inside lick3');
           if(!openData)
            setOpenData(openData);
            //event.preventDefault();
        }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {       
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
    };
}, [ref]);

const filterQuery = (query,dt) => {
    return dt?.filter(option => {
        if (query === '') return option;
        else if (option?.value?.toLowerCase().includes(query.toLowerCase().trim()))
         return option;
    })
}

    return (
        <div className="row">
        <section className={props.className}>
            <div className='wrapper'
                onKeyPress={() => { setOpenData(!openData) }}  >
                <input className=' input-field cust-inpt-fld' 
                onChange={e => {
                    setOpenData(true);
                    setSelectedVal(e.target.value); 
                    setQueryVal(e.target.value);
                }}
                type='text' 
                value={selectedVal} 
                placeholder="Select" 
                disabled={isSearchable}/>
                <button className='button-arrow arrow-btn cust-btn-arw' onClick={(event) => {
                    setOpenData(!openData);   
                    setQueryVal('');                 
                    event.preventDefault()
                }} ref={ref} >
                    {!openData ? <img src={downward_icon} alt="arrow" /> :
                        <img src={upward_icon} alt="arrow" />}
                </button>
            </div>
            <div>
                {openData && <ul className='ul-wrapper cust-ul-wrapper'>
                    {filterQuery(queryVal, props?.options)?.map(option =>
                    (<li value={option.value} key={option.id} className='list-wrapper'  onClick={()=>{setSelectedVal(option.value); setOpenData(!openData); props.selectedRegionHandler(option.id, option.value);}}>                        
                        <label className='label-region' htmlFor={option.id}>  {option.value} </label>
                    </li>)
                    )}

                </ul>}
            </div>
        </section>
    </div>
    );
};

export default CustomSelect;