import adminActionTypes from '../../constants/Administration/admin-action-types'

export const getAdminAccounts = (data) => (
  {
    type: adminActionTypes.GET_ADMIN_ACCOUNTS,
    payload: data,
  }
)


export const updateValidToDateStart = (data) => (
  {
    type: adminActionTypes.UPDATE_VALIDTO_DATE_START,
    payload: data,
  }
)


export const updateValidToDateSuccess = () => (
  {
    type: adminActionTypes.UPDATE_VALIDTO_DATE_SUCCESS,
  }
)

export const updateAccountDetails = (data) => (
  {
    type: adminActionTypes.UPDATE_ACCOUNT_DEATILS,
    payload: data,
  }
)

export const getAdminQueueTableData = (account, site) => (
  {
    type: adminActionTypes.GET_ADMIN_QUEUE_TABLE_DATA,
    account: account,
    site: site,
  }
)

export const getAdminVoiceSlaTableData = (account, site) => (
  {
    type: adminActionTypes.GET_ADMIN_VOICE_SLA_TABLE_DATA,
    account: account,
    site: site,
  }
)
export const getAdminVoiceSupportTableData = (account, site) => (
  {
    type: adminActionTypes.GET_ADMIN_VOICE_SUPPORT_TABLE_DATA,
    account: account,
    site: site,
  }
)


export const updateAdminQueueTableDataStart = (data) => (
  {
    type: adminActionTypes.UPDATE_ADMIN_QUEUE_TABLE_DATA_START,
    payload: data,
  }
)


export const updateAdminQueueTableDataSuccess = () => (
  {
    type: adminActionTypes.UPDATE_ADMIN_QUEUE_TABLE_DATA_SUCCESS,
  }
)

export const getAdminSlaTableData = (account, site, tab, card) => (
  {
    type: adminActionTypes.GET_ADMIN_SLA_TABLE_DATA,
    account: account,
    site: site,
    tab: tab,
    card: card
  }
)

export const updateAdminSlaTableDataStart = (data) => (
  {
    type: adminActionTypes.UPDATE_ADMIN_SLA_TABLE_DATA_START,
    payload: data,
  }
)


export const updateAdminSlaTableDataSuccess = () => (
  {
    type: adminActionTypes.UPDATE_ADMIN_SLA_TABLE_DATA_SUCCESS,
  }
)

export const getAdminSupportTableData = (account, site, tab, card) => (
  {
    type: adminActionTypes.GET_ADMIN_SUPPORT_TABLE_DATA,
    account: account,
    site: site,
    tab: tab,
    card: card
  }
)

export const updateQueueRequestStart = (data) => (
  {
    type: adminActionTypes.QUEUE_ADMIN_REQUEST_START,
    payload: data,
  }
)


export const updateQueueRequestSuccess = () => (
  {
    type: adminActionTypes.QUEUE_ADMIN_REQUEST_SUCCESS,
  }
)


export const updateSLARequestStart = (data) => (
  {
    type: adminActionTypes.SLA_ADMIN_REQUEST_START,
    payload: data,
  }
)


export const updateSLARequestSuccess = () => (
  {
    type: adminActionTypes.SLA_ADMIN_REQUEST_SUCCESS,
  }
)

export const updateSupportRequestStart = (data) => (
  {
    type: adminActionTypes.SUPPORT_ADMIN_REQUEST_START,
    payload: data,
  }
)


export const updateSupportRequestSuccess = () => (
  {
    type: adminActionTypes.SUPPORT_ADMIN_REQUEST_SUCCESS,
  }
)



export const updateAdminSupportTableDataStart = (data) => (
  {
    type: adminActionTypes.UPDATE_ADMIN_SUPPORT_TABLE_DATA_START,
    payload: data,
  }
)


export const updateAdminSupportTableDataSuccess = () => (
  {
    type: adminActionTypes.UPDATE_ADMIN_SUPPORT_TABLE_DATA_SUCCESS,
  }
)


export const SaveSLAFailureDetails = (data) => (
  {
    type: 'SAVE_SLA_FAILURE_DETAILS',
    payload: data,
  }
)

export const updateAdminUpdateStart = (data) => (
  {
    type: adminActionTypes.UPDATE_ADMIN_UPDATE_START,
    payload: data,
  }
)


export const updateAdminUpdateSuccess = () => (
  {
    type: adminActionTypes.UPDATE_ADMIN_UPDATE_SUCCESS,
  }
)

export const getPreviewAdmin = (account, site) => (
  {
    type: adminActionTypes.GET_PREVIEW_ADMIN,
    account: account, site: site
  }

)

export const getRoleBasedView = (data) => (
  {
    type: adminActionTypes.GET_ROLE_BASED_VIEW,
    payload: data,
  }
)

export const saveStaffAdminActiveTab = (data) => (
  {
    type: adminActionTypes.SAVE_STAFFADMIN_ACTIVE_TAB,
    payload: data,
  }
)
