import API from "../api";
let token = localStorage.getItem("token");
export const getTilesDataService = (requestUrl) => {
  return API.get(requestUrl, {
    headers: {
      Authorization: token,
    },
  })
    .then((resp) => resp)
    .catch((error) => error.response);
};