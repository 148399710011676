import { all, call, put, takeEvery } from "redux-saga/effects";
import { updateDeleteAccountSuccess, updateGeneralNonvoiceSuccess, updateNewTemplateSuccess, updateOnboardSubmitSuccess, updateOnboardToggleSuccess, updateQueueTableSuccess, updateReOpenRequestSuccess, updateRoleDataSuccess, updateSLADataSuccess, updateSupportHoursStart, updateSupportHoursSuccess } from "../actions/Onboard.action";
import { onboardURLS } from "../constants/onboard-urls";
import { onboard_actionTypes as actionTypes } from "../constants/Onboard-actionTypes";
import { getAccountSiteService, getOriginatingCountryService, getGeneralInfoSiteService, getRolesTabRolesService, getRolesTabUsersService, getSLATemplateDataService, updateNewTemplateService, updateRolesTabDataService, updateSLATableService, getSLAKPIDataService, getQueueTabLanguagesService, updateQueueTableService, updateSupportHoursService, getSupportHoursVoiceService, getNonVoiceSLADataService, getNonVoiceSupportHoursService, getGeneralNonVoiceService, updateGeneralNonVoiceService, getPreviewOnboardService, getOnboardAccountsService, updateOnboardToggleService, updateOnboardSubmitService, getOnboardValidUserService, updateReOpenRequestService } from "../services/Onboard.service";
import { getQueueTableDataService, getRoleTabDataService, updateDeleteAccountService } from './../services/Onboard.service';


function* fetchAccountSiteData(action) {
    try {
        const response = yield call(getAccountSiteService, onboardURLS('GET_ACCOUNT_SITE'));
        if (response.data) {
            yield put({
                type: actionTypes.ACCOUNT_SITE,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Roles Title  data", error);
    }
}

function* fetchGeneralInfoSiteData(action) {
    try {
        const response = yield call(getGeneralInfoSiteService, onboardURLS('GET_GENERAL_INFO_SITE'));
        if (response.data) {
            yield put({
                type: actionTypes.GENERAL_INFO_SITE,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Roles Title  data", error);
    }
}


function* fetchRolesTabRolesData() {
    try {
        const response = yield call(getRolesTabRolesService, onboardURLS('GET_ROLES_TAB_ROLES'));
        if (response.data) {
            yield put({
                type: actionTypes.ROLES_TAB_ROLES,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Roles Title  data", error);
    }
}


function* fetchRolesTabUsersData({ query }) {
    let items = {
        query: query
    }
    try {
        const response = yield call(getRolesTabUsersService, onboardURLS('GET_ROLES_TAB_USERS', items));
        if (response.data) {
            yield put({
                type: actionTypes.ROLES_TAB_USERS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Roles Title  data", error);
    }
}

function* updateRolesTabData({ payload: id }) {
    try {
        const response = yield call(updateRolesTabDataService, onboardURLS('UPDATE_ROLES_DATA'), id);
        if (response.data) {
            yield put(
                updateRoleDataSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* updateQueueTable({ payload: id }) {
    try {
        const response = yield call(updateQueueTableService, onboardURLS('UPDATE_QUEUE_TABLE'), id);
        if (response.data) {
            yield put(
                updateQueueTableSuccess()
            );
            console.log(response.data)
        }
    } catch (error) {
        console.log("Error while updating Widget data", error);
    }
}

function* fetchQueueTabLanguages(action) {
    try {
        const response = yield call(getQueueTabLanguagesService, onboardURLS('GET_QUEUE_LANGUAGES'));
        if (response.data) {
            yield put({
                type: actionTypes.QUEUE_LANGUAGES,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Queue Title  data", error);
    }
}


function* fetchOriginatingCountry(action) {
    try {
        const response = yield call(getOriginatingCountryService, onboardURLS('GET_ORIGINATING_COUNTRIES'));
        if (response.data) {
            yield put({
                type: actionTypes.ORIGINATING_COUNTRIES,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Queue Title  data", error);
    }
}

function* fetchQueueTableData({ account, site }) {
    let items = {
        account: account, site: site
    }
    try {
        const response = yield call(getQueueTableDataService, onboardURLS('GET_QUEUE_TABLE_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.QUEUE_TABLE_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchSLATemplateData({ account, tab }) {
    let items = {
        account: account,
        tab: tab
    }
    try {
        const response = yield call(getSLATemplateDataService, onboardURLS('GET_SLA_TEMPLATES', items));
        if (response.data) {
            yield put({
                type: actionTypes.SLA_TEMPLATES,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchSLAKPIData({ account, site }) {
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getSLAKPIDataService, onboardURLS('GET_SLA_KPT_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.SLA_KPI_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateSLATableData({ payload: id }) {
    try {
        const response = yield call(updateSLATableService, onboardURLS('UPDATE_SLA_TABLE'), id);
        if (response.data) {
            yield put(
               
                updateSLADataSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateNewTemplateData({ payload: id }) {
    try {
        const response = yield call(updateNewTemplateService, onboardURLS('UPDATE_NEW_TEMPLATE'), id);
        if (response.data) {
            yield put(
              
                updateNewTemplateSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchRoleTabData({ account, site }) {
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getRoleTabDataService, onboardURLS('GET_ROLES_TAB_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.ROLES_TAB_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateSupportHoursData({ payload: id }) {
    try {
        const response = yield call(updateSupportHoursService, onboardURLS('UPDATE_SUPPORT_HOURS'), id);
        if (response.data) {
            yield put(
              
                updateSupportHoursSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchSupportHoursVoiceData({ account, site }) {
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getSupportHoursVoiceService, onboardURLS('GET_SUPPORT_HOURS_VOICE', items));
        if (response.data) {
            yield put({
                type: actionTypes.SUPPORT_HOURS_VOICE,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchNonVoiceSLAData({ account, site, widget }) {
    let items = {
        account: account,
        site: site,
        widget: widget
    }
    try {
        const response = yield call(getNonVoiceSLADataService, onboardURLS('GET_NON_VOICE_SLA_DATA', items));
        if (response.data) {
            yield put({
                type: actionTypes.NON_VOICE_SLA_DATA,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchNonVoiceSHData({ account, site, widget }) {
    let items = {
        account: account,
        site: site,
        widget: widget
    }
    try {
        const response = yield call(getNonVoiceSupportHoursService, onboardURLS('GET_NON_VOICE_SUPPORT_HOURS', items));
        if (response.data) {
            yield put({
                type: actionTypes.NON_VOICE_SUPPORT_HOURS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchGeneralNonvoiceData({ account, site, widget }) {
    let items = {
        account: account,
        site: site,
        widget: widget
    }
    try {
        const response = yield call(getGeneralNonVoiceService, onboardURLS('GET_GENERAL_NON_VOICE', items));
        if (response.data) {
            yield put({
                type: actionTypes.GENERAL_NON_VOICE,
                payload: response.data,
            });
        }
        console.log(response.data)
    } catch (error) {
        console.log(error);
    }
}

function* updateGeneralNonvoiceData({ payload: id }) {
    try {
        const response = yield call(updateGeneralNonVoiceService, onboardURLS('UPDATE_GENERAL_NON_VOICE'), id);
        if (response.data) {
            yield put(
              
                updateGeneralNonvoiceSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchPreviewOnboardData({ account, site }) {
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getPreviewOnboardService, onboardURLS('GET_PREVIEW_ONBOARD', items));
        if (response.data) {
            yield put({
                type: actionTypes.PREVIEW_ONBOARD,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}


function* fetchOnboardAccountsData() {
    try {
        const response = yield call(getOnboardAccountsService, onboardURLS('GET_ONBOARD_ACCOUNTS'));
        if (response.data) {
            yield put({
                type: actionTypes.ONBOARD_ACCOUNTS,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateOnboardToggleData({ payload: id }) {
    try {
        const response = yield call(updateOnboardToggleService, onboardURLS('UPDATE_ONBOARD_TOGGLE'), id);
        if (response.data) {
            yield put(
             
                updateOnboardToggleSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateOnboardSubmitData({ payload: id }) {
    try {
        const response = yield call(updateOnboardSubmitService, onboardURLS('UPDATE_ONBOARD_SUBMIT'), id);
        if (response.data) {
            yield put(
               
                updateOnboardSubmitSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* fetchOnboardValidUser({ account, site }) {
    let items = {
        account: account,
        site: site
    }
    try {
        const response = yield call(getOnboardValidUserService, onboardURLS('GET_ONBOARD_VALID_USER',items));
        if (response.data) {
            yield put({
                type: actionTypes.ONBOARD_VALID_USER,
                payload: response.data,
            });
        }
    } catch (error) {
        console.log("Error while Valid User data", error);;
    }
}

function* updateReOpenRequest({ payload: id }) {
    try {
        const response = yield call(updateReOpenRequestService, onboardURLS('UPDATE_REOPEN_REQUEST'), id);
        if (response.data) {
            yield put(
               
                updateReOpenRequestSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* updateDeleteAccount({ payload: id }) {
    try {
        const response = yield call(updateDeleteAccountService, onboardURLS('UPDATE_DELETE_ACCOUNT'), id);
        if (response.data) {
            yield put(
               
                updateDeleteAccountSuccess()

            );
            console.log(response.data)
        }
    } catch (error) {
        console.log(error);
    }
}

function* watchGetRequest() {
    yield takeEvery(actionTypes.GET_ACCOUNT_SITE, fetchAccountSiteData)
    yield takeEvery(actionTypes.GET_GENERAL_INFO_SITE, fetchGeneralInfoSiteData)
    yield takeEvery(actionTypes.GET_ROLES_TAB_ROLES, fetchRolesTabRolesData)
    yield takeEvery(actionTypes.GET_ROLES_TAB_USERS, fetchRolesTabUsersData)
    yield takeEvery(actionTypes.UPDATE_ROLE_DATA_START, updateRolesTabData);
    yield takeEvery(actionTypes.GET_QUEUE_LANGUAGES, fetchQueueTabLanguages)
    yield takeEvery(actionTypes.GET_ORIGINATING_COUNTRIES, fetchOriginatingCountry)
    yield takeEvery(actionTypes.UPDATE_QUEUE_TABLE_START, updateQueueTable);
    yield takeEvery(actionTypes.GET_QUEUE_TABLE_DATA, fetchQueueTableData)
    yield takeEvery(actionTypes.GET_SLA_TEMPLATES, fetchSLATemplateData)
    yield takeEvery(actionTypes.UPDATE_NEW_TEMPLATE_START, updateNewTemplateData);
    yield takeEvery(actionTypes.UPDATE_SLA_TABLE_START, updateSLATableData);
    yield takeEvery(actionTypes.UPDATE_SUPPORT_TABLE_START, updateSupportHoursData);
    yield takeEvery(actionTypes.GET_ROLES_TAB_DATA, fetchRoleTabData)
    yield takeEvery(actionTypes.GET_SLA_KPI_DATA, fetchSLAKPIData)
    yield takeEvery(actionTypes.GET_SUPPORT_HOURS_VOICE, fetchSupportHoursVoiceData)
    yield takeEvery(actionTypes.GET_NON_VOICE_SLA_DATA, fetchNonVoiceSLAData)
    yield takeEvery(actionTypes.GET_NON_VOICE_SUPPORT_HOURS, fetchNonVoiceSHData)

    yield takeEvery(actionTypes.GET_GENERAL_NON_VOICE, fetchGeneralNonvoiceData)
    yield takeEvery(actionTypes.UPDATE_GENERAL_NON_VOICE_START, updateGeneralNonvoiceData);

    yield takeEvery(actionTypes.GET_PREVIEW_ONBOARD, fetchPreviewOnboardData)
    yield takeEvery(actionTypes.GET_ONBOARD_ACCOUNTS, fetchOnboardAccountsData)

    yield takeEvery(actionTypes.UPDATE_ONBOARD_TOGGLE_START, updateOnboardToggleData);
    yield takeEvery(actionTypes.UPDATE_ONBOARD_SUBMIT_START, updateOnboardSubmitData);
    yield takeEvery(actionTypes.GET_ONBOARD_VALID_USER, fetchOnboardValidUser)
    yield takeEvery(actionTypes.UPDATE_REOPEN_REQUEST_START, updateReOpenRequest);
    yield takeEvery(actionTypes.UPDATE_DELETE_ACCOUNT_START, updateDeleteAccount);

}
export default function* OnboardSagas() {
    yield all([watchGetRequest()]);
}