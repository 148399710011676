

const getUserFlowKeyword = (keyword,status) => {
    console.log(keyword)
    if (keyword === null || keyword === undefined) {
        return `/api/users/?$top=100&active=${status}`
    }
    else {
        return `/api/users/?keyword=${keyword}&$top=100&active=${status}`
    }
}

const getAuditRBACData = (items) => {
    console.log('items',items)
    const dispobj = (items.role !== '' ? `Role=${items.role}` : '') + '&' + (items.accessGroup !== '' ? `AccessGroup=${items.accessGroup}` : '') + '&' + (items.operation !== '' ? `Operation=${items.operation}` : '') + '&'; 
    console.log(items)
    if (items.date === '' && items.requesttype === '' && items.status === '' )
        return '/api/usp/AuditRBACGetData'
     else
         return `/api/usp/AuditRBACGetData?${dispobj}`;
}

const getAuditRoleData = (items) => {
    const dispobj = (items.role !== '' ? `Role=${items.role}` : '') + '&' + (items.account !== '' ? `Account=${items.account}` : '') + '&' + (items.site !== '' ? `Site=${items.site}` : '') + '&' + (items.user !== '' ? `User=${items.user}` : '') + '&' + (items.region !== '' ? `Region=${items.region}` : '') + '&'; 
    console.log(items)
    if (items.date === '' && items.requesttype === '' && items.status === '' )
        return '/api/usp/AuditRoleGetData'
     else
         return `/api/usp/AuditRoleGetData?${dispobj}`;
}

export const AdminViewURLS = (name, items) => {
    switch (name) {
        case 'GET_ADMIN_ROLES': {
            return '/api/usp/ManageAdminRoles'
        }
        case 'GET_ACCESS_GROUP': {
            return '/api/usp/ManageAdminGetAccessGroupOperations'
        }
        case 'UPDATE_ACCESSGROUP_DATA': {
            return `/api/usp/ManageAdminRoleAndAccessGroups`
        }
        case 'UPDATE_ROLE_DELETE': {
            return `/api/usp/ManageAdminDeleteRole`
        }

        // OPERATIONS FLOW 

        case 'GET_OPERATIONS_DATA': {
            return '/api/usp/ManageAdminGetOperations'
        }
        case 'UPDATE_OPERATIONS_DATA': {
            return `/api/usp/ManageAdminOperations`
        }
        case 'UPDATE_OPERATIONS_DELETE': {
            return `/api/usp/ManageAdminDeleteOperation`
        }

         // ACCESS GROUP FLOW 

         case 'GET_ACCESSGROUP_DATA': {
            return '/api/usp/ManageGetAccessGroupOperations'
        }
        case 'GET_ACCESSGROUP_EDIT_DATA': {
            return `/api/usp/ManageAdminGetAccessGroupSelectOperations?AccessGroupID=${items.accessgroupid}`
        }
        case 'GET_ACCESSGROUP_ADD_DATA': {
            return `/api/usp/ManageAdminGetAGBlankSelectOperations`
        }
        case 'UPDATE_ACCESSGROUP_ADDEDIT': {
            return `/api/usp/ManageAdminAccessGroupAndOperations`
        }
        case 'UPDATE_ACCESSGROUP_DELETE': {
            return `/api/usp/ManageAdminDeleteAccessGroup`
        }

        // USER FLOW

        case 'GET_TIMEZONE_DATA': {
            return '/api/usp/ManageAdminGetTimeZone'
        }
        case 'UPDATE_ADD_USER': {
            return `/api/usp/ManageAdminNewUser`
        }
        case 'UPDATE_DELETE_USER': {
            return `/api/usp/ManageAdminDeleteUser`
        }
        case 'GET_EDIT_USER_DATA': {
            return `/api/usp/ManageAdminGetUserAccountAssignment?UserID=${items.userId}`
        }
        case 'UPDATE_EDIT_USER': {
            return `/api/usp/ManageAdminAddRoleAndAccount`
        }
        case 'GET_REGION_DATA': {
            return '/api/usp/ManageAdminGetRegions'
        }
        case 'GET_UPN_VALIDATION': {
            return `api/users/?filter=UPN%20eq%20%27${items.upn}%27&$top=1`
        }
        case 'GET_USERFLOW_USERS': {
            return getUserFlowKeyword(items.query,items.status)
        }
        case 'GET_USERFLOW_COUNT': {
            return `/api/usp/usercount?active=${items.status}`
        }

        // AUDIT RBAC

        case 'GET_AUDITRBAC_DATA': {
            return getAuditRBACData(items)
        }

        // AUDIT RBAC

        case 'GET_AUDITROLE_DATA': {
            return getAuditRoleData(items)
        }
        default: { }
    }

}   