import axios from "axios";
import ConstructURLs from "../constants/urls";

let requestCounter = 0;

let axInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
  crossDomain: true,
});

axInstance.interceptors.request.use(function (config) {
  requestCounter++;
  document.body.classList.add('loading-indicator');
  
  // You can add token handling here if needed
  
  return config;
}, function (error) {
  console.log("error 1 ---->", error.message);
  return Promise.reject(error);
});

axInstance.interceptors.response.use(
  function (response) {
    requestCounter--;
    if (requestCounter === 0) {
      document.body.classList.remove('loading-indicator');
    }
    if (response.config.headers && response.config.headers.Location) {
      window.localStorage.clear();
      window.location.href = ConstructURLs("LOG_OUT_URL");
    }
    return response;
  },
  function (error) {
    requestCounter--;
    console.log(error);
    if (requestCounter === 0) {
      document.body.classList.remove('loading-indicator');
    }
    // Handle error cases here if needed
    return Promise.reject(error);
  }
);

export default axInstance;
