import React from "react";
import "../Box/box.css";
import "./tableBox.css";
import greenStatusImg from "../../assets/images/green_status.png";
import redStatusImg from "../../assets/images/red_status.png";
import ContractualSLAIcon from "../../assets/images/Contractual_SLAs.png";
const TableBox = (props) => {
  return (
    <div className="box">
      <div className="border-box"></div>
      <div className="table-box-body">
        <div className="box-name">
          {props.data ? props.data.name + "  --  " + props.data.line : ""}
        </div>
        <div className="info info-month">
          {props.data
            ? props.data.info.map((item, i) => (
                <div className="table-box-info" key={i}>
                  <div className="table-box-text1">{item.infoName}</div>
                  <div className="table-box-text2">
                    {item.percentage}
                    <span className="box-status-img">
                      {item.status ? (
                        <img
                          src={
                            item.status === "green"
                              ? greenStatusImg
                              : redStatusImg
                          }
                          alt="status"
                        />
                      ) : null}
                      {item.sla ? (
                        <img src={ContractualSLAIcon} alt="status" />
                      ) : null}
                    </span>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default TableBox;
