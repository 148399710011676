import { welcomePage_actionTypes as actionTypes } from "../constants/welcomePage-actionTypes";



export const getRolesTitleInfo = (data) => (
  {
    type: actionTypes.GET_ROLES_TITLE,
    payload: data,
  }
)


export const getWidgetInfo = (data) => (
  {
    type: actionTypes.GET_WIDGET_INFO,
    payload: data,
  }
)

export const recentlinksInfo = (data) => (
  {
    type: actionTypes.GET_RECENT_LINKS,
    payload: data,
  }
)


export const updateWidgetInfoStart = (data) => (
  {
    type: actionTypes.UPDATE_WIDGET_INFO_START,
    payload: data,
  }
)


export const updateWidgetInfoSuccess = () => (
  {
    type: actionTypes.UPDATE_WIDGET_INFO_SUCCESS,
  }
)



export const saveSelectedRole = (role) => ({
  type: actionTypes.SET_SELECTED_ROLE,
  payload: role
})


export const updateWidgetPreferenceShown = (data) => (
  {
    type: actionTypes.UPDATE_WIDGET_PREFERENCE_SHOWN,
    payload: data,
  }
)


export const WidgetPreferenceShown = () => (
  {
    type: actionTypes.WIDGET_PREFERENCE_SHOWN,
  }
)



export const getWidgetPreference = (data) => ({
  type: actionTypes.GET_WIDGET_PREFERENCE,
  payload: data
})

export const getcarouselTitle = (data) => ({
  type: actionTypes.GET_CAROUSEL_TITLE,
  payload: data
})

export const setWidgetSelected = data => ({
  type: actionTypes.SET_WIDGET_SELECTED,
  payload: data
})


export const setAccountSelected = data => ({
  type: actionTypes.SET_SELECTED_ACCOUNTS,
  payload: data
})

export const getMultiAccountWidgets = data => ({
  type: actionTypes.GET_MULTI_ACCOUNTS_WIDGETS,
  payload: data
})

export const getMultiWidgetInfo = data => ({
  type: actionTypes.GET_MULTI_WIDGET_INFO,
  payload: data
})

export const updateMultiWidgetStart = (data) => (
  {
    type: actionTypes.UPDATE_MULTI_WIDGET_START,
    payload: data,
  }
)


export const updateMultiWidgetSuccess = () => (
  {
    type: actionTypes.UPDATE_MULTI_WIDGET_SUCCESS,
  }
)


export const saveSelectedAccounts = (data) => ({
  type: actionTypes.SAVE_SELECTED_ACCOUNTS,
  payload: data
})

export const saveDropdownAccounts = (data) => ({
  type: actionTypes.SAVE_DROPDOWN_ACCOUNTS,
  payload: data
})

export const saveSelectedCustIDServID = (data) => ({
  type: actionTypes.SAVE_SELECTED_CUSTID_SERVID,
  payload: data
})

export const saveSelectedAccountObj = (data) => ({
  type: actionTypes.SAVE_SELECTED_ACCOUNT_OBJ,
  payload: data
})


export const getAccountSummary = (custID,servID) => ({
  type: actionTypes.GET_ACCOUNT_SUMMARY,
  custID: custID,
  servID: servID
})


export const getAccountDropdown = (data) => ({
  type: actionTypes.GET_ACCOUNT_DROPDOWN,
  custID: data
})

export const getLatestReleasesStart = () => ({
  type: actionTypes.GET_LATEST_RELEASES_START,
});


export const getLatestReleasesSuccess = (data) => ({
  type: actionTypes.GET_LATEST_RELEASES_SUCCESS,
  payload: data
});


export const updateLatestReleasesStart = (data) => ({
  type: actionTypes.UPDATE_LATEST_RELEASES_START,
  payload: data

});


export const updateLatestReleasesSuccess = () => ({
  type: actionTypes.UPDATE_LATEST_RELEASES_SUCCESS,
});

export const createLatestReleasesStart = (data) => ({
  type: actionTypes.CREATE_LATEST_RELEASES_START,
  payload: data
});


export const createLatestReleasesSuccess = () => ({
  type: actionTypes.CREATE_LATEST_RELEASES_SUCCESS,
});

export const deleteLatestReleasesStart = (data) => ({
  type: actionTypes.DELETE_LATEST_RELEASES_START,
  payload: data
});


export const deleteLatestReleasesSuccess = (data) => ({
  type: actionTypes.DELETE_LATEST_RELEASES_SUCCESS,
  payload: data
});


export const getStoredAccount = (data) => ({
  type: actionTypes.GET_STORED_ACCOUNT,
  payload: data
})

export const updateStoredAccountStart = (data) => (
  {
    type: actionTypes.UPDATE_STORED_ACCOUNT_START,
    payload: data,
  }
)


export const updateStoredAccountSuccess = () => (
  {
    type: actionTypes.UPDATE_STORED_ACCOUNT_SUCCESS,
  }
)

export const getTaskLinks = (data) => ({
  type: actionTypes.GET_TASK_LINKS,
  payload: data
})


export const setGithubSolutionCarouselData = (data) => ({
  type: 'SET_GITHUB_SOLUTION_CAROUSEL_DATA',
  payload: data
})