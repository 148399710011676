import { all, call, put, takeEvery } from "redux-saga/effects";
import actionTypes from "../constants/actionTypes";
import ConstructURLs from "../constants/urls";
import {
  getUserDataService,
  getUserTokenService,
  getRegionalDataService
} from "../services/user.service";

// function* fetchUserDetails(action) {
//   try {
//     const response = yield call(getUserDataService, ConstructURLs('GET_USER_DATA'));
//     console.log("Fetching user details API is success");
//     console.log(response);
//     if (response.data) {
//       yield put({
//         type: actionTypes.USER_DATA,
//         payload: response.data,
//       });
//     }
//   } catch (error) {
//     console.log("Error while fetching userdetails");    
//   }
// }


 // import { Redirect, useHistory, useLocation } from "react-router-dom";
// import { browserHistory } from 'react-router';
// import {useNavigate} from 'react-router-dom';


// const RedirectTo = (path) => { 
//   const navigate = useNavigate();
//   navigate.push(`/${path}`);
// }
console.log('location', window.location.hash);

function* fetchUserDetails(action) {
  try {
    const response = yield call(getUserDataService, ConstructURLs('GET_USER_DATA'));
    console.log("Fetching user details API is success");
    console.log(response);
    if (response.data && response.data.statusCode === 200) {
      yield put({
        type: actionTypes.USER_DATA,
        payload: response.data,
      });
    } else if(response.data && response.data.statusCode === 500) {
     
      console.log("======>", response.data.path) 
      window.location.href = (response.data.path);
     // return <Redirect to='/auth/login'/>
     // RedirectTo(response.data.path)
    }
    else{
      yield put({
        type: actionTypes.USER_DATA,
        payload: response.data,
      });
      console.log(response)
    }
  } catch (error) {
    console.log("Error while fetching userdetails");  
  }
}

function* fetchUserToken(action) {
  try {
    const response = yield call(getUserTokenService, ConstructURLs('GET_USER_TOKEN'));
    console.log("Fetching user Token API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.USER_TOKEN,
        payload: response.data.token,
      });

      localStorage.setItem("token", "Bearer " + response.data.token);
    }
  } catch (error) {
    console.log("Error while fetching user Token");    
  }
}

function* fetchRegionalData(action){
  try {
    const response = yield call(getRegionalDataService, ConstructURLs('GET_REGIONAL_DATA'));
    console.log("Fetching Regional Data API is success");
    console.log(response);
    if (response.data) {
      yield put({
        type: actionTypes.REGIONAL_DATA,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("Error while fetching Regional data");    
  }
}

function* watchGetRequest() {
  yield takeEvery(actionTypes.GET_USER_DATA, fetchUserDetails);
  yield takeEvery(actionTypes.GET_USER_TOKEN, fetchUserToken);
  yield takeEvery(actionTypes.GET_REGIONAL_DATA, fetchRegionalData);
}

export default function* userSagas() {
  yield all([watchGetRequest()]);
}


