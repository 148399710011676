import actionTypes from "../constants/actionTypes";

const initialState = { showSettingsModal: false,  };

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SETTINGS:
      return {
        showSettingsModal: action.payload
      }
    default:
      return state;
  }
};

export default userReducer;
