
import adminActionTypes from './../../constants/Administration/admin-action-types'

const initialState = {
    AdminAccounts: [],
    validToUpdateResponse: {},
    AccountDetails: {},
    AdminQueueTableData: [],
    UpdateAdminQueueTableData: [],
    AdminSlaTableData: [],
    UpdateAdminSlaTableData: [],
    AdminSupportTableData: [],
    UpdateAdminSupportTableData: [],
    AdminVoiceSlaTableData: [],
    AdminVoiceSupportTableData: [],
    SLAfailureDetails: {},
    previewAdminData:[],
    RoleBasedView:[],
    staffAdminActiveTab:''
}


const adminAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case adminActionTypes.GET_ADMIN_ACCOUNTS:
        case adminActionTypes.GET_PREVIEW_ONBOARD:
        case adminActionTypes.GET_ROLE_BASED_VIEW:
            return { ...state };


        case adminActionTypes.ADMIN_ACCOUNTS: {
            return { ...state, AdminAccounts: action.payload }
        }
        case adminActionTypes.UPDATE_VALIDTO_DATE_START: {
            return { ...state, validToUpdateResponse: action.payload }
        }
        case adminActionTypes.UPDATE_ACCOUNT_DEATILS: {
            return { ...state, AccountDetails: action.payload }
        }
        case adminActionTypes.ADMIN_QUEUE_TABLE_DATA: {
            return { ...state, AdminQueueTableData: action.payload }
        }
        case adminActionTypes.UPDATE_ADMIN_QUEUE_TABLE_DATA_START: {
            return { ...state, UpdateAdminQueueTableData: action.payload }
        }
        case adminActionTypes.ADMIN_SLA_TABLE_DATA: {
            return { ...state, AdminSlaTableData: action.payload }
        }
        case adminActionTypes.UPDATE_ADMIN_SLA_TABLE_DATA_START: {
            return { ...state, UpdateSlaQueueTableData: action.payload }
        }
        case adminActionTypes.ADMIN_SUPPORT_TABLE_DATA: {
            return { ...state, AdminSupportTableData: action.payload }
        }
        case adminActionTypes.UPDATE_ADMIN_SUPPORT_TABLE_DATA_START: {
            return { ...state, UpdateAdminSupportTableData: action.payload }
        }
        case adminActionTypes.ADMIN_VOICE_SLA_TABLE_DATA: {
            return { ...state, AdminVoiceSlaTableData: action.payload }
        }
        case adminActionTypes.ADMIN_VOICE_SUPPORT_TABLE_DATA: {
            return { ...state, AdminVoiceSupportTableData: action.payload }
        }
        case 'SAVE_SLA_FAILURE_DETAILS': {
            return { ...state, SLAfailureDetails: action.payload }
        }
        case adminActionTypes.PREVIEW_ADMIN: {
            return { ...state, previewAdminData: action.payload }
        }
        case adminActionTypes.ROLE_BASED_VIEW: {
            return { ...state, RoleBasedView: action.payload }
        }
        case adminActionTypes.SAVE_STAFFADMIN_ACTIVE_TAB: {
            return { ...state, staffAdminActiveTab: action.payload }
        }
        default:
            return state;
    }


}


export default adminAccountReducer