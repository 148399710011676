import React from 'react';
import { Accordion } from 'react-bootstrap';
import './OffCanvas.css'
import HINTS from './Hints.json'
function AccordionHandler() {
    return (<>

        <Accordion defaultActiveKey={['']} alwaysOpen flush={false}>
            {
                HINTS.VOICE.map((hints, i) => (
                    <Accordion.Item eventKey={i} style={{ marginTop: '0.3em' }}>
                        <Accordion.Header style={{
                            width: 'inherit',
                            height: '58px',
                            backgroundColor: ' #EEECF1 ',
                            boxShadow: '0px 3px 3px #9D9D9D80'
                        }}>
                            <span className='accordion-header'>{hints.Title}</span>
                        </Accordion.Header>
                        <Accordion.Body style={{
                            marginTop: '0', border: 'none', backgroundcolor: 'white'
                        }}>
                            <div className='queue-span'>  Usage  </div>
                            <div className='queue-para'>
                                {hints.Usage.map((para, i) => (
                                    <div id='p1' key={i}> {para}</div>
                                ))}
                                <div className='queue-span'>  Legend  </div>
                                {hints.Legend.map((legend, i) => (
                                    <> <div className='queue-span-two'> {legend.title}</div>
                                        <div id='p1'>  {legend.info}</div>
                                    </>
                                ))}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))
            }
        </Accordion>
    </>);
}

export default AccordionHandler;
