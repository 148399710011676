import React, { useEffect, useState } from 'react';
import FileUploadhandler from '../File-Upload/FileUploadhandler';
import FormFieldHandler from '../File-Upload/FormFieldHandler';
import { getVolumeSlakpiSelectedTemplatesData } from '../../../actions/uploads.action';
import { useDispatch, useSelector } from 'react-redux';
import UploadAccordion from './UploadAccordion';

function VoiceTabDetails(props) {
    const dispatch = useDispatch();
    const [openAccordion, setOpenAccordion] = useState(null);
    const [error, setError] = useState(null);
    const SelectedAccountDetails = useSelector((state) => state.uploadReducer.SelectedAccountDetails);
    const SelectedVoiceNonVoiceItem = useSelector((state) => state.uploadReducer.SelectedVoiceNonVoiceItem);
    const selectedVolumeSlaDate = useSelector((state) => state.uploadReducer.selectedVolumeSlaDate);
    const dataAdministrationValidation = useSelector((state) => state.uploadReducer.dataAdministrationValidation);

    console.log('dataAdministrationValidation',dataAdministrationValidation)

    const ACCORDION_INFO = [
        {
            id: 1,
            label: 'File Upload',
            Info: <FileUploadhandler/>
        },
        {
            id: 2,
            label: 'Volume Data',
            Info: <FormFieldHandler error={error} setError={setError}/>
        },
        {
            id: 3,
            label: 'SLA/KPI Data',
            Info: <FormFieldHandler error={error} setError={setError}/>
        }
    ];

    useEffect(() => {
        if (openAccordion) {
            dispatch(getVolumeSlakpiSelectedTemplatesData(
                SelectedAccountDetails.CustomerID || '',
                SelectedAccountDetails.ServiceDeskID || '',
                openAccordion === '1' ? 'Volume' : 'SLA/KPI',
                selectedVolumeSlaDate,
                SelectedVoiceNonVoiceItem
            ));
        }
    }, [openAccordion, dispatch, SelectedAccountDetails, SelectedVoiceNonVoiceItem, selectedVolumeSlaDate]);

    const handleAccordionOpen = (id) => {
        // Check if the clicked accordion is already open
        if (openAccordion === id) {
            // If it's already open, check validation before closing
            if (dataAdministrationValidation) {
                setOpenAccordion(null); // Close the accordion if validation passes
                setError(null);
            } else {
                console.log('Data Not Saved. Cannot close this section.'); // Prevent closing if validation fails
                // Optionally, show an alert or message to the user
                setError('Validation failed. Cannot close this section.');
            }
        } else {
            // If trying to open a different accordion, validate first
            if (dataAdministrationValidation) {
                setOpenAccordion(id); // Update the state if validation passes
                setError(null);
            } else {
                console.log('Validation failed. Cannot open this section.'); // Prevent the accordion from opening
                // Optionally, show an alert or message to the user
                setError('Data Not Saved. Cannot open this section.');
            }
        }
    };


    console.log('openAccordion',openAccordion)

    return (
        <section className='voice-container' style={{paddingBottom:'100px'}}>
            <main className='main-wrapper'>
                <div className='aws-div'>
                    <span className='aws-span'>AWS (ASD) Telephony</span>
                </div>
            </main>
            <UploadAccordion
                ACCORDION_INFO={ACCORDION_INFO}
                openAccordion={openAccordion}
                onAccordionOpen={handleAccordionOpen}
            />
            {props.children}
        </section>
    );
}

export default VoiceTabDetails;
